<div class="card">
	<div class="card-header">
		FAQs
	</div>
	<div class="card-body">
		<!-- REMEMBER: ApplicationMetaData.list() will auto-add the appID, but "search()" does not... -->
		<span *wackadooResourceBlock="let r1 of []; let idx = index; let prev = previous; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'faq', 'pageSize':-1, 'sortBy':'name', 'sortDirection':1};">
			<span *ngIf="(idx === 0) || (r1.name.value !== prev.name.value)">
				<div class="italic underline marginBottomQuarterEm" *ngIf="(idx === 0) || (r1.name.value !== prev.name.value)">
					{{ r1.name.value }}
				</div>
				<div class="marginBottomQuarterEm">
					<div class="marginLeftWholeEm paddingLeftWholeEm" *wackadooResourceBlock="let r2 of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':r1.subgroup.value, 'name':r1.name.value, 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
						{{ r2.value.value }}
					</div>
				</div>
			</span>
		</span>
	</div>
</div>
