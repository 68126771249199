<div *ngIf="fileContentField && fileContentField.isPopulated">
  <div>
    {{ title }}
  </div>
  <div class="d-flex justify-content-between flex-wrap">
    <div>
      {{ this.fileContentField.fileName }}
    </div>
    <div class="marginLeftWholeEm no-wrap">
      ({{ this.fileContentField.fileType }})
    </div>
    <div class="marginLeftWholeEm">
        {{ formatNumber(this.fileContentField.fileSize) }}
    </div>
  </div>
</div>
