import { Component, OnInit } from '@angular/core';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';

@Component({
  selector: 'wackadoo-info-pricing-info',
  templateUrl: './pricing-info.component.html',
})
export class PricingInfoComponent implements OnInit {

  screenType: ScreenType = null;

  constructor(
    public userInterfaceService: UserInterfaceService
  ) { }

  ngOnInit(): void {
    this.screenType = this.userInterfaceService.screenType.getValue();
  }

}
