<div class="card">
	<div class="card-header">
		Before You Get Started...
	</div>
	<div class="card-body scrollable"
			wackadooMaximizeContentHeight
			maxHeightThingBelowSelector=".wackadoo-footer"
			[maxHeightFudgeFactor]="-16"
	>
		<!-- REMEMBER: ApplicationMetaData.list() will auto-add the appID, but "search()" does not... -->
		<div class="marginBottomQuarterEm">
			<div *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'overview', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
				{{ r.value.value }}
			</div>
		</div>

		<strong>In this app...</strong>
		<ul class="marginBottomQuarterEm">
			<span *wackadooResourceBlock="let r1 of []; let idx = index; let prev = previous; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'entity', 'pageSize':-1, 'sortBy':'name', 'sortDirection':1};">
				<li *ngIf="(idx === 0) || (r1.name.value !== prev.name.value)">
					<div class="italic underline" *ngIf="(idx === 0) || (r1.name.value !== prev.name.value)">
						{{ r1.name.value }}
					</div>
					<ul>
						<li *wackadooResourceBlock="let r2 of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':r1.subgroup.value, 'name':r1.name.value, 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
							{{ r2.value.value }}
						</li>
					</ul>
				</li>
			</span>
		</ul>
		<strong>Initial setup</strong>
		<ol class="marginBottomQuarterEm">
			<li *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'initial setup', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
				{{ r.value.value }}
			</li>
		</ol>
		<strong>As you use the app...</strong>
		<ul class="marginBottomQuarterEm">
			<span *wackadooResourceBlock="let r1 of []; let idx = index; let prev = previous; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'procedure', sequence: 1, 'pageSize':-1}; debug:false;">
				<li>
					<div class="italic underline">
						{{ r1.name.value }}
					</div>
					<ul>
						<li *wackadooResourceBlock="let r2 of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'procedure', 'name':r1.name.value, 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1}; debug:false;">
							{{ r2.value.value }}
						</li>
					</ul>
				</li>
			</span>
		</ul>
		<strong>General recommendations...</strong>
		<ul class="marginBottomQuarterEm" *wackadooResourceBlock="let r1 of []; let idx = index; let prev = previous; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'recommendation', 'pageSize':-1, 'sortBy':'name', 'sortDirection':1};">
			<li *ngIf="(idx === 0) || (r1.name.value !== prev.name.value)">
				<div class="italic underline" *ngIf="(idx === 0) || (r1.name.value !== prev.name.value)">
					{{ r1.name.value }}
				</div>
				<ul>
					<li *wackadooResourceBlock="let r2 of []; let idx2 = index; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'recommendation', 'name':r1.name.value, 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
						{{ r2.value.value }}
					</li>
				</ul>
			</li>
		</ul>
	</div>
</div>
