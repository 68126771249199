import { Component, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { WResource } from 'src/app/client-core/data/resource.model';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-pricing',
  templateUrl: './pricing.component.html',
})
export class PricingComponent extends PageComponent implements OnInit {

  applications: WResource [] = [];

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
  ) {
      super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {

    super.ngOnInit();

    try {
      const parms: any = {};
      parms.pageSize = -1;
      parms.sortBy = 'appName';
      parms.sortDirection = 1;

      this.eventServerService.fireEvent('Applications', 'list', parms).subscribe(
        (event: WEvent) => {
          console.log(event);
          if (event.status !== 'OK') {
            throw new Error(event.message);
          }
          for (const app of event.resources) {

            // skip the AccountManager app
            if (app.appName.value !== 'AccountManager') {
              // console.log(app.appName.value);

              this.applications.push(app);

              this.eventServerService.fireEvent('AppPricing', 'list', app.appID.asParm).subscribe(
                (event2: WEvent) => {
                  // console.log(event2);
                  if (event2.status !== 'OK') {
                    throw new Error(event2.message);
                  }
                  // sometimes we forget to put a price on "private" apps... (e.g. SymptomCare)
                  if (event2.resources) {
                    // console.log(app.appName.value + ' prices: ' + event2.resources.length, event2);
                    for (const appPrice of event2.resources) {
                      app.addExtraField(appPrice.price);
                      app.addExtraField(appPrice.priceModel);
                    }
                  }
                }
              );
            }
          }
        }
      );
    } catch (ex) {
      const msg = 'Failure to load AppPricing and/or Apps...';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

  goToAppHomePage(appName: string): void {
    window.location.href = (Globals.rootApplication === 'AccountManager' ? ('/' + appName) : '/');
  }

}
