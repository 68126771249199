		<div class="card">
			<div class="card-header">
				Expired or Delinquent Account Policy...
			</div>
			<div class="card-body">
				<p>
					Accounts themselves do not expire, but web app registrations have an expiration date.  
					Each month, as the recurring payment for a subscription web app is made, the registration
					date is pushed out one more month.  Should the recurring payment fail, that web app 
					registration becomes delinquent.
				</p>
				<p>
					To reiterate the applicable part of our Billing Policy:
				</p>
				<p class="well italic">
					There is a four calendar-day window in which a delinquent subscription web app (i.e. one with a
					failed payment...) can be re-instated without causing interruption of service, or the loss of data 
					described in the backup/recovery policies. Simply go to the Billing page, enter a new credit card,
					and click on the "Subscribe" button.  Your credit card will be charged the payment for the current
					month, and the web app will be put back into the regular billing cycle.
				</p>
				<p>
					If you do not act within the four calendar-day re-instatement window, the registered application 
					will expire. To reiterate the applicable part of our Backup Policy:
				</p>
				<p class="well italic">
					Our server maintenance procedures automatically delete data when registered applications 
					are CANCELLED or have EXPIRED. They run on a daily basis.  So, if you do not back up your 
					data, and you decide to cancel a subscription web app, or let a web app expire, then 
					<span class="bold italic">YOUR DATA IS GONE</span> because 
					<span class="bold italic">WE DO NOT KEEP</span> a backup of your data <span class="bold italic">FOR YOU</span>.
				</p>
			</div>
		</div>
