import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-log-extractor',
  templateUrl: './log-extractor.component.html',

})
export class LogExtractorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
