import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subscriber } from 'rxjs';
import { PromptContent } from './prompt-content.model';
import { ModalDialogService } from '../../../services/modal-dialog.service';

@Component({
  selector: 'wackadoo-prompt',
  templateUrl: './prompt.component.html',

})
export class PromptComponent implements OnInit, OnDestroy {

  visible = false;

  showModalSubscription: Subscription;

  observer: Subscriber<boolean|string>;

  message = '';
  title = '';
  value = '';
  placeholder = '';

  constructor(
    private _modalDialogService: ModalDialogService
  ) { }

  ngOnInit(): void {
    this.showModalSubscription = this._modalDialogService.showPromptSubject.subscribe(
      (promptContent: PromptContent) => {

        try {
          this.observer = promptContent.observer;
          this.message = promptContent.message.replace(/\\n/g, '\n').replace(/\n/g, '<br/>');
          this.title = promptContent.title;
          this.placeholder = promptContent.placeholder;
          this.value = promptContent.defaultValue;
          this.visible = true;

        } catch (ex) {
          console.log(ex);
        }

      }
    );
  }

  ngOnDestroy(): void {
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }
  }

  onOK(): void {
    this.observer.next(this.value);
    this.visible = false;
    this.message = '';
    this.title = '';
  }

  onCancel(): void {
    this.observer.next(false);
    this.visible = false;
    this.message = '';
    this.title = '';
  }

}

