import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EventServerService } from '../../services/event-server.service';
import { WEvent } from '../../data/event.model';
import { ModalDialogService } from '../../services/modal-dialog.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { PageComponent } from '../page/page.component';
import { UserAuthService } from '../../services/user-auth.service';
import { WSelect } from '../../data/field.model';

@Component({
  selector: 'wackadoo-backup-restore',
  templateUrl: './backup-restore.component.html',

})
export class BackupRestoreComponent extends PageComponent implements OnInit {

  archives: string [] = [];

  year: WSelect = null;

  @ViewChild('fileInput', { static: true }) fileInputElementRef: ElementRef;

  backupFileType: string [] = [];
  methods: any [] = [];

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.archives = this.eventServerService.getDataArchiveEventHandlers();
    for (const archive of this.archives) {
      this.backupFileType.push('xml');
      this.methods.push(this.eventServerService.getMethods(archive));
    }
    this.userInterfaceService.clearSelectedResources();

    const lastYear = (new Date()).getFullYear() - 1;
    this.year = new WSelect('year', String(lastYear - 3));
    this.year.select = '';
    for (let i = 0; i < 25; i++) {
      this.year.select += ((lastYear - i) + (i < 24 ? '|' : ''));
    }
  }

  backup(idx: number): void {
    const parms = {
      fileExtension: this.backupFileType[idx]
    };
    // console.log(this.selectedArchive, 'backup', parms);
    this.eventServerService.downloadFile(this.archives[idx], 'backup', parms);
  }

  clear(idx: number): void {

    this.modalDialogService.showConfirm(
      'Do you REALLY want to do that?',
      'WARNING! You Are About To Wipe '
        + (
            this.archives[idx] === 'DataArchives'
            ?
              'ALL Your'
            :
              'Your ' + this.archives[idx].substring(0, this.archives[idx].length - 12)
          )
        + ' Data Off Our Servers!'
    )
    .subscribe(
      (choice: boolean) => {
        if (choice) {
          this.eventServerService.fireEvent(this.archives[idx], 'clear', {}).subscribe(
            (event: WEvent) => {
              if (event.status === 'OK') {
                this.modalDialogService.showAlert('You have erased ALL your data!', 'Congratulations!');

              } else {
                this.modalDialogService.showError(event.message, 'Event Error Occurred', event.status);
              }
            }
          );
        }
      }
    );
  }

  archiveYear(idx: number): void {
    this.modalDialogService.showConfirm(
        'WARNING! This \'archive\' action will COMPLETELY erase it from our database!'
        + '\n'
        + 'You will want to safely store the file this generates, as it contains the ONLY copy of that data that you will have left.'
        + '\n\n'
        + 'Do you want to continue?'
      ,
      'You are about to pull the '
        + this.archives[idx].substring(0, this.archives[idx].length - 12)
        + ' data for ' + this.year.value + ' offline!'
    )
    .subscribe(
      (choice: boolean) => {
        if (choice) {
          const parms = {
            fileExtension: this.backupFileType[idx]
            , year: this.year.value
          };
          // console.log(this.archives[idx], 'archive', parms);
          this.eventServerService.downloadFile(this.archives[idx], 'archive', parms);
        }
      }
    );
  }

}
