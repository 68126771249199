import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { Globals } from 'src/app/client-core/services/global.service';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wackadoo-powered-by',
  templateUrl: './powered-by.component.html',
})
export class PoweredByComponent extends PageComponent implements OnInit, OnDestroy {

  appName = '';
  domain = '';

  screenTypeChangeSubscription: Subscription = null;

  constructor(
    public userAuthService: UserAuthService,
    public userInterfaceService: UserInterfaceService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.appName = Globals.thisApplication;
    this.domain = Globals.domain;

    this.screenTypeChangeSubscription = this.userInterfaceService.screenType.subscribe(
      (st: ScreenType) => {
        this.screenType = st;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.screenTypeChangeSubscription) {
      this.screenTypeChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

}
