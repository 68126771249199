import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-value-substance-over-style',
  templateUrl: './value-substance-over-style.component.html',

})
export class ValueSubstanceOverStyleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
