<span *ngIf="f">
    <span class="link" (click)="goToForeignEH();" wackadooStopPropagation *ngIf="mode === 'read'">
        {{ (f.isPopulated ? (f.displayValue ? f.displayValue : f.value) : nullDisplayValueLabel) }} 
    </span>
    <span *ngIf="mode === 'write'">
        <span *ngIf="f.displayComponent === 'typeahead'">
            <wackadoo-type-ahead
                [name]="f.name"
                [eventHandler]="foreignEHName"
                [placeholder]="(f.required ? '* ' : '') + (nullValueLabel ? nullValueLabel : '(type to search)')"
                [selectResourceSubject]="selectTypeAheadResourceSubject"
                [fieldsToSearch]="typeAheadFKeyFieldsToSearch"
                [externalSelection]="typeAheadSelectedResource"
                [changed]="f.changed"
                [disabled]="f.readOnly || fkeyResourceIsCurrentlySelected"
                [filterParms]="foreignKeyFilterParms"
            ></wackadoo-type-ahead>
        </span>
        <span *ngIf="f.displayComponent !== 'typeahead'">
            <select 
                class="form-control fillAvailableWidth"
                [name]="f.name"
                [title]="(title ? title : f.name)"
                size="1"
                [ngClass]="{'fieldChanged': f.changed}"
                [disabled]="f.readOnly || fkeyResourceIsCurrentlySelected"
                (change)="onFKeyInputChange($event)"
            >
                <option 
                    *ngFor="let value of selectOptionValues; let idx = index;"
                    value="{{value}}"
                    [selected]="value === f.value"
                >{{selectOptionLabels[idx]}}</option>
            </select>
        </span>
    </span>
</span>
