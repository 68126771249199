import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { WField } from '../../../data/field.model';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { User } from 'src/app/client-core/data/user.model';

@Component({
  selector: 'wackadoo-detail-content',
  templateUrl: './detail-content.component.html',

})
export class DetailContentComponent extends ResourceComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'DetailContentComponent';

  @Input() eventHandler: string;
  @Input() action: string;
  @Input() user: User;
  @Input() repositoryPageElementRef: ElementRef;

  fieldAPIs: WField [] = [];

  ngOnInit(): void {
    super.ngOnInit();

    this.fieldAPIs = this.eventServerService.getFieldDefinitionsInOrder(this.eventHandler);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
