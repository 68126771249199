<span *ngIf="f">
    <input 
        class="form-check-input"
        type="checkbox" 
        [title]="(title ? title : f.name)"
        [name]="f.name" 
        [value]="f.value"
        [checked]="f.value"
        [disabled]="f.readOnly || (mode === 'read')"
        (input)="onCheckBoxValueChange($event)"
        (blur)="forceValidValue($event)"
    />
</span>
