import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-policy-data-portability',
  templateUrl: './policy-data-portability.component.html',

})
export class PolicyDataPortabilityComponent implements OnInit {

  public isCollapsed = true;

  constructor() {
  }

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnInit(): void {
  }

}
