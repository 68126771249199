import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subscriber } from 'rxjs';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { ErrorModalContent } from './error-modal-content.model';

@Component({
  selector: 'wackadoo-error-modal',
  templateUrl: './error-modal.component.html',

})
export class ErrorModalComponent implements OnInit, OnDestroy {

  visible = false;

  showModalSubscription: Subscription;

  observer: Subscriber<void>;

  message = '';
  title = '';
  status = null;
  error = null;

  constructor(
    private _modalDialogService: ModalDialogService,
  ) { }

  ngOnInit(): void {
    this.showModalSubscription = this._modalDialogService.showErrorSubject.subscribe(
      (error: ErrorModalContent) => {
        this.observer = error.observer;
        this.message = error.message.replace(/\\n/g, '\n').replace(/\n/g, '<br/>');
        this.title = error.title;
        this.status = (error.status ? error.status : null);
        this.error = (error.error ? JSON.stringify(error.error) : null);
        this.visible = true;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }
  }

  onClose(): void {
    this.visible = false;
    this.message = '';
    this.title = '';
    this.status = '';
    this.error = '';
    this.observer.next();
  }

}
