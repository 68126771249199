import { Component } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';

@Component({
  selector: 'wackadoo-reg-app-detail-content',
  templateUrl: './reg-app-detail-content.component.html',
})
export class RegAppDetailContentComponent extends DetailContentComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'RegAppDetailContentComponent';

}
