		<div class="card">
			<div class="card-header">
				Data Portability Policy...
			</div>
			<div class="card-body">
				<p>Your data is your data. Though we will do our best to keep you as a satisfied customer, should you ever decide that you are through with us, 
				your data can be in your hands, and out of ours, at the click of a button.
				<a (click)="toggle()" [attr.aria-expanded]="!isCollapsed">(Read {{ isCollapsed ? 'more' : 'less' }}...)</a>
				</p>
				<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
					<div class="well">
						<p>OK, we lied. It's *two* clicks of a button.</p>
						<p>The first thing we built into our platform was the "Backup/Restore" mechanism, offering backup, restore and clear operations.</p>
						<p>Your admin user simply clicks the "backup" button to download all your data in a portable XML (or tab-delimited text) format, and then 
						clicks the "clear" button to wipe all your data off our servers.</p>
						<p>(You will want to be very, very careful with the "clear" button...)</p>
					</div>
				</div>				
			</div>
		</div>
