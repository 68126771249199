import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { Subscription, Subject } from 'rxjs';
import { ScreenType } from '../../../services/screen-type.enum';

@Component({
  selector: 'wackadoo-grid-control-bar',
  templateUrl: './grid-control-bar.component.html',
})
export class GridControlBarComponent implements OnInit, OnDestroy {

  @Input() eventHandler: string;
  @Input() parameters: any;
  @Input() reloadGridSubject: Subject<void>;

  @Input() disabled = false;

  @Input() showPageControl = true;
  @Input() showSortControl = true;
  @Input() showSearchBox = true;

  @Input() minSortScreenSize = 575;

  screenType: ScreenType;
  screenSize: number;

  private _screenTypeSubscription: Subscription;

  constructor(
    private _userInterfaceService: UserInterfaceService,
  ) {
  }

  ngOnInit(): void {
    // console.log('grid-control-bar.ngOnInit() - ' + this.eventHandler + '.' + this.action, this.parameters);

    this.screenType = this._userInterfaceService.screenType.getValue();
    this.screenSize = window.outerWidth;

    this._screenTypeSubscription = this._userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        this.screenType = screenType;
        this.screenSize = window.outerWidth;
      }
    );
  }

  ngOnDestroy(): void {
    if (this._screenTypeSubscription) {
      this._screenTypeSubscription.unsubscribe();
    }
  }

}
