import { Component } from '@angular/core';
import { BinaryComponent } from '../binary.component';

@Component({
  selector: 'wackadoo-field-binary-video',
  templateUrl: './binary-video.component.html',

})
export class BinaryVideoComponent extends BinaryComponent {
}
