<span *ngIf="f">
    <span *ngIf="mode === 'read'">
        <span *ngIf="f.value !== ''">
            <a href="tel:{{ f.value }}" target="_blank" wackadooStopPropagation>{{ f.value }}</a> 
        </span>
        <span *ngIf="(f.value === '')">
            {{ f.value }}
        </span>
    </span>
    <span *ngIf="mode === 'write'">
        <input 
            class="form-control width-unset shrinkToFitWidth"
            type="text" 
            [ngClass]="{'fieldChanged': f.changed}"
            [placeholder]="(f.required ? '* ' : '') + (title ? title : f.name)"
            [name]="f.name" 
            [title]="augmentTitle()"
            [size]="(f.length && (f.length < size) ? f.length : size)"
            [required]="f.required"
            [value]="f.value" 
            [pattern]="f.pattern"
            [maxLength]="f.length"
            [disabled]="f.readOnly"
            (input)="onInputChange($event)"
            (blur)="forceValidValue($event)"
        />
    </span>
</span>
