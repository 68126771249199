<div *ngIf="visible && resource" wackadooFileDragOver [mode]="mode">
    <div class="modal-backdrop" (click)="onClose()"></div>
    <div class="modal-box">
        <div class="d-flex justify-content-around marginTopQuarterEm">
            <div *ngIf="(screenType !== 'phone')">
                <div class="d-flex">
                    <div class="min-width-8-em right">
                        To:
                    </div>
                    <div class="marginLeftHalfEm fullWidth">
                        <wackadoo-field [f]="resource.to" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="min-width-8-em right">
                        Reply To:
                    </div>
                    <div class="marginLeftHalfEm fullWidth">
                        <wackadoo-field [f]="resource.from" [mode]="mode" [onChange]="fromEMailChangeSubject"></wackadoo-field>
                        <div class="small">
                            <div *ngIf="(matchingSMTPServers === null) || (matchingSMTPServers.length !== 1)" title="If you wish to send email directly from your account, please contact technical support.">
                                (In order to avoid spam filters, the email will be "from" <i>{{ domain }}</i>, but in the name of <i>{{ user.accountName }}</i>.)
                            </div>
                            <div *ngIf="(matchingSMTPServers !== null) && (matchingSMTPServers.length === 1)">
                                (<span *ngFor="let smtpServer of matchingSMTPServers; let i = index;"><span *ngIf="(i > 0)">, </span>Found SMTP server: {{smtpServer.name.value}}</span>)
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="min-width-8-em right">
                        CC:
                    </div>
                    <div class="marginLeftHalfEm fullWidth">
                        <wackadoo-field [f]="resource.cc" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="min-width-8-em right">
                        BCC:
                    </div>
                    <div class="marginLeftHalfEm fullWidth">
                        <wackadoo-field [f]="resource.bcc" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
                <div class="d-flex marginTopWholeEm">
                    <div class="min-width-8-em right">
                        Subject:
                    </div>
                    <div class="marginLeftHalfEm fullWidth">
                        <wackadoo-field [f]="resource.subject" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
            </div>
            <div class="small" *ngIf="(screenType === 'phone')">
                <div>
                    To: <wackadoo-field [f]="resource.to" [mode]="mode"></wackadoo-field>
                </div>
                <div>
                    Reply To: <wackadoo-field [f]="resource.from" [mode]="mode"></wackadoo-field>
                </div>
                <div class="small">
                    <div *ngIf="(matchingSMTPServers === null) || (matchingSMTPServers.length !== 1)" title="If you wish to send email directly from your account, please contact technical support.">
                        (In order to avoid spam filters, the email will be "from" <i>{{ domain }}</i>, but in the name of <i>{{ user.accountName }}</i>.)
                    </div>
                    <div *ngIf="(matchingSMTPServers !== null) && (matchingSMTPServers.length === 1)">
                        (<span *ngFor="let smtpServer of matchingSMTPServers; let i = index;"><span *ngIf="(i > 0)">, </span>Found SMTP server: {{smtpServer.name.value}}</span>)
                    </div>
                </div>
                <div>
                    CC: <wackadoo-field [f]="resource.cc" [mode]="mode"></wackadoo-field>
                </div>
                <div>
                    BCC: <wackadoo-field [f]="resource.bcc" [mode]="mode"></wackadoo-field>
                </div>
                <div class="marginTopWholeEm">
                    <div>
                        Subject:
                    </div>
                    <div>
                        <wackadoo-field [f]="resource.subject" [mode]="mode"></wackadoo-field>
                    </div>
                </div>
            </div>
            <div *ngIf="(matchingSMTPServers !== null) && (matchingSMTPServers.length === 1)">
                <wackadoo-field [f]="resource.password" [mode]="mode"></wackadoo-field>
            </div>
            <div>
                <div class="card marginTop0"
                    *ngIf="(screenType !== 'phone')"

                    wackadooFileDrop
                    [fileDropSubject]="attachmentDropSubject"
                    [validExtensions]="resource.fileContent.validExtensions"
                    [maxFileSizeMB]="resource.fileContent.maxFileSizeMB"
                >
                    <div class="marginLeftHalfEm paddingQuarterEm">
                        <div class="floatGroup">
                            <div class="floatLeft">Attachment File (drag-n-drop here...)</div>
                            <div class="floatRight">
                                <button type="button" (click)="removeAttachment()" title="Remove" data-abbrev="" class="actionButton removeAttachment"><span class="fas fa-trash-alt" aria-hidden="true"></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body italic">
                        <span class="hidden">
                            <wackadoo-field [f]="resource.fileContent" [mode]="mode"></wackadoo-field>
                        </span>
                        <wackadoo-file-details [fileContentField]="resource.fileContent" ></wackadoo-file-details>
                        <div class="smallish">
                            Note: We do NOT store this attachment (or the email...) on our servers.
                        </div>
                    </div>
                </div>
                <div class="center paddingTopHalfEm">
                    <div class="btn-group btn-group-justified" >
                        <div class="btn-group">
                            <button type="button" (click)="toggleTimeZone()" [title]="timeZoneButtonTitle" data-abbrev="TimeZone" class="btn btn-secondary actionButton"><span class="fas fa-globe" aria-hidden="true"></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center marginTopWholeEm">
            <div>
                <wackadoo-field-html
                    [f]="resource.message" 
                    [mode]="mode" 

                    wrapperElementSelector=".modal-box"
                    [editorHeightFudgeFactor]="((screenType === 'phone') ? -54 : -108)"

                    wackadooAutoFocus

                ></wackadoo-field-html>
                <ng-container *ngIf="(screenType !== 'phone')">
                    <div class="italic" *ngIf="mode==='read'">
                        Note that the non-standard &lt;insert&gt; and &lt;resourceBlock&gt; tags are only visible when you toggle to look at the XHTML directly.
                    </div>
                    <div class="italic" *ngIf="mode==='write'">
                        <div>
                            Please do not edit the raw XHTML unless you know the difference between HTML and XHTML. If present, do not delete the non-standard &lt;resourceBlock&gt; tag.
                        </div>
                        <div>
                            Note that the non-standard &lt;insert&gt; tag should ONLY contain the name of the other email template to insert.
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <!--  
            We standardize on putting the button-bar at the bottom for consistency's sake.
        -->
        <div class="center paddingTopHalfEm">
            <div class="btn-group btn-group-justified" >
                <div class="btn-group">
                    <button type="button" (click)="send()" title="Send EMail" data-abbrev="Send" class="btn btn-secondary actionButton send"><span class="fas fa-envelope" aria-hidden="true"></span></button>
                </div>
                <div class="btn-group">
                    <button type="button" (click)="toggle()" [title]="toggleButtonTitle" data-abbrev="Toggle" class="btn btn-secondary actionButton toggle"><span class="fas fa-sync" aria-hidden="true"></span></button>
                </div>
                <div class="btn-group" *ngIf="canModifyEMailTemplates()">
                    <button type="button" (click)="editEMailTemplate()" title="Edit EMail Template" data-abbrev="Template" class="btn btn-secondary actionButton editEMailTemplate" ><span class="fas fa-pencil-alt" aria-hidden="true"></span></button>
                </div>
                <div class="btn-group" *ngIf="canModifyEMailTemplates()">
                    <button type="button" (click)="cloneEMailTemplate()" title="Clone EMail Template" data-abbrev="Clone" class="btn btn-secondary actionButton cloneEMailTemplate" ><span class="fas fa-clone" aria-hidden="true"></span></button>
                </div>
                <div class="btn-group">
                    <button type="button" (click)="cancel()" title="Cancel" data-abbrev="" class="btn btn-secondary actionButton cancel"><span class="fas fa-times" aria-hidden="true"></span></button>
                </div>
            </div>
        </div>
    </div>
</div>

