<div class="marginWholeEm">
  <div class="" [ngClass]="{'d-flex justify-content-around' :  screenType !== 'phone'}">
    <div class="card" *ngIf="resource">
      <div class="card-header">
        ShoppingCartLogEntry
      </div>
      <div class="card-body">
        <table>
            <tr *ngIf="!isCorporateSponsor">
              <td class="bold smallish top">
                Name
              </td>
              <td>
                <div class="d-flex" [ngClass]="{'justify-content-around' : (mode === 'write'), 'flex-row' : (mode === 'read')}">
                  <div>
                    <wackadoo-field [f]="resource.firstName" [mode]="mode" [size]="25"></wackadoo-field>
                  </div>
                  <div class="marginLeftHalfEm">
                    <wackadoo-field [f]="resource.lastName" [mode]="mode" [size]="25"></wackadoo-field>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="isCorporateSponsor">
              <td class="bold smallish top">
                Company
              </td>
              <td>
                <div>
                    <wackadoo-field [f]="resource.company" [mode]="mode" [size]="25"></wackadoo-field>
                </div>
              </td>
            </tr>
            <tr>
              <td class="bold smallish top">
                Address
              </td>
              <td>
                <div>
                    <wackadoo-field [f]="resource.address" [mode]="mode" [size]="25"></wackadoo-field>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <wackadoo-field [f]="resource.city" [mode]="mode"></wackadoo-field>
                  </div>
                  <div class="marginLeftHalfEm">
                    <wackadoo-field [f]="resource.state" [mode]="mode" [size]="2"></wackadoo-field>
                  </div>
                  <div class="marginLeftHalfEm">
                    <wackadoo-field [f]="resource.postalCode" [mode]="mode" [size]="6"></wackadoo-field>
                  </div>
                </div>
                <div>
                  <wackadoo-field [f]="resource.country" [mode]="mode" [size]="2"></wackadoo-field>
                </div>
              </td>
            </tr>
            <tr>
              <td class="bold smallish top">
                  Contact Info
              </td>
              <td>
                <div class="d-flex" [ngClass]="{'justify-content-around' : (mode === 'write'), 'flex-row' : (mode === 'read')}"  *ngIf="isCorporateSponsor">
                  <div>
                    <wackadoo-field [f]="resource.firstName" [mode]="mode" [size]="25"></wackadoo-field>
                  </div>
                  <div class="marginLeftHalfEm">
                    <wackadoo-field [f]="resource.lastName" [mode]="mode" [size]="25"></wackadoo-field>
                  </div>
                </div>
                <div>
                  <wackadoo-field [f]="resource.email" [mode]="mode" [size]="25"></wackadoo-field>
                </div>
                <div>
                  <wackadoo-field [f]="resource.phone" [mode]="mode" [size]="25"></wackadoo-field>
                </div>
              </td>
            </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header d-flex justify-content-between">
      ShoppingCartItem
    </div>
    <div class="card-body">
      <div class="min-height-10-em">
        <div class="d-flex justify-content-between align-items-center border-bottom">
          <div class="d-flex flex-row align-items-center">
            <div class="marginHalfEm width-5-em center">
              <wackadoo-field [f]="resource.itemQuantity" mode="read"></wackadoo-field>
            </div>
            <div class="marginHalfEm">
              <wackadoo-field [f]="resource.itemName" mode="read"></wackadoo-field>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="marginQuarterEm">
              <span [ngClass]="{small:(screenType === 'phone')}">
                ( {{resource.itemQuantity.value}} x <wackadoo-field [f]="resource.itemPrice" mode="read"></wackadoo-field>) = <wackadoo-field [f]="resource.itemExtendedPrice" mode="read"></wackadoo-field>
              </span>
            </div>
          </div>
        </div>
        <div class="marginHalfEm">
          <!-- wackadooResourcePlaceholder places the component BELOW this div... -->
          <div wackadooResourcePlaceholder [componentClassName]="shoppingCartComponentClassName" [resource]="resource" mode="read">
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between marginHalfEm border-top">
        <div>
            Created: <wackadoo-field [f]="resource.created" mode="read"></wackadoo-field>
        </div>
        <div>
            Submitted: <wackadoo-field [f]="resource.submitted" mode="read"></wackadoo-field>
        </div>
        <div>
            Modified: <wackadoo-field [f]="resource.modified" mode="read"></wackadoo-field>
        </div>
      </div>
    </div>
  </div>
</div>
