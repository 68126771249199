<div *ngIf="selectedFormFiller">
    <div class="card">
        <div class="card-header d-flex justify-content-between">
            <div [ngClass]="{'d-flex':(screenType !== 'phone')}">
                <div class="d-flex">
                    <div>
                        Format:
                    </div>
                    <div class="marginLeftHalfEm">
                        <wackadoo-field-foreign-key [f]="ffID" mode="write" [onChange]="onFormFillerChange"></wackadoo-field-foreign-key>
                    </div>
                </div>
                <div class="d-flex" [ngClass]="{marginLeftWholeEm:(screenType !== 'phone')}">
                    <div class="btn-group marginLeftHalfEm">
                        <button type="button" class="btn btn-secondary small padding3" (click)="add()"><span class="fas fa-plus" aria-hidden="true"></span> Add</button>
                        <button type="button" class="btn btn-secondary small padding3" (click)="rename()"><span class="fas fa-pencil-alt" aria-hidden="true"></span> Re-Name</button>
                        <button type="button" class="btn btn-secondary small padding3" (click)="copy()"><span class="fas fa-copy" aria-hidden="true"></span> Copy</button>
                        <button type="button" class="btn btn-secondary small padding3" (click)="delete()"><span class="fas fa-trash" aria-hidden="true"></span> Delete</button>
                        <button type="button" class="btn btn-secondary small padding3" (click)="export()" *ngIf="user.isAdminOrAbove"><span class="fas fa-file-export" aria-hidden="true"></span> Export</button>
                    </div>
                    <div class="btn-group marginLeftWholeEm" *ngIf="user.isAdminOrAbove">
                        <button type="button" class="btn btn-secondary small padding3" (click)="reload()"><span class="fas fa-refresh" aria-hidden="true"></span> Re-Load Defaults</button>
                    </div>
                </div>
            </div>
            <div class="unbold marginLeftWholeEm">
                <wackadoo-field [f]="selectedFormFiller.ffDescription" mode="write" [size]="100" [onChange]="onFormFillerDefnChange"></wackadoo-field>
            </div>
            <div class="unbold marginLeftWholeEm">
                <wackadoo-field [f]="selectedFormFiller.ffResourceType" mode="read"></wackadoo-field>
            </div>
        </div>
        <div class="card-body" *ngIf="selectedFormFiller.ffID.value">
            <div class="row">
                <div class="col-sm-3">
                    <div class="marginLeftHalfEm marginRightHalfEm d-flex justify-content-between">
                        <div class="bold">
                            Available Fields
                        </div>
                        <div class="smallish italic">
                            Click to select.  
                        </div>
                    </div>
                    <div class="border paddingHalfEm heightViewPort35 scrollable-y">
                        <div class="row" *ngFor="let fieldName of availableFieldNames;">
                            <div class="col-sm-12 rollover" [ngClass]="{selected: fieldIsDefined(fieldName)}" (click)="loadSelectedFormFillerFields(fieldName)">
                                {{ fieldName }}
                            </div>
                        </div>
                    </div>
                    <div class="marginTopQuarterEm marginLeftWholeEm smallish italic">
                        Defined fields are <span class="selected">highlighted</span>.
                    </div>
                </div>
                <div class="col-sm-9 top">
                    <div class="card">
                        <div class="card-header">
                            Cut-N-Paste Example Content Here...
                        </div>
                        <div class="card-body">
                            <textarea [(ngModel)]="content" (input)="parseContent()" class="nowrap no-resize heightViewPort25 fillAvailableWidth"></textarea>
                            <div class="d-flex marginLeftHalfEm" *ngIf="selectedFormFillerFields.length > 0">
                                <div class="bold">
                                    {{selectedFieldName}}:
                                </div>
                                <div class="marginLeftHalfEm italic">
                                    <wackadoo-field [f]="exampleResource[selectedFieldName]" mode="read"></wackadoo-field>
                                </div>
                            </div>
                            <div class="marginLeftHalfEm marginTopHalfEm" *ngIf="catchAllFieldName && (truncatedContent.length > 0)">
                                <div class="bold">
                                    Please Note: The parsed {{catchAllFieldName}} value for this content is too long! The truncated/omitted content is shown here:
                                </div>
                                <div class="italic">
                                    {{truncatedContent}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="marginTopWholeEm marginHalfEm" *ngIf="selectedFormFillerFields.length > 0">
                <div class="paddingTopHalfEm border-top d-flex">
                    <div class="bold">
                        <wackadoo-field [f]="selectedFormFillerFields[0].fffName" mode="read"></wackadoo-field>
                    </div>
                    <div class="italic marginLeftWholeEm" *ngIf="selectedFormFillerFields[0].fffID.isNull">
                        (field is not yet defined...)
                    </div>
                </div>
                <div *ngFor="let r of selectedFormFillerFields; let idx = index">
                    <wackadoo-form-filler-field [resource]="r" mode="write" [formFillerFieldEH]="formFillerFieldEH" [refreshFieldList]="refreshFieldList"></wackadoo-form-filler-field>
                </div>
            </div>
        </div>
    </div>
</div>
