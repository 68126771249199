import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-new-customer-cancelled',
  templateUrl: './new-customer-cancelled.component.html',
})
export class NewCustomerCancelledComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
