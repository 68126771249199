import { WEvent } from './event.model';
import { EventServerService } from '../services/event-server.service';
import { ModalDialogService } from '../services/modal-dialog.service';
import { Subject } from 'rxjs';
import { Globals } from '../services/global.service';
import { UtilityLibService } from '../services/utility-lib.service';

export class WField {

    name: string;
    type: string;
    text: string = null;

    eventHandler: string;

    value?: any = null;
    changed?: boolean;

    auto?: boolean;
    key?: boolean;
    displayValue?: string;

    number?: number;

    detailOnly?: boolean;

    defaultSort?: boolean;
    defaultSortDirection?: number;

    required?: boolean;
    readOnly?: boolean;

    select?: string;
    selectLabels?: string;
    default?: any;
    size?: number;

    // for string values...

    length?: number;
    minLength?: number;

    pattern?: string;
    step?: number;

    encrypt?: boolean;
    hash?: boolean;

    // for numeric values...

    min?: number;
    max?: number;
    precision?: number;

    // for date fields

    noTimestamp?: boolean;

    // for byte[] fields

    fileNameField?: string;
    fileTypeField?: string;
    fileSizeField?: string;
    validExtensions?: string;

    maxFileSizeMB?: number;

    displayComponent?: string;

    // IMPORTANT NOTE:
    // To save bandwidth, the server ONLY sends binary byte[] content on "getFile" Events!
    // Instead of setting the field type to "byte[]", and populating the field value with
    // the base64 string of the actual binary content (which is what we would normally
    // expect) the server will tag the field with "binaryContentUrl=true", set the field
    // "type" attr to "String", insert a relative URL that can be used to implement
    // a "getFile" Event against the same ResourceRepository EventHandler.
    binaryContentUrl?: boolean;
    // If it does, then these fields will have the proper values for the given resource...
    fileName?: string;
    fileType?: string;
    fileSize?: number;

    // for foreign key and other DB constraints

    unique?: boolean;
    constraint?: boolean;
    foreignKey?: boolean;
    foreignType?: string;
    onDeleteBehavior?: string;
    // a UI-side only mechanism for filtering fkey lookups...
    foreignKeyFilterParms?: any;

    // The catch-all for any other fields we might want to add. The "any"
    // allows us to put other named things (i.e. the methods below...) in the Field.
    [name: string]: any;

    _errorMessage: string;

    static factory(type: string, name: string, rawTextValue: string, eventHandler: string, apiFieldDefn?: any, fieldToClone?: any): WField {

        // const debug = (name === 'attorneyID');

        // if (debug) { console.log('field.factory() - enter', name, type, JSON.stringify(rawTextValue), eventHandler, apiFieldDefn, fieldToClone, JSON.stringify(fieldToClone)); }

        if (!apiFieldDefn) {
            apiFieldDefn = {};
        }

        if (!fieldToClone) {
            fieldToClone = {};
        }

        // if there is no rawTextValue, then we check for a default value...
        const textValue = ((rawTextValue === null) && (apiFieldDefn.default !== null)) ? apiFieldDefn.default : rawTextValue;

        // if (debug) { console.log('field.factory()', typeof rawTextValue, rawTextValue, typeof apiFieldDefn.default, apiFieldDefn.default, typeof textValue, textValue, (textValue === 'true')); }

        let fo: WField = null;

        // supported displayComponent values for
        //    string: (none/default), email, url
        //    byte[]: (none/default), text, html, image
        const displayComponent = (apiFieldDefn.displayComponent ? apiFieldDefn.displayComponent : '');

        // we handle fkeys and selects all by themselves...

        if (apiFieldDefn.select) {
            fo = new WSelect(name, textValue, eventHandler, apiFieldDefn, fieldToClone) ;
        } else if (apiFieldDefn.foreignKey) {
            fo = new WForeignKey(name, (textValue && (textValue !== '') && (textValue !== 'null') ? Number(textValue) : Globals.MAGIC_NULL_FKEY_VALUE), eventHandler, apiFieldDefn, fieldToClone) ;
        } else {

            // We resolve the "type" for byte[] fields here, to simplify the switch() statement below...
            if (fieldToClone.binaryContentUrl && (fieldToClone.type === 'String')) {
                type = 'byte[]';
            }

            switch (type) {
                case 'String' :
                    // valid displayComponent: (none/default), email, url
                    const encrypted = (apiFieldDefn.encrypt);
                    const unique = (apiFieldDefn.unique);
                    if (encrypted && (name.toLowerCase().indexOf('password') > -1)) {
                        fo = new WPassword(name, textValue, eventHandler, apiFieldDefn, fieldToClone) ;
                    } else if (encrypted) {
                        fo = new WEncrypted(name, textValue, eventHandler, apiFieldDefn, fieldToClone) ;
                    } else if (unique) {
                        fo = new WUnique(name, textValue, eventHandler, apiFieldDefn, fieldToClone) ;
                    } else if (displayComponent.toLowerCase() === 'email') {
                        fo = new WEmail(name, textValue, eventHandler, apiFieldDefn, fieldToClone) ;
                    } else if (displayComponent.toLowerCase() === 'phone') {
                        fo = new WPhone(name, textValue, eventHandler, apiFieldDefn, fieldToClone) ;
                    } else if (displayComponent.toLowerCase() === 'url') {
                        fo = new WURL(name, textValue, eventHandler, apiFieldDefn, fieldToClone) ;
                    } else if (
                                (displayComponent.toLowerCase() === 'hex')
                                || (displayComponent.toLowerCase() === 'rgb')
                                || (displayComponent.toLowerCase() === 'hsl')
                    ) {
                        fo = new WColor(name, textValue, eventHandler, apiFieldDefn, fieldToClone) ;
                    } else {
                        fo = new WString(name, textValue, eventHandler, apiFieldDefn, fieldToClone) ;
                    }
                    break;
                case 'Boolean' :
                    fo = new WBoolean(name, ('' + textValue === 'true'), eventHandler, apiFieldDefn, fieldToClone) ;
                    break;
                case 'Byte' :
                    fo = new WByte(name, (textValue ? Number(textValue) : null), eventHandler, apiFieldDefn, fieldToClone) ;
                    break;
                case 'Short' :
                    fo = new WShort(name, (textValue ? Number(textValue) : null), eventHandler, apiFieldDefn, fieldToClone) ;
                    break;
                case 'Integer' :
                    fo = new WInteger(name, (textValue ? Number(textValue) : null), eventHandler, apiFieldDefn, fieldToClone) ;
                    break;
                case 'Long' :
                    fo = new WLong(name, (textValue ? Number(textValue) : null), eventHandler, apiFieldDefn, fieldToClone) ;
                    break;
                case 'Float' :
                    fo = new WFloat(name, (textValue ? Number(textValue) : null), eventHandler, apiFieldDefn, fieldToClone) ;
                    break;
                case 'Double' :
                    fo = new WDouble(name, (textValue ? Number(textValue) : null), eventHandler, apiFieldDefn, fieldToClone) ;
                    break;
                case 'Date' :
                    fo = new WDate(name, (textValue ? textValue : null), eventHandler, apiFieldDefn, fieldToClone) ;
                    break;
                case 'byte[]' :
                    fo = new WBinary(name, textValue, eventHandler, apiFieldDefn, fieldToClone) ;
                    break;
                default:
                    fo = new WField(name, textValue, 'Unknown', eventHandler, apiFieldDefn, fieldToClone) ;
                    break;
            }
        }

        // if (debug) { console.log('field.factory() - exit', fieldToClone, fo); }

        return fo;
    }

    constructor(name: string, value: any, type: string, eventHandler?: string, apiFieldDefn?: any, fieldToClone?: any) {
        value = typeof value !== 'undefined' ? value : null;

        // const debug = (name === 'attorneyID');

        // if (debug) { console.log('field.constructor() - enter', name, type, value, eventHandler, apiFieldDefn, fieldToClone, JSON.stringify(fieldToClone)); }

        if (apiFieldDefn) {
            for (const [attr, val] of Object.entries(apiFieldDefn)) {
                let typedValue: any = val;
                if (('' + val) === 'true') {
                    typedValue = true;
                } else if (('' + val) === 'false') {
                    typedValue = false;
                } else if ((('' + val).trim() !== '') && !isNaN(val as any)) {
                    typedValue = Number(val);
                }
                // if (debug) { console.log('from field defn: ' + name + '.' + attr, val, typeof val, typedValue, typeof typedValue); }
                this[attr] = typedValue;
            }
        }

        if (fieldToClone) {
            for (const [attr, val] of Object.entries(fieldToClone)) {
                let typedValue: any = val;
                if (('' + val) === 'true') {
                    typedValue = true;
                } else if (('' + val) === 'false') {
                    typedValue = false;
                } else if ((('' + val).trim() !== '') && !isNaN(val as any)) {
                    typedValue = Number(val);
                }
                // if (debug) { console.log('field.constructor() - from clone field: ' + name + '.' + attr, val, typeof val, typedValue, typeof typedValue); }
                this[attr] = typedValue;
            }
        }

        // we do NOT want to over-write these in the code above!!! (especially type, which
        // we worked so hard to set in the sub-classes below, and in the factory above...)

        // if (debug) { console.log('field.constructor()', type, 'value', value, ', type ', typeof value, ', value', value, typeof value); }

        this.name = name;
        // If we do NOT have a value, but there IS a non-null default, set it...
        if ((value === null) && (typeof this.default !== 'undefined') && (this.default !== null)) {
            // if (debug) { console.log('field.constructor() - setting default value: [' + this.default + ']', 'incoming value: [' + value + ']'); }
            this.value = this.default;
        } else {
            this.value = value;
        }

        this.type = type;
        this.eventHandler = eventHandler;

        // if a displayValue refers to a null value, then we do NOT include that in the UX...

        if (typeof this.displayValue === 'string') {
            this.displayValue = this.displayValue.replace('null ', '').replace(' null', '').trim();
        }

        // if (debug) { console.log('field.constructor() - exit', this.name + ' is a ' + type, this, new Error().stack); }
    }

    get isNull(): boolean {
        const flag = (
            (typeof this.value === 'undefined')
            || (this.value === null)
            || ((this.foreignKey === true) && (Number(this.value) === Globals.MAGIC_NULL_FKEY_VALUE))
        );
        return flag;
    }

    get isPopulated(): boolean {
        const empty = (this.isNull || (this.value === ''));
        return !empty;
    }

    convertToTypeAndSetFieldValue(value: any): void {

        switch (this.type) {

            case 'Boolean':
                const flag = (
                    (!isNaN(value) && (value > 0))
                    || (String(value).toLowerCase() === 'true')
                    || (String(value).toLowerCase() === 'yes')
                );
                this.value = Boolean(flag);
                break;

            case 'Integer':
            case 'Long':
            case 'Short':
            case 'Byte':
            case 'Float':
            case 'Double':
                this.value = Number(value);
                break;

            case 'String':
            case 'Password':
            case 'Unique':
            case 'Email':
            case 'URL':
            case 'Color':
            case 'Binary':  // Base64 is string content...
            case 'Date':    // Date is maintained internally as a string, e.g. "2024-11-13T12:34:56Z"
            default:
                this.value = value;
        }

        // console.log('convertToTypeAndSetFieldValue()', this.name, this.type, value, typeof(value), ' -->', this.value, typeof(this.value));

    }

    // We left this as a single base-class function instead of spreading the logic out amongst
    // the various Field sub-classes - which WOULD have been the PROPER thing to do - because
    // this is the same logic used in the equivalent method on the server. So if it passes here
    // in javascript/typescript, it will pass there in java in Resource.isValidValue()

    isValidValue(value: any): boolean {
        value = typeof value !== 'undefined' ? value : this.value;

        if (this.required && (value === null)) {
            return false;
        }

        if (value === null) {
            return true;
        }

        // clear out the error message
        this._errorMessage = '';

        // TODO: Need to validate input for Boolean & Date values! (Is there a built-in/default calendar selector for Date?)

        let result = true;
        try {
            // console.log('Field.isValidValue(' + this.name + ', [' + value + ']) - ' + (this.type));

            // these all came from the field definition on the server...
            const fieldType = this.type;
            const fieldLength = this.length;
            const fieldMinLength = this.minLength;
            const fieldPattern = (this.pattern ? this.pattern : null);
            const fieldKey = this.key;
            const fieldEncrypt = this.encrypt;
            const fieldAuto = this.auto;
            const fieldMin = this.min;
            const fieldMax = this.max;
            const fieldSelect = this.select;

            // process the fieldType and fieldKey for all fields...

            // console.log('Field.isValidValue() - about to check key: ' + fieldKey);
            if (fieldKey) {
                // Almost any field can be a key...
                // But you can only really SET this value when
                // you are creating it for the first time.
                // Of course, it may have come back from the server...
                if ((fieldType === 'Binary') || (fieldType === 'Boolean')) {
                    this._errorMessage = fieldType + ' fields cannot be a key field.';
                    result = false;
                }
            }

            if (fieldSelect) {
                const goodValues: string [] = fieldSelect.split('|');
                // this allows for a way to clear the selection...
                goodValues.push('');
                let flag = false;
                for (const goodValue of goodValues) {
                    // console.log('checking [' + value + '] against [' + goodValue + '] : equal? ' + (value === goodValue));
                    if (value === goodValue) {
                        // console.log('good match!');
                        flag = true;
                        break;
                    }
                }
                this._errorMessage = (flag ? '' : 'Invalid select value: ' + value + '\n\n(Must be one of these: ' + fieldSelect + ')');

                result = flag;
            }

            // process the fieldLength and fieldPattern for String fields...
            if ((fieldType === 'String') || (fieldType === 'Password') || (fieldType === 'Unique') || (fieldType === 'Email') || (fieldType === 'URL') || (fieldType === 'Color')) {

                // console.log('Field.isValidValue() - about to check String length: ' + fieldLength);
                if (fieldLength) {
                    if ((value.length < 0) || (fieldLength < value.length)) {
                        this._errorMessage = 'Value may be at most ' + fieldLength + ' characters long: ' + value;
                        result = false;
                    }
                }
                // console.log('Field.isValidValue() - about to check min String length: ' + fieldMinLength);
                if (fieldMinLength) {
                    if ((value.length < 0) || (fieldMinLength > value.length)) {
                        this._errorMessage = 'Value must be at least ' + fieldMinLength + ' characters long: ' + value;
                        result = false;
                    }
                }
                // note that ANY zero length field with a pattern is "valid",
                // which allows us to empty out fields with regex patterns...
                // (This empty string is the ResourceRepository.MAGIC_NULL_DATE_VALUE
                // in the server code...)
                const MAGIC_NULL_DATE_VALUE = '';
                if (fieldPattern && (fieldPattern !== '')) {
                    if (value !== MAGIC_NULL_DATE_VALUE) {
                        const matches = value.match(new RegExp(fieldPattern));
                        // console.log('Field.isValidValue() - Non-zero-length value "' + value + '"', matches, 'pattern: ' + fieldPattern);
                        if (!matches || !matches.includes(value)) {
                            this._errorMessage = 'Value does not match required pattern (' + fieldPattern + '): ' + value;
                            result = false;
                        }
                    }
                }
            }
            // process the fieldEncrypt for String and byte[] fields...
            if ((fieldType === 'String') || (fieldType === 'Password') || (fieldType === 'Unique') || (fieldType === 'Email') || (fieldType === 'URL') || (fieldType === 'Binary')) {
                // Almost any String or byte[]field can be encrypted...
                // But you can only really SET this value when you are
                // creating it for the first time.
                // (And encrypted values should NEVER be sent back from
                // server - either as encrypted strings, and certainly
                // not as clear text!)

                // console.log('isValidValue() - about to check String/byte[] encrypt: ' + fieldEncrypt);

                if (fieldEncrypt) {
                    // NOP (take the default = true...)
                }
            }
            if (fieldType === 'Boolean') {
                // console.log('checking Boolean:', value, typeof value, value.toLowerCase());
                if (typeof value === 'string') {
                    if ((value.toLowerCase() !== 'true')
                            && (value.toLowerCase() !== 'false')) {
                        this._errorMessage = 'Invalid boolean value: ' + value;
                        result = false;
                    }
                } else if (typeof value !== 'boolean') {
                    result = false;
                }
            }
            if (fieldType === 'Date') {
                if ((value !== '') && !Date.parse(value)) {
                    this._errorMessage = 'Invalid Date string: ' + value;
                    result = false;
                }
            }

            // process the fieldAuto for Integer and Long fields...
            if ((fieldType === 'Integer') || (fieldType === 'Long')) {
                // Any Integer or Long field can be auto generated by
                // server...
                // You can't really SET this value - even when you are
                // creating the Resource for the first time.
                // (Of course, this value may have come back from the
                // server...)
                // console.log('Field.isValidValue() - about to check Integer/Long auto: ' + fieldAuto);
                if (fieldAuto) {
                    this._errorMessage = 'You may not set this value. It is defined as "auto"-generated by the server.';
                    result = false;
                }
            }
            // console.log('Field.isValidValue() - about to check physical max limits for numerics...');

            // check physical max values for fields... (per Java 7...)
            if (fieldType === 'Long') { // 64 bits
                // if ((value < -9223372036854775808)
                //         || (9223372036854775807 < value)) {
                // 9007199254740992 is the maximum numeric literal value in TypeScript...
                if ((value < -9007199254740991)
                        || (9007199254740991 < value)) {
                    // this._errorMessage = 'Value of type ' + fieldType + ' out of range (-9223372036854775808 to 9223372036854775807): ' + value;
                    this._errorMessage = 'Value of type ' + fieldType + ' out of TypeSCript range (-9007199254740991 to 9007199254740991): ' + value;
                    result = false;
                }
            }
            if (fieldType === 'Integer') { // 32 bits
                if ((value < -2147483648) || (2147483647 < value)) {
                    this._errorMessage = 'Value of type ' + fieldType + ' out of range (-2147483648 to 2147483647): ' + value;
                    result = false;
                }
            }
            if (fieldType === 'Short') { // 16 bits
                if ((value < -32768) || (32767 < value)) {
                    this._errorMessage = 'Value of type ' + fieldType + ' out of range (-32768 to 32767): ' + value;
                    result = false;
                }
            }
            if (fieldType === 'Byte') { // 8 bits
                if ((value < -128) || (127 < value)) {
                    this._errorMessage = 'Value of type ' + fieldType + ' out of range (-128 to 127): ' + value;
                    result = false;
                }
            }
            if (fieldType === 'Float') {
                // don't bother to check physical limits...
            }
            if (fieldType === 'Double') {
                // don't bother to check physical limits...
            }
            // process the fieldAuto, fieldMin and fieldMax for numeric fields...
            if ((fieldType === 'Integer') || (fieldType === 'Long')
                    || (fieldType === 'Short') || (fieldType === 'Byte')
                    || (fieldType === 'Float') || (fieldType === 'Double')) {
                // if empty string (because isNaN('') is false in javascript for some reason...)
                if (typeof value === 'string') {
                    if (value.trim() === '') {
                        this._errorMessage = 'Value of type ' + fieldType + ' may note be empty.';
                        result = false;
                    }
                }
                // console.log('Field.isValidValue() - about to check ' + this._name + ' whether isNaN: ' + value + ' (which is ' + isNaN(value) + ')');
                if (isNaN(value)) {
                    this._errorMessage = 'Value of type ' + fieldType + ' must be numeric: ' + value;
                    result = false;
                }
                // console.log('Field.isValidValue() - about to check numeric min: ' + fieldMin);
                if (fieldMin) {
                    if (value < fieldMin) {
                        this._errorMessage = 'Value of type ' + fieldType + ' may not be less than ' + fieldMax + ': ' + value;
                        result = false;
                    }
                }
                // console.log('Field.isValidValue() - about to check numeric max: ' + fieldMax);
                if (fieldMax) {
                    if (value > fieldMax) {
                        this._errorMessage = 'Value of type ' + fieldType + ' may not be more than ' + fieldMax + ': ' + value;
                        result = false;
                    }
                }
            }

            // console.log('Field.isValidValue() - about to check for decimal points and commas in whole numbers...');

            if ((fieldType === 'Integer') || (fieldType === 'Long') || (fieldType === 'Short') || (fieldType === 'Byte')) {
                // if string contains a "." it's a floating point number... NOT ALLOWED...
                // And for some reason, browser sometimes let these in...
                if (typeof value === 'string') {
                    if (value.indexOf('.') >= 0) {
                        this._errorMessage = 'Value of type ' + fieldType + ' may not contain a decimal: ' + value;
                        result = false;
                    }
                    if (value.indexOf(',') >= 0) {
                        this._errorMessage = 'Value of type ' + fieldType + ' may not contain a comma: ' + value;
                        result = false;
                    }
                }
            }

        } catch (ex) {
            console.log(ex);
        }

        // console.log('Field.isValidValue(field, ' + value + ') = ' + result + '\n' + JSON.stringify(this));

        return result ;
    }

    get asParm(): any {
        const parms = {};
        if ((typeof this.value !== 'undefined') && (this.value !== null)) {
            parms[this.name] = this.value;
        }
        return parms;
    }

    get clone(): WField {
        const f2 = new WField(this.name, this.value, this.type, this.eventHandler, null, this);
        return(f2);
    }

}

////////////////////////////////////////////////////////////////////
// These are simple wrapper classes for each field type...
////////////////////////////////////////////////////////////////////

export class WString extends WField {
    constructor(name: string, value: string, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'String', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WBoolean extends WField {
    constructor(name: string, value: boolean, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, (typeof value === 'boolean' ? value : false), 'Boolean', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WWholeNumber extends WField {
    constructor(name: string, value: number, type: string, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, type, eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WRealNumber extends WWholeNumber {
    constructor(name: string, value: number, type: string, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, type, eventHandler, apiFieldDefn, fieldToClone);

        if (!this.pattern) {
            this.pattern = '[-+]?[0-9]+([\.][0-9]{0,2})?';
        }
        if (!this.step) {
            this.step = 0.01;
        }
    }
}

export class WByte extends WWholeNumber {
    constructor(name: string, value: number, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Byte', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WShort extends WWholeNumber {
    constructor(name: string, value: number, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Short', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WInteger extends WWholeNumber {
    constructor(name: string, value: number, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Integer', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WLong extends WWholeNumber {
    constructor(name: string, value: number, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Long', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WFloat extends WRealNumber {
    constructor(name: string, value: number, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Float', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WDouble extends WRealNumber {
    constructor(name: string, value: number, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Double', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WDate extends WField {
    constructor(name: string, value: string, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Date', eventHandler, apiFieldDefn, fieldToClone);
    }

    beforeNow(): boolean {
        if (this.value === null) {
            return false;
        }
        const d = new Date(this.value);
        return d.getTime() < new Date().getTime();
    }

    afterNow(): boolean {
        if (this.value === null) {
            return false;
        }
        const d = new Date(this.value);
        return d.getTime() > new Date().getTime();
    }
}

export class WEncrypted extends WField {
    constructor(name: string, value: string, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Encrypted', eventHandler, apiFieldDefn, fieldToClone);
    }

    get isNull(): boolean {
        return (this.value === null);
    }
}

export class WPassword extends WField {
    constructor(name: string, value: string, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Password', eventHandler, apiFieldDefn, fieldToClone);
    }

    get isNull(): boolean {
        return (this.value === null);
    }
}

export class WUnique extends WField {
    constructor(name: string, value: string, eventHandler: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Unique', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WURL extends WField {
    constructor(name: string, value: string, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'URL', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WEmail extends WField {
    constructor(name: string, value: string, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Email', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WColor extends WField {
    constructor(name: string, value: string, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Color', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WPhone extends WField {
    constructor(name: string, value: string, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Phone', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WBinary extends WField {
    constructor(name: string, value: string, eventHandler: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Binary', eventHandler, apiFieldDefn, fieldToClone);
    }

    get fieldContentUrl(): string {
        if (this.type !== 'Binary') {
            return null;
        }
        return './EventServer' + this.value;
    }

    parseBinaryURL(text: string): {eh: string, method: string, parms: any} {
        let getFileParts = null;

        // The format for the "text" of ANY byte[] field upon
        // initial load from the server looks like this:
        //
        //       /ehName/getFile?resourceKeyField=resourceID&binaryContentFieldName=fileField
        //

        const regEx = '^/([\\w]*)/(getFile)\\?([\\w]*)=([\\w]*)\\&(binaryContentFieldName)=([\\w]*)$';

        const matches = text.match(regEx);

        // console.log('WBinary.parseBinaryURL()', matches);

        if (matches !== null) {
            const eh = matches[1];
            const method = matches[2];
            const parms: any = {};

            for (let i = 3; i < matches.length; i++) {
                const name = matches[i++];
                const value = matches[i];

                parms[name] = value;
            }

            getFileParts = { eh, method, parms };

        }

        return getFileParts;
    }

  /**
   * This gets the base64 content for the file and puts it into this.f.value, and this.f.text is set to null
   *
   * @param thisIsABinaryTextFile If false (the default), this.f.text is set to null. If true, this.f.text is set to the converted-from-base64 value.
   */
  getBinaryFileContentFromServer(eventServerService: EventServerService, modalDialogService: ModalDialogService, utilityLibService: UtilityLibService, thisIsABinaryTextFile?: boolean, callBackSubject?: Subject<void>): void {
    thisIsABinaryTextFile = typeof thisIsABinaryTextFile === 'boolean' ? thisIsABinaryTextFile : false;

    try {

      // console.log('getBinaryFileContentFromServer() - Got here: ' + thisIsABinaryTextFile, JSON.stringify(this.text), new Error('debug'));

      const binaryUrlExtract = this.parseBinaryURL(this.text);

      if (binaryUrlExtract) {

        // console.log('getBinaryFileContentFromServer() - hitting the server', this, this.name, this.value, this.binaryContentUrl, thisIsABinaryTextFile, new Error('debug'));

        const ehName = binaryUrlExtract.eh;
        const method = binaryUrlExtract.method;
        const parms = binaryUrlExtract.parms;
        parms.returnBase64FileContent = true;    // special flag for servlet to send back getFile response as a regular Event, and NOT just dump the actual binary file content back at us.

        // console.log('getBinaryFileContentFromServer() - firing Event: ' + ehName + '.' + method, parms);

        // Kind of wish this was a synchronous call...
        eventServerService.fireEvent(ehName, method, parms).subscribe(
          (responseEvent: WEvent) => {

            // console.log('getBinaryFileContentFromServer() - responseEvent:', responseEvent);

            if (responseEvent.status === 'OK') {
              const base64FileContent = responseEvent.getParameter('fileContent');

              // console.log('getBinaryFileContentFromServer() - Got the base64 file content! (' + base64FileContent.length + ' chars)' ); // , base64FileContent));

              // now we save the value that we got from the server...
              // and clear the flag saying that this field has the url in it...

              // we CANNOT use atob() because it does NOT support UTF-8 properly...
              // this.text = (thisIsABinaryTextFile === true ? atob(base64FileContent) : null);
              this.text = (thisIsABinaryTextFile === true ? utilityLibService.atobForUnicodeContent(base64FileContent) : null);

              this.value = base64FileContent;
              this.binaryContentUrl = false;

              if (callBackSubject) {
                  callBackSubject.next();
              }

            } else {
              modalDialogService.showAlert(responseEvent.message, 'Invalid Server Response');
            }
          }
        );

      }

    } catch (ex) {
      const msg = 'getBinaryFileContentFromServer() - Failed to process getFile url:\n' + this.text;
      modalDialogService.showAlert(msg + '\n' + JSON.stringify(ex), 'Error Getting File Content');
    }
  }

}

export class WSelect extends WField {
    constructor(name: string, value: any, eventHandler?: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'Select', eventHandler, apiFieldDefn, fieldToClone);
    }
}

export class WForeignKey extends WField {
    constructor(name: string, value: number, eventHandler: string, apiFieldDefn?: object, fieldToClone?: object) {
        super(name, value, 'ForeignKey', eventHandler, apiFieldDefn, fieldToClone);
    }
}
