import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EventServerService } from './event-server.service';
import { User } from '../data/user.model';
import { Globals } from './global.service';
import { WEvent } from '../data/event.model';
import { WResource } from '../data/resource.model';
import { WField } from '../data/field.model';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  private _user: User = null;

  currentUser = new BehaviorSubject<User>(null);

  constructor(
    private _eventServerService: EventServerService,
  ) {
  }

  bootupInitialization(): Promise<void> {
    return new Promise<void>(
      (resolve, reject) => {

        this._autoLogin(resolve);

        // to eliminate the lint warning...
        reject = reject;

      }
    );
  }

  private _autoLogin(resolveFromAppInitPromise?: any): void {
    // console.log('_autoLogin()');

    // this._eventServerService.showSession();

    this._eventServerService.loadUserAPI().subscribe(
      () => {
        // this._eventServerService.showSession();

        this._user = this._eventServerService.getUserFromCookie();

        // load the user's API for the current application...
        // when the request comes back, the user's role is properly set...

        // now that we have the new user info AND that user's API defined,
        // we can tell everybody there's a new user...

        // but first, if necessary, we try to extract the guest user's name from the appropriate resource type...

        // remember, guest in AccountManager is a special case...
        if ((this._user !== null) && (this._user.role === 'guest') && (Globals.thisApplication !== 'AccountManager')) {

          if (this._user.guestID && this._user.guestType) {
            const guestEhName = this._eventServerService.getEventHandlerForResourceType(this._user.guestType);
            const guestKeyFieldName = this._eventServerService.getKeyFieldName(guestEhName);

            const parms: any = {};
            parms[guestKeyFieldName] = this._user.guestID;

            // console.log('_autoLogin() - about to load guest', user, guestEhName, parms);

            this._eventServerService.loadResourceFromServer(guestEhName, parms).subscribe(
              (guestResource: WResource) => {
                // console.log('_autoLogin() - just loaded guest', user, guestEhName, parms, guestResource);

                this._user.guest = guestResource;

                // at this point, we try to set the guest user's name by examining the fields in the guestResource...

                const firstNameField = guestResource.fields.find((f: WField) => f.name.toLowerCase().indexOf('firstname') > -1);
                const lastNameField = guestResource.fields.find((f: WField) => f.name.toLowerCase().indexOf('lastname') > -1);

                this._user.fullName = firstNameField.value + ' ' + lastNameField.value;

                if (this._user.groupID && this._user.groupType) {
                  const groupEhName = this._eventServerService.getEventHandlerForResourceType(this._user.groupType);
                  const groupKeyFieldName = this._eventServerService.getKeyFieldName(groupEhName);

                  const parms2: any = {};
                  parms2[groupKeyFieldName] = this._user.groupID;

                  // console.log('_autoLogin() - about to load guest group', user, groupEhName, parms2);

                  this._eventServerService.loadResourceFromServer(groupEhName, parms2).subscribe(
                    (guestGroupResource: WResource) => {
                      // console.log('_autoLogin() - just loaded guest group', user, groupEhName, parms, guestGroupResource);

                      this._user.group = guestGroupResource;

                      // console.log('_autoLogin() - about to notify new user:' + this._user.fullName + ' ' + this._user.role);

                      this.currentUser.next(this._user);

                      // if we need to, we can now resolve the APP_INITIALIZATION Promise
                      if (resolveFromAppInitPromise) {
                          resolveFromAppInitPromise();
                      }
                    }
                  );
                } else {
                    throw new Error('Missing required groupID (' + this._user.groupID + ') or groupType (' + this._user.groupType + ')');
                }
              }
            );
          } else {
              throw new Error('Missing required guestID (' + this._user.guestID + ') or guestType (' + this._user.guestType + ')');
          }
        } else {
          // console.log('_autoLogin() - about to notify new user:' + this._user.fullName + ' ' + this._user.role);

          this.currentUser.next(this._user);

          // if we need to, we can now resolve the APP_INITIALIZATION Promise
          if (resolveFromAppInitPromise) {
              resolveFromAppInitPromise();
          }
        }
      }
    );

  }

  logout(): void {
    this._eventServerService.logout();
  }

  get isLoggedIn(): boolean {
    return this._eventServerService.isValidToken() && (this._user.isAuthorizedOrAbove);
  }

  /**************************************************************************
   * GUEST user functionality...
   **************************************************************************/

  loadPermittedGroupMemberIDsFromServer(): any[] {
    const idArray = [];
    if ((this._user.role === 'guest')
        && (this._user.guest !== null)
        && (this._user.group !== null)
    ) {
      const linkType = this._user.linkType;
      const groupID = this._user.groupID;

      const guestTypeKeyFieldName = this._user.guest.keyField.name;
      const groupTypeKeyFieldName = this._user.group.keyField.name;

    //   const debug = false;
    //   if (debug) console.log('EventServer.loadPermittedGroupMemberIDsFromServer() - linkType: ' + linkType + ', groupID: ' + groupID);

      const linkEHName = this._eventServerService.getEventHandlerForResourceType(linkType);

      if (linkEHName !== null) {

        // format the comma-separated string of ids to lookup in the EventHandler...
        const parms: any = {};
        // parms = wackadoo._UI.applySelectedResourceKeysToParms(parms, linkEHName);
        parms[groupTypeKeyFieldName] = groupID;
        parms.pageSize = 1000;

        // if (debug) console.log('EventServer.loadPermittedGroupMemberIDsFromServer() - \nparms: ' + JSON.stringify(parms));

        this._eventServerService.fireEvent(linkEHName, 'list', parms).subscribe(
          (event: WEvent) => {
            // walk the resources (which are NOT formal Resource types in the API, and are specific to the lookup method...)

            if (event.hasOwnProperty('resources')) {
              // if (debug) console.log('EventServer.loadPermittedGroupMemberIDsFromServer() - \nevent.resource.length: ' + event.resource.length);
              for (const res of event.resources) {
                idArray.push(res[guestTypeKeyFieldName].text);
              }
            } else {
            //   if (debug) console.log('No members defined for this linkType (' + linkType + ') and groupID: ' + groupID);
            }
          }
        );
      } else {
        // if (debug) console.log('User does not have access to this EventHandler: ' + linkEHName);
      }
    }
    return idArray;
  }

}
