import { Subscriber } from 'rxjs';
import { WResource } from '../../../data/resource.model';
import { WEvent } from '../../../data/event.model';
import { FieldMode } from 'src/app/client-core/data/field/field-mode.model';

export class ResourceModalContent {
  observer: Subscriber<WEvent|WResource> = null;
  componentName = '';
  resource: WResource = null;
  mode: FieldMode = FieldMode.read;
  eventHandler: string = null;
  action: string = null;
  okButtonTitle = null;

  constructor(observer: Subscriber<WEvent|WResource>, componentName: string, resource: WResource, mode?: FieldMode, eventHandler?: string, action?: string, okButtonTitle?: string) {
    this.observer = observer;
    this.componentName = componentName;
    this.resource = resource;
    this.mode = (typeof mode !== 'undefined' ? mode : FieldMode.read);
    this.eventHandler = (typeof eventHandler !== 'undefined' ? eventHandler : null);
    this.action = (typeof action !== 'undefined' ? action : null);
    this.okButtonTitle = (okButtonTitle ? okButtonTitle : null);
  }
}

