import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';
import { ModalDialogService } from '../services/modal-dialog.service';
import { WField } from '../data/field.model';
import { FileContentDescriptor } from './file-content-descriptor.model';
import { EventServerService } from '../services/event-server.service';
import { FileReadDirective } from './file-read.directive';
import { WEvent } from '../data/event.model';
import { UserInterfaceService } from '../services/user-interface.service';

@Directive({
  selector: '[wackadooFileUpload]'
})
export class FileUploadDirective extends FileReadDirective implements OnInit {

  @Input() eventHandler = '';
  @Input() action = '';
  @Input() parms = {};
  @Input() validExtensions: string = null;
  @Input() maxFileSizeMB = -1;
  @Input() successTitle = null;

  @Input() confirmTitle = null;
  @Input() confirmMessage = null;

  @Input() binaryField: WField = null;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
  ) {
    super(elementRef, renderer, modalDialogService);
  }

  ngOnInit(): void {

    // if we don't have a particular field, we simply load up the generic file descriptor parameters...
    if (this.binaryField === null) {
      this.binaryField = FileContentDescriptor.getGenericBinaryField();
    }

    if (this.successTitle === null) {
      this.successTitle = this.eventHandler + ' ' + this.action.slice(0, 1).toUpperCase() + this.action.slice(1) + ' Complete';
    }

    if (this.confirmTitle === null) {
      this.confirmTitle = 'Are You Sure?';
    }

    if (this.confirmMessage === null) {
      this.confirmMessage = 'You are about to upload the file and perform a ' + this.eventHandler + ' ' + this.action + ' operation.';
    }

    // console.log('FileUpLoadDirective', this.validExtensions);

    // this is null if the component has not yet fully loaded...
    if (this.validExtensions) {

      // create and decorate the fileInput...
      const fileInput = this.renderer.createElement('input');

      fileInput.type = 'file';
      // all EventServer file uploads use this field names: fileContent, fileName, fileSize, fileType
      // (fileName, fileSize, and fileType are set in the readAndUploadFile() call below...)
      fileInput.name = 'fileContent';
      // we'd rather do this: this.renderer.addClass(fileInput, 'hidden');
      // but since the fileInput is not yet in the DOM, we cannot...
      fileInput.className = 'hidden';

      const validExtensionsAsArray: string [] = this.validExtensions.split('|');
      // console.log('FileUpLoadDirective', this.validExtensions, validExtensionsAsArray);

      let temp = '';
      for (const ext of validExtensionsAsArray) {
        temp += (',.' + ext);
      }
      if (temp !== '') {
        temp = temp.substr(1);
      }
      // console.log('FileUpLoadDirective', this.validExtensions, validExtensionsAsArray, temp);
      fileInput.accept = temp;

      fileInput.onchange = () => {
        // console.log('about to read and upload file...');

        // show the 'please wait' dialog while the server is being called...
        this.modalDialogService.showPleaseWait(true, true);

        const subscr = this.readFileContent(fileInput, this.modalDialogService, validExtensionsAsArray, this.maxFileSizeMB).subscribe(
          (fcd: FileContentDescriptor) => {
            // console.log('got file content', fcd);
            if (fcd.valid) {
              const parms = this.parms;

              // copy in the stuff from file descriptor...
              parms[this.binaryField.fileNameField] = fcd.fileName;
              parms[this.binaryField.name] = fcd.fileContent;
              parms[this.binaryField.fileSizeField] = fcd.fileSize;
              parms[this.binaryField.fileDateField] = fcd.fileDate;
              parms[this.binaryField.fileTypeField] = fcd.fileType;

              const uploadStart = new Date().getTime();

              // console.log('about to upload file', this.eventHandler, this.action, this.parm, fcd);

              this.eventServerService.fireEvent(this.eventHandler, this.action, parms).subscribe(
                (event: WEvent) => {

                  this.modalDialogService.showPleaseWait(false);

                  if (event.status === 'OK') {
                    const uploadEnd = new Date().getTime();
                    const msg = fcd.fileName + '\n' + fcd.fileSize + ' bytes uploaded in ' + ((uploadEnd - uploadStart) / 1000) + ' seconds';
                    this.modalDialogService.showAlert(msg, this.successTitle).subscribe(
                      () => {
                        this.userInterfaceService.reloadCurrentPage();
                      }
                    );
                  } else {
                    this.modalDialogService.showAlert('The ' + this.action + ' operation failed!\n\n' + event.message, 'WARNING').subscribe(
                      () => {
                        this.userInterfaceService.reloadCurrentPage();
                      }
                    );
                    // console.log('The ' + this.action + ' operation failed!', event);
                  }
                }
              );

            } else {
              this.modalDialogService.showAlert(JSON.stringify(fcd), 'WARNING: Invalid File Descriptor!');
            }
            subscr.unsubscribe();
          }
        );
      };

      // add the hidden fileInput into the current element...

      this.renderer.appendChild(this.elementRef.nativeElement, fileInput);

      // tell the element to click the fileInput...

      this.renderer.listen(
        this.elementRef.nativeElement,
        'click',
        () => {
          this.modalDialogService.showConfirm(this.confirmMessage, this.confirmTitle).subscribe(
            (choice: boolean) => {
              if (choice) {
                fileInput.click();
              }
            }
          );
        }
      );
    }
  }

}
