import { WField } from '../data/field.model';

export class FileContentDescriptor {
    fileContent: string;    // raw, un-adorned base64 string... (i.e. no ";base64," suffix, or anything like that...)
    fileName: string;
    fileSize: number;       // number of bytes when the file is in it's binary form
    fileType: string;       // mime-type if we have it...
    fileDate: Date;         // a Date...

    constructor(fileContent?: string, fileName?: string, fileSize?: number, fileType?: string, fileDate?: Date) {
      fileContent = typeof fileContent !== 'undefined' ? fileContent : null;
      fileName = typeof fileName !== 'undefined' ? fileName : null;
      fileSize = typeof fileSize !== 'undefined' ? fileSize : null;
      fileType = typeof fileType !== 'undefined' ? fileType : null;
      fileDate = typeof fileDate !== 'undefined' ? fileDate : null;

      this.fileContent = fileContent;
      this.fileName = fileName;
      this.fileSize = fileSize;
      this.fileType = fileType;
      this.fileDate = fileDate;
    }

    // don't necessarily need a date to have a valid FCD...
    get valid(): boolean {
      return ((this.fileName !== null) && (this.fileSize !== null) && (this.fileType !== null) && (this.fileContent !== null));
    }

    /**
     * Builds a binary field with the proper attributes for a "generic" file upload.
     */
    static getGenericBinaryField(): WField {

      const binaryField = new WField('fileContent', null, 'byte[]');

      binaryField.fileNameField = 'fileName';
      binaryField.fileSizeField = 'fileSize';
      binaryField.fileTypeField = 'fileType';
      binaryField.fileDateField = 'fileDate';

      return(binaryField);
    }
}
