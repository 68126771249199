import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { Subscription } from 'rxjs';
import { EventServerService } from '../../../services/event-server.service';
import { ScreenType } from '../../../services/screen-type.enum';
import { BusinessRuleService, WMenuItem } from '../../../services/business-rule.service';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';

@Component({
  selector: 'wackadoo-menu',
  templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit, OnDestroy {

  @Input() showMenu = true;
  @Input() showAsTabs = false;

  activeItem = null;
  pageChangeSubscription: Subscription;

  menuItems: WMenuItem [] = [];

  buildMenuSubscription: Subscription;
  clearMenuSubscription: Subscription;

  screenType: ScreenType;
  screenTypeSubscription: Subscription;

  subMenuOpen: { [subMenuName: string]: boolean } = {};
  subMenus: { [subMenuName: string]: string [] } = {};

  constructor(
    private _userInterfaceService: UserInterfaceService,
    private _eventServerService: EventServerService,
    private _businessRuleService: BusinessRuleService,
    private _userAuthService: UserAuthService,
  ) {
  }

  ngOnInit(): void {

    this.screenTypeSubscription = this._userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        // console.log('banner: ' + JSON.stringify(screenType));
        this.screenType = screenType;
      }
    );

    this.pageChangeSubscription = this._userInterfaceService.currentPage.subscribe(
      (pageName: string) => {
        // console.log('menu.pageChangeSubscription()', this.activeItem, 'changing page: ' + pageName);
        this.activeItem = pageName;
        this.buildMenu();
       }
    );

    this.buildMenuSubscription = this._userInterfaceService.buildMenu.subscribe(
      () => {
        // console.log('menu.buildMenuSubscription()', this.activeItem);
        this.buildMenu();
      }
    );

    this.clearMenuSubscription = this._userInterfaceService.clearMenu.subscribe(
      () => {
        this.activeItem = null;
        this.menuItems = [];
      }
    );

    // console.log('menu.ngOnInit()', this.activeItem);
    this.buildMenu();
  }

  ngOnDestroy(): void {
    if (this.screenTypeSubscription) {
      this.screenTypeSubscription.unsubscribe();
    }
    if (this.pageChangeSubscription) {
      this.pageChangeSubscription.unsubscribe();
    }
    if (this.buildMenuSubscription) {
      this.buildMenuSubscription.unsubscribe();
    }
    if (this.clearMenuSubscription) {
      this.clearMenuSubscription.unsubscribe();
    }
  }

  buildMenu(): void {
    // console.log('menu.buildMenu()', this.activeItem);

    this.menuItems = this._businessRuleService.applyMenuRules(this._userAuthService.currentUser.getValue(), this._userInterfaceService.currentSelections, this._eventServerService.userAPI, this.screenType);

    // console.log('menu.buildMenu()', this._userAuthService.userAuthChanged.getValue(), this._userInterfaceService.currentSelections, this._eventServerService.userAPI, this.menuItems);

    // now build the sub-menus from scratch...

    // We save the open/closed status for sub-menus across menuBuild() calls
    // so that the user has control over whether they stay open or not...
    // this.subMenuOpen = {};

    this.subMenus = {};

    for (const mi of this.menuItems) {
      if (mi.subMenuItems) {

        // first, we populate the sub-menu
        this.subMenus[mi.name] = mi.subMenuItems;

        // second, we keep the submenu open or closed, per it's previous setting - if it had one...
        this.subMenuOpen[mi.name] = typeof this.subMenuOpen[mi.name] !== 'undefined' ? this.subMenuOpen[mi.name] : false;
        if (this.subMenuOpen[mi.name] === true) {
          this._openSubMenu(mi.name);
        } else {
          this._closeSubMenu(mi.name);
        }

        // if the activeItem indicates that we're on a particular sub-menu page for a CLOSED sub-menu...
        // then we open the sub-menu...

        if (this.subMenus[mi.name].includes(this.activeItem) && !this.subMenuOpen[mi.name]) {
          this.subMenuOpen[mi.name] = true;
          this._openSubMenu(mi.name);
        }
      }
    }

    const activeItemIndex = this.menuItems.findIndex(mi => mi.name === this.activeItem);

    // console.log('menu.buildMenu() - is active item in menu?', this.activeItem, this.menuItems, activeItemIndex, (activeItemIndex < 0));

    // If the active page is not in the current menu, we add it as a "special case" usage of that page...
    if (this.activeItem && (activeItemIndex < 0)) {
      // console.log('menu.buildMenu() - pushing activeItem into menu...', this.activeItem, this.menuItems, activeItemIndex, (activeItemIndex < 0));
      this.menuItems.push(new WMenuItem(this.activeItem));
    }

    // now tell the page to re-draw itself if it needs to...
    this._userInterfaceService.screenSizeChange.next();

    // console.log('menu.buildMenu()', this.menuItems, this.subMenus, 'activeItem: ' + this.activeItem, 'open sub menus:', this.subMenuOpen);
  }

  getPageIcon(pageName: string): string {
    return this._businessRuleService.getMenuIcon(pageName);
  }

  isSubMenu(pageName: string): boolean {
    return (typeof this.subMenus[pageName] !== 'undefined') && (this.subMenus[pageName] !== null);
  }

  isSubMenuItem(pageName: string): boolean {
    for (const subMenu of Object.values(this.subMenus)) {
      if (subMenu.includes(pageName)) {
        return true;
      }
    }
    return false;
  }

  onClick(e: any, pageName: string): void {
    // console.log(pageName, this.isSubMenu(pageName), this.screenType);
    if (this.isSubMenu(pageName)) {
      // we don't want the wrapping menu closer directive to hide the menu when we're just toggling the sub-menu...
      e.stopPropagation();
      e.preventDefault();
      this.toggleSubMenu(pageName);
      if (this.screenType !== 'fullscreen') {
        this._userInterfaceService.showSideNavMenu();
      }
    } else {
      // if we're on a phone or tablet, hide the menu, and just show the hamburger...
      if (this.screenType !== 'fullscreen') {
        this._userInterfaceService.hideSideNavMenu();
      }
      this._userInterfaceService.loadPage(pageName);
    }
  }

  toggleSubMenu(pageName: string): void {
    if (this.isSubMenu(pageName)) {
      if (this.subMenuOpen[pageName]) {
        this._closeSubMenu(pageName);
      } else {
        this._openSubMenu(pageName);
      }
      this.subMenuOpen[pageName] = !this.subMenuOpen[pageName];
    }
  }

  private _closeSubMenu(subMenuName: string): void {
    this.subMenus[subMenuName].forEach( smin => {
      const idx = this.menuItems.findIndex(mi => mi.name === smin);
      if (idx > -1) {
        this.menuItems.splice(idx, 1);
      }
    });
    this._userInterfaceService.screenSizeChange.next();
  }

  private _openSubMenu(subMenuName: string): void {
    const pageIdx = this.menuItems.findIndex(mi => mi.name === subMenuName);
    if (pageIdx > -1) {
      const subMenuIdx = pageIdx + 1;
      const temp = [];
      this.menuItems.slice(0, subMenuIdx).forEach( mi => temp.push(mi));
      this.subMenus[subMenuName].forEach( smi => temp.push(new WMenuItem(smi)));
      if (subMenuIdx <= this.menuItems.length) {
        this.menuItems.slice(subMenuIdx).forEach( mi => temp.push(mi));
      }
      this.menuItems = temp;
    }
    this._userInterfaceService.screenSizeChange.next();
  }

  hasSelectedItem(eh: string): boolean {
    return this._userInterfaceService.getSelectedResource(eh) !== null;
  }

  selectedItem(eh: string): string {
    return this._userInterfaceService.getSelectedResource(eh).keyField.displayValue;
  }

}
