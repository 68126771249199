<span *ngIf="f">
    <wackadoo-field-string        [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="((f.type === 'String') && !isHtmlStringField) || (f.type === 'Unknown')"></wackadoo-field-string>
    <wackadoo-field-html          [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'String') && isHtmlStringField"></wackadoo-field-html>
    <wackadoo-field-encrypted     [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Encrypted')"></wackadoo-field-encrypted>
    <wackadoo-field-password      [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Password')"></wackadoo-field-password>
    <wackadoo-field-unique        [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Unique')" [eventHandler]="f.eventHandler"></wackadoo-field-unique>
    <wackadoo-field-select        [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Select')"></wackadoo-field-select>
    <wackadoo-field-foreign-key   [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'ForeignKey')"></wackadoo-field-foreign-key>
    <wackadoo-field-url           [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'URL')"></wackadoo-field-url>
    <wackadoo-field-email         [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Email')"></wackadoo-field-email>
    <wackadoo-field-phone         [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Phone')"></wackadoo-field-phone>
    <wackadoo-field-color         [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Color')"></wackadoo-field-color>
    <wackadoo-field-boolean       [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Boolean')"></wackadoo-field-boolean>
    <wackadoo-field-whole-number  [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Byte') || (f.type === 'Short') || (f.type === 'Integer') || (f.type === 'Long')"></wackadoo-field-whole-number>
    <wackadoo-field-real-number   [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Float') || (f.type === 'Double')"></wackadoo-field-real-number>
    <wackadoo-field-date          [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Date')"></wackadoo-field-date>
    <wackadoo-field-binary        [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" [size]="size" [displayFormat]="displayFormat" *ngIf="(f.type === 'Binary')"></wackadoo-field-binary>
</span>
