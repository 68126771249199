<div class="login">
    <div class="card">
        <div class="card-header">
            <h3>Please sign in</h3>
        </div>
        <div class="card-body">
            <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
                <input 
                    name="userName" 
                    type="text" 
                    class="form-control fullWidth marginTopHalfEm marginBottomQuarterEm" 
                    placeholder="userName" 
                    required 
                    ngModel
                    wackadooAutoFocus
                />
                <input 
                    name="password" 
                    type="password" 
                    class="form-control fullWidth marginBottomQuarterEm" 
                    placeholder="password" 
                    required
                    ngModel
                />
                <div class="d-flex justify-content-around marginTopHalfEm marginBottomHalfEm">
                    <div>
                        <button id="login" class="btn btn-primary fullWidth" type="submit">Sign in</button>
                    </div>
                    <div>
                        <button id="cancel" class="btn btn-secondary fullWidth" type="button" (click)="cancel()">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

