<div class="container-fluid">
    <p>
        For your typical daily backups, you want to use DataArchives, as it includes ALL of your data. 
    </p>
    <p *ngIf="archives.length > 1">
        The other blocks shown below are sub-sets of the DataArchives, and are provided as a convenience for 
        operational purposes. (For example, if you were to get help with your Templates, this would provide 
        a mechanism for delivering just your CustomTemplates without including your other data.)
    </p>
    <div class="card" *ngFor="let archive of archives; let idx = index;">
        <div class="card-header">
            {{ (archive === 'DataArchives' ? archive : archive.substring(0, archive.length - 12)) }}
        </div>
        <div class="card-body d-flex justify-content-around">
            <div class="marginTopHalfEm center" *ngIf="methods[idx].includes('backup')">
                <button
                    class="btn btn-primary" 
                    type="button" 
                    (click)="backup(idx)"
                >Backup</button>
                <div [ngClass]="{'center':(screenType === 'phone'), 'd-flex justify-content-around':(screenType !== 'phone')}">
                    <div class="no-wrap">
                        <input 
                            id="xml{{idx}}" 
                            type="radio" 
                            name="backupFileType{{idx}}" 
                            value="xml" 
                            [(ngModel)]="backupFileType[idx]"
                            [checked]="backupFileType[idx] === 'xml'"
                        />
                        <label class="marginLeftQuarterEm" for="xml{{idx}}">xml (zip)</label>
                    </div>
                    <div class="no-wrap" [ngClass]="{'marginLeftWholeEm':(screenType !== 'phone')}">
                        <input 
                            id="txt{{idx}}" 
                            type="radio" 
                            name="backupFileType{{idx}}" 
                            value="txt" 
                            [(ngModel)]="backupFileType[idx]"
                            [checked]="backupFileType[idx] === 'txt'"
                        />
                        <label class="marginLeftQuarterEm" for="txt{{idx}}">text (zip)</label>
                    </div>
                </div>
            </div>
            <div class="marginTopHalfEm" *ngIf="methods[idx].includes('restore')">
                <button 
                    class="btn btn-primary"

                    wackadooFileUpload
                    eventHandler="{{ archive }}"
                    action="restore"
                    [validExtensions]="'zip|xml|txt'"
                    successTitle="{{ (archive === 'DataArchives' ? archive : archive.substring(0, archive.length - 12)) }} Restore Complete"
                    confirmTitle="WARNING! You are about to COMPLETELY ERASE {{ (archive === 'DataArchives' ? ' ALL YOUR DATA' : ' your ' +  archive.substring(0, archive.length - 12).toUpperCase() + ' data') }} from the server!"
                    confirmMessage="When this is done, the {{ (archive === 'DataArchives' ? 'ONLY' : 'only ' + archive.substring(0, archive.length - 12).toUpperCase() ) }} data you will have on the server is the data in the file that you are about to select.\n\nAre you REALLY sure you want to do this?"

                >Restore</button>
            </div>
            <div class="marginTopHalfEm" *ngIf="methods[idx].includes('clear')">
                <button
                    class="btn btn-primary" 
                    type="button" 
                    (click)="clear(idx)"
                >Clear</button>
            </div>
            <div class="larger marginTopQuarterEm"  *ngIf="methods[idx].includes('archive') || methods[idx].includes('unarchive')">
                <wackadoo-field-select [f]="year" mode="write"></wackadoo-field-select>
            </div>
            <div class="marginTopHalfEm marginBottomHalfEm" *ngIf="methods[idx].includes('archive')">
                <button
                    class="btn btn-primary" 
                    type="button" 
                    (click)="archiveYear(idx)"
                >Pull {{year.value}} Offline</button>
            </div>
            <div class="marginTopHalfEm marginBottomHalfEm" *ngIf="methods[idx].includes('unarchive')">
                <button 
                    class="btn btn-primary"

                    wackadooFileUpload
                    eventHandler="{{ archive }}"
                    action="unarchive"
                    [parms]="{year: year.value}"
                    [validExtensions]="'zip|xml|txt'"
                    successTitle="{{ (archive === 'DataArchives' ? archive : archive.substring(0, archive.length - 12)) }} Restore Complete"
                    confirmTitle="You are about to add {{ archive.substring(0, archive.length - 12) }} data for {{year.value}} BACK to the server!"
                    confirmMessage="This 'un-archive' action should be run using a {{ archive.substring(0, archive.length - 12) }} archive file that you have previously pulled down off the server.\n\nDo you want to continue?"

                >Put {{year.value}} Online</button>
            </div>
        </div>
    </div>
</div>
