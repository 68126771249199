import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { Subject, Subscription } from 'rxjs';
import { WField } from 'src/app/client-core/data/field.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WResource } from 'src/app/client-core/data/resource.model';

@Component({
  selector: 'wackadoo-price-list-item-detail-auto-update',
  templateUrl: './price-list-item-detail-auto-update.component.html',
})
export class PriceListItemDetailAutoUpdateComponent extends ResourceComponent implements OnInit, OnDestroy {

  static componentNameUsedForDynamicContentInAOT = 'PriceListItemDetailAutoUpdateComponent';

  @Input() row = 0;
  @Input() reloadPriceListSubject: Subject<void> = null;

  priceListItemChangedSubject: Subject<WField> = null;
  priceListItemChangedSubscription: Subscription = null;

  inputTimeout: any = null;

  packageLinks: WResource [] = [];

  packageItemSelectionComponentName: string = null;

  constructor(
    userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.packageItemSelectionComponentName = 'PriceListPackageSelectModalComponent';

    this.priceListItemChangedSubject = new Subject<WField>();
    this.priceListItemChangedSubscription = this.priceListItemChangedSubject.subscribe(
      (field: WField) => {

        // console.log('PriceListDetail.firingFieldChangeSubscription()', f);

        // if we're working on a package, we prevent the user from adjusting the quantity...

        if ((this.resource.value === 'Package') && this.resource.quantityAdjustable.value) {
          this.resource.quantityAdjustable.value = false;
          this.resource.quantityAdjustable.changed = true;
        }

        // if we're changing OFF of a Package, we clear out the package...

        if ((field.name === 'resourceType') && (field.value !== 'Package')) {
          this.clearPackage();
        }

        // saving each field value on every field change is WAY too chatty w/the server
        // but we lose data if we use a timeout...

        field.changed = true;
        this.eventServerService.saveResource(this.resource).subscribe(
          (result: WEvent) => {
            try {
              if (result) {
                if (result.status !== 'OK') {
                  throw new Error('Problem saving resource: ' + result.message);
                }
                // now mark the field as un-changed, since it has been updated...
                field.changed = false;
              }

            } catch (ex) {
              console.log(result);
              const msg = 'priceListItemChangedSubscription()\n';
              this.userInterfaceService.alertUserToException(ex, msg);
            }
          }
        );
      }
    );

    this.loadPackageLinks();

  }

  ngOnDestroy(): void {
    if (this.priceListItemChangedSubscription) {
      this.priceListItemChangedSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  loadPackageLinks(): void {

    this.eventServerService.fireEvent('PriceListPackageLinks', 'list', this.resource.keyField.asParm).subscribe(
      (event: WEvent) => {
        try {
          if (event.status !== 'OK') {
            throw new Error(event.message);
          }

          this.packageLinks = event.resources;

        } catch (ex) {
          const msg = 'PriceListPackageLinksComponent.loadPackageLinks()';
          this.userInterfaceService.alertUserToException(ex, msg);
        }

      }
    );

  }

  deletePackageItem(pkgItemLink: WResource): void {

    this.eventServerService.fireEvent('PriceListPackageLinks', 'delete', pkgItemLink.keyField.asParm).subscribe(
      (event: WEvent) => {
        try {
          if (event.status !== 'OK') {
            throw new Error(event.message);
          }

          this.userInterfaceService.reloadCurrentPage();

        } catch (ex) {
          const msg = 'PriceListPackageLinksComponent.deletePackageItem()';
          this.userInterfaceService.alertUserToException(ex, msg);
        }

      }
    );

  }

  deletePriceListItem(): void {
    const eventHandler = 'PriceList';
    const action = 'delete';
    const parms: any = this.resource.keyField.asParm;

    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (result: WEvent) => {
        try {
          if (result.status !== 'OK') {
            throw new Error(result.message);
          }

          if (this.reloadPriceListSubject) {
            this.reloadPriceListSubject.next();
          }

        } catch (ex) {
          const msg2 = 'PriceListDetail.deletePriceListItem()\n';
          this.userInterfaceService.alertUserToException(ex, msg2);
        }
      }
    );
  }

  addPackageItem(): void {

    const item = this.eventServerService.newResource('PriceListPackageLinks');
    item.priceListItemID.value = this.resource.keyField.value;
    item.priceListItemChildID.required = true;

    this.modalDialogService.addResourceModal(this.packageItemSelectionComponentName, 'PriceListPackageLinks', item).subscribe(
      (event: WEvent) => {
        try {
          // console.log('addPackageItem()', event);

          if (event) {

            if (event.status !== 'OK') {
              throw new Error(event.message);
            }

            this.eventServerService.loadResourceFromServer('PriceList', { priceListItemID : event.parameters.priceListItemChildID }, false).subscribe(
              (r2: WResource) => {
                if (r2) {
                  if (r2.fixedInventory.value) {
                    let msg = 'You have included a fixed inventory item in this Package: <i>' + r2.itemName.value + '</i>';
                    msg += '\n\nThe <i>' + r2.itemName.value + '</i> items in this Package DO NOT COUNT against the total inventory that you have defined for <i>' + r2.itemName.value + '</i>.';
                    msg += ' This means that YOU must MANUALLY ADJUST <i>' + r2.itemName.value + '</i> inventory to account for this.';
                    msg += '\n\n<i>(For example: Suppose you have 100 t-shirts to sell, so you define a fixed inventory PriceList item called "t-shirt", and set the total inventory to 100.';
                    msg += '\nBut then you want decide to offer 3 "Team" Packages, each of which includes 5 t-shirts.';
                    msg += '\nIn order to prevent over-selling t-shirts, you would need to reduce your t-shirt inventory by 15, i.e. set the total t-shirt inventory to 85.)</i>';

                    this.modalDialogService.showAlert(msg, 'Please Note');
                  }
                }
              }
            );

            this.loadPackageLinks();
          }

        } catch (ex) {
          const msg = 'PriceListPackageLinksComponent.addPackageItem()';
          this.userInterfaceService.alertUserToException(ex, msg);
        }

      }
    );
  }

  clearPackage(): void {

    this.eventServerService.fireEvent('PriceListPackageLinks', 'delete', this.resource.keyField.asParm).subscribe(
      (event: WEvent) => {
        try {
          // console.log(event);
          if (event.status !== 'OK') {
            throw new Error(event.message);
          }

        } catch (ex) {
          const msg = 'PriceListPackageLinksComponent.clearPackage()';
          this.userInterfaceService.alertUserToException(ex, msg);
        }

      }
    );

  }

}
