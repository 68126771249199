		<div class="card">
			<div class="card-header">
				About Custom Development...
			</div>
			<div class="card-body">
				<p>
					We are happy to speak with you for an hour or two to scope out what you 
					need before you enter into any kind of arrangement with us. We can do that 
					in person in RTP, North Carolina, or via web conference if you are elsewhere.
					<a routerLink="/contact-us">Just give us shout.</a>
				</p>
				<p>
					When developing custom web apps, our charges include:
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						An hourly rate for development services. (Usually between $100-$200 per hour)
					</li>
					<li>
						A monthly subscription rate. (Usually between $100-$500 per month)
					</li>
					<li>
						An initial subscription commitment. (Usually one year)
					</li>
				</ul>
				<p>
					The precise mix of hourly development rate, monthly subscription fee and initial subscription 
					commitment is negotiable. Additional factors in the negotiation include things like:
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						Re-usability: Is the web app suitable for multiple customers? For general release?
					</li>
					<li>
						Exclusivity: Do you want exclusive access to the web app?
						(i.e. for us to make it un-available for our other customers...)
					</li>
					<li>
						Feature Re-Use: This is a significant factor in both our custom development pricing, and 
						our prioritization of corporate and charitable work. (We are constantly looking to 
						develop components and features that we are able to use across multiple applications.)
					</li>
					<li>
						Hosting: Do we host it on our regular servers, or do you host it - either on your own domain or within your corporate network?
					</li>
					<li>
						The degree of UX customization you require.
					</li>
					<li>
						Your status as a non-profit organization.
					</li>
				</ul>
				<p>
					Please note that while developing custom web apps, we require full cooperation and participation on your 
					part in our Agile software development processes. Specifically, you will need to be available to provide 
					feedback, guidance and direction - typically one or more times per week - as we show you the work in progress.
				</p>
				<p>
					We are confident enough in our ability to deliver quickly, that we stand by our 
					<span class="bold italic">In By 10, Out By 5</span> policy for new custom web apps:
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						If we can agree on the details by 10am, we will be able to show you the first working 
						prototype by 5pm, or that first day of development is free.
					</li>
				</ul>
			</div>
		</div>
