<div class="card">
  <div class="card-header">
    Please enter information to create your account.
  </div>
  <div class="card-body">
    <div class="" [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
      <div class="">
        <div class="card">
          <div class="card-header">
            Account Identification
          </div>
          <div class="card-body">
            <div class="">
              Your organization name
            </div>
            <div class="">
              <wackadoo-field [f]="resource.accountName" [mode]="mode"></wackadoo-field>
            </div>
            <div class="">
                Your server prefix (i.e. ____.{{ domain }})
            </div>
            <div class="">
                <wackadoo-field [f]="resource.accountHostName" [mode]="mode"></wackadoo-field>
            </div>
            <div class="marginLeftHalfEm smallish italic">
              (Your server prefix may contain letters, numbers and dashes. A dash may not be the first or last character. No other punctuation or special characters are allowed.)
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            Account Contact Person
          </div>
          <div class="card-body table-responsive">
            <div class="d-flex">
              <div class="">
                <wackadoo-field [f]="resource.accountContactFirstName" [mode]="mode"></wackadoo-field>
              </div>
              <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.accountContactLastName" [mode]="mode"></wackadoo-field>
              </div>
            </div>
            <div class="d-flex">
              <div class="">
                <wackadoo-field [f]="resource.accountAddress" [mode]="mode"></wackadoo-field>
              </div>
            </div>
            <div class="d-flex">
              <div class="">
                <wackadoo-field [f]="resource.accountCity" [mode]="mode"></wackadoo-field>
              </div>
              <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.accountState" [mode]="mode"></wackadoo-field>
              </div>
              <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.accountPostalCode" [mode]="mode"></wackadoo-field>
              </div>
            </div>
            <div class="d-flex">
              <div class="">
                <wackadoo-field [f]="resource.accountCountry" [mode]="mode"></wackadoo-field>
              </div>
            </div>
            <div class="d-flex">
              <div class="">
                <wackadoo-field-phone [f]="resource.accountPhone" [mode]="mode" [forceUSAreaCodePlus7Number]="true"></wackadoo-field-phone>
              </div>
            </div>
            <div class="d-flex">
              <div class="">
                <wackadoo-field [f]="resource.accountEmail" [mode]="mode"></wackadoo-field>
                <span class="hidden">(validated: <wackadoo-field [f]="resource.accountEmailValidated" [mode]="mode"></wackadoo-field>)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class=""  [ngClass]="{'d-flex justify-content-between':(screenType !== 'phone')}">
          <div class="card">
            <div class="card-header">
              Account Administrator Login
            </div>
            <div class="card-body table-responsive">
              <div class="d-flex">
                <div>
                  <div class="no-wrap">
                    user name
                  </div>
                  <div class="">
                    password
                  </div>
                </div>
                <div class="marginLeftHalfEm">
                  <div>
                    <wackadoo-field [f]="resource.userName" [mode]="mode"></wackadoo-field>
                  </div>
                  <div class="marginLeftHalfEm">
                    <wackadoo-field [f]="resource.password" [mode]="mode"></wackadoo-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="" *ngIf="resource.captchaQuery">
            <div class="card">
                <div class="card-header">CAPTCHA</div>
                <div class="card-body">
                    <div class="d-flex justify-content-around">
                        <div class="center nowrap">{{ resource.captchaQuery.value }}</div>
                        <div class="width-5-em marginLeftHalfEm" title="Provide numeric answer (e.g. 42, -37, etc.)">
                            <wackadoo-field-whole-number [f]="resource.captchaAnswer" [mode]="mode" [size]="5"></wackadoo-field-whole-number> 
                        </div>
                    </div>
                    <div class="center marginTopQuarterEm">
                        <button type="button" class="btn btn-secondary refresh" (click)="refreshCaptcha()"><span class="fas fa-sync" aria-hidden="true"></span> Refresh</button>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div *ngIf="user.isPublicOnly">
          <div class="container">
            <div class="well">
              <div>
                At this point, you have not indicated which of our application(s) you would like to sign up for. We can do this one of two ways:
                <ol class="marginTopQuarterEm">
                  <li>
                    Go to the home page for the app you want to sign up for, and click on the green "Sign Up" button on THAT page.
                  </li>
                  <li>
                    Go through our "sign up for the account first" method on this page, which works like this:
                    <br/>
                    When you click on "Submit" below, we will create your account, set up your login and send you a verification email. 
                    After you verify your account data, you will be directed to the Billing page where you can select your application(s), 
                    enter your credit card, authorizing charges to be made against it.
                  </li>
                </ol>
              </div>
              <div class="italic marginTopHalfEm marginBottomHalfEm">
                {{ theBigOptIn }}
              </div>
              <div>
                Please note that <span class="bold">all</span> fields are required, but that you will be able to modify any of this information later, after your account has been created.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
