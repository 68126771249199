import { Component, OnInit, Input, Renderer2, OnDestroy, HostListener } from '@angular/core';
import { WField } from '../field.model';
import { ModalDialogService } from '../../services/modal-dialog.service';
import { Subject } from 'rxjs';
import { FieldMode } from './field-mode.model';

@Component({
  selector: 'wackadoo-field',
  templateUrl: './field.component.html',
})
export class FieldComponent implements OnInit, OnDestroy {

  @Input() public f: WField;
  @Input() public mode: FieldMode = FieldMode.read;
  @Input() public isHtmlStringField = false;

  // not that we put the OLD value of the field in the "f.oldValue" atribute of the field.
  @Input() public onChange: Subject<WField> = null;

  // This is here in case one of the sub-classes (just Date initially...)
  // needs it later to support per-field-instance control of the format.
  @Input() public displayFormat: string = null;

  @Input() title: string = null;
  @Input() size = 20;

  @HostListener('drop', ['$event']) drop = this.handleDrop;

  constructor(
    public renderer: Renderer2,
    public modalDialogService: ModalDialogService,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    // We set "this.f = null" to force the field component to start from scratch next time.
    // (That was a particular issue when trying to re-load binary html content...)
    this.f = null;
  }

  handleDrop(e: any): void {
    switch (this.f.type) {
      // we need this to propagate to handle binary files properly...
      case 'Binary':
        break;
      // do NOT handle text drop on these field types...
      case 'Boolean':
      case 'Password':
      case 'Encrypted':
      case 'ForeignKey':
      case 'Select':
        e.stopPropagation();
        e.preventDefault();
        break;
      default:
        const value = e.dataTransfer.getData('Text');
        // console.log('FieldComponent.handleDrop()', value);
        this.f.value = value;
        this.f.changed = true;
        // console.log('FieldComponent.handleDrop()', value, this.f);
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  }

  /**
   * This is the default method called on all input field changes.
   * It assumes the 'value' of the input element is populated with the new value.
   */
  onInputChange(e: any): boolean {
    // e = e || window.event;
    const target = e.srcElement || e.target;

    const cellInput = target;

    // console.log(cellInput);

    if (cellInput) {

      // console.log('cellInput.value: ' + cellInput.value + ', this.f.value: ' + this.f.value);
      // console.log('this.f.isValidValue(cellInput.value): ' + this.f.isValidValue(cellInput.value));

      // clear the previous formatting, if present...
      this.renderer.removeClass(cellInput, 'fieldChanged');
      this.renderer.removeClass(cellInput, 'fieldError');

      if (this.f.isValidValue(cellInput.value)) {
        // the new value passed validation...

        // console.log('cellInput.value: ' + cellInput.value, this.f.value, 'changed? ' + (cellInput.value !== this.f.value));

        if (cellInput.value !== this.f.value) {

          const oldValue = this.f.value;

          this.renderer.addClass(cellInput, 'fieldChanged');
          this.f.value = cellInput.value;
          this.f.changed = true;

          // console.log('cellInput.value: ' + cellInput.value, this.f.value, 'this.onChange exists? ' + (this.onChange !== null));
          if (this.onChange !== null) {
            // console.log('firing field.onChange.next()');
            this.f.oldValue = oldValue;
            this.onChange.next(this.f);
          }

        }
        // this._renderer.setStyle(cellInput, 'backgroundColor', '');
      } else {

        // console.log('this.f._errorMessage: ' + this.f._errorMessage);

        // the new value failed validation...
        this.renderer.addClass(cellInput, 'fieldError');
      }
    }

    return false;
  }

  onCheckBoxValueChange(e: any): boolean {
    // e = e || window.event;
    const target = e.srcElement || e.target;

    const cellInput = target;

    // console.log('onCheckBoxValueChange(' + target.id + ') - \nvalue: ' + cellInput.value + '\nchecked: ' + cellInput.checked);

    // clear the previous formatting, if present...
    this.renderer.removeClass(cellInput, 'fieldChanged');
    // let's face it, we don't need to do error formatting...
    // (it's either checked or it's not...)

    if (cellInput.checked !== this.f.value) {
      const oldValue = this.f.value;

      this.f.value = cellInput.checked;
      this.f.changed = true;

      this.renderer.addClass(cellInput, 'fieldChanged');

      if (this.onChange) {
        this.f.oldValue = oldValue;
        this.onChange.next(this.f);
      }

      // And now, we put the value in "cellInput.value" - even though that's not "standard" checkbox
      // usage - because this.forceValidValue() looks for ".value" for EVERYthing it checks...
      cellInput.value = cellInput.checked;
    }

    // console.log('onCheckBoxValueChange('+target.id+') - \nvalue: ' + cellInput.value + '\nchecked: ' + cellInput.checked);

    return false;
  }

  forceValidValue(e: any): boolean {
    // e = e || window.event;
    const target = e.srcElement || e.target;

    const cellInput = target;

    // console.log('forceValidValue(' + this.f.name + ') - '
    //   + '\ncell value: "' + cellInput.value + '"'
    //   + '\n\ttypeof: ' + (typeof cellInput.value)
    //   + '\n\tis null: ' + ((cellInput.value == null) || (cellInput.value === null))
    //   + '\n\tis string: ' + (typeof cellInput.value === 'string' || cellInput.value instanceof String)
    //   + '\n\tis number: ' + !isNaN(cellInput.value)
    //   + '\nfield value: "' + this.f.value + '"'
    //   + '\n\ttype: ' + this.f.type
    //   + '\n\ttypeof: ' + (typeof this.f.value)
    //   + '\n\tis null: ' + ((this.f.value == null) || (this.f.value === null))
    //   + '\n\tis string: ' + (typeof this.f.value === 'string' || this.f.value instanceof String)
    //   + '\n\tis number: ' + !isNaN(this.f.value)
    //   + '\n\tis changed: ' + this.f.changed);

    if (this.f.changed && !this.f.isValidValue(cellInput.value)) {

      const confirmMsg = 'Did you intend to enter an invalid value in the ' + this.f.name + ' field?\n'
                          + '\nClick OK to go back and correct it.'
                          + '\nClick Cancel to proceed with your previous valid value.'
                          ;

      this.modalDialogService.showConfirm(confirmMsg, 'Invalid Data Entry').subscribe(
        (flag: boolean) => {
          // If they clicked Cancel, we re-set the field to its previous value.
          if (!flag) {
            const oldChanged = this.f.changed;
            cellInput.value = this.f.value;
            this.onInputChange(e);
            this.f.changed = oldChanged;
          }
          // either way, we throw focus back into the field...
          window.setTimeout(() => cellInput.focus(), 0);
        }
      );

    }

    return false;
  }

}
