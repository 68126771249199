import { Component } from '@angular/core';
import { GridButtonBarComponent } from 'src/app/client-core/pages/repository-page/grid-button-bar/grid-button-bar.component';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-new-customer-grid-button-bar',
  templateUrl: './new-customer-grid-button-bar.component.html',
})
export class NewCustomerGridButtonBarComponent extends GridButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'NewCustomerGridButtonBarComponent';

  truncate(): void {
    this.eventServerService.fireEvent('NewCustomerTruncator', 'runNow', {}).subscribe(
      (event: WEvent) => {
        if (event.status === 'OK') {
          this.modalDialogService.showAlert('Truncation completed.', 'Success!');
        } else {
          this.modalDialogService.showAlert('Truncation failed: ' + event.message, 'Error');
        }
      }
    );
  }
}
