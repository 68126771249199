import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { FieldMode } from 'src/app/client-core/data/field/field-mode.model';

@Component({
  selector: 'wackadoo-root',
  templateUrl: './app.component.html',

})
export class AppComponent implements OnInit, OnDestroy {

  mode = FieldMode.read;
  fieldModeSubscription: Subscription;

  constructor(
    public userInterfaceService: UserInterfaceService
  ) {
  }

  ngOnInit(): void {
    this.fieldModeSubscription = this.userInterfaceService.resourceDetailPageDisplayMode.subscribe(
      (fieldMode: FieldMode) => {
        // console.log('changing fieldMode: ' + fieldMode);
        this.mode = fieldMode;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.fieldModeSubscription) {
      this.fieldModeSubscription.unsubscribe();
    }
  }

}
