<div class="" [ngClass]="{'d-flex justify-content-around extraLarge':(screenType !== 'phone')}">
  <div class="card">
    <div class="card-header max-height-3-em">
      <img class="" src="./assets/pics/closingpro.net.png" alt="ClosingPro.net" />
    </div>
    <div class="card-body">
      <a href="http://www.closingpro.net/AccountManager/Pricing" target="_blank">
        Click here
      </a>
      for pricing.
    </div>
  </div>
  <div class="card">
    <div class="card-header max-height-3-em">
      <img class="" src="./assets/pics/wackadoo.org.png" alt="wackadoo.org" />
    </div>
    <div class="card-body">
      <a href="http://www.wackadoo.org/Pricing" target="_blank">
        Click here
      </a>
      for pricing.
    </div>
  </div>
</div>
