import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserInterfaceService } from '../../services/user-interface.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EventServerService } from '../../services/event-server.service';
import { WEvent } from '../../data/event.model';
import { ModalDialogService } from '../../services/modal-dialog.service';
import { Globals } from '../../services/global.service';
import { UtilityLibService } from '../../services/utility-lib.service';

@Component({
  selector: 'wackadoo-login',
  templateUrl: './login.component.html',

})
export class LoginComponent implements OnInit, OnDestroy {

  loginSubscription: Subscription;

  constructor(
    private _eventServerService: EventServerService,
    private _userInterfaceService: UserInterfaceService,
    private _modalDialogService: ModalDialogService,
    private _utilityLibService: UtilityLibService,
  ) { }

  ngOnInit(): void {

    // Alert the user that their MS browser is NOT supported...

    if (Globals.isIE || Globals.isEdge) {
      let msg = '\nPlease note that <i>Internet Explorer</i> and <i>Edge</i> are not supported at this time.';
      msg += '\n\nWe highly recommend that you use the latest version of <i>Chrome</i>.';
      msg += ' (The latest versions of <i>Firefox</i> and <i>Safari</i> should work fine as well, but they are tested less frequently.)';

      this._modalDialogService.showAlert(msg, 'WARNING! Un-Supported Browser!');
    }
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  login(form: NgForm): void {

    this._modalDialogService.showPleaseWait(true);

    const parms = {
      userName: form.value.userName,
      password: form.value.password
    };

    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }

    this._modalDialogService.showPleaseWait(true);

    this.loginSubscription = this._eventServerService.fireEvent('Administration', 'login', parms).subscribe(
      (event: WEvent) => {

        this._modalDialogService.showPleaseWait(false);

        // console.log('login() - response event...', event);
        // alert('Check the logs prior to reloading the app...');

        if (event.status === 'OK') {

          // at this point, the server has properly set the cookies, so
          // we just re-load the app, which allows the server to re-direct
          // the user to the proper host name for their account...
          if (Globals.rootApplication !== Globals.thisApplication) {
            window.location.href = 'https://www.' + Globals.domain + '/' + Globals.thisApplication;
          } else {
            window.location.href = 'https://www.' + Globals.domain;
          }

        } else {
          this._modalDialogService.showPleaseWait(false);
          this._modalDialogService.showAlert(event.message, 'Invalid login!');
        }
      },
      (error: string) => {
        this._modalDialogService.showPleaseWait(false);
        this._modalDialogService.showAlert(error, 'Login Failed!');
      }
    );

    form.reset();
  }

  cancel(): void {
    // console.log('cancel');

    // if we get here, we can delete the startup cookie (if there was one...)
    this._utilityLibService.deleteCookie(Globals.startupCookieName);

    this._userInterfaceService.loadPage('home');
  }

}
