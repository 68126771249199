import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityLibService {

  constructor(
  ) { }

  //////////////////////////////////////////////////////////////////////////////////
  // Date stuff
  //////////////////////////////////////////////////////////////////////////////////

  formatHumanReadableDate(d: Date, includeYear?: boolean): string {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()] + ' ' + d.getDate() + (includeYear ?  ', ' + d.getFullYear() : '');
  }

  formatHumanReadableDateTime(d: Date, includeYear?: boolean): string {
    const hours = d.getHours();
    let minutes: string|number = d.getMinutes();
    let hours12 = hours % 12; // 12 hour clock am/pm
    hours12 = hours12 ? hours12 : 12; // the hour '0' should be '12'
    minutes = (minutes < 10 ? '0' + minutes : minutes);
    return this.formatHumanReadableDate(d, false) + ', '  + hours12 + ':' + minutes + '' + (hours < 12 ? 'am' : 'pm') + (includeYear ?  ', ' + d.getFullYear() : '');
  }

  getStartOfWeek(d: Date): Date {
    const d2 = new Date(d.toISOString());
    const firstDayOfWeek = d2.getDate() - d2.getDay();
    const startOfWeek = new Date(d2.setDate(firstDayOfWeek));
    startOfWeek.setHours(0);
    startOfWeek.setMinutes(0);
    startOfWeek.setSeconds(0);
    startOfWeek.setMilliseconds(0);
    // console.log('SOW:', d, d.getDate(), d.getDay(), d2, firstDayOfWeek, startOfWeek);
    return startOfWeek;
  }

  getEndOfWeek(d: Date): Date {
    const d2 = new Date(d.toISOString());
    const lastDayOfWeek = d2.getDate() - d2.getDay() + 7;
    const endOfWeek = new Date(d2.setDate(lastDayOfWeek));
    endOfWeek.setHours(0);
    endOfWeek.setMinutes(0);
    endOfWeek.setSeconds(0);
    endOfWeek.setMilliseconds(0);
    // console.log('EOW:', d, d.getDate(), d.getDay(), d2, lastDayOfWeek, endOfWeek);
    return endOfWeek;
  }

  formatDateForRange(dd: Date): string {
    const d = new Date(dd.toUTCString());
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    const s = '' + d.getFullYear() + ((d.getMonth() < 9) ? '0' : '') + (d.getMonth() + 1) + ((d.getDate() < 10) ? '0' : '') + d.getDate();
    return s;
  }

  formatDateForDisplay(dd: Date): string {
    const d = new Date(dd.toUTCString());
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    d.setMilliseconds(0);
    const s = '' + d.getFullYear() + ((d.getMonth() < 9) ? '0' : '') + (d.getMonth() + 1) + ((d.getDate() < 10) ? '0' : '') + d.getDate();
    return s;
  }

  //////////////////////////////////////////////////////////////////////////////////
  // string manipulation functionality
  //////////////////////////////////////////////////////////////////////////////////

  toTitleCase(s: string): string {
    return s.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() );
  }

  toCamelCase(input: string): string {
    if (!input) {
      return '';
    }
    // this handles spaces, dashes and underscores...
    return input.replace(/^([A-Z])|[\s-_](\w)/g, (_match, p1, p2, _offset) => {
        if (p2) {
          return p2.toUpperCase();
        }
        return p1.toLowerCase();
    });
  }

  getInitials(name: string): string {
    let initials = '';
    if (name) {
      // this handles spaces, dashes, underscores, colons, semi-colons, slashes, vertical bars, parens, curly & square brackets, ampersand...
      let temp = name.replace(/[\s-_:;/|()[\]{}&]/g, ' ');
      temp = temp.replace(/\s+/g, ' ');
      const parts = temp.split(' ');
      for (const part of parts) {
        if (part.length > 0 && part !== '') {
          initials += part[0];
        }
      }
    }
    return initials;
  }

  formatNumberWithCommas(n: number, separator?: string): string {
    separator = typeof separator !== 'undefined' ? separator : ',';
    let nStr = '';
    if ((n === null) || isNaN(n)) {
      nStr = '';
    } else {
      nStr = '' + n;
      nStr = nStr.replace(new RegExp('^(\\d{' + (nStr.length % 3 ? nStr.length % 3 : 0) + '})(\\d{3})', 'g'), '$1 $2').replace(/(\d{3})+?/gi, '$1 ').trim();
      if (typeof separator !== 'undefined' && separator !== ' ') {
        nStr = nStr.replace(/\s/g, separator).replace(separator + '.', '.'); // make sure we don't have separator AND a decimal point together...
      }
    }
    // console.log('formatNumberWithCommas', n, typeof n, isNaN(n), nStr);
    return nStr;
  }

  formatDurationAsHoursMinutesSeconds(durationInMilliSeconds: number): string {
    const days = Math.floor(durationInMilliSeconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor((durationInMilliSeconds % (1000 * 60 * 60 * 24)) / (60 * 60 * 1000));
    const minutes = Math.floor((durationInMilliSeconds % (1000 * 60 * 60)) / (60 * 1000));
    const seconds = Math.floor((durationInMilliSeconds % (1000 * 60)) / (1000));

    let sb = '';
    if (days !== 0) {
      sb += days + ' day' + (days !== 1 ? 's' : '') + ', ';
      sb += hours + ' hour' + (hours !== 1 ? 's' : '') + ', ';
      sb += minutes + ' minute' + (minutes !== 1 ? 's' : '') + ', ';
      sb += seconds + ' second' + (seconds !== 1 ? 's' : '');
    } else if (hours !== 0) {
      sb += hours + ' hour' + (hours !== 1 ? 's' : '') + ', ';
      sb += minutes + ' minute' + (minutes !== 1 ? 's' : '') + ', ';
      sb += seconds + ' second' + (seconds !== 1 ? 's' : '');
    } else if (minutes !== 0) {
      sb += minutes + ' minute' + (minutes !== 1 ? 's' : '') + ', ';
      sb += seconds + ' second' + (seconds !== 1 ? 's' : '');
    } else {
      sb += seconds + ' second' + (seconds !== 1 ? 's' : '');
    }
    return(sb);
  }


  formatMemorySize(bytes: number , significantDigitsToRightOfDecimal?: number): string {
    significantDigitsToRightOfDecimal = typeof significantDigitsToRightOfDecimal !== 'undefined' ? significantDigitsToRightOfDecimal : 0;

    let suffix = '';
    let exp = 0;
    let byteCount = bytes;

    while (byteCount > 1024.0) {
      byteCount = byteCount / 1024.0;
      exp++;
      // console.log('exp: ' + exp, 'byteCount: ' + byteCount);
    }
    switch (exp) {
      case 8:
        suffix = 'YB';
        break;
      case 7:
        suffix = 'ZB';
        break;
      case 6:
        suffix = 'EB';
        break;
      case 5:
        suffix = 'PB';
        break;
      case 4:
        suffix = 'TB';
        break;
      case 3:
        suffix = 'GB';
        break;
      case 2:
        suffix = 'MB';
        break;
      case 1:
        suffix = 'KB';
        break;
      case 0:
      default:
        suffix = 'Bytes';
        return(bytes + ' ' + suffix);
    }
    const accuracy = Math.pow(10, significantDigitsToRightOfDecimal);
    const n = Math.round(byteCount * accuracy) / accuracy;
    return(n + ' ' + suffix);
  }

  keepOnlyAlphaNumerics(n: string): string {
    let nStr = '';
    nStr = '' + n;
    const regEx = '[^a-zA-Z0-9]';
    nStr = nStr.replace(new RegExp(regEx, 'g'), '').trim();
    // console.log('keepOnlyAlphaNumerics', n, typeof n, nStr);
    return nStr;
  }

  keepOnlyAlpha(n: string): string {
    let nStr = '';
    nStr = '' + n;
    const regEx = '[^a-zA-Z]';
    nStr = nStr.replace(new RegExp(regEx, 'g'), '').trim();
    // console.log('keepOnlyAlphaNumerics', n, typeof n, nStr);
    return nStr;
  }

  keepOnlyNumerics(n: string): string {
    let nStr = '';
    nStr = '' + n;
    const regEx = '[^0-9]';
    nStr = nStr.replace(new RegExp(regEx, 'g'), '').trim();
    // console.log('keepOnlyNumerics', n, typeof n, nStr);
    return nStr;
  }

  wrapSimplePhraseQueriesInQuotes(query: string): string {
    const alphaNumericsAndSpacesOnly = query.match(/[a-zA-Z0-9 ]+/g);
    if (
        (query.indexOf(' ') > -1)
        && (alphaNumericsAndSpacesOnly.length === 1)
        && (alphaNumericsAndSpacesOnly[0] === query)
    ) {
      query = query.trim();
      if (query.indexOf('\'') >= 0) {
        query = query.replace('\'', '');
      }
      if (query.indexOf(' ') > 0) {
        query = '\'' + query + '\'';
      }
    }
    return query;
  }

  getParm(key: string, sourceURL: string): string {
    let rtn = null;
    let name = null;
    let parms = new Array();
    const queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
      parms = queryString.split('&');
      for (let i = parms.length - 1; i >= 0; i -= 1) {
        name = parms[i].split('=')[0];
        if (name === key) {
          rtn = parms[i].split('=')[1];
          break;
        }
      }
    }
    return rtn;
  }

  removeParm(key: string, sourceURL: string): string {
    let rtn = '';
    rtn = sourceURL.split('?')[0];
    let name = null;
    let parms = [];
    const queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
      parms = queryString.split('&');
      for (let i = parms.length - 1; i >= 0; i -= 1) {
        name = parms[i].split('=')[0];
        if (name === key) {
          parms.splice(i, 1);
        }
      }
      if (parms.length > 0) {
        rtn = rtn + '?' + parms.join('&');
      }
    }
    return rtn;
  }

  //////////////////////////////////////////////////////////////////////////////////
  // Cookie-related functionality
  //////////////////////////////////////////////////////////////////////////////////

  dumpCookies(): void {
    // console.log('dumpCookies() - ', window.document.cookie);
    const ca: string [] = window.document.cookie.split(';');
    for (const c of ca) {
      const cs: string [] = c.split('=');
      const cname = cs[0];
      const cvalue = cs[1];
      console.log('\n\tdumpCookie: ' + cname + '\t=\t' + cvalue);
    }
  }

  getCookie(cname: string): string {
    if (cname) {
      const name = cname + '=';
      // console.log('getCookie: all cookies: ' + window.document.cookie);
      const ca: string [] = window.document.cookie.split(';');
      for (const c of ca) {
        // console.log('getCookie: checking ' + cname);
        if (c.trim().indexOf(name) === 0) {
          const cvalue = c.trim().substring(name.length, c.length);
          // console.log('getCookie: ' + cname + '=' + cvalue);
          return cvalue;
        }
      }
    }
    // console.log('getCookie: ' + cname + '= NULL');
    // console.log('getCookie: all cookies: ' + window.document.cookie);
    return null;
  }

  setCookie(cname: string, cvalue: string, exhours?: number, domain?: string, path?: string): void {
    exhours = typeof exhours !== 'undefined' ? exhours : null;
    domain = typeof domain !== 'undefined' ? domain : location.hostname.substring(location.hostname.indexOf('.'));
    path = typeof path !== 'undefined' ? path : '/';
    if (!cname || !cvalue) {
      return;
    }
    const d = new Date();
    if (exhours) {
      d.setTime(d.getTime() + (exhours * 60 * 60 * 1000)); // a time in the future...
    }

    const cookie = cname + '=' + cvalue + ';domain=' + domain + ';path=' + path + (exhours !== null ? ';expires=' + d.toUTCString() + ';' : '');
    // console.log('setCookie: ' + cookie);
    window.document.cookie = cookie;
  }

  deleteCookie(cname: string, domain?: string, path?: string): void {
    domain = typeof domain !== 'undefined' ? domain : location.hostname.substring(location.hostname.indexOf('.'));
    path = typeof path !== 'undefined' ? path : '/';
    if (!cname) {
      return;
    }
    const d = new Date(1000); // a time in the past...
    const expires = d.toUTCString();

    const cookie = cname + '=;domain=' + domain + ';path=' + path + ';expires=' + expires + ';';
    // console.log('deleteCookie: ' + cookie);
    window.document.cookie = cookie;
  }

  setCookieIfNotSet(cname: string, cvalue: string, exhours?: number, domain?: string, path?: string): void {
    const oldCookie = this.getCookie(cname);

    if (oldCookie === null) {
      this.setCookie(cname, cvalue, exhours, domain, path);
    }

  }

  getJsonCookie(cookieName: string): any {
    let cookieAsJSON: any = {};
    const urlEncodedCookieValue = this.getCookie(cookieName);
    if (urlEncodedCookieValue !== null) {
      // console.log('getJsonCookie()', 'urlEncodedCookieValue: ' + urlEncodedCookieValue);
      const cookieValueString = decodeURIComponent(urlEncodedCookieValue);
      // console.log('getJsonCookie()', 'cookieValueString: ' + cookieValueString);
      cookieAsJSON = JSON.parse(cookieValueString);
      // console.log('getJsonCookie()', 'cookieAsJSON:', cookieAsJSON);
    }
    return cookieAsJSON;
  }

  setJsonCookie(cookieName: string, cookieAsJSON: any): any {
    const cookieAsJsonString = JSON.stringify(cookieAsJSON);
    const urlEncodedCookieValue = encodeURIComponent(cookieAsJsonString);
    this.setCookie(cookieName, urlEncodedCookieValue);
  }

  // adapted from
  // https://newbedev.com/using-javascript-s-atob-to-decode-base64-doesn-t-properly-decode-utf-8-strings
  // https://blog.josequinto.com/2017/03/08/typescript-functions-to-convert-from-base64-to-utf8-and-vice-versa/#Introduction

  btoaForUnicodeString(str: string): string {
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        (match, p1) => {
          return String.fromCharCode(parseInt(p1, 16));
        }
      )
    );
  }

  atobForUnicodeContent(str: string): string {
    return decodeURIComponent(
              Array.prototype.map.call(
                atob(str),
                (c: any) => {
                  return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }
              )
              .join('')
            );
  }

}
