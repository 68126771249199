<!-- We CANNOT just do this... 
<wackadoo-resource [resource]="resource"></wackadoo-resource>
...because we often get fields back that are not technically part of THIS resource.
-->
<ng-container *ngIf="fieldAPIs && (fieldAPIs.length < 20) || (screenType !== 'fullscreen')">
    <div class="d-flex flex-row marginTopQuarterEm smallish" *ngFor="let fAPI of fieldAPIs; let h = index;">
        <div class="right nowrap">
            {{ fAPI.name }} :
        </div>
        <div class="marginLeftWholeEm">
            <div *ngIf="resource[fAPI.name].type !== 'Binary'">
                <wackadoo-field [f]="resource[fAPI.name]" [mode]="mode"></wackadoo-field>
            </div>
            <div *ngIf="resource[fAPI.name].type === 'Binary'">
                <wackadoo-field-binary [f]="resource[fAPI.name]" [mode]="mode" [resource]="resource"></wackadoo-field-binary>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="fieldAPIs && (fieldAPIs.length >= 20) && (screenType === 'fullscreen')">
    <div class="d-flex flex-row marginTopQuarterEm smallish" *ngFor="let fieldRow of fieldAPIs | row:3; let h = index;"> 
        <ng-container *ngFor="let fAPI of fieldRow;">
            <div class="right nowrap widthViewPort10">
                {{ fAPI.name }} :
            </div>
            <div class="marginLeftWholeEm widthViewPort20">
                <div *ngIf="resource[fAPI.name].type !== 'Binary'">
                    <wackadoo-field [f]="resource[fAPI.name]" [mode]="mode"></wackadoo-field>
                </div>
                <div *ngIf="resource[fAPI.name].type === 'Binary'">
                    <wackadoo-field-binary [f]="resource[fAPI.name]" [mode]="mode" [resource]="resource"></wackadoo-field-binary>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
