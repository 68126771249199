<wackadoo-grid-control-bar
    [eventHandler]="eventHandler" 
    [parameters]="parameters" 
    [reloadGridSubject]="reloadGridSubject"
    [showPageControl]="showPageControl"
    [showSortControl]="showSortControl"
    [showSearchBox]="showSearchBox"
    [minSortScreenSize]="minSortScreenSize"
    [disabled]="disabled"
></wackadoo-grid-control-bar>

<!--
    HACK ALERT!
    We do the "div in a div" around this placeholder because if the GridContent div's "next sibling" is the 
    GridButtonBar, the GridButtonBar height gets screwed up when calculating height for the GridContent.
-->
<ng-template #awaitingResources *ngIf="!resources">
    <div>
        Awaiting resources to display...
    </div>
</ng-template>
<ng-template *ngIf="resources && (resources.length == 0)">
    <div>
        No resources to display...
    </div>
</ng-template>
<!--
    The general idea is that we lay the grid out per the fieldAPIs, and populate those cells from Resource Fields.
    If the resource does not HAVE one of those fields, the wackadoo-field itself should handle it properly.

    We simply iterate over the fields in the resource, per the resource defn, and the hide/show field configs,
    unless we have a gridResourceComponentClassName, which we display instead.

    wackadooGridContentPlaceholder USED to be on these two top-level divs...
-->
<div class="marginTopHalfEm marginRightHalfEm fillAvailableHeight wrap-scrollable-table" *ngIf="gridResourceComponentClassName">
    <table class="table table-hover" *ngIf="resources && (resources.length > 0) && (fieldAPIs.length > 0)">
        <thead>
            <tr class="numbered-row">
                <th>
                </th>
                <th>
                    <div class="center" *ngIf="(screenType === 'phone') && !showAllFieldsOnPhone ; else regularSortHeader">
                        <wackadoo-sort-control
                            [eventHandler]="eventHandler" 
                            [parameters]="parameters" 
                            [reloadGridSubject]="reloadGridSubject"
                            [disabled]="disabled"
                        ></wackadoo-sort-control>
                    </div>
                    <ng-template #regularSortHeader>
                        <div class="d-flex " [ngClass]="{ 'flex-row' : leftJustifyGridHeaders , 'justify-content-around' : !leftJustifyGridHeaders  }"> 
                            <ng-container *ngFor="let fAPI of fieldAPIs; let h = index;" >
                                <div class="link nowrap ultralightgray-bg" *ngIf="(fAPI.detailOnly !== true)" (click)="sortByField(fAPI.name)" [ngClass]="{ 'marginLeftWholeEm marginRightWholeEm' : leftJustifyGridHeaders }">
                                    <span *ngIf="fAPI.name === sortBy" class="fas" [ngClass]="{'fa-arrow-up' : (this.sortDirection >= 0), 'fa-arrow-down': (this.sortDirection < 0)}"></span>{{ fAPI.name }}
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>
                </th>
            </tr>
        </thead>
        <tbody *ngIf="startAt">
            <tr class="numbered-row" 
                    [ngClass]="{rollover: gridRollover, 'lightgray-bg': isSelected(r)}" 
                    [title]="gridHint"
                    *ngFor="let r of resources; let i = index;"
                    (click)="clickOnResource(r)"
                >
                <td class="right italic">
                    {{ startAt + i }}
                </td>
                <td class="">
                    <div wackadooResourcePlaceholder [componentClassName]="gridResourceComponentClassName" [resource]="r">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="marginTopHalfEm marginRightHalfEm fillAvailableHeight wrap-scrollable-table" *ngIf="!gridResourceComponentClassName">
    <table class="table table-hover" *ngIf="resources && (resources.length > 0) && (fieldAPIs.length > 0)">
        <thead>
            <tr class="numbered-row">
                <th>
                </th>
                <ng-container *ngIf="(screenType === 'phone') && !showAllFieldsOnPhone ; else regularSortHeader">
                    <th class="center">
                        <wackadoo-sort-control
                            [eventHandler]="eventHandler" 
                            [parameters]="parameters" 
                            [reloadGridSubject]="reloadGridSubject"
                            [disabled]="disabled"
                        ></wackadoo-sort-control>
                    </th>
                </ng-container>
                <ng-template #regularSortHeader>
                    <ng-container *ngFor="let fAPI of fieldAPIs; let h = index;" >
                        <th class="link nowrap"  *ngIf="(fAPI.detailOnly !== true)" (click)="sortByField(fAPI.name)" >
                            <span *ngIf="fAPI.name === sortBy" class="fas" [ngClass]="{'fa-arrow-up' : (this.sortDirection >= 0), 'fa-arrow-down': (this.sortDirection < 0)}"></span>{{ fAPI.name }}
                        </th>
                    </ng-container>
                </ng-template>
            </tr>
        </thead>
        <tbody *ngIf="startAt">
            <tr class="numbered-row" 
                    [ngClass]="{rollover: gridRollover, 'lightgray-bg': isSelected(r)}" 
                    [title]="gridHint"
                    *ngFor="let r of resources; let i = index;"
                    (click)="clickOnResource(r)"
                >
                <td class="right italic">
                    {{ startAt + i }}
                </td>
                <ng-container *ngFor="let fAPI of fieldAPIs; let h = index;" >
                    <td class="" *ngIf="(fAPI.detailOnly !== true)">
                        <wackadoo-field [f]="r[fAPI.name]" *ngIf="r[fAPI.name]"></wackadoo-field>
                    </td>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>
