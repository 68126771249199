import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-about-history',
  templateUrl: './about-history.component.html',

})
export class AboutHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
