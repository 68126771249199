<div class="nowrap">
  <span class="" ngbDropdown display="static" placement="bottom-left">
    <button 
        class="btn btn-outline-secondary dropdown-toggle actionButton wackadoo-grid-control round-all-corners" 
        type="button" 
        [disabled]="disabled"
        ngbDropdownToggle id="pageSizeDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
    >
      <span *ngIf="screenSize > 500">Size </span>({{ pageSize }})
    </button>
    <div class="dropdown-menu inherit-font-size paddingTop0 paddingBottom0" ngbDropdownMenu aria-labelledby="pageSizeDropdown">
      <a class="dropdown-item round-all-corners" (click)="setPageSize(shortPage)">{{ shortPage }}</a>
      <a class="dropdown-item round-all-corners" (click)="setPageSize(mediumPage)">{{ mediumPage }}</a>
      <a class="dropdown-item round-all-corners" (click)="setPageSize(longPage)">{{ longPage }}</a>
    </div>
  </span>
  <span class="wackadoo-grid-control marginLeftHalfEm marginRightHalfEm center middle default-cursor-important no-border-important transparent-bg-important" *ngIf="screenSize > 350">
    {{ startAt }} to {{ endAt }} of {{ hits }}
  </span>
  <span class="btn-group">
    <button title="First"     [disabled]="disabled" data-abbrev="" class="titleOrAbbrevAfterIcon btn btn-outline-secondary wackadoo-grid-control round-left-corners"   type="button" (click)="firstPage()"><span class="fas fa-fast-backward"></span></button>
    <button title="Page Up"   [disabled]="disabled || (startAt === 1)" data-abbrev="" class="titleOrAbbrevAfterIcon btn btn-outline-secondary wackadoo-grid-control"                      type="button" (click)="pageUp()"><span class="fas fa-backward"></span></button>
    <button title="Page Down" [disabled]="disabled || (hits < (startAt + pageSize))" data-abbrev="" class="titleOrAbbrevBeforeIcon btn btn-outline-secondary wackadoo-grid-control"                     type="button" (click)="pageDown()"><span class="fas fa-forward"></span></button>
    <button title="Last"      [disabled]="disabled || (hits < (startAt + pageSize))" data-abbrev="" class="titleOrAbbrevBeforeIcon btn btn-outline-secondary wackadoo-grid-control round-right-corners" type="button" (click)="lastPage()"><span class="fas fa-fast-forward"></span></button>
  </span>
</div>
