import { Component, OnInit } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';

@Component({
  selector: 'wackadoo-shopping-cart-log-detail',
  templateUrl: './shopping-cart-log-detail.component.html',
})
export class ShoppingCartLogDetailComponent extends ResourceComponent implements OnInit{

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ShoppingCartLogDetailComponent';

  shoppingCartComponentClassName = 'ShoppingCartItemDetailComponent';

  isCorporateSponsor = false;

  ngOnInit(): void {
    super.ngOnInit();

    if (this.resource.company.isPopulated) {
      this.isCorporateSponsor = true;
    }
  }

}
