import { Component } from '@angular/core';
import { Globals } from '../../../services/global.service';

@Component({
  selector: 'wackadoo-policy-billing',
  templateUrl: './policy-billing.component.html',

})
export class PolicyBillingComponent {

  domain = Globals.domain;

  constructor() { }
}
