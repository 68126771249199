import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { SystemMessageContent } from './system-message-content.model';

@Component({
  selector: 'wackadoo-system-message',
  templateUrl: './system-message.component.html',

})
export class SystemMessageComponent implements OnInit, OnDestroy {

  visible = false;

  showModalSubscription: Subscription;

  timestamp: Date = null;
  title = '';
  message = '';

  constructor(
    private _modalDialogService: ModalDialogService,
  ) { }

  ngOnInit(): void {
    this.showModalSubscription = this._modalDialogService.showSystemMessageSubject.subscribe(
      (systemMessageContent: SystemMessageContent) => {
        if (systemMessageContent) {
          this.timestamp = (systemMessageContent.timestamp && (systemMessageContent.timestamp.length > 0) ? new Date(systemMessageContent.timestamp) : null);
          this.title = systemMessageContent.title;
          this.message = systemMessageContent.message.replace(/\\n/g, '\n').replace(/\n/g, '<br/>');
          this.visible = true;
        } else {
          this.timestamp = null;
          this.title = '';
          this.message = '';
          this.visible = false;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }
  }

  onClose(): void {
    this.visible = false;
    this.message = '';
    this.title = '';
  }

}
