import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from '../../pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-import-template-fields',
  templateUrl: '../repository-page/repository-page.component.html',
})
export class ImportTemplateFieldsComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.detailContentComponentClassName = 'ImportTemplateFieldsDetailComponent';
    super.ngOnInit();
  }
}
