import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-policy-pricing',
  templateUrl: './policy-pricing.component.html',

})
export class PolicyPricingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
