<span *ngIf="f">
    <!--
    <span>
        {{ f.displayComponent }} / {{ f.fileType}}
    </span>
        special displayComponent fields get specialized display components
    -->
    <wackadoo-field-binary-html  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="f.displayComponent === 'html'"></wackadoo-field-binary-html>
    <wackadoo-field-binary-html  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="f.displayComponent === 'xhtml'"></wackadoo-field-binary-html>
    <wackadoo-field-binary-image [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="f.displayComponent === 'image'"></wackadoo-field-binary-image>
    <!-- note: at the time of this writing, none of these displayComponent values are used by any web app, but are included here for future expansion - -->
    <wackadoo-field-binary-text  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="f.displayComponent === 'txt'"></wackadoo-field-binary-text>
    <wackadoo-field-binary-text  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="f.displayComponent === 'text'"></wackadoo-field-binary-text>
    <wackadoo-field-binary-text  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="f.displayComponent === 'json'"></wackadoo-field-binary-text>
    <wackadoo-field-binary-text  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="f.displayComponent === 'xml'"></wackadoo-field-binary-text>
    <wackadoo-field-binary-audio [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="f.displayComponent === 'audio'"></wackadoo-field-binary-audio>
    <wackadoo-field-binary-video [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="f.displayComponent === 'video'"></wackadoo-field-binary-video>

    <!-- 
        if we have a binary displayComponent AND we have a value for f.fileType... 
    -->
    <wackadoo-field-binary-text  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="(f.displayComponent === 'binary') && (f.fileType === 'sql')"></wackadoo-field-binary-text>
    <wackadoo-field-binary-html  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="(f.displayComponent === 'binary') && (f.fileType === 'html')"></wackadoo-field-binary-html>
    <wackadoo-field-binary-html  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="(f.displayComponent === 'binary') && (f.fileType === 'xhtml')"></wackadoo-field-binary-html>
    <!-- ghtml will 99.999% sure be replaced with json formatting when we get that part of the UX migration... -->
    <wackadoo-field-binary-text  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="(f.displayComponent === 'binary') && (f.fileType === 'ghtml')"></wackadoo-field-binary-text>
    <!-- note: at the time of this writing, none of these f.fileType values are used by any web app, but are included here for future expansion - -->
    <wackadoo-field-binary-text  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="(f.displayComponent === 'binary') && (f.fileType === 'txt')"></wackadoo-field-binary-text>
    <wackadoo-field-binary-text  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="(f.displayComponent === 'binary') && (f.fileType === 'text')"></wackadoo-field-binary-text>
    <wackadoo-field-binary-text  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="(f.displayComponent === 'binary') && (f.fileType === 'json')"></wackadoo-field-binary-text>
    <wackadoo-field-binary-text  [resource]="resource" [f]="f" [mode]="mode" [onChange]="onChange" [title]="title" *ngIf="(f.displayComponent === 'binary') && (f.fileType === 'xml')"></wackadoo-field-binary-text>

    <!-- 
        We simply show file upload/download buttons when...
        A. we have a binary displayComponent AND we have NO value for f.fileType,
        OR B. there's no value at all for displayComponent (which, BTW, should never happen...) 
    --> 
    <ng-container *ngIf="(f.displayComponent === 'binary') && ( !f.hasOwnProperty('fileType') || ((f.fileType !== 'sql') && (f.fileType !== 'html') && (f.fileType !== 'xhtml') && (f.fileType !== 'ghtml') && (f.fileType !== 'json') && (f.fileType !== 'txt') && (f.fileType !== 'text')) )">
        <ng-container *ngIf="mode === 'read' ; else writeMode">
            <span *ngIf="f.value === null ; else weHaveFileContent">
                There Is No File To Download...
            </span>
            <ng-template #weHaveFileContent>
                <div>
                    <button 
                        class="btn btn-secondary"
                        (click)="downloadFile()"
                    >
                        Download File
                    </button>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #writeMode>
            <div class="d-flex justify-content-between">
                <div>
                    <ng-container *ngIf="f.changed === true ; else fileContentsUnchanged">
                        <div class="fieldChanged">
                            New File Content Is Now Cached...
                        </div>
                        <div class="italic">
                            (It will be uploaded when you click "Save"...)
                        </div>
                        <div class="marginTopHalfEm">
                            <span class="bold">
                                {{ f.fileName }}
                            </span>
                            ({{ f.fileSize }} bytes)
                        </div>
                        <div>
                            {{ f.fileType }}
                        </div>
                        <div>
                            File Last Modified: {{ f.fileDate.toLocaleString() }}
                        </div>
                    </ng-container>
                    <ng-template #fileContentsUnchanged>
                        <button 
                            class="btn btn-secondary"

                            wackadooFileRead
                            [newFileContent]="loadFileContent"
                            [validExtensions]="validExtensions"
                            [maxFileSizeMB]="f.maxFileSizeMB"
                        >
                            Upload New File Contents
                        </button>
                        <div>
                            (click button, or drop a {{formattedValidExtensions}} file here...) 
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="f.isPopulated">
                    <button type="button" class="btn btn-secondary" title="Clear File Contents" (click)="clearBinaryContent()" ><span class="fas fa-trash-alt"></span> Clear File</button>
                </div>
            </div>
        </ng-template>
    </ng-container>
</span>
