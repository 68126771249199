<span *ngIf="f">
    <ng-container *ngIf="mode === 'read' ; else writeMode">
       <pre class="fillAvailableSpace">{{ f.text }}</pre>
    </ng-container>
    <ng-template #writeMode>
        <textarea
            class="form-control no-wrap"
            [placeholder]="(f.required ? '* ' : '') + f.name"
            [name]="f.name"
            [value]="f.text" 
            [disabled]="f.readOnly"
            (input)="onInputChange($event)"
            (blur)="forceValidValue($event)"
        ></textarea>
    </ng-template>
</span>
