import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from '../../pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-import-templates',
  templateUrl: '../repository-page/repository-page.component.html',
})
export class ImportTemplatesComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.detailContentComponentClassName = 'ImportTemplatesDetailComponent';
    super.ngOnInit();
  }
}
