import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { WField } from '../../data/field.model';
import { WResource } from '../../data/resource.model';
import { UserInterfaceService } from '../../services/user-interface.service';
import { EventServerService } from '../../services/event-server.service';
import { Subject, Subscription } from 'rxjs';
import { WEvent } from '../../data/event.model';
import { ScreenType } from '../../services/screen-type.enum';

@Component({
  selector: 'wackadoo-grid',
  templateUrl: './grid.component.html',
})
export class GridComponent implements OnInit, OnChanges, OnDestroy {

  @Input() eventHandler: string;
  @Input() action: string;
  @Input() parameters: any;

  @Input() gridFieldsToHide: string [] = [];
  @Input() gridFieldsToShow: string [] = [];

  @Input() leftJustifyGridHeaders = false;

  @Input() gridResourceComponentClassName: string = null;

  @Input() resourceClickSubject: Subject<WResource> = null;
  @Input() postLoadEventSubject: Subject<WEvent> = null;

  @Input() gridRollover = false;
  @Input() gridHint = '';

  @Input() showPageControl = true;
  @Input() showSortControl = false;
  @Input() showSearchBox = true;
  @Input() minSortScreenSize = 575;

  @Input() disabled = false;

  @Input() gridName = null;

  startAt = 1;

  sortBy: string;
  sortDirection = 1; // >= 0 means ascending, < 0 means descending...

  @Input() resources: WResource [] = [];

  fieldAPIs: WField [] = [];

  reloadGridSubject: Subject<void> = null;
  reloadGridSubscription: Subscription = null;

  @Input() selectedResource: WResource = null;

  @Input() fireOnInit = false;
  @Input() debug = false;

  @Input() screenType: ScreenType = null;

  @Input() showAllFieldsOnPhone = false;
  @Input() hideSelectedFKeysInGrid = false;

  constructor(
    public userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
  ) {
  }

  ngOnInit(): void {

    if (!this.gridName) {
      this.gridName = this.eventHandler;
    }

    this.breakoutParmsOfInterest();
    this.defineColumnsToShow();

    this.reloadGridSubject = new Subject<void>();

    this.reloadGridSubscription = this.reloadGridSubject.subscribe(
      () => {
        this.reloadGrid();
      }
    );

    // console.log('Grid.ngOnInit()', this.parameters, this.resources);

    if (this.fireOnInit) {
      this.reloadGrid();
    }
  }

  ngOnDestroy(): void {
    if (this.reloadGridSubscription) {
      this.reloadGridSubscription.unsubscribe();
    }
  }

  ngOnChanges(sc: SimpleChanges): void {

    if (this.debug) { console.log('Grid.ngOnChanges() - enter', sc, this.eventHandler, this.action, this.parameters, this.resources); }

    if (sc.parameters) {
      this.parameters = sc.parameters.currentValue;
    }

    this.breakoutParmsOfInterest();

    // get the field defns for the eventHandler, whether it changed or not...
    this.defineColumnsToShow();

    // if (this.debug) { console.log('Grid.ngOnChanges() - exit', this.eventHandler, this.action, this.parameters, this.resources, this.fieldAPIs); }
  }

  defineColumnsToShow(): void {

    if (this.debug) { console.log('Grid.defineColumnsToShow() - enter', this.eventHandler, this.action, this.parameters, this.resources, this.gridFieldsToHide, this.gridFieldsToShow, this.showAllFieldsOnPhone, this.hideSelectedFKeysInGrid); }

    this.fieldAPIs = this.eventServerService.getFieldDefinitionsInOrder(this.eventHandler);

    // console.log('Grid.ngOnChanges() - this.gridFieldsToHide.length: ' + this.gridFieldsToHide.length + ', this.gridFieldsToShow.length: ' + this.gridFieldsToShow.length);

    // if we're on a phone, we USUALLY show just the key field for the resource...

    if ((this.screenType === 'phone') && !this.showAllFieldsOnPhone) {
      this.fieldAPIs = [ this.fieldAPIs.find( fapi => fapi.key ) ];
      this.fieldAPIs[0].detailOnly = false;
    } else {
      // by default, we hide the key field... (unless it's called out in "gridFieldsToShow"...)
      if (this.fieldAPIs) {
        this.fieldAPIs.forEach( (wf: WField) => {
          if (wf.key) {
            wf.detailOnly = true;
          }
        });
      }

      // if we hide or show the grid columns provided above...
      if (this.gridFieldsToHide.length > 0) {
        this.hideGridFields(this.gridFieldsToHide);
      } else if (this.gridFieldsToShow.length > 0) {
        this.showGridFields(this.gridFieldsToShow);
      }

      // now, we check to see if we want to filter out fkey columns where that type of resource is selected...
      if (this.fieldAPIs && this.hideSelectedFKeysInGrid) {
        this.fieldAPIs.forEach( (wf: WField) => {
          if (wf.foreignKey) {
            // we have to set/re-set this because sometimes we're staying on the same page...
            wf.detailOnly = (this.userInterfaceService.getSelectedResourceByResourceType(wf.foreignType) !== null);
          }
        });
      }
    }

    if (this.debug) { console.log('Grid.defineColumnsToShow() - exit', this.fieldAPIs); }
  }

  reloadGrid(): void {
    try {
      // console.log('Grid.reloadGrid()', this.eventHandler, this.action, this.parameters, new Error().stack);

      if (this.eventHandler) {

        this.breakoutParmsOfInterest();
        this.userInterfaceService.setGridState(this.gridName, this.parameters);

        // console.log('Grid.reloadGrid() - about to fire event:', this.eventHandler, this.action, this.parameters, new Error().stack);

        this.eventServerService.fireEvent(this.eventHandler, this.action, this.parameters).subscribe(
          (event: WEvent) => {
            // console.log('Grid.reloadGrid.event', event);
            if (this.debug) { console.log('Grid.reloadGrid.event', this.gridName, event); }
            if (event.status !== 'OK') {
              throw new Error(event.message);
            }
            this.resources = event.resources;
            this.parameters = event.parameters;

            if (this.postLoadEventSubject) {
              this.postLoadEventSubject.next(event);
            }
          }
        );
      }

    } catch (ex) {
      const msg = 'Grid.reloadGrid() failed!';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  sortByField(fieldName: string): void {

    if (this.sortBy === fieldName) {
      // user is toggling the current sortBy...
      this.sortDirection = -1 * this.sortDirection;
    }

    // re-define the sort for the grid...
    this.parameters.sortBy = fieldName;
    this.parameters.sortDirection = this.sortDirection;
    this.parameters.startAt = 1;

    this.reloadGrid();
  }

  breakoutParmsOfInterest(): void {
    // if (this.debug) { console.log('Grid.breakoutParmsOfInterest()', this.parameters); }

    if (this.parameters) {
      this.startAt = this.parameters.startAt ? this.parameters.startAt : this.startAt;
      this.sortBy = this.parameters.sortBy ? this.parameters.sortBy : this.sortBy;
      this.sortDirection = this.parameters.sortDirection ? this.parameters.sortDirection : this.sortDirection;
    } else {
      const sortByField = this.eventServerService.newResource(this.eventHandler).defaultSortField;
      if (sortByField) {
        this.sortBy = sortByField.name;
        this.sortDirection = sortByField.defaultSortDirection;
      }
    }

    if (this.debug) { console.log('Grid.breakoutParmsOfInterest()', this.parameters, this.startAt, this.sortBy, this.sortDirection); }
  }

  clickOnResource(resource: WResource): void {
    // console.log('clickOnResource()', resource, this.resourceClickSubject);
    if (this.resourceClickSubject) {
      this.resourceClickSubject.next(resource);
    }
  }

  isSelected(resource: WResource): boolean {
    return this.selectedResource && (this.selectedResource.keyField.value === resource.keyField.value);
  }

  hideGridFields(fieldList: string []): void {
    // console.log('hideGridFields()', fieldList);
    // console.log('before', this.fieldAPIs);

    // sometimes, during initialization, this is null the first time in...
    if (!this.fieldAPIs) {
      return;
    }

    this.fieldAPIs.forEach( (wf: WField) => {
      if (fieldList.includes(wf.name)) {
        wf.detailOnly = true;
      }
    });
    // console.log('after', this.fieldAPIs);
  }

  showGridFields(fieldList: string []): void {

    // if (this.debug) { console.log('Grid.showGridFields() - before', fieldList, this.fieldAPIs); }

    // sometimes, during initialization, this is null the first time in...
    if (!this.fieldAPIs) {
      return;
    }

    // Now, we MIGHT be wanting to display columns from JOIN-ed tables, which are NOT part of THIS EH's fieldAPIs...
    // So we add it in from the first resource on the list, assuming it exists...
    if (this.resources && (this.resources.length > 0)) {
      const tempResource = this.resources[0];
      fieldList.forEach(
        (name: string) => {
          if (this.fieldAPIs.findIndex((wf: WField) => name === wf.name) < 0) {
            if (tempResource[name]) {
              const f = tempResource[name].clone;
              delete f.value;
              delete f.displayValue;
              this.fieldAPIs.push(f);
              // if (this.debug) { console.log('Grid.showGridFields() - added', f); }
            }
          }
        }
      );
    }

    // if (this.debug) { console.log('Grid.showGridFields() - during', fieldList, this.fieldAPIs); }

    let defaultSortField: string = null;
    let defaultSortDirection: number = null;

    let n = fieldList.length + 1;

    this.fieldAPIs.forEach(
      (wf: WField) => {
        if (fieldList.includes(wf.name)) {
          wf.number = fieldList.findIndex((s: string) => s === wf.name) + 1;
          wf.detailOnly = false;
          // if (this.debug) { console.log('Grid.showGridFields() - showing', wf.name, wf.number); }
        } else {
          wf.number = n++;
          wf.detailOnly = true;
          // if (this.debug) { console.log('Grid.showGridFields() - hiding', wf.name, wf.number); }
        }
        if (wf.defaultSort) {
          defaultSortField = wf.name;
          defaultSortDirection = wf.defaultSortDirection;
          // if (this.debug) { console.log('Grid.showGridFields() - default sort', defaultSortField, defaultSortDirection); }
        }
      }
    );

    // if (this.debug) { console.log('Grid.showGridFields() - pre-tweak', 'defaultSortField', defaultSortField, 'defaultSortDirection', defaultSortDirection, 'this.sortBy', this.sortBy, 'this.sortDirection', this.sortDirection); }

    if (!this.sortBy) {
      if (defaultSortField) {
        // if (this.debug) { console.log('Grid.showGridFields() - fieldList.includes(defaultSortField)', fieldList.includes(defaultSortField)); }
        if (fieldList.includes(defaultSortField)) {
          this.sortBy = defaultSortField;
          this.sortDirection = defaultSortDirection;
        } else {
          const newDFS = this.fieldAPIs.find((f) => f.number === 1);
          this.sortBy = newDFS.name;
          this.sortDirection = 1;
        }
      }
    }

    // if (this.debug) { console.log('Grid.showGridFields() - pre-sort', this.fieldAPIs); }

    // then we sort the fields by the "number" attribute value... (which are text values...)
    this.fieldAPIs = this.fieldAPIs.sort( (f1: WField, f2: WField) => {
        const sortValue = Number(f1.number) > Number(f2.number) ? 1 : (Number(f1.number) === Number(f2.number) ? 0 : -1);
        // if (this.debug) { console.log('Grid.showGridFields() - mid-sort', f1, f2, f1.number, f2.number, Number(f1.number) > Number(f2.number), Number(f1.number) === Number(f2.number), sortValue); }
        return (sortValue);
    });

    // if (this.debug) { console.log('Grid.showGridFields() - after', this.fieldAPIs, this.fieldAPIs.find((fapi) => fapi.detailOnly === false), this.fieldAPIs.find((fapi) => fapi.detailOnly === true)); }
  }

}
