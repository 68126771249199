import { Component, OnInit } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';
import { WEvent } from 'src/app/client-core/data/event.model';
import { WString } from 'src/app/client-core/data/field.model';
import { WResource } from 'src/app/client-core/data/resource.model';

@Component({
  selector: 'wackadoo-smtp-server-detail-content',
  templateUrl: './smtp-server-detail-content.component.html',
})
export class SmtpServerDetailContentComponent extends DetailContentComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'SmtpServerDetailContentComponent';

  domain: WString = null;
  mxEntries: WResource [] = null;
  matchingSMTPServers: WResource [] = null;

  ngOnInit(): void {
    super.ngOnInit();

    this.domain = new WString('domain', '');
    this.domain.length = 50;

    this.mxEntries = null;
    this.matchingSMTPServers = null;
  }

  testRegEx(): void {

    this.mxEntries = null;
    this.matchingSMTPServers = null;

    const parms: any = {};
    parms.domain = this.domain.value;

    this.eventServerService.fireEvent('SMTPServers', 'listMXEntries', parms).subscribe(
      (event: WEvent) => {
        console.log(event);
        this.mxEntries = event.resources ? event.resources : [];
      }
    );

    this.eventServerService.fireEvent('SMTPServers', 'matchDomain', parms).subscribe(
      (event: WEvent) => {
        console.log(event);
        this.matchingSMTPServers = event.resources ? event.resources : [];
      }
    );
  }

}
