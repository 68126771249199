import { Component, Input } from '@angular/core';
import { WField } from '../../data/field.model';
import { UtilityLibService } from '../../services/utility-lib.service';

@Component({
  selector: 'wackadoo-file-details',
  templateUrl: './file-details.component.html',
})
export class FileDetailsComponent {

  @Input() title = 'File Details';
  @Input() fileContentField: WField = null;

  constructor(
    public utilityLibService: UtilityLibService
  ) {
  }

  formatNumber(n: number): string {
    return this.utilityLibService.formatMemorySize(n, 1);
  }

}
