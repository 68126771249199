<div *ngIf="!isLoggedIn">
	<ngb-carousel #carousel interval="5000">
		<ng-template ngbSlide *ngFor="let img of images; let idx = index">
			<div class="carousel-caption">
				<div class="right staticCarouselText">
					<div class="row">
						<div class="col-sm-6">
						</div>
						<div class="col-sm-6 black">
							<div>Welcome to {{ domain }}!</div> 
							<div class="small" *ngIf="domain === 'wackadoo.org'">(a division of <a href="https://www.wackadoo.info">wackadoo.info</a>)</div>
							<h1 class="marginTop2 green">
								<!-- REMEMBER: ApplicationMetaData.list() will auto-add the appID, but "search()" does not... -->
								<span *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{appID:1,type:'tagline',sequence:1};">
									{{ r.value.value }}
								</span>
							</h1>
						</div>
					</div>
					<div class="row" *ngIf="screenType === 'fullscreen'">
						<div class="col-sm-6">
						</div>
						<div class="black col-sm-6">
							<div *ngIf="domain === 'wackadoo.info'">
								Impactful, highly-targeted business web apps that are simple, 
								straight-forward and easy to use.
							</div>
							<div *ngIf="domain === 'wackadoo.org'">
								Subsized (sometimes donated...) web apps that assist ministries, churches, 
								volunteers and non-profit organizations serving people in need.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="faded-image image{{idx}}">
				<!-- 
					We don't put the url in here. Instead, we set background-image on the  
					imageX class (using code...) in order to implement a CSS fade effect...
				-->
				<img src=" " alt="" />
			</div>
		</ng-template>
	</ngb-carousel>
	<div class="" *ngIf="screenType !== 'fullscreen'">
		<div class="">
			<div class="well right">
				<div *ngIf="domain === 'wackadoo.info'">
					Impactful, highly-targeted business web apps that are simple, 
					straight-forward and easy to use.
				</div>
				<div *ngIf="domain === 'wackadoo.org'">
					Subsized (sometimes donated...) web apps that assist ministries, churches, 
					volunteers and non-profit organizations serving people in need.
				</div>
			</div>
		</div>
	</div>
	<div class="marginTopHalfEm marginLeftWholeEm marginRightWholeEm marginBottonWholeEm"
				wackadooMaximizeContentHeight
				maxHeightThingBelowSelector=".wackadoo-footer"
				[maxHeightFudgeFactor]="-16"
	>
		<h3 class="center green">
			<span *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{appID:1,type:'tagline',sequence:2};">
				{{ r.value.value }}
			</span>
		</h3>
		<div class="row large">
			<div class="col-sm-4"> 
                <div class="center">
                    <a href="http://www.closingpro.net" target="_blank">
											<img src="./assets/pics/closingpro.net.png" alt="ClosingPro.net" />
                    </a>
                    <p [ngClass]="{small:(screenType === 'phone')}">
                        <a href="http://www.closingpro.net" target="_blank">ClosingPro</a> is a real estate transaction coordinator's best friend, supporting 
                        fully customizable contract-to-close and listing processes.
										</p>
										<p [ngClass]="{small:(screenType === 'phone')}">
											<a href="http://www.closingpro.net/AccountManager/Pricing" target="_blank">
												Click here
											</a>
											for pricing.
										</p>
                </div>
			</div>
			<div class="col-sm-4">
                <div class="center">
										<a href="http://www.wackadoo.org" target="_blank">
											<img src="./assets/pics/wackadoo.org.png" alt="wackadoo.org" />
                    </a>
                    <p [ngClass]="{small:(screenType === 'phone')}">
                        <a href="http://www.wackadoo.org" target="_blank">wackadoo.org</a> is where we support organizations  
                        serving people in need by offering subsidized <span class="italics">(sometimes donated...)</span> 
                        technology consulting and cloud-based web apps that address the specific needs of ministries, churches, 
                        volunteers and non-profit organizations.
										</p>
           					<p [ngClass]="{small:(screenType === 'phone')}">
											<a href="http://www.wackadoo.org/Pricing" target="_blank">
												Click here
											</a>
											for pricing.
										</p>
                </div>
			</div>
			<!-- now we mimic an app to talk about custom web app development -->
			<div class="col-sm-4">
				<div class="center">
					<a class="large" [routerLink]="['about-custom-dev']">
						<img class="height-3-em" src="./assets/pics/logo.png" alt="" />
						<span class="bold italic large">
							We Build Custom Web Apps!
						</span>
					</a>
					<p [ngClass]="{small:(screenType === 'phone')}">
						If you have ever thought 
						<br/>
						<span class="italics">"I just need a web site that would do this one thing for me,
						but nobody has something that fits my specific situation"</span>, 
						<br/>
						we can probably do that for you.
					</p>
					<p [ngClass]="{small:(screenType === 'phone')}">
						<a [routerLink]="['contact-us']">Just give us a call</a>.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="marginWholeEm" *ngIf="user && isLoggedIn">
	<div class="marginWholeEm jumbotron shadow paddingTopWholeEm paddingBottomWholeEm">
		<h2>Welcome {{ user.fullName }}</h2>
		<h3> from {{ user.accountName }}!</h3>
		<br/>
		<p>
			userName: {{ user.userName }}
			<br/>
			login time: {{ user.lastAccess }})
		</p>
	</div>
	<!--
	    we check for user because sometimes we don't have one fast enough on the public page, 
    	like when we immediately jump to the "firstPage" due to business rule processing...
	-->
	<div *ngIf="accountManagerIsRootApplication">
		<div class="marginTop6 card large" *ngIf="user.isAdminOrBelow">
			<div class="card-header">
				Your Applications
			</div>
			<div class="card-body">
				<span *wackadooResourceBlock="let r1 of []; eventHandler:'RegisteredApplications'; action:'list'; parms:{sortBy:'appName', sortDirection: 1};">
					<span class="" *ngIf="(r1.appName) && (r1.appName.value !== 'AccountManager')">
						<span *ngIf="r1.expiration && isNotExpired(r1.expiration.value)">
							<span *wackadooResourceBlock="let r2 of []; eventHandler:'Applications'; action:'list'; parms:{appID: r1.appID.value};">
								<a href="./{{ r2.appName.value }}">
									<!-- we hack out the prefix for now... -->
									<span class="fas fa-{{ r2.appIcon.value }}"></span>
									{{ r2.appName.value }}</a> 
								<p [ngClass]="{small:(screenType === 'phone')}">
									{{ r2.appDescription.value }}
								</p>
							</span>
						</span>
					</span>
				</span>
			</div>
		</div>
	</div>
</div>
