<div *ngIf="visible">
    <div class="modal-backdrop" (click)="onCancel()"></div>
    <div class="modal-box">
        <p class="bold">Search {{ ehName }}</p>
        <p>Please enter query:</p>
        <input class="form-control" type="text" [(ngModel)]="query" wackadooAutoFocus/>
        <div class="marginTopWholeEm d-flex justify-content-around">
            <div>
                <button class="btn btn-secondary" (click)="onOK()">Search</button>
            </div>
            <div>
                <button class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
