import { Directive, ViewContainerRef, Input, OnInit, ComponentRef, OnDestroy, OnChanges, AfterViewInit } from '@angular/core';
import { WResource } from '..//data/resource.model';
import { UserInterfaceService } from '../services/user-interface.service';
import { FieldMode } from '../data/field/field-mode.model';

@Directive({
  selector: '[wackadooResourcePlaceholder]'
})
export class ResourcePlaceholderDirective implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() componentClassName: string = null;
  @Input() resource: WResource = null;
  @Input() mode: FieldMode = FieldMode.read;
  @Input() otherInputs: any = {};

  private _dynamicComponentRefToDestroy: ComponentRef<any> = null;

  constructor(
    public viewContainerRef: ViewContainerRef,
    public userInterfaceService: UserInterfaceService,
  ) {
  }

  ngOnInit(): void {
    // console.log('ResourcePlaceholderDirective.ngOnInit()', this.componentClassName, this.resource.keyField.value);
  }

  ngAfterViewInit(): void {
    // console.log('ResourcePlaceholderDirective.ngAfterViewInit()', this.componentClassName, this.resource.keyField.value);

    // we ALWAYS pass in the resource and mode...
    const inputsAsParms = { resource: this.resource, mode: this.mode };

    // and if there are any other inputs, we pass them in too...
    for (const [n, v] of Object.entries(this.otherInputs)) {
      inputsAsParms[n] = v;
    }

    this._dynamicComponentRefToDestroy = this.userInterfaceService.loadComponent(this.viewContainerRef, this.componentClassName, inputsAsParms);
  }

  ngOnChanges(): void {
    // console.log('ResourcePlaceholderDirective.ngOnChanges()', this.componentClassName, this.resource.keyField.value);

    if (this._dynamicComponentRefToDestroy) {
      // we ALWAYS pass in the resource and mode...
      const inputsAsParms = { resource: this.resource, mode: this.mode };

      // and if there are any other inputs, we pass them in too...
      for (const [n, v] of Object.entries(this.otherInputs)) {
        inputsAsParms[n] = v;
      }

      // now that we have the component, we load in the input values we want...
      if (inputsAsParms) {
        for (const [name, value] of Object.entries(inputsAsParms)) {
          this._dynamicComponentRefToDestroy.instance[name] = value;
        }
      }
    }

  }

  ngOnDestroy(): void {
    // console.log('ResourcePlaceholderDirective.ngOnDestroy()', this.componentClassName, this.resource.keyField.value);
    if (this._dynamicComponentRefToDestroy) {
      this._dynamicComponentRefToDestroy.destroy();
    }
  }

  loadTheComponent(): void {
    // console.log('ResourcePlaceholderDirective.loadThisSucker()', this.componentClassName, this.resource.keyField.value);

  }

}
