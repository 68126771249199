import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[wackadooAppSpecificPublicContentPlaceholder]'
})
export class AppSpecificPublicContentPlaceholderDirective {

  constructor(
    public viewContainerRef: ViewContainerRef,
  ) { }

}
