<div class="row left">
	<!-- REMEMBER: ApplicationMetaData.list() will auto-add the appID, but "search()" does not... -->
	<div class="col-sm-8">
		<div>
			<wackadoo-carousel [smallVersion]="false"></wackadoo-carousel>
			<div class="marginHalfEm">
				<div class="italic large center">
					<span *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'tagline','sequence':'1'};">
						{{ r.value.value }}
					</span>
				</div>
				<div *ngIf="(screenType === 'phone')">
					<span *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'paragraph','sequence':'1'};">
						<p>{{ r.value.value }}</p>
					</span>
				</div>
				<div *ngIf="(screenType !== 'phone')">
					<span *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'paragraph','sortBy':'sequence'};">
						<p>{{ r.value.value }}</p>
					</span>
				</div>
			</div>
		</div>
	</div>
	<div class="col-sm-4">
		<div>
			<div class="well">
				<div class="large bold">Features</div>
				<div class="wackadoo-line marginBottomHalfEm"></div>
				<ul>
					<li *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'feature','sortBy':'sequence'};">
						{{ r.value.value }}
					</li>
				</ul>
			</div>
			<div class="well">
				<div class="large bold">Benefits</div>
				<div class="wackadoo-line marginBottomHalfEm"></div>
				<ul>
					<li *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'benefit','sortBy':'sequence'};">
						{{ r.value.value }}
					</li>
				</ul>
			</div>
			<div class="marginLeftWholeEm marginRightWholeEm">
				<div class="marginLeftWholeEm marginRightWholeEm" *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'quote','sortBy':'sequence'}; ">
					<div class="floatGroup" [ngClass]="{ marginBottomHalfEm : (r.name.value !== null) && (r.name.value !== '') }">
						<div class="floatLeft quote">{{ r.value.value }}</div>
						<div class="floatRight quote-attribution">
							{{ r.name.value }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
