import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserAuthService } from '../../services/user-auth.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { Subscription } from 'rxjs';
import { User } from '../../data/user.model';
import { ScreenType } from '../../services/screen-type.enum';

@Component({
  selector: 'wackadoo-page',
  templateUrl: './page.component.html',

})
export class PageComponent implements OnInit, OnDestroy {

  screenType: ScreenType;
  screenSize: number;
  private _screenTypeSubscription: Subscription;

  user: User;

  private _userAuthChangeSubscription: Subscription;

  constructor(
    public userAuthService: UserAuthService,
    public userInterfaceService: UserInterfaceService
  ) {
  }

  ngOnInit(): void {

    this.screenType = this.userInterfaceService.screenType.getValue();
    this.screenSize = window.outerWidth;

    this._screenTypeSubscription = this.userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        this.screenType = screenType;
        this.screenSize = window.outerWidth;
        // console.log('page changing size/type: ' + this.screenType + ' - width: ' + this.screenSize);
      }
    );

    this.user = this.userAuthService.currentUser.getValue();

    this._userAuthChangeSubscription = this.userAuthService.currentUser.subscribe(
      (user: User) => {
        this.user = user;
      }
    );

  }

  ngOnDestroy(): void {
    if (this._screenTypeSubscription) {
      this._screenTypeSubscription.unsubscribe();
    }

    if (this._userAuthChangeSubscription) {
      this._userAuthChangeSubscription.unsubscribe();
    }
  }
}
