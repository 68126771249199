import { Component, OnInit, ViewChild, OnDestroy, Renderer2, AfterViewInit, Input } from '@angular/core';
import { AppHeaderPlaceholderDirective } from './app-header-placeholder.directive';
import { MenuPlaceholderDirective } from './menu-placeholder.directive';
import { PagePlaceholderDirective } from './page-placeholder.directive';
import { UserInterfaceService } from '../../services/user-interface.service';
import { UserAuthService } from '../../services/user-auth.service';
import { Subscription } from 'rxjs';
import { User } from '../../data/user.model';
import { ScreenType } from '../../services/screen-type.enum';
import { Globals } from '../../services/global.service';

@Component({
  selector: 'wackadoo-main',
  templateUrl: './main.component.html',

})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(AppHeaderPlaceholderDirective, {static: true}) appHeaderLocation: AppHeaderPlaceholderDirective;
  @ViewChild(MenuPlaceholderDirective, {static: true}) menuLocation: MenuPlaceholderDirective;
  @ViewChild(PagePlaceholderDirective, {static: true}) pageLocation: PagePlaceholderDirective;

  @Input() showMenu = true;

  userAuthChangedSubscription: Subscription;

  isLoggedIn: boolean;

  screenType: ScreenType;
  screenTypeSubscription: Subscription;
  screenSizeSubscription: Subscription;

  pageName = '';
  pageNameSubscription: Subscription = null;

  sideNavTogglerSubscription: Subscription;

  buildMenuSubscription: Subscription;

  appChangeSubscription: Subscription;

  constructor(
    private _userInterfaceService: UserInterfaceService,
    private _userAuthService: UserAuthService,
    private _renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {

    this.userAuthChangedSubscription = this._userAuthService.currentUser.subscribe(
      (user: User) => {
        if (user) {
          // console.log('main - detected userAuth change - user: ' + JSON.stringify(user));
          this.isLoggedIn = this._userAuthService.isLoggedIn;
          if (this.isLoggedIn || this._userInterfaceService.showPublicMenu) {
            this._userInterfaceService.loadComponent(this.appHeaderLocation.viewContainerRef, Globals.thisApplication + 'ApplicationHeaderComponent', {}, true);
            this.showSideNavMenu();
          } else {
            this.clearAppHeader();
            this.hideSideNavMenu();
          }
        }
      }
    );

    this.screenType = this._userInterfaceService.screenType.getValue();

    this.screenTypeSubscription = this._userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        // console.log('Main.screenTypeSubscription()', screenType);
        this.displayMenusProperly();
        this.screenType = screenType;
      }
    );

    // HACKALERT: Now, because the screenType was not properly initialized on initial load, on a non-full-screen desktop browser...
    window.setTimeout(
      () => {
        this._userInterfaceService.resetScreenType();
      }
      , 667
    );

    this.screenSizeSubscription = this._userInterfaceService.screenSizeChange.subscribe(
      () => {
        this.displayMenusProperly();
      }
    );

    this.pageName = this._userInterfaceService.currentPage.getValue();

    this.pageNameSubscription = this._userInterfaceService.currentPage.subscribe(
      (pageName: string) => {
        this.pageName = pageName;
      }
    );

    this.sideNavTogglerSubscription = this._userInterfaceService.sideNavMenuVisible.subscribe(
      (visible: boolean) => {
        // console.log('Main.sideNavTogglerSubscription()', 'visible: ' + visible);
        if ((this.isLoggedIn || this._userInterfaceService.showPublicMenu) && visible) {
          this.showSideNavMenu();
        } else {
          this.hideSideNavMenu();
        }
      }
    );

    this.buildMenuSubscription = this._userInterfaceService.buildMenu.subscribe(
      () => {
        // console.log('main.buildMenuSubscription()');
        this.displayMenusProperly();
      }
    );

    this.displayMenusProperly();
  }

  ngAfterViewInit(): void {
    this._userInterfaceService.loadComponent(this.appHeaderLocation.viewContainerRef, Globals.thisApplication + 'ApplicationHeaderComponent', {}, true);
  }

  ngOnDestroy(): void {
    if (this.userAuthChangedSubscription) {
      this.userAuthChangedSubscription.unsubscribe();
    }
    if (this.screenTypeSubscription) {
      this.screenTypeSubscription.unsubscribe();
    }
    if (this.screenSizeSubscription) {
      this.screenSizeSubscription.unsubscribe();
    }
    if (this.pageNameSubscription) {
      this.pageNameSubscription.unsubscribe();
    }
    if (this.buildMenuSubscription) {
      this.buildMenuSubscription.unsubscribe();
    }
    if (this.appChangeSubscription) {
      this.appChangeSubscription.unsubscribe();
    }
  }

  displayMenusProperly(): void {
    // console.log('Main.displayMenusProperly() - logged in? ' + this.isLoggedIn + ', ' + this.screenType);
    if ((this.isLoggedIn || this._userInterfaceService.showPublicMenu) && (this.screenType === 'fullscreen')) {
      this.showSideNavMenu();
    } else {
      this.hideSideNavMenu();
    }
  }

  clearAppHeader(): void {
    // console.log('main - clearAppHeader()');
    this.appHeaderLocation.viewContainerRef.clear();
  }

  /****************************************************************
     Side menu navigation controls...
   ****************************************************************/

  /* Set the width and height of the side navigation to auto and the left margin of the page content to X */
  showSideNavMenu(): void {
    // console.log('main - showSideNavMenu()');
    this._renderer.setStyle(this.menuLocation.elementRef.nativeElement, 'width', 'auto');
    this._renderer.setStyle(this.menuLocation.elementRef.nativeElement, 'height', 'auto');

    // so if we don't pause to let the auto take effect, we will always get width === 0...
    // this is because Angular is not instantly picking up the change to the DOM.
    window.setTimeout(() => {
      const newWidth = this.menuLocation.elementRef.nativeElement.offsetWidth;
      if (this.screenType === ScreenType.fullscreen) {
        // console.log('main - showSideNavMenu() - newWidth: ' + newWidth);
        this._renderer.setStyle(this.pageLocation.elementRef.nativeElement, 'margin-left', newWidth + 'px');
      }
    }, 100);

  }

  /* Set the width and height of the side navigation to 0 and the left margin of the page content to 0 */
  hideSideNavMenu(): void {
    // console.log('main - hideSideNavMenu()');
    this._renderer.setStyle(this.menuLocation.elementRef.nativeElement, 'width', '0');
    this._renderer.setStyle(this.menuLocation.elementRef.nativeElement, 'height', '0');
    this._renderer.setStyle(this.pageLocation.elementRef.nativeElement, 'margin-left', '0');
  }

}

