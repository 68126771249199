<div class="marginTop6 wackadoo-detail" [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
  <div class="card">
    <div class="card-header">
      Account Information
    </div>
    <div class="card-body">
      <div>
        <div>
          <wackadoo-field [f]="resource.accountName" [mode]="mode"></wackadoo-field>
        </div>
        <div class="d-flex">
          <div class="marginRightHalfEm">
            <wackadoo-field [f]="resource.accountContactFirstName" [mode]="mode"></wackadoo-field>
          </div>
          <div>
            <wackadoo-field [f]="resource.accountContactLastName" [mode]="mode"></wackadoo-field>
          </div>
        </div>
        <div>
          <wackadoo-field [f]="resource.accountAddress" [mode]="mode"></wackadoo-field>
        </div>
        <div class="d-flex">
          <div class="marginRightHalfEm">
            <wackadoo-field [f]="resource.accountCity" [mode]="mode"></wackadoo-field>
          </div>
          <div class="marginRightHalfEm">
            <wackadoo-field [f]="resource.accountState" [mode]="mode" [size]="2"></wackadoo-field>
          </div>
          <div class="marginRightHalfEm">
            <wackadoo-field [f]="resource.accountPostalCode" [mode]="mode"></wackadoo-field>
          </div>
          <div>
            <wackadoo-field [f]="resource.accountCountry" [mode]="mode" [size]="2"></wackadoo-field>
          </div>
        </div>
        <div>
          <wackadoo-field [f]="resource.accountPhone" [mode]="mode"></wackadoo-field>
        </div>
        <div>
          <wackadoo-field [f]="resource.accountEmail" [mode]="mode"></wackadoo-field>
        </div>
        <div class="d-flex marginTopWholeEm">
          <div class="right marginRightHalfEm">
              server prefix:
          </div>
          <div class="right marginRightHalfEm">
            <wackadoo-field [f]="resource.accountHostName" [mode]="mode"></wackadoo-field>
          </div>
          <div>
            (i.e. "{{(resource.accountHostName.isPopulated ? resource.accountHostName.value : '____')}}.{{ domain }}")
          </div>
        </div>
        <div class="small italic">
            Your server prefix may contain letters, numbers and dashes. A dash may not be the first or last character. No other punctuation or special characters are allowed.
        </div>
<!--
        <div>
          <wackadoo-field [f]="resource.accountURL" [mode]="mode"></wackadoo-field>
        </div>
        <div class="d-flex justify-content-between">
          <div class="right fillAvailableSpace">
            account locked
          </div>
          <div class="fillAvailableSpace">
            <wackadoo-field [f]="resource.accountLocked" [mode]="mode"></wackadoo-field>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="right fillAvailableSpace">
            account expiration
          </div>
          <div class="fillAvailableSpace">
            <wackadoo-field [f]="resource.accountExpiration" [mode]="mode"></wackadoo-field>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="right fillAvailableSpace">
            account email validated
          </div>
          <div class="fillAvailableSpace">
            <wackadoo-field [f]="resource.accountEmailValidated" [mode]="mode"></wackadoo-field>
            <br/>
            <wackadoo-field [f]="resource.accountEmailValidatedDate" mode="read"></wackadoo-field>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="right fillAvailableSpace">
            account sub merchant LIVE ID
          </div>
          <div class="fillAvailableSpace">
            <wackadoo-field [f]="resource.accountSubMerchantLiveID" [mode]="mode"></wackadoo-field>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="right fillAvailableSpace">
            account sub merchant TEST ID
          </div>
          <div class="fillAvailableSpace">
            <wackadoo-field [f]="resource.accountSubMerchantTestID" [mode]="mode"></wackadoo-field>
          </div>
        </div>
-->
      </div>
    </div>
  </div>
  <div class="smallish card" *ngIf="domain === 'wackadoo.org'">
    <div class="card-header">
      Accept Donations via <span class="fab fa-stripe fa-2x middle stripeBlue"></span>
    </div>
    <div class="card-body">
      <div>
        <div>
          We at {{domain}} partner with {{subMerchantVendorName}} to allow you 
          to receive donations directly from the public through your {{domain}} web apps.
        </div>
        <div class="marginTopHalfEm">
          At this point in time, direct-from-the-public-to-you payments are supported by:
        </div>
        <ul>
          <li>
            GolfTournament
          </li>
          <li>
            TeacherCare
          </li>
        </ul>
        <div class="italic small">
          (This list will expand as demand dictates.)
        </div>
        <div class="marginTopHalfEm">
          Please Note:
        </div>
        <ul>
          <li>
            Donations are subject to {{subMerchantVendorName}} service fees, based on their terms and conditions. 
          </li>
          <li>
            We at {{domain}} do not receive any portion of the donations made through our web apps.
          </li>
          <li>
            The Payment Mode setting below (LIVE or TEST) applies across all the web apps above.
          </li>
          <li>
            You will need to configure two different {{subMerchantVendorName}} accounts - one for LIVE Mode and one for TEST Mode.
            <br/>
            (And remember to only go live when you are ready...)
          </li>
          <li>
            For details on generating test {{subMerchantVendorName}} payments, <a target="_blank" href="https://stripe.com/docs/testing#cards">click here.</a>
          </li>
        </ul>  
      </div>
      <div class="card" *ngIf="!workingOnSystemAccount">
        <div class="card-header d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <div class="marginTopQuarterEm marginRightQuarterEm">
              <span class="fab fa-stripe fa-2x middle stripeBlue"></span>
            </div>
            <div>
              Account Status
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div>
              Payment Mode:
            </div>
            <div class="marginLeftQuarterEm bold italic {{(resource.accountSubMerchantInTestMode.value ? 'green' : 'red')}}">
              {{(resource.accountSubMerchantInTestMode.value ? 'TEST' : 'LIVE')}}
            </div>
            <div class="marginLeftWholeEm">
              <button type="button" class="btn btn-secondary italic bold white" [ngClass]="{ 'green-bg' : resource.accountSubMerchantInTestMode.value , 'red-bg' : !resource.accountSubMerchantInTestMode.value }" (click)="toggleTestMode()">
                  {{ (resource.accountSubMerchantInTestMode.value ? 'Go Live' : 'Revert To Test') }}
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div *ngIf="subMerchantIDIsPopulated()">
            <div class="d-flex justify-content-between">
              <div>
                You HAVE connected to a <span class="bold italic {{(resource.accountSubMerchantInTestMode.value ? 'green' : 'red')}}">{{(resource.accountSubMerchantInTestMode.value ? 'TEST' : 'LIVE')}}</span> {{subMerchantVendorName}} account ending in "{{last4}}".
              </div>
              <div class="marginLeftHalfEm">
                <button type="button" (click)="clearSubMerchantID(resource.accountSubMerchantInTestMode.value)" class="btn btn-secondary smallish paddingTop0 paddingBottom0 paddingLeftQuarterEm paddingRightQuarterEm">
                  Disconnect
                </button>
              </div>
            </div>
            <div class="italic marginTopHalfEm" *ngIf="checkingSubMerchantAccountStatus">
              Checking <span class="bold italic {{(resource.accountSubMerchantInTestMode.value ? 'green' : 'red')}}">{{(resource.accountSubMerchantInTestMode.value ? 'TEST' : 'LIVE')}}</span> {{subMerchantVendorName}} account status...
            </div>
            <div *ngIf="!checkingSubMerchantAccountStatus">
              <div class="marginTopHalfEm" *ngIf="!subMerchantExists">
                We are unable to locate your <span class="bold italic {{(resource.accountSubMerchantInTestMode.value ? 'green' : 'red')}}">{{(resource.accountSubMerchantInTestMode.value ? 'TEST' : 'LIVE')}}</span> {{subMerchantVendorName}} account using the account ID ending in "{{last4}}"" that we have on record. Please contact {{domain}} support.
              </div>
              <div class="marginTopHalfEm" *ngIf="subMerchantExists">
                <div>
                  Your <span class="bold italic {{(resource.accountSubMerchantInTestMode.value ? 'green' : 'red')}}">{{(resource.accountSubMerchantInTestMode.value ? 'TEST' : 'LIVE')}}</span> {{domain}}/{{subMerchantVendorName}} account {{subMerchantReadyToReceivePayments ? 'IS' : 'is NOT' }} ready to receive payments.
                </div>
                <div *ngIf="!subMerchantReadyToReceivePayments">
                  <div class="marginTopHalfEm marginBottomHalfEm">
                      Please continue to set up your {{domain}}/{{subMerchantVendorName}} payment mechanism  by completing the steps outlined to you by {{subMerchantVendorName}}.
                  </div>
                  <button class="btn btn-secondary" type="button" (click)="reDirectToSubMerchantVendorAccountManagement()" title="Continue {{subMerchantVendorName}} Account Setup">
                      Continue {{subMerchantVendorName}} Account Setup
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!subMerchantIDIsPopulated()">
            <div>
              You have NOT yet connected to a <span class="bold italic {{(resource.accountSubMerchantInTestMode.value ? 'green' : 'red')}}">{{(resource.accountSubMerchantInTestMode.value ? 'TEST' : 'LIVE')}}</span> {{subMerchantVendorName}} account.
            </div>
            <div class="marginTopHalfEm">
              <div class=" marginBottomHalfEm">
                  If your web app requires it, enable your <span class="bold italic {{(resource.accountSubMerchantInTestMode.value ? 'green' : 'red')}}">{{(resource.accountSubMerchantInTestMode.value ? 'TEST' : 'LIVE')}}</span> {{domain}}/{{subMerchantVendorName}} payment mechanism by clicking the button below, and completing the steps outlined to you by {{subMerchantVendorName}}.
              </div>
              <button class="btn btn-secondary" type="button" (click)="createSubMerchant()" title="Create {{subMerchantVendorName}} Account">
                  Connect To A <span class="bold italic {{(resource.accountSubMerchantInTestMode.value ? 'green' : 'red')}}">{{(resource.accountSubMerchantInTestMode.value ? 'TEST' : 'LIVE')}}</span> {{subMerchantVendorName}} Account
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="user.isSysAdminOnly">
        <div class="card">
          <div class="card-header">
              System Administrator Controls
          </div>
          <div class="card-body" *ngIf="workingOnSystemAccount">
            <div>
              You're looking at the system account, which, by definition, can never have a {{subMerchantVendorName}} subMerchant account.
            </div>
          </div>
          <div class="card-body" *ngIf="!workingOnSystemAccount">
            <div>
              {{subMerchantVendorName}} Account IDs for {{ resource.accountName.value}}
            </div>
            <div class="marginTopHalfEm d-flex justify-content-between">
              <div class="marginTopHalfEm d-flex flexrow">
                <div>
                  TEST:
                </div>
                <div class="marginLeftHalfEm">
                  <wackadoo-field [f]="resource.accountSubMerchantTestID" [mode]="mode"></wackadoo-field>
                </div>
              </div>
              <div class="marginLeftHalfEm">
                <button type="button" (click)="clearSubMerchantID(true)" class="btn btn-secondary smallish paddingTop0 paddingBottom0 paddingLeftQuarterEm paddingRightQuarterEm" [disabled]="!resource.accountSubMerchantTestID.isPopulated">
                    Disconnect
                </button>
              </div>
            </div>
            <div class="marginTopHalfEm d-flex justify-content-between">
              <div class="marginTopHalfEm d-flex flexrow">
                <div>
                  LIVE:
                </div>
                <div class="marginLeftHalfEm">
                  <wackadoo-field [f]="resource.accountSubMerchantLiveID" [mode]="mode"></wackadoo-field>
                </div>
              </div>
              <div class="marginLeftHalfEm">
                <button type="button" (click)="clearSubMerchantID(false)" class="btn btn-secondary smallish paddingTop0 paddingBottom0 paddingLeftQuarterEm paddingRightQuarterEm" [disabled]="!resource.accountSubMerchantLiveID.isPopulated">
                  Disconnect
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card" [ngClass]="{'maxWidthViewPort25':(screenType !== 'phone')}">
    <div class="card-header">
      Account Logo
    </div>
    <div class="card-body">
      <div class="card-body">
        <wackadoo-field-binary-image [resource]="resource" [f]="resource.logoFileContent" [mode]="mode"></wackadoo-field-binary-image>
        <div class="smallish">
          <wackadoo-file-details [fileContentField]="resource.logoFileContent" title="Logo File Details:" ></wackadoo-file-details>
        </div>
      </div>
    </div>
  </div>
</div>
