import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FileContentDescriptor } from './file-content-descriptor.model';
import { ModalDialogService } from '../services/modal-dialog.service';

@Directive({
  selector: '[wackadooFileRead]'
})
export class FileReadDirective implements OnInit {

  @Input() validExtensions: string = null;
  @Input() maxFileSizeMB = -1;

  @Input() newFileContent: Subject<FileContentDescriptor>;

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public modalDialogService: ModalDialogService,
  ) {}

  ngOnInit(): void {

    // console.log('FileLoadDirective', this.validExtensions);

    // this is null if the component has not yet fully loaded...
    if (this.validExtensions) {

      // create and decorate the fileInput...
      const fileInput = this.renderer.createElement('input');

      fileInput.type = 'file';
      // all EventServer file uploads use this field names: fileContent, fileName, fileSize, fileType
      // (fileName, fileSize, and fileType are set in the readAndUploadFile() call below...)
      fileInput.name = 'fileContent';
      // we'd rather do this: this.renderer.addClass(fileInput, 'hidden');
      // but since the fileInput is not yet in the DOM, we cannot...
      fileInput.className = 'hidden';

      const validExtensionsAsArray: string [] = this.validExtensions.split('|');
      // console.log('FileLoadDirective', this.validExtensions, validExtensionsAsArray);

      let temp = '';
      for (const ext of validExtensionsAsArray) {
        temp += (',.' + ext);
      }
      if (temp !== '') {
        temp = temp.substr(1);
      }
      // console.log('FileLoadDirective', this.validExtensions, validExtensionsAsArray, temp);
      fileInput.accept = temp;

      fileInput.onchange = () => {
        // console.log('about to read file...');
        this.readFile(fileInput, this.newFileContent, validExtensionsAsArray, this.maxFileSizeMB);
      };

      // add the hidden fileInput into the current element...

      this.renderer.appendChild(this.elementRef.nativeElement, fileInput);

      // tell the element to click the fileInput...

      this.renderer.listen(
        this.elementRef.nativeElement,
        'click',
        () => {
          fileInput.click();
        }
      );
    }

  }

  readFile(fileInput: HTMLInputElement, newFileContent: Subject<FileContentDescriptor>, validExtensions?: string [], maxFileSizeMB?: number): void {

    // show the 'please wait' dialog while the server is being called...
    this.modalDialogService.showPleaseWait(true, true);

    const subscr = this.readFileContent(fileInput, this.modalDialogService, validExtensions, maxFileSizeMB).subscribe(
      (fcd: FileContentDescriptor) => {
        if (fcd.valid) {
          // notify the recipient of the file content
          newFileContent.next(fcd);
        } else {
          this.modalDialogService.showAlert(JSON.stringify(fcd), 'WARNING: Invalid File Descriptor!');
        }
        subscr.unsubscribe();
        this.modalDialogService.showPleaseWait(false);
      }
    );
  }

  public readFileContent(fileInput: HTMLInputElement, modalDialogService: ModalDialogService, validExtensions?: string [], maxFileSizeMB?: number): Subject<FileContentDescriptor> {
    validExtensions = ((typeof validExtensions !== 'undefined') && (validExtensions !== null)) ? validExtensions : new Array();
    maxFileSizeMB = typeof maxFileSizeMB !== 'undefined' ? maxFileSizeMB : -1;

    // console.log('_readFileContent()');

    const fcd = new FileContentDescriptor();

    const fileDescriptorSubject = new Subject<FileContentDescriptor>();

    const fileFieldName = fileInput.name;
    // Retrieve the first (and only!) File from the FileList object

    const f = fileInput.files[0];
    if (f) {

      const lastDot = f.name.lastIndexOf('.');
      let fileExtension = null;
      if (lastDot > 0) {
        fileExtension = f.name.substring(lastDot + 1, f.name.length).toLowerCase();
      }
      fcd.fileType = fileExtension;

      // if we have valid extensions to check, do so here...
      // console.log('Got here...\n' + f.name + '\n' + target.validExtensions);

      if (validExtensions.length > 0) {
        // check the extension as a preliminary, client-side validation...
        if (!validExtensions.includes(fileExtension)) {
          let msg = 'Valid extensions include:\n';
          for (const ext2 of validExtensions) {
            msg += ('\t' + ext2 + '\n');
          }
          msg += 'Please try again.';
          modalDialogService.showPleaseWait(false);
          modalDialogService.showAlert(msg, 'WARNING: Invalid File Extension "' + fileExtension + '"');
          return;
        }
      }

      // if we have a max file size to check, do so here...
      if (maxFileSizeMB && (maxFileSizeMB > 0)  && (maxFileSizeMB * 1024 * 1024) < f.size) {
        let msg = 'Maximum allowed file upload: ' + maxFileSizeMB + 'MB\n';
        msg += 'Please try again.';
        modalDialogService.showPleaseWait(false);
        modalDialogService.showAlert(msg, 'WARNING: Invalid File Size!');
        return;
      }

      // console.log('Got here...\nabout to call FileReader...');

      const r = new FileReader();

      r.onload = (e2: any) => {
        // e2 = e2 || window.event;
        const readerTarget = e2.srcElement || e2.target;

        // console.log('Got here...\nonload()...');

        let base64Content = readerTarget.result;
        const base64tag = ';base64,';
        // readAsDataURL puts as string that ends with the base64tag at the front of the base64 value...
        const offset = base64Content.indexOf(base64tag) + base64tag.length;
        // console.log('offset = '+offset + ', base64Content: '+base64Content);
        base64Content = base64Content.substring(offset);
        // console.log('base64Content: '+base64Content);

        // console.log('myFormData\n'+JSON.stringify(myFormData));
        fcd.fileContent = base64Content;

        fcd.fileName = f.name;

        // SOMEtimes, we don't get a value for f.type, so we use the extension...
        if (f.type && (f.type.trim().length > 0)) {
          fcd.fileType = f.type;
        }

        fcd.fileSize = f.size;
        fcd.fileDate = new Date(f.lastModified);

        fileDescriptorSubject.next(fcd);
      };

      r.onerror = (e3: any) => {
        // e3 = e3 || window.event;
        const target3 = e3.srcElement || e3.target;

        modalDialogService.showPleaseWait(false);

        switch (target3.error.code) {
          case target3.error.NOT_FOUND_ERR:
            modalDialogService.showAlert('File not found', 'WARNING: File Selection Failed!');
            break;
          case target3.error.NOT_READABLE_ERR:
            modalDialogService.showAlert('File not readable', 'WARNING: File Selection Failed!');
            break;
          case target3.error.ABORT_ERR:
            break; // NOP
          default:
            modalDialogService.showAlert('An unknown error occurred reading this file.', 'WARNING: File Selection Failed!');
            break;
        }
      };

      // r.onloadstart = (e4: any) => {
      // };

      // r.onprogress = (e5: any) => {
      // };

      // r.onloadend = (e6: any) => {
      // };

      r.readAsDataURL(f);

    } else {
      modalDialogService.showPleaseWait(false);
      modalDialogService.showAlert('Failed to load file: ' + fileFieldName, 'WARNING: File Selection Failed!');
    }

    return fileDescriptorSubject;
  }

}
