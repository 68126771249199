<div class="card">
  <div class="card-header">
    <wackadoo-field [f]="resource.name" [mode]="mode"></wackadoo-field>
  </div>
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <div>
        <wackadoo-field [f]="resource.smtpHost" [mode]="mode"></wackadoo-field>
      </div>
      <div>
        <wackadoo-field [f]="resource.smtpPort" [mode]="mode" size="5"></wackadoo-field>
      </div>
      <div>
        SSL: <wackadoo-field [f]="resource.smtpSSL" [mode]="mode"></wackadoo-field>
      </div>
      <div>
        TLS: <wackadoo-field [f]="resource.smtpTLS" [mode]="mode"></wackadoo-field>
      </div>
    </div>
    <div class="marginTopWholeEm">
      This regular expression must uniquely identify this SMTP server from any other in the database.
    </div>
    <div>
      <wackadoo-field [f]="resource.smtpMxRegEx" [mode]="mode"></wackadoo-field>
    </div>
    <div class="marginTopWholeEm">
      Is there anything the user needs to know about using this particular SMTP server? 
    </div>
    <div>
      <wackadoo-field [f]="resource.smtpNote" [mode]="mode"></wackadoo-field>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-sm-6">
        Test A Domain Or EMail Address 
      </div>
      <div class="col-sm-6 unbold">
        <div class="d-flex">
          <div>
            <wackadoo-field [f]="domain" mode="write"></wackadoo-field>
          </div>
          <div clas="marginLeftHalfEm">
            <button class="btn btn-secondary" (click)="testRegEx()"><span class="fas fa-cogs"></span> Test</button>
          </div>
        </div>
        <div class="red small italic" *ngIf="mode === 'write'">
          To verify the configuration above, first click the Save button, THEN use this Test button.
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">
            MX Entries
          </div>
          <div class="card-body">
            <div *ngFor="let r of mxEntries;">
              {{r.mx.value}}
            </div>
            <div *ngIf="domain.isPopulated && (mxEntries !== null) && (mxEntries.length === 0)">
              No matching MX DNS entries. (i.e. not a valid email domain...)
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-header">
            <div>
              Matching SMTP Servers
            </div>
            <div class="red small unbold italic"
                *ngIf="domain.isPopulated && (matchingSMTPServers !== null) && (matchingSMTPServers.length > 0)">
              (There should only be one!)
            </div>
          </div>
          <div class="card-body">
            <div *ngFor="let r of matchingSMTPServers;">
              {{r.name.value}}
            </div>
            <div *ngIf="domain.isPopulated && (matchingSMTPServers !== null) && (matchingSMTPServers.length === 0)">
              No matching SMTP servers.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
