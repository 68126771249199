import { Component, OnInit } from '@angular/core';
import { Globals } from '../../services/global.service';

@Component({
  selector: 'wackadoo-contact-us',
  templateUrl: './contact-us.component.html',

})
export class ContactUsComponent implements OnInit {

  orgName = Globals.organizationName;
  domain = Globals.domain;

  constructor() { }

  ngOnInit(): void {
  }

}
