<div *ngIf="showBanner">
    <nav class="navbar fixed-top navbar-expand-lg navbar-light">

        <div class="container" wackadooMenuCloser>

            <!-- Side menu hamburger... -->
            <button class="navbar-toggler padding0" type="button" (click)="clickSideNavMenu()" wackadooStopPropagation *ngIf="showPhoneMenu">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="d-inline-flex align-items-center">
                <a href="https://www.{{ domain }}" class="navbar-brand link marginRight0">
                    <img class="navBarLogo" src="{{ domainLogo }}" alt="{{ domain}}" />
                </a>
                <!--
                <wackadoo-clock *ngIf="screenType === 'fullscreen'"></wackadoo-clock>
                -->
            </div>

        <!--
            <div class="tiny center">{{ screenType }}<br/>{{ screenWidth }}</div>
        -->

            <!-- phone or tablet appName display, but NOT logged into teeny/tiny phone... -->
            <div class="navBarAppName link marginBottomWholeEm" (click)="goToApp(appName)" *ngIf="!isRootApp && (screenType !== 'fullscreen') && !((screenWidth < 414) && isLoggedIn)">
                {{ appName }}
            </div>

            <button class="navbar-toggler padding0" type="button" aria-controls="navbarContent" [attr.aria-expanded]="!collapsed"
                aria-label="Toggle navigation" (click)="clickBannerMenu()" wackadooStopPropagation>
                <span class="navbar-toggler-icon"></span>
            </button>

            <div 
                class="navbar-collapse" 
                [ngClass]="{'collapse':collapsed}" 
                id="navbarContent"
            >
                <ul class="navbar-nav ml-auto floatRight">

                    <!-- fullcreen appName display, OR logged into teeny/tiny phone... -->
                    <li class="nav-item" *ngIf="(isRootApp && !isLoggedIn) || isAcctMgr">
                        <a class="nav-link" (click)="goToRootApp()" >
                            Home
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="!isRootApp && !isAcctMgr">
                        <a class="nav-link" (click)="goToApp(appName)">
                            <span class="navBarAppName" >
                                {{ appName }}
                            </span>
                        </a>
                    </li>

                    <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
                        <a class="nav-link" ngbDropdownToggle id="navbarDropdown1" role="button" wackadooStopPropagation>
                            About Us
                        </a>
                        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu inherit-font-size">
                            <a class="dropdown-item" (click)="loadPage('contact-us')">Contact Us</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" (click)="loadPage('about-custom-dev')">Custom development...</a>
                            <span role="separator" class="divider"></span>
                            <a class="dropdown-item" (click)="loadPage('about-name')">Our name...</a>
                            <a class="dropdown-item" (click)="loadPage('about-mission')">Our mission...</a>
                            <a class="dropdown-item" (click)="loadPage('about-technology')">Our technology...</a>
                            <a class="dropdown-item" (click)="loadPage('about-history')">Our history...</a>
                        </div>
                    </li>
                    
                    <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
                        <a class="nav-link" ngbDropdownToggle id="navbarDropdown2" role="button" wackadooStopPropagation>
                            Values
                        </a>
                        <div ngbDropdownMenu aria-labelledby="navbarDropdown2" class="dropdown-menu inherit-font-size">
                            <a class="dropdown-item" (click)="loadPage('value-directness')">Directness</a>
                            <a class="dropdown-item" (click)="loadPage('value-simplicity')">Simplicity</a>
                            <a class="dropdown-item" (click)="loadPage('value-flexibility')">Flexibility</a>
                            <a class="dropdown-item" (click)="loadPage('value-substance-over-style')">Substance Over Style</a>
                            <a class="dropdown-item" (click)="loadPage('value-transparency')">Transparency</a>
                        </div>
                    </li>
                    
                    <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
                        <a class="nav-link" ngbDropdownToggle id="navbarDropdown2" role="button" wackadooStopPropagation>
                            Policies
                        </a>
                        <div ngbDropdownMenu aria-labelledby="navbarDropdown2" class="dropdown-menu inherit-font-size">
                            <a class="dropdown-item" (click)="loadPage('policy-terms-and-conditions')">Terms &amp; Conditions</a>
                            <a class="dropdown-item" (click)="loadPage('policy-data-security')">Data Security</a>
                            <a class="dropdown-item" (click)="loadPage('policy-pricing')">Pricing</a>
                            <a class="dropdown-item" (click)="loadPage('policy-billing')">Billing</a>
                            <a class="dropdown-item" (click)="loadPage('policy-refunds')">Refunds</a>
                            <a class="dropdown-item" (click)="loadPage('policy-expired-or-delinquent-accounts')">Expired &amp; Delinquent Accounts</a>
                            <a class="dropdown-item" (click)="loadPage('policy-email-usage')">E-Mail Usage</a>
                            <a class="dropdown-item" (click)="loadPage('policy-confidentiality')">Confidentiality / Privacy</a>
                            <a class="dropdown-item" (click)="loadPage('policy-cookies')">Cookie Usage</a>
                            <a class="dropdown-item" (click)="loadPage('policy-backups-and-data-retention')">Backups &amp; Data-Retention</a>
                            <a class="dropdown-item" (click)="loadPage('policy-data-portability')">Data Portability</a>
                            <a class="dropdown-item" (click)="loadPage('policy-support')">Support</a>
                            <a class="dropdown-item" (click)="loadPage('policy-maintenance')">Maintenance</a>
                            <a class="dropdown-item" (click)="loadPage('policy-service-level-agreement')">Service Level Agreement</a>
                        </div>
                    </li>

                    <li class="nav-item" *ngIf="!isLoggedIn">
                        <a class="nav-link" (click)="goToPricing()" >Pricing</a>
                    </li>

                    <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
                        <a class="nav-link" ngbDropdownToggle id="navbarDropdown3" role="button" wackadooStopPropagation>
                            Help
                        </a>
                        <div ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu inherit-font-size">
                            <a class="dropdown-item" (click)="loadPage('help-getting-started')">Getting started</a>
                            <a class="dropdown-item" (click)="loadPage('help-video-library')">Help video library</a>
                            <a class="dropdown-item" (click)="loadPage('help-user-roles')">User roles</a>
                            <a class="dropdown-item" (click)="loadPage('help-faq')">FAQs</a>
                        </div>
                    </li>

                    <li class="nav-item" *ngIf="!isLoggedIn && showLoginMenu">
                        <a class="nav-link" role="button" (click)="loadPage('login')">Login</a>
                    </li>

                    <li class="nav-item" ngbDropdown display="dynamic" placement="bottom-right" *ngIf="isLoggedIn">
                        <a class="nav-link" ngbDropdownToggle id="navbarDropdown4" role="button" wackadooStopPropagation>
                            <span class="accountLogo"><img class="userLogo" src="{{ accountLogoURL }}" alt="" /></span>
                            <span class="marginLeftQuarterEm marginRightQuarterEm">{{ userName }}</span>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="navbarDropdown4" class="dropdown-menu inherit-font-size">
                            <span class="dropdown-header">{{ userRole }}</span>
                            <span class="dropdown-header">{{ accountName }}</span>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" *ngIf="!isAcctMgr && !isGuest" (click)="goToAccountManagerApp()">My Account</a>
                            <a class="dropdown-item" *ngIf="isAcctMgr && previousApp && (previousApp !== '')" (click)="returnToPreviousApp()">Return To {{ previousApp }}</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" (click)="logout()">Logout</a>
                        </div>
                    </li>

                </ul>
            </div>

        </div>

    </nav>
</div>
