import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-search-engine-updater',
  templateUrl: './search-engine-updater.component.html',

})
export class SearchEngineUpdaterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
