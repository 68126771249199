import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageComponent } from '../../page/page.component';
import { UserAuthService } from '../../../services/user-auth.service';
import { UserInterfaceService } from '../../../services/user-interface.service';

@Component({
  selector: 'wackadoo-help-getting-started',
  templateUrl: './help-getting-started.component.html',

})
export class HelpGettingStartedComponent extends PageComponent implements OnInit, OnDestroy {

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
