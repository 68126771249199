<div *ngIf="visible">
    <div class="modal-backdrop"></div>
    <div class="modal-box" [ngClass]="{ 'transparent-bg' : noTextContent }">
        <div class="center">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <p class="center bold" *ngIf="!noTextContent">Please wait...</p>
        <p [innerHTML]="message | safeHtml"></p>
        <p *ngIf="applicationStatus !== ''">{{ applicationStatus }}</p>
        <p *ngIf="applicationProgress !== ''">{{ applicationProgress }}</p>
<!--
        <p *ngIf="requestStatus !== ''">{{ requestStatus }}</p>
-->
    </div>
</div>
