import { WResource } from './resource.model';

export class Parameter { name: string; type: string; text: string; }
export class EventEntry { timestamp: string; eventHandler: string; message: string; }

export class WEvent {

    action: string;
    firstHandler: string;
    lastHandler: string;
    message: string;
    parameters: any;
    source: string;
    status: string;
    trace: { entry: EventEntry [] };

    // we may or may not get resources back...
    resources?: WResource[];

    // HACK ALERT! When the server returns one of these, it actually
    // wraps it one level deep in the JSON like this: "{event: WEvent}"
    // So we added this "extra" field as a hack to allow us to properly
    // strip off the extra part and just keep the "wrapped" WEvent.
    // (See EventServerService.fireEvent() and its use of pipe and map...)
    // DO NOT EVER USE THIS FIELD DIRECTLY!
    event?: WEvent;

    getParameter(name: string): any {
        return this.parameters[name];
    }

}
