import { Component, OnInit, Input } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { WEvent } from 'src/app/client-core/data/event.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { FieldMode } from 'src/app/client-core/data/field/field-mode.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'wackadoo-form-filler-field',
  templateUrl: './form-filler-field.component.html',
})
export class FormFillerFieldComponent extends ResourceComponent implements OnInit {

  @Input() formFillerFieldEH = null;
  @Input() refreshFieldList: Subject<void> = null;

  saveButtonTitle: string = null;

  constructor(
    userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.mode = FieldMode.write;

    this.saveButtonTitle = (this.resource.keyField.isPopulated ? 'Save' : 'Add New Field');
  }

  save(): void {

    // console.log('save()', this.selectedField);

    try {

      if (this.resource.hasChangedFields()) {

        const parms: any = this.resource.getChangedFieldValuesAsParms();
        let action = 'add';
        if (this.resource.fffID.isPopulated) {
          parms.fffID = this.resource.fffID.value;
          action = 'modify';
        }

        // console.log('save()\nEH: ' + fieldEHName + '\naction: ' + action + '\nparms: ' + JSON.stringify(parms));

        this.eventServerService.fireEvent(this.formFillerFieldEH, action, parms).subscribe(
          (responseEvent: WEvent) => {
            try {
              if (responseEvent.status !== 'OK') {
                throw new Error('Failed to save FormFillerField! (' + responseEvent.message + ')');
              }

              // we tell the parent component to refresh the list...

              if (this.refreshFieldList) {
                this.refreshFieldList.next();
              }

            } catch (ex) {
              const msg = 'save()\n';
              this.userInterfaceService.alertUserToException(ex, msg);
            }
          }
        );
      } else {
        this.modalDialogService.showAlert('The FormFillerField "' + this.resource.fffName + '" has no changes to save...');
      }

    } catch (ex) {
      const msg = 'save()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

  delete(): void {
    try {

      this.eventServerService.fireEvent(this.formFillerFieldEH, 'delete', this.resource.keyField.asParm).subscribe(
        (responseEvent: WEvent) => {
          try {
            if (responseEvent.status !== 'OK') {
              throw new Error('Failed to delete FormFillerField! (' + responseEvent.message + ')');
            }

            // we tell the parent component to refresh the list...

            if (this.refreshFieldList) {
              this.refreshFieldList.next();
            }

          } catch (ex) {
            const msg = 'delete()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'delete()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }


}
