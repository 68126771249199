import { Component, OnInit } from '@angular/core';
import { WResource } from 'src/app/client-core/data/resource.model';
import { NewAccountDetailContentComponent } from '../new-account-detail-content/new-account-detail-content.component';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-new-customer-detail-content',
  templateUrl: './new-customer-detail-content.component.html',
})
export class NewCustomerDetailContentComponent extends NewAccountDetailContentComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'NewCustomerDetailContentComponent';

  app: WResource = null;
  appPrice: WResource = null;

  // These two items for the collapsable noe in the credit card section of the UX
  isCollapsed = true;

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnInit(): void {
    super.ngOnInit();

    // now get the previousApplication resource

    const parms: any = {};
    parms.appName = this.userInterfaceService.previousApplication;

    this.eventServerService.fireEvent('Applications', 'list', parms).subscribe(
      (result: WEvent) => {
        if (result.status !== 'OK') {
          this.modalDialogService.showAlert('Failed to find application: ' + parms.appName, 'Error!');
        } else {
          this.app = result.resources[0];
          // console.log('this.app:', this.app);

          const parms2: any = this.app.appID.asParm;
          this.eventServerService.fireEvent('AppPricing', 'list', parms2).subscribe(
            (result2: WEvent) => {
              if (result2.status !== 'OK') {
                this.modalDialogService.showAlert('Failed to find pricing for application: ' + parms.appName, 'Error!');
              } else {
                this.appPrice = result2.resources[0];
                // console.log('this.appPrice:', this.appPrice);
              }
            }
          );
        }
      }
    );

  }

}
