<div class="btn-group marginTopQuarterEm d-flex" role="group">
    <button type="button" title="New"       data-abbrev="" class="btn btn-secondary actionButton" (click)="add()"        *ngIf="methods.includes('add')"><span class="fas fa-plus"></span></button>
    <button type="button" title="Clear All" data-abbrev="" class="btn btn-secondary actionButton" (click)="clear()"      *ngIf="methods.includes('clear')"><span class="fas fa-exclamation-triangle"></span></button>
    <button 
        *ngIf="methods.includes('bulkImport')"
        type="button" 
        title="Import"
        data-abbrev=""
        class="btn btn-secondary actionButton" 

        wackadooFileUpload
        eventHandler="{{ eventHandler }}"
        action="bulkImport"
        [parms]="{fileExtension:'txt'}"
        [validExtensions]="'txt'"
        successTitle="File Import Complete"
        confirmTitle="IMPORTANT! You are about to import a large number of resources."
        confirmMessage="These resources will be ADDED to the list on the {{ eventHandler }} page.\n\nThis is a BULK-ADD operation which WILL generate duplicate entries if the same data is imported multiple times. (This import process does NOT replace existing items! Though the format of the file must match what you get from the Export function, the key field value is ignored.)\n\nAre you REALLY sure you want to do this?"
    >
        <span class="fas fa-file-import"></span>
    </button>
    <button type="button" title="Export"    data-abbrev="" class="btn btn-secondary actionButton" (click)="export()"     *ngIf="methods.includes('export')"><span class="fas fa-file-export"></span></button>
    <button type="button" title="Truncate"  data-abbrev="" class="btn btn-secondary actionButton" (click)="truncate()"   *ngIf="methods.includes('delete') && (user.role === 'sysadmin')"><span class="fas fa-cut"></span></button>
    <button type="button" title="Search"    data-abbrev="" class="btn btn-secondary actionButton" (click)="search()"     *ngIf="methods.includes('search') && (screenSize < 575)"><span class="fas fa-search"></span></button>
</div>
