import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from '../../pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-e-mail-templates',
  templateUrl: '../repository-page/repository-page.component.html',
})
export class EMailTemplatesComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.gridButtonBarComponentClassName = 'ReportTemplateGridButtonBarComponent';
    this.detailContentComponentClassName = 'EMailTemplateDetailContentComponent';
    this.detailButtonBarComponentClassName = 'EMailTemplateDetailButtonBarComponent';
    super.ngOnInit();
  }
}
