<div class="card">
	<div class="card-header">
		Contact us...
	</div>
	<div class="card-body">
		<div>
			{{ orgName }}
		</div>
		<table>
			<tbody>
				<tr>
					<td><a href="mailto:steve@{{ domain }}">Steve Kuenzli</a> (Founder, CEO)</td><td>steve@{{ domain }}</td>
				</tr>
				<tr>
					<td><a href="mailto:contact@{{ domain }}">General Inquiries &amp; Sales</a></td><td>contact@{{ domain }}</td>
				</tr>
				<tr>
					<td><a href="mailto:support@{{ domain }}">Customer Support</a></td><td>support@{{ domain }}</td>
				</tr>
				<tr>
					<td><a href="mailto:billing@{{ domain }}">Billing</a></td><td>billing@{{ domain }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
