import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WResource } from 'src/app/client-core/data/resource.model';
import { FieldMode } from 'src/app/client-core/data/field/field-mode.model';
import { ScreenType } from '../../services/screen-type.enum';
import { Subscription, Subject } from 'rxjs';
import { UserInterfaceService } from '../../services/user-interface.service';
import { EventServerService } from '../../services/event-server.service';
import { WField } from '../field.model';
import { WEvent } from '../event.model';

@Component({
  selector: 'wackadoo-resource',
  templateUrl: './resource.component.html',
})
export class ResourceComponent implements OnInit, OnDestroy {

  @Input() resource: WResource = null;
  @Input() mode: FieldMode = FieldMode.read;

  screenType: ScreenType;
  private _screenTypeSubscription: Subscription;

  // this is for use by child component classes
  private _autoSaveOnChangeSubject: Subject<WField> = null;
  private _autoSaveOnChangeSubscription: Subscription;

  constructor(
    public userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
  ) {
  }

  ngOnInit(): void {

    this.screenType = this.userInterfaceService.screenType.getValue();

    this._screenTypeSubscription = this.userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        this.screenType = screenType;
      }
    );
  }

  ngOnDestroy(): void {
    if (this._screenTypeSubscription) {
      this._screenTypeSubscription.unsubscribe();
    }
    if (this._autoSaveOnChangeSubscription) {
      this._autoSaveOnChangeSubscription.unsubscribe();
    }
  }

  autoSave(enable: boolean): Subject<WField> {

    if (enable) {
      if (!this._autoSaveOnChangeSubscription) {
        this._autoSaveOnChangeSubject = new Subject<WField>();
        this._autoSaveOnChangeSubscription = this._autoSaveOnChangeSubject.subscribe(
          (field: WField) => {

            // saving each field value on every field change is WAY too chatty w/the server
            // but we lose data if we use a timeout...

            field.changed = true;

            this.eventServerService.saveResource(this.resource).subscribe(
              (result: WEvent) => {
                try {
                  if (result) {
                    if (result.status !== 'OK') {
                      throw new Error('Problem saving resource information: ' + result.message);
                    }

                    field.changed = false;
                  }
                } catch (ex) {
                  const msg = 'ResourceCompnent._autoSaveOnChangeSubscription()\n';
                  this.userInterfaceService.alertUserToException(ex, msg);
                }
              }
            );
          }
        );
      }
    } else if (this._autoSaveOnChangeSubscription) {
        this._autoSaveOnChangeSubscription.unsubscribe();
    }
    return this._autoSaveOnChangeSubject;
  }

}
