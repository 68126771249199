import { Subject, Subscriber } from 'rxjs';

export class ResourceSelectContent {
  observer: Subscriber<any> = null;
  subject: Subject<any> = null;
  eventHandler: string = null;
  action: string = null;
  parms: any = null;
  autoSelectSingleResource = false;

  constructor(observer: Subscriber<any>, eventHandler: string, action: string, parms: any, autoSelectSingleResource?: boolean) {
    this.observer = observer;
    this.eventHandler = (typeof eventHandler !== 'undefined' ? eventHandler : null);
    this.action = (typeof action !== 'undefined' ? action : null);
    this.parms = parms;

    this.autoSelectSingleResource = (typeof autoSelectSingleResource !== 'undefined' ? autoSelectSingleResource : false);

  }
}

