<ng-container *ngIf="f">
    <ng-container [ngSwitch]="f.displayComponent">
        <ng-container *ngSwitchCase="'radio'">
            <span class="form-check form-check-inline" *ngFor="let opt of (f.select ? f.select.split('|') : []); let idx = index;">
                <input 
                    class="form-check-input"
                    type="radio" 
                    [name]="f.name" 
                    [title]="(title ? title : f.name)"
                    [value]="opt"
                    [checked]="valueMatches(opt)"
                    [disabled]="f.readOnly"
                    (input)="onInputChange($event)"
                />
                <label class="form-check-label">{{ getLabel(opt) }}</label>
            </span>
        </ng-container>
        <ng-container *ngSwitchCase="'dropdown'">
            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline-secondary inherit-font-size" [id]="'selectButtonDropdown'+f.name" title="{{(title ? title : f.name)}}" [ngClass]="{'fieldChanged': f.changed}" ngbDropdownToggle>
                    {{ getLabel(f.value) }}
                </button>
                <div ngbDropdownMenu [attr.aria-labelledby]="'selectButtonDropdown'+f.name" class="max-height-12-em scrollable-y">
                    <ng-container *ngFor="let opt of (f.select ? f.select.split('|') : []); let idx = index;" >
                        <button class="do-not-inherit-font-size" ngbDropdownItem (click)="onSelectButtonChange(opt)">
                            {{ getLabel(opt) }}
                        </button>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <span *ngIf="mode === 'read'">
                {{ getLabel(f.value) }}
            </span>
            <span *ngIf="mode === 'write'">
                <select 
                    class="form-control"
                    name="{{ f.name }}"
                    [title]="(title ? title : f.name)"
                    disabled="{{ f.readOnly }}"
                    [ngClass]="{'fieldChanged': f.changed}"
                    (change)="onInputChange($event)"
                >
                    <option 
                        *ngFor="let opt of (f.select ? f.select.split('|') : []); let idx = index;"
                        value="{{ opt }}"
                        [selected]="valueMatches(opt)"
                    >
                        {{ getLabel(opt) }}
                    </option>
                </select>
            </span>
        </ng-container>
    </ng-container>
</ng-container>
