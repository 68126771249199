		<div class="card">
			<div class="card-header">
				Directness
			</div>
			<div class="card-body">
				<p>
					We don't beat around the bush with bad news - as shown by the fact that we led off our Policies 
					list with the oh-so-warm-and-fuzzy topics &quot;Terms &amp; Conditions&quot; and &quot;Data Security&quot;.
				</p>
				<p>
					When there is something that falls short of the mark in one of our apps - and there will be, because we are 
					only human - we'll let you know. Or if you find it first, let us know. While we may not get everything right 
					the first time, we will work diligently with you to try to make it right.
				</p>
			</div>
		</div>

