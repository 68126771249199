import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { StringComponent } from '../string/string.component';
import { ModalDialogService } from '../../../services/modal-dialog.service';

@Component({
  selector: 'wackadoo-field-url',
  templateUrl: './url.component.html',

})
export class UrlComponent extends StringComponent implements OnInit, OnDestroy {

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
