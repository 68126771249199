<wackadoo-banner wackadooFileDragOver [mode]="mode" [showLoginMenu]="false"> </wackadoo-banner>
<wackadoo-main wackadooFileDragOver [mode]="mode"></wackadoo-main>
<wackadoo-footer wackadooFileDragOver [mode]="mode"></wackadoo-footer>
<wackadoo-alert></wackadoo-alert>
<wackadoo-confirm></wackadoo-confirm>
<wackadoo-please-wait></wackadoo-please-wait>
<wackadoo-prompt></wackadoo-prompt>
<wackadoo-search></wackadoo-search>
<wackadoo-chart></wackadoo-chart>
<wackadoo-error-modal></wackadoo-error-modal>
<wackadoo-system-message></wackadoo-system-message>
<wackadoo-e-mailer></wackadoo-e-mailer>
<wackadoo-resource-modal></wackadoo-resource-modal>
<wackadoo-resource-select-modal></wackadoo-resource-select-modal>
<wackadoo-select-value></wackadoo-select-value>
