import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[wackadooPagePlaceholder]'
})
export class PagePlaceholderDirective {

  constructor(
    public elementRef: ElementRef,
  ) { }

}
