<div class="card">
	<div class="card-header">
		User role capabilities for this application...
	</div>
	<div class="card-body">
		<div class="marginBottomQuarterEm italic underline">
			admin
		</div>
		<ul>
			<!-- REMEMBER: ApplicationMetaData.list() will auto-add the appID, but "search()" does not... -->
			<li *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'user role', 'name':'admin', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
				{{ r.value.value }}
			</li>
		</ul>
		<div class="marginBottomQuarterEm italic underline">
			director
		</div>
		<ul>
			<li *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'user role', 'name':'director', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
				{{ r.value.value }}
			</li>
		</ul>
		<div class="marginBottomQuarterEm italic underline">
			manager
		</div>
		<ul>
			<li *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'user role', 'name':'manager', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
				{{ r.value.value }}
			</li>
		</ul>
		<div class="marginBottomQuarterEm italic underline">
			staff
		</div>
		<ul>
			<li *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'user role', 'name':'staff', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
				{{ r.value.value }}
			</li>
		</ul>
		<div class="marginBottomQuarterEm italic underline">
			member
		</div>
		<ul>
			<li *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'user role', 'name':'member', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
				{{ r.value.value }}
			</li>
		</ul>
		<div class="marginBottomQuarterEm italic underline">
			guest
		</div>
		<ul>
			<li *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'user role', 'name':'guest', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
				{{ r.value.value }}
			</li>
		</ul>
		<div class="marginBottomQuarterEm italic underline">
			authorized
		</div>
		<ul>
			<li *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'user role', 'name':'authorized', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
				{{ r.value.value }}
			</li>
		</ul>
		<div class="marginBottomQuarterEm italic underline">
			public
		</div>
		<ul>
			<li *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'user role', 'name':'public', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
				{{ r.value.value }}
			</li>
		</ul>
	</div>
</div>
