import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AlertContent } from '../ui/modal-dialogs/alert/alert-content.model';
import { ConfirmContent } from '../ui/modal-dialogs/confirm/confirm-content.model';
import { PromptContent } from '../ui/modal-dialogs/prompt/prompt-content.model';
import { ErrorModalContent } from '../ui/modal-dialogs/error-modal/error-modal-content.model';
import { SearchModalContent } from '../ui/modal-dialogs/search/search-modal-content.model';
import { ChartModalContent } from '../ui/modal-dialogs/chart-modal/chart-modal-content.model';
import { ResourceModalContent } from '../ui/modal-dialogs/resource-modal/resource-modal-content';
import { SelectValueContent } from '../ui/modal-dialogs/resource-modal/select-value-content';
import { EMailerContent } from '../ui/modal-dialogs/e-mailer/e-mailer-content';
import { FieldMode } from '../data/field/field-mode.model';
import { WResource } from '../data/resource.model';
import { WEvent } from '../data/event.model';
import { take } from 'rxjs/operators';
import { SystemMessageContent } from '../ui/modal-dialogs/system-message/system-message-content.model';
import { ResourceSelectContent } from '../ui/modal-dialogs/resource-select-modal/resource-select-content';

@Injectable({
  providedIn: 'root'
})
export class ModalDialogService {
  showPleaseWaitSubject: Subject<boolean|string>;
  showAlertSubject: Subject<AlertContent>;
  showConfirmSubject: Subject<ConfirmContent>;
  showPromptSubject: Subject<PromptContent>;
  showErrorSubject: Subject<ErrorModalContent>;
  showSystemMessageSubject: Subject<SystemMessageContent>;
  showModalSearchSubject: Subject<SearchModalContent>;
  showModalChartSubject: Subject<ChartModalContent>;
  showModalResourceSubject: Subject<ResourceModalContent>;
  showSelectResourceSubject: Subject<ResourceSelectContent>;
  showSelectValueSubject: Subject<SelectValueContent>;
  showEMailerSubject: Subject<EMailerContent>;

  private _pleaseWaitTimer: any = null;

  constructor() {

    this.showPleaseWaitSubject = new Subject<boolean|string>();
    this.showAlertSubject = new Subject<AlertContent>();
    this.showConfirmSubject = new Subject<ConfirmContent>();
    this.showPromptSubject = new Subject<PromptContent>();
    this.showErrorSubject = new Subject<ErrorModalContent>();
    this.showSystemMessageSubject = new Subject<SystemMessageContent>();
    this.showModalSearchSubject = new Subject<SearchModalContent>();
    this.showModalChartSubject = new Subject<ChartModalContent>();
    this.showModalResourceSubject = new Subject<ResourceModalContent>();
    this.showSelectResourceSubject = new Subject<ResourceSelectContent>();
    this.showSelectValueSubject = new Subject<SelectValueContent>();
    this.showEMailerSubject = new Subject<EMailerContent>();
  }

  closeModals(): void {
    this.showModalResourceSubject.next(null);
    this.showSelectResourceSubject.next(null);
    this.showSelectValueSubject.next(null);
    this.showEMailerSubject.next(null);
  }

  /*********************************************
   * Transient, modal dialogs...
   ********************************************/

  showPleaseWait(showModal: boolean | string, immediately?: boolean): void {
    immediately = typeof immediately !== 'undefined' ? immediately : false;
    // console.log('showPleaseWait(' + showModal + ')');
    if (showModal) {
      if (immediately) {
        this.showPleaseWaitSubject.next(showModal);
      } else {
        this._pleaseWaitTimer = setTimeout(
          () => {
            // HACK ALERT: We have to check for null timer
            // because clearTimeout does NOT seem to be working!
            if (this._pleaseWaitTimer !== null) {
              this.showPleaseWaitSubject.next(showModal);
            }
          },
          500
        );
      }
    } else {
      clearTimeout(this._pleaseWaitTimer);
      this._pleaseWaitTimer = null;
      this.showPleaseWaitSubject.next(false);
    }
  }

  showAlert(message: string, title?: string): Observable<void> {
    title = typeof title !== 'undefined' ? title : 'Alert Message';

    const obs = new Observable<void>(
      observer => {
        // we (always/usually/sometimes?) need to wait for the UI to settle before showing this modal...
        window.setTimeout(
          () => {
            const alertContent = new AlertContent(observer, message, title);
            this.showAlertSubject.next(alertContent);
          }
          , 0
        );
      }
    );
    // If there is no subscription, this Observable never fires
    // so we force the first subscription to display the modal...
    obs.subscribe().unsubscribe();
    return obs.pipe(take(1));
  }

  showConfirm(message: string, title: string): Observable<boolean> {
    const obs = new Observable<boolean>(
      observer => {
        const confirmContent = new ConfirmContent(observer, message, title);
        this.showConfirmSubject.next(confirmContent);
      }
    );
    return obs.pipe(take(1));
  }

  showPrompt(message: string, title: string, placeholder?: string, defaultValue?: string): Observable<string|boolean> {
    const obs = new Observable<string|boolean>(
      observer => {
        const promptContent = new PromptContent(observer, message, title, placeholder, defaultValue);
        this.showPromptSubject.next(promptContent);
      }
    );
    return obs.pipe(take(1));
  }

  showError(message: string, title: string, status?: string, error?: any): Observable<void>  {
    const obs = new Observable<void>(
      observer => {
        // we (always/usually/sometimes?) need to wait for the UI to settle before showing this modal...
        window.setTimeout(
          () => {
            const errorContent = new ErrorModalContent(observer, message, title, status, error);
            this.showErrorSubject.next(errorContent);
          }
          , 0
        );
      }
    );
    // If there is no subscription, this Observable never fires
    // so we force the first subscription to display the modal...
    obs.subscribe().unsubscribe();
    return obs.pipe(take(1));
  }

  showSystemMessage(message: string, timestamp?: string): void {
    const content = new SystemMessageContent(message, 'A Message From The System Administrator', timestamp);
    this.showSystemMessageSubject.next(content);
  }

  showModalSearch(ehName: string): Observable<boolean|string> {
    const obs = new Observable<boolean|string>(
      observer => {
        const modalSearchContent = new SearchModalContent(observer, ehName);
        this.showModalSearchSubject.next(modalSearchContent);
      }
    );
    // If there is no subscription, this Observable never fires
    // so we force the first subscription to display the modal...
    obs.subscribe().unsubscribe();
    return obs.pipe(take(1));
  }

  showModalChart(wChartJSON: string | object): void {
    // we (always/usually/sometimes?) need to wait for the UI to settle before showing this modal...
    window.setTimeout(
      () => {
        const modalChartContent = new ChartModalContent(wChartJSON);
        this.showModalChartSubject.next(modalChartContent);
      }
      , 0
    );
  }

  addResourceModal(componentName: string, eventHandler: string, resource?: WResource): Observable<WEvent> {
    return this._displayResourceModalDialog(componentName, resource, eventHandler, 'add', FieldMode.write) as Observable<WEvent>;
  }

  modifyResourceModal(componentName: string, eventHandler: string, resource: WResource): Observable<WEvent> {
    return this._displayResourceModalDialog(componentName, resource, eventHandler, 'modify', FieldMode.write) as Observable<WEvent>;
  }

  showResourceModal(componentName: string, resource: WResource, okButtonTitle?: string): Observable<WResource> {
    return this._displayResourceModalDialog(componentName, resource, null, 'display', FieldMode.write, okButtonTitle) as Observable<WResource>;
  }

  private _displayResourceModalDialog(componentName: string, resource: WResource, eventHandler?: string, action?: string, mode?: FieldMode, okButtonTitle?: string): Observable<WEvent|WResource> {
    eventHandler = typeof eventHandler === 'undefined' ? null : eventHandler;
    action = typeof action === 'undefined' ? null : action;
    mode = typeof mode === 'undefined' ? FieldMode.read : FieldMode.write;

    const obs = new Observable<WEvent|WResource>(
      observer => {
        const modalDialogContent = new ResourceModalContent(observer, componentName, resource, mode, eventHandler, action, okButtonTitle);
        this.showModalResourceSubject.next(modalDialogContent);
      }
    );
    // If there is no subscription, this Observable never fires
    // so we force the first subscription to display the modal...
    obs.subscribe().unsubscribe();
    return obs.pipe(take(1));
  }

  showSelectResource(eventHandler: string, action: string, parms: any, autoSelectSingleResource?: boolean): Observable<WResource> {
    const obs = new Observable<WResource>(
      observer => {
        const modalDialogContent = new ResourceSelectContent(observer, eventHandler, action, parms, autoSelectSingleResource);
        this.showSelectResourceSubject.next(modalDialogContent);
      }
    );
    return obs.pipe(take(1));
  }

  showSelectValue(componentName: string, resource: WResource, fieldName: string, selectButtonTitle?: string): Observable<any> {
    const obs = new Observable<any|boolean>(
      observer => {
        const modalDialogContent = new SelectValueContent(observer, componentName, resource, fieldName, selectButtonTitle);
        this.showSelectValueSubject.next(modalDialogContent);
      }
    );
    return obs.pipe(take(1));
  }

  showEMailer(eventHandler: string, parms: any, title?: string): void {

    // first, we hide other modals (if showing...)
    this.closeModals();

    // we (always/usually/sometimes?) need to wait for the UI to settle before showing this modal...
    window.setTimeout(
      () => {
        // then we show the EMailer...
        const emailerContent = new EMailerContent(eventHandler, parms, title);
        this.showEMailerSubject.next(emailerContent);
      }
      , 0
    );
  }

}

