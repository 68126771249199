import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BinaryComponent } from '../binary.component';
import { FileContentDescriptor } from 'src/app/client-core/ui/file-content-descriptor.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wackadoo-field-binary-image',
  templateUrl: './binary-image.component.html',
})
export class BinaryImageComponent extends BinaryComponent implements OnInit, OnDestroy {

  @Input() enableImageCaching = false;
  @Input() displayClass = '';

  private _imageFileContentSubscription: Subscription;

  oldUrl: string = null;

  ngOnInit(): void {
    super.ngOnInit();

    // we have to clean up old image stats when the new image loads...
    this._imageFileContentSubscription = this.loadFileContent.subscribe(
      (fcd: FileContentDescriptor) => {
        if (fcd) {
          delete this.f.width;
          delete this.f.height;
          delete this.f.aspectRatio;
          delete this.f.isBanner;
          delete this.f.goodLogoRatio;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this._imageFileContentSubscription) {
      this._imageFileContentSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public setURL(): void {
    super.setURL();
    if ((this.url !== null) && (this.url.indexOf('/getFile?') > 0)) {
      this.oldUrl = this.url;
      this.url +=  (this.enableImageCaching ? '' : '&timestamp=' + (new Date()).getTime());
    }
  }

  public calculateImageStats(): void {
    const stats = this.userInterfaceService.getImageStatistics(this.elementRef, 'img');
    if (stats) {
      // console.log('setURL()', stats);
      if (this.f) {
        this.f.width = stats.width;
        this.f.height = stats.height;
        this.f.aspectRatio = stats.aspectRatio;
        this.f.goodLogoRatio = stats.goodLogoRatio;
        this.f.isBanner = stats.isBanner;
      }
    }
  }

}
