<span *ngIf="f">
    <span *ngIf="mode === 'read'">
        {{ f.value }} 
    </span>
    <span *ngIf="mode === 'write'">
        <input 
            class="form-control"
            type="text" 
            [ngClass]="{'fieldChanged': f.changed}"
            [placeholder]="(f.required ? '* ' : '') + f.name"
            [name]="f.name" 
            [title]="(title ? title : f.name)"
            [size]="(f.length && (f.length < size) ? f.length : size)"
            [value]="f.value"
            [required]="f.required"
            [disabled]="f.readOnly"
            (input)="onInputChange($event)"
            (blur)="forceValidValue($event)"
        />
        <span class="italic" *ngIf="uniqueFieldStatus !== ''">
            ( {{ uniqueFieldStatus }} )
        </span>
    </span>
</span>
