import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserAuthService } from '../../../services/user-auth.service';
import { Subscription } from 'rxjs';
import { User } from '../../../data/user.model';
import { StatusService } from '../../../services/status.service';

@Component({
  selector: 'wackadoo-account-message',
  templateUrl: './account-message.component.html',

})
export class AccountMessageComponent implements OnInit, OnDestroy {

  message = '';
  isLoggedIn = false;
  private _userAuthChangedSubscription: Subscription;
  private _accountStatusSubscription: Subscription;

  constructor(
    private _userAuthService: UserAuthService,
    private _statusService: StatusService,
  ) { }

  ngOnInit(): void {
    this._userAuthChangedSubscription = this._userAuthService.currentUser.subscribe(
      (user: User) => {
        this.isLoggedIn = this._userAuthService.isLoggedIn;
      }
    );

    this._accountStatusSubscription = this._statusService.accountStatus.subscribe(
      (s: string) => {
        this.message = (s ? s : '');
      }
    );
  }

  ngOnDestroy(): void {
    if (this._userAuthChangedSubscription) {
      this._userAuthChangedSubscription.unsubscribe();
    }
    if (this._accountStatusSubscription) {
      this._accountStatusSubscription.unsubscribe();
    }
  }

}
