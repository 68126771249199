<div class="card">
  <div class="card-header">
    Please Enter Your EMail Password 
  </div>
  <div class="card-body">
    <div>
      <wackadoo-field [f]="resource.smtpAccountName" mode="read"></wackadoo-field>
    </div>
    <div>
      <wackadoo-field-simple-password [f]="resource.smtpPassword" mode="write"></wackadoo-field-simple-password>
    </div>
    <div class="marginTopWholeEm">
      Please note:
    </div>
    <ul>
      <li>
        We ONLY use this password to send this specific email.
      </li>
      <li>
        We do NOT store, log or keep it in any way.
      </li>
      <li class="bold italic" *ngIf="resource.smtpNote.isPopulated">
        <wackadoo-field [f]="resource.smtpNote" mode="read"></wackadoo-field>
      </li>
    </ul>
  </div>
</div>
