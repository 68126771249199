import { WResource } from './resource.model';

export class User {

    userID: number;
    userName: string;
    firstName: string;
    lastName: string;
    fullName: string;
    lastAccess: Date;
    accountID: number;
    accountName: string;
    role: string;

    // some helper stuff for determining the user's role... (used to control UX element display)

    isPublicOnly = true;
    isAuthorizedOnly = false;
    isGuestOnly = false;
    isMemberOnly = false;
    isStaffOnly = false;
    isManagerOnly = false;
    isDirectorOnly = false;
    isAdminOnly = false;
    isSysAdminOnly = false;

    isPublicOrAbove = true;
    isAuthorizedOrAbove = false;
    isGuestOrAbove = false;
    isMemberOrAbove = false;
    isStaffOrAbove = false;
    isManagerOrAbove = false;
    isDirectorOrAbove = false;
    isAdminOrAbove = false;
    isSysAdminOrAbove = false;

    isPublicOrBelow = true;
    isAuthorizedOrBelow = false;
    isGuestOrBelow = false;
    isMemberOrBelow = false;
    isStaffOrBelow = false;
    isManagerOrBelow = false;
    isDirectorOrBelow = false;
    isAdminOrBelow = false;
    isSysAdminOrBelow = false;

    /*
    * Note that rawCookieValueString parsed per EventServerServlet.generateReadableUserCookieValue()
    * which (for all apps except AccountManager...) sets extra values if role = 'guest', prior to URL encoding the JSON string...
    */

    guestID: number = null;
    guestType: string = null;
    guest: WResource = null;

    linkType: string = null;  // The name, if any, of the many-to-many resource type "linking" the guest and the group...

    groupID: number = null;
    groupType: string = null;
    group: WResource = null;

    guestPage: string = null;    // the name of the page/EH that the guest can see...

    constructor(userCookieAsJSON: {[name: string]: { '@type': string; text: string }}) {

        this.userID = 		Number(userCookieAsJSON.userID.text);
        this.userName = 	userCookieAsJSON.userName.text;
        this.firstName = 	userCookieAsJSON.firstName.text;
        this.lastName = 	userCookieAsJSON.lastName.text;
        this.fullName = 	this.firstName + ' ' + this.lastName;
        this.lastAccess =  	new Date(userCookieAsJSON.lastAccess.text);
        this.accountID = 	Number(userCookieAsJSON.accountID.text);
        this.accountName = 	userCookieAsJSON.accountName.text;
        this.role =         userCookieAsJSON.role.text;

        // console.log(userCookieAsJSON, 'guestID: ' + userCookieAsJSON.guestID);

        if ((this.role === 'guest') && userCookieAsJSON.hasOwnProperty('guestID')) {
            this.guestID = 		Number(userCookieAsJSON.guestID.text);
            this.guestType = 	userCookieAsJSON.guestType.text;
            this.linkType = 	(userCookieAsJSON.linkType ? userCookieAsJSON.linkType.text : null);
            this.groupID = 		(userCookieAsJSON.groupID ? Number(userCookieAsJSON.groupID.text) : null);
            this.groupType = 	(userCookieAsJSON.groupType ? userCookieAsJSON.groupType.text : null);
        }

        this.setUserRoleFlags();

    }


  private setUserRoleFlags(): void {

    this.isPublicOnly = false;
    this.isAuthorizedOnly = false;
    this.isGuestOnly = false;
    this.isMemberOnly = false;
    this.isStaffOnly = false;
    this.isManagerOnly = false;
    this.isDirectorOnly = false;
    this.isAdminOnly = false;
    this.isSysAdminOnly = false;

    this.isPublicOrAbove = false;
    this.isAuthorizedOrAbove = false;
    this.isGuestOrAbove = false;
    this.isMemberOrAbove = false;
    this.isStaffOrAbove = false;
    this.isManagerOrAbove = false;
    this.isDirectorOrAbove = false;
    this.isAdminOrAbove = false;
    this.isSysAdminOrAbove = false;

    this.isPublicOrBelow = false;
    this.isAuthorizedOrBelow = false;
    this.isGuestOrBelow = false;
    this.isMemberOrBelow = false;
    this.isStaffOrBelow = false;
    this.isManagerOrBelow = false;
    this.isDirectorOrBelow = false;
    this.isAdminOrBelow = false;
    this.isSysAdminOrBelow = false;

    switch (this.role) {

      case 'sysadmin' :

          this.isPublicOnly = false;
          this.isAuthorizedOnly = false;
          this.isGuestOnly = false;
          this.isMemberOnly = false;
          this.isStaffOnly = false;
          this.isManagerOnly = false;
          this.isDirectorOnly = false;
          this.isAdminOnly = false;
          this.isSysAdminOnly = true;

          this.isPublicOrAbove = true;
          this.isAuthorizedOrAbove = true;
          this.isGuestOrAbove = true;
          this.isMemberOrAbove = true;
          this.isStaffOrAbove = true;
          this.isManagerOrAbove = true;
          this.isDirectorOrAbove = true;
          this.isAdminOrAbove = true;
          this.isSysAdminOrAbove = true;

          this.isPublicOrBelow = false;
          this.isAuthorizedOrBelow = false;
          this.isGuestOrBelow = false;
          this.isMemberOrBelow = false;
          this.isStaffOrBelow = false;
          this.isManagerOrBelow = false;
          this.isDirectorOrBelow = false;
          this.isAdminOrBelow = false;
          this.isSysAdminOrBelow = true;

          break;

      case 'admin' :

          this.isPublicOnly = false;
          this.isAuthorizedOnly = false;
          this.isGuestOnly = false;
          this.isMemberOnly = false;
          this.isStaffOnly = false;
          this.isManagerOnly = false;
          this.isDirectorOnly = false;
          this.isAdminOnly = true;
          this.isSysAdminOnly = false;

          this.isPublicOrAbove = true;
          this.isAuthorizedOrAbove = true;
          this.isGuestOrAbove = true;
          this.isMemberOrAbove = true;
          this.isStaffOrAbove = true;
          this.isManagerOrAbove = true;
          this.isDirectorOrAbove = true;
          this.isAdminOrAbove = true;
          this.isSysAdminOrAbove = false;

          this.isPublicOrBelow = false;
          this.isAuthorizedOrBelow = false;
          this.isGuestOrBelow = false;
          this.isMemberOrBelow = false;
          this.isStaffOrBelow = false;
          this.isManagerOrBelow = false;
          this.isDirectorOrBelow = false;
          this.isAdminOrBelow = true;
          this.isSysAdminOrBelow = true;

          break;

      case 'director' :

          this.isPublicOnly = false;
          this.isAuthorizedOnly = false;
          this.isGuestOnly = false;
          this.isMemberOnly = false;
          this.isStaffOnly = false;
          this.isManagerOnly = false;
          this.isDirectorOnly = true;
          this.isAdminOnly = false;
          this.isSysAdminOnly = false;

          this.isPublicOrAbove = true;
          this.isAuthorizedOrAbove = true;
          this.isGuestOrAbove = true;
          this.isMemberOrAbove = true;
          this.isStaffOrAbove = true;
          this.isManagerOrAbove = true;
          this.isDirectorOrAbove = true;
          this.isAdminOrAbove = false;
          this.isSysAdminOrAbove = false;

          this.isPublicOrBelow = false;
          this.isAuthorizedOrBelow = false;
          this.isGuestOrBelow = false;
          this.isMemberOrBelow = false;
          this.isStaffOrBelow = false;
          this.isManagerOrBelow = true;
          this.isDirectorOrBelow = true;
          this.isAdminOrBelow = true;
          this.isSysAdminOrBelow = true;

          break;

      case 'manager' :

          this.isPublicOnly = false;
          this.isAuthorizedOnly = false;
          this.isGuestOnly = false;
          this.isMemberOnly = false;
          this.isStaffOnly = false;
          this.isManagerOnly = true;
          this.isDirectorOnly = false;
          this.isAdminOnly = false;
          this.isSysAdminOnly = false;

          this.isPublicOrAbove = true;
          this.isAuthorizedOrAbove = true;
          this.isGuestOrAbove = true;
          this.isMemberOrAbove = true;
          this.isStaffOrAbove = true;
          this.isManagerOrAbove = true;
          this.isDirectorOrAbove = false;
          this.isAdminOrAbove = false;
          this.isSysAdminOrAbove = false;

          this.isPublicOrBelow = false;
          this.isAuthorizedOrBelow = false;
          this.isGuestOrBelow = false;
          this.isMemberOrBelow = false;
          this.isStaffOrBelow = false;
          this.isManagerOrBelow = true;
          this.isDirectorOrBelow = true;
          this.isAdminOrBelow = true;
          this.isSysAdminOrBelow = true;

          break;

      case 'staff' :

          this.isPublicOnly = false;
          this.isAuthorizedOnly = false;
          this.isGuestOnly = false;
          this.isMemberOnly = false;
          this.isStaffOnly = true;
          this.isManagerOnly = false;
          this.isDirectorOnly = false;
          this.isAdminOnly = false;
          this.isSysAdminOnly = false;

          this.isPublicOrAbove = true;
          this.isAuthorizedOrAbove = true;
          this.isGuestOrAbove = true;
          this.isMemberOrAbove = true;
          this.isStaffOrAbove = true;
          this.isManagerOrAbove = false;
          this.isDirectorOrAbove = false;
          this.isAdminOrAbove = false;
          this.isSysAdminOrAbove = false;

          this.isPublicOrBelow = false;
          this.isAuthorizedOrBelow = false;
          this.isGuestOrBelow = false;
          this.isMemberOrBelow = false;
          this.isStaffOrBelow = true;
          this.isManagerOrBelow = true;
          this.isDirectorOrBelow = true;
          this.isAdminOrBelow = true;
          this.isSysAdminOrBelow = true;

          break;

      case 'member' :

          this.isPublicOnly = false;
          this.isAuthorizedOnly = false;
          this.isGuestOnly = false;
          this.isMemberOnly = true;
          this.isStaffOnly = false;
          this.isManagerOnly = false;
          this.isDirectorOnly = false;
          this.isAdminOnly = false;
          this.isSysAdminOnly = false;

          this.isPublicOrAbove = true;
          this.isAuthorizedOrAbove = true;
          this.isGuestOrAbove = true;
          this.isMemberOrAbove = true;
          this.isStaffOrAbove = false;
          this.isManagerOrAbove = false;
          this.isDirectorOrAbove = false;
          this.isAdminOrAbove = false;
          this.isSysAdminOrAbove = false;

          this.isPublicOrBelow = false;
          this.isAuthorizedOrBelow = false;
          this.isGuestOrBelow = false;
          this.isMemberOrBelow = true;
          this.isStaffOrBelow = true;
          this.isManagerOrBelow = true;
          this.isDirectorOrBelow = true;
          this.isAdminOrBelow = true;
          this.isSysAdminOrBelow = true;

          break;

      case 'guest':

          this.isPublicOnly = false;
          this.isAuthorizedOnly = false;
          this.isGuestOnly = true;
          this.isMemberOnly = false;
          this.isStaffOnly = false;
          this.isManagerOnly = false;
          this.isDirectorOnly = false;
          this.isAdminOnly = false;
          this.isSysAdminOnly = false;

          this.isPublicOrAbove = true;
          this.isAuthorizedOrAbove = true;
          this.isGuestOrAbove = true;
          this.isMemberOrAbove = false;
          this.isStaffOrAbove = false;
          this.isManagerOrAbove = false;
          this.isDirectorOrAbove = false;
          this.isAdminOrAbove = false;
          this.isSysAdminOrAbove = false;

          this.isPublicOrBelow = false;
          this.isAuthorizedOrBelow = false;
          this.isGuestOrBelow = true;
          this.isMemberOrBelow = true;
          this.isStaffOrBelow = true;
          this.isManagerOrBelow = true;
          this.isDirectorOrBelow = true;
          this.isAdminOrBelow = true;
          this.isSysAdminOrBelow = true;

          break;

      case 'authorized':

          this.isPublicOnly = false;
          this.isAuthorizedOnly = true;
          this.isGuestOnly = false;
          this.isMemberOnly = false;
          this.isStaffOnly = false;
          this.isManagerOnly = false;
          this.isDirectorOnly = false;
          this.isAdminOnly = false;
          this.isSysAdminOnly = false;

          this.isPublicOrAbove = true;
          this.isAuthorizedOrAbove = true;
          this.isGuestOrAbove = false;
          this.isMemberOrAbove = false;
          this.isStaffOrAbove = false;
          this.isManagerOrAbove = false;
          this.isDirectorOrAbove = false;
          this.isAdminOrAbove = false;
          this.isSysAdminOrAbove = false;

          this.isPublicOrBelow = false;
          this.isAuthorizedOrBelow = true;
          this.isGuestOrBelow = true;
          this.isMemberOrBelow = true;
          this.isStaffOrBelow = true;
          this.isManagerOrBelow = true;
          this.isDirectorOrBelow = true;
          this.isAdminOrBelow = true;
          this.isSysAdminOrBelow = true;

          break;

      case 'public' :
      default:

          this.isPublicOnly = true;
          this.isAuthorizedOnly = false;
          this.isGuestOnly = false;
          this.isMemberOnly = false;
          this.isStaffOnly = false;
          this.isManagerOnly = false;
          this.isDirectorOnly = false;
          this.isAdminOnly = false;
          this.isSysAdminOnly = false;

          this.isPublicOrAbove = true;
          this.isAuthorizedOrAbove = false;
          this.isGuestOrAbove = false;
          this.isMemberOrAbove = false;
          this.isStaffOrAbove = false;
          this.isManagerOrAbove = false;
          this.isDirectorOrAbove = false;
          this.isAdminOrAbove = false;
          this.isSysAdminOrAbove = false;

          this.isPublicOrBelow = true;
          this.isAuthorizedOrBelow = true;
          this.isGuestOrBelow = true;
          this.isMemberOrBelow = true;
          this.isStaffOrBelow = true;
          this.isManagerOrBelow = true;
          this.isDirectorOrBelow = true;
          this.isAdminOrBelow = true;
          this.isSysAdminOrBelow = true;

          break;
    }
  }

}
