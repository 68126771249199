import { Component } from '@angular/core';
import { Globals } from '../../../services/global.service';

@Component({
  selector: 'wackadoo-about-name',
  templateUrl: './about-name.component.html',

})
export class AboutNameComponent {

  domain = Globals.domain;

  constructor() { }

}
