<div>
  <div class="card">
    <div class="card-header d-flex justify-content-between">
      <div>
        <wackadoo-field [f]="resource.tifName" [mode]="mode"></wackadoo-field>
      </div>
      <div class="smallish unbold">
        <wackadoo-field [f]="resource.tifDescription" [mode]="mode"></wackadoo-field>
      </div>
      <div class="smallish unbold">
        <wackadoo-field [f]="resource.tifStartRow" [mode]="mode"></wackadoo-field>
      </div>
      <div class="smallish unbold">
        <wackadoo-field [f]="resource.tifType" [mode]="mode"></wackadoo-field>
      </div>
      <div class="smallish unbold marginRightWholeEm">
        <wackadoo-field [f]="resource.tifCharEncoding" [mode]="mode"></wackadoo-field>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-sm-2" *ngIf="resource.tifID.value">
          <div class="large bold">
            Fields That Can Be Imported
          </div>
          <div *ngIf="resource.tifID.value">
            <div class="row" *ngFor="let fieldName of fieldNamesToAdd;">
              <div class="col-sm-12 rollover" [ngClass]="{selected:selectedFieldName === fieldName}" (click)="selectFieldName(fieldName)">
                {{ fieldName }}
              </div>
            </div>
          </div>
          <div *ngIf="!resource.tifID.value">
            You must "Save" a new ImportFormat BEFORE you may add fields to it...
          </div>
        </div>
        <div class="col-sm-10 top" *ngIf="resource.tifID.value">
          <div class="border paddingHalfEm" 
                wackadooFileDrop 
                [fileDropSubject]="fileDropSubject" 
                [validExtensions]="'txt,csv,log'"
          >
            <div class="large bold">
              Drop An Example Of The Report File Here...
            </div>
            <textarea [(ngModel)]="fcd.fileContent" class="nowrap no-resize heightViewPort25 fillAvailableWidth"

                      wackadooImportTemplateReportTextArea 
                      [onSelectSubject]="onSelectSubject"
                      [initialSelectionStart]="initialSelectionStart"
                      [initialSelectionEnd]="initialSelectionEnd"
                      [debug]="false"
            ></textarea>
            <div class="d-flex justify-content-between small" *ngIf="fcd.valid">
              <div>
                {{fcd.fileName}}
              </div>
              <div>
                {{fcd.fileType}}
              </div>
              <div>
                {{ formatNumber(fcd.fileSize) }} bytes
              </div>
              <div>
                {{ formatDate(fcd.fileDate) }}
              </div>
            </div>
          </div>
          <div class="row" *ngIf="characterOnly">
            <div class="col-sm-1">
            </div>
            <div class="col-sm-11">
              IMPORTANT! When selecting a field in a character-aligned report, make sure to include the ENTIRE width of the field - including blank spaces.
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 right">
              selected text:
            </div>
            <div class="col-sm-10">
              <span class="italic">"{{selectedText ? selectedText : ''}}"</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-2 right">
              selected row:
            </div>
            <div class="col-sm-10">
              <span class="italic">"{{selectedRow ? selectedRow : ''}}"</span>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <br/>
            </div>
          </div>
          <div *ngIf="selectedField">
            <div class="row">
              <div class="col-sm-6 bold">
                <wackadoo-field [f]="selectedField.tifFieldName" [mode]="'read'"></wackadoo-field>
                <span class="italic" *ngIf="selectedField.tifFieldID.value === null">(undefined, new field...)</span>
              </div>
              <div class="col-sm-1 right">
                Row: 
              </div>
              <div class="col-sm-1">
                <wackadoo-field [f]="selectedField.tifFieldRow" [mode]="'write'"></wackadoo-field>
              </div>
              <div class="col-sm-1 right">
                Column:
              </div>
              <div class="col-sm-1">
                <wackadoo-field [f]="selectedField.tifFieldColumn" [mode]="'write'"></wackadoo-field>
              </div>
              <div class="col-sm-1 right">
                Length: 
              </div>
              <div class="col-sm-1">
                <wackadoo-field [f]="selectedField.tifFieldLength" [mode]="'write'"></wackadoo-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 paddingRight0">
                This field is required in order to have a valid record:
              </div>
              <div class="col-sm-1">
                <wackadoo-field [f]="selectedField.tifFieldRequired" [mode]="'write'"></wackadoo-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                Extract partial value:
              </div>
              <div class="col-sm-2">
                <wackadoo-field [f]="selectedField.tifFieldPartial" [mode]="'write'"></wackadoo-field>
              </div>
              <div class="col-sm-2">
                Dividing character:
              </div>
              <div class="col-sm-1">
                <wackadoo-field [f]="selectedField.tifFieldPartialDelimiter" [mode]="'write'"></wackadoo-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                Extract from Group 1 via ReEx: 
              </div>
              <div class="col-sm-2">
                <wackadoo-field [f]="selectedField.tifFieldRegEx" [mode]="'write'"></wackadoo-field> 
              </div>
              <div class="col-sm-2">
                Pattern:
              </div>
              <div class="col-sm-4">
                <wackadoo-field [f]="selectedField.tifFieldRegExPattern" [mode]="'write'"></wackadoo-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                How to modify (or interpret) the final value: 
              </div>
              <div class="col-sm-2">
                <wackadoo-field [f]="selectedField.tifFieldScrub" [mode]="'write'"></wackadoo-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                The final value is valid if it is: 
              </div>
              <div class="col-sm-2">
                <wackadoo-field [f]="selectedField.tifFieldCompare" [mode]="'write'"></wackadoo-field>
              </div>
              <div class="col-sm-5">
                <wackadoo-field [f]="selectedField.tifFieldCompareValue" [mode]="'write'"></wackadoo-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5 right">
                <div>
                  Set static value for all resources:
                </div>
                <div>
                  Set static as the default value if nothing else is found:
                </div>
              </div>
              <div class="col-sm-1">
                <div>
                  <wackadoo-field [f]="selectedField.tifFieldStatic" [mode]="'write'"></wackadoo-field>
                </div>
                <div>
                  <wackadoo-field [f]="selectedField.tifFieldStaticAsDefault" [mode]="'write'"></wackadoo-field>
                </div>
              </div>
              <div class="col-sm-5">
                <wackadoo-field [f]="selectedField.tifFieldStaticValue" [mode]="'write'"></wackadoo-field>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-8">
                This is a header-type field that is read only one time for the whole report (not read for each line...) whose value is added to ALL records: 
              </div>
              <div class="col-sm-1">
                <wackadoo-field [f]="selectedField.tifFieldAllRecords" [mode]="'write'"></wackadoo-field>
              </div>
            </div>
            <div class="row marginTopWholeEm">
              <div class="col-sm-12">
                <div class="btn-group">
                  <button type="button" (click)="saveImportFieldDefn()" title="Save Import Field Defn" data-abbrev="Sv Fld Defn" class="btn btn-secondary actionButton saveImportFieldDefn"><span class="fas fa-ok" aria-hidden="true"></span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
