import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-log-cleanup-service',
  templateUrl: './log-cleanup-service.component.html',

})
export class LogCleanupServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
