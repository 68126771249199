import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[wackadooImageLoaded]'
})
export class ImageLoadedDirective {

  @Output() loaded = new EventEmitter();

  @HostListener('load', ['$event']) loady = this.onLoad;

  constructor(private elRef: ElementRef<HTMLImageElement>) {
    this.checkIfComplete();
  }

  onLoad(): void {
    // console.log('emitting loading event');
    this.loaded.emit();
  }

  checkIfComplete(): void {
    // console.log('entering checkIfComplete()');
    if (this.elRef.nativeElement.complete) {
      this.onLoad();
    } else {
      window.setTimeout(
        () => {
          this.checkIfComplete();
        }
        , 100
      );
    }
  }
}
