import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit, ComponentRef, Renderer2, ElementRef } from '@angular/core';
import { UserAuthService } from '../../services/user-auth.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { EventPageComponent } from '../event-page/event-page.component';
import { WResource } from '../../data/resource.model';
import { Subscription, Subject } from 'rxjs';
import { SelectedResource } from '../../ui/main/current-selection/selected-resource.model';
import { ModalDialogService } from '../../services/modal-dialog.service';
import { GridContentPlaceholderDirective } from './grid-content-placeholder.directive';
import { GridButtonBarPlaceholderDirective } from './grid-button-bar-placeholder.directive';
import { DetailContentPlaceholderDirective } from './detail-content-placeholder.directive';
import { DetailButtonBarPlaceholderDirective } from './detail-button-bar-placeholder.directive';
import { FieldMode } from '../../data/field/field-mode.model';
import { EventServerService } from '../../services/event-server.service';
import { UrlSegment, ActivatedRoute } from '@angular/router';
import { User } from '../../data/user.model';

@Component({
  selector: 'wackadoo-repository-page',
  templateUrl: './repository-page.component.html',

})
export class RepositoryPageComponent extends EventPageComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(GridContentPlaceholderDirective, { static: false }) gridContentPlaceholder: GridContentPlaceholderDirective;
  @ViewChild(GridButtonBarPlaceholderDirective, { static: false }) gridButtonBarPlaceholder: GridButtonBarPlaceholderDirective;
  @ViewChild(DetailContentPlaceholderDirective, { static: false }) detailContentPlaceholder: DetailContentPlaceholderDirective;
  @ViewChild(DetailButtonBarPlaceholderDirective, { static: false }) detailButtonBarPlaceholder: DetailButtonBarPlaceholderDirective;

  private _selectionChangeSubscription: Subscription;

  selectedResource: WResource = null;

  mode = FieldMode.read;
  private _fieldModeSubscription: Subscription;

  editMode = false;

  @Input() gridFieldsToHide: string [] = [];
  @Input() gridFieldsToShow: string [] = [];

  // these hold the names of the customized components for a given instance of this class
  @Input() gridResourceComponentClassName: string = null;
  @Input() leftJustifyGridHeaders = false;
  @Input() gridButtonBarComponentClassName: string = null;
  @Input() detailContentComponentClassName: string = null;
  @Input() detailButtonBarComponentClassName: string = null;

  private _dynamicComponentRefsToDestroy: ComponentRef<any> [] = [];

  @Input() user: User;

  resourceClickSubject: Subject<WResource> = null;
  _resourceClickSubscription: Subscription = null;

  gridRollover = false;
  gridHint = '';

  showAllFieldsOnPhone = false;
  hideSelectedFKeysInGrid = false;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    public renderer: Renderer2,
    public repositoryPageElementRef: ElementRef,
    public eventServerService: EventServerService,
    public route: ActivatedRoute,
  ) {
    super(userAuthService, userInterfaceService, modalDialogService);
  }

  ngOnInit(): void {
    // console.log('RepositoryPageComponent.ngOnInit()'); // , new Error().stack);
    super.ngOnInit();

    // We use the value from UISvc to allow control of the select function in sub-classes...
    this.gridRollover = this.userInterfaceService.selectResourceOnRepositoryGridClick;
    this.gridHint = (this.userInterfaceService.selectResourceOnRepositoryGridClick ? 'Click to open detail page...' : '');

    this.mode = this.userInterfaceService.resourceDetailPageDisplayMode.getValue();

    this.selectedResource = this.userInterfaceService.getSelectedResource(this.eventHandler);

    this._selectionChangeSubscription = this.userInterfaceService.selectionChange.subscribe(
      (sr: SelectedResource) => {
        // console.log('RepositoryPageComponent.selectionChange()', this.eventHandler, sr);
        // console.log('RepositoryPageComponent.selectionChange()', this.eventHandler, (sr && sr.resource === null ? 'UN-select' : 'select'), sr);
        if (sr.ehName === this.eventHandler) {

          // console.log('RepositoryPageComponent.selectionChange() - select on SELF...', sr);
          this.selectedResource = sr.resource;

          // HACKALERT! The page structure is conditional on the existence of this.selectedResource!
          // That means that the detail component placeholders are undefined until the page recognizes
          // that the selectedResource is set and restructures itself accordingly.  Basically, we have
          // a race-condition, so we use this timeout mechanism to wait until the page settles.
          window.setTimeout(
            () => {
              this.loadComponents();
            }
            , 0
          );

        } else {
          // Either, we un-selected a DIFFERENT type of resource,
          // Or, this.eventHandler is not yet defined...
          // so we do NOTHING...
        }
      }
    );

    this.resourceClickSubject = new Subject<WResource>();

    this._resourceClickSubscription = this.resourceClickSubject.subscribe(
      (resource: WResource) => {

        // console.log('RepositoryPageComponent._resourceClickSubscription()', resource, this.userInterfaceService.selectResourceOnRepositoryGridClick);

        if (this.userInterfaceService.selectResourceOnRepositoryGridClick) {
          this.userInterfaceService.selectResource(this.eventHandler, resource);
          this.userInterfaceService.loadPage(this.eventHandler, null, resource.keyField.asParm);
        }

      }
    );

    this._fieldModeSubscription = this.userInterfaceService.resourceDetailPageDisplayMode.subscribe(
      (fieldMode: FieldMode) => {
        // console.log('RepositoryPageComponent.selectionChange() - changing fieldMode: ' + fieldMode);
        this.mode = fieldMode;
        this.editMode = (this.mode === FieldMode.write);

        this.loadComponents();
      }
    );

    // required because it can change while we're on this page...
    this.route.url.subscribe(
       (url: any) => {
          // console.log('RepositoryPageComponent - url', url);
          const newPageName = this.extractPageName(url);
          // if we changed pages, re-set the page
          if (this.eventHandler !== newPageName) {
            // console.log('RepositoryPageComponent - this.eventHandler: ' + this.eventHandler + ', new page: ' + newPageName);
            this.eventHandler = newPageName;
            this.selectedResource = this.userInterfaceService.getSelectedResource(this.eventHandler);
          }
       }
    );

    // console.log('grid-content.ngOnInit() - ' + this.eventHandler + '.' + this.action, this.parameters, this.resources, this.gridFieldsToHide, this.gridFieldsToShow);

  }

  extractPageName(url: UrlSegment[]): string {
    const temp = url[url.length - 1].path;
    return(temp);
  }

  ngOnDestroy(): void {
    // console.log('RepositoryPageComponent.ngOnDestroy()');

    if (this._resourceClickSubscription) {
      this._resourceClickSubscription.unsubscribe();
    }

    if (this._selectionChangeSubscription) {
      this._selectionChangeSubscription.unsubscribe();
    }
    if (this._fieldModeSubscription) {
      this._fieldModeSubscription.unsubscribe();
    }

    for (const cr of this._dynamicComponentRefsToDestroy) {
      if (cr) {
        cr.destroy();
      }
    }
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    // console.log('RepositoryPageComponent.ngAfterViewInit()');
    this.loadComponents();
  }

  postPageEvent(): void {
    // console.log('RepositoryPageComponent.postPageEvent() - ' + this.eventHandler, this.event, this.resources, this.parameters, this.mode, new Date().toJSON());

    // First thing we do is grab the currently selected resource for this eventHandler.

    this.selectedResource = this.userInterfaceService.getSelectedResource(this.eventHandler);

    // console.log('RepositoryPageComponent.postPageEvent()', this.action, 'this.selectedResource: ', this.selectedResource, this.resources);

    // The second thing we do is replace it with the "singleton" resource in the event,
    // but only for "list" and "search" singletons that match the search parameters!

    // Note: If we are doing an add or modify, then we have a selected
    // resource that contains some new values that we need to keep.

    if (
        ((this.action === 'search') || (this.action === 'list'))
        && this.resources && (this.resources.length === 1)
        && (this.parameters[this.resources[0].keyField.name] === this.resources[0].keyField.value)
      ) {
      // console.log('RepositoryPageComponent.postPageEvent() -\nold selectedResource', this.selectedResource, '\nabout to select', this.resources[0]);
      this.userInterfaceService.selectResource(this.eventHandler, this.resources[0]);
      this.selectedResource = this.resources[0];
    }

    // and finally drop in the new Components...
    this.loadComponents();
  }

  toggleMode(mode: FieldMode): void {
    this.mode = mode;
  }

  loadComponents(): void {

    if (!this.eventHandler) {
      return;
    }

    // Remember, this component might still be in place from a previous DIFFERENT eventHandler page load...
    // Also, it might be for an ADD, in which case, there is no "status" in this.event...
    // So we wait until we have an event with "status" before we display the content.

    // console.log('RepositoryPageComponent.loadComponents()', this.event, this.selectedResource);

    if (!this.event || !this.event.status) {
      return;
    }

    // console.log('RepositoryPageComponent.loadComponents()', this.selectedResource, this.detailContentPlaceholder);

    if (!this.selectedResource) {

      if (this.gridButtonBarPlaceholder) {
        this._dynamicComponentRefsToDestroy.push(
          this.userInterfaceService.loadComponent(
            this.gridButtonBarPlaceholder.viewContainerRef,
            this.gridButtonBarComponentClassName,
            {
              eventHandler: this.eventHandler,
              parameters: this.parameters,
              user: this.user,
              repositoryPageElementRef: this.repositoryPageElementRef
            },
            false,
            'GridButtonBarComponent'
          )
        );
      }

    } else {

      if (this.detailContentPlaceholder) {
        this._dynamicComponentRefsToDestroy.push(
          this.userInterfaceService.loadComponent(
            this.detailContentPlaceholder.viewContainerRef,
            this.detailContentComponentClassName,
            {
              resource: this.selectedResource,
              eventHandler: this.eventHandler,
              action: this.action,
              mode: this.mode,
              user: this.user,
              repositoryPageElementRef: this.repositoryPageElementRef
            },
            false,
            'DetailContentComponent'
          )
        );
      }

      if (this.detailButtonBarPlaceholder) {
        this._dynamicComponentRefsToDestroy.push(
          this.userInterfaceService.loadComponent(
            this.detailButtonBarPlaceholder.viewContainerRef,
            this.detailButtonBarComponentClassName,
            {
              eventHandler: this.eventHandler,
              action: this.action,
              parameters: this.parameters,
              resource: this.selectedResource,
              editMode: this.editMode,
              user: this.user,
              repositoryPageElementRef: this.repositoryPageElementRef
            },
            false,
            'DetailButtonBarComponent'
          )
        );
      }
    }
  }

}
