<div class="marginRightHalfEm nowrap input-group" *ngIf="eventHandlerSupportsSearch">
    <input type="text" [disabled]="disabled" class="wackadoo-grid-search-control round-left-corners left" placeholder="Query" [(ngModel)]="query"/>
    <div class="input-group-append">
        <button title="Search" 
            [disabled]="disabled" 
            class="btn btn-outline-secondary wackadoo-grid-search-control" 
            type="button" 
            (click)="search()"
        ><span class="fas fa-search"></span></button>
        <button title="Clear Query"
            class="btn btn-outline-secondary wackadoo-grid-search-control"
            (click)="clearQuery()"
            type="button"
            [disabled]="disabled"
        ><span class="fas fa-times"></span>
        </button>
    </div>
</div>
