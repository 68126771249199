import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { UserInterfaceService } from '../../../../services/user-interface.service';
import { Subscription, Subject } from 'rxjs';
import { ScreenType } from '../../../../services/screen-type.enum';

@Component({
  selector: 'wackadoo-page-control',
  templateUrl: './page-control.component.html',

})
export class PageControlComponent implements OnInit, OnDestroy, OnChanges {

  @Input() parameters: any;
  @Input() reloadGridSubject: Subject<void>;
  @Input() disabled = false;

  startAt = 0;
  endAt = 0;
  hits = 0;
  pageSize = 0;

  private _pageEventSubscription: Subscription;
  private _screenTypeSubscription: Subscription;

  shortPage = 10;
  mediumPage = 25;
  longPage = 50;

  screenType: ScreenType;
  screenSize: number;

  constructor(
    public userInterfaceService: UserInterfaceService,
  ) { }

  ngOnInit(): void {
    // console.log('page-control.ngOnInit()', this.parameters);

    this.screenType = this.userInterfaceService.screenType.getValue();
    this.screenSize = window.outerWidth;

    this.parseOutPageParameters();

    // this._pageEventSubscription = this._userInterfaceService.pageEvent.subscribe(
    //   (event: WEvent) => {
    //     if (event) {
    //       // console.log('page-control._pageEventSubscription() - Got event!', event);
    //       // we have to do this off the incoming event because we do NOT know if "this" has been re-loaded or not...
    //       this.parseOutPageParameters(event.parameters);
    //     }
    //   }
    // );

    this._screenTypeSubscription = this.userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        this.screenType = screenType;
        this.screenSize = window.outerWidth;
      }
    );

    /*
      // This is where we would integrate the page size business rule...

      if((wackadoo._BusinessRules !== null) && (wackadoo._BusinessRules.getDefaultPageSize(this.eventhandler) !== null)) {

        basePageSize = wackadoo._BusinessRules.getDefaultPageSize(this.eventhandler);

        if(basePageSize < 100) {
          secondPageSize = basePageSize*2;
          thirdPageSize = basePageSize*3;
        } else {
          secondPageSize = null;
          thirdPageSize = null;
        }
      }
    */
  }

  ngOnDestroy(): void {
    if (this._pageEventSubscription) {
      this._pageEventSubscription.unsubscribe();
    }

    if (this._screenTypeSubscription) {
      this._screenTypeSubscription.unsubscribe();
    }
  }

  ngOnChanges(): void {
    this.parseOutPageParameters();
  }

  parseOutPageParameters(): void {
    // console.log('page-control.parseOutPageParameters()', this.parameters);

    if (this.parameters) {
      this.hits = this.parameters.hits;
      this.startAt = this.parameters.startAt;
      this.pageSize = this.parameters.pageSize;
      const regularEnd = this.startAt + this.pageSize - 1;
      this.endAt = (this.hits < regularEnd ? this.hits : regularEnd);
    } else {
      this.hits = 0;
      this.startAt = 0;
      this.endAt = 0;
    }

    if (!this.hits || (this.hits === 0)) {
      this.hits = 0;
      this.startAt = 0;
      this.endAt = 0;
    } else if (this.hits < this.startAt) {
      this.firstPage();
    }
    // console.log('PageControl.parseOutPageParameters()', this.parameters, this.startAt, this.endAt, this.hits);
  }

  setPageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.parameters.pageSize = pageSize;
    this.parseOutPageParameters();
    this.reloadGridSubject.next();
  }

  firstPage(): void {
    this.parameters.startAt = 1;
    this.parseOutPageParameters();
    this.reloadGridSubject.next();
  }

  pageUp(): void {
    // Do the math on the results to determine the next value of startAt...
    if (this.startAt > 1) {
      this.parameters.startAt = ((this.startAt > this.pageSize) ? this.startAt - this.pageSize : 1);
      this.parseOutPageParameters();
      this.reloadGridSubject.next();
    }
  }

  pageDown(): void {
    // Do the math on the results to determine the next value of startAt...
    if (this.hits >= (this.startAt + this.pageSize)) {
      this.parameters.startAt = this.startAt + this.pageSize;
      this.parseOutPageParameters();
      this.reloadGridSubject.next();
    }
  }

  lastPage(): void {
    // Do the math on the results to determine the next value of startAt...
    if (this.hits >= (this.startAt + this.pageSize)) {
      this.parameters.startAt = this.hits - this.pageSize + 1;
      this.parseOutPageParameters();
      this.reloadGridSubject.next();
    }
  }

}
