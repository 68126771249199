import { Component, OnInit, Input } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { WEvent } from 'src/app/client-core/data/event.model';
import { Subject } from 'rxjs';
import { WResource } from 'src/app/client-core/data/resource.model';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';

@Component({
  selector: 'wackadoo-price-list-item-detail',
  templateUrl: './price-list-item-detail.component.html',
})
export class PriceListItemDetailComponent extends ResourceComponent implements OnInit {

  @Input() shoppingCartID = -1;
  @Input() shoppingCartChangedSubject: Subject<void> = null;

  constructor(
    userInterfaceService: UserInterfaceService,
    eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  addItemToShoppingCart(priceListItem: WResource): void {

    this.modalDialogService.showPleaseWait(true, true);

    // console.log('addItemToShoppingCart()', r);

    const eventHandler = 'ShoppingCartItems';
    const action = 'add';
    const parms: any = priceListItem.asParms;
    parms.shoppingCartID = this.shoppingCartID;
    parms.itemQuantity = 1;
    parms.itemExtendedPrice = priceListItem.itemPrice.value;

    // and because we DON'T want the created/modified/agent info from the PriceListItem to be saved in the ShoppingCartItem...
    delete parms.created;
    delete parms.modified;
    delete parms.agent;

    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (result: WEvent) => {
        // console.log('addItemToShoppingCart()', event);
        try {
          if (result.status !== 'OK') {
            throw new Error(result.message);
          }

          const shoppingCartItemID = result.parameters.shoppingCartItemID;

          if (priceListItem.resourceType.value === 'Package') {
            this.loadPackageItems(priceListItem.keyField.value, shoppingCartItemID);
          } else if (this.shoppingCartChangedSubject) {
            this.shoppingCartChangedSubject.next();
          }

        } catch (ex) {
          const msg = 'addItemToShoppingCart()\n';
          this.userInterfaceService.alertUserToException(ex, msg);
        }
      }
    );
  }

  loadPackageItems(packagePriceListItemID: number, shoppingCartItemID: number): void {
    const eh = 'PriceListPackageLinks';
    const parms = { priceListItemID : packagePriceListItemID };

    this.eventServerService.fireEvent(eh, 'list', parms).subscribe(
      (event: WEvent) => {
        try {
          if (event.status !== 'OK') {
            throw new Error(event.message);
          }

          const packageItemLinks = event.resources;

          for (const packageItemLink of packageItemLinks) {
            this.addPackageItemToShoppingCart(shoppingCartItemID, packageItemLink);
          }

        } catch (ex) {
          const msg = 'loadPackageItems() - Unable to list price list item package links.';
          this.userInterfaceService.alertUserToException(ex, msg);
        }

      }
    );

  }

  addPackageItemToShoppingCart(shoppingCartItemID: number, packageItemLink: WResource): void {

    // console.log('addPackageItemToShoppingCart()', packageItemLink);

    this.eventServerService.loadResourceFromServer('PriceList', { priceListItemID : packageItemLink.priceListItemChildID.value }, false).subscribe(
      (packagePriceListItem: WResource) => {
        // console.log('addPackageItemToShoppingCart()', packagePriceListItem);
        try {
          if (packagePriceListItem) {

            // this controls if we show "N 1 count items" or "1 N count item" in the shopping cart
            const showMultipleItemsOfQuantity1 = packageItemLink.priceListItemChildShowIndividualItems.value;

            // console.log('PriceListItemDetail.addPackageItemToShoppingCart()', loopOverSubPackageItems, packagePriceListItem, packageItemLink);

            for (let i = 1; i <= packageItemLink.priceListItemChildCount.value; i++) {

              if (packagePriceListItem.resourceType.value === 'Package') {
                this.loadPackageItems(packagePriceListItem.keyField.value, shoppingCartItemID);
              } else {

                const eventHandler = 'ShoppingCartItems';
                const action = 'add';
                const parms: any = packagePriceListItem.asParms;
                parms.shoppingCartID = this.shoppingCartID;
                parms.itemQuantity = (showMultipleItemsOfQuantity1 ? 1 : packageItemLink.priceListItemChildCount.value);
                parms.quantityAdjustable = false;
                parms.itemPrice = 0;
                parms.itemExtendedPrice = 0;

                // non-NULL packageID tells EVERYbody that this ShoppingCartItem is part of a package, so
                // (if PLI is fixedInventory) it does NOT count against inventory levels, and
                // it does NOT appear in the ShoppingCart...
                // but it DOES get "post-processed" in the web hook.
                parms.packageID = shoppingCartItemID;

                this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
                  (event: WEvent) => {
                    try {

                      // console.log('addPackageItemToShoppingCart()', eventHandler, action, parms, event);

                      if (event.status !== 'OK') {
                        throw new Error(event.message);
                      }

                      if (this.shoppingCartChangedSubject) {
                        this.shoppingCartChangedSubject.next();
                      }

                    } catch (ex) {
                      const msg = 'addPackageItemToShoppingCart.add()\n';
                      this.userInterfaceService.alertUserToException(ex, msg);
                    }
                  }
                );

              }

              if (!showMultipleItemsOfQuantity1) {
                break;
              }

            }
          }
        } catch (ex) {
          const msg = 'addPackageItemToShoppingCart.loadResource()\n';
          this.userInterfaceService.alertUserToException(ex, msg);
        }
      }
    );
  }

}
