import { Component, OnInit } from '@angular/core';
import { AdministrationComponent } from '../administration/administration.component';
import { WForeignKey } from 'src/app/client-core/data/field.model';

@Component({
  selector: 'wackadoo-event-broadcaster',
  templateUrl: './event-broadcaster.component.html',
})
export class EventBroadcasterComponent extends AdministrationComponent implements OnInit {

  appID: WForeignKey = null;

  ngOnInit(): void {
    const temp = this.eventServerService.newResource('RegisteredApplications');
    // console.log(temp);

    this.appID = temp.appID.clone;
    // console.log(this.appID);
  }

  fireEvent(eventHandler: string, action: string): void {
    const parms: any = {};
    if (this.appID.isPopulated) {
      parms.appName =  this.appID.displayValue.substring(this.appID.displayValue.indexOf(' ')).trim();
    }
    // console.log(eventHandler, action, parms);
    super.fireEvent(eventHandler, action, parms);
  }

}
