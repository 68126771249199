import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { EventServerService } from '../../../services/event-server.service';
import { Subscription } from 'rxjs';
import { ScreenType } from '../../../services/screen-type.enum';
import { WEvent } from '../../../data/event.model';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { FieldMode } from '../../../data/field/field-mode.model';
import { User } from 'src/app/client-core/data/user.model';
import { BusinessRuleService } from 'src/app/client-core/services/business-rule.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';

@Component({
  selector: 'wackadoo-grid-button-bar',
  templateUrl: './grid-button-bar.component.html',

})
export class GridButtonBarComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'GridButtonBarComponent';

  @Input() eventHandler: string;
  @Input() parameters: any;
  @Input() user: User;
  @Input() repositoryPageElementRef: ElementRef;

  screenType: ScreenType;
  screenSize: number;
  private _screenTypeSubscription: Subscription;

  methods: string [] = [];

  private _pageEventSubscription: Subscription;

  hasImportTemplates = false;
  importTemplateNames: string [] = [];
  importTemplateFileExtensions: string [] = [];
  assignedToRelatedEventHandler = null;

  constructor(
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    public businessRuleService: BusinessRuleService,
    public utilsLibService: UtilityLibService,
  ) {
  }

  ngOnInit(): void {
    // console.log('grid-button-bar.ngOnInit() = this.eventHandler: ' + this.eventHandler);
    this.methods = this.eventServerService.getMethods(this.eventHandler);
    // console.log('eh: ' + this.eventHandler, this.methods);

    this.screenType = this.userInterfaceService.screenType.getValue();
    this.screenSize = window.outerWidth;

    this._screenTypeSubscription = this.userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        this.screenType = screenType;
        this.screenSize = window.outerWidth;
      }
    );

    this._pageEventSubscription = this.userInterfaceService.pageEvent.subscribe(
      (event: WEvent) => {
        if (event) {
          this.methods = this.eventServerService.getMethods(event.firstHandler);
          // console.log('eh2: ' + event.firstHandler, this.methods);
        }
      }
    );

    this.resetImportTemplates();
  }

  resetImportTemplates(): void {

    this.importTemplateNames = [];
    this.importTemplateFileExtensions = [];

    const mightHaveImportTemplates = (this.businessRuleService.businessRules.importFormatRules.targetEventHandler === this.eventHandler);
    this.assignedToRelatedEventHandler = this.businessRuleService.businessRules.importFormatRules.assignedToRelatedEventHandler;
    const importTemplateEventHandler = this.eventServerService.getEventHandlerForResourceType('TextImportFormat');

    this.hasImportTemplates = (mightHaveImportTemplates && (importTemplateEventHandler !== null));

    // console.log('resetImportTemplates()', mightHaveImportTemplates, importTemplateEventHandler, this.hasImportTemplates, this.importTemplateNames);

    if (this.hasImportTemplates) {
      // go get the names of all the import templates...
      this.eventServerService.fireEvent(importTemplateEventHandler, 'list', {pageSize: -1}).subscribe(
        (event: WEvent) => {
          // console.log('resetImportTemplates()', event)
          if (event.status !== 'OK') {
            this.modalDialogService.showAlert('Unable to list import templates...', 'Warning').subscribe(
              () => {
                this.hasImportTemplates = false;
              }
            );
          } else {
            if (event.resources) {
              for (const r of event.resources) {
                // console.log('resetImportTemplates()', r);
                this.importTemplateNames.push(r.tifName.value);
                this.importTemplateFileExtensions.push(r.tifType.value === 'comma-separated' ? 'csv' : 'txt');
              }
            }
            if (this.importTemplateNames.length === 0) {
              this.hasImportTemplates = false;
            }
          }
          // console.log('resetImportTemplates()', this.hasImportTemplates, this.importTemplateNames, this.importTemplateFileExtensions);
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this._screenTypeSubscription) {
      this._screenTypeSubscription.unsubscribe();
    }

    if (this._pageEventSubscription) {
      this._pageEventSubscription.unsubscribe();
    }
  }

  add(): void {
    // generate a new, blank resource

    // The detail page requires that ALL possible fields be present in the selected
    // resource - even if the fields all have null values - including for add().
    // We cannot use "new Resource()" because that creates a field-less Resource.
    // We need to use Resource.factory(), which assigns null to all the defined
    // fields in the user's API - and ESSvc.newResource() is a convenience
    // function that handles that.

    const blankResource = this.eventServerService.newResource(this.eventHandler);

    // Note that we want to disable the user's ability to enter a value in the ID field
    // for this new resource, because that will be generated on the server...
    blankResource.keyField.readOnly = true;
    // but we need a null value here so we can have something sensible in the currentSelection div...
    blankResource.keyField.value = null;
    blankResource.keyField.displayValue = 'new, unsaved ' + this.eventServerService.getResourceTypeForEventHandler(this.eventHandler);

    // set the detail page to edit mode
    this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.write);
    // select the new, blank resource
    this.userInterfaceService.selectResource(this.eventHandler, blankResource);
  }

  clear(): void {

      let relatedItems =  '';

      this.eventServerService.fireEvent(this.eventHandler, 'describeOnDeleteCascade', {}).subscribe(
        (tempE: WEvent) => {

          if (tempE.status === 'OK') {
            const description = tempE.getParameter('description');
            if (description && (description !== '')) {
              relatedItems = '\n\nThis will ALSO delete ALL RELATED items of the following types:\n\n';
              relatedItems += description;
              relatedItems += '\n\nwhich may be FAR MORE DESTRUCTIVE than you intended!\n';
            }
          }

          const msg = 'You are about to CLEAR (read: "COMPLETELY ERASE"...) every resource on this page!\n\nAre you sure?' + relatedItems;

          this.modalDialogService.showConfirm(msg, 'WARNING!').subscribe(
            (ok: boolean) => {
              if (ok) {
                this.modalDialogService.showPleaseWait('Clearing all resources on this page...' + relatedItems);

                this.eventServerService.fireEvent(this.eventHandler, 'delete', {}).subscribe(
                  (event: WEvent) => {
                    this.modalDialogService.showPleaseWait(false);
                    // console.log(event.firstHandler + '.clear(): ' + event.status + '\nmsg: ' + event.message);
                    if ((event !== null) && (event.status === 'OK')) {
                      this.userInterfaceService.unSelectResource(event.firstHandler, true);
                      // this.userInterfaceService.loadPage(event.firstHandler);
                    } else {
                      // console.log(event);
                      this.modalDialogService.showAlert('There was a problem clearing all the resources: ' + event.message, 'WARNING');
                    }
                  }
                );
              }
            }
          );
        }
      );

  }

  export(): void {
    let parms: any = {}; // this.parameters;

    parms = this.userInterfaceService.applyCurrentSelectionsToParms(parms, this.eventHandler);

    // the default export format is a tab-delimited text file, which is the same as the bulkImport format...
    parms.fileExtension = 'txt';
    // but if we wanted to make the default export format to be a spreadsheet...
    // parms.fileExtension = 'xlsx';

    this.eventServerService.downloadFile(this.eventHandler, 'export', parms);
  }

  search(): void {
    this.modalDialogService.showModalSearch(this.eventHandler).subscribe(
      (query: boolean|string) => {
        // if it's a boolean, we know it will be 'false', which happens on "cancel"...
        if (typeof query === 'boolean') {
          delete this.parameters.query;
        // else, mimic the search-box search() method...
        } else if (!query || (query === '') || (query === '*')) {
          delete this.parameters.query;
        } else {
          this.parameters.query = this.utilsLibService.wrapSimplePhraseQueriesInQuotes(query);
        }
        this.userInterfaceService.setGridState(this.eventHandler, this.parameters);
        this.userInterfaceService.loadPage(this.eventHandler, 'search', this.parameters);
      }
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  // These methods are instrumentation for testing/debugging EventServerService.fireEvent() errors
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  // forceHttpError(): void {
  //   // this throws a 404 error...
  //   this.modalDialogService.showPleaseWait(true);
  //   this.eventServerService.fireEvent('DataArchives', 'list', {}).subscribe(
  //     (event: WEvent) => {
  //       this.modalDialogService.showPleaseWait(false);
  //       console.log('OOPS! Firing that event was supposed to generate an HTTP error...', event);
  //     }
  //   );
  // }

  // forceBadEHError(): void {
  //   // this fires a bad method error...
  //   this.modalDialogService.showPleaseWait(true);
  //   this.eventServerService.fireEvent('Rutabagas', 'list', {}).subscribe(
  //     (event: WEvent) => {
  //       this.modalDialogService.showPleaseWait(false);
  //       console.log('OOPS! Firing that event was supposed to generate an error...', event);
  //     }
  //   );
  // }

  // forceBadMethodError(): void {
  //   // this fires a bad method error...
  //   this.modalDialogService.showPleaseWait(true);
  //   this.eventServerService.fireEvent('DataArchives', 'rutabaga', {}).subscribe(
  //     (event: WEvent) => {
  //       this.modalDialogService.showPleaseWait(false);
  //       console.log('OOPS! Firing that event was supposed to generate an error...', event);
  //     }
  //   );
  // }

}
