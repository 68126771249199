import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef, AfterViewInit } from '@angular/core';
import { WResource } from '../../data/resource.model';
import { Subject, Observable, OperatorFunction, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ModalDialogService } from '../../services/modal-dialog.service';
import { EventServerService } from '../../services/event-server.service';
import { WEvent } from '../../data/event.model';

@Component({
  selector: 'wackadoo-type-ahead',
  templateUrl: './type-ahead.component.html',
})
export class TypeAheadComponent implements OnInit, OnChanges, AfterViewInit {

  // for more on ngbTypeahead, see https://ng-bootstrap.github.io/#/components/typeahead/examples

  @Input() name: string = null;
  @Input() eventHandler: string = null;
  @Input() fieldsToSearch: string [] = [];
  @Input() selectResourceSubject: Subject<WResource>;
  @Input() placeholder = 'Search for items to select';
  @Input() changed = false;
  @Input() keyStrokeDelay = 500;
  @Input() minCharsToSearch = 2;
  @Input() disabled = false;
  @Input() filterParms: any = {};

  // this ONLY handles display part of the process.
  // it does NOT trigger the "selectResourceSubject" because this resource is presumed to have already been "selected".
  @Input() externalSelection: WResource = null;

  searching = false;
  searchFailed = false;

  public selectedResource: WResource = null;

  inputDisabled = false;

  constructor(
    public modalDialogService: ModalDialogService,
    public eventServerService: EventServerService,
    public elementRef: ElementRef,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(sc: SimpleChanges): void {
    // console.log('ngOnChanges()', sc);

    if (sc.externalSelection) {
      if (sc.externalSelection.currentValue && sc.externalSelection.currentValue[this.name].isPopulated) {
        this.elementRef.nativeElement.querySelector('input[type="text"]').value = this.formatResource(sc.externalSelection.currentValue);
        this.selectedResource = sc.externalSelection.currentValue;
        this.inputDisabled = true;
      } else {
        this.elementRef.nativeElement.querySelector('input[type="text"]').value = '';
        this.selectedResource = null;
        this.inputDisabled = false;
      }
    }

    if (sc.minCharsToSearch) {
      this.minCharsToSearch = (sc.minCharsToSearch.currentValue > 0 ? sc.minCharsToSearch.currentValue : 1 );
    }
  }

  ngAfterViewInit(): void {
    // the 3rd party ngTypeahead component does not keep the "name" attribute, which we need for auto-focus
    const selector = 'input';
    const element: HTMLElement = this.elementRef.nativeElement.querySelector(selector) as HTMLElement;
    if (element && this.name) {
      element.setAttribute('name', this.name);
    }
  }

  onBlur(): void {
    // console.log('typeahead.onBlur()', typeof this.selectedResource, this.selectedResource);
    if (typeof this.selectedResource === 'string') {
      this.clearSelection();
    }
  }

  formatResource(r: WResource): string {
    return (r ? r.keyField.displayValue : '(null resource)');
  }

  clearSelection(): void {
    // console.log('typeahead.clearSelection()');
    this.selectedResource = null;
    this.inputDisabled = false;
    this.selectResourceSubject.next(null);
  }

  selectResource(r: WResource): void {
    // console.log('typeahead.selectResource()', typeof r, r);
    this.selectedResource = r;
    this.inputDisabled = true;
    this.selectResourceSubject.next(this.selectedResource);
  }

  search: OperatorFunction<string, readonly any []> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(this.keyStrokeDelay),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((term) => {
        if (term.trim().length >= this.minCharsToSearch) {
          const parms: any = this.filterParms;
          parms.query = this.buildQuery(term);

          // console.log(parms);

          return this.eventServerService.fireEvent(this.eventHandler, 'search', parms).pipe(
            tap(() => {
                this.searchFailed = false;
              })
            , catchError(() => {
                this.searchFailed = true;
                return of([]);
              })
            , map((event: WEvent) => {
                if (event && (event.status === 'OK')) {
                  const resources = event.resources;
                  return resources;
                } else {
                  this.searchFailed = true;
                  return [];
                }
              })
          );
        } else {
          return [];
        }
      }),
      tap(() => this.searching = false)
    )

  buildQuery(value: string): string {

    let query = '(';

    const tokens = value.trim().split(' ');

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];
      if (this.fieldsToSearch && (this.fieldsToSearch.length > 0)) {

        query += (i > 0 ? ' OR ' : '') + this.buildQueryTerm(this.fieldsToSearch[0], token, 4.5, 4.5);

        if (this.fieldsToSearch.length > 1) {
          query += ' OR ' + this.buildQueryTerm(this.fieldsToSearch[1], token, 2, 2);
        }
        if (this.fieldsToSearch.length > 2) {
          for (let j = 2; j < this.fieldsToSearch.length; j++) {
            query += ' OR ' + this.buildQueryTerm(this.fieldsToSearch[i], token, 1, 1);
          }
        }
      } else {
        query += (i > 0 ? ' OR ' : '') + this.buildQueryTerm(null, token, 4.5, 4.5);
      }
    }

    query += ')';

    // console.log('buildQuery()', value, tokens, this.fieldsToSearch, query);

    return query;
  }

  buildQueryTerm(fieldName: string, value: string, exactMatchWeight: number, wildCardWeight: number): string {

    const fieldToSearch = (fieldName ? fieldName + ':' : '');

    // we include some field boosting to prioritize last name accuracy...

    let queryTerm = '';

    value = value.toLowerCase().trim();
    const firstLetter = value.substring(0, 1);

    if (value.indexOf(' ') >= 0) {
      queryTerm += '(' + fieldToSearch + '"' + value + '")^' + exactMatchWeight;
    } else {
      queryTerm += '(' + fieldToSearch + value + ')^' + exactMatchWeight;
      if (!value.endsWith('*')) {
        queryTerm += ' OR (' + fieldToSearch + value + '*)^' + wildCardWeight;
      }
    }
    // Took this out since it was returning "too many" results that were confusing the user(s)...
    // queryTerm += ' OR (' + fieldToSearch + firstLetter + '*)';

    // console.log('buildQueryTerm()', queryTerm);

    return(queryTerm);
  }

}
