<div class="d-flex justify-content-around">
  <div class="card" [ngClass]="{
      extraLarge:(screenType === 'fullscreen')
      , smallish:(screenType === 'phone')
    }">
    <div class="card-header">
      Price List
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-between" *ngFor="let app of applications; let idx = index; ">
        <div class="link" (click)="goToAppHomePage(app.appName.value)">
          <span class="fas fa-{{ app.appIcon.value}}"></span>
          <wackadoo-field [f]="app.appName" mode="read"></wackadoo-field>
          <span class="italic" *ngIf="app.status.value !== 'released'">({{app.status.value}})</span>
        </div>
        <div class="marginLeftWholeEm d-flex justify-content-between">
          <div class="marginLeftWholeEm">
            <wackadoo-field [f]="app.price" mode="read"></wackadoo-field>
            ({{app.priceModel.value}})
          </div>
          <div class="marginLeftWholeEm" *ngIf="user && user.isPublicOnly">
            <wackadoo-sign-up-button [appName]="app.appName.value"></wackadoo-sign-up-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
