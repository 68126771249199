import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UserInterfaceService } from '../../../../services/user-interface.service';
import { WEvent } from '../../../../data/event.model';
import { Subscription, Subject } from 'rxjs';
import { EventServerService } from '../../../../services/event-server.service';

@Component({
  selector: 'wackadoo-sort-control',
  templateUrl: './sort-control.component.html',

})
export class SortControlComponent implements OnInit, OnDestroy {

  @Input() eventHandler: string;
  @Input() parameters: any;
  @Input() reloadGridSubject: Subject<void>;
  @Input() disabled = false;

  sortBy: string;
  sortDirection: number;
  fieldAPIs: any;

  private _pageEventSubscription: Subscription;

  constructor(
    private _userInterfaceService: UserInterfaceService,
    private _eventServerService: EventServerService,
  ) { }

  ngOnInit(): void {
    this.fieldAPIs = this._eventServerService.getFieldDefinitionsInOrder(this.eventHandler);

    // console.log(this.resources);
    this.sortBy = (this.parameters.sortBy ? this.parameters.sortBy : (this.fieldAPIs.find((fapi) => fapi.defaultSort === true).name));
    this.sortDirection = (this.parameters.sortDirection ? this.parameters.sortDirection : (this.fieldAPIs.find((fapi) => fapi.defaultSort === true).defaultSortDirection))

    this._pageEventSubscription = this._userInterfaceService.pageEvent.subscribe(
      (event: WEvent) => {
        if (event) {
          // console.log('sort-control.pageEvent.subscribe() - Got event!', event);
          // we have to do this off the incoming event because we do NOT know if "this" has been re-loaded or not...
          this.sortBy = event.parameters.sortBy;
          this.sortDirection = event.parameters.sortDirection;
          // this.fieldAPIs = this._eventServerService.getFieldDefinitionsInOrder(event.firstHandler);
        }
      }
    );
    // console.log('SortBy.ngOnInit() - exit', this.eventHandler, this.parameters, this.sortBy, this.sortDirection, this.fieldAPIs);
  }

  ngOnDestroy(): void {
    if (this._pageEventSubscription) {
      this._pageEventSubscription.unsubscribe();
    }
  }

  setSortBy(fieldName: string): void {
    this.sortBy = fieldName;
    this.parameters.sortBy = fieldName;
    this.reloadGridSubject.next();
  }

  toggleSort(): void {
    this.sortDirection = -1 * this.sortDirection;
    this.parameters.sortDirection = this.sortDirection;
    this.reloadGridSubject.next();
  }

}
