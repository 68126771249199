<div class="">
  <ngb-carousel #carousel interval="3000">
    <ng-template ngbSlide *ngFor="let img of images; let idx = index">
      <div class="carousel-caption max-height-10-em">
        <div class="right black staticCarouselText" [ngClass]="{marginBottomWholeEm:(screenType === 'phone')}">
          <div class="marginTopHalfEm" [ngClass]="{ smallish : smallVersion }">
            Welcome to
          </div>
          <h2 class="marginBottom0 green">
            {{ appName }}
          </h2>
          <div class="italic marginBottomHalfEm"  [ngClass]="{ large : !smallVersion }">
            by {{ domain.startsWith('wackadoo') ? domain : 'wackadoo.info' }}
          </div>
          <wackadoo-sign-up-button [appName]="appName"></wackadoo-sign-up-button>
        </div>
      </div>
      <div class="faded-image image{{idx}}" [ngClass]="{ 'max-height-10-em' : smallVersion }">
        <!-- 
          We don't put the url in here. Instead, we set background-image on the	
          imageX class (using code...) in order to implement a CSS fade effect...
        -->
        <img class="" src=" " alt="" />
      </div>
    </ng-template>
  </ngb-carousel>
</div>
