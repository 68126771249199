import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { UserInterfaceService } from '../../../../services/user-interface.service';
import { WEvent } from '../../../../data/event.model';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';

@Component({
  selector: 'wackadoo-search-box',
  templateUrl: './search-box.component.html',

})
export class SearchBoxComponent implements OnInit, OnDestroy {

  eventHandlerSupportsSearch = true;

  @Input() eventHandler: string;
  @Input() parameters: any;
  @Input() reloadGridSubject: Subject<void>;
  @Input() disabled = false;

  query: string = null;

  private _pageEventSubscription: Subscription;

  inputTimer: any = null;

  @HostListener('keydown', ['$event']) drop = this.keyDownListener;

  constructor(
    private _userInterfaceService: UserInterfaceService,
    private _eventServerService: EventServerService,
    private _utilsLib: UtilityLibService,
  ) { }

  ngOnInit(): void {

    this.eventHandlerSupportsSearch = this._eventServerService.getMethods(this.eventHandler).includes('search');

    this.query = (this.parameters && this.parameters.query ? this.parameters.query : '');

    this._pageEventSubscription = this._userInterfaceService.pageEvent.subscribe(
      (event: WEvent) => {
        if (event) {
          // console.log('search-box.pageEvent.subscribe() - Got event!', event);
          // we have to do this off the incoming event because we do NOT know if "this" has been re-loaded or not...
          this.query = (event.parameters.query ? event.parameters.query : '');
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this._pageEventSubscription) {
      this._pageEventSubscription.unsubscribe();
    }
  }

  clearQuery(): void {
    this.query = null;
    this.search();
  }

  search(): void {
    if ((!this.query) || (this.query === '') || (this.query === '*')) {
      delete this.parameters.query;
    } else {
      this.parameters.query = this._utilsLib.wrapSimplePhraseQueriesInQuotes(this.query);
    }
    delete this.parameters.startAt; // or set it to 1...
    this.reloadGridSubject.next();
  }

  keyDownListener(e: any): void {

    const keyCode = e.keyCode;

    // 13 is "enter" key

    if (keyCode === 13) {
      clearTimeout(this.inputTimer);
      this.inputTimer = null;
      this.search();
    } else {
      if (this.inputTimer !== null) {
        clearTimeout(this.inputTimer);
        this.inputTimer = null;
      }

      this.inputTimer = setTimeout(
        () => {
          this.executeAutoQuery();
        },
        1000
      );
    }

  }

  executeAutoQuery(): void {

    // console.log('checking target.value: ' + target.value);
    try {
      if (this.query) {
        // must be two chars or more, only alpha chars
        if (this.query.match(/^[a-zA-Z]{2,}[\*]{0,1}$/)) {

          let tempQuery = this.query.toLowerCase().trim();
          tempQuery += (tempQuery.endsWith('*') ? '' : '*');

          // Now go fire the search by writing those values into the resourceblock parms and reloading the grid...
          this.query = tempQuery;
          this.search();
        }
      }
    } catch (ex) {
      const msg = 'SearchBox.executeAutoQuery()\n';
      this._userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
