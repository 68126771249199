import { Component, OnInit } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';

@Component({
  selector: 'wackadoo-price-list-detail-content',
  templateUrl: './price-list-detail-content.component.html',
})
export class PriceListDetailContentComponent extends DetailContentComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'PriceListDetailContentComponent';

  ngOnInit(): void {
    super.ngOnInit();
  }

}
