		<div class="card">
			<div class="card-header">
				Pricing Policy...
			</div>
			<div class="card-body">
				<p>
					We provide access to software applications under three different price models:
				</p>
				<p>
					"per month"
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						Web app subscriptions, billed on a monthly basis.
					</li>
				</ul>
				<p>
				"fixed price"
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						Downloadable products, for which there is a one-time fee charged 
						to your credit card.
					</li>
					<li>
						Examples include, a specialized business spreadsheet, training videos or 
						an executable program that you download, install, and run on your own computer.
					</li>
				</ul>
				<p>
					"per event"
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						Web app access for which there is a one-time fee charged to your credit card. They are:
						<ul>
							<li>
								provided for a defined number of events (usually one...), 
							</li>
							<li>
								valid for a fixed period of time (usually one year...), 
							</li>
						</ul>
					</li>
					<li>
						An example of this would be an annual fund-raising golf scramble put on by a charitable organization.
					</li>
				</ul>
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				Our attitude about pricing...
			</div>
			<div class="card-body">
				<p>
					One of the most beautiful, balanced, and peaceful words in the English language is "enough". When you subscribe to one of our applications, 
					you get that whole application - including any new features that might get added to it in the future - because you are already paying "enough". 
				</p>
				<p>
					And because we know your budget, like ours, is tight, we will do our best to logically group features into applications so that you are only 
					paying for what you need.
				</p>
				<p>
					Personally, we think tiered pricing is for the birds. And so far, we think we can avoid it, whether based on features, data volume 
					or the number of users. The realities of business force us to acknowledge that we may have 
					to eat crow on this in the future, but we're going to fight hard to avoid tiered pricing for as long as we can. 
					We think that if we have designed our business model correctly, we shouldn't need to up-sell you to extract the marginal 
					dollar on "premium" features.
				</p>
				<p>
					The most likely causes for us to implement tiered pricing in the future seem to be:
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						New features that have additional volume-based costs for us with an external vendor.
					</li>
					<li>
						Enterprise level customers with data volumes or user bases large enough to force us to make operational accommodations.
					</li>
					<li>
						Some type of per-user based pricing, rather than per-account.
					</li>
				</ul>
				<p>
					As for downloaded products - we'll play that by ear, but our general rule of thumb is that incremental, point releases are 
					included in the original purchase, but major releases are not.
				</p>
			</div>
		</div>

