export class SystemMessageContent {
  message = '';
  title = '';
  timestamp = '';

  constructor(message: string, title?: string, timestamp?: string) {
    this.message = message;
    this.title = (title ? title : '');
    this.timestamp = (timestamp ? timestamp : '');
  }
}

