import { Component } from '@angular/core';
import { Globals } from '../../../services/global.service';

@Component({
  selector: 'wackadoo-about-mission',
  templateUrl: './about-mission.component.html',

})
export class AboutMissionComponent {

  domain = Globals.domain;

  constructor() { }

}
