<span *ngIf="f">
    <span *ngIf="mode === 'read'">
        ********
    </span>
    <span *ngIf="mode === 'write'">
        <input 
            class="form-control width-unset shrinkToFitWidth"
            type="password" 
            [ngClass]="{'fieldChanged': f.changed}"
            [placeholder]="(f.required ? '* ' : '') + f.name"
            [name]="f.name"
            [title]="(title ? title : f.name)"
            [size]="(f.length && (f.length < size) ? f.length : size)"
            [value]="f.value" 
            [required]="f.required"
            [pattern]="f.pattern"
            [maxLength]="f.length"
            [disabled]="f.readOnly"
            (input)="onInputChange($event)"
            (blur)="forceValidValue($event)"
        />
    </span>
</span>
