<span *ngIf="f">
    <div *ngIf="mode === 'read'">
        <span *ngIf="url && (url.length > 0) ; else noImage">
            <img src="{{ url }}" alt="{{ f.name }}" class="{{displayClass}}"
                wackadooImageLoaded (loaded)="calculateImageStats()"
            />
        </span>
        <ng-template #noImage>
            (no image url for {{ f.name }})
        </ng-template>
    </div>
    <div *ngIf="mode === 'write'">
        <div class="d-flex justify-content-between">
            <div *ngIf="f.changed">
                <div class="fieldChanged">
                    New Image File Is Now Cached...
                </div>
                <div class="italic marginTopHalfEm">
                    (It will be uploaded when you click "Save"...)
                </div>
                <div class="marginTopHalfEm">
                    <span class="bold">
                        {{ f.fileName }}
                    </span>
                    ({{ f.fileSize }} bytes)
                </div>
                <div>
                    {{ f.fileType }}
                </div>
                <div>
                    File Last Modified: {{ f.fileDate.toLocaleString() }}
                </div>
            </div>
            <div *ngIf="!f.changed">
                <div class="border round-corners paddingHalfEm">
                    <button 
                        class="btn btn-secondary"

                        wackadooFileRead
                        [newFileContent]="loadFileContent"
                        [validExtensions]="validExtensions"
                        [maxFileSizeMB]="f.maxFileSizeMB"
                    >
                        Upload New Image File
                    </button>
                    <div class="marginTopHalfEm">
                        (click button, or drop a {{formattedValidExtensions}} image file here...) 
                    </div>
                </div>
            </div>
            <div *ngIf="f.isPopulated">
                <button type="button" class="btn btn-secondary" title="Clear Image File Contents" (click)="clearBinaryContent()" ><span class="fas fa-trash-alt"></span> Clear Image</button>
            </div>
        </div>
    </div>
</span>
