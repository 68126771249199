<div class="d-flex">
  <div class="nowrap marginRightHalfEm">
    <wackadoo-field [f]="resource.firstName" [mode]="mode"></wackadoo-field> <wackadoo-field [f]="resource.lastName" [mode]="mode"></wackadoo-field>
  </div>
  <div class="marginRightHalfEm">
    <wackadoo-field [f]="resource.userName" [mode]="mode"></wackadoo-field>
  </div>
  <div class="nowrap marginRightHalfEm">
    has the role of
  </div>
  <div class="marginRightHalfEm">
    <wackadoo-field [f]="resource.role" [mode]="mode"></wackadoo-field>
  </div>
  <div class="marginRightHalfEm">
    in
  </div>
  <div class="marginRightHalfEm">
      {{ resource.appName.value }}
  </div>
  <ng-container *ngIf="user.isStaffOrAbove">
    <div class="marginRightHalfEm">
      for
    </div>
    <div class="nowrap marginRightHalfEm">
      <wackadoo-field [f]="resource.accountName" [mode]="mode"></wackadoo-field>
    </div>
  </ng-container>
</div>
