import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { User } from 'src/app/client-core/data/user.model';

@Component({
  selector: 'wackadoo-account-manager-application-header',
  templateUrl: './account-manager-application-header.component.html',

})
export class AccountManagerApplicationHeaderComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'AccountManagerApplicationHeaderComponent';

  isCollapsed = true;

  user: User = null;

  constructor(
    public userAuthService: UserAuthService,
  ) { }

  ngOnInit(): void {
    this.user = this.userAuthService.currentUser.getValue();
  }

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

}
