<div class="card">
  <div class="card-header d-flex justify-content-between">
    <div>
      Cut-N-Paste Form-Filler
    </div>
    <div>
      Format: <wackadoo-field-foreign-key [f]="formFillerID" mode="write" [onChange]="onFormFillerChange" [nullValueLabel]="'Select Format'"></wackadoo-field-foreign-key>
    </div>
  </div>
  <div class="card-body">
    <div>
      <wackadoo-field-string [f]="content" mode="write" [onChange]="onContentChange" title="enter text here..." size="100" rows="10"></wackadoo-field-string>
    </div>
    <div class="smallish">
      Values extracted: {{extractedValueCount}}
    </div>
  </div>
</div>
