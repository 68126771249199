import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UserInterfaceService } from '../../services/user-interface.service';
import { Globals } from '../../services/global.service';
import { ScreenType } from '../../services/screen-type.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wackadoo-sign-up-button',
  templateUrl: './sign-up-button.component.html',
})
export class SignUpButtonComponent implements OnInit, OnDestroy {

  @Input() appName = null;

  screenType = ScreenType.fullscreen;

  screenTypeSubscription: Subscription = null;

  constructor(
    public userInterfaceService: UserInterfaceService
  ) {
  }

  ngOnInit(): void {

    this.screenType = this.userInterfaceService.screenType.getValue();

    this.screenTypeSubscription = this.userInterfaceService.screenType.subscribe(
      (st: ScreenType) => {
        this.screenType = st;
      }
    );

  }

  ngOnDestroy(): void {
    if (this.screenTypeSubscription) {
      this.screenTypeSubscription.unsubscribe();
    }
  }

  goToSignUpPage(): void {
    window.location.href = (Globals.rootApplication === 'AccountManager' ? '' : '/AccountManager') + '/NewCustomer?action=add&previousApplication=' + (this.appName ? this.appName : Globals.thisApplication);
  }

}
