import { WResource } from '../../../data/resource.model';

export class SelectedResource {
    ehName: string;
    resource: WResource;
    keyFieldName?: string;

    constructor(ehName: string, resource: WResource, keyFieldName?: string) {
        this.ehName = ehName;
        this.resource = resource;
        this.keyFieldName = keyFieldName;
    }
}
