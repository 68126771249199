import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[wackadooAppHeaderPlaceholder]'
})
export class AppHeaderPlaceholderDirective {

  constructor(
    public viewContainerRef: ViewContainerRef,
  ) { }

}
