<span *ngIf="f  && !f.binaryContentUrl">
    <ng-container *ngIf="wysiwyg ; else rawText">
<!--
    TinyMCE Events:

    onActivate, onAddUndo, onBeforeAddUndo, onBeforeExecCommand, onBeforeGetContent, onBeforeRenderUI, onBeforeSetContent, onBeforePaste, onBlur,
    onChange, onClearUndos, onClick, onContextMenu, onCopy, onCut, onDblclick, onDeactivate, onDirty, onDrag, onDragDrop, onDragEnd, onDragGesture,
    onDragOver, onDrop, onExecCommand, onFocus, onFocusIn, onFocusOut, onGetContent, onHide, onInit, onKeyDown, onKeyPress, onKeyUp, onLoadContent,
    onMouseDown, onMouseEnter, onMouseLeave, onMouseMove, onMouseOut, onMouseOver, onMouseUp, onNodeChange, onObjectResizeStart, onObjectResized,
    onObjectSelected, onPaste, onPostProcess, onPostRender, onPreProcess, onProgressState, onRedo, onRemove, onReset, onSaveContent, onSelectionChange,
    onSetAttrib, onSetContent, onShow, onSubmit, onUndo, onVisualAid
-->
        <editor
            [disabled]="(mode === 'read') || f.readOnly"
            [init]="editorConfig"
            [initialValue]="f.text"

            (onPostRender)="onEditorPostRender($event)"
            (onChange)="onEditorChange($event)"
            (onBlur)="onEditorBlur($event)"

            (onMouseUp)="onEditorEvent($event)"

        ></editor>
<!--
            (onActivate)="onEditorEvent($event)"
            (onAddUndo)="onEditorEvent($event)"
            (onBeforeAddUndo)="onEditorEvent($event)"
            (onBeforeExecCommand)="onEditorEvent($event)"
            (onBeforeGetContent)="onEditorEvent($event)"
            (onBeforeRenderUI)="onEditorEvent($event)"
            (onBeforeSetContent)="onEditorEvent($event)"
            (onBeforePaste)="onEditorEvent($event)"
            (onBlur)="onEditorEvent($event)"
            (onChange)="onEditorEvent($event)"
            (onClearUndos)="onEditorEvent($event)"
            (onClick)="onEditorEvent($event)"
            (onContextMenu)="onEditorEvent($event)"
            (onCopy)="onEditorEvent($event)"
            (onCut)="onEditorEvent($event)"
            (onDblclick)="onEditorEvent($event)"
            (onDeactivate)="onEditorEvent($event)"
            (onDirty)="onEditorEvent($event)"
            (onDrag)="onEditorEvent($event)"
            (onDragDrop)="onEditorEvent($event)"
            (onDragEnd)="onEditorEvent($event)"
            (onDragGesture)="onEditorEvent($event)"
            (onDragOver)="onEditorEvent($event)"
            (onDrop)="onEditorEvent($event)"
            (onExecCommand)="onEditorEvent($event)"
            (onFocus)="onEditorEvent($event)"
            (onFocusIn)="onEditorEvent($event)"
            (onFocusOut)="onEditorEvent($event)"
            (onGetContent)="onEditorEvent($event)"
            (onHide)="onEditorEvent($event)"
            (onInit)="onEditorEvent($event)"
            (onKeyDown)="onEditorEvent($event)"
            (onKeyPress)="onEditorEvent($event)"
            (onKeyUp)="onEditorEvent($event)"
            (onLoadContent)="onEditorEvent($event)"
            (onMouseDown)="onEditorEvent($event)"
            (onMouseEnter)="onEditorEvent($event)"
            (onMouseLeave)="onEditorEvent($event)"
            (onMouseMove)="onEditorEvent($event)"
            (onMouseOut)="onEditorEvent($event)"
            (onMouseOver)="onEditorEvent($event)"
            (onNodeChange)="onEditorEvent($event)"
            (onObjectResizeStart)="onEditorEvent($event)"
            (onObjectResized)="onEditorEvent($event)"
            (onObjectSelected)="onEditorEvent($event)"
            (onPaste)="onEditorEvent($event)"
            (onPostProcess)="onEditorEvent($event)"
            (onPostRender)="onEditorEvent($event)"
            (onPreProcess)="onEditorEvent($event)"
            (onProgressState)="onEditorEvent($event)"
            (onRedo)="onEditorEvent($event)"
            (onRemove)="onEditorEvent($event)"
            (onReset)="onEditorEvent($event)"
            (onSaveContent)="onEditorEvent($event)"
            (onSelectionChange)="onEditorEvent($event)"
            (onSetAttrib)="onEditorEvent($event)"
            (onSetContent)="onEditorEvent($event)"
            (onShow)="onEditorEvent($event)"
            (onSubmit)="onEditorEvent($event)"
            (onUndo)="onEditorEvent($event)"
            (onVisualAid)="onEditorEvent($event)"
-->
    </ng-container>
    <ng-template #rawText>
        <ng-container *ngIf="mode === 'read' ; else writeMode">
            <pre class="purple fullHeight">{{ f.text }}</pre>
        </ng-container>
        <ng-template #writeMode>
            <textarea
                class="form-control no-wrap purple"
                [placeholder]="(f.required ? '* ' : '') + f.name"
                [name]="f.name"
                [value]="f.text" 
                [disabled]="f.readOnly"

                (input)="onInputChange($event)"
                (blur)="forceValidValue($event)"

                (mouseup)="onEditorEvent($event)"
            ></textarea>
        </ng-template>
    </ng-template>
</span>
