<div class="row">
  <div class="col-sm-3">
  </div>
  <div class="col-sm-6">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-6">
            <wackadoo-field [f]="resource.tifFieldID" [mode]="mode"></wackadoo-field>
          </div>
          <div class="col-sm-6">
            <wackadoo-field [f]="resource.tifID" [mode]="mode"></wackadoo-field>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <wackadoo-field [f]="resource.tifFieldName" [mode]="mode"></wackadoo-field>
          </div>
          <div class="col-sm-1 right">
            Row: 
          </div>
          <div class="col-sm-1">
            <wackadoo-field [f]="resource.tifFieldRow" [mode]="mode"></wackadoo-field>
          </div>
          <div class="col-sm-1 right">
            Column:
          </div>
          <div class="col-sm-1">
            <wackadoo-field [f]="resource.tifFieldColumn" [mode]="mode"></wackadoo-field>
          </div>
          <div class="col-sm-1 right">
            Length: 
          </div>
          <div class="col-sm-1">
            <wackadoo-field [f]="resource.tifFieldLength" [mode]="mode"></wackadoo-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 paddingRight0">
            This field is required in order to have a valid record:
          </div>
          <div class="col-sm-1">
            <wackadoo-field [f]="resource.tifFieldRequired" [mode]="mode"></wackadoo-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            Extract partial value:
          </div>
          <div class="col-sm-2">
            <wackadoo-field [f]="resource.tifFieldPartial" [mode]="mode"></wackadoo-field>
          </div>
          <div class="col-sm-2">
            Dividing character:
          </div>
          <div class="col-sm-1">
            <wackadoo-field [f]="resource.tifFieldPartialDelimiter" [mode]="mode"></wackadoo-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            Extract from Group 1 via ReEx: 
          </div>
          <div class="col-sm-2">
            <wackadoo-field [f]="resource.tifFieldRegEx" [mode]="mode"></wackadoo-field> 
          </div>
          <div class="col-sm-2">
            Pattern:
          </div>
          <div class="col-sm-4">
            <wackadoo-field [f]="resource.tifFieldRegExPattern" [mode]="mode"></wackadoo-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            How to modify (or interpret) the final value: 
          </div>
          <div class="col-sm-2">
            <wackadoo-field [f]="resource.tifFieldScrub" [mode]="mode"></wackadoo-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            The final value is valid if it is: 
          </div>
          <div class="col-sm-2">
            <wackadoo-field [f]="resource.tifFieldCompare" [mode]="mode"></wackadoo-field>
          </div>
          <div class="col-sm-5">
            <wackadoo-field [f]="resource.tifFieldCompareValue" [mode]="mode"></wackadoo-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5 right">
            <div>
              Set static value for all resources:
            </div>
            <div>
              Set static as the default value if nothing else is found:
            </div>
          </div>
          <div class="col-sm-1">
            <div>
              <wackadoo-field [f]="resource.tifFieldStatic" [mode]="'write'"></wackadoo-field>
            </div>
            <div>
              <wackadoo-field [f]="resource.tifFieldStaticAsDefault" [mode]="'write'"></wackadoo-field>
            </div>
          </div>
          <div class="col-sm-5">
            <wackadoo-field [f]="resource.tifFieldStaticValue" [mode]="'write'"></wackadoo-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            This is a header-type field that is read only one time for the whole report (not read for each line...) whose value is added to ALL records: 
          </div>
          <div class="col-sm-1">
            <wackadoo-field [f]="resource.tifFieldAllRecords" [mode]="mode"></wackadoo-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-3">
  </div>
</div>
