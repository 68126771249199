import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Subject, Subscription } from 'rxjs';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-price-list-add-modal',
  templateUrl: './price-list-add-modal.component.html',
})
export class PriceListAddModalComponent extends ResourceComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'PriceListAddModalComponent';

  @Input() loadDefaults = false;
  @Input() loadExisting = false;

  defaultPriceList: WResource [] = null;
  existingPriceList: WResource [] = null;
  potentialNewPriceListItems: WResource [] = null;

  preparePriceListSubjest: Subject<void> = null;
  preparePriceListSubscription: Subscription = null;

  ngOnInit(): void {
    super.ngOnInit();

    // console.log('ngOnInit()');

    this.preparePriceListSubjest = new Subject<void>();

    this.preparePriceListSubscription = this.preparePriceListSubjest.subscribe(
      () => {
        // populate the POTENTIAL list for this app, taking into account the default and existing lists...
        if (
            (this.loadDefaults && !this.loadExisting && (this.defaultPriceList !== null))
            ||
            (!this.loadDefaults && this.loadExisting && (this.existingPriceList !== null))
            ||
            (this.loadDefaults && this.loadExisting && (this.defaultPriceList !== null) && (this.existingPriceList !== null))
        ) {
          this.loadPriceListItemsToSelect();
        }
      }
    );

    // null out the price lists as an indicator that we have NOT yet loaded default or existing PriceListItems...

    this.defaultPriceList = null;
    this.existingPriceList = null;
    this.potentialNewPriceListItems = null;

    // load the DEFAULT & EXISTING lists, as required...

    if (this.loadDefaults && this.loadExisting) {
      this.loadDefaultPriceListItems();
      this.loadExistingPriceListItems();
    } else if (this.loadDefaults) {
      this.loadDefaultPriceListItems();
    } else if (this.loadExisting) {
      this.loadExistingPriceListItems();
    } else {
      this.loadPriceListItemsToSelect();
    }

  }

  ngOnDestroy(): void {

    if (this.preparePriceListSubscription) {
      this.preparePriceListSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }

  selectPriceListItem(ppli: WResource): void {
    this.resource.setFieldsFromParms(ppli.asParms);
    this.resource.markAllFieldsAsChanged();
  }

  loadDefaultPriceListItems(): void {

    // console.log('loadDefaultPriceListItems()');

    const eventHandler = 'PriceList';
    const action = 'listDefaults';
    const parms: any = {};
    parms.pageSize = -1;

    this.addAppSpecificValuesToParms(parms);

    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (result: WEvent) => {
        try {
          // console.log('loadDefaultPriceListItems()', 'result', result);

          if (result.status !== 'OK') {
            throw new Error(result.message);
          }
          this.defaultPriceList = result.resources;

          if (!this.defaultPriceList) {
            this.defaultPriceList = [];
          }

          // console.log('loadDefaultPriceListItems()', 'dplis', this.defaultPriceList);

          this.preparePriceListSubjest.next();

        } catch (ex) {
          const msg = 'PriceListAddModalComponent.loadDefaultPriceList()\n';
          this.userInterfaceService.alertUserToException(ex, msg);
        }
      }
    );
  }

  loadExistingPriceListItems(): void {
    try {
      const eventHandler = 'PriceList';
      const action = 'list';
      const parms: any = {};
      parms.pageSize = -1;

      this.addAppSpecificValuesToParms(parms);

      this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
        (result: WEvent) => {
          // console.log('loadExistingPriceListItems()', 'result', result);
          try {
            if (result.status !== 'OK') {
              throw new Error(result.message);
            }
            this.existingPriceList = result.resources;

            if (!this.existingPriceList) {
              this.existingPriceList = [];
            }

            // console.log('loadExistingPriceListItems()', 'plis', this.priceList);

            this.preparePriceListSubjest.next();

          } catch (ex) {
            const msg = 'PriceListAddModalComponent.loadExistingPriceListItems()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'PriceListAddModalComponent.loadExistingPriceListItems()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  //////////////////////////////////////////////////
  // functions to override on an app-specific basis
  //////////////////////////////////////////////////

  addAppSpecificValuesToParms(parms: any): void {
    // This applies app-specific values when obtaining the default and/or existing price list item lists...
    if (parms) {
    }
  }

  addAppSpecificValuesToPriceListItem(pli: WResource): void {
    // This applies app-specific values when using the base class to generate selections for each resourceType ...
    if ([pli]) {
    }
  }

  loadPriceListItemsToSelect(): void {

    try {

      this.potentialNewPriceListItems = [];

      const resourceTypes = this.eventServerService.newResource('PriceList').resourceType.select.split('|');

      for (const rt of resourceTypes) {

        const pli = this.eventServerService.newResource('PriceList');

        pli.itemName.value = 'New "' + rt + '" Item';
        pli.itemPrice.value = 1.98;
        pli.resourceType.value = rt;
        pli.quantityAdjustable.value = (rt !== 'Package');
        pli.fixedInventory.value = false;

        this.addAppSpecificValuesToPriceListItem(pli);

        this.potentialNewPriceListItems.push(pli);
      }

      // console.log('populateThePotentialNewPriceListItems()', 'pplis', this.potentialPriceListItems);

    } catch (ex) {
      const msg = 'PriceListAddModalComponent.loadPriceListItemsToSelect()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
