import { Directive, ElementRef, Renderer2, OnInit, OnDestroy, Input, AfterViewInit, HostListener } from '@angular/core';
import { UserInterfaceService } from '../services/user-interface.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[wackadooMaximizeContentHeight]'
})
export class MaximizeContentHeightDirective implements OnInit, OnDestroy, AfterViewInit {

  @Input() maxHeightThingBelowSelector = null;
  @Input() maxHeightFudgeFactor = 0;
  @Input() maxHeightTimoutDelay = 0;
  @Input() maxHeightLockThingBelowToBottomOfPage = false;
  @Input() maxHeightThingBelowDefaultHeight = 0;

  @HostListener('window:resize') onWindowResizeEvent = this.resizeComponent;

  public screenSizeChangeSubscription: Subscription;

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public userInterfaceService: UserInterfaceService,
  ) {
  }

  ngOnInit(): void {

    this.resizeComponent();

    // now make sure that the component height calculations run properly after stuff is on the screen...

    window.setTimeout(
      () => {
        this.resizeComponent();
      }
      , 100
    );

    this.screenSizeChangeSubscription = this.userInterfaceService.screenSizeChange.subscribe(
      () => {
        this.resizeComponent();
      }
    );
  }

  ngOnDestroy(): void {
    // console.log('ngOnDestroy()');

    if (this.screenSizeChangeSubscription) {
      this.screenSizeChangeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.resizeComponent();
  }

  resizeComponent(): void {

    // by doing it conditionally like this, we can embed this directive in something that might
    // possibly end up passing in null, thereby making this a NOP... (e.g. chart-block...)

    if (this.maxHeightThingBelowSelector) {

      // Note that passing in maxHeightTimoutDelay < 0 to wackadooMaximizeContentHeight
      // means that it does NOT wait until the screen settles before calculating the height.
      // (If we wait, even 0ms, then the chart-block canvas draws BEFORE the height is calculated.)

      if (this.maxHeightTimoutDelay < 0) {

        const targetElement: HTMLElement = this.elementRef.nativeElement;
        const theThingBelow: HTMLElement = document.querySelector(this.maxHeightThingBelowSelector);

        if (this.maxHeightLockThingBelowToBottomOfPage) {
          this.adjustContentHeightForButtonBarPage(targetElement, theThingBelow, this.maxHeightFudgeFactor);
        } else {
          this.adjustRelativeHeight(targetElement, theThingBelow, this.maxHeightFudgeFactor);
        }

      } else {

        window.setTimeout(
          () => {

            const targetElement: HTMLElement = this.elementRef.nativeElement;
            const theThingBelow: HTMLElement = document.querySelector(this.maxHeightThingBelowSelector);

            if (this.maxHeightLockThingBelowToBottomOfPage) {
              this.adjustContentHeightForButtonBarPage(targetElement, theThingBelow, this.maxHeightFudgeFactor);
            } else {
              this.adjustRelativeHeight(targetElement, theThingBelow, this.maxHeightFudgeFactor);
            }
          },
          this.maxHeightTimoutDelay
        );
      }


    }
  }

  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////
  // Screen re-size logic...
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////

  adjustContentHeightForButtonBarPage(targetElement: HTMLElement, theThingBelow: HTMLElement, fudgeFactor?: number): void {
    fudgeFactor = typeof fudgeFactor !== 'undefined' ? fudgeFactor : 0;

    // console.log('adjustContentHeightForButtonBarPage()', 'targetElement', targetElement, 'theThingBelow', theThingBelow, 'fudgeFactor', fudgeFactor);

    if (!targetElement || !theThingBelow) {
      return;
    }

    // console.log('adjustContentHeightForButtonBarPage()', target);

    // we look at the WHOLE page and make the page content fit, allowing for

    // 1. target offset from the top
    const targetAbsoluteOffset = this.userInterfaceService.getOffset(targetElement).top;

    // 2. the thing below element height
    const theThingBelowHeight = (theThingBelow.clientHeight === 0 ? this.maxHeightThingBelowDefaultHeight : theThingBelow.clientHeight);

    // 3. the height of the footer...
    const footerHeight = window.document.querySelector('.fixed-bottom').clientHeight;

    // 4. a misc fudge factor, because we are not REALLY getting the right heights somehow...

    const newContentHeight = window.innerHeight
      - targetAbsoluteOffset
      - theThingBelowHeight
      - footerHeight
      + fudgeFactor;

    // console.log('--- HEIGHT COMPONENTS ---');
    // console.log('window.innerHeight: ' + window.innerHeight);
    // console.log('-------------------------');
    // console.log('targetAbsoluteOffset: ' + targetAbsoluteOffset);
    // console.log('theThingBelowHeight: ' + theThingBelowHeight);
    // console.log('footerHeight: ' + footerHeight);
    // console.log('fudgeFactor: ' + fudgeFactor);
    // console.log('-------------------------');
    // console.log('newContentHeight: ' + newContentHeight);
    // console.log('-------------------------');

    this.renderer.setStyle(targetElement, 'display', 'block');
    this.renderer.setStyle(targetElement, 'height', newContentHeight + 'px');

    // console.log('adjustContentHeightForButtonBarPage() - AFTER', 'target', targetElement, 'theThingBelow', theThingBelow);

  }

  adjustRelativeHeight(targetElement: HTMLElement, theThingBelow: HTMLElement, fudgeFactor?: number): void {
    fudgeFactor = typeof fudgeFactor === 'number' ? fudgeFactor : 0;

    // we incorporate a misc fudge factor, because we are not REALLY getting the right heights somehow...

    // we need both elements...

    if (!targetElement || !theThingBelow) {
      return;
    }

    // console.log('adjustRelativeHeight() - BEFORE', 'target', targetElement, 'theThingBelow', theThingBelow);

    // 1. target offset from the top
    const targetAbsoluteOffset = this.userInterfaceService.getOffset(targetElement).top;

    // 2. the offset of the thing below the target...
    const theThingBelowAbsoluteOffset = this.userInterfaceService.getOffset(theThingBelow).top;

    // 3. calculate the height we want...
    const newContentHeight = theThingBelowAbsoluteOffset - targetAbsoluteOffset + fudgeFactor;

    // console.log('adjustRelativeHeight()', 'targetElement.clientHeight', targetElement.clientHeight, 'theThingBelow.clientHeight', theThingBelow.clientHeight, 'newContentHeight: ' + newContentHeight);

    this.renderer.setStyle(targetElement, 'display', 'block');
    this.renderer.setStyle(targetElement, 'height', newContentHeight + 'px');

    // console.log('adjustRelativeHeight() - AFTER', 'target', targetElement, 'theThingBelow', theThingBelow);


  }

}
