import { Component, OnInit, OnDestroy, Renderer2, AfterViewInit, ElementRef, Input } from '@angular/core';
import { FieldComponent } from '../field.component';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { EventServerService } from '../../../services/event-server.service';
import { WEvent } from '../../event.model';

@Component({
  selector: 'wackadoo-field-unique',
  templateUrl: './unique.component.html',

})
export class UniqueComponent extends FieldComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() eventHandler: string;
  uniqueFieldStatus = '';
  inputTimer: any = {};

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    private _elementRef: ElementRef,
    private _eventServerService: EventServerService,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {

    const cellInput: HTMLInputElement = this._elementRef.nativeElement as HTMLInputElement;

    // Now add eventListener to the field on each "keyUp" event
    // to validate the uniqueness of the field as the user types.
    // (We wait half a second between keystrokes before hitting the server...)

    this.renderer.listen(cellInput, 'keyup',
      (e: any) => {
        const target = e.target;
        if (this.inputTimer) {
          clearTimeout(this.inputTimer);
          this.inputTimer = undefined;
        }
        this.inputTimer = setTimeout(
          () => {

            this.uniqueFieldStatus = '';

            if (target.value) {
              // console.log('EventServer.isUniqueValueAvailable()', 'target.value: ' + target.value, 'valid? ' + this.f.isValidValue(target.value));
              target.value = target.value.toLowerCase().replace(/ /g, '');

              if ((target.value.length >= 3) && this.f.isValidValue(target.value)) {
                const parms: any =  {};
                parms.uniqueFieldName = target.name;
                parms.uniqueFieldValue = target.value;
                parms.skipServerStatus = 'true';

                this._eventServerService.fireEvent(this.eventHandler, 'isUniqueValueAvailable', parms).subscribe(
                  (event: WEvent) => {
                      // console.log('EventServer.isUniqueValueAvailable() - \n' + JSON.stringify(event));

                      // wackadoo._UI.setApplicationProgress(target.className);
                      this.renderer.removeClass(target, 'fieldChanged');
                      this.renderer.removeClass(target, 'fieldError');
                      this.renderer.removeClass(target, 'fieldChanged');

                      if (event !== null) {
                        const msg = event.getParameter('uniqueFieldStatus');

                        // console.log('EventServer.isUniqueValueAvailable() - uniqueFieldStatus: ' + msg);
                        if (msg === 'available') {
                          this.renderer.addClass(target, 'fieldSelected');
                        } else {
                          this.renderer.addClass(target, 'fieldError');
                        }
                        this.uniqueFieldStatus = msg;

                      } else {
                          this.renderer.addClass(target, 'fieldChanged');
                      }
                  }
                );
              }
            }
          }
          , 333);
      }
    );
  }


}
