<span *ngIf="f">
    <span *ngIf="mode === 'read'">
        <span *ngIf="f.noTimestamp">
            {{ f.value ? getLocalDate(f.value) : '' }}
        </span>
        <span *ngIf="!f.noTimestamp">
            {{ f.value ? getLocalDateAndTime(f.value) : '' }}
        </span>
    </span>
    <span *ngIf="mode === 'write'">
        <form class="marginTop2 form-inline">
            <div class="form-group marginBottom0">
                <div class="input-group" [title]="(f.required ? '* ' : '') + (title ? title : f.name)">
                    <!--
                        Not sure why this does NOT set the name, so we explicitly 
                        have to set the name in the TS code. Seems like a bug...
                     -->
                    <input
                        class="form-control dateField enter-date"
                        [ngClass]="{'fieldChanged': f.changed, 'fieldError': f.invalidFieldValue}"
                        [placeholder]="(f.required ? '* ' : '') + 'yyyy-mm-dd'"
                        [name]="f.name"
                        [(ngModel)]="dateInfo"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        firstDayOfWeek="7"
                        [disabled]="f.readOnly"
                    />
                    <div class="input-group-append">
                        <button
                            class="btn btn-outline-secondary choose-date"
                            (click)="d.toggle()"
                            type="button"
                            [disabled]="f.readOnly"
                        >
                            <span class="fas fa-calendar-alt"></span>
                        </button>
                        <ngb-timepicker
                            *ngIf="!f.noTimestamp"
                            class=""
                            [ngClass]="{'fieldChanged': f.changed}"
                            [name]="f.name+'Time'"
                            [(ngModel)]="timeInfo"
                            meridian="true"
                            [minuteStep]="15"
                            [seconds]="false"
                            [spinners]="false"
                            [disabled]="f.readOnly"
                        ></ngb-timepicker>
                        <button
                            class="btn btn-outline-secondary clear-date"
                            (click)="clearDate()"
                            type="button"
                            [disabled]="f.readOnly"
                        >
                            <span class="fas fa-calendar-times"></span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </span>
</span>
