<div class="card">
  <div class="card-header">
    Available Price List Items <span class="italic unbold">(click to select)</span>
  </div>
  <div class="card-body maxHeightViewPort40 min-width-20-em scrollable-y">
    <div *ngFor="let plpl of potentialNewPriceListPackageLinks; let idx = index;">
      <div class="border-bottom d-flex flex-row paddingTopHalfEm paddingBottomHalfEm rollover"
          (click)="selectPriceListItem(plpl)"
          [ngClass]="{ selected : resource && (plpl.priceListItemChildID.value === resource.priceListItemChildID.value) }"
      >
        <div class="center middle italic width-3-em">
          {{idx + 1}}
        </div>
        <div class="marginLeftHalfEm width-3-em">
          <wackadoo-field [f]="plpl.priceListItemChildCount" mode="write" ></wackadoo-field>
        </div>
        <div class="marginLeftHalfEm width-2-em">
          <wackadoo-field
              [f]="plpl.priceListItemChildShowIndividualItems" 
              [mode]="(plpl.priceListItemChildCount.value > 1 ? 'write' : 'read')"
              title="Show individual detail blocks in the shopping cart"
              [onChange]="checkBoxChangeSubject"
          ></wackadoo-field>
        </div>
        <div class="marginLeftHalfEm">
          {{ plpl.priceListItemChildID.displayValue }}
          <!--
          <wackadoo-field [f]="plpl.priceListItemChildID" mode="read" ></wackadoo-field>
          -->
        </div>
      </div>
    </div>
  </div>
</div>
