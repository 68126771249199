<div *ngIf="resource">
  <div class="d-flex justify-content-between">
    <div class="marginHalfEm">
      <wackadoo-field [f]="resource.sequence" [mode]="mode"></wackadoo-field>
    </div>
    <div class="marginHalfEm">
      <wackadoo-field [f]="resource.itemName" [mode]="mode"></wackadoo-field>
    </div>
    <div class="marginHalfEm">
      <wackadoo-field [f]="resource.itemPrice" [mode]="mode"></wackadoo-field>
    </div>
    <div class="marginHalfEm">
      quantityAdjustable: <wackadoo-field [f]="resource.quantityAdjustable" [mode]="mode"></wackadoo-field>
    </div>
    <div class="marginHalfEm">
      Fixed Inventory: <wackadoo-field [f]="resource.fixedInventory" [mode]="mode"></wackadoo-field>
    </div>
  </div>
  <div class="d-flex justify-content-between" *ngIf="resource.fixedInventory.value">
    <div class="marginHalfEm">
      Reserved Inventory: <wackadoo-field [f]="resource.reservedInventory" [mode]="mode"></wackadoo-field>
    </div>
    <div class="marginHalfEm">
      Total Inventory: <wackadoo-field [f]="resource.totalInventory" [mode]="mode"></wackadoo-field>
    </div>
    <div class="marginHalfEm italic" *ngIf="!resource.totalInventory.isNull && !resource.reservedInventory.isNull">
      ({{ resource.totalInventory.value - resource.reservedInventory.value }} Available)
    </div>
  </div>
  <div class="border-bottom">
    <ul>
      <li *ngIf="resource.description1.isPopulated || (mode === 'write')">
        <wackadoo-field [f]="resource.description1" [mode]="mode"></wackadoo-field>
      </li>
      <li *ngIf="resource.description2.isPopulated || (resource.description1.isPopulated && (mode === 'write'))">
        <wackadoo-field [f]="resource.description2" [mode]="mode"></wackadoo-field>
      </li>
      <li *ngIf="resource.description3.isPopulated || (resource.description2.isPopulated && (mode === 'write'))">
        <wackadoo-field [f]="resource.description3" [mode]="mode"></wackadoo-field>
      </li>
      <li *ngIf="resource.description4.isPopulated || (resource.description3.isPopulated && (mode === 'write'))">
        <wackadoo-field [f]="resource.description4" [mode]="mode"></wackadoo-field>
      </li>
      <li *ngIf="resource.description5.isPopulated || (resource.description4.isPopulated && (mode === 'write'))">
        <wackadoo-field [f]="resource.description5" [mode]="mode"></wackadoo-field>
      </li>
      <li *ngIf="resource.description6.isPopulated || (resource.description5.isPopulated && (mode === 'write'))">
        <wackadoo-field [f]="resource.description6" [mode]="mode"></wackadoo-field>
      </li>
      <li *ngIf="resource.description7.isPopulated || (resource.description6.isPopulated && (mode === 'write'))">
        <wackadoo-field [f]="resource.description7" [mode]="mode"></wackadoo-field>
      </li>
      <li *ngIf="resource.description8.isPopulated || (resource.description7.isPopulated && (mode === 'write'))">
        <wackadoo-field [f]="resource.description8" [mode]="mode"></wackadoo-field>
      </li>
      <li *ngIf="resource.description9.isPopulated || (resource.description8.isPopulated && (mode === 'write'))">
        <wackadoo-field [f]="resource.description9" [mode]="mode"></wackadoo-field>
      </li>
      <li *ngIf="resource.description10.isPopulated || (resource.description9.isPopulated && (mode === 'write'))">
        <wackadoo-field [f]="resource.description10" [mode]="mode"></wackadoo-field>
      </li>
    </ul>
  </div>
</div>
