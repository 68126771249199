import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { BinaryComponent } from '../binary.component';
import { FileContentDescriptor } from 'src/app/client-core/ui/file-content-descriptor.model';

@Component({
  selector: 'wackadoo-field-binary-text',
  templateUrl: './binary-text.component.html',

})
export class BinaryTextComponent extends BinaryComponent implements OnInit, OnDestroy, AfterViewInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    this.f.getBinaryFileContentFromServer(this.eventServerService, this.modalDialogService, this.utilityLibService, true);
  }

  /**
   * This MIMICS the default method called on all input field
   * changes, except that it works on field.text instead of field.value,
   * and it keeps the base64 version of that text in field.value.
   */
  onInputChange(e: any): boolean {
    // e = e || window.event;
    const target = e.srcElement || e.target;

    const cellInput = target;

    // console.log(cellInput);

    if (cellInput) {

      // console.log('cellInput.value: ' + cellInput.value + ', this.f.value: ' + this.f.value);
      // console.log('this.f.isValidValue(cellInput.value): ' + this.f.isValidValue(cellInput.value));

      // clear the previous formatting, if present...
      this.renderer.removeClass(cellInput, 'fieldChanged');
      this.renderer.removeClass(cellInput, 'fieldError');

      if (this.f.isValidValue(cellInput.value)) {
        // the new value passed validation...

        // console.log('cellInput.value: ' + cellInput.value, this.f.text, 'changed? ' + (cellInput.value !== this.f.text));

        // console.log(this.f.name + ' changed? ' + (cellInput.value !== this.f.text));

        if (cellInput.value !== this.f.text) {

          const oldValue = this.f.value;

          this.renderer.addClass(cellInput, 'fieldChanged');

          this.f.text = cellInput.value;

          // we CANNOT use btoa() because it does NOT support UTF-8 properly...
          // this.f.value = btoa(xhtml);
          this.f.value = this.utilityLibService.btoaForUnicodeString(cellInput.value);

          this.f.changed = true;

          let extension = 'unk';

          if (
              this.resource
              && this.resource[this.f.fileNameField]
              && this.resource[this.f.fileNameField].value
          ) {
            extension = this.getExtension(this.resource[this.f.fileNameField].value);
          }

          // This tells the UI to display the "new" text file size... (if it's on the screen...)
          // Now set the information for the current file into the UI if we can
          const fcd = new FileContentDescriptor(this.f.value, 'none.' + extension, this.f.text.length, extension, new Date());
          this.setRelatedFileContentFields(fcd);

          // console.log('cellInput.value: ' + cellInput.value, this.f.value, 'this.onChange exists? ' + (this.onChange !== null));
          if (this.onChange !== null) {
            // console.log('firing field.onChange.next()');
            this.f.oldValue = oldValue;
            this.onChange.next(this.f);
          }

        }
        // this._renderer.setStyle(cellInput, 'backgroundColor', '');
      } else {

        // console.log('this.f._errorMessage: ' + this.f._errorMessage);

        // the new value failed validation...
        this.renderer.addClass(cellInput, 'fieldError');
      }
    }

    return false;
  }

}
