import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-accounts',
  templateUrl: '../../../client-core/pages/repository-page/repository-page.component.html',
})
export class AccountsComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.detailContentComponentClassName = 'AccountDetailContentComponent';

    super.ngOnInit();
  }

}
