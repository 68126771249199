		<div class="card">
			<div class="card-header">
				Our history...
			</div>
			<div class="card-body">
				<p>
					Hello, and thank you for your interest. 
				</p>
				<p>
					This all started back in 2013 when I made the decision to stop living out of a suitcase as a 
					Regional Architect for a name-brand enterprise software company in order to spend time with my family.
					<span class="italic">(And yes, the very nature of that decision influenced the selection 
					of our company name...)</span> At that time, I was on the board of a 501(c)3 Christian ministry 
					whose donor management software was beyond end-of-life, presenting me with an interesting opportunity. 
				</p>
				<p>
					Never being one to sit idle (well, for very long, anyway...), I drew on my years of experience 
					in enterprise software architecture, intentionally designing our platform to support numerous 
					web apps, multiple customers, and the rapid deployment of new functionality since, like icebergs, 
					90% or more of all web apps do basically similar things "below the surface".  Once the technical 
					base is in place, proper design, component re-use and Agile software development techniques make 
					rolling out new web apps an easily repeatable process - which reduces both time-to-deliver and 
					overall customer risk.
				</p>
				<p>
					This was the genesis of <a href="https://www.wackadoo.info">wackadoo information systems llc</a>,
					which, from the outset, has intentionally targeted both commercial and charitable organizations, 
					with the work of the one helping to support the other.
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						<a href="https://www.wackadoo.info">wackadoo.info</a> - our corporate home page.
					</li>
					<li>
						<a href="https://www.wackadoo.org">wackadoo.org</a> - where we host subsidized (sometimes donated...) 
						charitable web apps that are geared towards ministries, churches, volunteer groups and non-profit organizations.
					</li>
					<li>
						<a href="https://www.closingpro.net">ClosingPro.net</a> - a web app for real estate transaction coordinators.
					</li>
				</ul>
				<p>
					So, while all this wackadoo stuff may look new to you, in fact, the core platform has been up and 
					running in production since 2014.
				</p>
				<p>
					Now we're just trying to see if we can make a go of it as a boutique web app company.
				</p>
				<p>
					Stay tuned...
				</p>
				<p>
					Thanks,
				</p>
				<p>
					Steve Kuenzli
				</p>
				<p>
					(the chief wackadoo...)
				</p>
			</div>
			
		</div>
