		<div class="card">
			<div class="card-header">
				Our technology...
			</div>
			<div class="card-body">
				<p>Do you really care how we built it?  We'd be happy to talk to you about it if you do, since we are 
				appropriately proud of our work.  But we suggest that it is enough that the solution works, is available 
				when you want it, and keeps your data secure.</p>
				<p>We are rather successful at not being cool - just ask our kids.  We don't chase trends, fads, early 
				adopters, or viral video fame.  Our intention is to be "connected enough" and make integrating new technology 
				into our platform a well-scoped, finite duration effort. (However, if that turns out not to be the case, then 
				we will adjust our core platform appropriately.)</p>
			</div>
		</div>
