<div class="card">
  <div class="card-header">
    Available Price List Items <span class="italic unbold">(click to select)</span>
  </div>
  <div class="card-body maxHeightViewPort40 min-width-20-em scrollable-y">
    <div *ngFor="let pnpli of potentialNewPriceListItems; let idx = index;">
      <div class="border-bottom d-flex flex-row paddingTopHalfEm paddingBottomHalfEm rollover"
          (click)="selectPriceListItem(pnpli)"
          [ngClass]="{ 'selected' : resource && (pnpli.itemName.value === resource.itemName.value) }"
      >
        <div class="center middle italic width-3-em">
          {{idx + 1}}
        </div>
        <div class="marginLeftHalfEm">
          <wackadoo-field [f]="pnpli.itemName" mode="read" ></wackadoo-field>
        </div>
      </div>
    </div>
  </div>
</div>
