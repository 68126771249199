import { Component } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-users-detail-button-bar',
  templateUrl: './user-detail-button-bar.component.html',
})
export class UserDetailButtonBarComponent extends DetailButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'UserDetailButtonBarComponent';

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService ,
  ) {
    super(eventServerService, userInterfaceService, modalDialogService);
  }

  copy(): void {

    // first, we clean out all the Account fields from the User...
    const tempAcct = this.eventServerService.newResource('Accounts');
    for (const f of tempAcct.fields) {
      if (!f.key) {
        delete this.resource[f.name];
      }
    }

    super.copy();
  }
  validateEMail(): void {
    console.log('UserButtonBar.validateEMail()');
    try {
      const user = this.resource.asParms;

      // console.log('UserButtonBar.validateEMail() - user:', user);

      // set the guest parameters...
      const parms: any = {};
      // required to ID this user...
      parms.userID = user.userID;
      // some of the user stuff...
      parms.firstName = user.firstName;
      parms.lastName = user.lastName;
      parms.userName = user.userName;
      parms.email = user.email;
      parms.accountName = user.accountName;
      parms.accountContact = user.accountContact;
      parms.accountEmail = user.accountEmail;
      parms.logoFileContent = user.logoFileContent;
      parms.logoFileType = user.logoFileType;
      // required email envelope info...
      parms.to = user.email;
      parms.fromName = user.accountName;
      // required prefix for user event url...
      parms.eventUrlPrefix = this.userInterfaceService.getApplicationUrl();

      // now send the user a request to validate their e-email...

      // console.log('UserButtonBar.validateEMail() - parms:', parms);

      this.eventServerService.fireEvent('ValidateUserEmail', 'send', parms).subscribe(
        (event: WEvent) => {
          // console.log('UserButtonBar.validateEMail() - event:', event);

          const target = user.firstName + ' ' + user.lastName + ' at ' + user.email;

          if (event.status === 'OK') {
            const msg = 'User email validation request sent to ' + target + '.';
            this.modalDialogService.showAlert(msg, 'Thank You!');
          } else {
            const msg = 'User email validation request NOT sent to ' + target + '.\n' + event.message + '\nPlease try again.';
            this.modalDialogService.showAlert(msg, 'Error!');
          }
        }
      );

    } catch (ex) {
      const msg = 'Users.postDetailLoad().send()';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }
}
