<div *ngIf="visible">
    <div class="modal-backdrop" (click)="onClose()"></div>
    <div class="modal-box">
        <div class="card">
            <div class="card-header">
                <h2 class="marginHalfEm">{{ title }}</h2>
            </div>
            <div class="card-body">
                <p *ngIf="timestamp" class="small">{{timestamp.toLocaleDateString() + ' ' + timestamp.toTimeString()}}</p>
                <p [innerHTML]="message | safeHtml" class="marginWholeEm large"></p>
            </div>
        </div>
        <div class="marginTopWholeEm floatGroup">
            <div class="floatRight">
                <button class="btn btn-secondary paddingLeftWholeEm paddingRightWholeEm marginRightWholeEm" (click)="onClose()">Close</button>
            </div>
        </div>
    </div>
</div>