import { Component } from '@angular/core';
import { DetailContentComponent } from '../../repository-page/detail-content/detail-content.component';

@Component({
  selector: 'wackadoo-import-template-fields-detail',
  templateUrl: './import-template-fields-detail.component.html',
})
export class ImportTemplateFieldsDetailComponent extends DetailContentComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ImportTemplateFieldsDetailComponent';

}
