import { Component, OnInit, OnDestroy, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { FieldComponent } from '../field.component';
import { FieldMode } from '../field-mode.model';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wackadoo-field-date',
  templateUrl: './date.component.html',

})
export class DateComponent extends FieldComponent implements OnInit, OnDestroy, AfterViewInit {

  private _dateInfo: NgbDateStruct;
  private _timeInfo: NgbTimeStruct;

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    public elementRef: ElementRef,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    // set titles for the buttons...
    if (this.mode === FieldMode.write) {

      // Not sure why we explicitly have to set the name. Seems like a bug...
      let e: any = this.elementRef.nativeElement.querySelector('.enter-date');
      let title = 'enter ' + this.f.name + ' (YYYY-MM-DD)';
      if (e) {
        this.renderer.setAttribute(e, 'name', this.f.name);
        this.renderer.setAttribute(e, 'title', title);
      }

      e = this.elementRef.nativeElement.querySelector('.choose-date');
      title = 'choose ' + this.f.name + ' from calendar';
      if (e) {
        this.renderer.setAttribute(e, 'title', title);
      }

      e = this.elementRef.nativeElement.querySelector('.clear-date');
      title = 'clear ' + this.f.name;
      if (e) {
        this.renderer.setAttribute(e, 'title', title);
      }

      e = this.elementRef.nativeElement.querySelector('.ngb-tp-hour > input');
      title = 'hour (1-12)';
      if (e) {
        this.renderer.setAttribute(e, 'title', title);
      }

      e = this.elementRef.nativeElement.querySelector('.ngb-tp-minute > input');
      title = 'minutes (0-59)';
      if (e) {
        this.renderer.setAttribute(e, 'title', title);
      }

      e = this.elementRef.nativeElement.querySelector('.ngb-tp-meridian > button');
      title = 'toggle AM/PM';
      if (e) {
        this.renderer.setAttribute(e, 'title', title);
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  extractDateInfoFromFieldValue(): NgbDateStruct {

    let di: NgbDateStruct = null;

    if (this.f.value) {

      const dateFromFieldValue = new Date(this.f.value);

      di = {
        year: dateFromFieldValue.getFullYear(),
        month: dateFromFieldValue.getMonth() + 1,
        day: dateFromFieldValue.getDate()
      };
    }

    return di;
  }

  extractTimeInfoFromFieldValue(): NgbTimeStruct {

    let ti: NgbTimeStruct = null;

    if (this.f.value) {

      const dateFromFieldValue = new Date(this.f.value);

      if (!this.f.noTimestamp) {
        ti = {
          hour: dateFromFieldValue.getHours(),
          minute: dateFromFieldValue.getMinutes(),
          second: dateFromFieldValue.getSeconds()
        };
      }
    }

    return ti;
  }

  get dateInfo(): NgbDateStruct {
    if (this.f && (this.f._previousValue !== this.f.value)) {
      this.f._previousValue = this.f.value;
      this._dateInfo = this.extractDateInfoFromFieldValue();
      this._timeInfo = this.extractTimeInfoFromFieldValue();
    }
    return this._dateInfo;
  }

  set dateInfo(dateInfo: NgbDateStruct) {
    this.f.invalidFieldValue = false;
    if (typeof dateInfo === 'object') {
      this._dateInfo = dateInfo;
    } else {
      this.f.invalidFieldValue = true;
      this._dateInfo = null;
    }
    this.onDateChange();
  }

  get timeInfo(): NgbTimeStruct {
    return this._timeInfo;
  }

  set timeInfo(timeInfo: NgbTimeStruct) {
    if (typeof timeInfo === 'object') {
      this._timeInfo = timeInfo;
    } else {
      this._timeInfo = null;
    }
    this.onDateChange();
  }

  clearDate(): void {
    this._dateInfo = null;
    this._timeInfo = null;
    this.onDateChange();
  }

  getLocalDate(jsonTimestamp: string): string {
    return (this.formatMostlyLocalDate(jsonTimestamp, this.displayFormat ? this.displayFormat : 'MMM dd, YYYY'));
  }

  getLocalDateAndTime(jsonTimestamp: string): string {
    return (this.formatMostlyLocalDate(jsonTimestamp, this.displayFormat ? this.displayFormat : 'MMM dd, YYYY hh:mm'));
  }

  /*
    This formats dates for the given string in the local time zone - unless the format is international in nature.
    Supported international formats:
      iso, json, utc, gmt
    Supported local formats: (US English day and month names are used...)
      local
      strings made up of "YYYY MMMM MMM M dd HH hh mm ss day d" with dividers "-,:| /"
  */
  formatMostlyLocalDate(jsonTimestamp: string, displayFormat: string): string {
    const d: Date = new Date(jsonTimestamp);

    if (displayFormat.toLowerCase() === 'iso') {
      return d.toISOString();
    }

    if (displayFormat.toLowerCase() === 'json') {
      return d.toJSON();
    }

    if (displayFormat.toLowerCase().includes('local')) {
      return (this.f.noTimestamp ? d.toLocaleDateString() : d.toLocaleString());
    }

    if ( (displayFormat.toLowerCase() === 'utc')
        || (displayFormat.toLowerCase() === 'gmt')
    ) {
      return d.toUTCString();
    }

    // If we got here, we are parsing a custom LOCAL date/time format...

    const parts = displayFormat.split(/[-,:| \/]+/g);
    const dividers = displayFormat.split(/[^-,:| \/]+/g);

    // console.log('displayFormat', displayFormat, 'parts', parts, 'dividers', dividers);

    let dstr = '';

    const MMMM = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const MMM = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dow = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    let amOrPm = null;

    for (let i = 0; i < parts.length; i++) {
      const p = parts[i];

      switch (p) {
        case 'YYYY' :
              dstr += d.getFullYear();
              break;
        case 'MMMM' :
              dstr += MMMM[d.getMonth()];
              break;
        case 'MMM' :
              dstr += MMM[d.getMonth()];
              break;
        case 'M' :
              dstr += d.getMonth() + 1;
              break;
        case 'dd' :
              dstr += d.getDate();
              break;
        case 'HH' :
              dstr += d.getHours();
              break;
        case 'hh' :
              dstr += ((d.getHours() % 12) === 0 ? 12 : (d.getHours() % 12));
              amOrPm = (d.getHours() < 12 ? 'AM' : 'PM');
              break;
        case 'mm' :
              dstr += (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
              break;
        case 'ss' :
              dstr += (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();
              break;
        case 'day' :
              dstr += day[d.getDay()];
              break;
        case 'd' :
              dstr += dow[d.getDay()];
              break;
      }

      if (
          amOrPm
          &&
          (
            ( (p === 'hh') && !parts.includes('mm') && !parts.includes('ss') )
            ||
            ( (p === 'mm') && !parts.includes('ss') )
            ||
            (p === 'ss')
          )
       ) {
        dstr += ' ' + amOrPm;
      }

      const div = dividers[i + 1];
      dstr += (div ? div : '');

    }

    return dstr;
  }

  onDateChange(): void {

    // console.log('onDateChange()', this.f.value, this._dateInfo, this._timeInfo);

    if (this._dateInfo || this._timeInfo) {

      const year = this._dateInfo.year;
      const month = this._dateInfo.month;
      const day = this._dateInfo.day;

      let hour = 0;
      let minute = 0;
      let second = 0;

      if (!this.f.noTimestamp && this._timeInfo) {
        hour = this._timeInfo.hour;
        minute = this._timeInfo.minute;
        second = this._timeInfo.second;
      }

      const newDateFromUI = new Date();
      // NOTE: month in the _dateInfo object is 1-12, but it's 0-11 in the Date object...
      newDateFromUI.setFullYear(year, month - 1, day);
      newDateFromUI.setHours(hour, minute, second);

      const oldDateFromFieldValue = new Date(this.f.value);

      // console.log('onDateChange()', '(' + oldDateFromFieldValue.toJSON() + ' -> ' + newDateFromUI.toJSON() + ')', month, oldDateFromFieldValue.getMonth(), newDateFromUI.getMonth(), (oldDateFromFieldValue.getMonth() !== newDateFromUI.getMonth()));

      const dateChangedEnough = (
        (oldDateFromFieldValue.getFullYear() !== newDateFromUI.getFullYear())
        ||
        (oldDateFromFieldValue.getMonth() !== newDateFromUI.getMonth())
        ||
        (oldDateFromFieldValue.getDate() !== newDateFromUI.getDate())
        ||
        (oldDateFromFieldValue.getHours() !== newDateFromUI.getHours())
        ||
        (oldDateFromFieldValue.getMinutes() !== newDateFromUI.getMinutes())
        // Remember: we're NOT letting users enter seconds in this component, so we ignore them...
        // ||
        // (fieldDate.getSeconds() !== d.getSeconds())
      );

      // console.log('onDateChange()', 'delta: ' + Math.abs(oldDateFromFieldValue.getTime() - newDateFromUI.getTime()), 'dateChangedEnough: ' + dateChangedEnough);

      if (dateChangedEnough) {
        // console.log('changing field value:', this.f.value, newDateFromUI.toJSON());

        const oldValue = this.f.value;

        // we set the field value...
        this.f.value = newDateFromUI.toJSON();

        // we use the "this.f.changed" value in the HTML template to
        // trigger the fieldChanged class change on the inputs...
        this.f.changed = true;

        // now tell anybody who might be listening that the value changed...
        if (this.onChange) {
          this.f.oldValue = oldValue;
          this.onChange.next(this.f);
        }

      } else {
        // console.log('NOT changing field value:', this.f.value, newDateFromUI.toJSON());
        this.f.changed = false;
      }

    } else if (this.f.value !== null) {
      const oldValue = this.f.value;

      this.f.value = null;
      this.f.changed = true;

      // now tell anybody who might be listening that the value changed...
      if (this.onChange) {
        this.f.oldValue = oldValue;
        this.onChange.next(this.f);
      }
    }

  }

}
