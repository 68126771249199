import { Component, OnInit, OnDestroy } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';
import { Globals } from 'src/app/client-core/services/global.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WResource } from 'src/app/client-core/data/resource.model';

@Component({
  selector: 'wackadoo-account-detail-content',
  templateUrl: './account-detail-content.component.html',
})
export class AccountDetailContentComponent extends DetailContentComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'AccountDetailContentComponent';

  domain: string = null;

  // subMerchant credit card payment stuff...

  subMerchantVendorName: string = null;
  subMerchantExists = false;
  subMerchantReadyToReceivePayments = false;

  checkingSubMerchantAccountStatus = true;

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.domain = Globals.domain;
    this.getSubMerchantVendorName();
    this.verifySubMerchantExists();
    this.verifySubMerchantIsReadyForPayments();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  toggleTestMode(): void {
    this.resource.accountSubMerchantInTestMode.value = !this.resource.accountSubMerchantInTestMode.value;
    this.resource.accountSubMerchantInTestMode.changed = true;
    this.eventServerService.saveResource(this.resource).subscribe(
      (result: WEvent) => {
        if (result) {
          this.userInterfaceService.reloadCurrentPage();
        }
      }
    );
  }

  get workingOnSystemAccount(): boolean {
    return (this.resource.keyField.value === 1);
  }

  getSubMerchantVendorName(): void {
    const eventHandler = 'Accounts';
    const action = 'getVendorName';
    const parms: any = {};

    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (event: WEvent) => {
        // console.log(event);
        try {
          if (event) {
            if (event.status !== 'OK') {
              throw new Error(event.status + ' / ' + event.message);
            }
            this.subMerchantVendorName = event.getParameter('vendorName');
          }
        } catch (ex) {
          console.log(event);
          this.modalDialogService.showAlert(ex.message, 'Error: getSubMerchantVendorName');
        }
      }
    );
  }

  createSubMerchant(): void {
    const eventHandler = 'Accounts';
    const action = 'generateSubMerchantID';
    const parms: any = {};

    this.modalDialogService.showPleaseWait(true);
    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (event: WEvent) => {
        try {
          this.modalDialogService.showPleaseWait(false);
          if (event) {
            if (event.status !== 'OK') {
              // if we got here, the server FAILED to generate and save the subMerchantID in the Account
              throw new Error(event.message);
            }

            // if we got here, the server generated and saved the subMerchantID in the Account
            // (we never see the subMerchantID in the response for security reasons...)

            this.reDirectToSubMerchantVendorAccountManagement();

          }
        } catch (ex) {
          console.log(event);
          this.verifySubMerchantIsReadyForPayments();
          this.modalDialogService.showAlert(action + ' failed: ' + ex.message, 'Error');
        }
      }
    );
  }

  reDirectToSubMerchantVendorAccountManagement(): void {
    const eventHandler = 'Accounts';
    const action = 'onBoardSubMerchant';
    const parms: any = {};

    this.modalDialogService.showPleaseWait('Re-directing you to ' + this.subMerchantVendorName + ' for on-boarding.');

    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (event: WEvent) => {
        try {
          this.modalDialogService.showPleaseWait(false);
          if (event) {
            if (event.status !== 'OK') {
              throw new Error(event.message);
            }
            const redirectUrl = event.parameters.redirectUrl;
            // now take the re-direct...
            window.location.href = redirectUrl;
          } else {
            throw new Error(action + ' failed: You should not be reading this, because that button was supposed to re-direct you to ' + this.subMerchantVendorName + ' for on-boarding.');
          }
        } catch (ex) {
          console.log(event);
          this.modalDialogService.showAlert(ex.message, 'Error: Unable to re-direct you to ' + this.subMerchantVendorName + ' for on-boarding.');
        }
      }
    );
  }

  verifySubMerchantExists(): void {
    const eventHandler = 'Accounts';
    const action = 'verifySubMerchantExists';
    const parms: any = (this.user.isSysAdminOnly ? this.resource.keyField.asParm : {});

    // the system account (accountID === 1) will never have a sub merchant ID...
    if (!this.workingOnSystemAccount) {
      this.modalDialogService.showPleaseWait(true);
      this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
        (event: WEvent) => {
          try {
            this.modalDialogService.showPleaseWait(false);
            if (event) {
              if (event.status === 'ACCESS DENIED') {
                throw new Error(event.status + ' / ' + event.message);
              }
              this.subMerchantExists = (event.status === 'OK'); // ERROR simply means it does not exist...
            }
          } catch (ex) {
            console.log(event);
            this.modalDialogService.showAlert(ex.message, 'Error: verifySubMerchantExists');
          }
          this.checkingSubMerchantAccountStatus = false;
        }
      );
    }
  }

  verifySubMerchantIsReadyForPayments(): void {
    const eventHandler = 'Accounts';
    const action = 'verifySubMerchantIsReadyForPayments';
    const parms: any = (this.user.isSysAdminOnly ? this.resource.keyField.asParm : {});

    this.modalDialogService.showPleaseWait(true);
    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (event: WEvent) => {
        try {
          this.modalDialogService.showPleaseWait(false);
          if (event) {

            // event.status is OK, ERROR, 'ACCESS DENIED'

            if (event.status === 'ACCESS DENIED') {
              throw new Error(event.status + ' / ' + event.message);
            }

            // OK means yes, ERROR means no
            this.subMerchantReadyToReceivePayments = (event.status === 'OK');
          }
        } catch (ex) {
          console.log(event);
          this.modalDialogService.showAlert(ex.message, 'Error: verifySubMerchantIsReadyForPayments');
        }
        this.checkingSubMerchantAccountStatus = false;
      }
    );
  }

  get last4(): string {
    return (this.resource.accountSubMerchantInTestMode.value
            ? this.resource.accountSubMerchantTestID.value.substr(this.resource.accountSubMerchantTestID.value.length - 4)
            : this.resource.accountSubMerchantLiveID.value.substr(this.resource.accountSubMerchantLiveID.value.length - 4)
          );
  }

  subMerchantIDIsPopulated(): boolean {
    return  (this.resource.accountSubMerchantInTestMode.value ? this.resource.accountSubMerchantTestID.isPopulated : this.resource.accountSubMerchantLiveID.isPopulated);
  }

  clearSubMerchantID(test: boolean): void {
    let msg = 'Please note:';
    msg += '<ol>';
    msg += '<li>';
    msg += 'Your ' + (test ? 'TEST' : 'LIVE') + ' Mode ' + this.subMerchantVendorName + ' account ID ending in "' + this.last4 + '" WILL NOT be deleted by ' + this.subMerchantVendorName + '.';
    msg += '</li>';
    msg += '<li>';
    msg += 'That account WILL NOT be dissociated from ' + this.domain + ' by ' + this.subMerchantVendorName + '. (You would have to use your ' + this.subMerchantVendorName + ' dashboard to do that.)';
    msg += '</li>';
    msg += '<li>';
    msg += 'You WILL have to create a new ' + this.subMerchantVendorName + ' account if you want to re-connect in ' + (test ? 'TEST' : 'LIVE') + ' Mode in the future.';
    msg += '</li>';
    msg += '</ol>';
    msg += '\n\nAre you absolutely sure you want to do this?';

    this.modalDialogService.showConfirm(msg, 'WARNING! You are about to COMPLETELY DISCONNECT from your ' + (test ? 'TEST' : 'LIVE') + ' Mode ' + this.subMerchantVendorName + ' account!').subscribe(
      (flag: boolean ) => {
        if (flag) {
          const eventHandler = 'Accounts';
          const action = 'modify';
          const parms: any = this.resource.keyField.asParm;
          if (test) {
            parms.accountSubMerchantTestID = Globals.MAGIC_NULL_STRING_VALUE;
          } else {
            parms.accountSubMerchantLiveID = Globals.MAGIC_NULL_STRING_VALUE;
          }

          this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
            (event: WEvent) => {
              try {
                if (event) {
                  if (event.status !== 'OK') {
                    throw new Error(event.message);
                  }
                }
                this.eventServerService.loadResourceFromServer(this.eventHandler, this.resource.keyField.asParm).subscribe(
                  (r: WResource) => {
                    this.userInterfaceService.selectResource(this.eventHandler, r);
                    this.userInterfaceService.reloadCurrentPage();
                  }
                );
              } catch (ex) {
                console.log(event);
                this.modalDialogService.showAlert(ex.message, 'Error: clearSubMerchantID');
              }
            }
          );
        }
      }
    );
  }

}
