<div class="card">
  <div class="card-header">
    It takes a few seconds for the message to be set or cleared.
  </div>
  <div class="card-body">
    Message: <wackadoo-field [f]="message" mode="write"></wackadoo-field>
  </div>
</div>
<div>
  <div class="btn-group btn-group-justified buttonBar marginBottomHalfEm" >
    <div class="btn-group">
      <button type="button" (click)="setAccountStatus()" title="Set Scrolling Message" data-abbrev="Set Msg" class="btn btn-secondary actionButton"><span class="fas fa-volume-up" aria-hidden="true"></span></button>
    </div>
    <div class="btn-group">
      <button type="button" (click)="clearAccountStatus()" title="Clear Scrolling Message" data-abbrev="Clear Msg" class="btn btn-secondary actionButton"><span class="fas fa-volume-mute" aria-hidden="true"></span></button>
    </div>
  </div>
</div>
