		<div class="card">
			<div class="card-header">
				Transparency
			</div>
			<div class="card-body">
				<p>
					We may be small now, but we have big plans - and we want you to be able to see where we're headed. 
					As we get rolling, we plan to give all our customers access to a feedback/support app that directly 
					feeds our product backlog, and will allow you to make (and rank...) feature and application requests.
				</p>
			</div>
		</div>
