import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[wackadooStopPropagation]'
})
export class StopPropagationDirective {

  @HostListener('click', ['$event']) click = this.processEvent;

  constructor(
    public elementRef: ElementRef,
  ) {}

  processEvent(e: any): void {

    // this stops the propagation of the click event w/in the page (i.e. preventing resource selection, etc.)
    e.stopPropagation();

    // If we un-commented this, it would prevent the OS from (e.g.) opening the default mail client (but we WANT it to open...)
    // e.preventDefault();
  }
}
