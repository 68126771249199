		<div class="card">
			<div class="card-header">
				Help Video Library
			</div>
			<div class="card-body">
				<p>
					All of our help videos are hosted on the <a href="https://www.youtube.com/channel/UC3r_L3fwXgJ8bLMjWlCTRqA" target="_blank">"wackadoo.info" YouTube channel</a>.
					Some videos apply to <a href="https://www.youtube.com/playlist?list=PLejd8jf0LJwnuXwAsFtuhOE8rfchYBfBb" target="_blank">all our applications</a>, while others are application specific.
				</p>
				<p class="italic small">
					Please note that if the video listed below is not an active link, then that video has yet to be posted.
				</p>
				<div class="row">
					<div class="col-sm-6" *ngIf="!isAcctMgr">
						<p>
							These videos cover topics that are specific to this "{{ appName }}" application.
						</p>
						<ul class="marginBottomWholeEm">
							<li *wackadooResourceBlock="let r of []; let idx = index; let prev = previous; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'video', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1};">
								<span *ngIf="r.value && r.value.value && (r.value.value !== '')">
									<a href="{{ r.value.value }}" target="_blank">{{ r.name.value }}</a> 
								</span>
								<span *ngIf="!r.value || !r.value.value || (r.value.value === '')">
									{{ r.name.value }} 
								</span>
							</li>
						</ul>
					</div>
					<div class="col-sm-6">
						<p>
							These videos cover topics that are pertinent for most, if not all, applications.
						</p>
						<ul class="marginBottomWholeEm">
							<li *wackadooResourceBlock="let r of []; eventHandler:'ApplicationMetaData'; action:'list'; parms:{'type':'help', 'subgroup':'video', 'pageSize':-1, 'sortBy':'sequence', 'sortDirection':1, 'appID':1};">
								<span *ngIf="r.value && r.value.value && (r.value.value !== '')">
									<a href="{{ r.value.value }}" target="_blank">{{ r.name.value }}</a> 
								</span>
								<span *ngIf="!r.value || !r.value.value || (r.value.value === '')">
									{{ r.name.value }} 
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

