<span *ngIf="f">
    <span *ngIf="mode === 'read'">
        {{ formatValue() }}
    </span>
    <span *ngIf="mode === 'write'">
        <div class="input-group">
            <div class="input-group-prepend" *ngIf="f.displayComponent === 'currency'">
                <span class="input-group-text">$</span>
            </div>
            <input 
                class="form-control"
                type="number" 
                [ngClass]="{'fieldChanged': f.changed}"
                [placeholder]="(f.required ? '* ' : '') + f.name"
                [name]="f.name" 
                [title]="(title ? title : f.name)"
                [size]="(f.length && (f.length < size) ? f.length : size)"
                [value]="f.value" 
                [required]="f.required"
                [min]="f.min"
                [max]="f.max"
                [disabled]="f.readOnly"
                (input)="onInputChange($event)"
                (blur)="forceValidValue($event)"
            />
            <div class="input-group-append" *ngIf="f.displayComponent === 'percentage'">
                <span class="input-group-text">%</span>
            </div>
        </div>
    </span>
</span>
