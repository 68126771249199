<div class="marginLeftWholeEm d-flex" [ngClass]="{ 'red italic': resource.expiration.beforeNow() }">
  <div>
    <span class="fas fa-{{ resource.appIcon.value }}"></span> {{ resource.appName.value }} 
  </div>
  <div class="marginLeftHalfEm">
    <span *ngIf="resource.expiration.beforeNow()">expired:  <wackadoo-field [f]="resource.expiration" mode="read"></wackadoo-field></span>
    <span *ngIf="resource.expiration.afterNow()">expires:  <wackadoo-field [f]="resource.expiration" mode="read"></wackadoo-field></span>
    <span *ngIf="!resource.expiration.value">(never expires)</span>
  </div>
  <div  class="marginLeftHalfEm italic" *ngIf="user.isSysAdminOnly">
    ({{ resource.accountName.value }})
  </div>
</div>
