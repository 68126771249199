import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Subject } from 'rxjs';
import { WField } from 'src/app/client-core/data/field.model';

@Component({
  selector: 'wackadoo-shopping-cart-item-detail',
  templateUrl: './shopping-cart-item-detail.component.html',
})
export class ShoppingCartItemDetailComponent extends ResourceComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ShoppingCartItemDetailComponent';

  @Input() shoppingCart: WResource = null;
  @Input() shoppingCartItemQuantityChangedSubject: Subject<WField> = null;

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
