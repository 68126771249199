import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { EventPageComponent } from '../../pages/event-page/event-page.component';
import { UserInterfaceService } from '../../services/user-interface.service';
import { ModalDialogService } from '../../services/modal-dialog.service';
import { UserAuthService } from '../../services/user-auth.service';
import { WResource } from '../../data/resource.model';
import { WField, WSelect, WBinary } from '../../data/field.model';
import { EventServerService } from '../../services/event-server.service';
import { Subject, Subscription } from 'rxjs';
import { SelectedResource } from '../../ui/main/current-selection/selected-resource.model';
import { WEvent } from '../../data/event.model';
import { UtilityLibService } from '../../services/utility-lib.service';

@Component({
  selector: 'wackadoo-reports',
  templateUrl: './reports.component.html',
})
export class ReportsComponent extends EventPageComponent implements OnInit, OnDestroy, AfterViewInit {

  selectedReport: WResource = null;

  // we leave this.extraParameters null until we actually need it...
  extraParameters: WResource = null;
  extraParameterSubjects: Subject<WField> [] = [];
  extraParameterSubscriptions: Subscription [] = [];

  exportFileExtension: WSelect = null;
  templateFileExtension: WSelect = null;

  hasDocxTemplate = false;
  hasXlsxTemplate = false;
  hasXlsmTemplate = false;
  hasGhtmlTemplate = false;

  pageReloadSubscription: Subscription = null;

  resizeTimeout: number = null;

  private _selectionChangeSubscription: Subscription = null;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    public eventServerService: EventServerService,
    public utilityLibService: UtilityLibService,
  ) {
    super(userAuthService, userInterfaceService, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.selectedReport = this.userInterfaceService.getSelectedResource('Reports'); // because this.eventHandler is not yet set...

    this.buildExtraParametersResource();

    this._selectionChangeSubscription = this.userInterfaceService.selectionChange.subscribe(
      (sr: SelectedResource) => {
        // console.log(sr);
        // console.log('reports.selectionChange() - ' + (sr && sr.resource === null ? 'UN-select' : 'select'));
        if (sr.ehName === this.eventHandler) {
          this.selectedReport = sr.resource;
        }
        this.initializePage();
      }
    );

    this.initializePage();

  }

  ngOnDestroy(): void {
    if (this.extraParameterSubscriptions.length > 0) {
      for (const eps of this.extraParameterSubscriptions) {
        eps.unsubscribe();
      }
    }
    if (this._selectionChangeSubscription) {
      this._selectionChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    // console.log('ngAfterViewInit()', this.selectedReport, this.resources);
    // wait until the screen settles, then re-size the screen...
    this.initializePage();
  }

  buildExtraParametersResource(): void {

    const fieldAPIs = this.eventServerService.getFieldDefinitionsInOrder('Reports'); // because "this.eventHandler" is not yet set...

    for (const fAPI of fieldAPIs) {
      if ((fAPI.name !== 'id') && (fAPI.name !== 'reportName') && (fAPI.name !== 'sql') && (fAPI.name !== 'userQueryParms') && !fAPI.foreignKey) {

        if (this.extraParameters === null) {
          this.extraParameters = new WResource();
        }

        let defaultValueFromSelectedReport = null;

        if (this.selectedReport
            && this.selectedReport[fAPI.name]
            && (this.selectedReport[fAPI.name].value !== null)
        ) {
          defaultValueFromSelectedReport = this.selectedReport[fAPI.name].value;
        }

        // console.log('buildExtraParametersResource()', fAPI.name, defaultValueFromSelectedReport);

        let temp = WField.factory(fAPI.type, fAPI.name, defaultValueFromSelectedReport, fAPI.eventHandler, fAPI, this.selectedReport);

        // special handling for the "year" field for force it to be a WSelect Field...

        if (fAPI.name === 'year') {
          const thisYear = (new Date()).getFullYear();
          temp = new WSelect(fAPI.name, String(defaultValueFromSelectedReport === null ? thisYear : defaultValueFromSelectedReport) , this.eventHandler, fAPI);
          temp.select = '';
          for (let i = 0; i < 25; i++) {
            temp.select += ((thisYear - i) + (i < 24 ? '|' : ''));
          }
        }

        // special handling for "month" field to force full month names...
        // Note: month.select is (as of this writing...) already defined with 3 letter abbreviations.
        // (We should go back and change them to their proper full names in the resource defn files,
        // but we hacked it here instead...)

        if (fAPI.name === 'month') {
          if (!temp.value) {
            const thisMonth = (new Date()).getMonth() + 1;
            temp.value = String(defaultValueFromSelectedReport === null ? thisMonth : defaultValueFromSelectedReport);
          }
          temp.select = '1|2|3|4|5|6|7|8|9|10|11|12';
          temp.selectLabels = 'January|February|March|April|May|June|July|August|September|October|November|December';
        }

        // wire up a Subject and Subscription for use by the extraParameter's field component...

        const tempSubj = new Subject<WField>();

        const tempSubscr = tempSubj.subscribe(
          (field: WField) => {
            // this will never be null, but we do this to (a) force this to properly fire, and (b) eliminate a lint warning...
            if (field) {

              // console.log('field changed...', field, this.selectedReport, this.extraParameters);

              if (this.selectedReport !== null) {

                // set up a blank report Resource
                const report: WResource = this.eventServerService.newResource(this.eventHandler);

                // add in the non-null selected report fields...
                report.addAllFields(this.selectedReport);

                // this will add in the non-null NEWLY MODIFIED extra parms...
                this.selectReport(report);

              }
            }

          }
        );

        this.extraParameterSubjects.push(tempSubj);
        this.extraParameterSubscriptions.push(tempSubscr);

        this.extraParameters[fAPI.name] = temp;

      }
    }

    // console.log('extraParameters', this.extraParameters);

  }

  initializePage(): void {

    // console.log('initializePage()', this.resources);

    // set the flags indicating which templates we do or do not have...

    if (this.selectedReport) {

      const res: any = this.selectedReport.asParms;

      // console.log('selectedReport', this.selectedReport, res);

      this.hasDocxTemplate = res.docx;
      this.hasXlsxTemplate = res.xlsx;
      this.hasXlsmTemplate = res.xlsm;
      this.hasGhtmlTemplate = res.ghtml;

      ///////////////////////////////////////////////////////
      // build fields for indicating export file formats...
      // (i.e. exportFileExtension, templateFileExtension)
      ///////////////////////////////////////////////////////

      this.exportFileExtension = new WSelect('exportFileExtension', 'txt', 'string');
      this.exportFileExtension.displayComponent = 'radio';
      this.exportFileExtension.select = 'txt|xml|xlsx';

      // console.log('exportFileExtension', this.exportFileExtension);

      this.templateFileExtension = new WSelect('templateFileExtension', null, 'string');
      this.templateFileExtension.displayComponent = 'radio';

      const temp: string [] = [];
      if (this.hasDocxTemplate) {
        temp.push('docx');
      }
      if (this.hasXlsxTemplate) {
        temp.push('xlsx');
      }
      if (this.hasXlsmTemplate) {
        temp.push('xlsm');
      }

      if (temp.length > 0) {
        this.templateFileExtension.select = temp.join('|');
        this.templateFileExtension.value = temp[0];
      }

    }

    // now trigger the screen height calculations...
    this.userInterfaceService.screenSizeChange.next();

  }

  postPageEvent(): void {

    this.selectedReport = this.userInterfaceService.getSelectedResource(this.eventHandler);

    // IMPORTANT NOTE: If there IS a selectedReport, then UISvc._firePageEvent() already applied the
    // parameters from that selected report to the page Event that we're responding to now...

    // console.log('Reports.postPageEvent() - ' + this.eventHandler + (this.selectedReport === null ? '' : ' - ' + this.selectedReport.reportName.value), this.event, this.resources);

    // now initialize the page for the new event information...

    this.initializePage();
  }

  selectReport(report: WResource): void {

    // add in any non-null extra parms that we might need...
    report.addAllFields(this.extraParameters);

    report.keyField.displayValue = report.reportName.value;
    this.userInterfaceService.selectResource(this.eventHandler, report);

    // we clear this so the reports themselves don't show on the page as "report results"
    this.resources = null;

    // console.log('Reports.selectReport() - about to run report', this.eventHandler, 'list', report.asParms);

    this.modalDialogService.showPleaseWait('Running Report: ' + report.keyField.displayValue, true);

    this.userInterfaceService.loadPage(this.eventHandler, 'list', report.asParms);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Report result button bar methods...
  /////////////////////////////////////////////////////////////////////////////

  export(): void {
    // console.log('ReportResultsComponent.export()');

    if (this.exportFileExtension.value !== null) {

      // load non-null extra parms...
      const report: WResource = this.eventServerService.newResource(this.eventHandler);
      report.addAllFields(this.extraParameters);

      // we change the form data at click time...
      // because we want to only export the selected item (if one is selected...)
      let parms: any = report.asParms;

      const reportKeyField = this.selectedReport.keyField;
      parms[reportKeyField.name] = reportKeyField.value;

      parms.fileExtension = this.exportFileExtension.value;

      // all the other foreign keys are already selected for this page...
      // so we put them in to filter the export results by all pertinent foreign keys...
      parms = this.userInterfaceService.applyCurrentSelectionsToParms(parms, this.eventHandler);

      // console.log('export parms', parms);

      // OK, do the actual file download...

      this.eventServerService.downloadFile(this.eventHandler, 'export', parms);

    } else {
      this.modalDialogService.showAlert('Please indicate which you prefer by selecting an export type.', 'What type of file do you want?');
    }
  }

  populateTemplate(): void {

    // console.log('ReportResultsComponent.populateTemplate()');

    if (this.templateFileExtension.value !== null) {

      // load non-null extra parms...
      const report: WResource = this.eventServerService.newResource(this.eventHandler);
      report.addAllFields(this.extraParameters);

      // we change the form data at click time...
      // because we want to only export the selected item (if one is selected...)
      let parms: any = report.asParms;

      const reportKeyField = this.selectedReport.keyField;
      parms[reportKeyField.name] = reportKeyField.value;

      // Now these are the only real differences from a "regular" export... (aside from the supported file extensions...)
      parms.templateExtension = this.templateFileExtension.value; // instead of setting fileExtension...
      parms.templateName = parms.reportName;
      parms.applyTemplate = true;

      // all the other foreign keys are already selected for this page...
      // so we put them in to filter the export results by all pertinent foreign keys...
      parms = this.userInterfaceService.applyCurrentSelectionsToParms(parms, this.eventHandler);

      // console.log('populate template parms', parms);

      // OK, do the actual file download...

      this.eventServerService.downloadFile(this.eventHandler, 'export', parms);

    } else {
      this.modalDialogService.showAlert('Please indicate which you prefer by selecting the template type.', 'No Template Format Selected');
    }
  }

  viewGraph(): void {
    // console.log('ReportResultsComponent.viewGraph()', this.selectedReport);

    const parms = { templateName: this.selectedReport.reportName.value, templateExtension: 'ghtml'};

    this.eventServerService.fireEvent('ReportTemplates', 'list', parms).subscribe(
        (event: WEvent) => {
          // console.log('ReportResultsComponent.viewGraph()', event);
          if (event.status === 'OK') {
            if (event.resources) {
              if (event.resources.length === 1) {
                const reportTemplate = event.resources[0];
                // console.log('ReportResultsComponent.viewGraph()', reportTemplate);
                const bf: WBinary = reportTemplate.templateFileContent;

                const callBackSubject = new Subject<void>();

                const callbackSubscription = callBackSubject.subscribe(
                  () => {
                    try {
                      // we now SHOULD have the actual content of the template in bf.text

                      callbackSubscription.unsubscribe();

                      // debug exammple...
                      // const wChartJSON = {
                      //       eventHandler: 'Reports',
                      //       action: 'list',
                      //       parameters: { reportName: 'AAA'},
                      //       title: 'Contracts Closed (wChartJSON)',
                      //       defaultTextContent: 'No data found for AAA...'
                      //       subTitle: 'By Month, Year-on-Year',
                      //       chartType: 'bar',
                      //       yAxisLabel: 'Contracts Closed',
                      //       xAxisLabelField: 'month',
                      //       applySelectedKeys: false,
                      //       debug: false
                      //   };

                      const wChartJSON = JSON.parse(bf.text);

                      wChartJSON.eventHandler = this.eventHandler;
                      wChartJSON.action = 'list';
                      wChartJSON.parameters = { reportName: this.selectedReport.reportName.value },
                      wChartJSON.defaultTextContent = 'No data found for ' + this.selectedReport.reportName.value + '...';

                      if (!wChartJSON.title) {
                        wChartJSON.title = this.selectedReport.reportName.value;
                      }

                      // console.log('ReportResultsComponent.viewGraph()', wChartJSON);

                      this.modalDialogService.showModalChart(wChartJSON);

                    } catch (ex) {
                      const msg = 'Reports.viewGraph()\n';
                      this.modalDialogService.showAlert(msg + '\n' + JSON.stringify(ex), 'Error generating chart from GHTML');
                    }
                  }
                );

                bf.getBinaryFileContentFromServer(this.eventServerService, this.modalDialogService, this.utilityLibService, true, callBackSubject);

              } else {
                this.modalDialogService.showAlert('Invalid number of matching templates: ' + event.resources.length, 'Error loading GHTML template...');
              }
            } else {
              this.modalDialogService.showAlert('No templates found', 'Error loading GHTML template...');
            }
          } else {
            this.modalDialogService.showAlert(event.message, 'Error loading GHTML template...');
          }
        }
      );

  }

  cancel(): void {
    this.userInterfaceService.unSelectResource(this.eventHandler, true);
  }


}
