<div *ngIf="visible">
    <div class="modal-backdrop" (click)="onClose()"></div>
    <div class="modal-box minWidthViewPort20">
        <p class="bold">{{ title }}</p>
        <div class="scrollable-y maxHeightViewPort50 paddingQuarterEm">
            <p [innerHTML]="message | safeHtml"></p>
        </div>
        <div class="marginTopWholeEm floatGroup">
            <div class="floatRight">
                <button class="btn btn-secondary paddingLeftWholeEm paddingRightWholeEm marginRightWholeEm" (click)="onClose()" wackadooAutoFocus>Close</button>
            </div>
        </div>
    </div>
</div>
