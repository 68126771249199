import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-policies',
  templateUrl: './policies.component.html',

})
export class PoliciesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
