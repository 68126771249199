import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { UserAuthService } from './user-auth.service';
import { User } from '../data/user.model';
import { EventServerService } from './event-server.service';
import { WEvent } from '../data/event.model';
import { ModalDialogService } from './modal-dialog.service';
import { UserInterfaceService } from './user-interface.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService implements OnDestroy {

  private _statusIntervalInSeconds = 5;
  private _statusIntervalID = null;

  private _previouslyShownSystemMessage = '';
  private _previousInputDisabledStatus = false;

  requestStatus: Subject<string>;
  applicationStatus: Subject<string>;
  applicationProgress: Subject<string>;
  accountStatus: Subject<string>;

  private _userAuthSubscription: Subscription;
  private _showPleaseWaitSubscription: Subscription = null;

  constructor(
    eventServerService: EventServerService,
    userAuthService: UserAuthService,
    public modalDialogService: ModalDialogService,
    public userInterfaceService: UserInterfaceService,
  ) {
    this.requestStatus = new Subject<string>();
    this.applicationStatus = new Subject<string>();
    this.applicationProgress = new Subject<string>();
    this.accountStatus = new Subject<string>();

    this.init(eventServerService, userAuthService);
  }

  /*
    Not sure why, but regular Service injection in the constructor did not work. This
    subscription never fired. To get it to work, we called this explicitly from UIService's
    constructor. Our best guess is that this is some kind of Service constructor order issue.

    Not sure why (in hindsight...) we didn't use ngOnInit() instead.
  */
  init(eventServerSvc: EventServerService, userAuthSvc: UserAuthService): void {
    // console.log('statusSvc.init()');
    this._userAuthSubscription = userAuthSvc.currentUser.subscribe(
      (user: User) => {
        // console.log(user);
        if (user && userAuthSvc.isLoggedIn) {
          this.enableStatusUpdate(eventServerSvc, userAuthSvc);
        } else {
          this.disableStatusUpdate();
        }
      }
    );

    this._showPleaseWaitSubscription = this.modalDialogService.showPleaseWaitSubject.subscribe(
      (showModal: boolean|string) => {
        // console.log('StatusService._showPleaseWaitSubscription() - showModal: ' + showModal);
        this._statusIntervalInSeconds = (typeof showModal === 'boolean' ? (showModal ? 1 : 5) : 1);
      }
    );
  }

  ngOnDestroy(): void {
    this.disableStatusUpdate();
    if (this._userAuthSubscription) {
      this._userAuthSubscription.unsubscribe();
    }
    if (this._showPleaseWaitSubscription) {
      this._showPleaseWaitSubscription.unsubscribe();
    }
  }

/***********************************************************
 *  This is the status-monitoring code...
 **********************************************************/

  disableStatusUpdate(): void {
    // console.log('disableStatusUpdate()');
    if (this._statusIntervalID) {
      window.clearInterval(this._statusIntervalID);
      this._statusIntervalID = null;
    }
  }

  enableStatusUpdate(eventServerSvc: EventServerService, userAuthSvc: UserAuthService): void {
    // console.log('enableStatusUpdate()');
    if (this._statusIntervalID) {
      // if we already have one, turn off the dynamic status update... (should be moot, but...)
      window.clearInterval(this._statusIntervalID);
      this._statusIntervalID = null;
    }
    this._statusIntervalID = window.setInterval(this.trackStatus(eventServerSvc, userAuthSvc), this._statusIntervalInSeconds * 1000);
  }

  trackStatus(eventServerSvc: EventServerService, userAuthSvc: UserAuthService): TimerHandler {
    // console.log('defining trackStatus()');
    return(
      () => {
        // console.log('trackStatus()');
        if (userAuthSvc.isLoggedIn) {

          const parms = {
            name: 'Event Handler Network',
            skipServerStatus: 'true'
          };

          // console.log('trackStatus() - calling Administration.getStatus()');

          eventServerSvc.fireEvent('Administration', 'getStatus', parms).subscribe(
            (event: WEvent) => {
              // console.log(event);
              const res = event.resources[0].asParms;

              // console.log('trackStatus()', res);

              this.requestStatus.next(res.requestStatus);
              this.applicationStatus.next(res.applicationStatus);
              this.applicationProgress.next(res.applicationProgress);
              this.accountStatus.next(res.accountStatus);
              this.setSystemStatus(res.systemStatus, res.systemStatusTimeStamp);

              this.autoTogglePleaseWaitWhenInputDisabledOrEnabled(res.inputDisabled);

            }
          );
        }
      }
    );
  }

  setSystemStatus(msg: string, timestamp?: string): void {
    const emptyMessage = (!msg || (msg === ''));
    const differentMessage = (msg && (msg !== this._previouslyShownSystemMessage));

    // We calculate showDialog like this to allow the user to close the dialog
    // once they have seen a message, and NOT see that same message again...
    const showSystemMessageDialog = !emptyMessage && differentMessage;

    // console.log('StatusService.setSystemStatus()', '' + msg + '/' + emptyMessage + '/' + differentMessage + '/' + showDialog);

    if (showSystemMessageDialog) {
      this.modalDialogService.showSystemMessage(msg, timestamp);
      this._previouslyShownSystemMessage = msg;
    }
  }

  autoTogglePleaseWaitWhenInputDisabledOrEnabled(inputDisabled: boolean): void {
    const inputDisabledStatusHasChanged = (inputDisabled !== this._previousInputDisabledStatus);

    // console.log('StatusService.autoTogglePleaseWaitWhenInputDisabledOrEnabled(' + inputDisabled + ')', 'input status changed? ' + inputDisabledStatusHasChanged);

    // We auto-toggle the "please wait" on/off when the input status changes...
    // (i.e. occurs when backup and restore cmds start/finish...)
    if (inputDisabledStatusHasChanged) {
      this.modalDialogService.showPleaseWait(inputDisabled);
      this._previousInputDisabledStatus = inputDisabled;

      // if we just turned OFF the auto-please-wait, then we reload the current page...
      if (!inputDisabled) {
        this.userInterfaceService.reloadCurrentPage();
      }
    }
  }

}
