import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-new-customer',
  templateUrl: '../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class NewCustomerComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {

    this.gridButtonBarComponentClassName = 'NewCustomerGridButtonBarComponent';

    // The sign-up process for a customer of a single app (noted in "this.userInterfaceService.previousApplication") is different
    // from the old, soon to be obsolete (and deleted...) "app-less / pre-app-selection" account sign up process
    // (i.e. if we know the app, we take the credit card right there, and there's no "click on the link" verification step for the new customer...)

    const prevApp = this.userInterfaceService.previousApplication;

    if (prevApp && (prevApp.length > 0) && (prevApp !== 'AccountManager')) {
      this.detailContentComponentClassName = 'NewCustomerDetailContentComponent';
      this.detailButtonBarComponentClassName = 'NewCustomerDetailButtonBarComponent';
    } else {
      this.detailContentComponentClassName = 'NewAccountDetailContentComponent';
      this.detailButtonBarComponentClassName = 'NewAccountDetailButtonBarComponent';
    }

    super.ngOnInit();
  }

}
