		<div class="card">
			<div class="card-header">
				Simplicity / Ease of Use
			</div>
			<div class="card-body">
				<p>If we do our jobs correctly, you are not going to need a three-inch thick manual to figure out how to do something in one of our web apps.</p>
				<p>Nevertheless, we plan to provide a centralized, searchable help database with how-tos, FAQs, and "What were they thinking?!" 
				entries for each application.</p>
				<p>To paraphrase Bill Buxton, the trick is to present increasingly complex information and functionality in a simple enough manner to fit 
				within the finite limits of human capacity.</p>
				<div class="hidden">
					(insert graph here...)
					<ul>
						<li>
							Ever increasing complexity of technology. (geometric/exponential growth curve)
						</li>
						<li>
							Fixed level of human capacity of complexity. (fixed horizontal line)
						</li>
						<li>
							The trick is to present/enable the technology in a simple enough manner that MORE complex tech becomes SIMPLE enough to fit under the fixed horizontal line of human capacity.
						</li>
					</ul>
				</div>
			</div>
		</div>
