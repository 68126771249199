import { Directive, ElementRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[wackadooResourceModalPlaceholder]'
})
export class ResourceModalPlaceholderDirective {

  constructor(
    public elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef,
  ) { }

}
