		<div class="card">
			<div class="card-header">
				Backup Policy...
			</div>
			<div class="card-body lightred-bg">
				<div class="red bold italic">Backing up YOUR data is YOUR responsibility!</div>
				<p>
					<span class="bold italic">WE DO NOT KEEP</span> a backup of your data <span class="bold italic">FOR YOU</span>.
				</p>
				<p>
					Backing up your data is easy. Simply click the "Backup/Restore" button within that app, and click the Backup button to
					save the time-stamp-named file that you get.
				</p>
				<p>
					You are responsible for backing up your data after any significant amount of work has been done on that web app.
				</p>
				<p>
					Our server maintenance procedures run on a daily basis, and automatically delete data when 
					registered applications have been CANCELLED or have EXPIRED.  So, if you do not back up your 
					data, and you decide to cancel a web app, or let it expire, then 
					<span class="bold italic">YOUR DATA IS GONE</span> because 
					<span class="bold italic">WE DO NOT KEEP</span> a backup of your data <span class="bold italic">FOR YOU</span>.
				</p>
				<p>
					Later on, should you decide to re-purchase that web app, you can "restore" your data from 
					any of YOUR backup files.  Just don't expect your data to still be on the server, because 
					<span class="bold italic">WE DO NOT KEEP</span> a backup of your data <span class="bold italic">FOR YOU</span>.
				</p>
				<p>
					SOME TIPS:
				</p>
				<ol>
					<li>
						We recommend daily backups.
					</li>
					<li>
						Each backup zip file name indicates the date and time it was created, so you can save 
						all your backup files in a single file directory and easily see when each was created.
					</li>
					<li>
						Be sure to coordinate who is doing backup/restore operations within your organization, 
						as multiple people might inadvertently cause data to be lost.
						(For example, consider the situation where Ralph does a backup at 5pm, but then Ed works late
						and does a backup at 9pm. If Ralph restores from his 5pm backup file at 10pm, all the work Ed did
						after 5pm would be lost.)
					</li>
				</ol>
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				Data Retention Policy...
			</div>
			<div class="card-body">
				<div class="bold italic">Disaster recovery is OUR responsibility!</div>
				<p>
					We backup our system daily, but for <span class="bold italic">SYSTEM-LEVEL, DISASTER-RECOVERY PURPOSES ONLY</span>, 
					where "disaster" is defined as a hardware, software, operational or environmental failure.
				</p>
				<p>
					Our automated disaster-recovery backups run at approximately 5AM local time,
					with the files being deleted on a rolling basis over time. In the event of 
					a disaster we are able to recover to the current state of all customer data 
					as of the "morning backup" for any of the following:
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						Each day for the last two weeks.
					</li>
					<li>
						Each Sunday morning for the last calendar month.
					</li>
					<li>
						The first of each month for the last 3 calendar months.
					</li>
				</ul>
				<p>
					Other aspects of our Data Retention Policy... 
				</p>
				<ul>
					<li>
						We delete our log files after 3 months.
					</li>
					<li>
						We delete our usage records after 6 months.
					</li>
				</ul>
			</div>
		</div>
