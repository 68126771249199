import { Subscriber } from 'rxjs';

export class SearchModalContent {

  observer: Subscriber<boolean|string>;
  ehName: string;

  constructor(observer: Subscriber<boolean|string>, ehName: string) {
    this.observer = observer;
    this.ehName = ehName;
  }
}

