import { Directive, ElementRef, Renderer2, Input, OnInit, HostListener, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { UserInterfaceService } from '../../../services/user-interface.service';

@Directive({
  selector: '[wackadooImportTemplateReportTextArea]'
})
export class ImportTemplateReportTextAreaDirective implements OnInit, OnChanges {

  @Input() onSelectSubject: Subject<any>;
  @Input() debug = false;

  @Input() initialSelectionStart: number = null;
  @Input() initialSelectionEnd: number = null;

  @HostListener('select', ['$event']) select = this.handleSelectEvent;
  @HostListener('drop', ['$event']) filedrop = this.handleDropEvent;

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public modalDialogService: ModalDialogService,
    public userInterfaceService: UserInterfaceService,
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(sc: SimpleChanges): void {
    if (this.debug) { console.log('directive.ngOnChanges()', sc, this.initialSelectionStart, this.initialSelectionEnd); }

    if (this.initialSelectionStart && this.initialSelectionEnd) {
      const textarea = this.elementRef.nativeElement;

      // This fires the underlying "select" event in the DOM...

      // this.renderer.setProperty(textarea, 'selectionStart', this.initialSelectionStart);
      // this.renderer.setProperty(textarea, 'selectionEnd', this.initialSelectionEnd);

      textarea.selectionStart = this.initialSelectionStart;
      textarea.selectionEnd = this.initialSelectionEnd;

      // ...but does NOT "show" the selection in the UX until the textarea has focus.
      textarea.focus();

    }
  }

  handleDropEvent(e: any): void {

    const files = e.dataTransfer.files; // FileList object.
    if (!files || (files.length !== 1) || !(files[0] instanceof Blob)) {
      e.stopPropagation();
      e.preventDefault();
    }

  }

  handleSelectEvent(): void {

    try {

      const textarea = this.elementRef.nativeElement;

      const selectionOffset = textarea.selectionStart;
      let selectionLength = textarea.selectionEnd - textarea.selectionStart;

      const content = textarea.value;
      let selectedText = content.substr(selectionOffset, selectionLength);

      if (this.debug) { console.log('directive.handleSelectEvent()\nselectionStart: ' + textarea.selectionStart + '\nselectionEnd: ' + textarea.selectionEnd + '\nselectionLength: ' + selectionLength + '\nselectedText: ' + selectedText); }

      if (selectedText.indexOf('\n') > -1) {
        // first un-select everything...
        this.renderer.setProperty(textarea, 'selectionStart', 0);
        this.renderer.setProperty(textarea, 'selectionEnd', 0);
        // then throw the error
        throw new Error('Cannot select across multiple lines...');
      }
      const row = content.substr(0, selectionOffset).split('\n').length - 1;

      const allRows = content.split('\n');
      const selectedRow = allRows[row];

      let selectedRowOffset = 0;
      for (let i = 0; i < row; i++) {
        selectedRowOffset += (allRows[i].length + 1); // the +1 is for the \n char used in the split() call...
      }

      let column = null;

      if (selectedRow.indexOf('\t') >= 0) {

        const allColumns = selectedRow.split('\t');
        // console.log(JSON.stringify(allColumns));

        let selectionOffsetWithinThisRow = 0;
        for (let j = 0; j < allColumns.length; j++) {
          column = j;
          selectedText = allColumns[j];
          selectionLength = selectedText.length;

          const columnLength = allColumns[j].length;
          selectionOffsetWithinThisRow += (columnLength + 1); // the +1 is for the \t char used in the split() call...

          // console.log('selectionOffsetWithinThisRow for tab ' + j + ': ' + selectionOffsetWithinThisRow);
          if (selectionOffsetWithinThisRow > (selectionOffset - selectedRowOffset)) {
            break;
          }
        }

      } else {
        column = selectionOffset - selectedRowOffset;
      }

      // console.log('directive.handleSelectEvent()', 'selectedText: "' + selectedText + '"', 'row: ' + row, 'column: ' + column, 'selectionLength: ' + selectionLength, 'selectedRowOffset: ' + selectedRowOffset, 'selectedRow: "' + selectedRow + '"');

      /////////////////////////////////////////////////////////////////////////////
      // NOW... we have to DO something with all that pertinent information...
      /////////////////////////////////////////////////////////////////////////////

      const payload: any = {};
      payload.selectedText = selectedText;
      payload.selectedRow = selectedRow;
      payload.row = row;
      payload.column = column;
      payload.selectionLength = selectionLength;

      if (this.debug) { console.log('directive.handleSelectEvent()', 'payload:', payload); }

      if (this.onSelectSubject) {
        this.onSelectSubject.next(payload);
      }

    } catch (ex) {
      this.userInterfaceService.alertUserToException(ex, 'handleSelectEvent()');
    }
  }

}
