import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// app-specific pages
import { BillingComponent } from './pages/billing/billing.component';
import { EMailErrorWebHookComponent } from './pages/email-error-web-hook/email-error-web-hook.component';
import { AdministrationComponent } from './pages/administration/administration.component';
import { EventBroadcasterComponent } from './pages/event-broadcaster/event-broadcaster.component';
import { BackupCleanupServiceComponent } from './pages/backup-cleanup-service/backup-cleanup-service.component';
import { LogCleanupServiceComponent } from './pages/log-cleanup-service/log-cleanup-service.component';
import { DbConnectionPoolComponent } from './pages/db-connection-pool/db-connection-pool.component';
import { LogExtractorComponent } from './pages/log-extractor/log-extractor.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { UsersComponent } from './pages/users/users.component';
import { RegisteredApplicationsComponent } from './pages/registered-applications/registered-applications.component';
import { RolesComponent } from './pages/roles/roles.component';
import { NewCustomerComponent } from './pages/new-customer/new-customer.component';
import { NewCustomerNotValidatedComponent } from './pages/new-customer/new-customer-not-validated/new-customer-not-validated.component';
import { NewCustomerCancelledComponent } from './pages/new-customer/new-customer-cancelled/new-customer-cancelled.component';
import { NewCustomerNotCancelledComponent } from './pages/new-customer/new-customer-not-cancelled/new-customer-not-cancelled.component';


///////////////////////////////////////
// NOW set the routes...
///////////////////////////////////////

const routes: Routes = [
  // app-specific page routes
  { path: 'Billing', component: BillingComponent, pathMatch: 'full' },
  { path: 'EMailErrorWebHook', component: EMailErrorWebHookComponent, pathMatch: 'full' },
  { path: 'Administration', component: AdministrationComponent, pathMatch: 'full' },
  { path: 'EventBroadcaster', component: EventBroadcasterComponent, pathMatch: 'full' },
  { path: 'BackupCleanupService', component: BackupCleanupServiceComponent, pathMatch: 'full' },
  { path: 'DbConnectionPool', component: DbConnectionPoolComponent, pathMatch: 'full' },
  { path: 'LogCleanupService', component: LogCleanupServiceComponent, pathMatch: 'full' },
  { path: 'LogExtractor', component: LogExtractorComponent, pathMatch: 'full' },
  { path: 'Pricing', component: PricingComponent, pathMatch: 'full' },
  { path: 'Accounts', component: AccountsComponent, pathMatch: 'full' },
  { path: 'Users', component: UsersComponent, pathMatch: 'full' },
  { path: 'RegisteredApplications', component: RegisteredApplicationsComponent, pathMatch: 'full' },
  { path: 'Roles', component: RolesComponent, pathMatch: 'full' },
  { path: 'NewCustomer', component: NewCustomerComponent, pathMatch: 'full' },
  { path: 'newCustomerNotValidated', component: NewCustomerNotValidatedComponent, pathMatch: 'full' },
  { path: 'newCustomerCancelled', component: NewCustomerCancelledComponent, pathMatch: 'full' },
  { path: 'newCustomerNotCancelled', component: NewCustomerNotCancelledComponent, pathMatch: 'full' },
];

@NgModule({
  declarations: [],
  imports: [
     RouterModule.forChild(routes)
   ],
  exports: [
    RouterModule
  ]
})

export class AccountManagerCoreRoutingModule {
}
