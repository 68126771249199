import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { WResource } from '../../data/resource.model';
import { PageComponent } from '../page/page.component';
import { UserAuthService } from '../../services/user-auth.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { EventServerService } from '../../services/event-server.service';
import { ModalDialogService } from '../../services/modal-dialog.service';
import { WEvent } from '../../data/event.model';
import { Globals } from '../../services/global.service';
import { Subscription, Subject } from 'rxjs';
import { SelectedResource } from '../../ui/main/current-selection/selected-resource.model';
import { WString, WField, WSelect } from '../../data/field.model';

@Component({
  selector: 'wackadoo-bulk-e-mailer',
  templateUrl: './bulk-e-mailer.component.html',

})
export class BulkEMailerComponent extends PageComponent implements OnInit, OnDestroy {

  reports: WResource [] = [];

  selectedReport: WResource = null;
  selectedEMailTemplate: WResource = null;

  extraParameters: WResource = null;
  extraParameterSubjects: Subject<any> [] = [];
  extraParameterSubscriptions: Subscription [] = [];

  domain: string = null;
  accountName: string = null;

  selectionChangeSubscription: Subscription = null;

  showReportResults = true;
  emailAddresses = '';
  reportResults: WResource [] = null;

  testEmailAddress: WString = null;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
    public elementRef: ElementRef,
  ) {
    super(userAuthService, userInterfaceService);
   }

  ngOnInit(): void {
    super.ngOnInit();

    this.domain = Globals.domain;
    this.accountName = this.user.accountName;

    this.testEmailAddress = new WString('testEmailAddress', '');
    this.testEmailAddress.length = 48;

    this.userInterfaceService.unSelectResource('Reports');
    this.userInterfaceService.unSelectResource('EMailTemplates');
    this.userInterfaceService.unSelectResource('BulkEMailer');  // This one should be a NOP...

    this.buildExtraParametersResource();

    this.clearReportResults();

    this.selectionChangeSubscription = this.userInterfaceService.selectionChange.subscribe(
      (sr: SelectedResource) => {
        if (sr && (sr.resource === null)) {
          if (sr.ehName === 'Reports') {
            this.selectedReport = null;
            this.clearReportResults();

          } else if (sr.ehName === 'EMailTemplates') {
            this.selectedEMailTemplate = null;
          }
        }
      }
    );

    let reportParameters = {fieldName: 'email', pageSize: -1};

    reportParameters = this.userInterfaceService.applyCurrentSelectionsToParms(reportParameters, 'Reports');

    this.eventServerService.fireEvent('Reports', 'filterList', reportParameters).subscribe(
      (event: WEvent) => {
        try {
          // console.log('BulkEMailer.Reports.filterList()', event);

          this.reports = event.resources;

        } catch (ex) {
          this.userInterfaceService.alertUserToException(ex, 'BulkEMailer.postReportFilterListCallback()');
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.extraParameterSubscriptions.length > 0) {
      for (const eps of this.extraParameterSubscriptions) {
        eps.unsubscribe();
      }
    }
    if (this.selectionChangeSubscription) {
      this.selectionChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  buildExtraParametersResource(): void {

    this.extraParameters = new WResource();

    const fieldAPIs = this.eventServerService.getFieldDefinitionsInOrder('Reports'); // because "this.eventHandler" is not yet set...

    // console.log('BulkEMailer.buildExtraParametersResiurce()', fieldAPIs);

    for (const fAPI of fieldAPIs) {
      if ((fAPI.name !== 'id') && (fAPI.name !== 'reportName') && (fAPI.name !== 'sql') && (fAPI.name !== 'userQueryParms') && !fAPI.foreignKey) {

        // console.log('BulkEMailer.buildExtraParametersResiurce()', fAPI);

        let defaultValueFromSelectedReport = null;

        if (this.selectedReport
            && this.selectedReport[fAPI.name]
            && (this.selectedReport[fAPI.name].value !== null)
        ) {
          defaultValueFromSelectedReport = this.selectedReport[fAPI.name].value;
        }

        let temp = WField.factory(fAPI.type, fAPI.name, defaultValueFromSelectedReport, fAPI.eventHandler, fAPI);

        // special handling for the "year" field for force it to be a WSelect Field...

        if (fAPI.name === 'year') {
          const thisYear = (new Date()).getFullYear();
          temp = new WSelect(fAPI.name, (defaultValueFromSelectedReport === null ? thisYear : defaultValueFromSelectedReport) , 'Reports', fAPI);
          temp.select = '';
          for (let i = 0; i < 25; i++) {
            temp.select += ((thisYear - i) + (i < 24 ? '|' : ''));
          }
        }

        // special handling for "month" field to force full month names...
        // Note: month.select is (as of this writing...) already defined with 3 letter abbreviations.
        // (We should go back and change them to their proper full names in the resource defn files,
        // but we hacked it here instead...)

        if (fAPI.name === 'month') {
          if (!temp.value) {
            const thisMonth = (new Date()).getMonth() + 1;
            temp.value = (defaultValueFromSelectedReport === null ? thisMonth : defaultValueFromSelectedReport);
          }
          temp.select = '1|2|3|4|5|6|7|8|9|10|11|12';
          temp.selectLabels = 'January|February|March|April|May|June|July|August|September|October|November|December';
        }

        // wire up a Subject and Subscription for use by the extraParameter's field component...

        const tempSubj = new Subject<boolean>();

        const tempSubscr = tempSubj.subscribe(
          () => {
          // we don't need the value in this case...
          // (value: any) => {
            // console.log('field changed...', value, this.selectedReport, this.extraParameters);

            if (this.selectedReport !== null) {
              // set up a blank report Resource
              const report: WResource = this.eventServerService.newResource('Reports');

              // add in the non-null selected report fields...
              report.addAllFields(this.selectedReport);

              // add in the non-null NEWLY MODIFIED extra parms...
              report.addAllFields(this.extraParameters);

              // and fire the UPDATED report request...
              this.userInterfaceService.selectResource('Reports', report);
              // NOTE: Here's where we differ from ReportsComponent.buildExtraParametersResource()
              this.processReport(report);
            }
          }
        );

        this.extraParameterSubjects.push(tempSubj);
        this.extraParameterSubscriptions.push(tempSubscr);

        this.extraParameters[fAPI.name] = temp;

      }
    }

    // console.log('BulkEMailer.buildExtraParametersResiurce()', this.extraParameters);

  }

  clearReportResults(): void {
    this.showReportResults = true;
    this.emailAddresses = '';
    this.reportResults = null;
  }

  clickReport(resource: WResource): void {
    // console.log('Clicked on report: ' + JSON.stringify(resource.simplifyResource().reportName));
    resource.keyField.displayValue = resource.reportName.value;

    // add in the non-null extra parms...
    resource.addAllFields(this.extraParameters);

    this.selectedReport = resource;

    this.userInterfaceService.selectResource('Reports', resource);
    this.processReport(resource);
  }

  clickEMailTemplate(resource: WResource): void {
    // console.log('Clicked on email template: ' + JSON.stringify(resource.simplifyResource().templateName));
    this.selectedEMailTemplate = resource;
    this.userInterfaceService.selectResource('EMailTemplates', resource);
  }

  toggleEMailAddresses(): void {
    this.showReportResults = !this.showReportResults;
  }

  sendEMail(testing?: boolean): void {
    testing = typeof testing === 'boolean' ? testing : false;
    // console.log('BulkEMailer.sendEMail()', 'testing?', testing);
    try {

      if (this.userInterfaceService.getSelectedResource('Reports') === null) {
        this.modalDialogService.showAlert('Please select an EMail Report...');
        return;
      }

      if (this.userInterfaceService.getSelectedResource('EMailTemplates') === null) {
        this.modalDialogService.showAlert('Please select an EMail Template...');
        return;
      }

      if (testing && (!this.testEmailAddress || !this.testEmailAddress.isPopulated)) {
        this.modalDialogService.showAlert('Please provide a testEmailAddress...');
        return;
      }

      const message = 'Please Enter E-Mail Subject:\n(Cancel accepts the default subject...)';
      const title = 'EMail Subject';
      const placeholder = 'e-mail subject goes here';
      const defaultValue = 'Automated Message from ' + this.user.accountName;

      this.modalDialogService.showPrompt(message, title, placeholder, defaultValue).subscribe(
        (inputValue: string | boolean) => {
          try {
            if (typeof inputValue === 'boolean') {
            } else {
              let parms = this.selectedReport.asParms;
              // all required/supported fkeys are defined in Report.xml Resource defn...
              parms = this.userInterfaceService.applyCurrentSelectionsToParms(parms, 'Reports');

              parms.reportName = this.selectedReport.reportName.value;
              parms.templateName = this.selectedEMailTemplate.templateName.value;
              parms.eventUrlPrefix = this.userInterfaceService.getApplicationUrl();
              parms.fromName = this.user.accountName;

              parms.subject = inputValue;

              // add the timezone name to allow dates in the email content to be formatted correctly...

              parms.olsonIanaTimeZoneName = Globals.TIMEZONE;
              parms.includeTimeZone = 'TRUE';

              let msg = '';

              if (testing) {
                parms.testEmailAddress = this.testEmailAddress.value;
                msg += 'You are about to format and send ONE email message from the selected report to: <b>' + this.testEmailAddress.value + '</b>';
              } else {
                msg += 'You are about to format and send email to <b>EVERY RECIPIENT ON THE SELECTED LIST!</b>';
              }

              msg += '\n\n';
              msg += 'You will be using the selected <b>' + parms.templateName + '</b> email template. Examples in the system default templates show you how you can insert fields from the selected <b>' + parms.reportName + '</b> ';
              msg += 'report into the body of the email.\n\nFor single fields, simply wrap the field name like this: <i>{fieldName}</i>\n\n';
              msg += 'In order to consolidate multiple records into a single email, insert a <i>&lt;resourceBlock&gt;</i> XML element that contains the fields and XHTML formatting you wish to repeat, like this: ';
              msg += '<i>&lt;resourceBlock&gt;{field1} ${field2} {field3} {field4}&lt;/resourceBlock&gt;</i>\n\n';
              msg += 'This is your LAST CHANCE TO BAIL OUT! Do you REALLY wish to continue?';

              this.modalDialogService.showConfirm(msg, 'Double Checking...').subscribe(
                (flag: boolean) => {
                  if (flag) {
                    // console.log('BulkEMailer.sendMail(). About to fire event w/parms:', 'parms:', parms);

                    this.modalDialogService.showPleaseWait('Sending ' + (testing ? 'Test ' : 'Bulk ') + 'E-Mail...');

                    this.eventServerService.fireEvent('BulkEMailer', 'send', parms).subscribe(
                      (event: WEvent) => {
                        // console.log('BulkEMailer.sendMail(). Just fired event:', event);
                        this.modalDialogService.showPleaseWait(false);
                        this.modalDialogService.showAlert('E-Mail transmission ' + (event.status === 'OK' ? 'complete' : 'failed') + ': ' + event.message);
                      }
                    );
                  }
                }
              );

            }

          } catch (ex) {
            const msg = 'BulkEMailer.sendEMail.internalPromptCallback()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }
        }
      );

    } catch (ex) {
      const msg = 'BulkEMailer.sendEMail()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  processReport(resource: WResource): void {
    // console.log('BulkEMailer.processReport()', resource);
    try {
      if (resource) {

        this.clearReportResults();

        const res = resource.asParms;
        delete res.sql;

        // console.log('BulkEMailer.processReport()', res);

        const missingParameter = resource.identifyMissingOrInvalidField(false);

        // console.log('BulkEMailer.processReport()', 'missingParameter: ' + missingParameter);

        if (missingParameter !== null) {
          this.modalDialogService.showAlert('Missing required parameter: ' + missingParameter).subscribe(
            () => {
              this.userInterfaceService.focusOnField(this.elementRef, missingParameter);
            }
          );

        } else {

          this.userInterfaceService.selectResource('Reports', resource);

          // console.log('BulkEMailer.processReport() - just set selected resource:', this.userInterfaceService.getSelectedResource('Reports').asParms);

          let parms = resource.asParms;
          // we do NOT send these Report fields back...
          // we really only need the reportName and the individual things listed in userQueryParms...
          delete parms.sql;
          delete parms.id;
          delete parms.userQueryParms; // this is the list of parm names, not the parms themselves... so we delete it.
          parms = this.userInterfaceService.applyCurrentSelectionsToParms(parms, 'Reports');

          // Now we tell the UX to NOT display base64 content (mostly to save rendering time...)
          parms.maskBinaryContent = 'true';

          // console.log('BulkEMailer.processReport() - parms: ', parms);

          this.modalDialogService.showPleaseWait(true);

          this.eventServerService.fireEvent('Reports', 'list', parms).subscribe(
            (event: WEvent) => {
              try {
                // console.log('BulkEMailer.processReport() - event: ', event);

                if (event.status !== 'OK') {
                  throw new Error('Report failed! ' + event.message);
                }

                // "0" results... even if undefined...
                this.reportResults = [];

                if (event.resources) {

                  this.reportResults = event.resources;

                  // now populate the "just email addresses" list...

                  for (const r of this.reportResults) {
                    if (r.email && r.email.value) {
                      this.emailAddresses = this.emailAddresses + (this.emailAddresses === '' ? '' : '; ') + r.email.value;
                    }
                  }
                }

              } catch (ex) {
                const msg = 'BulkEMailer.processReport.callback()\n';
                this.userInterfaceService.alertUserToException(ex, msg);
              }

              this.modalDialogService.showPleaseWait(false);
            }
          );
        }
      }

    } catch (ex) {
      const msg = 'BulkEMailer.processReport()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
