<div *ngIf="visible">
    <div class="modal-backdrop" (click)="onCancel()"></div>
    <div class="modal-box">
        <p class="bold">{{ title }}</p>
        <div class="scrollable-y maxHeightViewPort50 paddingQuarterEm">
            <p [innerHTML]="message | safeHtml"></p>
        </div>
        <input class="form-control" type="text" [(ngModel)]="value" [placeholder]="placeholder" wackadooAutoFocus/>
        <div class="marginTopWholeEm d-flex justify-content-around">
            <div>
                <button id="ok" class="btn btn-secondary" (click)="onOK()">OK</button>
            </div>
            <div>
                <button id="close" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
