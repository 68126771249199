		<div class="card">
			<div class="card-header">
				Maintenance Policy...
			</div>
			<div class="card-body">
				<p>Our offices are located in the Eastern US time zone, so we try to schedule server 
				and/or software maintenance outside of standard business hours in the continental US. 
				(read: before 7AM or after 8PM Eastern)</p>
				<p>That being said, and this being the software business, we may need to perform 
				un-scheduled maintenance during prime US business hours.</p>
				<p>Whenever we impact availability, we will first generate a system administration message, 
				seen by all active users, indicating the nature of the work, and the expected time that 
				our services will be back online.</p>
			</div>
		</div>

