<h2>Our Policies</h2>
<wackadoo-policy-terms-and-conditions></wackadoo-policy-terms-and-conditions>
<wackadoo-policy-data-security></wackadoo-policy-data-security>
<wackadoo-policy-billing></wackadoo-policy-billing>
<wackadoo-policy-confidentiality></wackadoo-policy-confidentiality>
<wackadoo-policy-cookies></wackadoo-policy-cookies>
<wackadoo-policy-data-portability></wackadoo-policy-data-portability>
<wackadoo-policy-data-retention></wackadoo-policy-data-retention>
<wackadoo-policy-email-usage></wackadoo-policy-email-usage>
<wackadoo-policy-expired-delinquent-accts></wackadoo-policy-expired-delinquent-accts>
<wackadoo-policy-maintenance></wackadoo-policy-maintenance>
<wackadoo-policy-pricing></wackadoo-policy-pricing>
<wackadoo-policy-refunds></wackadoo-policy-refunds>
<wackadoo-policy-service-level-agreement></wackadoo-policy-service-level-agreement>
<wackadoo-policy-support></wackadoo-policy-support>
