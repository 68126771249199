<div class="marginTop6 wackadoo-detail" [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
  <div class="card">
    <div class="card-header">
      User Info
    </div>
    <div class="card-body table-responsive">
      <table>
        <tbody>
          <tr>
            <td class="right">userName</td>
            <td><wackadoo-field [f]="resource.userName" [mode]="mode"></wackadoo-field></td>
          </tr>
          <tr>
            <td class="right">password</td>
            <td><wackadoo-field [f]="resource.password" [mode]="mode"></wackadoo-field></td>
          </tr>
          <tr>
            <td class="right">firstName</td>
            <td><wackadoo-field [f]="resource.firstName" [mode]="mode"></wackadoo-field></td>
          </tr>
          <tr>
            <td class="right">lastName</td>
            <td><wackadoo-field [f]="resource.lastName" [mode]="mode"></wackadoo-field></td>
          </tr>
          <tr>
            <td class="right">email</td>
            <td><wackadoo-field [f]="resource.email" [mode]="mode"></wackadoo-field> (validated: <wackadoo-field [f]="resource.emailValidated" [mode]="mode"></wackadoo-field> <wackadoo-field [f]="resource.emailValidatedDate" mode="read"></wackadoo-field>)</td>
          </tr>
          <tr>
            <td class="right">phone</td>
            <td><wackadoo-field [f]="resource.phone" [mode]="mode"></wackadoo-field></td>
          </tr>
<!--
          <tr>
            <td class="right">memberID</td>
            <td><wackadoo-field [f]="resource.memberID" [mode]="mode"></wackadoo-field></td>
          </tr>
-->
          <tr>
            <td class="right">accountID</td>
            <td><wackadoo-field [f]="resource.accountID" [mode]="mode"></wackadoo-field></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div>
    <div class="card">
      <div class="card-header">
        Login Info
      </div>
      <div class="card-body table-responsive">
        <table>
          <tbody>
            <tr>
              <td class="right">lastAccess</td>
              <td><wackadoo-field [f]="resource.lastAccess" [mode]="mode"></wackadoo-field></td>
            </tr>
            <tr>
              <td class="right">lastAttempt</td>
              <td><wackadoo-field [f]="resource.lastAttempt" [mode]="mode"></wackadoo-field></td>
            </tr>
            <tr>
              <td class="right">failedAttempts</td>
              <td><wackadoo-field [f]="resource.failedAttempts" [mode]="mode"></wackadoo-field></td>
            </tr>
            <tr>
              <td class="right">locked</td>
              <td><wackadoo-field [f]="resource.locked" [mode]="mode"></wackadoo-field></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
<!--
    <div class="card">
      <div class="card-header">
        Security 
      </div>
      <div class="card-body table-responsive">
        <table>
          <tbody class="top">
            <tr>
              <td class="right">forcePWChange</td>
              <td><wackadoo-field [f]="resource.forcePWChange" [mode]="mode"></wackadoo-field></td>
            </tr>
            <tr class="marginTopHalfEm">
              <td colspan="3" class="">
                Questions for Personal Identification
              </td>
            </tr>
            <tr>
              <td class="right"></td>
              <td>question</td>
              <td>answer</td>
            </tr>
            <tr>
              <td class="right">#1</td>
              <td><wackadoo-field [f]="resource.question1" [mode]="mode"></wackadoo-field></td>
              <td><wackadoo-field [f]="resource.answer1" [mode]="mode"></wackadoo-field></td>
            </tr>
            <tr>
              <td class="right">#2</td>
              <td><wackadoo-field [f]="resource.question2" [mode]="mode"></wackadoo-field></td>
              <td><wackadoo-field [f]="resource.answer2" [mode]="mode"></wackadoo-field></td>
            </tr>
            <tr>
              <td class="right">#3</td>
              <td><wackadoo-field [f]="resource.question3" [mode]="mode"></wackadoo-field></td>
              <td><wackadoo-field [f]="resource.answer3" [mode]="mode"></wackadoo-field></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
-->
  </div>
</div>
