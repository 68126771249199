import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-about-technology',
  templateUrl: './about-technology.component.html',

})
export class AboutTechnologyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
