import { Directive, ElementRef, Renderer2, Input, OnInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { FileContentDescriptor } from './file-content-descriptor.model';
import { ModalDialogService } from '../services/modal-dialog.service';
import { UserInterfaceService } from '../services/user-interface.service';

@Directive({
  selector: '[wackadooFileDrop]'
})
export class FileDropDirective implements OnInit {

  @Input() validExtensions: string = null;
  @Input() maxFileSizeMB = -1;

  @Input() fileDropSubject: Subject<FileContentDescriptor>;

  // this is the "drop" portion of the drag-n-drop mechanism...
  @HostListener('drop', ['$event']) drop = this.handleFileDrop;

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2,
    public modalDialogService: ModalDialogService,
    public userInterfaceService: UserInterfaceService,
  ) {}

  ngOnInit(): void {
  }

  handleFileDrop(e: any): void {
    try {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files; // FileList object.
      if (files === null) {
        throw new Error('No file (' + files + ') selected!');
      } else if (files.length > 1) {
        throw new Error('Too many files (' + files.length + ') selected! (Only 1 is allowed.)');
      }
      const file = files[0];

      if (file instanceof Blob) {
        // console.log('handleFileSelect() - ' + file.name);

        // this.modalDialogService.showAlert('Trying to drop file: ' + file.name, 'Good Drop!');

        const reader = new FileReader();
        reader.onload = this._readerOnLoad(this.userInterfaceService, file, this.validExtensions, this.maxFileSizeMB, this.fileDropSubject);
        reader.readAsDataURL(file);
      }

    } catch (ex) {
      this.userInterfaceService.alertUserToException(ex, 'handleFileSelect()');
    }
  }

  private _readerOnLoad(userInterfaceService: UserInterfaceService, file: any, validExtensions: string, maxFileSizeMB: number, newFileContent: Subject<FileContentDescriptor>): any {
    return(
      function(e: any): void {
        try {
          e.stopPropagation();
          e.preventDefault();

          const lastDot = file.name.lastIndexOf('.');
          let fileExtension = null;
          if (lastDot > 0) {
            fileExtension = file.name.substring(lastDot + 1, file.name.length).toLowerCase();
          }

          // if we have valid extensions to check, do so here...
          // console.log('Got here...', validExtensions, maxFileSizeMB);

          if (validExtensions && (validExtensions.length > 0)) {
            const validExtArray = validExtensions.split(',');
            // check the extension as a preliminary, client-side validation...
            if (!validExtArray.includes(fileExtension)) {
              let msg = 'Valid extensions include:\n';
              for (const ext2 of validExtArray) {
                msg += ('\t' + ext2 + '\n');
              }
              msg += 'Please try again.';
              this.modalDialogService.showPleaseWait(false);
              this.modalDialogService.showAlert(msg, 'WARNING: Invalid File Extension "' + fileExtension + '"');
              return;
            }
          }

          // if we have a max file size to check, do so here...

          if (maxFileSizeMB && (maxFileSizeMB > 0)  && (maxFileSizeMB * 1024 * 1024) < file.size) {
            let msg = 'Maximum allowed file upload: ' + maxFileSizeMB + 'MB\n';
            msg += 'Please try again.';
            this.modalDialogService.showPleaseWait(false);
            this.modalDialogService.showAlert(msg, 'WARNING: Invalid File Size!');
            return;
          }

          // Now parse out the base64 content...
          // ("readAsDataURL" returns the base64 prefixed with a tag...)

          let base64Content = e.target.result;
          const base64tag = ';base64,';
          const offset = base64Content.indexOf(base64tag) + base64tag.length;
          // console.log('offset = '+offset + ', base64Content: '+base64Content);
          base64Content = base64Content.substring(offset);
          // console.log('base64Content: '+base64Content);

          /////////////////////
          // build the FCD...
          /////////////////////

          const fcd = new FileContentDescriptor();

          fcd.fileContent = base64Content;

          fcd.fileName = file.name;

          // SOMEtimes, we don't get a value for file.type, so we use the extension...
          fcd.fileType = fileExtension;
          if (file.type && (file.type.trim().length > 0)) {
            fcd.fileType = file.type;
          }

          fcd.fileSize = file.size;
          fcd.fileDate = new Date(file.lastModified);

          // And finally, notify the recipient of the file content

          newFileContent.next(fcd);

        } catch (ex) {
          userInterfaceService.alertUserToException(ex, 'Ignoring file.');
        }
      }
    );
  }

}
