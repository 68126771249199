import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-value-transparency',
  templateUrl: './value-transparency.component.html',

})
export class ValueTransparencyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
