import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-search-service',
  templateUrl: './search-service.component.html',

})
export class SearchServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
