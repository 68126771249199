import { Component, Input, OnInit, ElementRef, HostListener } from '@angular/core';
import { EventServerService } from '../../../services/event-server.service';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { WEvent } from '../../../data/event.model';
import { WChart, WChartType } from './chart.model';
import { ModalDialogService } from '../../../services/modal-dialog.service';

@Component({
  selector: 'wackadoo-chart-block',
  templateUrl: './chart-block.component.html',
})
export class ChartBlockComponent implements OnInit {

  // we populate this chart per the given @Input() values
  // Either we have the data directly, or we fire an event to get it...
  chart: WChart = null;

  // this provides a way to configure this component in a single JSON object
  // NOTE: This over-rides the other configured @Input() values
  @Input() wChartJSON: any = null;

  // chart basics...

  @Input() mainTitle: string = null;
  @Input() subTitle: string = null;
  @Input() chartType: WChartType = null;
  @Input() yAxisLabel: string = null;  // ignored for pie charts...
  @Input() showPercentage = false; // only used for pie charts
  @Input() hideDataLabels = false;

  ////////////////////////////////////////////////////////////////
  // We can get the data to plot the chart in ONE of TWO ways
  ////////////////////////////////////////////////////////////////

  @Input() dataSetLabels: any = null;

  // 1. we take the data from the configured @Input() fields... (xAxisLabels, dataSets, dataSetLabels)

  @Input() xAxisLabels: string [] = null;
  @Input() dataSets: [number []] = null;
  // dataSetLabels is a string []...

  // 2. we take the data from the resources in the returned WEvent...

  @Input() eventHandler: string;
  @Input() action: string;
  @Input() parms: {};
  @Input() xAxisLabelField: string = null;
  @Input() applySelectedKeys = true; // we usually DO want to apply selected keys, but probably not always...
  @Input() debug = false; // if true, event result is logged to console...
  @Input() defaultTextContent = 'No data found...';
  // dataSetLabels is an optional any {} with value:label pairs...

  // if we fire an event to get the data from the resources,
  // each dataset must be in a Resource field, where the column name is the dataset label...
  // In this example, which works for bar or line charts
  //    Jan/Feb/Mar are the xAxis labels because xAxisLabelField is "month"
  //    bought/sold/inventory are the data set labels
  /*
      month   bought    sold      inventory
      Jan     34        23        89
      Feb     12        26        74
      Mar     32        56        31
  */

  // In this example, which works for pie charts
  //    Jan/Feb/Mar are the pie chart legend labels because xAxisLabelField is "month"
  //    bought is data set label
  /*
      month   bought
      Jan     34
      Feb     12
      Mar     32
  */

  // Now we do something to control the height...

  // If we have a thing-below-selector, we calculate that height
  @Input() maxHeightThingBelowSelector: string = null;
  @Input() maxHeightFudgeFactor = 0;

  // If we DO NOT have a thing-below-selector, we go either absolute or relative height...
  @Input() absoluteHeight: number = null;
  @Input() relativeHeight: number = null;

  wStyle = ['position'];
  wValue = ['relative'];

  // this is how we get the canvas to re-size properly...
  @HostListener('window:resize') onWindowResizeEvent = this.ngOnInit;

  constructor(
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    public elementRef: ElementRef,
  ) {
  }

  ngOnInit(): void {

    try {

      if (!this.maxHeightThingBelowSelector && (this.absoluteHeight || this.relativeHeight)) {
        this.wStyle.push('height');
        this.wValue.push(
          (
            this.absoluteHeight
            ?
              (this.absoluteHeight + 'px')
            :
              (this.relativeHeight + 'vh')
          )
        );
      }

      if (this.wChartJSON) {

        if (typeof this.wChartJSON === 'string') {
          const tempJSONStr = this.wChartJSON;
          this.wChartJSON = JSON.parse(tempJSONStr);
          if (this.debug === true) {
            console.log('ChartBlock()', tempJSONStr, this.wChartJSON);
          }
        }

        this.mainTitle = this.wChartJSON.mainTitle;
        this.subTitle = this.wChartJSON.subTitle;
        this.chartType = this.wChartJSON.chartType;
        this.yAxisLabel = this.wChartJSON.yAxisLabel;

        this.xAxisLabels = this.wChartJSON.xAxisLabels;
        this.dataSets = this.wChartJSON.dataSets;
        this.dataSetLabels = this.wChartJSON.dataSetLabels;

        this.eventHandler = this.wChartJSON.eventHandler;
        this.action = this.wChartJSON.action;
        this.parms = this.wChartJSON.parameters;
        this.xAxisLabelField = this.wChartJSON.xAxisLabelField;
        this.applySelectedKeys = (typeof this.wChartJSON.applySelectedKeys !== 'undefined' ? this.wChartJSON.applySelectedKeys : this.applySelectedKeys);
        this.debug = (typeof this.wChartJSON.debug !== 'undefined' ? this.wChartJSON.debug : this.debug);
        this.defaultTextContent = (this.wChartJSON.defaultTextContent ? this.wChartJSON.defaultTextContent : this.defaultTextContent);
      }

      if (this.debug === true) {
        console.log('ChartBlock()',
          this.mainTitle,
          this.subTitle,
          this.chartType,
          this.yAxisLabel,
          'Manual data',
          this.dataSets,
          this.dataSetLabels,
          'WEvent data',
          this.eventHandler,
          this.action,
          this.parms,
          this.xAxisLabelField,
          this.applySelectedKeys,
          this.debug,
          this.defaultTextContent
        );
      }

      if (this.eventHandler && this.action && this.parms) {
        let parms = this.parms;

        if (this.applySelectedKeys) {
          parms = this.userInterfaceService.applyCurrentSelectionsToParms(parms, this.eventHandler);
          // console.log('munged resourceBlockparms:', parms);
        }

        this.eventServerService.fireEvent(this.eventHandler, this.action, parms).subscribe(
          (event: WEvent) => {
            if (this.debug === true) {
              console.log(event);
            }
            if (event.status === 'OK') {
              // check to see that we actually got an array of resources...
              if (event.resources) {

                // Build the WChart object from the event resources...

                const chart = new WChart(this.chartType, this.mainTitle, this.yAxisLabel, this.subTitle, this.hideDataLabels, this.showPercentage);

                chart.datasets = [];
                chart.labels = [];

                for (const r of event.resources) {
                  for (const field of r.fields) {
                    if (field.name === this.xAxisLabelField) {
                      chart.labels.push(field.value);
                    } else {
                      // if we have dataSetLabels, we ONLY use those fields...
                      // else we DO NOT have dataSetLabels, we use ALL fields...
                      let label = null;
                      if (this.dataSetLabels) {
                         label = this.dataSetLabels[field.name];
                      } else {
                         label = field.name;
                      }
                      if (label) {
                        // console.log('ChartBlock()', this.dataSetLabels, field.name, label);
                        let idx = chart.datasets.findIndex((ds) => ds.label === label);
                        if (idx < 0) {
                          idx = chart.datasets.push({ data: [], label}) - 1;
                        }
                        chart.datasets[idx].data.push(Number(field.value));
                      }
                    }
                  }
                }

                this.chart = chart;

                if (this.debug === true) {
                  console.log('chart', chart);
                }

              } else {
                const msg = 'No resources to chart';
                if (this.debug === true) {
                  console.log(msg, event);
                }
              }
            } else {
              if (this.debug === true) {
                console.log('Chart Event Error', event);
              }
              this.modalDialogService.showAlert(event.message, 'Chart Event Error');
            }
          }
        );

      } else {

        const chart = new WChart(this.chartType, this.mainTitle, this.yAxisLabel, this.subTitle, this.hideDataLabels, this.showPercentage);

        chart.datasets = [];

        for (let i = 0; i < this.dataSets.length; i++) {
          const dataset: any = {};
          dataset.data = this.dataSets[i];
          dataset.label = this.dataSetLabels[i];
          chart.datasets.push(dataset);
        }

        chart.labels = this.xAxisLabels;

        this.chart = chart;

        if (this.debug === true) {
          console.log('chart', chart);
        }

      }

    } catch (ex) {
      this.modalDialogService.showAlert('Error parsing wChartJSON parameter. (See console for details.)', 'Chart Error');
      console.log('Not a valid string representation of a JSON object!', this.wChartJSON, ex);
    }

  }

  // The "active" array are ChartElements that appear useless out of the box. They do
  // not pass back the data item that was clicked, and it is NOT obvious how to get it.
  // (Maybe the ng2-charts API offers some kind of "data" pass-through element...)
  onChartClick( { event, active }: {event: any, active: any []}): void {
    console.log('clicked', event, active);
  }

}
