import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Subscriber } from 'rxjs';
import { ConfirmContent } from './confirm-content.model';
import { ModalDialogService } from '../../../services/modal-dialog.service';

@Component({
  selector: 'wackadoo-confirm',
  templateUrl: './confirm.component.html',

})
export class ConfirmComponent implements OnInit, OnDestroy {

  visible = false;

  showModalSubscription: Subscription;

  message = '';
  title = '';

  observer: Subscriber<boolean>;

  constructor(
    private _modalDialogService: ModalDialogService,
  ) { }

  ngOnInit(): void {
    this.showModalSubscription = this._modalDialogService.showConfirmSubject.subscribe(
      (confirmContent: ConfirmContent) => {
        this.observer = confirmContent.observer;
        this.message = confirmContent.message.replace(/\\n/g, '\n').replace(/\n/g, '<br/>');
        this.title = confirmContent.title;
        this.visible = true;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }
  }

  onOK(): void {
    this.observer.next(true);
    this.visible = false;
    this.message = '';
    this.title = '';
  }

  onCancel(): void {
    this.observer.next(false);
    this.visible = false;
    this.message = '';
    this.title = '';
  }

}
