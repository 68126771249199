<div *ngIf="visible">
    <div class="modal-backdrop" (click)="onClose()"></div>
    <div class="modal-box">
        <div class="scrollable-y maxHeightViewPort50 paddingHalfEm">
            <div class="bold italic" *ngIf="title !== ''">{{ title }}</div>
            <p [innerHTML]="message | safeHtml"></p>
            <p *ngIf="status">{{ status }}</p>
            <div class="scrollable-y maxHeightViewPort30 rounded border paddingHalfEm" *ngIf="error && (error.trim().length > 0)">
                <p>
                    {{error}}
                </p>
            </div>
            <p>
                We deeply apologize for the inconvenience.
                <br/>
                Please know that the error has been logged, and that we will work diligently to fix things.
            </p>
            <p>
                Thank you for your patience...
            </p>
        </div>
        <div class="marginTopWholeEm floatGroup">
            <div class="floatRight">
                <button class="btn btn-secondary paddingLeftWholeEm paddingRightWholeEm marginRightWholeEm" (click)="onClose()">Close</button>
            </div>
        </div>
    </div>
</div>
