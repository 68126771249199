<div>
  <div class="card marginBottomHalfEm">
    <div class="card-header d-flex justify-content-between">
      <div>
        <ng-container *ngIf="selectedReport && selectedReport['reportName'] ; else reportListTitle">
          <div class="nowrap">
            Report Name: <span class="italic">{{ selectedReport['reportName'].value }}</span>
          </div>
          <div class="unbold smallish nowrap" *ngIf="resources">
            Records Returned: <span class="italic">{{ resources.length }}</span>
          </div>
        </ng-container>
        <ng-template #reportListTitle>
          <div class="bold italic">
            Reports
          </div>
          <div class="unbold smallish nowrap" *ngIf="resources">
            Number of Available Reports: <span class="italic">{{ resources.length }}</span>
          </div>
          <div class="unbold small italic">
            (Some reports use the current selections to help filter their results, so the number of available reports can vary.)
          </div>
        </ng-template>
      </div>
      <div class="center marginLeftWholeEm" *ngIf="extraParameters">
        Additional Report Parameters 
        <div class="marginLeftWholeEm d-flex flex-wrap">
          <ng-container *ngFor="let f of extraParameters.fields; let i = index;" >
              <div class="unbold smallish nowrap">
                {{ f.name }} <wackadoo-field [f]="f" [onChange]="extraParameterSubjects[i]" mode="write"></wackadoo-field> &nbsp; 
              </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- the Report results -->
    <ng-container *ngIf="resources && (selectedReport !== null)">
      <div class="card-body paddingTop0 paddingBottom0 wrap-scrollable-table"
        wackadooMaximizeContentHeight
        maxHeightThingBelowSelector=".reportButtonBar"
        [maxHeightFudgeFactor]="-16"
        [maxHeightTimoutDelay]="100"
        [maxHeightLockThingBelowToBottomOfPage]="true"
        [maxHeightThingBelowDefaultHeight]="38"
      >
        <table class="table table-hover smallish marginTop0 paddingTop0 marginBottom0 scrollable report-results">
          <thead>
            <tr class="numbered-row">
              <th class="italic">
              </th>
              <th  *ngFor="let f of resources[0].fields; let j = index;">
                {{ f.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="numbered-row" *ngFor="let r of resources; let i = index;">
              <td class="italic right">
                {{ i + 1 }}
              </td>
              <td *ngFor="let f of r.fields; let j = index;">
                {{ f.value }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <!-- the list of Reports -->
    <ng-container *ngIf="(selectedReport === null)">
      <div class="card-body paddingTop0 paddingBottom0 wrap-scrollable-table"
        wackadooMaximizeContentHeight
        maxHeightThingBelowSelector=".wackadoo-footer"
        [maxHeightFudgeFactor]="-16"
        [maxHeightTimoutDelay]="100"
        [maxHeightLockThingBelowToBottomOfPage]="false"
      >
        <ng-container *ngIf="(screenType !== 'phone')">
          <ng-container *ngIf="resources && (resources.length >= 0) ; else noReportsToDisplay">
            <div class="row marginTopQuarterEm" *ngFor="let resourceRow of resources | column:3; let h = index;"> 
              <ng-container *ngFor="let r of resourceRow;">
                <div *ngIf="r && r.id && r.reportName" class="col-sm-4 d-flex"  (click)="selectReport(r)">
                  <div>
                    ({{ r.id.value }})
                  </div>
                  <div class="marginLeftHalfEm">
                    <span class="link">
                      {{ r.reportName.value }}
                    </span>
                    <span class="smallish italic marginLeftHalfEm" *ngIf="r.userQueryParms && r.userQueryParms.value">
                      (requires {{ r.userQueryParms.value }})
                    </span>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(screenType === 'phone')">
          <ng-container *ngIf="resources && (resources.length >= 0) ; else noReportsToDisplay">
            <div class="marginTopQuarterEm" *ngFor="let r of resources; let h = index;"> 
              <div *ngIf="r && r.id && r.reportName" class="d-flex"  (click)="selectReport(r)">
                <div>
                  ({{ r.id.value }})
                </div>
                <div class="marginLeftHalfEm">
                  <span class="link">
                    {{ r.reportName.value }}
                  </span>
                  <span class="smallish italic marginLeftHalfEm" *ngIf="r.userQueryParms && r.userQueryParms.value">
                    (requires {{ r.userQueryParms.value }})
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container> 
        <ng-template #noReportsToDisplay>
          <div class="marginWholeEm italic paddingWholeEm">
              No reports to display...
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
  <!-- the button bar for Report results -->
  <div *ngIf="resources && (selectedReport !== null)" class="marginTopHalfEm d-flex justify-content-around reportButtonBar" role="group">
    <div *ngIf="user.isMemberOrAbove">
      <button type="button" title="Export to File" data-abbrev="" class="btn btn-secondary actionButton"  (click)="export()"><span class="fas fa-file-export"></span></button>
      <div class="marginTopQuarterEm">
        <wackadoo-field [f]="exportFileExtension" mode="write"></wackadoo-field>
      </div>
    </div>
    <div *ngIf="user.isMemberOrAbove && (hasDocxTemplate || hasXlsxTemplate || hasXlsmTemplate)">
      <button type="button" title="Populate Template" data-abbrev="Pop Tmpl" class="btn btn-secondary actionButton" (click)="populateTemplate()"><span class="fas fa-file-download"></span></button>
      <div class="marginTopQuarterEm">
        <wackadoo-field [f]="templateFileExtension"  mode="write"></wackadoo-field>
      </div>
    </div>
    <button *ngIf="hasGhtmlTemplate" type="button" title="View Graph" data-abbrev="" class="btn btn-secondary actionButton align-self-start" (click)="viewGraph()"><span class="fas fa-chart-pie"></span></button>
    <button type="button" title="Cancel" data-abbrev="" class="btn btn-secondary actionButton align-self-start" (click)="cancel()"><span class="fas fa-times"></span></button>
  </div>
</div>
