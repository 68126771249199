import { Component } from '@angular/core';
import { DetailButtonBarComponent } from '../../repository-page/detail-button-bar/detail-button-bar.component';
import { EventServerService } from '../../../services/event-server.service';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { WEvent } from '../../../data/event.model';
import { BusinessRuleService } from 'src/app/client-core/services/business-rule.service';

@Component({
  selector: 'wackadoo-report-template-detail-button-bar',
  templateUrl: './report-template-detail-button-bar.component.html',
})
export class ReportTemplateDetailButtonBarComponent extends DetailButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ReportTemplateDetailButtonBarComponent';

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    public businessRuleService: BusinessRuleService
  ) {
    super(eventServerService, userInterfaceService, modalDialogService);
  }

  get userCanEditThisTemplate(): boolean {
    return !this.resource.systemTemplate.value || this.businessRuleService.reportSystemTemplateIsEditable(this.resource.templateName.value);
  }

  save(): void {

    // When populating a NEW resource that includes a binary-text field, we need to
    // populate these related fields to make sure we have a "proper" template resource.

    // console.log('save() - enter:', this.resource.templateFileName.value, this.resource.templateExtension.value, this.resource.keyField.isPopulated, this.resource.templateFileName.isPopulated, this.resource.templateFileType.isPopulated);

    if ((this.resource.templateExtension.value === 'sql') || (this.resource.templateExtension.value === 'ghtml')) {

      if (!this.resource.keyField.isPopulated) {

        if (!this.resource.templateFileType.isPopulated || (this.resource.templateFileType.value === 'unk')) {
          this.resource.templateFileType.value = this.resource.templateExtension.value;
        }

        if (!this.resource.templateFileName.isPopulated || (this.resource.templateFileName.value === 'none.unk')) {
          this.resource.templateFileName.value = 'none.' + this.resource.templateExtension.value;
        }

        this.resource.markAllFieldsAsChanged();
      }

      if (this.resource.templateFileContent.changed) {
        const content = (this.resource.templateFileContent.isPopulated ? this.resource.templateFileContent.text : '');
        this.resource.templateFileSize.value = content.length;
      }

    }

    // console.log('save() - (changed) exit:', this.resource.getChangedFieldValuesAsParms());

    super.save();
  }

  copy(): void {
    this.resource.systemTemplate.value = false;
    super.copy();
  }

  reloadDefaultTemplate(): void {

    let confirmMessage = 'You are about to re-initialize this "systemTemplate" to its original state.';
    confirmMessage += '\n\n' + 'If you proceed, any changes that you have made to this local copy will be wiped out.';
    confirmMessage += '\n\n' + 'Do you still want to do this?';

    this.modalDialogService.showConfirm(confirmMessage, '').subscribe(
      (response: boolean) => {

        if (response) {
          const parms: any = {};
          parms.templateID = this.resource.templateID.value;
          parms.templateName = this.resource.templateName.value;

          // console.log('about to reloadDefaultTemplate: ' + JSON.stringify(parms));

          // this call (with a specific templateID in the parms...) reloads just the ONE template...
          this.eventServerService.fireEvent(this.eventHandler, 'reloadDefaultTemplate', parms).subscribe(
            (responseEvent: WEvent) => {

              if (responseEvent.status !== 'OK') {
                this.modalDialogService.showAlert('Failed to reload default template: ' + responseEvent.message, 'Error Loading Default Template');
              } else {
                this.userInterfaceService.reloadSelectedResourceFromServer(this.eventHandler);
              }
            }
          );

        }
      }
    );
  }

}
