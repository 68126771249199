import { WResource } from '../../../data/resource.model';
import { Subject, Subscriber } from 'rxjs';

export class SelectValueContent {
  observer: Subscriber<any> = null;
  componentName = '';
  resource: WResource = null;
  fieldName = null;
  subject: Subject<any> = null;
  selectButtonTitle: string = null;

  constructor(observer: Subscriber<any>, componentName: string, resource: WResource, fieldName: string, selectButtonTitle?: string) {
    this.observer = observer;
    this.componentName = componentName;
    this.resource = resource;
    this.fieldName = fieldName;
    this.selectButtonTitle = (selectButtonTitle ? selectButtonTitle : null);
  }
}

