import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-backup-service',
  templateUrl: './backup-service.component.html',

})
export class BackupServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
