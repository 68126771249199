<div class="row">
    <div class="col-sm-4">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <div>
                    <wackadoo-field [f]="resource.templateName" [mode]="(resource.systemTemplate.value ? 'read' : mode)"></wackadoo-field>
                </div>
                <div>
                    <wackadoo-field [f]="resource.templateExtension" mode="read"></wackadoo-field>
                </div>
            </div>
            <div class="card-body smallish">
                <wackadoo-file-details [fileContentField]="resource.templateFileContent" ></wackadoo-file-details>
                <div>
                    Last Modified: <wackadoo-field [f]="resource.modified" mode="read"></wackadoo-field>
                </div>
            </div>
        </div>
        <div class="card marginTopWholeEm">
            <div class="card-header">
                Fields Available For Use In Template
            </div>
            <div class="card-body">
                <div class="small italic">
                    Select resource type.
                    <br/>
                    <span class="bold" *ngIf="mode === 'read'">You must be editing the template to enable field selection.</span>
                    <span class="bold" *ngIf="mode === 'write'">To insert a field name: Click on the field name, then click in the template.</span>
                </div>
                <div class="border-bottom paddingBottomQuarterEm" *ngIf="resourceType">
                    <wackadoo-field [f]="resourceType" mode="write" [onChange]="resourceTypeChangeSubject"></wackadoo-field>
                </div>
                <div class="marginTopHalfEm scrollable-y"
                    wackadooMaximizeContentHeight
                    [maxHeightThingBelowSelector]="'div[wackadooDetailButtonBarPlaceholder]'"
                    [maxHeightFudgeFactor]="-12"
                    [maxHeightTimoutDelay]="333"
                >
                    <div *ngFor="let fn of fieldNames;">
                        <div (click)="selectFieldName(fn)" [ngClass]="{selected:(selectedFieldName === fn)}">
                            {{fn}}
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-8">
        <div class="card">
            <div class="card-header">
                File Content
            </div>
            <div class="card-body">
                <wackadoo-field-binary-html
                    [f]="resource.templateFileContent"
                    [mode]="mode"
                    [validExtensions]="resource.templateExtension.value"
                    [resource]="resource"

                    [eventSubject]="mouseUpSubject"

                    wackadooMaximizeContentHeight
                    [maxHeightThingBelowSelector]="'div[wackadooDetailButtonBarPlaceholder]'"
                    [maxHeightFudgeFactor]="-12"
                ></wackadoo-field-binary-html>
            </div>
        </div>
    </div>   
</div>
