import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { ModalDialogService } from '../services/modal-dialog.service';
import { FieldMode } from '../data/field/field-mode.model';
import { UserInterfaceService } from '../services/user-interface.service';

@Directive({
  selector: '[wackadooFileDragOver]'
})
export class FileDragOverDirective implements OnInit {

  @HostListener('dragover', ['$event']) dragOver = this.handleFileDragOver;
  @HostListener('drop', ['$event']) drop = this.handleWrongPlaceForFileDrop;

  @Input() mode = FieldMode.read;

  constructor(
    public modalDialogService: ModalDialogService,
    public userInterfaceService: UserInterfaceService,
  ) {}

  ngOnInit(): void {
  }

  handleFileDragOver(e: any): void {
    e.stopPropagation();
    e.preventDefault();
    // console.log('handleFileDragOver()');
    if (this.mode === FieldMode.write) {
      e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
    }
  }

  handleWrongPlaceForFileDrop(e: any): void {
    try {
      // console.log('handleWrongPlaceForFileDrop()', e);
      const files = e.dataTransfer.files; // FileList object.
      if ((files !== null) && (files.length > 0)) {
        e.stopPropagation();
        e.preventDefault();
        if (this.mode === FieldMode.write) {
          this.modalDialogService.showAlert('This is not a valid place to drop a file...', 'Please Try Again');
        } else {
          this.modalDialogService.showAlert('You must be editing a resource before you can drop a file,<br/>and then, you can only drop the file on the actual file content field...', 'Wrong Place To Drop A File');
        }
      }

    } catch (ex) {
      this.userInterfaceService.alertUserToException(ex, 'handleWrongPlaceForFileDrop()');
    }
  }

}
