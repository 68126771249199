import { Component, OnInit, OnDestroy } from '@angular/core';
import { SearchModalContent } from './search-modal-content.model';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { Subscription, Subscriber } from 'rxjs';

@Component({
  selector: 'wackadoo-search',
  templateUrl: './search.component.html',

})
export class SearchComponent implements OnInit, OnDestroy {

  static SEARCH_ALL = '*';

  visible = false;

  showModalSubscription: Subscription;

  observer: Subscriber<boolean|string>;

  ehName = '';
  query = SearchComponent.SEARCH_ALL;

  constructor(
    private _modalDialogService: ModalDialogService
  ) { }

  ngOnInit(): void {
    this.showModalSubscription = this._modalDialogService.showModalSearchSubject.subscribe(
      (modalSearchContent: SearchModalContent) => {
        this.observer = modalSearchContent.observer;
        this.ehName = modalSearchContent.ehName;
        this.visible = true;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }
  }

  onOK(): void {
    this.observer.next(this.query);
    this.visible = false;
    this.ehName = '';
  }

  onCancel(): void {
    this.observer.next(false);
    this.visible = false;
    this.ehName = '';
    this.query = SearchComponent.SEARCH_ALL;
  }

}

