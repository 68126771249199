import { Component, OnInit, Renderer2, OnDestroy, Input } from '@angular/core';
import { FieldComponent } from '../field.component';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { UtilityLibService } from '../../../services/utility-lib.service';

@Component({
  selector: 'wackadoo-field-real-number',
  templateUrl: './real-number.component.html',

})
export class RealNumberComponent extends FieldComponent implements OnInit, OnDestroy {

  @Input() skipCommas = false;

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    public utilLibService: UtilityLibService,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.f.skipCommas === true) {
      this.skipCommas = this.f.skipCommas;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /**
   * This is the over-ridden method called on real number field changes.
   * It assumes the 'value' of the input element is populated with the new value.
   */
  onInputChange(e: any): boolean {
    // e = e || window.event;
    const target = e.srcElement || e.target;

    const cellInput = target;

    // console.log(cellInput);

    if (cellInput) {

      // console.log('cellInput.value: ' + cellInput.value + ', this.f.value: ' + this.f.value);
      // console.log('this.f.isValidValue(cellInput.value): ' + this.f.isValidValue(cellInput.value));

      // clear the previous formatting, if present...
      this.renderer.removeClass(cellInput, 'fieldChanged');
      this.renderer.removeClass(cellInput, 'fieldError');

      // this is the part where we convert the string in the field input to the numeric value...
      const n = parseFloat(cellInput.value);

      if (this.f.isValidValue(n)) {
        // the new value passed validation...

        // console.log('new value: ' + n, this.f.value, 'changed? ' + (n !== this.f.value));

        if (n !== this.f.value) {

          const oldValue = this.f.value;

          this.renderer.addClass(cellInput, 'fieldChanged');
          this.f.value = n;
          this.f.changed = true;

          // console.log('cellInput.value: ' + cellInput.value, this.f.value, 'this.onChange exists? ' + (this.onChange !== null));
          if (this.onChange !== null) {
            // console.log('firing field.onChange.next()');
            this.f.oldValue = oldValue;
            this.onChange.next(this.f);
          }

        }
        // this._renderer.setStyle(cellInput, 'backgroundColor', '');
      } else {

        // console.log('this.f._errorMessage: ' + this.f._errorMessage);

        // the new value failed validation...
        this.renderer.addClass(cellInput, 'fieldError');
      }
    }

    return false;
  }

  getStep(): number {
    return (this.f && (this.f.displayComponent === 'currency') ? 0.01 : 1);
  }

  formatValue(): string {
    let nStr = '';
    if (this.f.value !== null) {
      let n = this.f.value;
      if (typeof n === 'string') {
        n = parseInt(n, 10);
        this.f.value = n;
      }
      if (this.f.displayValue) {
        nStr = '' + this.f.displayValue;
      } else if (this.f.displayComponent === 'currency') {
        nStr = n.toLocaleString('en-US', { style: 'currency', currency: 'USD'});
        if (nStr.endsWith('.00')) {
          nStr = nStr.substr(0, (nStr.length - 3));
        }
      } else if (this.f.displayComponent === 'percentage') {
        nStr = parseFloat('' + n).toFixed(2) + '%';
      } else {
        nStr = (this.skipCommas ? '' + n : this.utilLibService.formatNumberWithCommas(n));
      }
    }
    return nStr;
  }

}
