import { Component, OnInit, OnDestroy, Renderer2, Input, ElementRef, HostListener, OnChanges } from '@angular/core';
import { FieldComponent } from '../field.component';
import { FieldMode } from '../field-mode.model';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { EventServerService } from '../../../services/event-server.service';
import { FileContentDescriptor } from '../../../ui/file-content-descriptor.model';
import { Subject, Subscription } from 'rxjs';
import { WResource } from '../../resource.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { WEvent } from '../../event.model';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-field-binary',
  templateUrl: './binary.component.html',
})
export class BinaryComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges {

  @Input() resource: WResource = null;
  @Input() validExtensions: string = null;

  @Input() autoUploadOnChange: Subject<WEvent> = null;

  // these are used by the button...
  public loadFileContent = null;
  private _loadFileContentSubscription: Subscription;

  // this is the "drop" portion of the drag-n-drop mechanism...
  @HostListener('drop', ['$event']) drop = null;

  public url: string = null;

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    public elementRef: ElementRef,
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
    public utilityLibService: UtilityLibService,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.setURL();
    this.drop = this.handleGoodFileDrop;

    this.loadFileContent = new Subject<FileContentDescriptor>();

    this._loadFileContentSubscription = this.loadFileContent.subscribe(
      (fcd: FileContentDescriptor) => {
        // console.log('loadFileContent - before', fcd, this.f);

        if (fcd) {

          this.f.value = fcd.fileContent;
          this.f.changed = true;

          this.f.fileName = fcd.fileName;
          this.f.fileSize = fcd.fileSize;
          this.f.fileType = fcd.fileType;
          this.f.fileDate = fcd.fileDate;

          // Now set the information for the current file into the UI, if we can

          this.setRelatedFileContentFields(fcd);

          // console.log('loadFileContent()', this.f, this.resource, 'trigger onChange?', (this.onChange !== null));

          if (this.onChange) {
            this.onChange.next(this.f);
          }

        }
        // console.log('loadFileContent - after', fcd, this.f);
      }
    );

    if (this.f && !this.validExtensions) {
      this.validExtensions = this.f.validExtensions;
    }

  }

  ngOnDestroy(): void {
    if (this._loadFileContentSubscription) {
      this._loadFileContentSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  ngOnChanges(): void {
    if (this.f) {
      if (!this.validExtensions) {
        this.validExtensions = this.f.validExtensions;
      }
      if (this.resource) {
        this.setURL();
      }
    }
  }

  get formattedValidExtensions(): string {
    let ext = '';

    if (this.validExtensions) {
      ext = this.validExtensions.replace(/\|/g, ', ');
      const lidx = ext.lastIndexOf(',');

      if (lidx > 0) {
        ext = ext.substr(0, lidx) + ' or' + ext.substr(lidx + 1);
      }
    }

    return ext;
  }

  public setURL(): void {
    if (this.f && this.f.isPopulated) {
      if (this.f.value.trim().startsWith('<')) {
        this.url = null;
      } else if (0 < this.f.value.indexOf('/getFile?')) {
        this.url = window.location.origin + this.userInterfaceService.relativeEventServerURL;
        this.url += this.f.value;
        this.url += '&' + this.eventServerService.getSessionTokenURLParameter();
      } else if (this.resource) {
        this.url = 'data:' + this.resource.getField(this.f.fileTypeField).value + ';base64,' + this.f.value;
      }
    } else {
      this.url = null;
    }
  }

  // All of these need to be handled in the HTML for this component...
  isTextExtension(extension: string): boolean {
    return(
        (extension === 'sql')
        || (extension === 'html')
        || (extension === 'ghtml')
        || (extension === 'xhtml')
        || (extension === 'txt')
        || (extension === 'text')
        || (extension === 'json')
        || (extension === 'xml')
    );
  }

  downloadFile(): void {
    try {

      const binaryUrlExtract = this.f.parseBinaryURL(this.f.text);

      if (binaryUrlExtract) {

        // console.log('downloadFile() - hitting the server', this.f, this.f.name, this.f.value, this.f.binaryContentUrl);

        const ehName = binaryUrlExtract.eh;
        const method = binaryUrlExtract.method;
        const parms = binaryUrlExtract.parms;

        // Note: we do NOT set returnBase64FileContent!

        // console.log('downloadFile() -  firing Event: ' + ehName + '.' + method, parms);

        this.eventServerService.downloadFile(ehName, method, parms);
      }

    } catch (ex) {
      const msg = 'downloadFile() - Failed to process getFile url:\n' + this.f.text;
      this.modalDialogService.showAlert(msg + '\n' + JSON.stringify(ex), 'Error Getting File Content');
    }
  }

  setRelatedFieldInputValue(name: string, value: any): void {
    if (this.resource && name && value) {
      this.resource[name].value = value;
      this.resource[name].changed = true;
    }
  }

  setRelatedFileContentFields(fcd: FileContentDescriptor): void {
    if (this.resource) {

      this.setRelatedFieldInputValue(this.f.fileNameField, fcd.fileName);
      this.setRelatedFieldInputValue(this.f.fileSizeField, fcd.fileSize);
      this.setRelatedFieldInputValue(this.f.fileTypeField, fcd.fileType);
      this.setRelatedFieldInputValue(this.f.fileDateField, fcd.fileDate);

      // console.log('setRelatedFileContentFields()', this.f, fcd, this.resource.getChangedFieldValuesAsParms());

      if (this.resource.keyField.isPopulated && this.autoUploadOnChange) {

        const temp = this.eventServerService.newResource(this.eventServerService.getEventHandlerForResourceType(this.resource.getType()));

        temp.keyField.value = this.resource.keyField.value;

        temp.getField(this.f.name).value = fcd.fileContent;
        temp.getField(this.f.name).changed = true;

        temp.getField(this.f.fileNameField).value = fcd.fileName;
        temp.getField(this.f.fileNameField).changed = true;

        temp.getField(this.f.fileSizeField).value = fcd.fileSize;
        temp.getField(this.f.fileSizeField).changed = true;

        temp.getField(this.f.fileTypeField).value = fcd.fileType;
        temp.getField(this.f.fileTypeField).changed = true;

        temp.getField(this.f.fileDateField).value = fcd.fileDate;
        temp.getField(this.f.fileDateField).changed = true;

        this.eventServerService.saveResource(temp).subscribe(
          (result: WEvent) => {
            this.autoUploadOnChange.next(result);
          }
        );
      }
    }
  }

  getExtension(fileName: string): string {
    if (!fileName) {
      return(null);
    }
    if (fileName.lastIndexOf('.') <= 0) {
      return(null);
    }
    return(fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase());
  }

  ////////////////////////////////////////////////////////////////
  // Drag-n-Drop fns()
  ////////////////////////////////////////////////////////////////

  handleGoodFileDrop(e: any): void {
    try {
      e.stopPropagation();
      e.preventDefault();

      // console.log('handleGoodFileDrop()', 'mode: ' + this.mode);

      if (this.mode === FieldMode.read) {
        this.modalDialogService.showAlert('You must be editing this resource before you can drop a file here...', 'Please Try Again');
      } else {

        const files = e.dataTransfer.files; // FileList object.
        if (files == null) {
          throw new Error('No files (' + files + ') selected!');
        } else if (files.length > 1) {
          throw new Error('Too many files (' + files.length + ') selected!');
        }

        const file = files[0];

        // console.log('handleGoodFileDrop()', file);

        let fileType = file.type;

        const extension = this.getExtension(file.name);

        if (!fileType || (fileType.length === 0)) {
          fileType = extension;
        }

        const validExtensionsAsArray: string [] = (this.validExtensions ? this.validExtensions.split('|') : []);

        // console.log('handleGoodFileDrop()', this.f.validExtensions, validExtensionsAsArray, fileType, extension, 'validExtensionsAsArray.includes(extension): ' + validExtensionsAsArray.includes(extension));

        if (validExtensionsAsArray.includes(extension)) {

          const reader = new FileReader();

          reader.onload = (e2: any) => {
            try {
              e2.stopPropagation();
              e2.preventDefault();

              // console.log('drop.onload()', extension, file, e2);

              const contents = e2.target.result;

              // console.log('drop.onload()', extension, file, e2, contents);

              let base64Content = contents;
              const base64tag = ';base64,';
              const offset = base64Content.indexOf(base64tag) + base64tag.length;
              base64Content = base64Content.substring(offset);

              // we CANNOT use atob() because it does NOT support UTF-8 properly...
              // const convertedTextContent = (this.isTextExtension(extension) ? atob(base64Content) : null);
              const convertedTextContent = (this.isTextExtension(extension) ? this.utilityLibService.atobForUnicodeContent(base64Content) : null);

              if (extension === 'sql') {
                // and strip any invalid SQL commands that they might try to sneak in...
                this.throwExceptionOnBadSQL(convertedTextContent);
              }

              // Now set the information for this file in the field itself...
              // (ONLY used for the display of the binary component, now that it's been loaded...)

              // console.log('handleGoodFileDrop() - before', this.f);

              this.f.fileName = file.name;
              this.f.fileType = fileType;
              this.f.fileSize = file.size;
              this.f.fileDate = new Date(file.lastModified);

              // console.log('handleGoodFileDrop() - during', this.f);

              // Now set the information for the current file into the UI if we can
              const fcd = new FileContentDescriptor(base64Content, file.name, file.size, fileType, this.f.fileDate);
              this.setRelatedFileContentFields(fcd);

              // console.log('handleGoodFileDrop() - after', this.f);

              // the way text based binary files work is that
              // 1. the base64 is in f.value, and
              // 2. the "text" version for text files (sql, html, etc.) is in f.text

              this.f.value = base64Content;
              // console.log('handleGoodFileDrop() - base64Content (AFTER):\n'+wackadoo._EventServer.getFieldInUI('templateFileContent'));

              if (convertedTextContent !== null) {
                this.f.text = convertedTextContent;
              }

              this.f.changed = true;

              // console.log('handleGoodFileDrop() - file drop complete', this.f, this.resource, 'trigger onChange?', (this.onChange !== null));

              if (this.onChange) {
                this.onChange.next(this.f);
              }

            } catch (ex) {
              console.log(ex);
              this.modalDialogService.showAlert('Ignoring template file.\n' + JSON.stringify(ex), 'Error Getting File Content');
            }

          };

          // we ALWAYS read the base64 binary value of the file...
          reader.readAsDataURL(file);

        } else {
          this.modalDialogService.showAlert('Invalid file extension: ' + extension + ' (' + file.type + ')', 'File Selection Error');
        }

      }

    } catch (ex) {
      console.log(ex);
      this.modalDialogService.showAlert(JSON.stringify(ex), 'Error in handleFileSelect()');
    }
  }

  ////////////////////////////////////////////////////////////////
  // SQL scrubber
  ////////////////////////////////////////////////////////////////

  throwExceptionOnBadSQL(s: string): void {

    const stuffToFail = [

      // Data defn statements
      'alter'
      , 'create'
      , 'drop'
      , 'rename'
      , 'truncate'

      // Data manipulation statements
      , 'call'
      , 'delete'
      , 'do'
      , 'handler'
      , 'insert'
      , 'load'
      , 'replace'
      , 'update'

      // Trnasctional and Locking statements
      , 'start transaction'
      , 'start'
      , 'stop'
      , 'transaction'
      , 'commit'
      , 'rollback'
      , 'savepoint'
      , 'unlock'
      , 'lock'
      , 'lock'
      , 'xa commit'
      , 'xa start'
      , 'xa begin'
      , 'xa end'
      , 'xa prepare'
      , 'xa recover'
      , 'suspend'
      , 'convert'

      // Replication statements
      , 'purge'
      , 'reset'
      , 'set'
      , 'show'
      , 'change'
      , 'stop'

      // Prepared SQL Statement Syntax
      , 'prepare'
      , 'execute'
      , 'deallocate'

      // compound statement syntax
      , 'begin'
      , 'end'
      , 'declare'

        // flow control statements
        , 'case'
        , 'iterate'
        , 'leave'
        , 'loop'
        , 'repeat'
        , 'return'
        , 'while'

        // cursors
        , 'close'
        , 'declare'
        , 'fetch'
        , 'open'

        // condition handling
        , 'declare'
        , 'condition'
        , 'handler'
        , 'get current diagnostics'
        , 'get stacked diagnostics'
        , 'resignal'
        , 'signal'

      // DBA statements

      // acct mgt
      , 'alter user'
      , 'create user'
      , 'drop user'
      , 'rename user'
      , 'grant'
      , 'revoke'
      , 'set password'
      , 'open'
      , 'use'

      // table maintenance
      , 'analyze'
      , 'check table'
      , 'checksum table'
      , 'optimize'
      , 'repair table'
      , 'table'

      // plugins...
      , 'create aggregate function'
      , 'create function'
      , 'returns'
      , 'drop function'
      , 'uninstall plugin'
      , 'install plugin'

      // misc
      , 'set'
      , 'show'
      , 'binlog'
      , 'cache'
      , 'flush'
      , 'kill'
      , 'load'
      , 'reset'
      , 'shutdown'

      // utilities
      , 'describe'
      , 'explain'
      , 'help'
      , 'use'

    ];

    for (const badStuff of stuffToFail) {
      const pattern = '\\b' + badStuff + '\\b';
      const regex = new RegExp(pattern, 'i');
      if (regex.test(s)) {
        throw new Error('SQL template may not include "' + badStuff.toUpperCase() + '" directives.');
      }
    }

    const pattern2 = '_table\\b';
    const regex2 = new RegExp(pattern2, 'i');
    if (regex2.test(s)) {
      throw new Error('SQL template may not include direct "_table" references.');
    }
  }

  clearBinaryContent(): void {
    try {
      if (this.resource) {
        this.resource[this.f.name].value = Globals.MAGIC_NULL_STRING_VALUE;
        this.resource[this.f.name].changed = true;
        this.resource[this.f.fileNameField].value  = Globals.MAGIC_NULL_STRING_VALUE;
        this.resource[this.f.name].changed = true;
        this.resource[this.f.fileSizeField].value  = 0;
        this.resource[this.f.name].changed = true;
        this.resource[this.f.fileTypeField].value  = Globals.MAGIC_NULL_STRING_VALUE;
        this.resource[this.f.name].changed = true;

        this.eventServerService.saveResource(this.resource).subscribe(
          (result: WEvent) => {
            console.log(result);
            try {
              if (result.status !== 'OK') {
                throw new Error(result.message);
              }
            } catch (ex) {
              const msg = 'clearBinaryContent()\n';
              this.userInterfaceService.alertUserToException(ex, msg);
            }
          }
        );
      }
    } catch (ex) {
      const msg = 'clearBinaryContent()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
