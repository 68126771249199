import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-value-simplicity',
  templateUrl: './value-simplicity.component.html',

})
export class ValueSimplicityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
