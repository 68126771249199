<div class="row">
    <div class="col-sm-4">
        <div class="card">
            <div class="card-header d-flex justify-content-between">
                <div>
                    <wackadoo-field [f]="resource.templateName" [mode]="mode"></wackadoo-field>
                </div>
                <div>
                    <wackadoo-field [f]="resource.templateExtension" [mode]="mode" [onChange]="extensionChange"></wackadoo-field>
                </div>
            </div>
            <div class="card-body smallish">
                <wackadoo-file-details [fileContentField]="resource.templateFileContent" ></wackadoo-file-details>
                <div>
                    Last Modified: <wackadoo-field [f]="resource.modified" mode="read"></wackadoo-field>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-8">
        <div class="card">
            <div class="card-header">
                File Content
            </div>
            <div class="card-body">
                <wackadoo-field-binary
                    [f]="resource.templateFileContent"
                    [mode]="mode"
                    [validExtensions]="resource.templateExtension.value"
                    [resource]="resource"

                    wackadooMaximizeContentHeight
                    [maxHeightThingBelowSelector]="'div[wackadooDetailButtonBarPlaceholder]'"
                    [maxHeightFudgeFactor]="-24"
                ></wackadoo-field-binary>
            </div>
        </div>
    </div>   
</div>
