		<div class="card">
			<div class="card-header">
				Data Security Policy...
			</div>
			<div class="card-body">
				<p>Like you, we take data security seriously. For us, that starts at the browser - where you may have noticed that you're 
				already reading this over an encrypted connection - and continues on through firewalls to our servers, development practices,
				and administrative protocols.</p>
				<p>You need to know that despite our best efforts to secure your data: 
				<span class="italic">"No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security."</span></p>
				<p>
					That quote is borrowed from our internet registrar partner's web site - and we completely agree with it. 
					We cannot guarantee the absolute security of your data. 
					Nevertheless, we will do our absolute best to keep your data secure.
				</p>
				<p>
					Here are some examples of how we currently keep our web apps and your data secure:
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						Our servers run behind a locked-down firewall at a world class hosting provider.
					</li>
					<li>
						We force *all* web access to use SSL/TLS, so all traffic is encrypted - including innocuous pages, like this one.
					</li>
					<li>
						We don't keep your credit card information.  We never touch it. You send it directly to our 3rd party 
						payment vendor.  We want you to know that your credit card information is protected by an industry leader 
						when you set up your credit card payments.
					</li>
					<li>
						We work with our credit card service provider <a href="https://www.braintreepayments.com/">Braintree Payments (a PayPal company)</a> 
						and their PCI compliance partner, <a href="https://www.securitymetrics.com">SecurityMetrics</a> to remain 
						PCI compliant. We will be obtaining a graphical representation of that certification for our billing page 
						in the near future.
					</li>
					<li>
						Once you have logged in, every single functional server request you make is tagged with 
						a random token, which improves your (and our...) protection against something called a CSRF attack.  
					</li>
					<li>
						We protect against click-jacking by denying any web site from embedding our web apps in a frame or iframe.
					</li>
					<li>
						We protect against XSS by implementing Content Security Policy, or CSP, rules in our web app platform 
						that only allow resources, especially scripts, to load and run if they came from our own servers.  
						And we do not allow inline or embedded scripts of any kind in our web apps, which protects against 
						scripts being injected into any of our web apps.
					</li>
					<li>
						We protect against SQL injection attacks by never building a SQL command via string manipulation. 
						We use only bound parameters.
					</li>
					<li>
						All our web apps require each user to be explicitly assigned a role for that particular application. 
						This user role controls access to components (and the functions within those components) of that web app.
						When your request reaches the server, every single component of our software verifies that you are allowed 
						access to that component on every single request that you make. And most pages that you see are made up of 
						numerous behind-the-scenes server requests, each of which are validated in exactly the same way.
					</li>
					<li>
						We reduce risks associated with unauthorized server access by encrypting all sensitive information 
						(passwords, credentials, etc.) within our server configuration files.
					</li>
					<li>
						We protect against password database leaks by hashing your password using BCRYPT, which, although no longer 
						the *most* secure one-way hashing algorithm in existence, is still near the top of the list. 
						(BCRYPT is considered secure enough that even if someone should get hold of our password database for 
						nefarious purposes, it would simply take them too much time and money to crack the passwords.)
					</li>
				</ul>
			</div>
		</div>

