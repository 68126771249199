import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'column' })
export class ColumnPipe implements PipeTransform {
  // input is an array of any
  // mod is the modulo, every mod items, create a row
  // but this does it in columns instead of rows...
  transform(input: any[], mod: number): any[][] {
    const rows = Math.ceil(input.length / mod);
    return input.reduce(
      (previous, next, index) => {
        if (index < rows) {
            previous.push([next]);
        } else {
            previous[(index % rows)].push(next);
        }
        return previous;
      },
      [] as any[][]
    );
  }
}
