import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { UserInterfaceService } from '../../services/user-interface.service';
import { UserAuthService } from '../../services/user-auth.service';
import { PageComponent } from '../page/page.component';
import { Globals } from '../../services/global.service';
import { AppSpecificPublicContentPlaceholderDirective } from './public-content-placeholder.directive';
import { BusinessRuleService } from '../../services/business-rule.service';
import { Subscription } from 'rxjs';
import { ScreenType } from '../../services/screen-type.enum';

@Component({
  selector: 'wackadoo-home-app',
  templateUrl: './home-app.component.html',
})
export class HomeAppComponent extends PageComponent implements OnInit, OnDestroy, AfterViewInit {

  // this is NOT static because it is inside dynamic content...
  @ViewChild(AppSpecificPublicContentPlaceholderDirective, { static: false }) appSpecificPublicContent: AppSpecificPublicContentPlaceholderDirective;

  isLoggedIn = false;
  hasAlternateFirstPage = false;

  showWackadooContent = true;
  appName = '';
  domain = '';

  screenTypeChangeSubscription: Subscription = null;

  constructor(
    public userAuthService: UserAuthService,
    public userInterfaceService: UserInterfaceService,
    private _businessRuleService: BusinessRuleService,
  ) {
    super(userAuthService, userInterfaceService);

    // These are used to determine if we show anything on this page prior to BusinessRule
    // and UI services bouncing the user to the "first EH page", which occurs else where...
    // (This just eliminates an ugly, short term page flash when first coming into this web-app...)
    this.isLoggedIn = this.userAuthService.isLoggedIn;
    // console.log('home', typeof this._businessRuleService.businessRules.firstEHPage);
    this.hasAlternateFirstPage = (typeof this._businessRuleService.businessRules.firstEHPage !== 'undefined');
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.showWackadooContent = (this.screenType !== 'phone') || (this.user.accountID === 1);
    this.appName = Globals.thisApplication;
    this.domain = Globals.domain;

    this.screenTypeChangeSubscription = this.userInterfaceService.screenType.subscribe(
      (st: ScreenType) => {
        this.screenType = st;
        this.showWackadooContent = (this.screenType !== 'phone') || (this.user.accountID === 1);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.screenTypeChangeSubscription) {
      this.screenTypeChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (this.appSpecificPublicContent) {
      this.userInterfaceService.loadComponent(this.appSpecificPublicContent.viewContainerRef, Globals.thisApplication + 'PublicContentComponent', {user: this.user}, true);
    }

  }

  get showPublicContentForAccount(): boolean {
    // for account-specific public user access, accountID > 1
    // (i.e. if we're on www, accountID <= 1, but if
    //  we're on some account's accountUrlPrefix, accountID > 1)
    // console.log('showPublicContentForAccount()', this.user, this.user.isPublicOnly, this.user.accountID);
    return this.user && this.user.isPublicOnly && (this.user.accountID > 1);
  }

  toggleWackadooContent(): void {
    this.showWackadooContent = !this.showWackadooContent;
  }

}
