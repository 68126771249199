import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-new-customer-not-cancelled',
  templateUrl: './new-customer-not-cancelled.component.html',
})
export class NewCustomerNotCancelledComponent implements OnInit {

  message = '';

  constructor() { }

  ngOnInit(): void {
  }

}
