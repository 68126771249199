export class EMailerContent {
  eventHandler = null;
  parms = {};
  title = '';

  constructor(eventHandler: string, parms: any, title?: string) {
    this.eventHandler = eventHandler;
    this.parms = parms;
    this.title = title;
  }
}
