import { WField, WString } from './field.model';

export class WResource {
    private '@name': string;
    private '@type': string;

    // HACK ALERT: @application is only used by the EventBroadcaster...
    private '@application': string;

    // HACK ALERT: @className is only used by Statistics and Status resources...
    private '@className': string;

    // The catch-all for the fields. The "any" allows us to put other named
    // stuff (@name, @type and the methods below...) in the Resource too...
    [fieldName: string]: any | WField;

    static factory(type: string, fieldAPIs: any [], eventHandler: string, resourceToClone?: any): WResource {

        // console.log('Resource.factory() - enter', type, fieldAPIs, eventHandler, resourceToClone);

        const r: WResource = new WResource();
        r['@type'] = type;

        // if necessary, merge all the Resource stuff from the server into the just-created instance
        // (note that this may over-write the @type...)
        if (resourceToClone) {
            r['@name'] = resourceToClone['@name'];

            // HACK ALERT: @application is only used by the EventBroadcaster...
            if (resourceToClone['@application']) {
                r['@application'] = resourceToClone['@application'];
            }

            // HACK ALERT: @className is only used by Statistics and Status resources...
            if (resourceToClone['@className']) {
                r['@className'] = resourceToClone['@className'];
            }

            // pull in all the parameters as WFields...
            for (const [name, fieldToClone] of Object.entries(resourceToClone)) {
                // we do not touch the @ attribute tags at the resource level...
                // (e.g. @name and @type...) because they are not fields...
                if (!name.startsWith('@')) {
                    const fieldAPI = (fieldAPIs ? fieldAPIs.find((fd) => fd.name === name) : null);
                    r[name] = WField.factory((fieldToClone as any).type, name, (fieldToClone as any).text, eventHandler, fieldAPI, fieldToClone);
                }
            }
        }

        // populate all the "missing" fields w/empty fields...

        // Note: Sometimes we get null fieldAPIs e.g. "status" resources are part of the
        // Administration EH, and NOT in the user's API, so there would be no fieldAPIs...
        // Except for Report resources, which might either define the Report, or be a Report result.
        // (If we have no reportName, we are looking at a Report result, rather than a Report defn...)

        if (fieldAPIs) {
            for (const fieldAPI of fieldAPIs) {
                if (!r[fieldAPI.name]) {
                    if ((eventHandler !== 'Reports') || r.reportName) {
                        r[fieldAPI.name] = WField.factory(fieldAPI.type, fieldAPI.name, null, eventHandler, fieldAPI);
                    }
                }
            }
        }

        // console.log('Resource.factory() - exit', type, fieldAPIs, eventHandler, resourceToClone, r);

        return r;
    }

    constructor() {
    }

    getName(): string {
        return this['@name'];
    }

    getType(): string {
        return this['@type'];
    }

    // HACK ALERT: @application is only used by the EventBroadcaster...
    getApplication(): string {
        return this['@application'];
    }

    // HACK ALERT: @className is only used by Statistics and Status resources...
    getClassName(): string {
        return this['@className'];
    }

    get asParms(): any {
        const res = {};
        for (const f of Object.values(this.fields)) {
            if (f instanceof WField) {
                if (!f.isNull) {
                    res[f.name] = f.value;
                }
            }
        }
        // console.log(res);
        return (res);
    }

    resetDisplayLabel(): void {
        this.keyField.displayValue = '';

        for (const f of Object.values(this.fields)) {
            if (f.displayLabel === true) {
                this.keyField.displayValue += ((f.displayLabelPrefix ? f.displayLabelPrefix : ' ') + (f.displayValue ? f.displayValue : f.value));
            }
        }
        this.keyField.displayValue = this.keyField.displayValue.replace(/null/g, ' ');
        this.keyField.displayValue = this.keyField.displayValue.replace(/  /g, ' ');
        this.keyField.displayValue = this.keyField.displayValue.trim();
    }

    addAllFields(r: WResource, includeNullFields?: boolean): void {
        if (r) {
            this['@type'] = r['@type'];
            includeNullFields = typeof includeNullFields === 'boolean' ? includeNullFields : false;
            for (const f of r.fields) {
                if (includeNullFields || !f.isNull) {
                    this[f.name] = f.clone;
                }
            }
        }
    }

    get clone(): WResource {
        const r = new WResource();
        r['@name'] = this['@name'] + '(clone)';
        r['@type'] = this['@type'];
        for (const f of this.fields) {
            r[f.name] = f.clone;
        }
        return r;
    }

    removeAllNullFields(): void {
        for (const f of this.fields) {
            if (f.isNull) {
                delete this[f.name];
            }
        }
    }

    get fields(): WField [] {
        const fa: WField [] = [];

        // we get all the fields from this resource...
        // (BIG ASSUMPTION: Object.values() returns Fields in their "natural" order...)
        for (const v of Object.values(this)) {
            if (v instanceof WField) {
                fa.push(v);
            }
        }

        // Q: Do we sort the fields by the fields' "number" attribute?
        // A: NO! We leave them in their "natural" order...
        //    (a lot of things are counting on that... too numerous to catalog...)
        // fa = fa.sort(
        //     (f1: Field, f2: Field) => {
        //         return (f1.number > f2.number ? 1 : (f1.number === f2.number ? 0 : -1));
        //     }
        // );

        return fa;
    }

    get keyField(): WField {
        return Object.values(this.fields).find((f) => f.key);
    }

    get defaultSortField(): WField  {
        return Object.values(this.fields).find((f) => f.defaultSort);
    }

    getField(name: string): WField {
        return Object.values(this.fields).find((f) => f.name === name);
    }

    setField(name: string, value: any): void {
        let field = this.getField(name);
        if (field) {
            field.changed = (field.value !== value);
            field.value = value;
        } else {
            field = new WField(name, value, 'String');
            field.changed = true;
            // console.log('Added new String field "' + name + '" in resource!', this, field);
        }
    }

    addExtraField(f: WField, overWrite?: boolean): void {
        overWrite = typeof overWrite === 'boolean' ? overWrite : false;
        if (!this[f.name] || overWrite) {
            this[f.name] = f;
        } else {
            throw new Error('Field already exists: ' + f.name);
        }
    }

    setFieldsFromParms(parms: any): void {
        for (const name in parms) {
            if (parms[name] !== null) {
                const value = parms[name];
                let field = this.getField(name);
                if (field) {
                    // you never mark the key field as changed...
                    if (!field.key) {
                        field.changed = (field.value !== value);
                    }
                    field.convertToTypeAndSetFieldValue(value);
                } else {
                    field = new WString(name, value);
                    this.addExtraField(field);
                }
            }
        }
    }

    getChangedFieldValuesAsParms(includeKey?: boolean): object {
        includeKey = typeof includeKey === 'boolean' ? includeKey : false;

        let parms = {};
        if (includeKey) {
            parms = this.keyField.asParm;
        }

        const changedFields = Object.values(this.fields).filter((f) => f.changed === true);
        for (const field of changedFields) {
            parms[field.name] = field.value;
        }

        return parms;
    }

    hasChangedFields(): boolean {
        return (Object.values(this.fields).filter((f) => f.changed === true).length > 0);
    }

    markAllFieldsAsChanged(includeFieldsWithNullValues?: boolean): void {
        includeFieldsWithNullValues = typeof includeFieldsWithNullValues !== undefined ? includeFieldsWithNullValues : false;
        for (const f of this.fields) {
            if (!f.key ) {
                if (!f.isNull || includeFieldsWithNullValues) {
                    f.changed = true;
                }
            }
        }
    }

    clearAllFields(includeKey?: boolean): void {
        includeKey = typeof includeKey !== undefined ? includeKey : false;
        for (const f of this.fields) {
            if (!f.key || includeKey) {
                f.value = (f.default ? f.default : null);
            }
        }
    }

    markAllFieldsAsUnChanged(): void {
        for (const f of this.fields) {
            f.changed = false;
        }
    }

    identifyMissingOrInvalidField(checkRequiredFields: boolean): WField {
        let badField: WField = null;
        const changedOrRequiredFields = Object.values(this.fields).filter((f) => (f.changed === true) || (checkRequiredFields && (f.required === true)));
        for (const f of changedOrRequiredFields) {
            // console.log('WResource.identifyMissingOrInvalidField()', 'name: ' + f.name, 'value: ' + f.value, 'valid: ' + f.isValidValue(f.value), f, 'required: ' + f.required);
            if (f.required) {
                if (!f.isPopulated) {
                    badField = f;
                } else if (!f.isValidValue(f.value)) {
                    badField = f;
                }
            } else if (f.changed) {
                badField = (f.isValidValue(f.value) ? null : f);
            }
            if (badField) {
                // console.log('WResource.identifyMissingOrInvalidField()', 'Bad field: ' + badField.name);
                break;
            }
        }
        return badField;
    }

    identifyUnPopulatedRequiredField(): WField {
        let badField: WField = null;
        const requiredFields = Object.values(this.fields).filter((f) => (f.required === true));
        for (const f of requiredFields) {
            // console.log('WResource.identifyUnPopulatedRequiredField()', 'name: ' + f.name, 'value: ' + f.value, 'valid: ' + f.isValidValue(f.value), f, 'addMode: ' + addMode, 'required: ' + f.required, (addMode && f.required));
            if (f.required) {
                if (!f.isPopulated) {
                    badField = f;
                } else if (!f.isValidValue(f.value)) {
                    badField = f;
                }
            }
            if (badField) {
                // console.log('WResource.identifyUnPopulatedRequiredField()', 'Bad field: ' + badField.name);
                break;
            }
        }
        return badField;
    }

}
