import { Component, OnInit, ElementRef, OnDestroy, ComponentFactoryResolver, ComponentRef, ViewChild, AfterViewInit } from '@angular/core';
import { Subscriber, Subscription } from 'rxjs';
import { ResourceModalContent } from './resource-modal-content';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { ResourceModalPlaceholderDirective } from './resource-modal-placeholder.directive';
import { WResource } from '../../../data/resource.model';
import { FieldMode } from '../../../data/field/field-mode.model';
import { EventServerService } from '../../../services/event-server.service';
import { WEvent } from '../../../data/event.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';

@Component({
  selector: 'wackadoo-resource-modal',
  templateUrl: './resource-modal.component.html',
})
export class ResourceModalComponent implements OnInit, OnDestroy, AfterViewInit {

  visible = false;

  showModalSubscription: Subscription;

  observer: Subscriber<WEvent> = null;

  componentName = null;
  resource: WResource = null;
  mode: FieldMode = null;
  eventHandler: string = null;
  action: string = null;

  @ViewChild(ResourceModalPlaceholderDirective, { static: false }) modalResourceDialogContentPlaceholder: ResourceModalPlaceholderDirective;

  private _dynamicComponentRefsToDestroy: ComponentRef<any> [] = [];

  private _reDisplayTriggerTimeout: any = null;

  okButtonTitle = 'Save';

  constructor(
    public modalDialogService: ModalDialogService,
    public elementRef: ElementRef,
    public componentFactoryResolver: ComponentFactoryResolver,
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
  ) {
  }

  ngOnInit(): void {

    this.clearModal();

    this.showModalSubscription = this.modalDialogService.showModalResourceSubject.subscribe(
      (modalPageContent: ResourceModalContent) => {
        try {
          // console.log('ModalResourceComponent.showModalSubscr()', modalPageContent, this.componentName, this.resource, this.mode, this.eventHandler, this.action);

          if (modalPageContent) {
            this.observer = modalPageContent.observer;
            this.componentName = modalPageContent.componentName;
            this.resource = modalPageContent.resource;
            this.mode = modalPageContent.mode;
            this.eventHandler = modalPageContent.eventHandler;
            this.action = modalPageContent.action;
            if (modalPageContent.okButtonTitle) {
              this.okButtonTitle = modalPageContent.okButtonTitle;
            }

            // Now we do special-case handling for "add" operations...

            if (!this.resource && (this.action === 'add')) {

              // The detail page could use ANY of the possible fields be present in the new
              // resource - even if the fields all have null values - including for add().
              // We cannot use "new Resource()" because that creates a field-less Resource.
              // We need to use Resource.factory(), which assigns null to all the defined
              // fields in the user's API - and ESSvc.newResource() is a convenience
              // function that handles that.

              const blankResource = this.eventServerService.newResource(this.eventHandler);

              // Note that we want to disable the user's ability to enter a value in the ID field
              // for this new resource, because that will be generated on the server...
              blankResource.keyField.readOnly = true;
              // but we need a null value here so we can have something sensible in the currentSelection div...
              blankResource.keyField.value = null;
              blankResource.keyField.displayValue = 'new, unsaved ' + blankResource.getType();

              this.resource = blankResource;
            }

            // if we're doing an add, and some of the values have been "pre-populated",
            // then we definitely want those values considered as user input...
            if (this.action === 'add') {
              this.resource.markAllFieldsAsChanged();
            }

            // make the dialog visible

            this.visible = true;

            this.triggerReDisplay();

          } else {
            this.clearModal();
          }
        } catch (ex) {
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }

    for (const cr of this._dynamicComponentRefsToDestroy) {
      cr.destroy();
    }
  }

  ngAfterViewInit(): void {
    this.triggerReDisplay();
  }

  triggerReDisplay(): void {
    if (this._reDisplayTriggerTimeout) {
      window.clearTimeout(this._reDisplayTriggerTimeout);
    }
    this._reDisplayTriggerTimeout = window.setTimeout(
      () => {
        // populate the component...

        this.loadComponent(this.componentName, this.resource, this.mode);

        // re-focus on the cancel/close button...

        const closeButton: HTMLButtonElement = this.elementRef.nativeElement.querySelector('#close') as HTMLButtonElement;
        // console.log(closeButton);
        if (closeButton) {
          window.setTimeout(() => closeButton.focus(), 0);
        }

      },
      0
    );
  }

  get showOKButton(): boolean {
    return (this.action !== null);
  }

  get cancelButtonTitle(): string {
    return (this.mode === FieldMode.read ? 'Close' : 'Cancel');
  }

  clearModal(): void {
    this.visible = false;
    this.componentName = null;
    this.resource = null;
    this.mode = null;
    this.eventHandler = null;
    this.action = null;
    this.observer = null;
  }

  // this is any so we can extend this class for other response types...
  closeModal(subscriptionResponse: any): void {
    this.visible = false;
    this.componentName = null;
    this.resource = null;
    this.mode = null;
    this.eventHandler = null;
    this.action = null;
    // have to fire this BEFORE we null it out...
    if (this.observer) {
      this.observer.next(subscriptionResponse);
    }
    this.observer = null;

  }

  onOK(): void {
    const addMode = (this.action === 'add');
    const badField = this.resource.identifyMissingOrInvalidField(addMode);
    if (addMode && badField) {
      this.modalDialogService.showAlert('Invalid field value or missing required field: ' + badField.name, 'Error');
      this.userInterfaceService.focusOnField(this.elementRef, badField);
    } else if ((this.eventHandler !== null) && (this.action !== null)) {
      const includeKey = !addMode;
      const parms = this.resource.getChangedFieldValuesAsParms(includeKey);
      // console.log('ModalResourceDialogComponent.onOK()', this.eventHandler, this.action, parms, this.resource);
      this.eventServerService.fireEvent(this.eventHandler, this.action, parms).subscribe(
        (event: WEvent) => {
          // it is up to the calling page to deal with any error(s)...
          this.closeModal(event);
        }
      );
    } else {
      // this.modalDialogService.showAlert('Invalid Event request for modal resource dialog: ' + this.eventHandler + ', ' + this.action);
      this.closeModal(this.resource);
    }
  }

  onCancel(): void {
    if (this.action === 'modify') {
      // Note: this.resource has been modified, and is visible to the parent dialog,
      // so we have to "un-do" any changes made to the field values in this resource...
      this.eventServerService.loadResourceFromServer(this.eventHandler, this.resource.keyField.asParm).subscribe(
        (resource: WResource) => {
          this.resource = resource;
          this.closeModal(null);
        }
      );
    } else {
      this.closeModal(null);
    }
  }

  loadComponent(componentClassName: string, resource: WResource, mode: FieldMode): void {
    if (resource) {
      if (this.modalResourceDialogContentPlaceholder) {
        this.userInterfaceService.loadComponent(
          this.modalResourceDialogContentPlaceholder.viewContainerRef,
          componentClassName,
          {
            resource,
            mode,
          }
        );
      }
    }
  }

}
