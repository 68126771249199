<div class="smallish">
  <div class="row">
    <div class="col-sm-3">
      <div class="card">
        <div class="card-header">
          <span class="large">Available E-Mail Reports</span>
          <div  *ngIf="extraParameters && (extraParameters.fields.length > 0)">
            <div class="marginLeftHalfEm">
              Additional Report Parameters
            </div>
            <div class="marginLeftWholeEm d-flex flex-wrap">
              <ng-container *ngFor="let f of extraParameters.fields; let i = index;" >
                  <div class="unbold smallish nowrap">
                    {{ f.name }} <wackadoo-field [f]="f" [onChange]="extraParameterSubjects[i]" mode="write"></wackadoo-field> &nbsp; 
                  </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="card-body max-height-200-px min-height-200-px scrollable">
          <!-- this loads the filtered list of email reports -->
          <div *ngFor="let r of reports; let idx = index;"
              title="Click to select report..." 
              (click)="clickReport(r)"
              class="rollover border-bottom" 
              [ngClass]="{selected:(selectedReport && (r.keyField.value === selectedReport.keyField.value))}"
          >
            {{r.reportName.value}}
            <span class="smallish italic" *ngIf="r.userQueryParms && r.userQueryParms.value">&nbsp; (requires {{ r.userQueryParms.value }})</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-9">
      <div class="card">
        <div class="card-header large d-flex justify-content-between">
          <div>
            Selected Report Output
          </div>
          <div class="italic smallish unbold marginTopQuarterEm" *ngIf="reportResults">
            ({{reportResults.length}} Results)
          </div>
          <div class="btn-group" *ngIf="reportResults">
            <button type="button" (click)="toggleEMailAddresses()" class="btn btn-secondary small">
              Show Just E-Mail Addresses
            </button>
          </div>
        </div>
        <div class="card-body">
          <div *ngIf="reportResults && selectedReport ;  else noReportSelected">
            <div *ngIf="reportResults.length > 0 ; else noReportResults">
              <div *ngIf="showReportResults" class=" max-height-200-px min-height-200-px wrap-scrollable-table">
                <table class="table">
                  <thead>
                    <tr>
                      <th *ngFor="let f of reportResults[0].fields">
                        {{f.key ? '' : f.name}}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let r of reportResults; let idx = index;">
                      <td *ngFor="let f of r.fields">
                        {{f.key ? '' : f.value}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div *ngIf="!showReportResults" class="max-height-200-px min-height-200-px scrollable" >
                {{emailAddresses}}
              </div>
            </div>
            <ng-template #noReportResults>
              <div class="italic blue max-height-200-px min-height-200-px">No results for this report...</div>
            </ng-template>
          </div>
          <ng-template #noReportSelected>
            <div class="italic blue max-height-200-px min-height-200-px">Report results for selected report go here...</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-3">
      <div class="card">
        <div class="card-header large">
          E-Mail Templates
        </div>
        <div class="card-body max-height-200-px min-height-200-px scrollable">
          <!-- this auto-loads the filtered list of email templates -->
          <div 
              *wackadooResourceBlock="let r of []; let idx = index; eventHandler:'EMailTemplates'; action:'list'; parms: {pageSize: -1};"
              title="Click to select email template..." 
              (click)="clickEMailTemplate(r)" 
              class="rollover border-bottom" 
              [ngClass]="{selected:(selectedEMailTemplate && (r.keyField.value === selectedEMailTemplate.keyField.value))}"
            >
            {{r.templateName.value}}
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card">
        <div class="card-header large">
          Test E-Mail
        </div>
        <div class="card-body">
          <div class="italic marginHalfEm">
            This allows you to see what the email will look like, and how it will be received.
            One of the many email messages to be generated for the Selected Report Output above will be randomly chosen, 
            formatted using the selected EMail Template, and sent to the test email address that you provide here.
          </div>
          <div class="center">
            <div class="marginHalfEm">
              <wackadoo-field-string [f]="testEmailAddress" [mode]="'write'" [size]="32"></wackadoo-field-string>
            </div>
            <div class="btn-group">
              <button type="button" id="testEmail" class="btn btn-secondary" (click)="sendEMail(true)">
                <span class="fas fa-envelope" aria-hidden="true"></span>
                Test E-Mail
              </button>
            </div>
          </div>
          <div>
            <br/>
          </div>
          <div class="marginHalfEm">
            (In order to avoid spam filters, the email will be "from" <i>{{domain}}</i>, but in the name of <i>{{accountName}}</i>.)
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="card">
        <div class="card-header large">
          Send E-Mail
        </div>
        <div class="card-body">
          <div class="italic marginHalfEm">
            This generates ALL of the email messages for the Selected Report Output above, 
            formatting each one using the selected EMail Template.
          </div>
          <div class="center">
            <button type="button" id="sendBulkEmail" class="btn btn-secondary" (click)="sendEMail(false)">
              <span class="fas fa-envelope" aria-hidden="true"></span>
              Send Bulk E-Mail
            </button>
          </div>
          <div>
            <br/>
          </div>
          <div class="marginHalfEm">
            (In order to avoid spam filters, the email will be "from" <i>{{domain}}</i>, but in the name of <i>{{accountName}}</i>.)
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
