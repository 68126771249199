		<div class="card">
			<div class="card-header">
				Service Level Agreement
			</div>
			<div class="card-body">
				<p>
					We strive for, and fully expect, but do not guarantee, 99.4% service availability. 
					This would give us almost 9 minutes per day for maintenance - which is about the 
					time it takes to stop the service, roll in a complete new software update and bring 
					the service back up.
				</p>
				<p>
					Clearly, we do not expect to have to do that on a daily basis. As our operational
					procedures mature, that availability will certainly increase - or we're not doing  
					our jobs correctly.
				</p>
				<p>
					To reiterate an applicable part of our Maintenance Policy:
				</p>
				<p class="well italic">
					Regardless, whenever we must impact availability, we will generate a system 
					administration message, seen by all active users, indicating the nature of the work, 
					and the expected time that our services will be back online.
				</p>
				<p>
					As for disaster recovery, in the event that a system-level recovery becomes necessary,
					we will restore data to the most recent, valid system backups that we have available. 
					That could mean the loss (on your part) of one or more days' worth of work - especially 
					if you have not backed up your data recently. <span class="bold italic">(See our Backup 
					Policy for a more detailed explanation of this important information.)</span>
				</p>
			</div>
		</div>
