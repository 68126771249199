import { Component } from '@angular/core';
import { BinaryComponent } from '../binary.component';

@Component({
  selector: 'wackadoo-field-binary-audio',
  templateUrl: './binary-audio.component.html',

})
export class BinaryAudioComponent extends BinaryComponent {

}
