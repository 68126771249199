<div class="marginBottomHalfEm" [ngClass]="{ 'd-flex justify-content-between' : screenType !== 'phone'}">
    <div class="nowrap">
        Prefix:
        <wackadoo-field [f]="resource.fffPrefix" [mode]="mode"></wackadoo-field>
    </div>
    <div class="nowrap">
        Delimiter:
        <wackadoo-field [f]="resource.fffPrefixDelimiter" [mode]="mode"></wackadoo-field>
    </div>
    <div class="nowrap">
        Type:
        <wackadoo-field [f]="resource.fffType" [mode]="mode"></wackadoo-field>
    </div>
    <div class="nowrap" *ngIf="resource.fffType.value === 'String'">
        Label:
        <wackadoo-field [f]="resource.fffLabel" [mode]="mode"></wackadoo-field>
    </div>
    <div class="nowrap" *ngIf="resource.fffType.value === 'String'">
        Partial:
        <wackadoo-field [f]="resource.fffPartial" [mode]="mode"></wackadoo-field>
    </div>
    <div class="nowrap" *ngIf="resource.fffType.value === 'String' && resource.fffPartial.value !== 'all'">
        {{resource.fffPartial.value === 'regex' ? 'RegEx' : 'Separator'}}:
        <wackadoo-field [f]="resource.fffPartialValue" [mode]="mode" [size]="resource.fffPartial.value === 'regex' ? 25 : 1"></wackadoo-field>
    </div>
    <div class="btn-group">
        <button type="button" (click)="save()" title="{{saveButtonTitle}}" data-abbrev="" class="btn btn-secondary actionButton small"><span class="fas fa-check" aria-hidden="true"></span></button>
        <button type="button" (click)="delete()" title="Delete" data-abbrev="" class="btn btn-secondary actionButton small" *ngIf="resource.fffID.isPopulated"><span class="fas fa-times" aria-hidden="true"></span></button>
    </div>
</div>
