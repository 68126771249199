import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-registered-applications',
  templateUrl: '../../../client-core/pages/repository-page/repository-page.component.html',
})
export class RegisteredApplicationsComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.gridResourceComponentClassName = 'RegAppComponent';
    this.detailContentComponentClassName = 'RegAppDetailContentComponent';

    super.ngOnInit();
  }

}
