import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-policy-cookies',
  templateUrl: './policy-cookies.component.html',

})
export class PolicyCookiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
