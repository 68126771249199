import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Globals } from '../../services/global.service';
import { Subscription } from 'rxjs';
import { UserAuthService } from '../../services/user-auth.service';
import { User } from '../../data/user.model';
import { UserInterfaceService } from '../../services/user-interface.service';

@Component({
  selector: 'wackadoo-footer',
  templateUrl: './footer.component.html',

})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() showFooter = true;

  orgNameURL: string;
  orgName: string;
  copyrightYear: number;

  isLoggedIn = false;
  userAuthChangeSubscription: Subscription;

  hidePublicFooterContent = false;
  hidePublicContentSubscription: Subscription = null;

  constructor(
    private _userAuthService: UserAuthService,
    private _userInterfaceService: UserInterfaceService,
  ) {
  }

  ngOnInit(): void {
    this.orgName = Globals.organizationName;
    this.orgNameURL = Globals.organizationURL;
    this.copyrightYear = Globals.copyrightYear;

    this.isLoggedIn = this._userAuthService.isLoggedIn;

    this.userAuthChangeSubscription = this._userAuthService.currentUser.subscribe(
      (user: User) => {
        if (user) {
          this.isLoggedIn = this._userAuthService.isLoggedIn;
          // console.log('footer user: ' + user.fullName + ' - ' + user.accountName + ' - is logged in: ' + this.isLoggedIn, user);
        }
      }
    );

    this.hidePublicFooterContent = this._userInterfaceService.hidePublicFooterContent.getValue();

    this.hidePublicContentSubscription = this._userInterfaceService.hidePublicFooterContent.subscribe(
      (flag: boolean) => {
        this.hidePublicFooterContent = flag;
      }
    );

  }

  ngOnDestroy(): void {
    if (this.userAuthChangeSubscription) {
      this.userAuthChangeSubscription.unsubscribe();
    }
    if (this.hidePublicContentSubscription) {
      this.hidePublicContentSubscription.unsubscribe();
    }
  }

  get isRootApp(): boolean {
    return Globals.thisApplication === Globals.rootApplication;
  }

  goToPricing(): void {
    this._userInterfaceService.goToPricing();
  }

}
