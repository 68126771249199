import { Component, OnInit } from '@angular/core';
import { SelectValueContent } from './select-value-content';
import { FieldMode } from '../../../data/field/field-mode.model';
import { ResourceModalComponent } from '../resource-modal/resource-modal.component';

@Component({
  selector: 'wackadoo-select-value',
  templateUrl: './resource-modal.component.html',
})
export class SelectValueComponent extends ResourceModalComponent implements OnInit {

  // This is the ModalResourceComponent, EXCEPT...
  //  - we re-label the OK button to "Select"
  //  - we load a different set of values in ngOnInit,
  //  - we return a single field value from the resource onSave()...
  // (Note that the component is responsible for setting read/write on the appropriate fields in the resource...)

  fieldName = null;

  ngOnInit(): void {

    this.okButtonTitle = 'Select';

    this.clearModal();

    this.mode = FieldMode.read;

    this.showModalSubscription = this.modalDialogService.showSelectValueSubject.subscribe(
      (modalPageContent: SelectValueContent) => {
        try {
          if (modalPageContent) {
            this.observer = modalPageContent.observer;
            this.componentName = modalPageContent.componentName;
            this.resource = modalPageContent.resource;
            this.fieldName = modalPageContent.fieldName;
            if (modalPageContent.selectButtonTitle) {
              this.okButtonTitle = modalPageContent.selectButtonTitle;
            }

            // make the dialog visible

            this.visible = true;

            this.triggerReDisplay();

          } else {
            this.clearModal();
          }
        } catch (ex) {
          this.modalDialogService.showAlert(ex.message, 'Error');
        }
      }
    );
  }

  clearModal(): void {
    this.fieldName = null;
    super.clearModal();
  }

  closeModal(subscriptionResponse: any): void {
    this.fieldName = null;
    super.closeModal(subscriptionResponse);
  }

  get showOKButton(): boolean {
    return true;
  }

  onOK(): void {
    this.closeModal(this.resource.getField(this.fieldName).value);
  }

}
