import { Component, OnInit } from '@angular/core';
import { EventServerService } from '../../services/event-server.service';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';

@Component({
  selector: 'wackadoo-check-for-res-rep',
  templateUrl: './check-for-res-rep.component.html',
})
export class CheckForResRepComponent implements OnInit {

  pageName: string;
  resourceType: string;
  isResRepEH = false;

  constructor(
    private _route: ActivatedRoute,
    private _eventServerService: EventServerService,
  ) { }

  ngOnInit(): void {
    this.pageName = this.extractPageName(this._route.snapshot.url);
    this.resourceType = this._eventServerService.getResourceTypeForEventHandler(this.pageName);
    // console.log('CheckForResRepComponent - pageName: ' + this.pageName + ', resourceType: ' + this.resourceType);
    this.isResRepEH = (this.resourceType !== null);

    // required because it can change while we're on this page...
    this._route.url.subscribe(
       (url: any) => {
          // console.log('CheckForResRepComponent - url:', url);

          const newPageName = this.extractPageName(url);
          if (this.pageName !== newPageName) {
            // console.log('CheckForResRepComponent - old page: ' + this.pageName + ', new page: ' + newPageName);

            this.pageName = newPageName;
            this.resourceType = this._eventServerService.getResourceTypeForEventHandler(this.pageName);
            // console.log('CheckForResRepComponent - pageName: ' + this.pageName + ', resourceType: ' + this.resourceType);
            this.isResRepEH = (this.resourceType !== null);
          }
       }
    );

  }

  extractPageName(url: UrlSegment[]): string {
    const temp = url[url.length - 1].path;
    return(temp);
  }

}
