<span *ngIf="f">
    <span *ngIf="mode === 'read'">
        {{ f.value }} 
    </span>
    <span *ngIf="(mode === 'write')">
        <span *ngIf="f.pattern ; else nopattern">
            <input 
                class="form-control width-unset shrinkToFitWidth"
                type="text" 
                [ngClass]="{'fieldChanged': f.changed}"
                [placeholder]="(f.required ? '* ' : '') + (title ? title : f.name)"
                [name]="f.name"
                [title]="augmentTitle()"
                [size]="(f.length && (f.length < size) ? f.length : size)"
                [value]="f.value" 
                [required]="f.required"
                [pattern]="f.pattern"
                [maxLength]="f.length"
                [disabled]="f.readOnly"
                (input)="onInputChange($event)"
                (blur)="forceValidValue($event)"
            />
        </span>
        <ng-template #nopattern>
            <span *ngIf="!f.length || (f.length <= 255); else textarea">
                <input 
                    class="form-control width-unset shrinkToFitWidth"
                    type="text" 
                    [ngClass]="{'fieldChanged': f.changed}"
                    [placeholder]="(f.required ? '* ' : '') + (title ? title : f.name)"
                    [name]="f.name"
                    [title]="augmentTitle()"
                    [size]="(f.length && (f.length < size) ? f.length : size)"
                    [value]="f.value" 
                    [required]="f.required"
                    [maxLength]="f.length"
                    [disabled]="f.readOnly"
                    (input)="onInputChange($event)"
                    (blur)="forceValidValue($event)"
                />
            </span>
            <ng-template #textarea>
                <textarea 
                    class="form-control {{ forceWrap ? '' : 'no-wrap'}}"
                    [ngClass]="{'fieldChanged': f.changed}"
                    [placeholder]="(f.required ? '* ' : '') + (title ? title : f.name)"
                    [name]="f.name"
                    [title]="augmentTextAreaTitle()"
                    [cols]="(size ? size : 25)"
                    [rows]="(rows ? rows : 3)"
                    [value]="f.value" 
                    [required]="f.required"
                    [maxLength]="f.length"
                    [disabled]="f.readOnly"
                    (input)="onInputChange($event)"
                    (blur)="forceValidValue($event)"
                ></textarea>
            </ng-template>
        </ng-template>
    </span>
</span>
