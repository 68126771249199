import { Component } from '@angular/core';
import { RepositoryPageComponent } from '../../pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-form-fillers',
  templateUrl: '../repository-page/repository-page.component.html',
})
export class FormFillersComponent extends RepositoryPageComponent {

}
