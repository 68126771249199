		<div class="card">
			<div class="card-header">
				Billing Policy...
			</div>
			<div class="card-body">
				<p>
					How we do billing depends on the price model for the product or web app that you purchase:
				</p>
				<p>
					For "fixed price" products, we charge the stated amount against the credit card.
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						You get access to the download page for that product.
					</li>
				</ul>
				<p>
					For "per event" web apps, we charge the stated amount against the credit card.
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						You have access to that web app for one year from the time of your most recent purchase, 
						during which, you may only hold the number of events that you have purchased.
					</li>
				</ul>
				<p>
					For "per month" web apps that have recurring payments, we charge the stated amount against the credit card
					each billing cycle, as described below.
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						First, you enter a credit card on the Billing page to send your credit card information directly 
						to our credit card service provider. Once they have it, we only have access to the last four 
						digits of the card and the expiration date - which we use to show you the card you have 
						registered, if any.
					</li>
					<li>
						When you click on the Subscribe button, your initial payment will be a pro-rated amount 
						to your credit card that covers the remainder of the month. Then you join the regular 
						billing cycle. <span class="italic">(If this is the first time you have subscribed to 
						that particular web app, you will be given a 14 day free trial period. Your credit 
						card will not be charged until the 14 days are up, with the pro-rated initial payment 
						calculated at that point.)</span>
					</li>
					<li>
						Our regular billing cycle charges your credit card on the first of the month for the following month.
					</li>
					<li>
						Every time we charge your credit card, we send a receipt to the current contact email 
						address that you have defined for your Account.
					</li>
					<li>
						If there is an error in payment, you will receive an email indicating that payment failed, along 
						with instructions on how to correct that. Usually, it's simply a question of the credit card being
						cancelled or expiring.
					</li>
					<li>
						There is a four calendar-day window in which a delinquent subscription web app (i.e. one with a
						failed payment...) can be re-instated without causing interruption of service, or the loss of data 
						described in the backup/recovery policies. Simply go to the Billing page, enter a new credit card,
						and click on the "Subscribe" button.  Your credit card will be charged the payment for the current
						month, and the web app will be put back into the regular billing cycle.
					</li>
					<li>
						In the event of a billing error that is more than just an expired or cancelled credit card, contact 
						us at billing@{{ domain }}, include your name, your Account name, your contact information 
						(if different than the contact listed for the Account name) and a description of the issue. We will 
						contact you and work with you individually to resolve the situation.
					</li>
					<li>
						You can cancel recurring payments at any time. There are two ways to do this.
						<ol>
							<li>
								Simply click the appropriate Cancel button on the Billing page. This will immediately cause you to lose access to the web app.
								<ul>
									<li>
										IMPORTANT! Back up your data before you cancel! If you do not, 
										<span class="bold italic">YOUR DATA IS GONE</span>, because 
										<span class="bold italic">WE DO NOT KEEP</span> a backup of your data <span class="bold italic">FOR YOU</span>. 
										(See <a data-es-refresh-page="policy-backups-and-data-retention">Backups &amp; Data-Retention Policy</a> for more information.)
									</li>
								</ul>
							</li>
							<li>
								Clear your credit card on the Billing page. This will leave you access to the web app through the end of the current billing period.
								<ul>
									<li>
										If you re-subscribe to a cancelled web app in the future, you will be charged as described 
										above, but without a 14 day free trial.
									</li>
								</ul>
							</li>
						</ol>
					</li>
				</ul>
				<p>
					For custom web apps:
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						During development, we bill at the negotiated hourly rate, submitting a monthly invoice.
					</li>
					<li>
						At the completion of development, we bill you for the entire initial subscription period. 
						When that period ends, you join the regular billing cycle, described above.
					</li>
					<li>
						(See <a data-es-refresh-page="about-custom-dev">Custom Development</a> for more information.)
					</li>
				</ul>
			</div>
		</div>

