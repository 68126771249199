import { NgModule, enableProdMode, APP_INITIALIZER } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EditorModule } from '@tinymce/tinymce-angular';

// core wackadoo-client app stuff
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// common stuff pulled in by ALL web-app modules ...
import { ClientCoreModule } from 'src/app/client-core/client-core.module';
import { ClientCoreRoutingModule } from 'src/app/client-core/client-core-routing.module';

// for APP_INITIALIZER
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';

// ROOT application module and overlay pages
import { AccountManagerCoreModule } from 'src/app/account-manager-core/account-manager-core.module';
import { HomeInfoComponent } from './pages/home-info/home-info.component';
import { PricingInfoComponent } from './pages/pricing-info/pricing-info.component';
import { Globals } from 'src/app/client-core/services/global.service';

// enable production mode code operation
enableProdMode();

///////////////////////////////////////
// ROOT application components...
///////////////////////////////////////

Globals.rootApplication = 'AccountManager';
Globals.thisApplication = 'AccountManager';

document.title = Globals.domain;

// app initialization stuff
export function initializeUserAuthentication(userAuthService: UserAuthService): () => Promise<any> {
  return (): Promise<any> => {
    return userAuthService.bootupInitialization();
  };
}

@NgModule({
  declarations: [

    // app stuff
    AppComponent,

    HomeInfoComponent,
    PricingInfoComponent,

  ],
  imports: [
    // Angular stuff...
    BrowserModule,
    HttpClientModule,
    FormsModule,

    // 3rd party stuff everybody uses...
    NgbModule,
    FontAwesomeModule,
    ChartsModule,
    EditorModule,

    // top-level routing module...
    AppRoutingModule,

    // common web app components
    ClientCoreModule,
    ClientCoreRoutingModule,

    // ROOT application module
    AccountManagerCoreModule

  ],
  exports: [],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeUserAuthentication, deps: [UserAuthService], multi: true},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    HomeInfoComponent,
    PricingInfoComponent,
  ]
})
export class AppModule {
}
