<div class="card">
  <div class="card-header col-sm-12">
    Details
  </div>
  <div class="card-body">
    <div class="" [ngClass]="{'d-flex':(screenType !== 'phone')}">
      <div class="d-flex" [ngClass]="{'halfWidth':(screenType !== 'phone')}">
        <div class="bold">accountID</div>
        <div class="marginLeftHalfEm"><wackadoo-field [f]="resource.accountID" [mode]="mode"></wackadoo-field></div>
      </div>
      <div class="d-flex" [ngClass]="{'halfWidth':(screenType !== 'phone')}">
        <div class="bold">discount</div>
        <div class="marginLeftHalfEm">
            <span *ngIf="resource.discount.value > 0 || mode === 'write'; else noDiscount">
              <wackadoo-field [f]="resource.discount" [mode]="mode"></wackadoo-field>
            </span>
            <ng-template #noDiscount>
              (no discount)
            </ng-template>
        </div>
      </div>
    </div>
    <div class="" [ngClass]="{'d-flex':(screenType !== 'phone')}">
      <div class="d-flex" [ngClass]="{'halfWidth':(screenType !== 'phone')}">
        <div class="bold">appID</div>
        <div class="marginLeftHalfEm">
          <wackadoo-field [f]="resource.appID" [mode]="mode"></wackadoo-field>
        </div>
      </div>
      <div class="d-flex" [ngClass]="{'halfWidth':(screenType !== 'phone')}">
        <div class="bold">count (for per-item apps)</div>
        <div class="marginLeftHalfEm">
          <wackadoo-field [f]="resource.count" [mode]="mode"></wackadoo-field>
        </div>
      </div>
    </div>
    <div class="" [ngClass]="{'d-flex':(screenType !== 'phone')}">
      <div class="d-flex" [ngClass]="{'halfWidth':(screenType !== 'phone')}">
        <div class="bold">expiration</div>
        <div class="marginLeftHalfEm">
            <span *ngIf="resource.expiration.value !== null  || mode === 'write'; else noExpiration">
              <wackadoo-field [f]="resource.expiration" [mode]="mode"></wackadoo-field>
            </span>
            <ng-template #noExpiration>
              (never expires)
            </ng-template>
        </div>
      </div>
      <div class="d-flex" [ngClass]="{'halfWidth':(screenType !== 'phone')}">
        <div class="bold">
          donation
        </div>
        <div class="marginLeftHalfEm">
          <wackadoo-field [f]="resource.donation" [mode]="mode"></wackadoo-field>
        </div>
      </div>
    </div>
  </div>
</div>
