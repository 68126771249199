import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { Subscription } from 'rxjs';
import { ChartModalContent } from './chart-modal-content.model';

@Component({
  selector: 'wackadoo-chart',
  templateUrl: './chart-modal.component.html',

})
export class ChartModalComponent implements OnInit, OnDestroy {

  wChartJSON: string |object = null;
  visible = false;

  showModalSubscription: Subscription;

  constructor(
    private _modalDialogService: ModalDialogService
  ) { }

  ngOnInit(): void {

    this.showModalSubscription = this._modalDialogService.showModalChartSubject.subscribe(
      (chartModalContent: ChartModalContent) => {
        if (chartModalContent) {
          this.wChartJSON = chartModalContent.wChartJSON;
          this.visible = true;
        } else {
          this.visible = false;
          this.wChartJSON = null;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }
  }

  onOK(): void {
    this.visible = false;
    this.wChartJSON = null;
  }

  onCancel(): void {
    this.visible = false;
    this.wChartJSON = null;
  }

}

