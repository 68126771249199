		<div class="card">
			<div class="card-header">
				Confidentiality/Privacy Policy...
			</div>
			<div class="card-body">
				<p>We don't give your information to anyone - and that includes our information about you. We don't sell mailing lists. 
				We don't give it to our partners. We don't even look at your data ourselves, unless there's a problem we're solving for
				you or some server maintenance activity requires it.</p>
				<p>Within the limitations of U.S. law, we will not give access to your data to anyone but you. Period.</p>
				<p>Note that our stated policy is to comply with legal requests made by U.S. law enforcement officials.</p>
			</div>
		</div>
