import { Component, OnInit } from '@angular/core';
import { WString } from '../../data/field.model';
import { PageComponent } from '../page/page.component';
import { UserAuthService } from '../../services/user-auth.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { EventServerService } from '../../services/event-server.service';

@Component({
  selector: 'wackadoo-broadcast',
  templateUrl: './broadcast.component.html',
})
export class BroadcastComponent extends PageComponent implements OnInit {

  message: WString = null;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    this.message = new WString('message', '');
    this.message.length = 255;
  }

  setAccountStatus(): void {
    if (this.message.isPopulated) {
      this.eventServerService.fireEvent('Administration', 'setAccountStatus', this.message.asParm).subscribe(
        () => {
          this.userInterfaceService.reloadCurrentPage();
        }
      );
    } else {
      this.clearAccountStatus();
    }
  }

  clearAccountStatus(): void {
    this.message.value = '';
    this.eventServerService.fireEvent('Administration', 'clearAccountStatus', {}).subscribe(
      () => {
        this.userInterfaceService.reloadCurrentPage();
      }
    );
  }

}
