import { Component, OnInit, OnDestroy } from '@angular/core';

import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { EventPageComponent } from 'src/app/client-core/pages/event-page/event-page.component';

@Component({
  selector: 'wackadoo-email-error-web-hook',
  templateUrl: './email-error-web-hook.component.html',

})
export class EMailErrorWebHookComponent extends EventPageComponent implements OnInit, OnDestroy {

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService
  ) {
    super(userAuthService, userInterfaceService, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
