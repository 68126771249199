import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// root-level components used by many/all web apps...

// banner pages
import { HomeAppComponent } from './pages/home-app/home-app.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { LoginComponent } from './pages/login/login.component';

// about banner-link pages
import { AboutComponent } from './pages/about/about.component';
import { AboutCustomDevComponent } from './pages/about/about-custom-dev/about-custom-dev.component';
import { AboutNameComponent } from './pages/about/about-name/about-name.component';
import { AboutMissionComponent } from './pages/about/about-mission/about-mission.component';
import { AboutTechnologyComponent } from './pages/about/about-technology/about-technology.component';
import { AboutHistoryComponent } from './pages/about/about-history/about-history.component';

// value banner-link pages
import { ValuesComponent } from './pages/values/values.component';
import { ValueDirectnessComponent } from './pages/values/value-directness/value-directness.component';
import { ValueSimplicityComponent } from './pages/values/value-simplicity/value-simplicity.component';
import { ValueFlexibilityComponent } from './pages/values/value-flexibility/value-flexibility.component';
import { ValueSubstanceOverStyleComponent } from './pages/values/value-substance-over-style/value-substance-over-style.component';
import { ValueTransparencyComponent } from './pages/values/value-transparency/value-transparency.component';

// policy banner-link pages
import { PoliciesComponent } from './pages/policies/policies.component';
import { PolicyTermsAndConditionsComponent } from './pages/policies/policy-terms-and-conditions/policy-terms-and-conditions.component';
import { PolicyDataSecurityComponent } from './pages/policies/policy-data-security/policy-data-security.component';
import { PolicyPricingComponent } from './pages/policies/policy-pricing/policy-pricing.component';
import { PolicyBillingComponent } from './pages/policies/policy-billing/policy-billing.component';
import { PolicyRefundsComponent } from './pages/policies/policy-refunds/policy-refunds.component';
import { PolicyExpiredDelinquentAcctsComponent } from './pages/policies/policy-exp-delinq-accts/policy-exp-delinq-accts.component';
import { PolicyEmailUsageComponent } from './pages/policies/policy-email-usage/policy-email-usage.component';
import { PolicyConfidentialityComponent } from './pages/policies/policy-confidentiality/policy-confidentiality.component';
import { PolicyCookiesComponent } from './pages/policies/policy-cookies/policy-cookies.component';
import { PolicyDataRetentionComponent } from './pages/policies/policy-data-retention/policy-data-retention.component';
import { PolicyDataPortabilityComponent } from './pages/policies/policy-data-portability/policy-data-portability.component';
import { PolicySupportComponent } from './pages/policies/policy-support/policy-support.component';
import { PolicyMaintenanceComponent } from './pages/policies/policy-maintenance/policy-maintenance.component';
import { PolicyServiceLevelAgreementComponent } from './pages/policies/policy-sla/policy-sla.component';

// help banner-link pages
import { HelpComponent } from './pages/help/help.component';
import { HelpVideoLibraryComponent } from './pages/help/help-video-library/help-video-library.component';
import { HelpGettingStartedComponent } from './pages/help/help-getting-started/help-getting-started.component';
import { HelpUserRolesComponent } from './pages/help/help-user-roles/help-user-roles.component';
import { HelpFaqComponent } from './pages/help/help-faq/help-faq.component';

// account-level admin pages, used across multiple web-apps... (Really? All of them?)
import { BackupServiceComponent } from './pages/backup-service/backup-service.component';
import { SearchServiceComponent } from './pages/search-service/search-service.component';
import { SearchEngineUpdaterComponent } from './pages/search-engine-updater/search-engine-updater.component';
import { SecurityServicesComponent } from './pages/security-services/security-services.component';

// Common functional pages, used across multiple web-apps...
import { BackupRestoreComponent } from './pages/backup-restore/backup-restore.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { ReportTemplatesComponent } from './pages/report-templates/report-templates.component';
import { BulkEMailerComponent } from './pages/bulk-e-mailer/bulk-e-mailer.component';
import { EMailErrorsComponent } from './pages/e-mail-errors/e-mail-errors.component';
import { EMailTemplatesComponent } from './pages/e-mail-templates/e-mail-templates.component';
import { ImportTemplatesComponent } from './pages/import-templates/import-templates.component';
import { ImportTemplateFieldsComponent } from './pages/import-template-fields/import-template-fields.component';
import { BroadcastComponent } from './pages/broadcast/broadcast.component';
import { SmtpServersComponent } from './pages/smtp-servers/smtp-servers.component';
import { PoweredByComponent } from './pages/powered-by/powered-by.component';
import { FormFillersComponent } from './pages/form-fillers/form-fillers.component';
import { FormFillerTemplatesComponent } from './pages/form-filler-templates/form-filler-templates.component';
import { FormFillerFieldsComponent } from './pages/form-filler-fields/form-filler-fields.component';

const routes: Routes = [
  // loadChildren w/import() is the lazy-loading mechanism...
  // Make sure your import statements are clean!

  // root-level components used by many/all web apps...

  // banner pages
  { path: '', component: HomeAppComponent, pathMatch: 'full' },
  { path: 'home', redirectTo: '/', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full' },

  // about banner-link pages
  { path: 'about', component: AboutComponent, pathMatch: 'full' },
  { path: 'about-custom-dev', component: AboutCustomDevComponent, pathMatch: 'full' },
  { path: 'about-history', component: AboutHistoryComponent, pathMatch: 'full' },
  { path: 'about-name', component: AboutNameComponent, pathMatch: 'full' },
  { path: 'about-mission', component: AboutMissionComponent, pathMatch: 'full' },
  { path: 'about-technology', component: AboutTechnologyComponent, pathMatch: 'full' },

  // value banner-link pages
  { path: 'values', component: ValuesComponent, pathMatch: 'full' },
  { path: 'value-directness', component: ValueDirectnessComponent, pathMatch: 'full' },
  { path: 'value-flexibility', component: ValueFlexibilityComponent, pathMatch: 'full' },
  { path: 'value-simplicity', component: ValueSimplicityComponent, pathMatch: 'full' },
  { path: 'value-substance-over-style', component: ValueSubstanceOverStyleComponent, pathMatch: 'full' },
  { path: 'value-transparency', component: ValueTransparencyComponent, pathMatch: 'full' },

  // policy banner-link pages
  { path: 'policies', component: PoliciesComponent, pathMatch: 'full' },
  { path: 'policy-billing', component: PolicyBillingComponent, pathMatch: 'full' },
  { path: 'policy-confidentiality', component: PolicyConfidentialityComponent, pathMatch: 'full' },
  { path: 'policy-cookies', component: PolicyCookiesComponent, pathMatch: 'full' },
  { path: 'policy-data-portability', component: PolicyDataPortabilityComponent, pathMatch: 'full' },
  { path: 'policy-backups-and-data-retention', component: PolicyDataRetentionComponent, pathMatch: 'full' },
  { path: 'policy-data-security', component: PolicyDataSecurityComponent, pathMatch: 'full' },
  { path: 'policy-email-usage', component: PolicyEmailUsageComponent, pathMatch: 'full' },
  { path: 'policy-expired-or-delinquent-accounts', component: PolicyExpiredDelinquentAcctsComponent, pathMatch: 'full' },
  { path: 'policy-maintenance', component: PolicyMaintenanceComponent, pathMatch: 'full' },
  { path: 'policy-pricing', component: PolicyPricingComponent, pathMatch: 'full' },
  { path: 'policy-refunds', component: PolicyRefundsComponent, pathMatch: 'full' },
  { path: 'policy-service-level-agreement', component: PolicyServiceLevelAgreementComponent, pathMatch: 'full' },
  { path: 'policy-support', component: PolicySupportComponent, pathMatch: 'full' },
  { path: 'policy-terms-and-conditions', component: PolicyTermsAndConditionsComponent, pathMatch: 'full' },

  // help banner-link pages
  { path: 'help', component: HelpComponent, pathMatch: 'full' },
  { path: 'help-video-library', component: HelpVideoLibraryComponent, pathMatch: 'full' },
  { path: 'help-getting-started', component: HelpGettingStartedComponent, pathMatch: 'full' },
  { path: 'help-user-roles', component: HelpUserRolesComponent, pathMatch: 'full' },
  { path: 'help-faq', component: HelpFaqComponent, pathMatch: 'full' },

  // Common account admin pages...

  { path: 'BackupService', component: BackupServiceComponent, pathMatch: 'full' },
  { path: 'SearchService', component: SearchServiceComponent, pathMatch: 'full' },
  { path: 'SearchEngineUpdater', component: SearchEngineUpdaterComponent, pathMatch: 'full' },
  { path: 'SecurityService', component: SecurityServicesComponent, pathMatch: 'full' },

  // Common functional pages...

  { path: 'BackupRestore', component: BackupRestoreComponent, pathMatch: 'full' },
  { path: 'Reports', component: ReportsComponent, pathMatch: 'full' },
  { path: 'ReportTemplates', component: ReportTemplatesComponent, pathMatch: 'full' },
  { path: 'BulkEMailer', component: BulkEMailerComponent, pathMatch: 'full' },
  { path: 'EMailErrors', component: EMailErrorsComponent, pathMatch: 'full' },
  { path: 'EMailTemplates', component: EMailTemplatesComponent, pathMatch: 'full' },
  { path: 'ImportTemplates', component: ImportTemplatesComponent, pathMatch: 'full' },
  { path: 'ImportTemplateFields', component: ImportTemplateFieldsComponent, pathMatch: 'full' },
  { path: 'Broadcast', component: BroadcastComponent, pathMatch: 'full' },
  { path: 'SMTPServers', component: SmtpServersComponent, pathMatch: 'full' },
  { path: 'PoweredBy', component: PoweredByComponent, pathMatch: 'full' },
  { path: 'FormFillerTemplates', component: FormFillerTemplatesComponent, pathMatch: 'full' },
  { path: 'FormFillers', component: FormFillersComponent, pathMatch: 'full' },
  { path: 'FormFillerFields', component: FormFillerFieldsComponent, pathMatch: 'full' },

  // So instead of doing a single, generic Donate page across all web apps... which we could do...
  // we take it out of here, in favor of specific Donate pages for each web app that requires one.
  // { path: 'Donate', component: DonateComponent, pathMatch: 'full' },

];


@NgModule({
  declarations: [],
  imports: [
     RouterModule.forChild(routes)
   ],
  exports: [
    RouterModule
  ]
})

export class ClientCoreRoutingModule { }
