<span *ngIf="f">
    <span *ngIf="mode === 'read'">
        ********
    </span>
    <span *ngIf="mode === 'write'">
        <input 
            class="form-control"
            type="password" 
            [placeholder]="(f.required ? '* ' : '') + f.name"
            [name]="f.name"
            [title]="(title ? title : f.name)"
            [size]="(f.length && (f.length < size) ? f.length : size)"
            [value]="f.value" 
            [pattern]="f.pattern"
            [maxLength]="f.length"
            [disabled]="f.readOnly"
            (input)="onInputChange($event)"
            (blur)="forceValidValue($event)"
            (focus)="onFocusMainInput()"
        />
        <input 
            class="form-control"
            type="password" 
            [placeholder]="'repeat new ' + f.name"
            [name]="f.name + 'Repeat'"
            [title]="(title ? title : f.name)"
            [size]="(f.length && (f.length < size) ? f.length : size)"
            [value]="" 
            [pattern]="f.pattern"
            [maxLength]="f.length"
            [disabled]="f.readOnly"
            (input)="onInputChangeOtherInput()"
            (blur)="onBlurOtherInput()"
        />
        <div class="small italic">
            The two fields must match EXACTLY. (Yes, this *is* case-sensitive.)
            <span *ngIf="f.minLength > 0">
               And the value must be at least {{ f.minLength }} characters in length.
            </span>
        </div>
    </span>
</span>
