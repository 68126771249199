<ng-template #rt let-r="result">
  <div class="type-ahead-option">
    {{ formatResource(r) }}
  </div>
</ng-template>
<div class="input-group">
  <input type="text" 
        class="form-control"
        [name]="name"
        [ngClass]="{'fieldChanged': changed}"
        [placeholder]="placeholder"
        [disabled]="disabled || inputDisabled" 
        [(ngModel)]="selectedResource" 
        [ngbTypeahead]="search" 
        [resultTemplate]="rt" 
        [inputFormatter]="formatResource"
        (selectItem)="selectResource($event.item)"
        (blur)="onBlur()"
  />
  <button
      class="input-group-append border rounded-right marginLeft0 paddingTop2 paddingLeft4 paddingRight4"
      (click)="clearSelection()"
      type="button"
      [disabled]="disabled || !inputDisabled"
  >
      <span class="smallish fas fa-times"></span>
  </button>
</div>
