<!--
    This is the required structure of a Resource Repository page. The ways to customize this are, in order of expected frequency:
    1. customize the DETAIL Resource layout (Easily the MOST common thing, as almost EVERY page is going to do this...)
    2. add or remove buttons from the DETAIL button bar
    3. add or remove buttons from the GRID button bar
    4. customize the resource GRID layout... (This is expected to be pretty rare...)

    For most (all?) of these scenarios, you will need to 
    1. sub-class off of the ResourceComponent, GridButtonBar, DetailContent and/or DetailButtonBar component as desired.
    2. set the gridResourceComponentClassName, gridButtonBarComponentName, detailContentComponentName, and/or 
       detailButtonBarComponentName in the HTML template for the sub-class of THIS page, like this:

       <wackadoo-repository-page
            gridResourceComponentClassName="SubClassedResourceComponent"
            gridButtonBarComponentClassName="SubClassedGridButtonBarComponent"
            detailContentComponentClassName="SubClassedDetailContentComponent"
            detailButtonBarComponentClassName="SubClassedDetailButtonBarComponent"
       ></wackadoo-repository-page>

    3. IMPORTANT! EVERY resource repository page sub-component that wants to be dynamically 
       loaded MUST have the following lines at the very top of it's class defnition:

        // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
        static componentNameUsedForDynamicContentInAOT = 'MySubClassedComponent';

    4. REMEMBER! Put the sub-classed component into the "entryComponents" in the web-app module

    IMPORTANT NOTES ABOUT THE PLACEHOLDER DIRECTIVES:
    1. They are used to properly size content and button bars, as well as size the columns.
    2. They must be on DIVs (not ng-containers, which is the normal Angular thing...) in order to survive internal page updates.

-->
<div>

    <div *ngIf="selectedResource === null">

        <div class="marginBottom40 fillAvailableSpace" wackadooGridContentPlaceholder
            wackadooMaximizeContentHeight
            maxHeightThingBelowSelector="[wackadooGridButtonBarPlaceholder]"
            [maxHeightThingBelowDefaultHeight]="38"
            [maxHeightFudgeFactor]="-48"
            [maxHeightLockThingBelowToBottomOfPage]="true"
            [maxHeightTimoutDelay]="100"
        >
            <wackadoo-grid 
                [eventHandler]="eventHandler" 
                [action]="action" 
                [parameters]="parameters"
                [resources]="resources"
                [gridResourceComponentClassName]="gridResourceComponentClassName"
                [leftJustifyGridHeaders]="leftJustifyGridHeaders"
                [screenType]="screenType"
                [gridFieldsToShow]="gridFieldsToShow"
                [gridFieldsToHide]="gridFieldsToHide"
                [resourceClickSubject]="resourceClickSubject"
                [gridRollover]="gridRollover"
                [gridHint]="gridHint"
                [showAllFieldsOnPhone]="showAllFieldsOnPhone"
                [hideSelectedFKeysInGrid]="hideSelectedFKeysInGrid"
            ></wackadoo-grid>
        </div>
        <div wackadooGridButtonBarPlaceholder></div>

    </div>

    <div *ngIf="selectedResource !== null">

        <div class="scrollable-y" 
            wackadooMaximizeContentHeight
            maxHeightThingBelowSelector="[wackadooDetailButtonBarPlaceholder]"
            [maxHeightFudgeFactor]="-12"
            [maxHeightTimoutDelay]="100"
            [maxHeightLockThingBelowToBottomOfPage]="true"
            [maxHeightThingBelowDefaultHeight]="38"
        >
            <div wackadooDetailContentPlaceholder></div>
        </div>

        <div wackadooDetailButtonBarPlaceholder></div>

    </div>

</div>
