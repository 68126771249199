import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-users',
  templateUrl: '../../../client-core/pages/repository-page/repository-page.component.html',
})
export class UsersComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.gridFieldsToShow = ['firstName', 'lastName', 'userName', 'email', 'lastAccess', 'lastUserActivity', 'accountID'];
    this.detailContentComponentClassName = 'UserDetailContentComponent';
    this.detailButtonBarComponentClassName = 'UserDetailButtonBarComponent';

    super.ngOnInit();
  }

}
