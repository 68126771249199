import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';
import { EventServerService } from '../services/event-server.service';
import { UserInterfaceService } from '../services/user-interface.service';
import { WResource } from '../data/resource.model';
import { WEvent } from '../data/event.model';

@Directive({
  selector: '[wackadooResourceBlock]'
})
export class ResourceBlockDirective implements OnInit, OnChanges {

  // gotta prefix input names from the directive value itself using the selector above
  // (it's a built-in Angular thing for structural Directives...)
  @Input() wackadooResourceBlockEventHandler = '';
  @Input() wackadooResourceBlockAction = '';
  @Input() wackadooResourceBlockParms = {};
  @Input() wackadooResourceBlockDebug = false;
  @Input() wackadooResourceBlockApplySelectedKeys = false;
  @Input() wackadooResourceBlockDefaultText = '';
  @Input() wackadooResourceBlockPendingText = '';

  @Input() wackadooResourceBlockNullOrMatchCriteria: any = {};
  @Input() wackadooResourceBlockOmitCriteria: any = {};

  // gotta name the "iterable thing" using the selector above + "Of"
  // (it's a built-in Angular thing for structural Directives...)
  @Input() wackadooResourceBlockOf: WResource [];

  constructor(
    public templateRef: TemplateRef<ResourceBlockDirectiveContext>,
    public viewContainerRef: ViewContainerRef,
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
    public renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    // console.log('wackadooResourceBlock() ' + this.wackadooResourceBlockEventHandler + '.' + this.wackadooResourceBlockAction + '()', this.wackadooResourceBlockParms);

    // we only load the pending content the very first time we load data.
    // we don't do it on subsequent changes so that the screen keeps the old data as long as possible.
    this.renderer.appendChild(this.viewContainerRef.element.nativeElement.parentElement, this.renderer.createText(this.wackadooResourceBlockPendingText));

    this.loadContent();
  }

  ngOnChanges(sc: SimpleChanges): void {
    let reloadContent = false;

    if (sc.wackadooResourceBlockEventHandler && !sc.wackadooResourceBlockEventHandler.firstChange && (sc.wackadooResourceBlockEventHandler.currentValue !== sc.wackadooResourceBlockEventHandler.previousValue)) {
      reloadContent = true;
    } else if (sc.wackadooResourceBlockAction && !sc.wackadooResourceBlockAction.firstChange && (sc.wackadooResourceBlockAction.currentValue !== sc.wackadooResourceBlockAction.previousValue)) {
      reloadContent = true;
    } else if (sc.wackadooResourceBlockParms && !sc.wackadooResourceBlockParms.firstChange ) {
      // if we have the same number of parameters as before...
      if (Object.entries(sc.wackadooResourceBlockParms.currentValue) !== Object.entries(sc.wackadooResourceBlockParms.previousValue)) {
        // check to see if any have changed...
        for (const [name, value] of Object.entries(sc.wackadooResourceBlockParms.currentValue)) {
          if (value !== sc.wackadooResourceBlockParms.previousValue[name] ) {
            reloadContent = true;
            break;
          }
        }
      } else {
        // we have a different number of parameters than before...
        reloadContent = true;
      }
    } else if (sc.wackadooResourceBlockDebug && !sc.wackadooResourceBlockDebug.firstChange && (sc.wackadooResourceBlockDebug.currentValue !== sc.wackadooResourceBlockDebug.previousValue)) {
      reloadContent = true;
    } else if (sc.wackadooResourceBlockApplySelectedKeys && !sc.wackadooResourceBlockApplySelectedKeys.firstChange && (sc.wackadooResourceBlockApplySelectedKeys.currentValue !== sc.wackadooResourceBlockApplySelectedKeys.previousValue)) {
      reloadContent = true;
    }

    if (reloadContent) {
      // console.log(sc);
      this.loadContent();
    }

  }

  nullOrMatchesCriteria(r: WResource): boolean {
    let nullOrMatchesCriteria = true;
    for (const [criteriaName, criteriaValue] of Object.entries(this.wackadooResourceBlockNullOrMatchCriteria)) {
      nullOrMatchesCriteria = nullOrMatchesCriteria && ((r[criteriaName].value === null) || (r[criteriaName].value === criteriaValue));
    }
    return nullOrMatchesCriteria;
  }

  matchesOmitCriteria(r: WResource): boolean {
    // nothing matches empty omit criteria
    let matchesOmitCriteria = false;

    if (Object.entries(this.wackadooResourceBlockOmitCriteria).length > 0) {
      // now we check if it matches the provided omit criteria
      matchesOmitCriteria = true;
      for (const [criteriaName, criteriaValue] of Object.entries(this.wackadooResourceBlockOmitCriteria)) {
        matchesOmitCriteria = matchesOmitCriteria && (r[criteriaName].value === criteriaValue);
      }
    }

    return matchesOmitCriteria;
  }

  loadContent(): void {
    const eventHandler = this.wackadooResourceBlockEventHandler;
    const action = this.wackadooResourceBlockAction;
    let parms = this.wackadooResourceBlockParms;
    const debug = this.wackadooResourceBlockDebug;
    const applySelectedKeys = this.wackadooResourceBlockApplySelectedKeys;

    if (applySelectedKeys) {
      parms = this.userInterfaceService.applyCurrentSelectionsToParms(parms, eventHandler);
      // console.log('munged resourceBlockparms:', parms);
    }

    if (debug) {
      console.log('wackadooResourceBlock(debug=' + debug + ' applySelectedKeys=' + applySelectedKeys + ') ' + eventHandler + '.' + action + '()', parms);
    }

    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (event: WEvent) => {
        if (debug) {
          console.log(event);
        }
        if (event.status === 'OK') {
          // check to see that we actually got an array of resources...
          if (event.resources) {
            this.wackadooResourceBlockOf = event.resources;
          } else {
            this.wackadooResourceBlockOf = [];
          }
          // console.log(this.wackadooResourceBlockOf);

          //////////////////////////////////////////////////////////
          // Now we go display the guts of the resource block
          //////////////////////////////////////////////////////////

          // clear the old content
          this.viewContainerRef.clear();

          // populate the new content
          if (this.wackadooResourceBlockOf.length > 0) {
            let previousResource: WResource = null;

            for (const resource of this.wackadooResourceBlockOf) {

              if (this.nullOrMatchesCriteria(resource) && !this.matchesOmitCriteria(resource)) {

                const idx = this.wackadooResourceBlockOf.indexOf(resource);
                const length = this.wackadooResourceBlockOf.length;

                this.viewContainerRef.createEmbeddedView(
                  this.templateRef,
                  new ResourceBlockDirectiveContext(
                      resource,               // this resource
                      idx,                    // idx
                      previousResource,       // previous resource
                      (idx === 0),            // first
                      (idx === length - 1),   // last
                      ((idx % 2) === 0),      // even
                      ((idx % 2) === 1),      // odd
                      length                  // length
                  )
                );
                previousResource = resource;
              }
            }

          } else {
            // console.log(this.viewContainerRef.element.nativeElement.parentElement, typeof this.viewContainerRef.element.nativeElement.parentElement, this.wackadooResourceBlockDefaultText);
            // sometimes, this is called on a null element (something to do w/Angular dialog mgt...)
            if ((this.viewContainerRef.element.nativeElement.parentElement instanceof HTMLElement)
                && this.wackadooResourceBlockDefaultText
                && (this.wackadooResourceBlockDefaultText !== 'null')
                && (this.wackadooResourceBlockDefaultText !== '')
            ) {
              this.renderer.appendChild(this.viewContainerRef.element.nativeElement.parentElement, this.renderer.createText(this.wackadooResourceBlockDefaultText));
            }
          }
        } else {
          console.log('resource block error:', event);
        }
      }
    );

  }

}

class ResourceBlockDirectiveContext {
  constructor(
    public $implicit: WResource,
    public index: number,
    public previous: WResource,
    public first: boolean,
    public last: boolean,
    public even: boolean,
    public odd: boolean,
    public count: number,
  ) {
  }
}
