import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-security-services',
  templateUrl: './security-services.component.html',

})
export class SecurityServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
