import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportTemplateDetailButtonBarComponent } from '../../report-templates/report-template-detail-button-bar/report-template-detail-button-bar.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { HtmlEditorService } from 'src/app/client-core/services/html-editor.service';
import { Subscription } from 'rxjs';
import { BusinessRuleService } from 'src/app/client-core/services/business-rule.service';

@Component({
  selector: 'wackadoo-e-mail-template-detail-button-bar',
  templateUrl: './e-mail-template-detail-button-bar.component.html',
})
export class EMailTemplateDetailButtonBarComponent extends ReportTemplateDetailButtonBarComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'EMailTemplateDetailButtonBarComponent';

  public wysiwyg: boolean;
  public wysiwygSubscription: Subscription;

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
    businessRuleService: BusinessRuleService,
    public htmlEditorService: HtmlEditorService,
  ) {
    super(eventServerService, userInterfaceService, modalDialogService, businessRuleService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.wysiwyg = true;
    this.htmlEditorService.wysiwygMode.next(this.wysiwyg);

    this.wysiwygSubscription = this.htmlEditorService.wysiwygMode.subscribe(
      (wysiwyg: boolean) => {
        this.wysiwyg = wysiwyg;
      }
    );

  }

  ngOnDestroy(): void {
    if (this.wysiwygSubscription) {
      this.wysiwygSubscription.unsubscribe();
    }
    // super.ngOnDestroy(); // apparently, the super call does not exist...
  }

  save(): void {

    // When populating a NEW resource that includes a binary-text field, we need to
    // populate these related fields to make sure we have a "proper" template resource.

    if (!this.resource.keyField.isPopulated) {

      if (!this.resource.templateExtension.isPopulated) {
        this.resource.templateExtension.value = this.resource.templateExtension.default;
      }

      if (!this.resource.templateFileType.isPopulated || (this.resource.templateFileType.value === 'unk')) {
        this.resource.templateFileType.value = this.resource.templateExtension.value;
      }

      if (!this.resource.templateFileName.isPopulated || (this.resource.templateFileName.value === 'none.unk')) {
        this.resource.templateFileName.value = 'none.' + this.resource.templateExtension.value;
      }

      this.resource.markAllFieldsAsChanged();
    }

    if (this.resource.templateFileContent.changed) {
      const content = (this.resource.templateFileContent.isPopulated ? this.resource.templateFileContent.text : '');
      this.resource.templateFileSize.value = content.length;
      this.resource.templateFileSize.changed = true;
    }

    super.save();
  }

  copy(): void {
    // most email templates have "email.xhtml", which is technically an invalid value...
    if (!this.resource.templateExtension.isValidValue()) {
      this.resource.templateExtension.value = this.resource.templateExtension.default;
    }
    super.copy();
  }

  get toggleButtonTitle(): string {
    return( 'Toggle To ' + (this.wysiwyg ? 'XHTML' : 'WYSIWYG'));
  }

  toggle(): void {
    // all we do is toggle the HTML field editor...
    this.wysiwyg = !this.wysiwyg;
    this.htmlEditorService.wysiwygMode.next(this.wysiwyg);
  }
}
