<div *ngIf="visible && !(autoSelectSingleResource && (resources.length <= 1))">
    <div class="modal-backdrop" (click)="onCancel()"></div>
    <div class="modal-box">
        <div class="scrollable fillAvailableSpace">
            <div class="card">
            <div class="card-header">
                Click To Select
            </div>
            <div class="card-body maxHeightViewPort40 min-width-20-em scrollable-y">
                <div *ngFor="let r of resources; let idx = index;">
                    <div class="border-bottom d-flex flex-row paddingTopHalfEm paddingBottomHalfEm rollover marginLeftWholeEm marginRightWholeEm"
                        (click)="selectResource(r)"
                    >
                        <div class="center middle italic width-3-em">
                            {{idx + 1}}
                        </div>
                        <div class="marginLeftHalfEm">
                            <wackadoo-field [f]="r.keyField" mode="read" ></wackadoo-field>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <div class="marginTopWholeEm d-flex justify-content-around">
            <div>
                <button id="close" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
