		<div class="card">
			<div class="card-header">
				Our mission...
			</div>
			<div class="card-body">
				<p *ngIf="domain !== 'wackadoo.org'">
					Provide simple, practical web apps that help 
					real people do real jobs.
				</p>
				<p *ngIf="domain === 'wackadoo.org'">
					Provide simple, practical web apps that help 
					ministries, churches, volunteers and non-profit organizations who are doing the work spelled 
					out in the Gospel of Matthew, Chapter 25, verses 35-36, which read:
				</p>
				<p *ngIf="domain === 'closingpro.net'">
					For ClosingPro, that means giving transaction coordinators on busy real estate teams the ability to keep track of dozens of signed contracts simultaneously.
				</p>
				<div class="well small italic" *ngIf="domain === 'wackadoo.org'">
					"For I was hungry and you gave me something to eat, I was thirsty and you gave me something to drink, 
					I was a stranger and you invited me in, I needed clothes and you clothed me, I was sick and you 
					looked after me, I was in prison and you came to visit me."
					<span class="small">
						<br/>
						Holy Bible, New International Version&reg;, NIV&reg; &copy;1973, 1978, 1984, 2011 
						by <a href="http://www.biblica.com/" target="_blank">Biblica, Inc.&reg;</a> 
						Used by permission. All rights reserved worldwide.
					</span>
				</div>
				<div *ngIf="domain !== 'closingpro.net'">
					<strong>Our immediate goal...</strong>
					<p>Validate our core platform by delivering a simple solution or two, which you are looking at now.</p>
					<p>Then deliver the next solution...</p>
					<p>and the next one...</p>
					<p>and the next...</p>
				</div>
				<div *ngIf="domain === 'wackadoo.org'">
					<p>...continually looking for another opportunity to serve.</p>
					<p class="italic">
						(BTW - Don't tell the guys over at <a href="http://www.wackadoo.info">wackadoo.info</a> this, 
						but <a href=".">wackadoo.org</a> is the real motivation behind our company's existence...)
					</p>
				</div>
				<div *ngIf="domain !== 'wackadoo.org'">
					<strong>Our *other* mission...</strong>
					<p>To help those who serve people in need by giving back through <a href="http://www.wackadoo.org">wackadoo.org</a>.</p>
				</div>
			</div>
		</div>
