import { Directive, ElementRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[wackadooDetailButtonBarPlaceholder]'
})
export class DetailButtonBarPlaceholderDirective {

  constructor(
    public elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef,
  ) { }

}
