<div *ngIf="visible">
    <div class="modal-backdrop" (click)="onCancel()"></div>
    <div class="modal-box">
        <div class="scrollable fillAvailableSpace" wackadooResourceModalPlaceholder></div>
        <div class="marginTopWholeEm d-flex justify-content-around">
            <div>
                <button id="ok" class="btn btn-secondary" (click)="onOK()" *ngIf="showOKButton">{{ okButtonTitle }}</button>
            </div>
            <div>
                <button id="close" class="btn btn-secondary" (click)="onCancel()" wackadooAutoFocus>{{ cancelButtonTitle }}</button>
            </div>
        </div>
    </div>
</div>
