import { Component, OnInit, OnDestroy } from '@angular/core';
import { DetailContentComponent } from '../../repository-page/detail-content/detail-content.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { BusinessRuleService } from 'src/app/client-core/services/business-rule.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { Subject, Subscription } from 'rxjs';
import { FileContentDescriptor } from 'src/app/client-core/ui/file-content-descriptor.model';
import { WResource } from 'src/app/client-core/data/resource.model';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-import-templates-detail',
  templateUrl: './import-templates-detail.component.html',
})
export class ImportTemplatesDetailComponent extends DetailContentComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ImportTemplatesDetailComponent';

  private _importTemplateFieldEH = 'ImportTemplateFields';

  // these three are set by the business rules...
  targetEventHandler: string = null;
  assignedToRelatedEventHandler: string = null;
  fieldNamesToAdd: string [] = [];
  fieldNamesToSkip: string [] = [];

  // selected field stuff...
  selectedField: WResource = null;
  selectedFieldName = null;

  onSelectSubject: Subject<any> = null;
  onSelectSubscription: Subscription = null;

  fcd: FileContentDescriptor = null;

  characterOnly = false;
  tabDelimited = false;
  commaSeparated = false;

  selectedText = '';
  selectedRow = '';

  fileDropSubject: Subject<FileContentDescriptor> = null;
  fileDropSubscription: Subscription = null;

  initialSelectionStart = null;
  initialSelectionEnd = null;

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    public businessRuleService: BusinessRuleService,
    public modalDialogService: ModalDialogService,
    public utilityLibService: UtilityLibService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    try {
      this.fcd = new FileContentDescriptor();

      this.fileDropSubject = new Subject<FileContentDescriptor>();

      this.fileDropSubscription = this.fileDropSubject.subscribe(
        (fcd: FileContentDescriptor) => {
          // console.log('fileDrop', JSON.stringify(fcd));

          try {
            // convert from Base64 to get the actual text content of the file...
            // we CANNOT use atob() because it does NOT support UTF-8 properly...
            // fcd.fileContent = atob(fcd.fileContent);
            fcd.fileContent = this.utilityLibService.atobForUnicodeContent(fcd.fileContent);
            this.fcd = fcd;

            // now check that you loaded the right kind of file... (tab-delimited or character-aligned...)
            this.tabDelimited = (this.fcd.fileContent.indexOf('\t') !== -1);
            this.commaSeparated = !this.tabDelimited && fcd.fileName.toLowerCase().endsWith('.csv');
            this.characterOnly = !this.tabDelimited && !this.commaSeparated;

            const type = (this.tabDelimited  ? 'tab-delimited' : (this.commaSeparated ? 'comma-separated' : 'character-aligned'));

            if (this.resource.tifType.value !== type) {
              this.modalDialogService.showAlert('You just loaded a ' + type + ' example file, but the template is defined as ' + this.resource.tifType.value + '.\n\nYou need to either get the proper example file, or change the tifType for this template.', 'Caution!');
            }

            if (this.commaSeparated) {
              this.fcd.fileContent = this.convertCSV(this.fcd.fileContent);
            }

            // and, now that we have a file, we try to highlight any selected field in the textarea...
            // We wait for the UX to settle to make sure there's text in the textarea to highlight...
            window.setTimeout(
              () => {
                this.showSelectionInTextAreaIfPopulated();
              }, 0
            );

          } catch (ex) {
            const msg = 'ImportTemplatesDetail.fileDropSubscription()\n';
            this.userInterfaceService.alertUserToException(ex, msg);
          }

        }
      );

      this.onSelectSubject = new Subject<any>();
      this.onSelectSubscription = this.onSelectSubject.subscribe(
        (payload: any) => {
          // console.log('onSelect', payload);

          this.selectedText = '';
          this.selectedRow = '';

          if (this.selectedField) {
            this.selectedField.tifFieldRow.changed = this.selectedField.tifFieldRow.changed || (this.selectedField.tifFieldRow.value !== payload.row);
            this.selectedField.tifFieldRow.value = payload.row;

            this.selectedField.tifFieldColumn.changed = this.selectedField.tifFieldColumn.changed || (this.selectedField.tifFieldColumn.value !== payload.column);
            this.selectedField.tifFieldColumn.value = payload.column;

            this.selectedField.tifFieldLength.changed = this.selectedField.tifFieldLength.changed || (this.selectedField.tifFieldLength.value !== payload.selectionLength);
            this.selectedField.tifFieldLength.value = payload.selectionLength;
          }

          this.selectedText = payload.selectedText;
          this.selectedRow = payload.selectedRow;

        }
      );

      // load the app specific stuff from the this.businessRuleService.businessRules

      if (this.businessRuleService.businessRules && this.businessRuleService.businessRules.hasOwnProperty('importFormatRules')) {
        this.targetEventHandler = this.businessRuleService.businessRules.importFormatRules.targetEventHandler;
        this.assignedToRelatedEventHandler = this.businessRuleService.businessRules.importFormatRules.assignedToRelatedEventHandler;
        this.fieldNamesToAdd = this.businessRuleService.businessRules.importFormatRules.fieldNamesToAdd;
        this.fieldNamesToSkip = this.businessRuleService.businessRules.importFormatRules.fieldNamesToSkip;
      } else {
        throw new Error('BusinessRules do not exist! At a minimum, they must include importFormatRules.targetEventHandler!');
      }
      if (!this.targetEventHandler) {
        throw new Error('BusinessRules MUST include importFormatRules.targetEventHandler!');
      }
      this.fieldNamesToAdd = this.fieldNamesToAdd !== null ? this.fieldNamesToAdd : [];
      this.fieldNamesToSkip = this.fieldNamesToSkip !== null ? this.fieldNamesToSkip : ['created', 'modified', 'agent'];

      // set up the fields to select from per the business rules...

      const res = this.eventServerService.newResource(this.targetEventHandler);

      for (const field of res.fields) {
        if (!this.fieldNamesToSkip.includes(field.name) && !this.fieldNamesToAdd.includes(field.name)) {
          this.fieldNamesToAdd.push(field.name);
        }
      }

      // finally, if we have a field already selected, display it properly...

      this.selectedField = this.userInterfaceService.getSelectedResource(this._importTemplateFieldEH);
      if (this.selectedField) {
        this.selectFieldName(this.selectedField.tifFieldName.value);
      }

    } catch (ex) {
      const msg = 'ImportTemplatesDetail.ngOnInit()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

  ngOnDestroy(): void {
    if (this.fileDropSubscription) {
      this.fileDropSubscription.unsubscribe();
    }
    if (this.onSelectSubscription) {
      this.onSelectSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  formatDate(d: Date): string {
    return d.toLocaleString();
  }

  formatNumber(n: number): string {
    return this.utilityLibService.formatNumberWithCommas(n);
  }

  convertCSV(csvFileContent: string): string {
    let tabDelimitedFileContent = '';

    // insert tabs for the proper commas...

    let lastC = null;
    let inQuote = false;
    for (const c of csvFileContent) {
      // console.log(c, lastC, inQuote);
      if ((c === ',') && !inQuote) {
        tabDelimitedFileContent += '\t';
      } else {
        if (c === '"') {
          inQuote = !inQuote;
        }
        tabDelimitedFileContent += c;
      }
      // console.log('[' + tabDelimitedFileContent + ']');
      lastC = c;
    }

    // now that we've inserted tabs in for the proper commas, un-quote all values...

    tabDelimitedFileContent = tabDelimitedFileContent.replace(/""/g, '"'); // double double-quotes
    tabDelimitedFileContent = tabDelimitedFileContent.replace(/^"/g, '');      // leading " in file
    tabDelimitedFileContent = tabDelimitedFileContent.replace(/"$/g, '');      // trailing " in file
    tabDelimitedFileContent = tabDelimitedFileContent.replace(/\nt"/g, '\n');  // leading " on lines
    tabDelimitedFileContent = tabDelimitedFileContent.replace(/"\n/g, '\n');   // trailing " on lines
    tabDelimitedFileContent = tabDelimitedFileContent.replace(/\t"/g, '\t');   // leading " on values
    tabDelimitedFileContent = tabDelimitedFileContent.replace(/"\t/g, '\t');   // trailing " on values

    return tabDelimitedFileContent;


  }

  showSelectionInTextAreaIfPopulated(): void {
    // console.log('showSelectionInTextAreaIfPopulated()', this.fcd, this.selectedField); // , (new Error()).stack);

    if (!this.fcd) {
      return;
    }

    if (!this.selectedField) {
      return;
    }

    try {
      let content = this.fcd.fileContent;

      // console.log('showSelectionInTextAreaIfPopulated() - content.length: ' + content.length);

      // ignore unpopulated textareas...
      if (!content || (content.length === 0)) {
        this.modalDialogService.showAlert('Please load an example report into the drop area,\nso that we can highlight the selected field.');
        return;
      }

      // we eliminate linefeeds because they screw up the column count...
      content = content.replace(/\r/g, '');

      let selectionStart = 0;

      const allRows = content.split('\n');

      const row = this.selectedField.tifFieldRow.value;
      const column =  this.selectedField.tifFieldColumn.value;
      let length = this.selectedField.tifFieldLength.value;

      // console.log('row: ' + row + '\ncolumn: ' + column + '\nlength: ' + length);

      // ignore fields that have no row/column offset...
      if ((row === null) || (column === null)) {
        return;
      }

      this.selectedRow = allRows[row];
      let selectedRowOffset = 0;
      for (let i = 0; i < row; i++) {
        // console.log('selectedRowOffset: ' + selectedRowOffset + '\n(allRows[i].length + 1): ' + (allRows[i].length + 1));
        selectedRowOffset += (allRows[i].length + 1); // the +1 is for the \n char used in the split() call...
      }
      // console.log('row: ' + row + '\nselectedRowOffset: ' + selectedRowOffset);

      if (this.selectedRow.indexOf('\t') >= 0) {

        const allTabs = this.selectedRow.split('\t');
        // console.log(JSON.stringify(allTabs));

        if ((0 < column) && (column >= allTabs.length)) {
          throw new Error('Not enough columns in report.\nHave you loaded the correct report?');
        }

        selectionStart = selectedRowOffset;
        for (let j = 0; j < column; j++) {
          const tabLength = allTabs[j].length;
          selectionStart += (tabLength + 1); // the +1 is for the \t char used in the split() call...
        }
        length = allTabs[column].length;

      } else {
        selectionStart = Number(selectedRowOffset) + Number(column);
      }

      // now highlight the content in the textarea...

      const selectionEnd = Number(selectionStart) + Number(length);

      // console.log('About to set...\n\nselectionStart: ' + selectionStart + '\nselectionEnd: ' + selectionEnd + '\nlength: ' + length);

      this.initialSelectionStart = selectionStart;
      this.initialSelectionEnd = selectionEnd;

      // console.log('Just set...\n\nthis.initialSelectionStart: ' + this.initialSelectionStart + '\nthis.initialSelectionEnd: ' + this.initialSelectionEnd);

    } catch (ex) {
      this.userInterfaceService.alertUserToException(ex, 'showSelectionInTextAreaIfPopulated())');
    }

  }

  selectFieldName(selectedFieldName: string): void {
    this.selectedFieldName = selectedFieldName;

    this.selectedField = this.userInterfaceService.getSelectedResource(this._importTemplateFieldEH);
    this.userInterfaceService.unSelectResource(this._importTemplateFieldEH);

    this.selectedText = '';
    this.selectedRow = '';

    if (this.selectedField
        && (this.selectedField.tifID.value === this.resource.tifID.value)
        && (this.selectedField.tifFieldName.value === this.selectedFieldName)
    ) {

      // if we're on the right one, RE-select it...
      this.userInterfaceService.selectResource(this._importTemplateFieldEH, this.selectedField);
      this.showSelectionInTextAreaIfPopulated();

    } else {

      // if we're on the wrong one, go get the right one...

      const parms: any = {};
      parms.tifID = this.resource.tifID.value;
      parms.tifFieldName = this.selectedFieldName;

      this.eventServerService.loadResourceFromServer(this._importTemplateFieldEH, parms, false).subscribe(
        (tifField: WResource) => {
          if (tifField) {
            this.selectedField = tifField;
          } else {
            this.selectedField = this.eventServerService.newResource(this._importTemplateFieldEH);
            this.selectedField.tifFieldName.value = this.selectedFieldName;
            this.selectedField.tifFieldName.changed = true;
            this.selectedField.tifFieldName.displayValue = 'new, unsaved ' + this.eventServerService.getResourceTypeForEventHandler(this._importTemplateFieldEH);
            this.selectedField.tifID.value = this.resource.tifID.value;
            this.selectedField.tifID.changed = true;
          }
          this.selectedField.tifID.displayValue = this.resource.tifID.displayValue;
          this.selectedField.resetDisplayLabel();

          this.userInterfaceService.selectResource(this._importTemplateFieldEH, this.selectedField);

          this.showSelectionInTextAreaIfPopulated();
        }
      );
    }

  }

  saveImportFieldDefn(): void {

    // console.log('saveImportFieldDefn()', this.selectedField);

    try {

      if (this.selectedField.hasChangedFields()) {

        const parms: any = this.selectedField.getChangedFieldValuesAsParms();
        let action = 'add';
        if (this.selectedField.tifFieldID.isPopulated) {
          parms.tifFieldID = this.selectedField.tifFieldID.value;
          action = 'modify';
        }

        // console.log('saveImportFieldDefn()\nEH: ' + fieldEHName + '\naction: ' + action + '\nparms: ' + JSON.stringify(parms));

        this.eventServerService.fireEvent(this._importTemplateFieldEH, action, parms).subscribe(
          (responseEvent: WEvent) => {
            try {
              if (responseEvent.status !== 'OK') {
                throw new Error('Failed to save ImportTemplateField! (' + responseEvent.message + ')');
              }

              // we un-select the field we just updated and re-initialize...

              this.userInterfaceService.unSelectResource(this._importTemplateFieldEH);

              this.selectedField = null;
              this.selectedFieldName = null;
              this.selectedText = '';
              this.selectedRow = '';

            } catch (ex) {
              const msg = 'saveImportFieldDefn()\n';
              this.userInterfaceService.alertUserToException(ex, msg);
            }
          }
        );
      } else {
        this.modalDialogService.showAlert('The ImportTemplateField "' + this.selectedFieldName + '" has no changes to save...');
      }

    } catch (ex) {
      const msg = 'saveImportFieldDefn()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

}
