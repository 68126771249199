import { Component, OnDestroy, OnInit, Renderer2, Input, AfterViewInit, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { HtmlEditorService } from 'src/app/client-core/services/html-editor.service';
import { StringComponent } from '../string/string.component';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';

@Component({
  selector: 'wackadoo-field-html',
  templateUrl: './html.component.html',

})
export class HtmlComponent extends StringComponent implements OnInit, OnDestroy, AfterViewInit {

  public wysiwyg: boolean;
  public wysiwygSubscription: Subscription;

  public editorConfig = null;

  public screenSizeChangeSubscription: Subscription;

  @Input() wrapperElementSelector: string = null;
  @Input() editorHeightFudgeFactor = 0;

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    public htmlEditorService: HtmlEditorService,
    public userInterfaceService: UserInterfaceService,
    public elementRef: ElementRef,
    public utilityLibService: UtilityLibService,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.editorConfig = this.htmlEditorService.editorConfig;

    this.wysiwyg = this.htmlEditorService.wysiwygMode.getValue();

    this.wysiwygSubscription = this.htmlEditorService.wysiwygMode.subscribe(
      (wysiwyg: boolean) => {
        this.wysiwyg = wysiwyg;
        this.setTextAreaHeight();
      }
    );

    this.screenSizeChangeSubscription = this.userInterfaceService.screenSizeChange.subscribe(
      () => {
        this.setTextAreaHeight();
      }
    );

  }

  ngOnDestroy(): void {
    if (this.wysiwygSubscription) {
      this.wysiwygSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    if (!this.wysiwyg) {
      this.setTextAreaHeight();
    }
  }

  setTextAreaHeight(): void {
    if (!this.wysiwyg) {
      window.setTimeout(
        () => {
          const wrapperElement = (this.wrapperElementSelector ? window.document.querySelector(this.wrapperElementSelector) : this.elementRef.nativeElement);
          // the "- 6" is due to a mysterious 6px margin we see in HtmlComponent when toggled to textarea...
          this.htmlEditorService.setEditorHeightAndWidthToFillWrapper(wrapperElement, this.renderer, this.editorHeightFudgeFactor - 6, 'wackadoo-field-html textarea.form-control.purple');
        }, 0
      );
    }
  }

  ///////////////////////////////////
  // TinyMCE Event handling methods
  ///////////////////////////////////

  onEditorPostRender(): void {
    const wrapperElement = (this.wrapperElementSelector ? window.document.querySelector(this.wrapperElementSelector) : this.elementRef.nativeElement);
    this.htmlEditorService.setEditorHeightAndWidthToFillWrapper(wrapperElement, this.renderer, this.editorHeightFudgeFactor);
  }

  onEditorBlur(e: any): void {
    if (this.f.changed && !this.f.isValidValue(e.editor.getContent())) {
      this.modalDialogService.showAlert('Please enter a valid value.', 'Invalid Data Entry');
    }
  }

  onEditorChange(e: any): void {
    const xhtml = this.htmlEditorService.cleanXHTML(e.editor.getContent());

    // console.log('onEditorChange()', xhtml);

    this.f.text = xhtml;

    // we CANNOT use btoa() because it does NOT support UTF-8 properly...
    // this.f.value = btoa(xhtml);
    this.f.value = this.utilityLibService.btoaForUnicodeString(xhtml);

    this.f.changed = true;

    // Apparently, we cannot show the field has changed by applying the
    // "fieldChanged" CSS class to the element itself, so we mimic it...
    this.renderer.addClass(e.editor.editorContainer, 'fieldChanged');
    // e.editor.editorContainer.style.borderColor = 'blue';
  }

  // This debug code was used to figure out how TinyMCE fired it's Events...
  // onEditorEvent(e: any): void {
  //   console.log('onEditorEvent(' + e.event.type + ')');
  // }

}
