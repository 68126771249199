import { Subscriber } from 'rxjs';

export class AlertContent {
  observer: Subscriber<void>;
  message = '';
  title = '';

  constructor(observer: Subscriber<void>, message: string, title?: string) {
    this.observer = observer;
    this.message = message;
    this.title = (title ? title : '');
  }
}

