import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-about-custom-dev',
  templateUrl: './about-custom-dev.component.html',

})
export class AboutCustomDevComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
