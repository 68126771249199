import { Component, OnInit, OnDestroy } from '@angular/core';
import { WEvent, EventEntry } from '../../data/event.model';
import { UserAuthService } from '../../services/user-auth.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { Subscription } from 'rxjs';
import { WResource } from '../../data/resource.model';
import { PageComponent } from '../page/page.component';
import { ModalDialogService } from '../../services/modal-dialog.service';

@Component({
  selector: 'wackadoo-event-page',
  templateUrl: './event-page.component.html',

})
export class EventPageComponent extends PageComponent implements OnInit, OnDestroy {

  // this avoids logging null access errrors...
  public event: WEvent = new WEvent();

  // bits parsed out of the WEvent...
  public eventHandler: string = null;
  public action: string = null;
  public parameters: any = null;
  public status: string = null;
  public message: string = null;
  public resources: WResource [] = null;
  public trace: { entry: EventEntry [] } = null;

  public pageEventSubscription: Subscription = null;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    // console.log('EventPage.ngOnInit()', this.pageEventSubscription, this.userInterfaceService.pageEvent.observers.length, this.eventHandler, this.action, this.parameters, this.event, new Error().stack);
    this.setupSubscription();
  }

  ngOnDestroy(): void {
    this.clearupSubscription();
    super.ngOnDestroy();
  }

  /**
   * This method is to be over-written by sub-classes to respond AFTER the event has returned...
   */
  postPageEvent(): void {
    // console.log(this.resources);
  }

  setupSubscription(): void {
    this.pageEventSubscription = this.userInterfaceService.pageEvent.subscribe(
      (event: WEvent) => {

        // console.log('EventPage.subscribe() - Got page event!', event);

        if ( event !== null) {
          this.event = event;

          this.eventHandler = event.firstHandler;
          this.action = event.action;
          this.parameters = event.parameters;
          this.status = event.status;
          this.message = event.message;
          this.resources = event.resources;
          this.trace = event.trace;

          this.postPageEvent();

        } else {
          // this avoids logging null access errrors...
          this.event = new WEvent();
          this.eventHandler = null;
          this.action = null;
          this.parameters = null;
          this.status = null;
          this.message = null;
          this.resources = null;
          this.trace = null;
        }
      }
    );
  }

  clearupSubscription(): void {
    if (this.pageEventSubscription) {
      this.pageEventSubscription.unsubscribe();
    }
  }

}
