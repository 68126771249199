<div class="nowrap">
  <span class="" ngbDropdown display="static" placement="bottom-left">
    <button 
        class="btn btn-outline-secondary dropdown-toggle wackadoo-grid-control round-left-corners" 
        type="button" ngbDropdownToggle id="sortByDropdown" 
        [disabled]="disabled"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
    >
      <span>
        <span *ngIf="sortDirection > 0" class="fas fa-arrow-up"></span>
        <span *ngIf="sortDirection < 0" class="fas fa-arrow-down"></span>
      </span>
      {{ sortBy }}
    </button>
    <div class="dropdown-menu smallish paddingTop0 paddingBottom0" ngbDropdownMenu aria-labelledby="sortByDropdown">
      <ng-container *ngFor="let fAPI of fieldAPIs;">
        <a class="dropdown-item" (click)="setSortBy(fAPI.name)" *ngIf="(fAPI.detailOnly !== true)">
          {{ fAPI.name }}
        </a>
      </ng-container>
    </div>
  </span>
  <button class="btn btn-outline-secondary wackadoo-grid-control paddingLeftQuarterEm paddingRightQuarterEm round-right-corners" title="Toggle sort direction" (click)="toggleSort()">
      <span class="fas fa-arrow-up"></span>
      <span class="fas fa-arrow-down"></span>
  </button>
</div>
