import { Component, OnInit, OnDestroy } from '@angular/core';
import { PriceListAddModalComponent } from '../price-list-add-modal/price-list-add-modal.component';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Subject, Subscription } from 'rxjs';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WField } from 'src/app/client-core/data/field.model';

@Component({
  selector: 'wackadoo-price-list-package-select-modal',
  templateUrl: './price-list-package-select-modal.component.html',
})
export class PriceListPackageSelectModalComponent extends PriceListAddModalComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'PriceListPackageSelectModalComponent';

  potentialNewPriceListPackageLinks: WResource [] = null;

  addLinkToListSubject: Subject<WResource> = null;
  addLinkToListSubscription: Subscription = null;

  // we need this subject because the resource is selected BEFORE the checkbox changes value... (event race condition of some kind...)
  checkBoxChangeSubject: Subject<WField> = null;
  checkBoxChangeSubscription: Subscription = null;

  constructor(
    userInterfaceService: UserInterfaceService,
    eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {

    this.loadDefaults = false;
    this.loadExisting = true;

    super.ngOnInit();

    this.addLinkToListSubject = new Subject<WResource>();

    this.addLinkToListSubscription = this.addLinkToListSubject.subscribe(
      (pli: WResource) => {
        if (pli) {
        }
      }
    );

    this.checkBoxChangeSubject = new Subject<WField>();

    this.checkBoxChangeSubscription = this.checkBoxChangeSubject.subscribe(
      (f: WField) => {
        if (f) {
          this.resource[f.name].value = f.value;
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.addLinkToListSubscription) {
      this.addLinkToListSubscription.unsubscribe();
    }

    if (this.checkBoxChangeSubscription) {
      this.checkBoxChangeSubscription.unsubscribe();
    }

    super.ngOnDestroy();

  }

  // checkForCircularPackageDefinition(pli: WResource, child: WResource): void {
  //   // console.log('checkForCircularPackageDefinition()', pli.itemName.value, pli.resourceType.value, (child ? child.itemName.value : ''), (child ? child.resourceType.value : '') );
  //   if (pli.resourceType.value === 'Package') {
  //     this.eventServerService.fireEvent('PriceListPackageLinks', 'list', pli.keyField.asParm).subscribe(
  //       (event: WEvent) => {
  //         try {
  //           if (event.status !== 'OK') {
  //             throw new Error(event.message);
  //           }
  //           let packageLinks = event.resources;
  //           if (!packageLinks) {
  //             packageLinks = [];
  //           }
  //           let circularChild = null;
  //           for (const link of packageLinks) {
  //             if (link.priceListItemID.value === this.resource.priceListItemID.value) {
  //               circularChild = this.existingPriceList.find(x => x.priceListItemID.value === link.priceListItemID.value);
  //               break;
  //             }
  //           }
  //           if (circularChild === null) {
  //             for (const link of packageLinks) {
  //               const child2 = this.existingPriceList.find(x => x.priceListItemID.value === link.priceListItemID.value);
  //               if (child2) {
  //                 this.checkForCircularPackageDefinition(pli, child2);
  //               }
  //             }
  //           } else {
  //             let msg = 'Preventing circular Package structure for ' + pli.itemName.value;
  //             msg += '\nFound ' + circularChild.itemName.value + ' in ' + child.itemName.value;
  //             this.modalDialogService.showAlert(msg, 'Circular Package Definition!');
  //             const idx = this.potentialNewPriceListPackageLinks.findIndex(x => x.keyField.value === circularChild.keyField.value);
  //             this.potentialNewPriceListPackageLinks.splice(idx, 1);
  //           }
  //         } catch (ex) {
  //           const msg = 'PriceListPackageSelectModalComponent.checkForCircularPackageDefinition()';
  //           this.userInterfaceService.alertUserToException(ex, msg);
  //         }
  //       }
  //     );
  //   }
  // }

  loadPriceListItemsToSelect(): void {
    try {

      // REMEMBER: this.resource is a PriceListPackageLink, NOT a PriceListItem!

      // console.log('loadPriceListItemsToSelect()', this.resource, this.existingPriceList);

      this.potentialNewPriceListPackageLinks = [];

      for (const pli of this.existingPriceList) {

        // only show stuff from the existing list that:
        // a) is not itself
        // b) is not a purely cash gift
        // c) does not create a "circular" Package definition (but checkForCircularPackageDefinition() is not yet working...)

        if (
            (pli.priceListItemID.value !== this.resource.priceListItemID.value)
            &&
            (pli.resourceType.value !== 'Cash')
        )  {

          const plpl: WResource = this.eventServerService.newResource('PriceListPackageLinks');

          // identify the "parent" PriceListItem...

          plpl.priceListItemID.value = this.resource.priceListItemID.value;

          // identify the "child" PriceListItem...

          plpl.priceListItemChildID.value = pli.priceListItemID.value;
          plpl.priceListItemChildID.displayValue = pli.itemName.value;
          plpl.priceListItemChildID.changed = true;

          // console.log('addLinkToListSubscription()', plpl);

          this.potentialNewPriceListPackageLinks.push(plpl);

        }
      }

      // console.log('loadPriceListItemsToSelect()', this.potentialNewPriceListItems);

    } catch (ex) {
      const msg = 'PriceListPackageSelectModalComponent.loadPriceListItemsToSelect()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
