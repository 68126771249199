<div>
  <!-- This is what wackadoo admins, et al, see... -->
  <div *ngIf="user.isManagerOrAbove">
    <div class="btn-group">
      <button type="button" title="" data-abbrev="" class="btn btn-secondary actionButton" (click)="runServiceNow()"><span class="fas fa-usd" aria-hidden="true"></span> Run Service Now</button>
    </div>
    <div class="card">
      <div class="card-header">
        Application Registration Details Per Account
      </div>
      <div class="card-body">
        <div *wackadooResourceBlock="let acct of []; let idx = index; eventHandler:'Accounts'; action:'list'; parms:{}; defaultText: 'No accounts...'; debug: false;">
          <!-- skip the wackadoo admin account -->
          <div *ngIf="acct.accountID.value > 1">
            <div class="row marginTopHalfEm">
              <div class="col-sm-9 large bold">
                {{ acct.accountName.value }}
              </div>
              <div class="col-sm-3">
                <button type="button" class="btn btn-secondary" (click)="createTheBillingAccount(acct)">Create Billing Account</button>
              </div>
            </div>
            <div *wackadooResourceBlock="let app of []; let idx = index; eventHandler:'Applications'; action:'list'; parms:{}; defaultText: 'Error! No applications...'; debug: false;">
              <!-- skip the AccountManager app -->
              <div *ngIf="app.appName.value !== 'AccountManager'" >
                <wackadoo-reg-app-billing-control 
                    [accountID]="acct.accountID.value" 
                    [accountName]="acct.accountName.value" 
                    [app]="app" 
                    [user]="user" 
                    [creditCardIsOnFile]="creditCardIsOnFile" 
                    [optInMessage]="theBigOptIn"
                ></wackadoo-reg-app-billing-control>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- This is what customers see... -->
  <form *ngIf="user.isMemberOnly" id="billingSetupForm" method="post" action="./EventServer/Billing/createCustomerAndVerifyCreditCard">
    <div class="row">
      <div class="col-sm-8">
        <div class="card">
          <div class="card-header">
            <div>
              <span *ngIf="creditCardIsOnFile">
                To subscribe or purchase using the Credit Card On File, simply click the appropriate button(s) below.
              </span>
              <span *ngIf="!creditCardIsOnFile">
                You are unable to subscribe or purchase until you have entered a valid credit card.
              </span>
            </div>
            <div class="small italic" *ngIf="!creditCardIsOnFile">
              <ul>
                <li>
                  For subscriptions, we use the Credit Card On File for the initial and recurring charges.
                </li>
                <li>
                  For single-payment items, you may remove the Credit Card On File after the purchase has been made.
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <p class="small">
              The first time you subscribe to a "per month" web app, you get a 14 day free trial period, per our standard Billing Policy.
            </p>
            <span *wackadooResourceBlock="let app of []; let idx = index; eventHandler:'Applications'; action:'list'; parms:{status: 'released'}; defaultText: 'No released applications...'; debug: false;">
              <!-- skip the AccountManager app -->
              <div *ngIf="app.appName.value !== 'AccountManager'" >
                  <wackadoo-reg-app-billing-control 
                      [accountID]="user.accountID" 
                      [accountName]="user.accountName" 
                      [app]="app" 
                      [user]="user" 
                      [creditCardIsOnFile]="creditCardIsOnFile" 
                      [optInMessage]="theBigOptIn"
                  ></wackadoo-reg-app-billing-control>
              </div>
            </span>
            <span *wackadooResourceBlock="let app of []; let idx = index; eventHandler:'Applications'; action:'list'; parms:{status: 'beta'}; defaultText: ''; debug: false;">
                <wackadoo-reg-app-billing-control 
                    [accountID]="user.accountID" 
                    [accountName]="user.accountName" 
                    [app]="app" 
                    [user]="user" 
                    [creditCardIsOnFile]="creditCardIsOnFile" 
                    [optInMessage]="theBigOptIn"
                ></wackadoo-reg-app-billing-control>
            </span>
            <span *wackadooResourceBlock="let app of []; let idx = index; eventHandler:'Applications'; action:'list'; parms:{status: 'private'}; defaultText: ''; debug: false;">
                <wackadoo-reg-app-billing-control 
                    [accountID]="user.accountID" 
                    [accountName]="user.accountName" 
                    [app]="app" 
                    [user]="user" 
                    [creditCardIsOnFile]="creditCardIsOnFile" 
                    [optInMessage]="theBigOptIn"
                ></wackadoo-reg-app-billing-control>
            </span>
            <p class="marginTopWholeEm large bold italic red">
              {{ theBigOptIn }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card">
          <div class="card-header floatGroup lightblue-bg">
            <span class="floatLeft">
              Credit Card On File
            </span>
            <span class="floatRight">
              <span class="small italic">
                <span *ngIf="creditCardIsOnFile ; else noCreditCard2">
                    <img class="max-height-2-em marginRightHalfEm" src="{{ creditCard.imageURL.value }}" />
                    x{{ creditCard.lastFour.value }},
                    exp: {{ creditCard.expiration.value }}
                    <button type="button" (click)="removeCreditCard()" class="btn btn-secondary marginLeftWholeEm smallish paddingHalfEm" title="Remove Credit Card On File">Remove</button>
                </span>
                <ng-template #noCreditCard2>
                  <span class="marginRightWholeEm paddingRightWholeEm">none</span>
                </ng-template>
              </span>
            </span>
          </div>
          <div class="card-body">
            <div class="marginBottomHalfEm">
              <a class="link" (click)="toggle()" [attr.aria-expanded]="!isCollapsed">
                Click on this link to learn about how we protect your credit card...
                <span *ngIf="!isCollapsed">
                  (or to close this when done reading...)
                </span>
              </a>
            </div>
            <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
              <div class="well lightblue-bg marginWholeEm">
                The credit card input section below is a "drop-in" form from our credit card service provider 
                <a href="https://www.braintreepayments.com/" target="_blank">Braintree Payments (A PayPal company)</a>  
                that acts like a tunnel directly to their servers.  We do not look at or store the credit 
                card information that you enter below, which goes straight to them. However, we do request 
                a partial description of your currently saved credit card to display on the blue bar above.
              </div>
            </div>
            <div id="payment-form"></div>
            <div class="center marginBottomHalfEm">
              <button type="button" (click)="save()" class="btn btn-secondary" title="Update/Save Credit Card On File">
                <span *ngIf="creditCardIsOnFile ; else saveCard">
                  Update
                </span>
                <ng-template #saveCard>
                  Save
                </ng-template>
              </button>
            </div>
            <div class="small italic center marginBottomHalfEm">
              The "{{(creditCardIsOnFile ? 'Update' : 'Save')}}" button verifies the credit card, but does not make any charges against it.
              <br/>
              See Billing Policy for details.
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
