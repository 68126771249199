import { Component, OnInit } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { WResource } from 'src/app/client-core/data/resource.model';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-new-account-detail-button-bar',
  templateUrl: './new-account-detail-button-bar.component.html',
})
export class NewAccountDetailButtonBarComponent extends DetailButtonBarComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'NewAccountDetailButtonBarComponent';

  appPrice: WResource = null;
  app: WResource = null;

  theBigOptIn = '(' + Globals.theBigOptIn + ')';

  saveButtonTitle: string = null;

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    modalDialogService: ModalDialogService,
  ) {
    super(eventServerService, userInterfaceService, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.saveButtonTitle = this.user.isPublicOnly ? 'Submit' : 'Save';
  }

  ///////////////////////////////////
  // NewCustomer actions...
  ///////////////////////////////////

  save(): void {

    // this is a modified version of Page.save() ...

    const msg = this.theBigOptIn;

    this.modalDialogService.showConfirm(msg, 'Important!').subscribe(
      (flag: boolean) => {
        if (flag) {

            // This looks for the key field in the current resource.
            // In short, we do a "modify" if we have a valid key (i.e. it is present, populated and non-null)
            const addMode = !this.resource.keyField.isPopulated;

            // console.log(this.resource, this.resource.asParms, this.resource.getChangedFieldValuesAsParms(false));
            const badField = this.resource.identifyMissingOrInvalidField(addMode);
            if (badField) {
              this.modalDialogService.showAlert('Invalid field value or missing required field: ' + badField.name, 'Error');
            } else {
              /////////////////////////////////////////////////
              // add email parameters to the parms...
              /////////////////////////////////////////////////

              const parms: any = this.resource.getChangedFieldValuesAsParms(!addMode);

              parms.to = this.resource.accountEmail.value;
              parms.fromName = Globals.domain + ' - Account Registration';
              parms.eventUrlPrefix = this.userInterfaceService.getApplicationUrl();
              parms.domain = Globals.domain;

              // const msg2 = 'These are the parameters for the ' + (this.eventHandler + '.' + (addMode ? 'add' : 'modify')) + '() event we are about to fire...';
              // console.log(msg2, parms);

              // do the save here, then notify the user what's up...

              this.modalDialogService.showPleaseWait('Processing new account...');

              this.eventServerService.fireEvent(
                this.eventHandler,
                (addMode ? 'add' : 'modify'),
                parms
              ).subscribe(
                (event: WEvent) => {

                  this.modalDialogService.showPleaseWait(false);

                  if (event.status === 'OK') {

                    this.modalDialogService.showAlert('We have sent a validation message to the given email address.\n\n(If it does not arrive within a minute or two, check your junk mail folder, or you can try again with the same account host name in ten minutes.)', 'Success!').subscribe(
                      () => {
                        this.cancel();
                      }
                    );
                  } else {
                    // console.log('The save operation failed!', event);
                    if (JSON.stringify(event).indexOf('NumberFormatException') > -1) {
                      this.modalDialogService.showAlert('Invalid or missing CAPTCHA.').subscribe(
                        () => {
                          this.userInterfaceService.focusOnField(this.repositoryPageElementRef, this.resource.captchaAnswer);
                        }
                      );
                    } else {
                      this.modalDialogService.showAlert('The save operation failed!\n\n' + event.message, 'Warning!');
                    }
                  }
                }
              );
            }
          }
        }
    );
  }

  cancel(): void {
    this.userInterfaceService.unSelectResource(this.eventHandler, true);
    // public users get sent back to the home page instead of the NewCustomer grid...
    if (this.user.isPublicOnly) {
      if (Globals.rootApplication === Globals.thisApplication) {
        this.userInterfaceService.loadPage('home');
      } else {
        window.location.href = '/';
      }
    } else {
      super.cancel();
    }
  }

}
