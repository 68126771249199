import { Subscriber } from 'rxjs';

export class ErrorModalContent {
  observer: Subscriber<void>;
  title: string = null;
  message: string = null;
  status: string = null;
  error: any = null;

  constructor(observer: Subscriber<void>, msg: string, title: string, status?: string, error?: any) {
    this.observer = observer;
    this.message = msg;
    this.title = title;
    this.status = status;
    this.error = error;
  }
}
