import { Subscriber} from 'rxjs';

export class ConfirmContent {

  observer: Subscriber<boolean>;
  message = '';
  title = '';

  constructor(observer: Subscriber<boolean>, message: string, title: string) {
    this.observer = observer;
    this.message = message;
    this.title = (title ? title : '');
  }
}
