		<div class="card">
			<div class="card-header">
				Substance over style
			</div>
			<div class="card-body">
				<p>
					As you can surmise from this web site, we don't worry a lot about trying to make 
					things look slick - or even original.  We intentionally rely on the default settings 
					in the third-party UX library we have selected, and then adjust individual pages on 
					an as-needed basis.
				</p>
				<p>
					So, in the near term, sometimes we'll roll out an app page where, for example, the 
					UI elements load in an odd order, or don't behave perfectly in response to re-sizing 
					your screen, or something like that - and we will fix those kinds of things.
					However, at this stage of our growth, we prioritize perfection in UX surface 
					implementation behind working with you to provide well-thought-out functionality 
					that helps you get your job done. (That is, until we can get a real UX person on-board...)
				</p>
			</div>
		</div>
