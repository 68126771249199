import { Component, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { WField } from 'src/app/client-core/data/field.model';
import { WResource } from 'src/app/client-core/data/resource.model';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-administration',
  templateUrl: './administration.component.html',

})
export class AdministrationComponent extends PageComponent implements OnInit {

  resources: WResource [] = [];
  messages: string [] = [];

  currentResourceType: string = null;

  message: WField = null;
  messageSize = 256;

  drillDown: any = {};

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.message = new WField('message', '', 'string');
    this.message.length = this.messageSize;
    // console.log(this.message);
  }

  extractTraceMessages(event: WEvent): void {
    this.messages = [];
    if (event) {
      if (event.trace) {
        for (const entry of event.trace.entry) {
          if (entry) {
            this.messages.push(entry.message);
          }
        }
      }
    }
  }

  extractStatistics(resources: WResource []): void {
    if (resources) {
      for (const r of resources) {
        if (r) {
          const app = (r.getApplication() ? r.getApplication() : Globals.thisApplication);
          if (!this.drillDown[app]) {
            this.drillDown[app] = {};
          }
          const ehName = r.getName();
          if (!this.drillDown[app][ehName]) {
            this.drillDown[app][ehName] = {};
          }
          const method = r.title.value;
          this.drillDown[app][ehName][method] = r;
        }
      }
    }
  }

  extractEventHandlers(resources: WResource []): void {
    if (resources) {
      for (const r of resources) {
        if (r) {
          const app = (r.getApplication() ? r.getApplication() : Globals.thisApplication);
          if (!this.drillDown[app]) {
            this.drillDown[app] = {};
          }
          const ehName = r.getName();
          let pipelineName = ehName;
          if (ehName.match(/[a-zA-Z0-9]*-[0-9]*/)) {
            pipelineName = ehName.split('-')[0];
          }
          if (!this.drillDown[app][pipelineName]) {
            this.drillDown[app][pipelineName] = {};
          }
          this.drillDown[app][pipelineName][ehName] = r;
        }
      }
    }
  }

  fireEvent(eventHandler: string, action: string, parms?: any): void {
    parms = typeof parms !== 'undefined' ? parms : {};

    try {

      this.currentResourceType = null;
      this.resources = [];
      this.messages = [];

      // console.log('fireEvent() - firing... ' + eventHandler + '.' + action + '()', parms);

      this.modalDialogService.showPleaseWait(true);

      this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
        (event: WEvent) => {
          try {
            this.modalDialogService.showPleaseWait(false);

            // console.log('fireEvent()', event);

            if (event.status !== 'OK') {
              this.extractTraceMessages(event);
              throw new Error(event.message);
            }

            if (event.resources) {
              this.currentResourceType = event.resources[0].getType();
              this.resources = event.resources;
            } else {
              this.extractTraceMessages(event);
              // this.modalDialogService.showAlert('Command completed: ' + eventHandler + ' ' + action, 'Success');
            }

            // console.log('fireEvent()', this.resources);

          } catch (ex){
            const msg2 = 'fireEvent().callback()\n';
            this.userInterfaceService.alertUserToException(ex, msg2);
          }
        }
      );

    } catch (ex) {
      const msg = 'fireEvent()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  bounceSocketListener(): void {
    try {

      this.currentResourceType = null;
      this.resources = [];
      this.messages = [];

      this.modalDialogService.showPleaseWait(true);

      this.eventServerService.fireEvent('SocketEventListener', 'stop', {}).subscribe(
        (event: WEvent) => {
          try {
            this.modalDialogService.showPleaseWait(false);

            console.log('bounceSocketListener()', event);

            if (event.status !== 'OK') {
              throw new Error(event.message);
            }

            this.fireEvent('SocketEventListener', 'start', {});

          } catch (ex){
            const msg2 = 'bounceSocketListener().callback()\n';
            this.userInterfaceService.alertUserToException(ex, msg2);
          }
        }
      );

    } catch (ex) {
      const msg = 'bounceSocketListener()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  runServerMonitor(): void {
    try {

      this.currentResourceType = null;
      this.resources = [];
      this.messages = [];

      this.modalDialogService.showPleaseWait(true);

      this.eventServerService.fireEvent('ServerMonitor', 'runNow', {}).subscribe(
        (event: WEvent) => {
          try {
            this.modalDialogService.showPleaseWait(false);

            console.log('runServerMonitor()', event);

            if (event.status !== 'OK') {
              throw new Error(event.message);
            }

            this.fireEvent('Administration', 'getStatus', {name: 'ServerMonitor'});

          } catch (ex){
            const msg2 = 'runServerMonitor().callback()\n';
            this.userInterfaceService.alertUserToException(ex, msg2);
          }
        }
      );

    } catch (ex) {
      const msg = 'runServerMonitor()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

}
