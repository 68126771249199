import { Component, OnInit, OnDestroy, Renderer2, Input } from '@angular/core';
import { FieldComponent } from '../field.component';
import { ModalDialogService } from '../../../services/modal-dialog.service';

@Component({
  selector: 'wackadoo-field-string',
  templateUrl: './string.component.html',

})
export class StringComponent extends FieldComponent implements OnInit, OnDestroy {

  @Input() rows: number = null;
  @Input() forceWrap = false;

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  augmentTitle(): string {
    return (
      (this.title ? this.title : this.f.name)
      +
      (this.f.length && (this.f.length > this.size)
        ? (' ('
          + (this.f.isPopulated && this.f.length ? this.f.value.length : 0)
          + '/'
          + this.f.length
          + ')'
          )
        : ''
      )
    );
  }

  augmentTextAreaTitle(): string {
    return (
      (this.title ? this.title : this.f.name)
      + (' ('
          + (this.f.isPopulated && this.f.length ? this.f.value.length : 0)
          + '/'
          + this.f.length
          + ')'
        )
    );
  }

}
