import { Component, OnInit } from '@angular/core';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { User } from 'src/app/client-core/data/user.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';

@Component({
  selector: 'wackadoo-reg-app',
  templateUrl: './reg-app.component.html',
})
export class RegAppComponent extends ResourceComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'RegAppComponent';

  user: User = null;

  constructor(
    userInterfaceService: UserInterfaceService,
    public userAuthService: UserAuthService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.user = this.userAuthService.currentUser.getValue();
  }

}
