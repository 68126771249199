import { Directive, ElementRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[wackadooGridContentPlaceholder]'
})
export class GridContentPlaceholderDirective {

  constructor(
    public elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef,
  ) { }

}
