import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageComponent } from '../../page/page.component';
import { UserAuthService } from '../../../services/user-auth.service';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-help-video-library',
  templateUrl: './help-video-library.component.html',

})
export class HelpVideoLibraryComponent extends PageComponent implements OnInit, OnDestroy {

  appName: string;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.appName = Globals.thisApplication;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  get isAcctMgr(): boolean {
    return this.appName === 'AccountManager';
  }

}
