<span *ngIf="f">
    <span *ngIf="mode === 'read'">
        ********
    </span>
    <span *ngIf="mode === 'write'">
        <input 
            class="form-control"
            type="password" 
            [placeholder]="(f.required ? '* ' : '') + f.name"
            [name]="f.name"
            [title]="(title ? title : f.name)"
            [size]="(f.length && (f.length < size) ? f.length : size)"
            [value]="f.value" 
            [pattern]="f.pattern"
            [maxLength]="f.length"
            [disabled]="f.readOnly"
            (input)="onInputChange($event)"
            (blur)="forceValidValue($event)"
            (focus)="onFocusMainInput()"
        />
        <input 
            class="form-control"
            type="password" 
            [placeholder]="'repeat new ' + f.name"
            [name]="f.name + 'Repeat'"
            [title]="(title ? title : f.name) + 'Repeat'"
            [size]="(f.length && (f.length < size) ? f.length : size)"
            [value]="" 
            [pattern]="f.pattern"
            [maxLength]="f.length"
            [disabled]="f.readOnly"
            (input)="onInputChangeOtherInput()"
            (blur)="onBlurOtherInput()"
        />
        <div class="small italic">
            Passwords must be at least {{ f.minLength }} characters in length,
            and include upper case, lower case, numeric and special characters.
        </div>
        <div class="small" *ngIf="showCommonPasswordWarning">
            <strong>Please Note:</strong>
            <p>
                The general pattern of characters in your password is "well known" and among the easiest to break by hackers.
                When we say "well known", we mean a pattern that enterprise security analysts have discovered to be popular and  
                published on the web - and therefore, easily downloadedable by hackers for use in brute force attacks.
            </p>
            <p>
                For details, please take a look at this site:
                <br/>
	            <a target="_blank" href="https://blog.korelogic.com/blog/2014/04/04/pathwell_topologies">https://blog.korelogic.com/blog/2014/04/04/pathwell_topologies</a>
            </p>
            <p>
                (See our Data Security Policy for more on how we protect your information.)
            </p>
        </div>
    </span>
</span>
