		<div class="card">
			<div class="card-header">
				Cookie Usage Policy...
			</div>
			<div class="card-body">
				<p>
					We use "session" cookies to help maintain user session information.
					Their auto-timeout period varies by the web app.
				</p>
				<p>
					We do not use "persistent" cookies that would be stored permanently by your browser.
				</p>
				<p>
					Yes, this means that you have to login every time you start up, 
					but we chose to err on the side of precaution, and web safety.
				</p>
			</div>
		</div>
