import { Component, OnInit, Input } from '@angular/core';
import { WResource } from 'src/app/client-core/data/resource.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import { User } from 'src/app/client-core/data/user.model';
import { Globals } from 'src/app/client-core/services/global.service';
import { BusinessRuleService } from 'src/app/client-core/services/business-rule.service';

@Component({
  selector: 'wackadoo-reg-app-billing-control',
  templateUrl: './reg-app-billing-control.component.html',
})
export class RegAppBillingControlComponent implements OnInit {

  @Input() accountID: number = null;
  @Input() accountName: string = null;
  @Input() app: WResource = null;
  @Input() user: User = null;
  @Input() creditCardIsOnFile = false;
  @Input() optInMessage: string = null;

  regApp: WResource = null;
  appPrice: WResource = null;

  isActive = false;

  constructor(
    public userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
    public businessRuleService: BusinessRuleService,
  ) {
  }

  ngOnInit(): void {

    this.eventServerService.loadResourceFromServer('AppPricing', this.app.keyField.asParm, false).subscribe(
      (r: WResource) => {
        this.appPrice = r;

        this.eventServerService.loadResourceFromServer('RegisteredApplications', {accountID: this.accountID, appID: this.app.keyField.value}, false).subscribe(
          (r2: WResource) => {
            this.regApp = r2;

            this.isActive = (
                              this.regApp
                              &&
                              this.regApp.expiration
                              &&
                              (
                                this.regApp.expiration.isNull
                                ||
                                (new Date(this.regApp.expiration.value).getTime() > new Date().getTime())
                              )
                            );
          }
        );
      }
    );

  }

  ////////////////////////////////////
  // purchasing actions...
  ////////////////////////////////////

  purchaseApplication(): void {
    try {
      const confirmationMessage = 'By clicking the OK button, I authorize <strong>' + Globals.organizationName + '</strong> to charge my credit card <strong>$'
                    + this.appPrice.price.value + ' ' + (this.appPrice.priceModel.value === 'per month' ? this.appPrice.priceModel.value : '')
                    + '</strong> for <strong>' + this.app.appName.value + '</strong>.'
                    + (this.appPrice.priceModel.value === 'per month' ? '.\n\nPLEASE NOTE: You are purchasing a FUTURE month of a subscription web app.  Per our Refund Policy, we do not offer refunds. We recommend that you let the normal monthly billing cycle charge you one month at a time.' : '')
                    + '\n\n' + this.optInMessage;

      this.modalDialogService.showConfirm(confirmationMessage, 'IMPORTANT! PAYMENT AUTHORIZATION!').subscribe(
        (ok: boolean) => {
          if (ok) {

            this.modalDialogService.showPleaseWait('Processing payment...');

            const parms: any = this.app.keyField.asParm;

            // console.log('Billing.purchaseApplication() - about to buy', parms);

            this.eventServerService.fireEvent('Billing', 'purchaseApplication', parms).subscribe(
              (event: WEvent) => {

                // console.log('Billing.purchaseApplication()', event, 'current page: ' + this.userInterfaceService.currentPage.getValue());

                this.modalDialogService.showPleaseWait(false);

                if (event.status === 'OK') {

                  // if this is a new purchase (i.e. they didn't already have a regApp that had expired sometime in the past...)

                  if (!this.regApp) {

                    const parms2: any = {};
                    parms2.userID = this.user.userID;
                    parms2.regAppID = event.parameters.regAppID; // this comes back as a by-product of a successful purchase...
                    parms2.role = this.businessRuleService.getPurchaserRole(this.app.appName.value);

                    // console.log('Billing.purchaseApplication() - about to set initial userAppRole', parms2);

                    this.eventServerService.fireEvent('Roles', 'add', parms2).subscribe(
                      (event2: WEvent) => {

                        // console.log('Billing.purchaseApplication() - Roles.add()', event);

                        if (event2.status !== 'OK') {
                          this.modalDialogService.showAlert('Failed to assign you the role of ' + parms2.role + ' in <strong>' + this.app.appName.value + '</strong>...\n' + event2.message).subscribe(
                            () => {
                              this.userInterfaceService.reloadCurrentPage();
                            }
                          );
                        } else {
                          this.userInterfaceService.reloadCurrentPage();
                        }
                      }
                    );

                  } else {
                    this.userInterfaceService.reloadCurrentPage();
                  }

                } else {
                  this.modalDialogService.showAlert('Purchasing error!\n' + event.message).subscribe(
                    () => {
                      this.userInterfaceService.reloadCurrentPage();
                    }
                  );
                }
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'Billing.purchaseApplication()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  cancelApplication(): void {
    try {
      // console.log('cancelApplication()', app);

      const msg = 'You will want to backup your data before you continue, because clicking OK will *immediately* cause'
      + ' you to lose access to <strong>' + this.app.appName.value + '</strong>, and all your data will be deleted.\n\nTHERE IS NO UN-DO! Please review'
      + ' our Backup Policy for more details.\n\nContinue, and immediately lose access to <strong>' + this.app.appName.value + '</strong> and all your data in it?';

      this.modalDialogService.showConfirm(msg, 'IMPORTANT! CANCELLATION CAUSES YOUR DATA TO BE DELETED!').subscribe(
        (ok: boolean) => {
          if (ok) {

            let plsWaitMsg = 'Cancelling <strong>' + this.app.appName.value + '</strong>...';
            plsWaitMsg += '<br/><br/>';
            plsWaitMsg += 'Please wait a minute or two while we email';
            plsWaitMsg += '<br/>';
            plsWaitMsg += 'a backup of your data to your account contact.';

            this.modalDialogService.showPleaseWait(plsWaitMsg);

            const parms = this.app.keyField.asParm;

            // console.log('Billing.cancelApplication() - about to cancel', parms);

            this.eventServerService.fireEvent('Billing', 'cancelApplication', parms).subscribe(
              (event: WEvent) => {
                // console.log('Billing.cancelApplication()', event);
                try {
                    this.modalDialogService.showPleaseWait(false);

                    if (event.status !== 'OK') {
                    this.modalDialogService.showAlert('Billing.cancelApplication() - error: ' + event.message).subscribe(
                      () => {
                        this.userInterfaceService.reloadCurrentPage();
                      }
                    );
                  } else {
                    this.userInterfaceService.reloadCurrentPage();
                  }
                } catch (ex) {
                  const msg2 = 'Billing.cancelApplication.okCallback()\n';
                  this.userInterfaceService.alertUserToException(ex, msg2);
                }
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'Billing.cancelApplication()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  billTheAccount(): void {
    // console.log('billTheAccount()', account, regApp, appPricing);

    try {

      let msg = 'This will charge the <strong>' + this.accountName + '</strong> account for <strong>' + this.regApp.appName.value + '</strong>';
      msg += ' which has a base price of <strong>$' + this.appPrice.price.value + ' ' + this.appPrice.priceModel.value + '</strong>';
      if (this.regApp.discount.value) {
        msg += ', taking into account the fact that they get a discount of <strong>' + this.regApp.discount.value + '%</strong>';
      }
      msg += '.';
      if (this.regApp.donation.value) {
        msg += '(Note that the' + (this.regApp.discount.value ? ' discounted ' : ' ') + 'fee to be charged is being donated...)';
      }
      if (this.appPrice.priceModel.value === 'per month') {
        msg += '\n\nPLEASE NOTE: You are charging them for a FUTURE month of a subscription web app.';
      }
      msg += '\n\nDo you wish to continue?';

      this.modalDialogService.showConfirm(msg, 'WARNING! You are about to cost people money!').subscribe(
        (ok: boolean) => {
          if (ok) {

            this.modalDialogService.showPleaseWait(true);

            const parms: any = {};
            parms.accountID = this.accountID;
            parms.appID = this.regApp.appID.value;

            this.eventServerService.fireEvent('Billing', 'purchaseApplication', parms).subscribe(
              (event3: WEvent) => {
                // console.log('Billing.createCustomerAndVerifyCreditCard() - purchaseApplication', event3);

                this.modalDialogService.showPleaseWait(false);

                if (event3.status !== 'OK') {
                  throw new Error(event3.message);
                }

                this.modalDialogService.showAlert('Customer was billed.\n' + event3.message, 'Success!').subscribe(
                  () => {
                    this.userInterfaceService.reloadCurrentPage();
                  }
                );
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'Billing.billTheAccount()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

}
