import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserAuthService } from '../../../services/user-auth.service';
import { Subscription } from 'rxjs';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { SelectedResource } from './selected-resource.model';
import { BusinessRuleService } from '../../../services/business-rule.service';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';

@Component({
  selector: 'wackadoo-current-selection',
  templateUrl: './current-selection.component.html',

})
export class CurrentSelectionComponent implements OnInit, OnDestroy {

  isLoggedIn = false;
  private _userAuthChangedSubscription: Subscription;
  private _selectionChangedSubscription: Subscription;

  // currentSelections is an array so we can use ngFor in the HTML template...
  currentSelections: SelectedResource [] = [];

  screenType: string;
  screenTypeSubscription: Subscription;

  constructor(
    private _userAuthService: UserAuthService,
    private _userInterfaceService: UserInterfaceService,
    private _businessRuleService: BusinessRuleService,
  ) { }

  ngOnInit(): void {

    this.currentSelections = [];

    this._userAuthChangedSubscription = this._userAuthService.currentUser.subscribe(
      () => {
        this.isLoggedIn = this._userAuthService.isLoggedIn;
      }
    );

    this._selectionChangedSubscription = this._userInterfaceService.selectionChange.subscribe(
      () => {
        // doesn't matter what comes in, we grab ALL the currentSelections...
        this.buildSelections();
      }
    );

    this.screenTypeSubscription = this._userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        // console.log('banner: ' + JSON.stringify(screenType));
        this.screenType = screenType;
      }
    );

  }

  ngOnDestroy(): void {
    if (this._userAuthChangedSubscription) {
      this._userAuthChangedSubscription.unsubscribe();
    }
    if (this._selectionChangedSubscription) {
      this._selectionChangedSubscription.unsubscribe();
    }
    if (this.screenTypeSubscription) {
      this.screenTypeSubscription.unsubscribe();
    }
  }

  buildSelections(): void {
    this.currentSelections = [];
    // convert UISvc.currentSelections into an array so we can use ngFor in the HTML template...
    for (const srEHName in this._userInterfaceService.currentSelections) {
      if (this._userInterfaceService.currentSelections[srEHName]) {
        this.currentSelections.push(this._userInterfaceService.currentSelections[srEHName]);
      }
    }
  }

  unselect(sr: SelectedResource): void {
    this._userInterfaceService.unSelectResource(sr.ehName, true);
  }

  getPageIcon(pageName: string): string {
    return this._businessRuleService.getMenuIcon(pageName);
  }

}
