import { Component, OnInit, ElementRef, OnDestroy, ComponentFactoryResolver, ComponentRef, AfterViewInit } from '@angular/core';
import { Subscriber, Subscription } from 'rxjs';
import { ResourceSelectContent } from './resource-select-content';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { WResource } from '../../../data/resource.model';
import { EventServerService } from '../../../services/event-server.service';
import { WEvent } from '../../../data/event.model';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';

@Component({
  selector: 'wackadoo-resource-select-modal',
  templateUrl: './resource-select-modal.component.html',
})
export class ResourceSelectModalComponent implements OnInit, OnDestroy, AfterViewInit {

  visible = false;

  showModalSubscription: Subscription;

  observer: Subscriber<WEvent> = null;

  eventHandler: string = null;
  action: string = null;
  parms: any = {};

  autoSelectSingleResource = false;

  resources: WResource [] = [];

  private _dynamicComponentRefsToDestroy: ComponentRef<any> [] = [];

  private _reDisplayTriggerTimeout: any = null;

  constructor(
    public modalDialogService: ModalDialogService,
    public elementRef: ElementRef,
    public componentFactoryResolver: ComponentFactoryResolver,
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
  ) {
  }

  ngOnInit(): void {

    this.clearModal();

    this.showModalSubscription = this.modalDialogService.showSelectResourceSubject.subscribe(
      (modalPageContent: ResourceSelectContent) => {
        try {
          // console.log('ResourceSelectModalComponent.showModalSubscr()', modalPageContent, this.eventHandler, this.action);

          if (modalPageContent) {
            this.observer = modalPageContent.observer;
            this.eventHandler = modalPageContent.eventHandler;
            this.action = modalPageContent.action;
            this.parms = modalPageContent.parms;
            this.autoSelectSingleResource = modalPageContent.autoSelectSingleResource;

            // make the dialog visible

            this.visible = true;

            this.triggerReDisplay();

          } else {
            this.clearModal();
          }
        } catch (ex) {
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }

    for (const cr of this._dynamicComponentRefsToDestroy) {
      cr.destroy();
    }
  }

  ngAfterViewInit(): void {
    this.triggerReDisplay();
  }

  triggerReDisplay(): void {
    if (this._reDisplayTriggerTimeout) {
      window.clearTimeout(this._reDisplayTriggerTimeout);
    }
    this._reDisplayTriggerTimeout = window.setTimeout(
      () => {
        // populate the component...

        this.loadMatchingResources();

        // re-focus on the cancel/close button...

        const closeButton: HTMLButtonElement = this.elementRef.nativeElement.querySelector('#close') as HTMLButtonElement;
        // console.log(closeButton);
        if (closeButton) {
          window.setTimeout(() => closeButton.focus(), 0);
        }

      },
      0
    );
  }

  clearModal(): void {
    this.visible = false;
    this.eventHandler = null;
    this.action = null;
    this.parms = {};
    this.observer = null;
  }

  // this is any so we can extend this class for other response types...
  closeModal(subscriptionResponse: any): void {
    this.visible = false;
    this.eventHandler = null;
    this.action = null;
    this.parms = {};
    // have to fire this BEFORE we null it out...
    if (this.observer) {
      this.observer.next(subscriptionResponse);
    }
    this.observer = null;

  }

  selectResource(resource: WResource): void {
    this.closeModal(resource);
  }

  onCancel(): void {
    this.closeModal(null);
  }

  loadMatchingResources(): void {
    // when the component loads, it is empty
    // it should not display, and it should not fire an event...
    if (this.eventHandler) {
      if (!this.parms.pageSize) {
        this.parms.pageSize = -1;
      }
      this.eventServerService.fireEvent(this.eventHandler, this.action, this.parms).subscribe(
        (event: WEvent) => {
          // console.log(event);
          if (event) {
            if (event.status === 'OK') {
              if (event.resources) {
                // we have a list of tournaments...
                this.resources = event.resources;
              } else {
                // we define an empty list of tournaments...
                this.resources = [];
              }
              // now we handle auto-selection...

              if (this.resources.length === 0) {
                this.closeModal(null);
              }

              if (this.autoSelectSingleResource && (this.resources.length === 1)) {
                this.closeModal(this.resources[0]);
              }
            }
          }
        }
      );
    }
  }

}
