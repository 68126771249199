import { Component, ElementRef, OnDestroy, OnInit, Renderer2, Input } from '@angular/core';
import { BinaryTextComponent } from '../binary-text/binary-text.component';
import { Subscription, Subject } from 'rxjs';
import { ModalDialogService } from '../../../../services/modal-dialog.service';
import { EventServerService } from '../../../../services/event-server.service';
import { HtmlEditorService } from 'src/app/client-core/services/html-editor.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';

@Component({
  selector: 'wackadoo-field-binary-html',
  templateUrl: './binary-html.component.html',

})
export class BinaryHtmlComponent extends BinaryTextComponent implements OnInit, OnDestroy {

  public wysiwyg: boolean;
  public wysiwygSubscription: Subscription;

  public editorConfig = null;

  public screenSizeChangeSubscription: Subscription;

  @Input() eventSubject: Subject<any> = null;

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    elementRef: ElementRef,
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    utilityLibService: UtilityLibService,
    public htmlEditorService: HtmlEditorService,
  ) {
    super(renderer, modalDialogService, elementRef, eventServerService, userInterfaceService, utilityLibService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.editorConfig = this.htmlEditorService.editorConfig;

    this.wysiwyg = this.htmlEditorService.wysiwygMode.getValue();

    this.wysiwygSubscription = this.htmlEditorService.wysiwygMode.subscribe(
      (wysiwyg: boolean) => {
        this.wysiwyg = wysiwyg;
      }
    );

    this.screenSizeChangeSubscription = this.userInterfaceService.screenSizeChange.subscribe(
      () => {
        this.onEditorPostRender();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.wysiwygSubscription) {
      this.wysiwygSubscription.unsubscribe();
    }
    if (this.screenSizeChangeSubscription) {
      this.screenSizeChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  // this handles both WYSIWYG and TEXT/XHTML mode
  // check HTML template for which event types it is configured for...

  onEditorEvent(e: any): void {
    if (this.eventSubject) {
      this.eventSubject.next(e);
    }
  }

  ///////////////////////////////////
  // TinyMCE Event handling methods
  ///////////////////////////////////

  onEditorPostRender(): void {
    this.htmlEditorService.adjustEditorToFillAvailableHeight(this.elementRef.nativeElement, this.renderer);
  }

  onEditorBlur(e: any): void {
    if (this.f.changed && !this.f.isValidValue(e.editor.getContent())) {
      this.modalDialogService.showAlert('Please enter a valid value.', 'Invalid Data Entry');
    }
  }

  onEditorChange(e: any): void {
    const xhtml = this.htmlEditorService.cleanXHTML(e.editor.getContent());

    // console.log('onEditorChange()', xhtml);

    this.f.text = xhtml;

    // we CANNOT use btoa() because it does NOT support UTF-8 properly...
    // this.f.value = btoa(xhtml);
    this.f.value = this.utilityLibService.btoaForUnicodeString(xhtml);

    this.f.changed = true;

    // Apparently, we cannot show the field has changed by applying the
    // "fieldChanged" CSS class to the element itself, so we mimic it...
    this.renderer.addClass(e.editor.editorContainer, 'fieldChanged');
    // e.editor.editorContainer.style.borderColor = 'blue';
  }

  // This debug code was used to figure out how TinyMCE fired it's Events...
  // onEditorEvent(e: any): void {
  //   console.log('onEditorEvent(' + e.event.type + ')');
  // }

}
