		<div class="card">
			<div class="card-header">
				Terms and Conditions
			</div>
			<div class="card-body">
				<p>
					You use our services and applications at your own risk.
				</p>
				<p>
					We do not guarantee anything, except our best, professional efforts.
				</p>
				<p>
					We reserve the right to terminate your access to our services without notice, 
					without refund, and at our sole discretion, if we deem your use of our services 
					to be - or to be in support of - abusive or illegal activities.
				</p>
				<p class="italic">
					<br/>(And yes, we hate this legal disclaimer stuff as much as you do...)
				</p>
			</div>
		</div>
