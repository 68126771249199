<div class="d-inline-flex align-items-center" *ngIf="showPageControl || showSearchBox || (showSearchBox && (screenSize >= minSortScreenSize))">
    <div class="" *ngIf="showPageControl">
        <wackadoo-page-control 
            [parameters]="parameters" 
            [reloadGridSubject]="reloadGridSubject"
            [disabled]="disabled"
        ></wackadoo-page-control>
    </div>
    <div class="marginLeftHalfEm" *ngIf="showSortControl">
        <wackadoo-sort-control
            [eventHandler]="eventHandler" 
            [parameters]="parameters" 
            [reloadGridSubject]="reloadGridSubject"
            [disabled]="disabled"
        ></wackadoo-sort-control>
    </div>
    <div class="marginLeftHalfEm" *ngIf="showSearchBox && (screenSize >= minSortScreenSize)">
        <wackadoo-search-box
            [eventHandler]="eventHandler" 
            [parameters]="parameters" 
            [reloadGridSubject]="reloadGridSubject"
            [disabled]="disabled"
        ></wackadoo-search-box>
    </div>
</div>
