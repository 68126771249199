<div class="card" *ngIf="chart">
    <div class="card-header"  *ngIf="chart.title || chart.subTitle">
        <div *ngIf="chart.title">
            {{ chart.title }}
        </div>
        <div class="italic smallish unbold" *ngIf="chart.subTitle">
            {{ chart.subTitle }}
        </div>
    </div>
    <div class="card-body">
        <!--
            Chart.js uses its parent container to update the canvas render and display sizes.
            However, this method requires the container to be relatively positioned and dedicated
            to the chart canvas only.

            Note that we also need this in the chart model: maintainAspectRatio = false;

            Note that passing in maxHeightTimoutDelay < 0 to wackadooMaximizeContentHeight 
            means that it does NOT wait until the screen settles before calculating the height.
            (If we wait, even 0ms, then the chart-block canvas draws BEFORE the height is calculated.)
        -->
        <div *ngIf="chart.labels && chart.datasets ; else defaultTextContent" 

            wackadooStyle
            [wStyle]="wStyle"
            [wValue]="wValue"

            wackadooMaximizeContentHeight
            [maxHeightThingBelowSelector]="maxHeightThingBelowSelector"
            [maxHeightFudgeFactor]="maxHeightFudgeFactor"
            [maxHeightTimoutDelay]="-1"

        >
            <canvas baseChart 
                    [chartType]="chart.type" 
                    [datasets]="chart.datasets" 
                    [labels]="chart.labels" 
                    [colors]="chart.colors" 
                    [legend]="chart.legend" 
                    [options]="chart.options"
                    [plugins]="chart.plugins"
            ></canvas>
        </div>
        <ng-template #defaultTextContent>
            {{ defaultTextContent }}
        </ng-template>
    </div>
</div>
