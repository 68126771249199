import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { BusinessRuleService } from 'src/app/client-core/services/business-rule.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { Subject, Subscription } from 'rxjs';
import { UtilityLibService } from 'src/app/client-core/services/utility-lib.service';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';
import { WField, WString, WDate } from 'src/app/client-core/data/field.model';
import { WResource } from 'src/app/client-core/data/resource.model';
import { WEvent } from 'src/app/client-core/data/event.model';

@Component({
  selector: 'wackadoo-form-filler',
  templateUrl: './form-filler.component.html',
})
export class FormFillerComponent extends ResourceComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'FormFillerComponent';

  catchAllFieldName = null;

  debug = false;

  formFillerEH = null;
  formFillerFieldEH = null;

  formFillerID: WField = null;

  onFormFillerChange: Subject<WField> = null;
  onFormFillerChangeSubscription: Subscription = null;

  definedFields: WResource [] = [];

  content: WString = new WString('content', '');

  extractedValueCount = 0;

  onContentChange: Subject<WField> = null;
  onContentChangeSubscription: Subscription = null;

  // this is a working, mungible copy of the text cut-n-paste into the textarea...
  lines: string [] = [];

  // Note: we put the catch-all in as the first field map so that it gets appended with unknown fields AFTER the "real" value...

  constructor(
    userInterfaceService: UserInterfaceService,
    public businessRuleService: BusinessRuleService,
    public modalDialogService: ModalDialogService,
    public utilityLibService: UtilityLibService,
    eventServerService: EventServerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {

    super.ngOnInit();

    try {

      // set up the catchAll field...

      if (this.resource._catchAllFieldName) {
        this.catchAllFieldName = this.resource._catchAllFieldName;
      } else {
        console.log(this.resource);
        this.modalDialogService.showAlert('resource._catchAllFieldName is un-defined!', 'Coding Error');
      }

      // set up the formFillerID field...

      this.formFillerEH = this.eventServerService.getEventHandlerForResourceType('FormFiller');
      this.formFillerFieldEH = this.eventServerService.getEventHandlerForResourceType('FormFillerField');

      const temp = this.eventServerService.newResource(this.formFillerFieldEH);
      this.formFillerID = temp.ffID;

      // do a "list" here to force the loading of the formFiller defaults from the server...
      this.eventServerService.fireEvent(this.formFillerEH, 'list', {}).subscribe(
        () => {
          // load in the form filler field maps
          this.loadDefinedFields();
        }
      );

      this.onFormFillerChange = new Subject<WField>();
      this.onFormFillerChangeSubscription = this.onFormFillerChange.subscribe(
        (f: WField) => {
          if (f) {
            this.loadDefinedFields();
          }
        }
      );

      // set up the content field...

      this.content = new WString('content', '');
      this.content.title = 'paste text content here...';
      this.content.length = 2048;

      this.extractedValueCount = 0;

      this.onContentChange = new Subject<WField>();
      this.onContentChangeSubscription = this.onContentChange.subscribe(
        (field: WField) => {
          this.extractedValueCount = this.parsePayload(field.value);
        }
      );

    } catch (ex) {
      const msg = 'InBoxFormFillerComponent.ngOnInit()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

  ngOnDestroy(): void {
    if (this.onFormFillerChangeSubscription) {
      this.onFormFillerChangeSubscription.unsubscribe();
    }
    if (this.onContentChangeSubscription) {
      this.onContentChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  loadDefinedFields(): void {

    if (this.debug) { console.log('loadDefinedFields()', this.formFillerID); }

    try {

      this.definedFields = [];

      if (this.formFillerID && this.formFillerID.isPopulated) {

        const parms = this.formFillerID.asParm;
        parms.pageSize = -1;

        this.eventServerService.fireEvent(this.formFillerFieldEH, 'list', parms).subscribe(
          (event: WEvent) => {
            this.modalDialogService.showPleaseWait(false);
            try {
              if (event.status !== 'OK') {
                throw new Error('Unable to list fields for form filler: ' + event.message);
              }

              this.definedFields = event.resources;

              if (this.debug) { console.log('loadDefinedFields()', this.definedFields); }

              this.extractedValueCount = this.parsePayload(this.content.value);

            } catch (ex) {
              const msg = 'loadDefinedFields()\n';
              this.userInterfaceService.alertUserToException(ex, msg);
            }
          }
        );
      }

    } catch (ex) {
      const msg = 'loadDefinedFields()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }


  }

  formatDate(d: Date): string {
    return d.toLocaleString();
  }

  formatNumber(n: number): string {
    return this.utilityLibService.formatNumberWithCommas(n);
  }

  parsePayload(payload: string): number {

    // we don't do clear ALL the fields here. (This allows the calling component to control things when it wants to...)
    // this.resource.clearAllFields();

    // but we DO clear out the "catchAll" field, if we have one...
    if (this.catchAllFieldName) {
      if (this.resource[this.catchAllFieldName]) {
        const catchAllField = this.definedFields.find((r) => r.fffName === this.catchAllFieldName);
        if (catchAllField) {
          this.resource[this.catchAllFieldName].value = '';
          this.resource[this.catchAllFieldName].changed = true;
        }
      }
    }

    let count = 0;

    if (payload) {
      this.lines = [];

      // remove all LF chars and split into lines by NL char
      payload = payload.replace(/\r/g, '');
      this.lines = payload.split('\n');

      if (this.debug) { console.log('parsePayload()', this.lines.length + ' payload lines', this.formFillerID.displayValue, this.definedFields); }

      if (this.definedFields.length > 0) {
        for (const fieldMap of this.definedFields) {
          if (fieldMap) {
            count += this.extractValue(fieldMap.asParms);
          }
        }

        // now append all un-used, delimited lines to the catch-all field...
        this.appendUnUsedLines();

      }
    }

    if (this.debug) { console.log('parsePayload() - exiting: ', this.resource.asParms); }

    return count;
  }

  extractValue(fieldMap: any): number {
    if (this.debug) { console.log('extractValue()', fieldMap); }

    let rawValue = null;

    let isMultiLine = false;
    let isNumber = false;
    let isCurrency = false;
    let isDate = false;

    const isString = !fieldMap.fffType || (fieldMap.fffType === 'String');

    if (!isString) {
      isMultiLine = (fieldMap.fffType === 'MultiLine');
      isNumber = (fieldMap.fffType === 'Number');
      isCurrency = (fieldMap.fffType === 'Currency');
      isDate = (fieldMap.fffType === 'Date');
    }

    for (let i = 0; i < this.lines.length; i++) {
      let line = this.lines[i];

      if (line && (line.length > 0) && line.startsWith(fieldMap.fffPrefix + fieldMap.fffPrefixDelimiter)) {
        if (this.debug) { console.log('\tline: ', line); }
        rawValue = line.substr(fieldMap.fffPrefix.length + fieldMap.fffPrefixDelimiter.length).trim();
        if (!isString || (fieldMap.fffPartial === 'all')) {
          this.lines[i] = ''; // empty out the line so we don't use it again...
        }

        // these are values we ignore
        if (['none', 'na', 'unknown', 'tbd', '?', '??', '???'].includes(rawValue.toLowerCase())) {

          rawValue = null;

        } else if (isMultiLine) {

          const maxRawValueLength = Number(this.resource[fieldMap.fffName].length);

          let keepGoing = false;
          do {
            line = this.lines[++i];

            keepGoing = (i < this.lines.length)
                        && (line !== null)
                        // HACK ALERT! This next line being commented out makes us assume that the multi-line field is the LAST field in the input!
                        // && (line.indexOf(fieldMap.fffPrefixDelimiter) === -1)
                        && (rawValue.length + line.trim().length <= maxRawValueLength);

            const appendThisLine = keepGoing && (line.trim().length > 0);

            if (this.debug) { console.log('\tkeepGoing? ' + (keepGoing ? 'true' : 'false') + ', appendThisLine? ' + (appendThisLine ? 'true' : 'false')); }

            if (appendThisLine) {
              if (this.debug) { console.log('\tline: ', line.trim()); }
              rawValue += ('\n' + line.trim());
              this.lines[i] = ''; // empty out the line so we don't use it again...
            }

          } while (keepGoing);
        }
        break;
      }
    }

    if (rawValue) {
      let value = rawValue.trim();

      if (isString) {

        if (fieldMap.fffPartial) {
          const firstOffset = rawValue.indexOf(fieldMap.fffPartialValue);
          const lastOffset = rawValue.lastIndexOf(fieldMap.fffPartialValue);

          switch (fieldMap.fffPartial) {
            case 'regex' :
              const matches = rawValue.match(fieldMap.fffPartialValue);
              value = (matches ? matches[1] : 'no match');
              break;
            case 'before first' :
              if (firstOffset > -1) {
                value = rawValue.substring(0, firstOffset);
              }
              break;
            case 'after first' :
              if (firstOffset > -1) {
                value = rawValue.substring(firstOffset + 1);
              }
              break;
            case 'before last' :
              if (lastOffset > -1) {
                value = rawValue.substring(0, lastOffset);
              }
              break;
            case 'after last' :
              if (lastOffset > -1) {
                value = rawValue.substring(lastOffset + 1);
              }
              break;
            case 'all' :
            default :
              // NOP: since, per above, value = rawValue
              break;
          }

          if (this.debug) { console.log('\t"' + rawValue + '"', fieldMap.fffPartial, fieldMap.fffPartialValue, firstOffset, lastOffset, '"' + value + '"'); }
        }

        if (fieldMap.fffLabel) {
          value = fieldMap.fffLabel + fieldMap.fffPrefixDelimiter + ' ' + value;
        }

      } else if (isCurrency) {
        value = value.replace(/\$/g, '');
        value = value.replace(/,/g, '');
        value = Number(value);
      } else if (isNumber) {
        value = value.replace(/,/g, '');
        value = Number(value);
      } else if (isDate) {
        value = new Date(value);
      }

      if (this.debug) { console.log('\tvalue: ', value, typeof value); }

      let fieldName = fieldMap.fffName;

      // we check for the fieldName from the map being part of the resource defn...
      // if not, then we switch to the catch-all field, and use it (including it's proper multiValueSeparator...)

      if (!this.resource[fieldName]) {
        if (this.catchAllFieldName) {
          if (this.resource[this.catchAllFieldName]) {
            const catchAllField = this.definedFields.find((r) => r.fffName === this.catchAllFieldName);
            if (catchAllField) {
              const catchAllFieldMap = catchAllField.asParms;
              value = fieldMap.fffName + fieldMap.fffPrefixDelimiter + ' ' + rawValue;
              fieldName = this.catchAllFieldName;
              isMultiLine = (catchAllFieldMap.fffType === 'MultiLine');
            }
          }
        }
      }

      // at this point, we ALWAYS should have a valid resource field.
      // However, we include conditional processing of unknown fields
      // just in case the catch-all field is invalid or its map is missing.

      if (this.resource[fieldName]) {
        if (this.resource[fieldName].type === 'String') {
          this.resource[fieldName].value = (this.resource[fieldName].value ? this.resource[fieldName].value + (isMultiLine ? '\n/ ' : ' / ') : '') + value;
        } else if (isDate) {
          this.resource[fieldName].value = value.toJSON();
        } else {
          this.resource[fieldName].value = value;
        }

      } else {

        let fld: WField = null;

        if (isDate) {
          fld = new WDate(fieldName, value.toJSON());
        } else if (isNumber) {
          fld = new WField(fieldName, value, ((value % 1 === 0.0) && !isCurrency ? 'Integer' : 'Float'));
        } else {
          fld = new WString(fieldName, value);
        }

        if (this.debug) { console.log('\tnew field, type: ', fld.type); }
        this.resource[fieldName] = fld;
      }

      this.resource[fieldName].changed = true;

      if (this.debug) { console.log(fieldName + ': ', this.resource[fieldName].value); }

      return 1;

    }

    return 0;
  }

  appendUnUsedLines(): void {
    if (this.debug) { console.log('appendUnUsedLines()', this.lines); }

    if (this.catchAllFieldName) {

      const catchAllField = this.definedFields.find((r) => r.fffName.value === this.catchAllFieldName);

      if (catchAllField) {

        if (this.debug) { console.log('appendUnUsedLines()', catchAllField); }

        const catchAllFieldMap = catchAllField.asParms;

        if (this.debug) { console.log('appendUnUsedLines()', catchAllFieldMap, this.resource[this.catchAllFieldName]); }

        if (catchAllFieldMap && this.resource[this.catchAllFieldName]) {

          for (const line of this.lines) {

            if (line && (line.length > 0) && (line.indexOf(catchAllFieldMap.fffPrefixDelimiter) > -1)) {

              if (this.debug) { console.log('\tline: ', line); }

              // load the catch-all field...

              const oldValue = this.resource[this.catchAllFieldName].value;

              this.resource[this.catchAllFieldName].value = (oldValue ? oldValue + (catchAllFieldMap.fffType === 'MultiLine' ? '\n/ ' : ' / ') : '') + line;
              this.resource[this.catchAllFieldName].changed = true;

            }
          }

          if (this.debug) { console.log(
                this.resource[this.catchAllFieldName].value.length,
                typeof this.resource[this.catchAllFieldName].value.length,
                this.resource[this.catchAllFieldName].length,
                typeof this.resource[this.catchAllFieldName].length
             ); }

          if (this.resource[this.catchAllFieldName].value.length > this.resource[this.catchAllFieldName].length) {

            let indexOfLastGoodNewLine = this.resource[this.catchAllFieldName].length;
            while (this.resource[this.catchAllFieldName].value[indexOfLastGoodNewLine] !== '\n') {
              indexOfLastGoodNewLine--;
            }

            const extraContent = this.resource[this.catchAllFieldName].value.substr(indexOfLastGoodNewLine);

            this.resource[this.catchAllFieldName].value = this.resource[this.catchAllFieldName].value.substr(0, indexOfLastGoodNewLine);

            const msg = 'The ' + this.catchAllFieldName + ' has been truncated. The following portion of the text was omitted:\n\n<i>' + extraContent + '</i>';
            this.modalDialogService.showAlert(msg, 'Please note...');
          }

          if (this.debug) { console.log(this.catchAllFieldName + ': ', this.resource[this.catchAllFieldName].value); }
        }
      }
    }
  }

}
