import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[wackadooMenuPlaceholder]'
})
export class MenuPlaceholderDirective {

  constructor(
    public elementRef: ElementRef,
  ) { }

}
