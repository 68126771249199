import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class Globals {

  // ROOT and THIS application names can be initialized in PROJECT app-routing module... (But we default to AccountManager...)
  static rootApplication = 'AccountManager';
  static thisApplication = 'AccountManager';

  static notAccountManager = 'NotAccountManager';

  // domain and organizational stuff......

  static domain: string = window.location.hostname.substring(window.location.hostname.indexOf('.') + 1);
  static domainURL = 'http://www.' + Globals.domain;
  static domainLogoURL: string = './assets/pics/' + Globals.domain + '.png';
  static organizationName = 'wackadoo information systems llc';
  static organizationURL = 'http://www.wackadoo.info';
  static copyrightYear = (new Date()).getFullYear();

  static MAGIC_NULL_FKEY_VALUE = -1;
  static MAGIC_NULL_STRING_VALUE = 'NULL';
  // static TIMEZONE = jstz.determine().name();
  static TIMEZONE = moment.tz.guess();

  ////////////////////////////////////////////////////////////////
  // cookie and token names...
  ////////////////////////////////////////////////////////////////

  static sessionTokenName = 'sessionToken';
  static readableUserCookieName = 'EventServerCurrentUserInfo';
  static startupCookieName = 'EventServerStartupToken';
  static pageStateCookieName = 'EventServerPageStates';

  ////////////////////////////////////////////////////////////////
  // browser detection methods...
  ////////////////////////////////////////////////////////////////

  // This is part of a longer list of browser detection mechanisms stolen off web at
  // http://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
  // (Updated as of January 2019, but modified here to use hasOwnProperty() to work in TypeScript...)

  // Internet Explorer 6-11
  static isIE = /*@cc_on!@*/false || !!(document.hasOwnProperty('documentMode'));
  // Edge 20+
  static isEdge = !Globals.isIE && !!(window.hasOwnProperty('StyleMedia'));

  ////////////////////////////////////////////////////////////////
  // The Big Opt In
  ////////////////////////////////////////////////////////////////

  static theBigOptIn = 'By purchasing or subscribing to our services, you are agreeing to our Terms & Conditions, and all of our policies - especially our Billing, Refund, and E-Mail Usage Policies.';

  ////////////////////////////////////////////////////////////////
  // Various IMAGE related values
  ////////////////////////////////////////////////////////////////

  static minBannerImageWidth = 1920; // px
  static maxLogoImageRatio = 2.4; // i.e. 2.4:1

  ////////////////////////////////////////////////////////////////
  // misc values
  ////////////////////////////////////////////////////////////////

  static wackadooGreen = '#085916';

}

// console.log('Globals.TIMEZONE: ' + Globals.TIMEZONE);
