import { Subscriber } from 'rxjs';

export class PromptContent {

  observer: Subscriber<boolean|string>;
  message = '';
  title = '';
  placeholder: string;
  defaultValue: string;

  constructor(observer: Subscriber<boolean|string>, message: string, title: string, placeholder?: string, defaultValue?: string) {
    this.observer = observer;
    this.message = message;
    this.title = (title ? title : '');
    this.placeholder = (placeholder ? placeholder : '');
    this.defaultValue = (defaultValue ? defaultValue : '');
  }
}

