<div class="paddingTopHalfEm paddingBottomHalfEm border-bottom" [ngClass]="{ 'border-top' : (row === 0) }">
  <div class="row">
    <div class="col-sm-1 center middle italic">
      <div class="d-flex flex-row">
        <div class="marginRightHalfEm" *ngIf="(screenType === 'phone')">
          Item Sequence #:
        </div>
        <div class="width-3-em">
          <wackadoo-field [f]="resource.sequence" mode="write" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </div>
      </div>
    </div>
    <div class="col-sm-2">
      <span *ngIf="(screenType === 'phone')">Item Name: </span>
      <wackadoo-field [f]="resource.itemName" mode="write" [onChange]="priceListItemChangedSubject"></wackadoo-field>
    </div>
    <div class="col-sm-1 nowrap">
      <div class="d-flex flex-row">
        <div class="marginRightHalfEm" *ngIf="(screenType === 'phone')">
          Item Price:
        </div>
        <div class="width-7-em">
          <wackadoo-field [f]="resource.itemPrice" mode="write" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </div>
      </div>
    </div>
    <div class="col-sm-2">
      <span *ngIf="(screenType === 'phone')">Resource Type: </span>
      <wackadoo-field [f]="resource.resourceType" mode="write" [onChange]="priceListItemChangedSubject"></wackadoo-field>
    </div>
    <div class="col-sm-1" title="If checked, the item quantity will be adjustable in the ShoppingCart.">
      <span *ngIf="(screenType === 'phone')">Quantity Adjustable: </span>
      <wackadoo-field [f]="resource.quantityAdjustable" [mode]="(resource.resourceType.value === 'Package' ? 'read' : mode)" [onChange]="priceListItemChangedSubject"></wackadoo-field>
    </div>
    <div class="col-sm-2">
      <div class="d-flex flex-row" title="If checked, there will be a limited number of these items available for people to purchase.">
        Fixed Inventory: <wackadoo-field [f]="resource.fixedInventory" mode="write" [onChange]="priceListItemChangedSubject"></wackadoo-field>
      </div>
      <div class="smallish italic d-flex flex-row" *ngIf="resource.fixedInventory.value">
        <div class="" *ngIf="resource.fixedInventory.value"  title="The maximum number of items available as inventory.">
          Total:
        </div>
        <div class="marginLeftHalfEm max-width-4-em" *ngIf="resource.fixedInventory.value" >
          <wackadoo-field [f]="resource.totalInventory" [mode]="(resource.fixedInventory.value ? 'write' : 'read')" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </div>
        <div class="marginLeftWholeEm"  title="The number of inventory items that have been 'consumed' to date." >
          Sold:
        </div>
        <div class="marginLeftHalfEm max-width-4-em">
          <wackadoo-field [f]="resource.reservedInventory" [mode]="(resource.fixedInventory.value ? 'write' : 'read')" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </div>
        <div class="marginLeftHalfEm" *ngIf="!resource.totalInventory.isNull && !resource.reservedInventory.isNull">
          ({{ resource.totalInventory.value - resource.reservedInventory.value }} left)
        </div>
      </div>
    </div>
    <div class="col-sm-1">
      <button type="button" data-abbrev="" class="btn btn-secondary smallish paddingTop0 paddingBottom0 paddingLeft3 paddingRight3" (click)="deletePriceListItem()"><span class="fas fa-times"></span> Delete</button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-1">
    </div>
    <div class="col-sm-3">
      <div>
        Description:
      </div>
      <ul>
        <li>
          <wackadoo-field [f]="resource.description1" mode="write" [size]="45" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </li>
        <li *ngIf="resource.description1.isPopulated">
          <wackadoo-field [f]="resource.description2" mode="write" [size]="45" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </li>
        <li *ngIf="resource.description2.isPopulated">
          <wackadoo-field [f]="resource.description3" mode="write" [size]="45" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </li>
        <li *ngIf="resource.description3.isPopulated">
          <wackadoo-field [f]="resource.description4" mode="write" [size]="45" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </li>
        <li *ngIf="resource.description4.isPopulated">
          <wackadoo-field [f]="resource.description5" mode="write" [size]="45" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </li>
        <li *ngIf="resource.description5.isPopulated">
          <wackadoo-field [f]="resource.description6" mode="write" [size]="45" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </li>
        <li *ngIf="resource.description6.isPopulated">
          <wackadoo-field [f]="resource.description7" mode="write" [size]="45" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </li>
        <li *ngIf="resource.description7.isPopulated">
          <wackadoo-field [f]="resource.description8" mode="write" [size]="45" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </li>
        <li *ngIf="resource.description8.isPopulated">
          <wackadoo-field [f]="resource.description9" mode="write" [size]="45" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </li>
        <li *ngIf="resource.description9.isPopulated">
          <wackadoo-field [f]="resource.description10" mode="write" [size]="45" [onChange]="priceListItemChangedSubject"></wackadoo-field>
        </li>
      </ul>
    </div>
    <div class="col-sm-2">
      <div *ngIf="resource.resourceType.value === 'Package'">
        <div class="d-flex justify-content-between align-items-center">
          <div class="">
             PriceList Items In Package
          </div>
          <div class="marginLeftHalfEm">
            <button 
              type="button" title="New" data-abbrev="" (click)="addPackageItem()" 
              class="btn btn-secondary actionButton small paddingTopQuarterEm paddingBottomQuarterEm paddingLeftHalfEm paddingRightHalfEm" 
            ><span class="fas fa-plus"></span></button>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center" *ngFor="let pkgLink of packageLinks; let idx = index;">
          <div class="d-flex flex-row align-items-center">
            <div class="max-width-4-em">
              <wackadoo-field [f]="pkgLink.priceListItemChildCount" mode="read"></wackadoo-field>
            </div>
            <div class="marginLeftHalfEm">
              <wackadoo-field [f]="pkgLink.priceListItemChildID" mode="read"></wackadoo-field>
            </div>
          </div>
          <div class="marginLeftHalfEm" *ngIf="pkgLink.keyField.isPopulated">
            <button 
              type="button" title="delete" data-abbrev="del" (click)="deletePackageItem(pkgLink)" 
              class="btn btn-secondary actionButton small paddingTopQuarterEm paddingBottomQuarterEm paddingLeftHalfEm paddingRightHalfEm" 
            ><span class="fas fa-times"></span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
