import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StatusService } from '../../../services/status.service';
import { ModalDialogService } from '../../../services/modal-dialog.service';

@Component({
  selector: 'wackadoo-please-wait',
  templateUrl: './please-wait.component.html',

})
export class PleaseWaitComponent implements OnInit, OnDestroy {

  visible = false;

  showModalSubscription: Subscription;

  message = '';

  requestStatus = '';
  applicationStatus = '';
  applicationProgress = '';

  requestStatusSubscription: Subscription;
  applicationStatusSubscription: Subscription;
  applicationProgressSubscription: Subscription;

  constructor(
    private _modalDialogService: ModalDialogService,
    private _statusService: StatusService,
  ) { }

  ngOnInit(): void {
    this.showModalSubscription = this._modalDialogService.showPleaseWaitSubject.subscribe(
      (showModal: boolean|string) => {
        // console.log('PleaseWait.showModalSubscription() - showModal: ' + showModal);
        // console.log(new Error().stack);
        const oldVisible = this.visible;
        const oldMessage = this.message;

        this.visible = (typeof showModal === 'boolean' ? showModal : true);

        if (typeof showModal === 'string') {
          this.message = showModal.replace(/\\n/g, '\n').replace(/\n/g, '<br/>');
        } else if (this.visible && oldVisible && oldMessage) {
          this.message = oldMessage;
        } else {
          this.message = '';
        }
      }
    );

    this.requestStatusSubscription = this._statusService.requestStatus.subscribe(
      (s: string) => {
        this.requestStatus = (s ? s : '');
      }
    );

    this.applicationStatusSubscription = this._statusService.applicationStatus.subscribe(
      (s: string) => {
        this.applicationStatus = (s ? s : '');
      }
    );

    this.applicationProgressSubscription = this._statusService.applicationProgress.subscribe(
      (s: string) => {
        this.applicationProgress = (s ? s : '');
      }
    );

  }

  ngOnDestroy(): void {
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }
    if (this.requestStatusSubscription) {
      this.requestStatusSubscription.unsubscribe();
    }
    if (this.applicationStatusSubscription) {
      this.applicationStatusSubscription.unsubscribe();
    }
    if (this.applicationProgressSubscription) {
      this.applicationProgressSubscription.unsubscribe();
    }
  }

  get noTextContent(): boolean {
    return (this.message.trim() + this.applicationStatus.trim() + this.applicationProgress.trim()).length === 0;
  }

}
