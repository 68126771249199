import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[wackadooAutoFocus]'
})
export class AutoFocusDirective implements OnInit {

  constructor(
    public elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.elementRef.nativeElement.focus();
  }
}
