import { Component, OnInit, OnDestroy } from '@angular/core';
import { WForeignKey, WField, WDate, WString } from '../../data/field.model';
import { WEvent } from '../../data/event.model';
import { PageComponent } from '../page/page.component';
import { Subject, Subscription } from 'rxjs';
import { ModalDialogService } from '../../services/modal-dialog.service';
import { UserAuthService } from '../../services/user-auth.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { EventServerService } from '../../services/event-server.service';
import { WResource } from '../../data/resource.model';
import { UtilityLibService } from '../../services/utility-lib.service';
import { BusinessRuleService } from '../../services/business-rule.service';
import { Globals } from '../../services/global.service';

@Component({
  selector: 'wackadoo-form-filler-templates',
  templateUrl: './form-filler-templates.component.html',
})
export class FormFillerTemplatesComponent extends PageComponent implements OnInit, OnDestroy {

  debug = false;

  formFillerEH = null;
  formFillerFieldEH = null;

  onFormFillerChange: Subject<WField> = null;
  onFormFillerChangeSubscription: Subscription = null;

  onFormFillerDefnChange: Subject<WField> = null;
  onFormFillerDefnChangeSubscription: Subscription = null;
  formFillerDefnInputTimeout: any = null;

  ffID: WForeignKey = null;

  selectedFormFiller: WResource = null;

  availableFieldNames: string [] = [];
  definedFields: WResource [] = [];

  selectedFormFillerFields: WResource [] = [];

  selectedFieldName: string = null;

  content = '';
  truncatedContent = '';

  refreshFieldList: Subject<void> = null;
  refreshFieldListSubscription: Subscription = null;

  exampleResource: WResource = null;
  catchAllFieldName: string = null;

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    public eventServerService: EventServerService,
    public utilityLibService: UtilityLibService,
    public businessRuleService: BusinessRuleService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.formFillerEH = this.eventServerService.getEventHandlerForResourceType('FormFiller');
    this.formFillerFieldEH = this.eventServerService.getEventHandlerForResourceType('FormFillerField');

    this.onFormFillerChange = new Subject<WField>();

    this.onFormFillerChangeSubscription = this.onFormFillerChange.subscribe(
      (f: WField) => {
        if (f) {
          // remember, fkeys return the string value for the field...
          this.loadFormFiller(Number(f.value));
        }
      }
    );

    this.onFormFillerDefnChange = new Subject<WField>();

    this.onFormFillerDefnChangeSubscription = this.onFormFillerDefnChange.subscribe(
      (f: WField) => {
        if (f) {
          // console.log('onFormFillerDefnChangeSubscription()', f);

          // saving the whole resource on every field change is WAY too chatty w/the server, so we use a timeout...

          if (this.formFillerDefnInputTimeout) {
            clearTimeout(this.formFillerDefnInputTimeout);
            this.formFillerDefnInputTimeout = undefined;
          }
          this.formFillerDefnInputTimeout = window.setTimeout(
            () => {
              // console.log('saving resource');

              this.eventServerService.saveResource(this.selectedFormFiller).subscribe(
                (result: WEvent) => {
                  // console.log('saved resource', result);

                  try {
                    if (result) {
                      if (result.status !== 'OK') {
                        throw new Error('Problem saving resource: ' + result.message);
                      }
                      this.selectedFormFiller.markAllFieldsAsUnChanged();
                    }

                  } catch (ex) {
                    console.log(result);
                    const msg = 'auto-save resource()\n';
                    this.userInterfaceService.alertUserToException(ex, msg);
                  }
                }
              );
            }
            , 333
          );
        }
      }
    );

    this.refreshFieldList = new Subject<void>();

    this.refreshFieldListSubscription = this.refreshFieldList.subscribe(
      () => {
        this.loadDefinedFields();
        this.loadSelectedFormFillerFields(this.selectedFieldName);
      }
    );

    // populate the selection field for FormFillers...

    this.ffID = this.eventServerService.newResource(this.formFillerFieldEH).ffID;
    this.ffID.value = this.userInterfaceService.getPageState(this.formFillerEH, 'ffID');

    // console.log('ngOnInit()', this.ffID);

    this.loadFormFiller(this.ffID.value);

  }

  ngOnDestroy(): void {
    if (this.onFormFillerChangeSubscription) {
      this.onFormFillerChangeSubscription.unsubscribe();
    }
    if (this.onFormFillerDefnChangeSubscription) {
      this.onFormFillerDefnChangeSubscription.unsubscribe();
    }
    if (this.refreshFieldListSubscription) {
      this.refreshFieldListSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  fieldIsDefined(fieldName: string): boolean {
    let flag = false;
    flag = this.definedFields.findIndex((r) => r.fffName.value === fieldName) > -1;
    return flag;
  }

  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  // This section of the code loads the FormFiller (& Field) definitions
  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////

  loadFormFiller(ffID: number): void {

    // clear any selected items

    this.selectedFieldName = null;
    this.selectedFormFillerFields = [];

    // and now go load up everything for the FormFiller

    const parms: any = {};
    parms.pageSize = -1;
    if (ffID) {
      parms.ffID = ffID;
    }

    // console.log('loadFormFiller()', ffID, typeof ffID, parms);

    if (ffID === Globals.MAGIC_NULL_FKEY_VALUE) {
      this.availableFieldNames = [];
      this.definedFields = [];
      return;
    }

    this.eventServerService.fireEvent(this.formFillerEH, 'list', parms).subscribe(
      (event: WEvent) => {
        try {
          if (event) {
            if (event.status === 'OK') {
              // console.log('loadFormFiller() - ' + this.formFillerEH + '.list count: ' + (event.resources ? event.resources.length : 'null'));
              if (event.resources && (event.resources.length > 0)) {

                this.selectedFormFiller = event.resources[0];

                // this forces a reload of the fkey field display values...
                this.ffID = this.eventServerService.newResource(this.formFillerFieldEH).ffID;
                this.ffID.value = this.selectedFormFiller.ffID.value;

                this.userInterfaceService.setPageState(this.formFillerEH, 'ffID', this.ffID.value);

                // console.log('loadFormFiller()', 'selectedFormFiller', this.selectedFormFiller, 'ffID', this.ffID);

                this.loadFieldNames();
                this.loadDefinedFields();
              }
            } else {
              throw new Error(event.message);
            }
          }
        } catch (ex) {
          console.log(event);
          this.userInterfaceService.alertUserToException(ex, 'Error loading FormFillers!', true);
        }
      }
    );
  }

  loadFieldNames(): void {
    // NOTE: ffResourceType.default can be defined in resource defn file: etc/types/FormFiller.xml
    const temp = this.eventServerService.newResource(this.formFillerEH);
    const defaultResourceType = temp.ffResourceType.default;

    let resourceType = this.selectedFormFiller.ffResourceType.value;

    if (!resourceType) {
      resourceType = defaultResourceType;
    }

    // Load the field names for the resource type we are trying to fill...

    this.availableFieldNames = [];

    this.exampleResource = null;

    if (resourceType) {
      this.exampleResource = this.eventServerService.newResource(this.eventServerService.getEventHandlerForResourceType(resourceType));

      if (this.exampleResource) {
        for (const f of this.exampleResource.fields) {
          if (!f.readOnly) {
            // console.log('loadFieldNames()', f.name);
            this.availableFieldNames.push(f.name);
          }
        }
      }

      // console.log('loadFieldNames()', temp, resourceType, resource, this.availableFieldNames);
    } else {
      this.modalDialogService.showAlert('No resourceType found for ' + this.selectedFormFiller.ffName.value, 'WARNING');
    }

  }

  loadDefinedFields(): void {

    // console.log('loadDefinedFields()');

    const parms = this.selectedFormFiller.ffID.asParm;
    parms.pageSize = -1;
    parms.sortBy = 'fffName';
    parms.sortDirection = 1;

    this.eventServerService.fireEvent(this.formFillerFieldEH, 'list', parms).subscribe(
      (event: WEvent) => {
        this.modalDialogService.showPleaseWait(false);
        try {
          if (event.status !== 'OK') {
            throw new Error('Unable to list fields for form filler: ' + event.message);
          }

          this.definedFields = event.resources;
          if (!this.definedFields) {
            this.definedFields = [];
          }

        } catch (ex) {
          const msg = 'loadDefinedFields()\n';
          this.userInterfaceService.alertUserToException(ex, msg);
        }
      }
    );

  }

  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  // This section of the code handles FormFiller CRUD
  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////

  add(): void {
    try {
      // console.log('add() - Got here!');

      const title = 'Form Filler';
      const placeholder = 'form filler name';
      const message = 'Please enter the name of the new form filler:';

      this.modalDialogService.showPrompt(message,  title, placeholder).subscribe(
        (inputValue: string | boolean) => {
          try {
            if (inputValue && (inputValue !== '')) {
              const parms: any = {};
              parms.ffName = inputValue;

              // console.log('add callback() - Got there! parms: ' + JSON.stringify(parms));

              this.eventServerService.fireEvent(this.formFillerEH, 'add', parms).subscribe(
                (event: WEvent) => {

                  // console.log('add callback()', event);

                  if (event.status !== 'OK') {
                    throw new Error(event.message);
                  }

                  this.userInterfaceService.setPageState(this.formFillerEH, 'ffID', event.parameters.ffID);

                  // this forces a reload of the fkey field display values...
                  this.ffID = this.eventServerService.newResource(this.formFillerFieldEH).ffID;

                  this.userInterfaceService.reloadCurrentPage();
                }
              );
            }
          } catch (ex) {
            const msg2 = 'add callback()\n';
            this.userInterfaceService.alertUserToException(ex, msg2);
          }
        }
      );

    } catch (ex) {
      const msg = 'add()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  rename(): void {
    try {
      const title = 'Form Filler';
      const placeholder = 'form filler name';
      const defaultValue = this.selectedFormFiller.ffID.displayValue;
      const message = 'Please enter a NEW name for the "' + defaultValue + '" form filler:';

      this.modalDialogService.showPrompt(message, title, placeholder, defaultValue).subscribe(
        (inputValue) => {
          try {
            if (inputValue && (inputValue !== '') && (inputValue !== defaultValue)) {
              const parms: any = {};
              parms.ffID = this.selectedFormFiller.ffID.value;
              parms.ffName = inputValue;

              // console.log('rename - about to modify w/parms', parms);

              this.eventServerService.fireEvent(this.formFillerEH, 'modify', parms).subscribe(
                (event: WEvent) => {
                  if (event.status !== 'OK') {
                    throw new Error(event.message);
                  }
                  // this forces a reload of the fkey field display values...
                  this.ffID = this.eventServerService.newResource(this.formFillerFieldEH).ffID;

                  this.userInterfaceService.reloadCurrentPage();
                }
              );
            }
          } catch (ex) {
            const msg2 = 'modifyFormFiller.internalPromptCallback()\n';
            this.userInterfaceService.alertUserToException(ex, msg2);
          }
        }
      );

    } catch (ex) {
      const msg = 'modify()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  copy(): void {
    try {

      const oldFormFillerID = this.selectedFormFiller.ffID.value;
      const formFillerName = this.selectedFormFiller.ffID.displayValue;

      const title = 'Copy Form Filler: ' + formFillerName;
      const placeholder = 'form filler name';
      const message = 'Please enter the name of the NEW form filler:';

      this.modalDialogService.showPrompt(message, title, placeholder).subscribe(
        (inputValue: string | boolean) => {
          try {
            if (!inputValue) {
              // user clicked cancel...
            } else if (inputValue === '') {
              this.modalDialogService.showAlert('Not copying to empty form filler name', 'WARNING!');
            // } else if (inputValue === defaultValue) {
            //   this.modalDialogService.showAlert('Not copying to un-changed form filler name: ' + inputValue, 'WARNING!');
            } else {

              const confirmMsg = 'This will create a new form filler called "'
                      + inputValue
                      + '",\nwhich will be a copy of the "'
                      +  formFillerName
                      + '" form filler.\n\nAre you sure?';

              this.modalDialogService.showConfirm(confirmMsg, 'Confirm - ' + title).subscribe(
                (flag: boolean) => {
                  if (flag) {

                    this.modalDialogService.showPleaseWait('...while we copy the templates.');

                    // add the new list name...

                    const parms: any = {};
                    parms.ffName = inputValue;

                    this.eventServerService.fireEvent(this.formFillerEH, 'add', parms).subscribe(
                      (event: WEvent) => {
                        try {

                          // console.log(this.formFillerEH, 'add', event);

                          if (event.status !== 'OK') {
                            throw new Error('Unable to add ' + inputValue + ' to FormFillers: ' + event.message);
                          }

                          if (event.parameters.ffID === null) {
                            const msg = 'Failed to get ID for new list: ' + inputValue;
                            // wackadoo.lib.alert(msg + '\n' + JSON.stringify(event));
                            throw new Error(msg);
                          }

                          const newFormFillerID = event.parameters.ffID;

                          this.userInterfaceService.setPageState(this.formFillerEH, 'ffID', newFormFillerID);

                          // dupe all the items...
                          // (gotta list them, and add a new copy of each...)

                          const parms2: any = {};
                          parms2.ffID = oldFormFillerID;
                          parms2.pageSize = -1;

                          // console.log('copy.internalPromptCallback.okCallback() - Got there! parms2:', parms2);

                          this.eventServerService.fireEvent(this.formFillerFieldEH, 'list', parms2).subscribe(
                            (event2: WEvent) => {
                              try {
                                // console.log(this.formFillerFieldEH, 'list', parms2, event2);
                                if (event2.status !== 'OK') {
                                  throw new Error('Unable to list form filler: ' + event2.message);
                                }

                                let i = 1;
                                const totalCount = (event2.resources ? event2.resources.length : 0);

                                for (const r of event2.resources) {

                                  const parms3: any = r.asParms;
                                  parms3.ffID = newFormFillerID;
                                  parms3.isLast = (i++ === totalCount);
                                  delete parms3.fffID;

                                  // console.log('copy.list.okCallback() - about to add, w/parms3:', parms3);

                                  this.eventServerService.fireEvent(this.formFillerFieldEH, 'add', parms3).subscribe(
                                    (event3: WEvent) => {
                                      try {

                                        // console.log(this.formFillerFieldEH, 'add', parms3, event3);

                                        if (event3.status !== 'OK') {
                                          throw new Error('Unable to add form filler field: ' + event3.message);
                                        }

                                        if (event3.parameters.isLast === true) {
                                          this.modalDialogService.showPleaseWait(false);

                                          // this forces a reload of the fkey field display values...
                                          this.ffID = this.eventServerService.newResource(this.formFillerFieldEH).ffID;

                                          this.userInterfaceService.reloadCurrentPage();
                                        }

                                      } catch (ex) {
                                        this.modalDialogService.showPleaseWait(false);
                                        const msg = 'copy.add field.callback()\n';
                                        this.userInterfaceService.alertUserToException(ex, msg);
                                      }
                                    }
                                  );

                                }

                              } catch (ex) {
                                this.modalDialogService.showPleaseWait(false);
                                const msg = 'copy.list.callback()\n';
                                this.userInterfaceService.alertUserToException(ex, msg);
                              }
                            }
                          );

                        } catch (ex) {
                          this.modalDialogService.showPleaseWait(false);
                          const msg = 'copy.add.callback()\n';
                          this.userInterfaceService.alertUserToException(ex, msg);
                        }
                      }
                    );
                  }
                }
              );
            }
          } catch (ex) {
            const msg = 'copy.internalPromptCallback()\n';
            this.userInterfaceService.alertUserToException(ex, msg, true);
          }
        }
      );

    } catch (ex) {
      const msg = 'copy()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  delete(): void{
    try {
      const ffID = this.selectedFormFiller.ffID.value;
      const ffName = this.selectedFormFiller.ffID.displayValue;

      if (ffID === 1) {
        const msg = 'You are not allowed to delete form filler number ' + ffID + ', the "' + ffName + '" form filler!' ;
        this.modalDialogService.showAlert(msg, 'WARNING!');

      }  else {

        const confirmMsg = 'This is going to delete the "'
                + ffName
                + '" form filler and all the defined fields associated with it!'
                + '\n\nAre you REALLY sure you want to do this?';

        this.modalDialogService.showConfirm(confirmMsg, 'WARNING!').subscribe(
          (flag: boolean) => {
            if (flag) {
              const parms = { ffID };

              this.modalDialogService.showPleaseWait('Deleting "' + ffName + '" and all the defined fields associated with it...');

              this.eventServerService.fireEvent(this.formFillerEH, 'delete', parms).subscribe(
                (event: WEvent) => {
                  if (event.status !== 'OK') {
                    throw new Error(event.message);
                  }
                  this.modalDialogService.showPleaseWait(false);
                  this.userInterfaceService.clearPageState(this.formFillerEH, 'ffID');

                  // this forces a reload of the fkey field display values...
                  this.ffID = this.eventServerService.newResource(this.formFillerFieldEH).ffID;

                  this.userInterfaceService.reloadCurrentPage();
                }
              );
            }
          }
        );

      }
    } catch (ex) {
      const msg = 'delete()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  export(): void{
    const parms = {
      reportName: 'Generate New System Default FormFillers',
      fileExtension: 'txt',
      ffID: this.selectedFormFiller.ffID.value,
    };
    this.eventServerService.downloadFile('Reports', 'export', parms);
  }

  private _loadDefaultTemplates(): void {
    // console.log('_loadDefaultTemplates()');

    try {

      // reload the system template values...

      this.eventServerService.fireEvent(this.formFillerEH, 'reloadDefaultTemplateDefinitions', {}).subscribe(
        (event3: WEvent) => {
          // console.log(event3);
          if (event3.status !== 'OK') {
            throw new Error(event3.message);
          }

          // by passing no parms, we are re-loading ALL the defaults from the file...

          this.eventServerService.fireEvent(this.formFillerFieldEH, 'reloadDefaultTemplateDefinitions', {}).subscribe(
            (event4: WEvent) => {
              // console.log(event4);
              this.modalDialogService.showPleaseWait(false);
              if (event4.status !== 'OK') {
                throw new Error(event4.message);
              }

              this.userInterfaceService.clearPageState(this.formFillerEH, 'ffID');

              // this forces a reload of the fkey field display values...
              this.ffID = this.eventServerService.newResource(this.formFillerFieldEH).ffID;

              this.userInterfaceService.reloadCurrentPage();
            }
          );
        }
      );

    } catch (ex) {
      const msg = '_loadDefaultTemplates()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }

  }

  reload(): void{
    try {
      const msgTitle = 'WARNING! It is REALLY not a good idea to do this!';

      let msg = 'This completely erases ALL of your existing FormFiller definitions - including your customized ones - and then re-loads the defaults from the server.';
      msg += '\n\nIf you want to keep a copy of your customized FormFillers, you should run a "Templates" Backup first.';
      msg += '\n\nAre you SURE?';

      this.modalDialogService.showConfirm(msg, msgTitle).subscribe(
        (flag: boolean) => {
          if (flag) {
            this.modalDialogService.showPleaseWait('Re-loading default FormFiller definitions from the server.');
            this._loadDefaultTemplates();
          }
        }
      );

    } catch (ex) {
      const msg = 'reload()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  loadSelectedFormFillerFields(selectedFieldName: string): void {
    this.selectedFieldName = selectedFieldName;

    const parms = this.selectedFormFiller.ffID.asParm;
    parms.fffName = selectedFieldName;
    parms.pageSize = -1;
    parms.sortBy = 'fffName';
    parms.sortDirection = 1;

    // console.log('loadSelectedFields()', parms);

    this.eventServerService.fireEvent(this.formFillerFieldEH, 'list', parms).subscribe(
      (event: WEvent) => {
        // console.log(event);
        try {
          if (event.status !== 'OK') {
            throw new Error('Unable to list fields for form filler: ' + event.message);
          }

          if (event.resources) {
            this.selectedFormFillerFields = event.resources;
          } else {
            this.selectedFormFillerFields = [];
          }

          const r = this.eventServerService.newResource(this.formFillerFieldEH);
          r.ffID.value = this.ffID.value;
          r.ffID.changed = true;
          r.fffName.value = selectedFieldName;
          r.fffName.changed = true;
          this.selectedFormFillerFields.push(r);

          // now, in case we have something selected, re-parse the content
          this.parseContent();

        } catch (ex) {
          const msg = 'loadSelectedFields()\n';
          this.userInterfaceService.alertUserToException(ex, msg);
        }
      }
    );
  }

  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////
  // This section of the code deals with parsing the content according
  // to the currently defined FormFillerField definitions...
  ///////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////

  parseContent(): void {

    if (this.content && (this.definedFields.length > 0)) {
      let lines = [];

      // first, we clear out the old values...
      this.exampleResource.clearAllFields();
      // we set this (if necessary) when we look at the un-used lines...
      this.truncatedContent = '';

      // remove all LF chars and split into lines by NL char
      lines = this.content.replace(/\r/g, '').split('\n');

      if (this.debug) { console.log('parseContent()', lines.length + ' content lines'); }
      // if (this.debug) { console.log('parseContent()', 'definedFields:', this.definedFields); }
      // if (this.debug) { console.log('parseContent()', 'exampleResource:', this.exampleResource); }

      const catchAllFormFillerFieldResource = this.definedFields.find((r) => r.fffType.value === 'MultiLine');
      // if (this.debug) { console.log('parseContent()', 'catchAllFormFillerFieldResource:', catchAllFormFillerFieldResource); }
      const catchAllFieldMap = (catchAllFormFillerFieldResource ? catchAllFormFillerFieldResource.asParms : null);

      if (this.debug) { console.log('parseContent()', 'catchAllFieldMap:', catchAllFieldMap); }

      this.catchAllFieldName = (catchAllFieldMap ? catchAllFieldMap.fffName : null);

      for (const fieldMap of this.definedFields) {
        if (fieldMap) {
          this.extractValue(fieldMap.asParms, lines, catchAllFieldMap);
        }
      }

      // now append all un-used, delimited lines to the catch-all field...
      this.appendUnUsedLines(lines, catchAllFieldMap);

      if (this.debug) { console.log('parseContent() - populated resource:', this.exampleResource.asParms); }

    }

    if (this.debug) { console.log('parseContent() - exiting'); }

  }

  extractValue(fieldMap: any, lines: string [], catchAllFieldMap: WField): void {

    if (this.debug) { console.log('extractValue()', fieldMap, catchAllFieldMap); }

    let rawValue = null;

    let isMultiLine = false;
    let isNumber = false;
    let isCurrency = false;
    let isDate = false;

    const isString = !fieldMap.fffType || (fieldMap.fffType === 'String');

    if (!isString) {
      isMultiLine = (fieldMap.fffType === 'MultiLine');
      isNumber = (fieldMap.fffType === 'Number');
      isCurrency = (fieldMap.fffType === 'Currency');
      isDate = (fieldMap.fffType === 'Date');
    }

    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];

      if (line && (line.length > 0) && line.startsWith(fieldMap.fffPrefix + fieldMap.fffPrefixDelimiter)) {
        if (this.debug) { console.log('\tline: ', line); }
        rawValue = line.substr(fieldMap.fffPrefix.length + fieldMap.fffPrefixDelimiter.length).trim();
        if (!isString || (fieldMap.fffPartial === 'all')) {
          lines[i] = ''; // empty out the line so we don't use it again...
        }

        // these are values we ignore
        if (['none', 'na', 'unknown', 'tbd', '?', '??', '???'].includes(rawValue.toLowerCase())) {

          rawValue = null;

        } else if (isMultiLine) {

          const maxRawValueLength = Number(this.exampleResource[fieldMap.fffName].length);

          let keepGoing = false;
          do {
            line = lines[++i];

            keepGoing = (i < lines.length) && (line !== null) && (line.indexOf(fieldMap.fffPrefixDelimiter) === -1) && (rawValue.length + line.trim().length <= maxRawValueLength);
            const appendThisLine = keepGoing && (line.trim().length > 0);

            if (this.debug) { console.log('\tkeepGoing? ' + (keepGoing ? 'true' : 'false') + ', appendThisLine? ' + (appendThisLine ? 'true' : 'false')); }

            if (appendThisLine) {
              if (this.debug) { console.log('\tline: ', line.trim()); }
              rawValue += ('\n' + line.trim());
              lines[i] = ''; // empty out the line so we don't use it again...
            }

          } while (keepGoing);
        }
        break;
      }
    }

    if (rawValue) {
      let value = rawValue.trim();

      if (isString) {

        if (fieldMap.fffPartial) {
          const firstOffset = rawValue.indexOf(fieldMap.fffPartialValue);
          const lastOffset = rawValue.lastIndexOf(fieldMap.fffPartialValue);

          switch (fieldMap.fffPartial) {
            case 'regex' :
              const matches = rawValue.match(fieldMap.fffPartialValue);
              value = (matches ? matches[1] : 'no match');
              break;
            case 'before first' :
              if (firstOffset > -1) {
                value = rawValue.substring(0, firstOffset);
              }
              break;
            case 'after first' :
              if (firstOffset > -1) {
                value = rawValue.substring(firstOffset + 1);
              }
              break;
            case 'before last' :
              if (lastOffset > -1) {
                value = rawValue.substring(0, lastOffset);
              }
              break;
            case 'after last' :
              if (lastOffset > -1) {
                value = rawValue.substring(lastOffset + 1);
              }
              break;
            case 'all' :
            default :
              // NOP: since, per above, value = rawValue
              break;
          }

          if (this.debug) { console.log('\t"' + rawValue + '"', fieldMap.fffPartial, fieldMap.fffPartialValue, firstOffset, lastOffset, '"' + value + '"'); }
        }

        if (fieldMap.fffLabel) {
          value = fieldMap.fffLabel + fieldMap.fffPrefixDelimiter + ' ' + value;
        }

      } else if (isCurrency) {
        value = value.replace(/\$/g, '');
        value = value.replace(/,/g, '');
        value = Number(value);
      } else if (isNumber) {
        value = value.replace(/,/g, '');
        value = Number(value);
      } else if (isDate) {
        value = new Date(value);
      }

      if (this.debug) { console.log('\tvalue: ', value, typeof value); }

      let fieldName = fieldMap.fffName;

      // we check for the fieldName from the map being part of the resource defn...
      // if not, then we switch to the catch-all field, and use it (including it's proper multiValueSeparator...)

      if (!this.exampleResource[fieldName]) {
        if (catchAllFieldMap) {
          value = fieldMap.fffName + fieldMap.fffPrefixDelimiter + ' ' + rawValue;
          fieldName = catchAllFieldMap.name;
          isMultiLine = (catchAllFieldMap.fffType === 'MultiLine');
        }
      }

      // at this point, we ALWAYS should have a valid resource field.
      // However, we include conditional processing of unknown fields
      // just in case the catch-all field is invalid or its map is missing.

      if (this.exampleResource[fieldName]) {
        if (this.exampleResource[fieldName].type === 'String') {
          this.exampleResource[fieldName].value = (this.exampleResource[fieldName].value ? this.exampleResource[fieldName].value + (isMultiLine ? '\n/ ' : ' / ') : '') + value;
        } else if (isDate) {
          this.exampleResource[fieldName].value = value.toJSON();
        } else {
          this.exampleResource[fieldName].value = value;
        }

      } else {

        let fld: WField = null;

        if (isDate) {
          fld = new WDate(fieldName, value.toJSON());
        } else if (isNumber) {
          fld = new WField(fieldName, value, ((value % 1 === 0.0) && !isCurrency ? 'Integer' : 'Float'));
        } else {
          fld = new WString(fieldName, value);
        }

        if (this.debug) { console.log('\tnew field, type: ', fld.type); }
        this.exampleResource[fieldName] = fld;
      }

      this.exampleResource[fieldName].changed = true;

      if (this.debug) { console.log(fieldName + ': ', this.exampleResource[fieldName].value); }

    }
  }

  appendUnUsedLines(lines: string [], catchAllFieldMap: any): void {
    if (this.debug) { console.log('appendUnUsedLines()', lines, catchAllFieldMap); }

    if (catchAllFieldMap) {

      const catchAllFieldName = catchAllFieldMap.fffName;

      if (this.debug) { console.log('appendUnUsedLines()', catchAllFieldMap, catchAllFieldName, this.exampleResource[catchAllFieldName]); }

      if (catchAllFieldMap && this.exampleResource[catchAllFieldName]) {

        for (const line of lines) {

          if (line && (line.length > 0) && (line.indexOf(catchAllFieldMap.fffPrefixDelimiter) > -1)) {

            if (this.debug) { console.log('\tline: ', line); }

            // load the catch-all field...

            const oldValue = this.exampleResource[catchAllFieldName].value;

            this.exampleResource[catchAllFieldName].value = (oldValue ? oldValue + (catchAllFieldMap.fffType === 'MultiLine' ? '\n/ ' : ' / ') : '') + line;
            this.exampleResource[catchAllFieldName].changed = true;

          }
        }

        if (this.debug) { console.log(
              this.exampleResource[catchAllFieldName].value.length,
              typeof this.exampleResource[catchAllFieldName].value.length,
              this.exampleResource[catchAllFieldName].length,
              typeof this.exampleResource[catchAllFieldName].length
            ); }

        if (this.exampleResource[catchAllFieldName].value.length > this.exampleResource[catchAllFieldName].length) {

          let indexOfLastGoodNewLine = this.exampleResource[catchAllFieldName].length;
          while (this.exampleResource[catchAllFieldName].value[indexOfLastGoodNewLine] !== '\n') {
            indexOfLastGoodNewLine--;
          }

          // first, save the truncated part...
          this.truncatedContent = this.exampleResource[catchAllFieldName].value.substr(indexOfLastGoodNewLine);
          // ...and THEN do the actual truncation.
          this.exampleResource[catchAllFieldName].value = this.exampleResource[catchAllFieldName].value.substr(0, indexOfLastGoodNewLine);

        }

        if (this.debug) { console.log(catchAllFieldName + ': ', this.exampleResource[catchAllFieldName].value); }
      }
    }
  }

}
