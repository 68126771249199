import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-policy-data-security',
  templateUrl: './policy-data-security.component.html',

})
export class PolicyDataSecurityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
