import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError, tap, take } from 'rxjs/operators';
import { UtilityLibService } from './utility-lib.service';
import { WEvent } from '../data/event.model';
import { WResource } from '../data/resource.model';
import { WField } from '../data/field.model';
import { ModalDialogService } from './modal-dialog.service';
import { BusinessRuleService } from './business-rule.service';
import * as FileSaver from 'file-saver';
import { Globals } from './global.service';
import { User } from '../data/user.model';
import { EventServerHttpUrlEncodingCodec } from './event-server-http-url-encoding-codec';

enum EventServerRequestErrorType { http = 'http', eventParsing = 'eventParsing', other = 'opther' }

@Injectable({
  providedIn: 'root'
})
export class EventServerService {

  private _sessionToken = ''; // empty string means "public" user access...

  public userAPI: any [] = [];

  private _readOnlyOnceEvents: { [eh: string]: WEvent } = {};

  constructor(
    private _http: HttpClient,
    private _utils: UtilityLibService,
    private _modalDialogService: ModalDialogService,
    private _businessRuleService: BusinessRuleService,
  ) {
    this._sessionToken = this._utils.getCookie(Globals.sessionTokenName);
  }

  isValidToken(): boolean {
    return (this._sessionToken !== null) && (this._sessionToken !== '');
  }

  clearUserAPI(): void {
    this.userAPI = [];
  }

  getSessionTokenURLParameter(): string {
    return Globals.sessionTokenName + '=' + encodeURIComponent(this._sessionToken);
  }

  getUserFromCookie(): User {
    const userCookieAsJSON = this._utils.getJsonCookie(Globals.readableUserCookieName);
    // console.log('getUserFromCookie()', 'userCookieAsJSON:', userCookieAsJSON);

    return new User(userCookieAsJSON);
  }

  showSession(msg?: string): void {
    console.log(
        (msg ? msg + ' - ' : '') + 'Current session info:'
        , '\n\t' + Globals.sessionTokenName  + ': ' + this._sessionToken
        , '\n\t' + Globals.startupCookieName + ': ' + this._utils.getCookie(Globals.startupCookieName)
        , '\n\t' + Globals.readableUserCookieName + ': ', this.getUserFromCookie()
    );
  }

  logMessage(message: string, level?: string): void {
    level = typeof level !== 'undefined' ? level : 'WARNING';
    console.log('logMessage', level, message);
    const parms =  { level, message };
    this.fireEvent('Administration', 'logMessage', parms).subscribe(
      (e: WEvent) => {
        // we do this mostly to clean up the un-used "e" LINT warning...
        if (e.status !== 'OK') {
          console.log('logMessage', (e.status), level, message, e);
        }
      }
    );
  }

  logout(): void {

    // sometimes, on errors w/public users, we try to logout when the user doesn't have permission to logout...

    if ( this.getMethods('Administration').includes('logout')) {

      // we COULD re-initialize everything here, but to logout, we really only need to reload the web app.
      this.fireEvent('Administration', 'logout', {}).subscribe(
        () => {
          this._utils.deleteCookie(Globals.sessionTokenName);
          this._utils.deleteCookie(Globals.readableUserCookieName);
          this._utils.deleteCookie(Globals.startupCookieName);
          window.location.href = 'https://www.' + Globals.domain;
        }
      );
    } else {
      this._utils.deleteCookie(Globals.sessionTokenName);
      this._utils.deleteCookie(Globals.readableUserCookieName);
      this._utils.deleteCookie(Globals.startupCookieName);
      window.location.href = 'https://www.' + Globals.domain;
    }
  }

  fireEvent(eventHandler: string, action: string, parms: any, appName?: string): Observable<WEvent> {
    appName = typeof appName !== 'undefined' ? appName : Globals.thisApplication;

    const debug = false; // ((eventHandler === 'Agents') && ((action === 'add') || (action === 'modify')));

    if (debug) { console.log('EventServerService.fireEvent()', eventHandler, action, parms, new Error('debug')); }

    // first, we check our inputs...

    if (!eventHandler || (eventHandler === '')) {
      throw new Error('EventServerService.fireEvent() - Missing eventHandler!');
    }

    if (!action || (action === '')) {
      throw new Error('EventServerService.fireEvent() - Missing action!');
    }

    if (!parms) {
      parms = {};
    }

    // second, we check to see if we're doing something with fileContent...

    let fileContentFieldName = this.getBinaryContentFieldNameForEventHandler(eventHandler);
    let fileContent = (fileContentFieldName ? parms[fileContentFieldName] : null);

    // QUESTION: Do we have to use the "fake/special" field names for file uploads?
    // i.e. fileContent, fileName and fileType (as defined in the FileContentDescriptor)
    // ANSWER: Only on a direct file upload from the fileUploadDirective - which preloads
    // all of them via FileContentDescriptor.genericBinaryField.
    // In that scenario, the DataArchives EH does NOT have a resource defn type, and
    // hence, no "fileContentFieldName" so we use the incoming fileContent parameter
    // Note that the EventServerServlet properly handles these parameter names...
    if (
        !fileContent
        &&
        parms.hasOwnProperty('fileContent')
        &&
        parms.hasOwnProperty('fileName')
        &&
        parms.hasOwnProperty('fileType')
        &&
        parms.hasOwnProperty('fileSize')
        &&
        parms.hasOwnProperty('fileDate')
    ) {
      fileContentFieldName = 'fileContent';
      fileContent = parms.fileContent;
    }

    // DEBUG LINE!
    // if (debug) { console.log('fireEvent()', fileContentFieldName, fileContent, parms); }

    // allow business rules to munge the request...

    const mungedRequest = this._businessRuleService.applyServerRequestRules(eventHandler, action, parms);
    eventHandler = mungedRequest.eh;
    action = mungedRequest.action;
    parms = mungedRequest.parms;

    // if we're hitting a resource repository, we make sure we have startAt and pageSize set...
    if (parms && this.isResourceRepositoryEventHandler(eventHandler)) {
      if (!parms.startAt) {
        parms.startAt = 1;
      }
      if (!parms.pageSize) {
        parms.pageSize = this._businessRuleService.getDefaultPageSize(eventHandler);
      }
    }

    let url = ((appName !== Globals.rootApplication) ? '/' + appName : '');
    url += '/EventServer/' + eventHandler + '/' + action;

    // using our "do not encode the + sign encoder..."
    let params = new HttpParams({encoder: new EventServerHttpUrlEncodingCodec()});
    let query = '';
    if (parms) {
        for (const [name, value] of Object.entries(parms)) {
          if (name !== fileContentFieldName) {
            query += ('&' + name + '=' + encodeURIComponent(String(value)));
            params = params.set(name, String(value));
          }
        }
    }
    const readOnceURL = url + ((query.length > 0) ? ('?' + query.substring(1)) : '');

    // check the cache for a previous readOnlyOnce event...

    if (this._businessRuleService.readOnlyOnce(eventHandler) && this.hasPreviousEvent(readOnceURL)) {
      const event = this.getPreviousEvent(readOnceURL);
      return new Observable<WEvent>(observer => { observer.next(event); } );
    } else {

      // if (debug) { console.log('fireEvent()', url, parms, fileContentFieldName, fileContent); }

      // if we ARE uploading binary file content, we do a multi-part-mime POST operation...
      if (fileContent) {

          const boundary = '-----file-upload-' + Math.floor(Math.random() * 32768) + '-' + Math.floor(Math.random() * 32768) + '-' + Math.floor(Math.random() * 32768) + '-file-upload-----';
          const filePart = '';

          let formPart = ''; // empty for now...

          formPart += '--' + boundary + '\r\n' + 'Content-Disposition: form-data; name="' + fileContentFieldName + '"\r\n' + '\r\n' + fileContent + '\r\n' + '--' + boundary + '--\r\n';

          // console.log('fireEvent() - MultiPartMime - formPart:', formPart);

          let multipart = '';
          multipart += filePart;
          multipart += formPart; // we could do multiples here, if the server supported more than one per Event...

          // console.log('fireEvent() - MultiPartMime - Multi-part...', multipart);

          // DEBUG LINE!
          // if (debug) { console.log('fireEvent() MultiPartMime - firing event:', url, multipart, params); }

          // If we wanted to look at the headers, we would do it this way...
          return this._http.post<any>(url, multipart, {
            headers: new HttpHeaders({
              Accept: 'application/json',
              'content-type': 'multipart/form-data; boundary=' + boundary
            }),
            params,
            observe: 'response'
          } ).pipe(
            catchError((err: any) => {
              return(this.handleError(err, this, this._modalDialogService));
            })
            // , tap(
            //   (response) => {
            //     console.log(response.body);
            //   }
            // )
            , map((response) => {
                // It turns out that the "Event" returned by the server has
                // some formatting issues that make it more difficult to use
                // than we would like. So we scrub it here, on the way in,
                // and put it into our more palatable WEvent format.
                const event: WEvent = this._eventFactory(response);

                return event;
              }
            )
            , tap ((event: WEvent) => {
                // cache the readOnlyOnce event...
                if (this._businessRuleService.readOnlyOnce(eventHandler)) {
                  this.setPreviousEvent(url, event);
                }
              }
            )
            , map((event: WEvent) => {
                return this._businessRuleService.applyServerResponseRules(event);
              }
            )
            , catchError((err: any) => {
              return(this.handleEventParsingError(err, this._modalDialogService));
            })
          );

      // else if we ARE NOT uploading binary file content, we do a simple POST operation...
      } else if (!fileContent) {

        if (debug) { console.log('firing event:', url, params); }

        // If we wanted to look at the headers, we would do it this way...
        return this._http.post<any>(url, params, { observe: 'response', responseType: 'json' }).pipe(
          // catchError((err: any) => {
          //     // a lot of times, the JSON text looks JUST FINE (thank you very much...)
          //     // so we are going to try to catch it... again.
          //     if ((err instanceof HttpErrorResponse) && (err.status === 200) && (err.statusText === 'OK')) {
          //       const jsonEvent = JSON.parse(err.error.text);
          //       const event: WEvent = this._eventFactory(jsonEvent);
          //       console.log('EventServerService.fireEvent(' + eventHandler + ', ' + action + ', ' + JSON.stringify(parms) + ') - Caught false-negative event response!');
          //       return of<WEvent>(event);
          //     }
          //     return err;
          //   }
          // ) ,
          catchError((err: any) => {
            return(this.handleError(err, this, this._modalDialogService));
          })
          // , tap(
          //   (response) => {
          //     console.log(response.body);
          //   }
          // )
          , map((response: HttpResponse<any>) => {
              // It turns out that the "Event" returned by the server has
              // some formatting issues that make it more difficult to use
              // than we would like. So we scrub it here, on the way in,
              // and put it into our more palatable WEvent format.

              // if (debug) { console.log(response); }

              const event: WEvent = this._eventFactory(response);

              if (debug) { console.log(event); }

              return event;
            }
          )
          , tap ((event: WEvent) => {
              // cache the readOnlyOnce event...
              if (this._businessRuleService.readOnlyOnce(eventHandler)) {
                this.setPreviousEvent(url, event);
              }
            }
          )
          , map((event: WEvent) => {
              return this._businessRuleService.applyServerResponseRules(event);
            }
          )
          , catchError((err: any) => {
            return(this.handleEventParsingError(err, this._modalDialogService));
          })
        );

      // else if we ARE uploading binary file content, we do a multi-part MIME POST operation...

      }
    }
  }

  private handleError(httpErrorResponse: HttpErrorResponse, ess: EventServerService, mds: ModalDialogService): Observable<never> {
    // See https://angular.io/api/common/http/HttpErrorResponse
    console.log('\n===========================\n', httpErrorResponse, '\n===========================');

    let logoutFailed = false;
    let logMessageFailed = false;
    let getStatusFailed = false;

    mds.showPleaseWait(false);

    let title = '';
    let message = '';
    let status = null;
    let error = null;

    switch (httpErrorResponse.status) {

      // Should log user out on any of these...
      // ERR_CONNECTION_RESET
      // ERR_CONNECTION_REFUSED
      // ERR_TIMED_OUT

      case 0 :

      // if status = 0, then server has completely gone away

        title = 'The web site (' + Globals.domain + ') is currently unavailable.';
        message = 'Please try again in a few minutes.';

        break;

      case 401 :
      case 404 :

        // if status = 401, then the user has lost permission to the requested resource
        // if status = 404, then the user cannot see the requested resource
        // Both of these are basically the same thing when firing EventServer events...
        // This was probably due to the session expiring.

        title = 'Access Denied';

        // https://test.closingpro.net/EventServer/TaskAndNoteDataArchives/list
        // https://acctPrefix.{Globals.domain}/EventServer/eh/method

        const url = httpErrorResponse.url;
        const parts = url.split('/');
        // console.log('url parts:', url, parts);

        if (parts[2].endsWith(Globals.domain) && (parts[3] === 'EventServer')) {

          logoutFailed = (parts[5] === 'logout');
          logMessageFailed = (parts[5] === 'logMessage');
          getStatusFailed = (parts[5] === 'getStatus');

          message = 'Either your login timed out or the server glitched.';
          message += '\n\n<span class="smallish italic">(You will need to login again.)</span>';
        } else {
          message = httpErrorResponse.message + '\n' + url;
        }

        // message += '\n\n==================================';
        // message += '\n(Please text this to Steve...)';
        // message += '\n' + (new Date()).toUTCString();
        // message += '\n' + url;
        // message += '\n' + httpErrorResponse.status + ' ' + httpErrorResponse.statusText;
        // message += '\n' + (new Error()).stack;
        // message += '\n' + JSON.stringify(httpErrorResponse);
        // message += '\n' + Globals.sessionTokenName  + ': ' + this._utils.getCookie(Globals.sessionTokenName);
        // message += '\n' + Globals.startupCookieName + ': ' + this._utils.getCookie(Globals.startupCookieName);
        // message += '\n' + Globals.readableUserCookieName + ': ', this.getUserFromCookie();
        // message += '\n==================================';

        break;

      default :

        title = 'An HTTP Error Occurred';

        if (httpErrorResponse.error instanceof ErrorEvent) {
          // Get client-side error
          message = httpErrorResponse.error.message;
        } else {
          // Get server-side error
          message = httpErrorResponse.message;
        }

        status = httpErrorResponse.status + ' - ' + httpErrorResponse.statusText;
        error = httpErrorResponse.error;

        break;
    }

    if (logoutFailed || logMessageFailed) {
      this._utils.deleteCookie(Globals.sessionTokenName);
      this._utils.deleteCookie(Globals.readableUserCookieName);
      this._utils.deleteCookie(Globals.startupCookieName);
      window.location.href = 'https://www.' + Globals.domain;
    } else if (getStatusFailed) {
      ess.logMessage(message);
    } else {
      mds.showError(message, title, status, error).subscribe(
        () => {
          this._utils.deleteCookie(Globals.sessionTokenName);
          this._utils.deleteCookie(Globals.readableUserCookieName);
          this._utils.deleteCookie(Globals.startupCookieName);
          window.location.href = 'https://www.' + Globals.domain;
        }
      );
    }

    return throwError(EventServerRequestErrorType.http);
  }

  private handleEventParsingError(error: any, mds: ModalDialogService): Observable<never> {

    if (error !== EventServerRequestErrorType.http) {

      console.log('\n===========================\n', error, '\n===========================', this._modalDialogService);

      mds.showPleaseWait(false);

      const message = 'Error details:';
      const title = 'Error Parsing Server Response';
      const status = null;

      mds.showError(message, title, status, error);
    }

    return throwError(null);
  }

  /*
    It turns out that the "Event" returned by the server has
    a LOT of formatting issues that make it more difficult to use
    than we would like. So we scrub it here, on the way in,
    and put it into our more palatable WEvent / WResource / WField formats.
  */
  private _eventFactory(response: HttpResponse<any>): WEvent {

    // 1. parse the body into a generic JSON object, un-wrapping the redundant "event" layer...
    const o = response.body.event;

    // const debug = o.firstHandler === 'InBox'; // (o.action !== 'getStatus'); // used to control debugging statements...

    try {
      // if (debug) { console.log('============='); }
      // if (debug) console.log(o);

      const ehName = o.firstHandler; // used in applying API defns to incoming resource fields...

      // 2. re-structure the parameters as a flattened JSON object instead of
      //    the {@name: string; @type: string; text: string} that we get from the server
      //    (This allows us to use the incoming parms as the basis of out-going parms as necessary.)
      if (o.hasOwnProperty('parameters')) {
        const oldParms = o.parameters;
        o.parameters = {};

        // re-format the parms for use in subsequent server calls...
        for (const [pName, valueObj] of Object.entries(oldParms)) {
          const parm: any = valueObj;
          const name = pName.startsWith('@') ? pName.substr(1) : pName ;
          // convert parameter values to the proper primitive (string, boolean, number)
          let value = parm.text;
          if (value === 'true') {
              value = true;
          } else if (value === 'false') {
              value = false;
          } else if ((value !== '') && !isNaN(value as any)) {
              value = Number(value);
          }
          o.parameters[name] = value;
        }
      }

      // if we get "o.resource", it may be a singleton or an array, but practically
      // speaking, we always want to treat this as an array...

      // 3. wrap singleton resources as a one-element array to make things consistent...
      if (o.hasOwnProperty('resource') && !((o.resource as any) instanceof Array)) {
        o.resource = [o.resource as WResource];
      }

      // if (debug) console.log('=============');
      // if (debug) console.log(o);

      // 4. scrub the incoming JSON to build proper Resources
      if (o.hasOwnProperty('resource')) {
        const oldResArray = o.resource;
        const newResArray: WResource [] = [];

        for (const r of oldResArray) {

          // if (debug) console.log('=============');
          // if (debug) console.log(r);

          for (const [fieldName, fldObj] of Object.entries(r)) {

            // we do not touch the @ attribute tags at the resource level...
            // (e.g. @name and @type...) because they are not fields...
            if (!fieldName.startsWith('@')) {

              // if (debug) { console.log('Before: ', fldObj); }

              // HACK ALERT: The server gives us the field name OUTSIDE the fldObj, and we want it INSIDE...
              (fldObj as any).name = fieldName;

              // HACK ALERT: The server gives some "field" attribute names to us
              // with an @ prefix, and field-model.ts does not want that.
              // So, we remove the @ from all field attribute names - e.g. @type becomes type, etc.
              for (const [attr, value] of Object.entries(fldObj)) {

                // if (debug) { console.log('Got here: ', attr, value); }

                if (attr.startsWith('@')) {
                  fldObj[attr.substr(1)] = value;
                  delete fldObj[attr];
                }
              }

              // if (debug) { console.log('After: ', fldObj); }

              // replace the simple JSON object with the updated fldObj
              r[fieldName] = fldObj;

            }
          }

          // if (debug) console.log(r);

          // create a real Resource object...
          // (we do this so that we can add functionality to the Resource model.)

          const resType = r['@type'];
          const fieldAPIs = this.getFieldDefinitionsInOrder(ehName);

          // if (debug) console.log(resType, fieldAPIs);

          const rr: WResource = WResource.factory(resType, fieldAPIs, ehName, r);

          // if (debug) { console.log(r, rr); }

          newResArray.push(rr);
        }

        // now store the WResource [] as "o.resources" instead of "o.resource"
        o.resources = newResArray;
        delete o.resource;
      }
    } catch (ex) {
      console.log(ex);
    }

    // create a real WEvent object...
    // (we do this so that we can add functionality to the Event model.)
    const e: WEvent = new WEvent();

    // now merge all the Event stuff into the just-created instance...
    for (const [name, value] of Object.entries(o)) {
        e[name] = value;
    }

    // if (debug) { console.log(e); }
    // if (debug) { console.log('============='); }

    return e;
  }

  getFieldDefn(ehName: string, fieldName: string): object {
    let apiFieldDefn: object = null;

    // if we have the user's API...
    if (this.userAPI.length > 0) {
      // then grab the server-side defn for the EH API resource defn
      const ehAPI = this.userAPI.find((eh: any) => eh['@name'] === ehName);
      // and if we have one...
      // (e.g. the 'Administration' EH does not ever appear in the user's API...)
      if (ehAPI && ehAPI.resource) {
         // get the api defn of the field that we're trying to generate
        apiFieldDefn = ehAPI.resource[fieldName];
      }
    }
    return apiFieldDefn;

  }

  /***********************************
   * User API
   **********************************/

  loadUserAPI(): Observable<void> {

    // console.log('loadUserAPI()', new Error().stack);

    // clear the old one...
    this.userAPI = [];

    /*
      We do NOT use the regular "fireEvent()" method for loadUserAPI()
      because the fields that come back in a "clientAPI" resource are
      different - at least the "resource" field is - than "regular"
      fields, and so the normal scrubbing for that field does not apply.
      So we capture it raw, munge it a little, and use it as is.
    */
    // using our "do not encode the + sign encoder..."
    let params = new HttpParams({encoder: new EventServerHttpUrlEncodingCodec()});
    params = params.set('skipServerStatus', 'true');

    // console.log('loadUserAPI() - Globals: ', 'root: ' + Globals.rootApplication, 'this: ' + Globals.thisApplication, 'prev: ' + Globals.previousApplication);

    let url = (Globals.thisApplication === Globals.rootApplication ? '' : '/' + Globals.thisApplication);
    url += '/EventServer/Administration/getClientAPI';

    // console.log('loadUserAPI() - firing event: ', url, params);

    // this.showSession();

    return this._http.post<any>(url, params, { observe: 'response', responseType: 'json' }).pipe(
      catchError((err: any) => {
        return(this.handleError(err, this, this._modalDialogService));
      })
      , map( (response: any) => {
          try {
            // console.log(response.body);
            // console.log('before:', response.body.event.resource);
            this.userAPI = this.convertFieldAttributesInFieldAPIs(response.body.event.resource);
            // console.log('after:', this.userAPI);
          } catch (ex) {
            console.log(response, ex);
            this._modalDialogService.showError(ex.message, 'Error Parsing User API');
          }
        }
      )
    );
  }

  convertFieldAttributesInFieldAPIs(userAPI: any): any {

    // console.log('convertFieldAttributesInFieldAPIs()', userAPI);

    let api: any [] = [];

    // sometimes we get a singleton ehDefn instead of an array of ehDefns in userAPI...

    if (userAPI.hasOwnProperty('@name')) {
      api = [ userAPI ];
    } else {
      api = userAPI;
    }

    // console.log(typeof userAPI, userAPI, typeof api, api);

    for (const ehDefn of api) {
      // const ehName = ehDefn['@name'];
      // sometimes there is no resource, and hence, no fields...
      if (ehDefn.resource) {
        for (const [fieldName, fieldDefn] of Object.entries(ehDefn.resource)) {
          // if it is defined, and it has a name, it is a field defn...
          if (fieldName && (fieldDefn as any).name) {
            // convert attr types, similar to Field.constructor()
            for (const [attr, val] of Object.entries(fieldDefn)) {
                let typedValue: any = val;
                if (val === 'true') {
                    typedValue = true;
                } else if (val === 'false') {
                    typedValue = false;
                } else if ((val.trim() !== '') && !isNaN(val as any)) {
                    typedValue = Number(val);
                }
                // if ((fieldName === 'fffPartialValue') && (attr === 'default')) { console.log(fieldName, fieldDefn, attr, val, JSON.stringify(val), typeof val, typedValue, JSON.stringify(typedValue), typeof typedValue); }
                fieldDefn[attr] = typedValue;
            }
          }
        }
      }

    }
    return api;
  }

  getDefaultMethod(ehName: string): string {
    let method: string = null;

    // console.log(this.userAPI.find(val => val.name === ehName));

    const ehAPI = this.userAPI.find((eh: any) => eh['@name'] === ehName);
    // console.log(ehAPI);

    if (ehAPI) {

      const methods: string [] = ehAPI.method.text.split(',');

      // console.log(ehName, methods);

      // we prefer search over list
      // HACK ALERT: Reports has a search method, but we want list as the default...
      if (ehName === 'Reports') {
        method = 'list';
      } else if (methods.includes('search')) {
        method = 'search';
      } else if (methods.includes('list')) {
        method = 'list';
      // but if there's only one thing defined, that's what we use
      } else if (methods.length === 1) {
        method = methods[0];
      }
    }

    // console.log('default method: ' + method);

    return method;
  }

  getMethods(ehName: string): string [] {
    let methods: string [] = [];

    // console.log(this.userAPI.find(val => val.name === ehName));

    const ehAPI = this.userAPI.find((eh: any) => eh['@name'] === ehName);
    // console.log(ehAPI);

    if (ehAPI) {
      methods = ehAPI.method.text.split(',');
    }

    // console.log('default method: ' + method);

    return methods;
  }

  getDataArchiveEventHandlers(): string [] {
    const ehs: string [] = [];
    // we want this one first, always...
    if (this.userAPI.find( (ehAPI: any) => ehAPI['@name'] === 'DataArchives')) {
      ehs.push('DataArchives');
    }
    // now get the others...
    for (const ehAPI of this.userAPI) {
      const ehName = ehAPI['@name'];
      if ((ehName !== 'DataArchives') && (ehName.endsWith('DataArchives'))) {
        ehs.push(ehName);
      }
    }
    return ehs;
  }

  isEventHandler(ehName: string): boolean {
    let flag = false;

    for (const eventHandler of this.userAPI) {
      if (eventHandler['@name'] === ehName) {
        flag = true;
        break;
      }
    }

    return flag;
  }

  isValidMethod(ehName: string, method: string): boolean {
    return (ehName && method ? this.getMethods(ehName).includes(method) : false);
  }

  isResourceRepositoryEventHandler(ehName: string): boolean {
    return ehName && (ehName !== 'Reports') && this.isEventHandler(ehName) && this.userAPI.find((ehDefn: any) => ehDefn['@name'] === ehName).hasOwnProperty('resource');
  }

  getFieldDefinitionsInOrder(ehName: string): WField [] {
    // console.log('EventServerSvc.getFieldDefinitionsInOrder(' + ehName + ')');
    // console.log(this.userAPI);

    let fieldAPIs: WField [] = null;

    const ehDefn = this.userAPI.find((r: any) => r['@name'] === ehName);
    // console.log(ehDefn);

    if (ehDefn) {
      const resDefn: WField = ehDefn.resource;
      // console.log(resDefn);

      // sometimes there is no resource, and hence, no fields...

      if (resDefn) {

        fieldAPIs = [];

        for (const val of Object.values(resDefn)) {
          // if it is defined, and it has a name, it is a field defn...
          if (val && val.name) {
            fieldAPIs.push(val);
          }
        }

        // then we sort the fields by the "number" attribute value... (which are text values...)
        // console.log(tempArray);
        fieldAPIs = fieldAPIs.sort( (f1: WField, f2: WField) => {
            return (Number(f1.number) > Number(f2.number) ? 1 : (Number(f1.number) === Number(f2.number) ? 0 : -1));
        });
      }

    }
    // console.log('EventServerSvc.getFieldDefinitionsInOrder(' + ehName + ')', fieldAPIs);

    return fieldAPIs;
  }

  /**
   * This method searches all EHs with single ResourceTypes,
   * disallowing for any EHs with names that were generated as
   * part of an EH pipeline on the server. (i.e. we only want
   * top level EHs...)
   */
  getEventHandlerForResourceType(resourceType: string): string {

    let ehName: string = null;

    if (resourceType) {
      // console.log(this.userAPI);
      for (const ehDefn of this.userAPI) {
        // console.log(ehDefn);
        const resDefn = ehDefn.resource;
        // console.log(resDefn);
        if (resDefn && resDefn['@type']) {
          if (resDefn['@type'].toLowerCase() === resourceType.toLowerCase()) {
            ehName = ehDefn['@name'];
            break;
          }
        }
      }
    }

    return ehName;
  }

  getResourceTypeForEventHandler(ehName: string): string {

    let resourceType: string = null;

    // console.log(this.userAPI);
    for (const ehDefn of this.userAPI) {
      // console.log(ehDefn);
      const resDefn = ehDefn.resource;
      // console.log(resDefn);
      if (resDefn) {
        if (ehDefn['@name'] === ehName) {
          resourceType = resDefn['@type'];
          break;
        }
      }
    }

    return resourceType;
  }

getResourceDefinition(ehName: string): object {
    // console.log(this.userAPI);
    const ehDefn = this.userAPI.find((r: any) => r['@name'] === ehName);
    // console.log(ehDefn);
    if (ehDefn) {
      const resDefn = ehDefn.resource;
      // console.log(resDefn);
      if (resDefn) {
        return resDefn;
      }
    }
    return null;
  }

  // This is a convenience function that wraps Resource.factory() and automatically looks
  // up the required Resource type and user API information. It returns a "blank" resource
  // of the appropriate type, where all defined fields are set to null.
  // If the the user's API does not support that eventHandler, it returns null.

  newResource(eventHandler: string): WResource {
    let resource: WResource = null;
    if (eventHandler) {
      const rType = this.getResourceTypeForEventHandler(eventHandler);
      if (rType) {
        const fieldAPIs = this.getFieldDefinitionsInOrder(eventHandler);
        if (fieldAPIs) {
          resource = WResource.factory(rType, fieldAPIs, eventHandler);
        }
      }
    }
    return resource;
  }

  getKeyFieldName(ehName: string): string {
    // console.log('EventServerSvc.getKeyFieldName(' + ehName + ')');
    // console.log(this.userAPI);

    let keyFieldName: string = null;

    const ehDefn = this.userAPI.find((r: any) => r['@name'] === ehName);
    // console.log(ehDefn);

    if (ehDefn) {
      const resDefn: WField = ehDefn.resource;
      // console.log(resDefn);

      for (const val of Object.values(resDefn)) {
        if (val && (String(val.key) === 'true')) {
          keyFieldName = val.name;
          break;
        }
      }

    }
    // console.log(fieldAPIs);

    return keyFieldName;
  }

  /**
   * @returns null if no key found for the EventHandler...
   */
  getKeyFieldType(ehName: string): string {
    let keyFieldType = null;

    const ehDefn = this.userAPI.find((r: any) => r['@name'] === ehName);
    // console.log(ehDefn);

    if (ehDefn) {
      const resDefn: WField = ehDefn.resource;
      // console.log(resDefn);

      for (const val of Object.values(resDefn)) {
        if (val && (String(val.key) === 'true')) {
          keyFieldType = val.type;
          break;
        }
      }

    }
    // console.log(fieldAPIs);

    return keyFieldType;
  }

  /**
   * @returns The name of the byte[] (or Binary...) field for the resource definition for the given EventHandler. This returns null if it cannot be found, for whatever reason.
   */
  getBinaryContentFieldNameForEventHandler(ehName: string): string {
    // console.log('getBinaryContentFieldNameForEventHandler(' + ehName + ')');
    // console.log(this.userAPI);

    let binaryFieldName: string = null;

    const ehDefn = this.userAPI.find((r: any) => r['@name'] === ehName);
    // console.log(ehDefn);

    if (ehDefn) {
      const resDefn: WField = ehDefn.resource;
      // console.log(resDefn);

      if (resDefn) {
        for (const val of Object.values(resDefn)) {
          if (val && (String(val.type) === 'byte[]')) {
            binaryFieldName = val.name;
            break;
          }
        }
      }

    }
    // console.log('getBinaryContentFieldNameForEventHandler(' + ehName + ')', binaryFieldName);

    return binaryFieldName;
  }

  /**
   * If a Resource defn has multiple fkeys that refer to the same type of resource,
   * then the FIRST that matches that foreign type is the one returned.
   * @returns null if no foreign keys found for the EventHandler.
   */
  getResourceForeignKeys(ehName: string): object {
    if (!ehName) {
      throw new Error('EventServer.getResourceForeignKeys(null/undefined)');
    }
    let foreignKeys: object = null;
    const ehDefn = this.userAPI.find((r: any) => r['@name'] === ehName);
    // console.log(ehDefn);

    if (ehDefn) {
      const resourceDefn: WField = ehDefn.resource;
      // console.log(resDefn);

      if (resourceDefn) {
        for (const fieldDefn of Object.values(resourceDefn)) {
          if (fieldDefn.hasOwnProperty('foreignType')) {
            if (!foreignKeys) {
              foreignKeys = {};
            }
            if (!foreignKeys[fieldDefn.foreignType]) {
              foreignKeys[fieldDefn.foreignType] = fieldDefn.name;
            }
          }
        }
      }
    }
    return (foreignKeys);
  }

  getResourceDisplayLabelFields(ehName: string): string [] {
    if (!ehName) {
      throw new Error('EventServer.getResourceDisplayLabelFields(' + ehName + ')');
    }
    let displayLabelFields: string [] = null;
    const ehDefn = this.userAPI.find((r: any) => r['@name'] === ehName);
    // console.log(ehDefn);

    if (ehDefn) {
      const resourceDefn: WField = ehDefn.resource;
      // console.log(resDefn);

      if (resourceDefn) {
        for (const fieldDefn of Object.values(resourceDefn)) {
          // console.log(fieldDefn.name, fieldDefn.hasOwnProperty('displayLabel'), fieldDefn);
          if (fieldDefn.hasOwnProperty('displayLabel')) {
            if (!displayLabelFields) {
              displayLabelFields = [];
            }
            displayLabelFields.push(fieldDefn.name);
          }
        }
      }
    }
    return (displayLabelFields);
  }

  /**************************************************
   * Misc utilities...
   *************************************************/

  hasPreviousEvent(url: string): boolean {
    return(this._readOnlyOnceEvents.hasOwnProperty(url));
  }

  getPreviousEvent(url: string): WEvent {
    return(this._readOnlyOnceEvents[url]);
  }

  setPreviousEvent(url: string, event: WEvent): void {
    this._readOnlyOnceEvents[url] = event;
  }

  loadResourceFromServer(eh: string, parms: any, showAlertOnNullResponse?: boolean): Observable<WResource> {
    showAlertOnNullResponse = typeof showAlertOnNullResponse === 'boolean' ? showAlertOnNullResponse : true;
    const action = 'list'; // "list" forces an exact match. if we do "search", then we might get false positives...

    // console.log('loadResourceFromServer() - about to call ' + eh + '.' + action, parms, new Error().stack);

    return this.fireEvent(eh, action, parms).pipe(
      catchError(
        (error: any) => {
          console.log(error);

          // let errorMessage = '';
          // if (error.error instanceof ErrorEvent) {
          //   // Get client-side error
          //   errorMessage = error.error.message;
          // } else {
          //   // Get server-side error
          //   errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          // }

          // this.modalDialogService.showPleaseWait(false);
          // const title = 'Request for resource failed!';
          // const msg = eh + '.' + action + '(' + JSON.stringify(parms) + ')\n\nError: ' + errorMessage;
          // this.modalDialogService.showAlert(msg, title);

          return of<WResource>(null);
        }
      )
      , map (
        (event: WEvent) => {
          // console.log('loadResourceFromServer()', event);
          let res: WResource = null;
          if (event.status === 'OK') {
            if (event.resources && (event.resources.length === 1)) {
              // Good Event, found single resource...
              res = event.resources[0];
            } else if (event.resources && (event.resources.length > 1)) {
              // Good Event, bad result, multiple resources...
              const title = 'Too many matching resources!';
              console.log('loadResourceFromServer()', title, event);
              const msg = 'Found too many ' + eh + ' using ' + JSON.stringify(parms) + '\n\nNumber of returned resources: ' + event.resources.length;
              delete parms.pageSize;
              this._modalDialogService.showAlert(msg, title);
            } else {
              // Good Event, bad result, ZERO resources...
              if (showAlertOnNullResponse) {
                const title = 'Request for resource failed!';
                let msg = eh + '.' + action + '(' + JSON.stringify(parms) + ')\n\nError Message: ' + event.message;
                delete parms.pageSize;
                msg = 'Did not find any ' + eh + ' using ' + JSON.stringify(parms);
                this._modalDialogService.showAlert(msg, title);
              }
            }

           } else {
            // Bad Event...
            const title = 'Request for resource failed!';
            const msg = eh + '.' + action + '(' + JSON.stringify(parms) + ')\n\nError Message: ' + event.message;
            this._modalDialogService.showAlert(msg, title);
          }
          return res;
        }
      ), take(1)
    );
  }

  /******************************************************
   * File upload/download stuff...
   *****************************************************/

  downloadFile(eventHandler: string, action: string, parms: any, appName?: string): void {
    appName = typeof appName !== 'undefined' ? appName : Globals.thisApplication;

    let url = ((appName !== null) && (appName !== '') && (appName !== Globals.rootApplication) ? '/' + appName : '');
    url += '/EventServer/' + eventHandler + '/' + action;

    // console.log('requesting file: ' + url, 'w/parms: ' + JSON.stringify(parms);

    // show the 'please wait' dialog while the server is being called...
    this._modalDialogService.showPleaseWait(true, true);

    this._http.get(url, {
        params: parms,
        observe: 'response',
        responseType: 'blob'
       }
    // ).pipe(
    //   // this tap() is for debugging purposes only...
    //   tap(
    //     (response) => {
    //       console.log(response);
    //     }
    //   )
    )
    .subscribe(
        (response: any) => {
          this._modalDialogService.showPleaseWait(false);

          // console.log(response);

          // if we have a content-disposition header, then the file download succeeded...

          const contentDisposition: string = response.headers.get('content-disposition');

          if (contentDisposition) {

            // First, we get the fileName from the content-disposition header.
            // The server sends back a header that looks like this:
            // attachment; filename="wackadoo information systems.1.Usage.export.2019-11-08T08_58_50.770-0500.txt"
            const fileName = contentDisposition.replace(/attachment; filename=/g, '').replace(/"/g, '');

            // Second, we get the contentType from the content-type header.
            // The server sends back an array:
            // ["application/octet-stream"]
            const contentTypeHeader: string [] = response.headers.get('content-type');
              // or should we default to 'application/octet-stream' ?
            const contentType: string = (contentTypeHeader ? contentTypeHeader[0] : 'unknown');

            // Third, we create a Blob object with the file content from the response...
            const blob: any = new Blob([response.body], { type: contentType });

            // Lastly, we open a save-as dialog - which uses a 3rd party npm FileSaver package
            FileSaver.saveAs(blob, fileName);

          } else {

            // we DON'T have a content-disposition header, so the file download failed...
            // what we got back was a JSON formatted event...

            // console.log('response.body', response.body);

            const blob: any = new Blob([response.body], { type: 'application/json' });

            blob.text().then((jsonEvent: string) => {

              // console.log(jsonEvent);

              const eventWrapper = JSON.parse(jsonEvent);

              // console.log(eventWrapper);
              // console.log(eventWrapper.event);
              // console.log(eventWrapper.event.resource);
              // console.log(eventWrapper.event.resource.stack);
              // console.log(eventWrapper.event.resource.stack.text);

              const title = 'File download error';

              let msg = 'Something failed on the server while trying to execute ' + eventHandler + '.' + action + '()';
              msg += '\n\n';
              msg += 'event.status: ' + eventWrapper.event.status;
              msg += '\n';
              msg += 'event.message: ' + eventWrapper.event.message;
              msg += '\n';
              msg += 'event.resource.stack.text: ' + eventWrapper.event.resource.stack.text;

              this._modalDialogService.showAlert(msg, title);

            });

          }

      }
      , (error: any ) => {
          // console.log(error);
          this._modalDialogService.showPleaseWait(false);

          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
          } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          }

          const title = 'WARNING: File Download Failed!';
          const msg = 'Error Message: ' + errorMessage;
          this._modalDialogService.showAlert(msg, title);
      }
      , () => {
          this._modalDialogService.showPleaseWait(false);
          // const title = 'File Download Complete';
          // const msg = 'Check your browser for the downloaded file.';
          // this._modalDialogService.showAlert(msg, title);
      }
    );

  }

  saveResource(resource: WResource): Observable<WEvent> {
    const type = resource['@type'];
    if (!type) {
      throw new Error('Resource is not typed: ' + type);
    }
    const eh = this.getEventHandlerForResourceType(type);
    if (!eh) {
      throw new Error('Resource type (' + type + ') not associated with Resource Repository EventHandler');
    }

    if (resource.hasChangedFields()) {

      let action = 'modify';
      if (resource.keyField.isNull) {
        action = 'add';
      }

      const parms: any = resource.getChangedFieldValuesAsParms(action === 'modify');

      // console.log('EventHandlerService.saveResource() - eh: ' + eh + ', action: ' + action + ', parms:', parms);

      return this.fireEvent(eh, action, parms);

    } else {
      return of<WEvent>(null);
    }
  }

}
