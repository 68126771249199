import { Component, Input, OnInit, OnChanges, ElementRef } from '@angular/core';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { EventServerService } from '../../../services/event-server.service';
import { WResource } from '../../../data/resource.model';
import { FieldMode } from '../../../data/field/field-mode.model';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { WEvent } from '../../../data/event.model';
import { User } from 'src/app/client-core/data/user.model';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';

@Component({
  selector: 'wackadoo-detail-button-bar',
  templateUrl: './detail-button-bar.component.html',

})
export class DetailButtonBarComponent implements OnInit, OnChanges {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'DetailButtonBarComponent';

  @Input() eventHandler: string;
  @Input() action: string;
  @Input() parameters: any;
  @Input() resource: WResource;
  @Input() editMode: boolean;
  @Input() user: User;
  @Input() repositoryPageElementRef: ElementRef;

  public methods: string [];

  constructor(
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService
  ) {
  }

  ngOnInit(): void {
    // console.log('detail-button-bar.ngOnInit() = this.eventHandler: ' + this.eventHandler);
    this.methods = this.eventServerService.getMethods(this.eventHandler);
  }

  // When the inputs change, re-do the grid initialization.
  // This is needed because we moved the pageEvent logic inside the Router
  // processing and the page is already loaded/ngOnInit()-ed BEFORE the
  // event fires. So when the event-page sets this.eventHandler
  // AFTER the event returns, this component must be told to re-initialize.
  ngOnChanges(): void {
    this.ngOnInit();
  }

  get screenType(): ScreenType {
    return this.userInterfaceService.screenType.getValue();
  }

  save(): void {
    // This looks for the key field in the current resource.
    // In short, we do a "modify" if we have a valid key (i.e. it is present, populated and non-null)
    const addMode = !this.resource.keyField.isPopulated;

    // console.log(this.resource, this.resource.asParms, this.resource.getChangedFieldValuesAsParms(false));
    const badField = this.resource.identifyMissingOrInvalidField(addMode);
    if (badField) {
      this.modalDialogService.showAlert('Invalid field value or missing required field: ' + badField.name, 'Error');
    } else {

      // let msg = 'These are the parameters for the ' + (this.eventHandler + '.' + (addMode ? 'add' : 'modify')) + '() event we are about to fire...';
      // console.log(msg, this.resource.getChangedFieldValuesAsParms(!addMode));
      // msg += '\n\n' + JSON.stringify(this.resource.getChangedFieldValuesAsParms(!addMode));
      // this.modalDialogService.showAlert(msg, 'Debugging - Un-Comment The Real Code!');

      const debugResourceSave = this.userInterfaceService.getPageState(this.eventHandler, 'debugResourceSave');

      if (debugResourceSave) {
        console.log('save()', this.eventHandler, (addMode ? 'add' : 'modify'), this.resource, this.resource.asParms, this.resource.getChangedFieldValuesAsParms(!addMode));
      }

      // do the save here, then reload the page...

      this.modalDialogService.showPleaseWait(true);

      this.eventServerService.fireEvent(
          this.eventHandler,
          (addMode ? 'add' : 'modify'),
          this.resource.getChangedFieldValuesAsParms(!addMode)
        ).subscribe(
          (event: WEvent) => {

            this.modalDialogService.showPleaseWait(false);

            if (event.status === 'OK') {

                // re-set field mode...
                this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.read);

                // we re-load the resource from the server, and tell the user...

                const parms: any = {};
                parms[this.resource.keyField.name] = event.getParameter(this.resource.keyField.name);

                this.modalDialogService.showPleaseWait(true);
                this.eventServerService.loadResourceFromServer(this.eventHandler, parms).subscribe(
                  (resource2: WResource) => {
                    this.modalDialogService.showPleaseWait(false);

                    // old way...
                    // this.userInterfaceService.selectResource(this.eventHandler, resource2);
                    // this.userInterfaceService.reloadCurrentPage();

                    // new way...
                    this.userInterfaceService.loadPage(this.eventHandler, null, resource2.keyField.asParm);
                  }
                );

            } else {
              this.modalDialogService.showAlert('The save operation failed!\n\n' + event.message, 'WARNING');
              // console.log('The save operation failed!', event);
            }
          }
        );
    }
  }

  modify(): void {
    // console.log('detail-button-bar.modify() = this.eventHandler: ' + this.eventHandler);
    this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.write);
  }

  copy(): void {

    this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.write);

    // This leaves the key field in the current resource, when we really just want to delete
    // it. However, that caused other problems around the existence of the key field being assumed.
    // As a result, resource.keyField.asParms returns { keyField: null } instead of {},
    // so we hard code the null check in save() above...
    this.resource.keyField.value = null;
    this.resource.keyField.displayValue = 'creating new copy';

    // now, we flag all the fields as changed, so they will
    // ALL be added to the "new" resource in "save" above...
    this.resource.markAllFieldsAsChanged();

    // finally, we clear the "tracking" fields in the new resource so that we don't send them in...
    this.resource.created.value = null;
    this.resource.modified.value = null;
    this.resource.agent.value = null;
    this.resource.created.changed = false;
    this.resource.modified.changed = false;
    this.resource.agent.changed = false;

    const msg =
      'You are about to see a COPY of the ' + this.resource.getType() + ' that you were just looking at.'
      + '\n\nBe sure to change the values that you want to change before saving it as a NEW ' + this.resource.getType() + '.'
      // + '\n'
      // + '\n'
      // + JSON.stringify(this.resource.getChangedFieldValuesAsParms())
      ;

    this.modalDialogService.showAlert(msg, 'Please Note...');
  }

  remove(): void {

    this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.read);

    if (this.resource) {

      let relatedItems =  '';

      this.eventServerService.fireEvent(this.eventHandler, 'describeOnDeleteCascade', {}).subscribe(
        (tempE: WEvent) => {

          if (tempE.status === 'OK') {
            const resourceType = this.eventServerService.getResourceTypeForEventHandler(this.eventHandler);
            const description = tempE.getParameter('description');
            if (description && (description !== '') && (description !== resourceType)) {
              relatedItems = '\n\n<span class="bold">Please Note:</span> This will also delete related "orphaned" items of the following types, as appropriate:\n';
              relatedItems += '<span class="bold">' + description + '</span>.';
            }
          }

          const msg = 'You are about to DELETE the currently selected ' + this.resource.getType() + ': ' + this.resource.keyField.displayValue + '\n\nAre you sure?' + relatedItems;

          this.modalDialogService.showConfirm(msg, 'WARNING!').subscribe(
            (ok: boolean) => {
              if (ok) {
                const parms = this.resource.keyField.asParm;

                this.modalDialogService.showPleaseWait('Deleting resource' + relatedItems);

                this.eventServerService.fireEvent(this.eventHandler, 'delete', parms).subscribe(
                  (event: WEvent) => {
                    this.modalDialogService.showPleaseWait(false);
                    // console.log(eventhandler + '.delete(): ' + event.status + '\nmsg: ' + event.message);
                    if ((event !== null) && (event.status === 'OK')) {
                      this.userInterfaceService.unSelectResource(event.firstHandler, true);
                    } else {
                      // console.log(event.firstHandler + '.delete(): ' + JSON.stringify(event));
                      this.modalDialogService.showAlert('There was a problem deleting the resource: ' + event.message, 'WARNING');
                    }
                  }
                );
              }
            }
          );
        }
      );

    } else {
      this.modalDialogService.showAlert('You are unable to delete a resource until you have one selected.', 'Please select a resource...');
    }

  }

  cancel(): void {

    this.userInterfaceService.resourceDetailPageDisplayMode.next(FieldMode.read);

    const addMode =
      (this.resource.keyField === null)
      || (this.resource.keyField.value === null)
      || (this.resource.keyField.value === 'null')
      || (this.resource.keyField.value === '')
      || (this.resource.keyField.value <= 0)
    ;

    if (!this.editMode || addMode) {
      // we let the un-select trigger the proper navigation...
      this.userInterfaceService.unSelectResource(this.eventHandler, true);
    } else {
      this.userInterfaceService.reloadSelectedResourceFromServer(this.eventHandler);
    }
  }

}
