		<div class="card">
			<div class="card-header">
				Flexibility
			</div>
			<div class="card-body">
				<p>We live in an incremental delivery world, where "Listen and Adjust" wins out over "Stick To The Plan".</p>
				<p>Software delivery is more improvisational jazz than classical symphony. It's flexible enough to go in any direction at 
				any time, yet follows strict disciplines within which the musicians themselves exercise their creativity and expertise.</p>
				<p>If this sounds agile to you, then you heard correctly.</p>
				<p>Agile software development practices have matured our platform to the point where we are able to support 
				multiple applications, stand up new functionality quickly, and iteratively adjust as we learn more going forward.</p>
			</div>
		</div>
