<ul role="tablist" class="nav nav-pills scrollable" [ngClass]="{ 'flex-column heightViewPort90 smallish' : !showAsTabs, 'flex-row' : showAsTabs }" *ngIf="showMenu">
    <li class="nav-item" *ngFor="let menuItem of menuItems; let idx = index">
        <a  class="nav-link border smallish paddingTopQuarterEm paddingBottomQuarterEm paddingLeftHalfEm paddingRightHalfEm" 
            (click)="onClick($event, menuItem.name)"
            [ngClass]="{
                'active':activeItem === menuItem.name
                , 'left':!isSubMenuItem(menuItem.name)
                , 'indent smallish italic':isSubMenuItem(menuItem.name)
                , 'bg-light':(screenType !== 'fullscreen') && (activeItem !== menuItem.name)
                , 'darkgreen-bg':(screenType !== 'fullscreen') && (activeItem === menuItem.name)
            }" 
            role="tab" 
            aria-selected="false" 
            aria-disabled="false"
        >
            <span [ngClass]="{'white':activeItem === menuItem.name}">
                <span class="fas fa-{{ getPageIcon(menuItem.name) }}"></span>
                {{ menuItem.name }}
                <div *ngIf="(screenType !== 'fullscreen') && (hasSelectedItem(menuItem.name))">
                    <div class="small italic">
                        ({{selectedItem(menuItem.name)}})
                    </div>
                </div>
                <span class="fas fa-{{ subMenuOpen[menuItem.name] ? 'caret-up' : 'caret-down' }}" *ngIf="isSubMenu(menuItem.name)"></span>
            </span>
        </a>
    </li>
</ul>
