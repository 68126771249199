		<div class="card">
			<div class="card-header">
				E-Mail Usage Policy...
			</div>
			<div class="card-body">
				<strong>Our E-Mail To You...</strong>
				<p>
					Your account contact email address is the primary way that we communicate important 
					<span class="italic">administrative and operational</span> information with you. 
					When you sign up and create your account with us, you automatically opt-in to 
					receive <span class="italic">administrative and operational</span> email from us
					at that email address for as long as your account with us is active.
				</p>
				<p>
					Examples of this <span class="italic">administrative and operational</span> email 
					include, but are not limited to:
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						Receipts for credit card charges.
					</li>
					<li>
						Notices of planned server maintenance.
					</li>
					<li>
						Significant changes to web app functionality.
					</li>
					<li>
						Notice of pending changes in pricing or price model.
					</li>
					<li>
						Data backup files from expiring web apps.
					</li>
					<li>
						Various legal notifications, such as in the event of a data leak, 
						termination/suspension of service due to misuse of bulk email services,
						or any other notification required by law.
					</li>
				</ul>
				<p>
					When you create your account, your account contact information was also placed 
					in our <span class="italic">marketing</span> email list, from which you have 
					full opt-out capabilities.
				</p>
				<p>
					Per our Privacy/Confidentiality Policy, we do not share or sell either our account contact 
					information or our marketing contact information.
				</p>
				<strong>Your E-Mail To Your Mailing Lists...</strong>
				<p>
					Many of our web apps offer the ability to send bulk email to a mailing list that you have 
					created within that application. Your use of that mechanism is governed by numerous laws
					defining proper use of bulk email. By using our services, you have agreed to comply with 
					our acceptable Email Use Policy (below) in your use of the bulk email capabilities within 
					our web apps. (Note that this is the Acceptable EMail Use Policy of our bulk email provider,
					so this governs our overall email usage.)
				</p>
				<div class="well">
					<p>
						ACCEPTABLE EMAIL USE
					</p>
					<p>
						When sending an email, your recipient must be either someone with whom you have a personal or professional relationship, 
						or is a customer, member or subscriber of your business or organization, or who has specifically asked to receive your 
						emails by opting in or signing up in some way, or someone who has bought a product or service from you in the past 18 months.
					</p>
					<p>
						We have strict rules for sending to mailing lists. The following rules must be followed when sending to a mailing list:
					</p>
					<ul class="marginBottomWholeEm">
						<li>
							Lists must be 100% opt-in. This means that you must have obtained the permission of your recipients to send them emails. Examples of lists that are not allowed are purchased lists, email addresses scoured from the Internet, and marketing leads (including lists built via LinkedIn).
						</li>
						<li>
							In addition, lists must be up-to-date, and have had emails sent to them recently (within the previous 3 months). We closely monitor the percentage of bouncing email addresses and take action where necessary.
						</li>
						<li>
							An opt-out (unsubscribe) link must be placed at the bottom of each email which will allow the recipients to immediately remove themselves from the maillist.
						</li>
						<li>
							Emails must not contain content related to the industries mentioned below.
						</li>
					</ul>
					<p>
						You must also NOT use the service to send emails that contain content related to the following industries or activities. Such emails, even if 'opt-in', generate a high proportion of complaints and could jeopardise our service for all of our users.
					</p>
					<ul class="marginBottomWholeEm">
						<li>
							Pornographic or adult content
						</li>
						<li>
							Adult novelty items
						</li>
						<li>
							Dating or escort services
						</li>
						<li>
							Multi-level marketing
						</li>
						<li>
							Affiliate marketing
						</li>
						<li>
							Pharmaceutical products
						</li>
						<li>
							Keylogging
						</li>
						<li>
							Get-rich-quick, work-at-home schemes or paid surveys
						</li>
						<li>
							Online trading, forex trading, or stock market related content
						</li>
						<li>
							Gambling services, products or gambling education
						</li>
						<li>
							Online sweepstakes
						</li>
						<li>
							Credit repair, get-out-of-debt content
						</li>
						<li>
							Advertising your CV
						</li>
						<li>
							Mortgages and/or loans (without prior approval)
						</li>
						<li>
							Nutritional supplements, herbal supplements or vitamin supplements
						</li>
						<li>
							List brokers or list rental services
						</li>
						<li>
							Unsolicited marketing email (i.e. without proper permission from recipients)
						</li>
					</ul>
					<p>
						We have a strict policy against spamming. We forbid the use of the service to send unsolicited mass emails or unsolicited emails of any kind. We reserve the right to terminate your account "for cause" if we deem you to be in violation of our anti-spamming policies. We also reserve the right to suspend your account pending review upon receipt of any complaint or other evidence that you may be engaging in any spamming activity.
					</p>
					<p>
						You must also NOT use the service to a) knowingly send emails containing viruses, worms, or any other harmful code or software, b) send defamatory, harmful, abusive, vulgar or obscene content or material, c) send emails that are not in compliance with all applicable laws, or that do infringe upon the rights of any other party, including but not limited to copyrights, trademarks, privacy rights, moral rights, trade secrets, patents and any other rights d) harass or send threatening content.
					</p>
					<p>
						You must also NOT use the service in a manner that substantially reduces performance for other subscribers, send "mailbombs" or other harmful devices, or engage in activity designed to gain unauthorized access to any of our computers or information. 
					</p>
					<p>
						You must also NOT use the service to auto-forward emails originally sent by 3rd parties. You must have full control of the content and quality of emails sent.
					</p>
					<p>
						In the event that we deem you to be in violation of any of these policies, we shall immediately suspend your account and take further action as deemed necessary, including pursing legal action. 
					</p>
				</div>
			</div>
		</div>
