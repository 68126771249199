import { Directive, HostListener, OnInit, OnDestroy } from '@angular/core';
import { ScreenType } from '../services/screen-type.enum';
import { UserInterfaceService } from '../services/user-interface.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[wackadooMenuCloser]'
})
export class MenuCloserDirective implements OnInit, OnDestroy {

  @HostListener('click', ['$event']) click = this.closeMenus;
  @HostListener('focus', ['$event']) focus = this.closeMenus;

  screenType: ScreenType = null;
  screenTypeSubscription: Subscription = null;

  constructor(
    public userInterfaceService: UserInterfaceService,
  ) {
  }

  ngOnInit(): void {
    this.screenType = this.userInterfaceService.screenType.getValue();

    this.screenTypeSubscription = this.userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        this.screenType = screenType;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.screenTypeSubscription) {
      this.screenTypeSubscription.unsubscribe();
    }
  }

  closeMenus(): void {
    if (this.screenType !== ScreenType.fullscreen) {
      this.userInterfaceService.hideSideNavMenu();
      this.userInterfaceService.hideBannerMenu();
    }
  }

}
