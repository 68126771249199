import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-policy-confidentiality',
  templateUrl: './policy-confidentiality.component.html',

})
export class PolicyConfidentialityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
