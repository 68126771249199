<div *ngIf="visible">
    <div class="modal-backdrop" (click)="onCancel()"></div>
    <div class="modal-box">
        <wackadoo-chart-block
            [wChartJSON]="wChartJSON"
        ></wackadoo-chart-block>
        <div class="marginTopWholeEm floatGroup">
            <div class="floatRight">
                <button class="btn btn-secondary paddingLeftWholeEm paddingRightWholeEm marginRightWholeEm" (click)="onCancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
