<div class="marginTopHalfEm row">
  <div class="col-sm-3 nowrap">
    <span class="fas fa-{{ app.appIcon.value}}"></span>
    <wackadoo-field [f]="app.appName" mode="read"></wackadoo-field>
    <span class="italic" *ngIf="app.status.value !== 'released'">({{app.status.value}})</span>
  </div>

  <div class="col-sm-3">
    <div *ngIf="regApp">
      <div *ngIf="regApp.expiration && regApp.expiration.value ; else noExpiration">
        expires <wackadoo-field [f]="regApp.expiration" mode="read"></wackadoo-field>
      </div>
      <ng-template #noExpiration>
        <div>
          (no expiration)
        </div>
      </ng-template>
      <div *ngIf="regApp.discount && regApp.discount.value">
        discount: <wackadoo-field [f]="regApp.discount" mode="read"></wackadoo-field>
      </div>
      <div *ngIf="regApp.donation && regApp.donation.value">
        donation
      </div>
    </div>
    <div *ngIf="!regApp && appPrice">
      {{ appPrice.priceModel.value === 'per month' ? 'No subscription' : 'Not purchased' }}
    </div>
  </div>

  <div class="col-sm-3">
    <span *ngIf="appPrice">
      <wackadoo-field [f]="appPrice.price" mode="read"></wackadoo-field>
      <span>
        ({{appPrice.priceModel.value}})
      </span>
      <div class="small count" *ngIf="regApp && regApp.count && (appPrice.priceModel.value === 'per event')">
        (total purchased: {{ regApp.count.value }})
      </div>
    </span>
  </div>

  <div class="col-sm-3">
    <span  *ngIf="appPrice">
      <span *ngIf="user.isMemberOnly">
        <span *ngIf="creditCardIsOnFile">
          <span *ngIf="appPrice.priceModel.value === 'fixed price' ; else purchaseApp">
            (already purchased)
          </span>
          <ng-template #purchaseApp>
            <button type="button" class="btn btn-secondary" (click)="purchaseApplication()" [disabled]="regApp && (appPrice.priceModel.value !== 'per event')">
              <span *ngIf="appPrice.priceModel.value === 'per month'">Subscribe</span>
              <span *ngIf="appPrice.priceModel.value !== 'per month'">Purchase</span>
            </button>
          </ng-template>
        </span>
        <span *ngIf="appPrice.priceModel.value === 'per month'">
          <button type="button" class="btn btn-secondary" (click)="cancelApplication()" [disabled]="!(regApp && isActive)">Cancel</button>
        </span>
      </span>
      <span *ngIf="user.isManagerOrAbove">
        <button type="button" class="btn btn-secondary" (click)="billTheAccount()">Bill Account Manually</button>
      </span>
    </span>
  </div>

</div>
