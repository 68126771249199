import { Component, OnInit, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { EncryptedComponent } from '../encrypted/encrypted.component';

@Component({
  selector: 'wackadoo-field-password',
  templateUrl: './password.component.html',

})
export class PasswordComponent extends EncryptedComponent implements OnInit, OnDestroy {

  showCommonPasswordWarning = false;

  onBlurEventListenerFunction: any;

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    elementRef: ElementRef,
  ) {
    super(renderer, modalDialogService, elementRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onInputChange(e: any): any {
    this.showCommonPasswordWarning = this.isCommonPasswordTopology(e.target.value);
    return super.onInputChange(e);
  }

  onFocusMainInput(): void {
    super.onFocusMainInput();
    this.showCommonPasswordWarning = false;
  }

  isCommonPasswordTopology(pw: string): boolean {

    const topologies = [
      /[A-Z]{1}[a-z]{5}[0-9]{2}/
      , /[A-Z]{1}[a-z]{6}[0-9]{2}/
      , /[A-Z]{1}[a-z]{3}[0-9]{4}/
      , /[a-z]{7}[0-9]{1}/
      , /[A-Z]{1}[a-z]{7}[0-9]{2}/
      , /[A-Z]{1}[a-z]{6}[0-9]{1}/
      , /[A-Z]{1}[a-z]{5}[0-9]{4}/
      , /[A-Z]{1}[a-z]{4}[0-9]{4}/
      , /[a-z]{6}[0-9]{2}/
      , /[A-Z]{1}[a-z]{7}[0-9]{1}/
      , /[A-Z]{1}[a-z]{4}[0-9]{3}/
      , /[A-Z]{1}[a-z]{2}[0-9]{4}([\W]|[_]){1}/
      , /[a-z]{8}/
      , /[A-Z]{1}[a-z]{5}[0-9]{3}/
      , /[a-z]{7}[0-9]{2}/
      , /[a-z]{2}([\W]|[_]){1}[0-9]{2}[a-z]{1}[0-9]{2}[a-z]{1}/
      , /[a-z]{8}[0-9]{1}/
      , /[A-Z]{1}[a-z]{5}[0-9]{2}([\W]|[_]){1}/
      , /[A-Z]{1}[a-z]{6}[0-9]{4}/
      , /[A-Z]{1}[a-z]{8}[0-9]{2}/
      , /[A-Z]{1}[a-z]{5}[0-9]{1}([\W]|[_]){1}/
      , /[A-Z]{1}[a-z]{8}[0-9]{1}/
      , /[A-Z]{1}[a-z]{5}[0-9]{4}([\W]|[_]){1}/
      , /[a-z]{9}/
      , /[a-z]{8}[0-9]{2}/
      , /[A-Z]{1}[a-z]{6}[0-9]{3}/
      , /[a-z]{5}[0-9]{3}/
      , /[A-Z]{1}[a-z]{3}[0-9]{4}([\W]|[_]){1}/
      , /[A-Z]{1}[a-z]{7}[0-9]{4}/
      , /[A-Z]{1}[a-z]{5}([\W]|[_]){1}[0-9]{2}/
      , /[A-Z]{6}[0-9]{1}[a-z]{1}/
      , /[a-z]{4}[0-9]{4}/
      , /[0-9]{2}[A-Z]{1}[a-z]{7}/
      , /[A-Z]{1}[a-z]{2}([\W]|[_]){1}[0-9]{4}/
      , /[A-Z]{1}[a-z]{4}[0-9]{2}([\W]|[_]){1}/
      , /[A-Z]{1}[a-z]{6}[0-9]{1}([\W]|[_]){1}/
      , /[0-9]{2}[A-Z]{1}[a-z]{6}/
      , /[a-z]{4}([\W]|[_]){1}[0-9]{3}/
      , /[a-z]{9}[0-9]{1}/
      , /[a-z]{5}[0-9]{4}/
      , /[a-z]{10}/
      , /[a-z]{6}[0-9]{3}/
      , /[A-Z]{1}[a-z]{9}[0-9]{2}/
      , /[A-Z]{1}[a-z]{9}[0-9]{1}/
      , /[0-9]{6}[A-Z]{1}[a-z]{1}/
      , /[A-Z]{1}[a-z]{7}[0-9]{3}/
      , /[A-Z]{1}[a-z]{6}[0-9]{2}([\W]|[_]){1}/
      , /[A-Z]{6}[0-9]{1}([\W]|[_]){1}/
      , /[A-Z]{2}[0-9]{1}[a-z]{3}[0-9]{3}[A-Z]{1}/
      , /[A-Z]{1}[a-z]{4}([\W]|[_]){1}[0-9]{2}/
      , /[A-Z]{1}[a-z]{5}([\W]|[_]){1}[0-9]{1}/
      , /[a-z]{3}([\W]|[_]){1}[0-9]{4}/
      , /[a-z]{6}[0-9]{4}/
      , /[A-Z]{1}[a-z]{7}[0-9]{2}([\W]|[_]){1}/
      , /[0-9]{2}[A-Z]{1}[a-z]{5}/
      , /[A-Z]{1}[a-z]{7}[0-9]{1}([\W]|[_]){1}/
      , /[A-Z]{1}[a-z]{4}[0-9]{3}([\W]|[_]){1}/
      , /[A-Z]{1}[a-z]{4}[0-9]{4}([\W]|[_]){1}/
      , /[A-Z]{1}[a-z]{3}([\W]|[_]){1}[0-9]{4}/
      , /[A-Z]{1}[a-z]{4}([\W]|[_]){1}[0-9]{3}/
      , /[A-Z]{1}[a-z]{6}[0-9]{4}([\W]|[_]){1}/
      , /[A-Z]{1}[a-z]{3}[0-9]{3}([\W]|[_]){1}/
      , /[a-z]{4}([\W]|[_]){1}[0-9]{4}/
      , /[a-z]{6}([\W]|[_]){1}[0-9]{2}/
      , /[a-z]{6}[0-9]{2}([\W]|[_]){1}/
      , /[0-9]{4}[A-Z]{1}[a-z]{3}/
      , /[0-9]{8}/
      , /[A-Z]{1}[a-z]{6}([\W]|[_]){1}[0-9]{1}/
      , /[A-Z]{1}[a-z]{1}[0-9]{6}/
      , /[a-z]{6}([\W]|[_]){1}[0-9]{1}/
      , /[A-Z]{1}[0-9]{1}[a-z]{7}[0-9]{1}/
      , /[a-z]{10}[a-z]{1}/
      , /[a-z]{10}[0-9]{1}/
      , /[a-z]{5}[0-9]{2}([\W]|[_]){1}/
      , /[a-z]{4}[0-9]{3}([\W]|[_]){1}/
      , /[A-Z]{1}[a-z]{8}[0-9]{4}/
      , /[A-Z]{8}/
      , /[A-Z]{1}[a-z]{3}([\W]|[_]){1}[0-9]{3}/
      , /[A-Z]{1}[a-z]{6}([\W]|[_]){1}[0-9]{2}/
      , /[A-Z]{1}[a-z]{5}[0-9]{3}([\W]|[_]){1}/
      , /[a-z]{5}([\W]|[_]){1}[0-9]{2}/
      , /[A-Z]{1}[a-z]{4}([\W]|[_]){1}[0-9]{4}/
      , /[A-Z]{1}[a-z]{3}[0-9]{5}/
      , /[A-Z]{1}[a-z]{2}[0-9]{6}/
      , /[A-Z]{1}[a-z]{2}[0-9]{5}/
      , /[a-z]{9}[0-9]{2}/
      , /[a-z]{7}[0-9]{2}([\W]|[_]){1}/
      , /[a-z]{7}[0-9]{3}/
      , /[a-z]{6}[0-9]{1}([\W]|[_]){1}/
      , /[a-z]{3}[0-9]{4}([\W]|[_]){1}/
      , /[A-Z]{3}[a-z]{3}[0-9]{4}/
      , /[A-Z]{1}[a-z]{5}([\W]|[_]){1}[0-9]{3}/
      , /[A-Z]{1}[a-z]{7}([\W]|[_]){1}[0-9]{1}/
      , /[a-z]{8}([\W]|[_]){1}[0-9]{1}/
      , /[a-z]{7}[0-9]{4}/
      , /[A-Z]{1}[a-z]{5}([\W]|[_]){1}[0-9]{4}/
      , /[a-z]{7}[0-9]{1}([\W]|[_]){1}/
      , /[a-z]{4}[0-9]{4}([\W]|[_]){1}/
      , /[0-9]{4}[A-Z]{1}[a-z]{4}/
      , /[A-Z]{2}[0-9]{1}[a-z]{3}[0-9]{4}/
    ];

    for (const topo of topologies) {
      if (pw.search(topo) > -1) {
        return true;
      }
    }
    return false;
  }

}
