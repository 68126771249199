<div [ngClass]="{smallish:(screenType === 'phone')}" *ngIf="app && appPrice">
  <div [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
    <div>
      <div class="card extraLarge" *ngIf="app && appPrice">
        <div class="card-header">
            {{ app.appName.value }}
            <span *ngIf="app.status.value !== 'released'">
              ({{ app.status.value }})
            </span>
        </div>
        <div class="card-body">
          <div>
            <span class="green bold">${{ appPrice.price.value }}  {{ appPrice.priceModel.value }}</span>
            &nbsp;
            <span class="small">(USD)</span>
          </div>
          <div class="tiny italic no-wrap" *ngIf="appPrice.priceModel.value === 'per month'">
            New customers get a 14 day free trial period!
          </div>
        </div>
      </div>
    </div>
    <div class="marginTopHalfEm marginLeftWholeEm marginRightWholeEm">
      <div [ngClass]="{marginTopWholeEm:(screenType !== 'phone')}" *ngIf="appPrice.priceModel.value !== 'per month'">
        When you click on "Submit", we will create your account, set up your login, and bill your credit card.
      </div>
      <div [ngClass]="{marginTopWholeEm:(screenType !== 'phone')}" *ngIf="appPrice.priceModel.value === 'per month'">
        When you click on "Submit", we will create your account, set up your login, and register your credit card.
        <br/>
        If you are a new customer, you will have 14 days to try out the web application for free. After the free trial period is up, your credit card will be charged a pro-rated amount for the remainder of the month.
        <br/>
        Then you join the regular monthly billing cycle.
      </div>
      <div class="italic marginTopHalfEm">
        {{ theBigOptIn }}
      </div>
      <div class="marginTopHalfEm">
        Please note:  
        <ul>
          <li>
            <span class="bold">All</span> fields are required, but you will be able to modify
            any of this information later, after your account has been created.
          </li>
          <li *ngIf="appPrice.priceModel.value === 'per month'">
            You can cancel your subscription, or update your registered credit card, at any time (even during the 14 day free trial period) on the Billing page.
          </li>
        </ul> 
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      Please enter information to create your account.
    </div>
    <div class="card-body">
      <div  [ngClass]="{smallish:(screenType !== 'phone'), 'd-flex justify-content-around':(screenType !== 'phone')}">
        <div class="">
          <div class="card">
            <div class="card-header">
              Account Identification
            </div>
            <div class="card-body">
              <div class="">
                Your organization name
              </div>
              <div class="">
                <wackadoo-field [f]="resource.accountName" [mode]="mode"></wackadoo-field>
              </div>
              <div class="">
                  Your server prefix (i.e. ____.{{ domain }})
              </div>
              <div class="">
                  <wackadoo-field [f]="resource.accountHostName" [mode]="mode"></wackadoo-field>
              </div>
              <div class="marginLeftHalfEm smallish italic">
                (Your server prefix may contain letters, numbers and dashes. A dash may not be the first or last character. No other punctuation or special characters are allowed.)
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              Account Contact Person
            </div>
            <div class="card-body table-responsive">
              <div class="d-flex">
                <div class="">
                  <wackadoo-field [f]="resource.accountContactFirstName" [mode]="mode"></wackadoo-field>
                </div>
                <div class="marginLeftHalfEm">
                  <wackadoo-field [f]="resource.accountContactLastName" [mode]="mode"></wackadoo-field>
                </div>
              </div>
              <div class="d-flex">
                <div class="">
                  <wackadoo-field [f]="resource.accountAddress" [mode]="mode"></wackadoo-field>
                </div>
              </div>
              <div class="d-flex">
                <div class="">
                  <wackadoo-field [f]="resource.accountCity" [mode]="mode"></wackadoo-field>
                </div>
                <div class="marginLeftHalfEm">
                  <wackadoo-field [f]="resource.accountState" [mode]="mode"></wackadoo-field>
                </div>
                <div class="marginLeftHalfEm">
                  <wackadoo-field [f]="resource.accountPostalCode" [mode]="mode"></wackadoo-field>
                </div>
              </div>
              <div class="d-flex">
                <div class="">
                  <wackadoo-field [f]="resource.accountCountry" [mode]="mode"></wackadoo-field>
                </div>
              </div>
              <div class="d-flex">
                <div class="">
                  <wackadoo-field-phone [f]="resource.accountPhone" [mode]="mode" [forceUSAreaCodePlus7Number]="true"></wackadoo-field-phone>
                </div>
              </div>
              <div class="d-flex">
                <div class="">
                  <wackadoo-field [f]="resource.accountEmail" [mode]="mode"></wackadoo-field>
                  <span class="hidden">(validated: <wackadoo-field [f]="resource.accountEmailValidated" [mode]="mode"></wackadoo-field>)</span>
                </div>
              </div>
            </div>
          </div>
          <div [ngClass]="{'d-flex justify-content-around':(screenType !== 'phone')}">
            <div class="">
              <div class="card">
                <div class="card-header">
                  Account Administrator Login
                </div>
                <div class="card-body table-responsive">
                  <div class="d-flex">
                    <div>
                      <div class="no-wrap">
                        user name
                      </div>
                      <div class="">
                        password
                      </div>
                    </div>
                    <div class="marginLeftHalfEm">
                      <div>
                        <wackadoo-field [f]="resource.userName" [mode]="mode"></wackadoo-field>
                      </div>
                      <div class="marginLeftHalfEm">
                        <wackadoo-field [f]="resource.password" [mode]="mode"></wackadoo-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="resource.captchaQuery">
              <div class="card">
                  <div class="card-header">CAPTCHA</div>
                  <div class="card-body">
                      <div class="d-flex justify-content-around">
                          <div class="center nowrap">{{ resource.captchaQuery.value }}</div>
                          <div class="width-5-em marginLeftHalfEm" title="Provide numeric answer (e.g. 42, -37, etc.)">
                              <wackadoo-field-whole-number [f]="resource.captchaAnswer" [mode]="mode" [size]="5"></wackadoo-field-whole-number> 
                          </div>
                      </div>
                      <div class="center marginTopQuarterEm">
                          <button type="button" class="btn btn-secondary refresh" (click)="refreshCaptcha()"><span class="fas fa-sync" aria-hidden="true"></span> Refresh</button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="public-only">
          <div class="card">
            <div class="card-header lightblue-bg">
              Credit Card Information
            </div>
            <div class="card-body">
              <div class="marginBottomHalfEm">
                <a class="link" (click)="toggle()" [attr.aria-expanded]="!isCollapsed">
                  Click on this link to learn about how we protect your credit card...
                  <span *ngIf="!isCollapsed">
                    (or to close this when done reading...)
                  </span>
                </a>
              </div>
              <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                <div class="well lightblue-bg marginWholeEm">
                  The credit card input section below is a "drop-in" form from our credit card service provider 
                  <a href="https://www.braintreepayments.com/" target="_blank">Braintree Payments (A PayPal company)</a>  
                  that acts like a tunnel directly to their servers.  We do not look at or store the credit 
                  card information that you enter below, which goes straight to them. After that, all we have access to
                  is a partial, non-identifying description of your saved credit card to display on the Billing page.
                </div>
              </div>
              <div id="payment-form"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
