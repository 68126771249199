import { Component, OnInit } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';
import { Globals } from 'src/app/client-core/services/global.service';
import { WResource } from 'src/app/client-core/data/resource.model';
import { WEvent } from 'src/app/client-core/data/event.model';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WString, WInteger } from 'src/app/client-core/data/field.model';
import { AccountManagerService } from 'src/app/account-manager-core/account-manager.service';

@Component({
  selector: 'wackadoo-new-account-detail-content',
  templateUrl: './new-account-detail-content.component.html',
})
export class NewAccountDetailContentComponent extends DetailContentComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'NewAccountDetailContentComponent';

  domain = Globals.domain;

  clientToken: string = null;
  creditCard: WResource = null;

  theBigOptIn = '(' + Globals.theBigOptIn + ')';

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    public accountManagerService: AccountManagerService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // generate the CAPTCHA...

    this.eventServerService.fireEvent('NewCustomer', 'generate', {}).subscribe(
      (result: WEvent) => {
        // console.log(result);

        if (result.status === 'OK') {
          const captchaQuery = result.getParameter('captchaQuery');
          const captchaKey = result.getParameter('captchaKey');

          let f = new WString('captchaQuery', captchaQuery);
          this.resource.addExtraField(f);

          f = new WInteger('captchaAnswer', null);
          f.required = true;
          this.resource.addExtraField(f);

          f = new WString('captchaKey', captchaKey);
          f.changed = true;
          this.resource.addExtraField(f);

          // console.log('captchaQuery: ' + captchaQuery + ', captchaKey: ' + captchaKey);

        } else {
          this.modalDialogService.showAlert('Unable to generate CAPTCHA!\n' + result.message, 'Error!');
        }
      }
    );

  }

  refreshCaptcha(): void {
    // console.log('NewAccount.refreshCaptcha()');
    this.eventServerService.fireEvent('NewCustomer', 'generate', null).subscribe(
      (result: WEvent) => {
        // console.log('NewAccount.refreshCaptcha() - result: ' + JSON.stringify(result));

        if (result.status === 'OK') {
          const captchaQuery = result.getParameter('captchaQuery');
          const captchaKey = result.getParameter('captchaKey');

          this.resource.captchaQuery.value = captchaQuery;

          this.resource.captchaKey.value = captchaKey;
          this.resource.captchaKey.changed = true;

          this.resource.captchaAnswer.value = null;

        } else {
          this.modalDialogService.showAlert('Unable to generate CAPTCHA!\n' + result.message, 'Error!');
        }
      }
    );
  }

}
