import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EditorModule } from '@tinymce/tinymce-angular';

// common app stuff...
import { ClientCoreModule } from '../client-core/client-core.module';

// routing stuff
import { AccountManagerCoreRoutingModule } from './account-manager-core-routing.module';

// services, etc.
import { BusinessRules } from 'src/app/client-core/services/business-rules.model';
import { BusinessRuleService } from 'src/app/client-core/services/business-rule.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';

// the unique stuff for AccountManager
import { AccountManagerApplicationHeaderComponent } from './ui/application-header/account-manager-application-header.component';
import { BillingComponent } from './pages/billing/billing.component';
import { EMailErrorWebHookComponent } from './pages/email-error-web-hook/email-error-web-hook.component';
import { AdministrationComponent } from './pages/administration/administration.component';
import { EventBroadcasterComponent } from './pages/event-broadcaster/event-broadcaster.component';
import { LogExtractorComponent } from './pages/log-extractor/log-extractor.component';
import { BackupCleanupServiceComponent } from './pages/backup-cleanup-service/backup-cleanup-service.component';
import { LogCleanupServiceComponent } from './pages/log-cleanup-service/log-cleanup-service.component';
import { DbConnectionPoolComponent } from './pages/db-connection-pool/db-connection-pool.component';
import { RegAppBillingControlComponent } from './pages/billing/reg-app-billing-control/reg-app-billing-control.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { AccountDetailContentComponent } from './pages/accounts/account-detail-content/account-detail-content.component';
import { UsersComponent } from './pages/users/users.component';
import { UserDetailContentComponent } from './pages/users/user-detail-content/user-detail-content.component';
import { UserDetailButtonBarComponent } from './pages/users/user-detail-button-bar/user-detail-button-bar.component';
import { RegisteredApplicationsComponent } from './pages/registered-applications/registered-applications.component';
import { RegAppDetailContentComponent } from './pages/registered-applications/reg-app-detail-content/reg-app-detail-content.component';
import { RegAppComponent } from './pages/registered-applications/reg-app/reg-app.component';
import { RolesComponent } from './pages/roles/roles.component';
import { AppRoleComponent } from './pages/roles/app-role/app-role.component';
import { NewCustomerComponent } from './pages/new-customer/new-customer.component';
import { NewAccountDetailContentComponent } from './pages/new-customer/new-account-detail-content/new-account-detail-content.component';
import { NewAccountDetailButtonBarComponent } from './pages/new-customer/new-account-detail-button-bar/new-account-detail-button-bar.component';
import { NewCustomerNotValidatedComponent } from './pages/new-customer/new-customer-not-validated/new-customer-not-validated.component';
import { NewCustomerCancelledComponent } from './pages/new-customer/new-customer-cancelled/new-customer-cancelled.component';
import { NewCustomerNotCancelledComponent } from './pages/new-customer/new-customer-not-cancelled/new-customer-not-cancelled.component';
import { NewCustomerDetailContentComponent } from './pages/new-customer/new-customer-detail-content/new-customer-detail-content.component';
import { NewCustomerDetailButtonBarComponent } from './pages/new-customer/new-customer-detail-button-bar/new-customer-detail-button-bar.component';
import { NewCustomerGridButtonBarComponent } from './pages/new-customer/new-customer-grid-button-bar/new-customer-grid-button-bar.component';


@NgModule({
  declarations: [
    // AccountManager stuff
    AccountManagerApplicationHeaderComponent,
    BillingComponent,
    EMailErrorWebHookComponent,
    AdministrationComponent,
    EventBroadcasterComponent,
    LogExtractorComponent,
    BackupCleanupServiceComponent,
    LogCleanupServiceComponent,
    DbConnectionPoolComponent,
    RegAppBillingControlComponent,
    PricingComponent,
    AccountsComponent,
    AccountDetailContentComponent,
    UsersComponent,
    UserDetailContentComponent,
    UserDetailButtonBarComponent,
    RegisteredApplicationsComponent,
    RegAppDetailContentComponent,
    RegAppComponent,
    RolesComponent,
    AppRoleComponent,
    NewCustomerComponent,
    NewAccountDetailContentComponent,
    NewAccountDetailButtonBarComponent,
    NewCustomerNotValidatedComponent,
    NewCustomerCancelledComponent,
    NewCustomerNotCancelledComponent,
    NewCustomerDetailContentComponent,
    NewCustomerDetailButtonBarComponent,
    NewCustomerGridButtonBarComponent,
  ],
  imports: [
    // Angular stuff...
    CommonModule,
    HttpClientModule,
    FormsModule,

    // 3rd party stuff everybody uses...
    NgbModule,
    FontAwesomeModule,
    ChartsModule,
    EditorModule,

    // core client stuff
    ClientCoreModule,
    AccountManagerCoreRoutingModule,

  ],
  exports: [
    AccountManagerApplicationHeaderComponent,
    BillingComponent,
    EMailErrorWebHookComponent,
    AdministrationComponent,
    EventBroadcasterComponent,
    LogExtractorComponent,
    BackupCleanupServiceComponent,
    LogCleanupServiceComponent,
    DbConnectionPoolComponent,
    RegAppBillingControlComponent,
    PricingComponent,
    AccountsComponent,
    AccountDetailContentComponent,
    UsersComponent,
    UserDetailContentComponent,
    UserDetailButtonBarComponent,
    RegisteredApplicationsComponent,
    RegAppDetailContentComponent,
    RegAppComponent,
    RolesComponent,
    AppRoleComponent,
    NewAccountDetailContentComponent,
    NewAccountDetailButtonBarComponent,
    NewCustomerNotValidatedComponent,
    NewCustomerCancelledComponent,
    NewCustomerNotCancelledComponent,
    NewCustomerDetailContentComponent,
    NewCustomerDetailButtonBarComponent,
  ],
  entryComponents: [
    AccountManagerApplicationHeaderComponent,
    AccountDetailContentComponent,
    UserDetailContentComponent,
    UserDetailButtonBarComponent,
    RegAppDetailContentComponent,
    RegAppComponent,
    AppRoleComponent,
    NewAccountDetailContentComponent,
    NewAccountDetailButtonBarComponent,
    NewCustomerDetailContentComponent,
    NewCustomerDetailButtonBarComponent,
    NewCustomerGridButtonBarComponent,
  ]
})
export class AccountManagerCoreModule {

  constructor(
    private _businessRuleService: BusinessRuleService,
    // The only reason we included UserInterfaceService here is that it
    // HAS to be loaded PRIOR to the initial page load in order to catch
    // the NavigationStart routing event for that initial page load
    public userInterfaceService: UserInterfaceService,
  ) {
    this.loadBusinessRules();
  }

  loadBusinessRules(): void {

    const rules: BusinessRules = {

        purchaserRole: 'member',

        firstEHPage: 'Users',

        defaultPageSizes: {
            Applications: 10
        },

        defaultActions : {
        },

        otherMenuItems: [
            'Pricing'
        ],

        otherPublicPages: [
          'ValidateAccountEmail'
        ],

        menuRules: [
          {
            _selection: 'Users',
            _role: 'member-',

            Accounts :  { role: 'member+'}
            , Billing :  { role: 'member+'}
            , RegisteredApplications :  { role: 'member+'}
            , Users :  { role: 'authorized+'}
            , Roles :  { role: 'authorized+'}
            , Reports : { role: 'guest+'}
          }
          ,  {
            _selection: '*',
            _role: '*',

            'System Mgt' :  {
              subMenu: {
                Administration :  { role: 'staff+'}
                , EventBroadcaster :  { role: 'staff+'}
                , NewCustomer :  { role: 'staff+'}
                , TransactionLog :  { role: 'staff+'}
                , Usage :  { role: 'staff+'}
                , BackupRestore :  { role: 'staff+'}
              }
            }
            , 'Application Mgt' :  {
              subMenu: {
                Applications :  { role: 'staff+'}
                , ApplicationMetaData :  { role: 'staff+'}
                , AppPricing :  { role: 'staff+'}
              }
            }
            , Accounts :  { role: 'member+'}
            , Billing :  { role: 'member+'}
            , RegisteredApplications :  { role: 'member+'}
            , Users :  { role: 'authorized+'}
            , Roles :  { role: 'authorized+'}
            , SMTPServers : { role: 'staff+'}
            , Reports : { role: 'guest+'}

            // Stuff we took off the menu...

            // , 'EMailErrorWebHook'
            // , 'DBConnectionPool'
            // , 'NewCustomerTruncator'
            // , 'SecurityServices'
            // , 'BackupService'
            // , 'SearchService'
            // , 'SearchEngineUpdater'
            // , 'ServerMonitor'
            // , 'CustomTemplates'
            // , 'SocketEventListener'
            // , 'UsageTruncator'
            // , 'BackupCleanupService'
            // , 'LogExtractor'
            // , 'LogCleanupService'
            // , 'ValidateUserEmail'
            // , 'ValidateAccountEmail'
            // , 'SystemEmailFormatter'
          }
        ],

        menuIcons: {
            Administration: 'cog'
            , SecurityServices: 'lock'
            , BackupService: 'save'
            , Reports: 'print'
            , CustomTemplates: 'file-alt'
            , BackupRestore:  'cloud'
            , ServerMonitor: 'server'
            , SearchService: 'search'
            , SearchEngineUpdater: 'redo'
            , Usage: 'signal'
            , LogExtractor: 'share-alt'
            , BackupCleanupService: 'trash'
            , LogCleanupService: 'trash-alt'
            , Broadcast: 'volume-up'
            , EMailTemplates: 'envelope-square'
            , ReportTemplates: 'file-alt'
            , ImportTemplates: 'file-upload'
            , ImportTemplateFields:  'file-import'
            , EMailer : 'envelope'
            , BulkEMailer: 'mail-bulk'
            , EMailErrors: 'exclamation-triangle'
            , SMTPServers: 'mail-bulk'

            // main app
            , Accounts: 'users'
            , Users: 'user'
            , Applications: 'window-restore'
            , RegisteredApplications: 'wrench'
            , Roles: 'user'
            , AppPricing: 'dollar-sign'
            , Pricing: 'dollar-sign'
            , Billing: 'credit-card'
            , NewCustomer: 'users-cog'
            , NewCustomerTruncator: 'cut'
            , DBConnectionPool: 'database'
            , EventBroadcaster: 'bullhorn'
            , TransactionLog: 'clipboard'
            , SocketEventListener: 'satellite-dish'
            , EMailErrorWebHook: 'satellite-dish'
            , UsageTruncator: 'cut'
            , ValidateUserEmail: 'user-check'
            , ValidateAccountEmail: 'user-check'
            , SystemEmailFormatter: 'mail-bulk'
            , ApplicationMetaData: 'comment-dots'

            , 'System Mgt': 'toolbox'
            , 'Application Mgt': 'tools'
        },

        serverRequestRules: [
        ],

        serverResponseRules: [
        ],

        readOnlyOnceRules: [
        ],

        menuClickRules: [
        ],

        foreignKeysThatMustBePopulated: {
        },

        importFormatRules: {
        },

        editableReportSystemTemplates: [
        ],

    };

    this._businessRuleService.loadRules('AccountManager', rules);
  }

}
