import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-value-flexibility',
  templateUrl: './value-flexibility.component.html',

})
export class ValueFlexibilityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
