import { Component, OnInit } from '@angular/core';
import { FieldComponent } from '../field.component';

@Component({
  selector: 'wackadoo-field-select',
  templateUrl: './select.component.html',

})
export class SelectComponent extends FieldComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
    // console.log('ngOnInit()', this.f.name + ' ' + this.f.value, this.f.default, this.f);
    if (this.f.value === null) {
      this.f.value = (this.f.default ? this.f.default : this.f.select.split('|')[0]);
      this.f.changed = true;
    }
  }

  valueMatches(opt: string): boolean {
    const flag = String(opt) === String(this.f.value);
    // console.log('valueMatches()', opt, this.f.value, flag);
    return flag;
  }

  getLabel(value?: any): any {
    value = typeof value !== 'undefined' ? value : this.f.value;
    const values = this.f.select.split('|');
    let label = value;
    if (this.f.selectLabels) {
      const labels = this.f.selectLabels.split('|');
      const idx = values.indexOf(String(value));
      // console.log('getLabel()', this.f.name, idx, value, values, labels);
      if (idx >= 0) {
        label = labels[idx];
      }
    }
    return label;
  }

  /**
   * This mimics the default method called for all input field changes
   */
  onSelectButtonChange(value: any): boolean {
    // console.log('this.f.isValidValue(cellInput.value): ' + this.f.isValidValue(value));

    if (this.f.isValidValue(value)) {
      // the new value passed validation...

      // console.log('cellInput.value: ' + cellInput.value, this.f.value, 'changed? ' + (cellInput.value !== this.f.value));

      if (value !== this.f.value) {

        const oldValue = this.f.value;
        this.f.value = value;
        this.f.changed = true;

        // console.log('value: ' + value, this.f.value, 'this.onChange exists? ' + (this.onChange !== null));
        if (this.onChange !== null) {
          // console.log('firing field.onChange.next()');
          this.f.oldValue = oldValue;
          this.onChange.next(this.f);
        }

      }
    }

    return false;
  }

}
