import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Subscription, Subscriber } from 'rxjs';
import { AlertContent } from './alert-content.model';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';

@Component({
  selector: 'wackadoo-alert',
  templateUrl: './alert.component.html',

})
export class AlertComponent implements OnInit, OnDestroy {

  visible = false;

  showModalSubscription: Subscription;

  message = '';
  title = '';
  observer: Subscriber<void>;

  screenType: ScreenType = null;

  constructor(
    private _modalDialogService: ModalDialogService,
    private _elementRef: ElementRef,
    private _userInterfaceService: UserInterfaceService,
  ) { }

  ngOnInit(): void {
    this.screenType = this._userInterfaceService.screenType.getValue();

    this.showModalSubscription = this._modalDialogService.showAlertSubject.subscribe(
      (alertContent: AlertContent) => {
        if (alertContent.message) {
          this.observer = alertContent.observer;
          this.message = alertContent.message.replace(/\\n/g, '\n').replace(/\n/g, '<br/>');
          this.title = alertContent.title;
          this.visible = true;

          const closeButton: HTMLButtonElement = this._elementRef.nativeElement.querySelector('button') as HTMLButtonElement;
          // console.log(closeButton);
          if (closeButton) {
            window.setTimeout(() => closeButton.focus(), 0);
          }
        }
      }
    );
  }

  ngOnDestroy(): void {
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }
  }

  onClose(): void {
    this.visible = false;
    this.message = '';
    this.title = '';
    this.observer.next();
  }

}
