		<div class="card">
			<div class="card-header">
				Support Policy...
			</div>
			<div class="card-body">
				<p>
					To contact support, email us at <a href="mailto:support@{{ domain }}">support@{{ domain }}</a>
				</p>
				<p>
					When you contact us with an issue, include your name, a call-back phone number, a description of what 
					you were trying to do, and what happened that you did not expect.
				</p>
				<p>
					We will get back to you as soon as we can.  We maintain standard business hours in the Eastern US Time Zone.
					That being said, and this being the software business, we do check our email outside of that time frame, and 
					will respond to service interruption or similar magnitude events as fast as we can.
				</p>
				<p>
					We will work with you to understand what you were doing and what happened.  If necessary, we will 
					try to duplicate the issue using test data in a test account with test users on a test server. 
					Failing that, other actions we might take include, but are not limited to, the following (un-ordered) list:
				</p>
				<ul class="marginBottomWholeEm">
					<li>
						Ask permission to use a snapshot of your account data with our test account with test users on our test server. 
					</li>
					<li>
						Ask permission to set up a temporary user in your account to see the issue on our production servers.
					</li>
					<li>
						Ask to use a desktop-sharing application (like Skype or GoToMeeting...) to see exactly what you are seeing.
					</li>
				</ul>
			</div>
		</div>
