import { Component, OnInit, OnDestroy } from '@angular/core';
import { DetailContentComponent } from '../../repository-page/detail-content/detail-content.component';
import { WField } from '../../../data/field.model';
import { Subject, Subscription } from 'rxjs';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { EventServerService } from '../../../services/event-server.service';
import { UserInterfaceService } from '../../../services/user-interface.service';

@Component({
  selector: 'wackadoo-report-template-detail-content',
  templateUrl: './report-template-detail-content.component.html',
})
export class ReportTemplateDetailContentComponent extends DetailContentComponent implements OnInit, OnDestroy {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ReportTemplateDetailContentComponent';

  public extensionChange = new Subject<WField>();
  public extensionChangeSubscription: Subscription;

  constructor(
    eventServerService: EventServerService,
    userInterfaceService: UserInterfaceService,
    private _modalDialogService: ModalDialogService,
  ) {
    super(userInterfaceService, eventServerService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.mode = this.userInterfaceService.resourceDetailPageDisplayMode.getValue();

    // console.log('ngOnInit()', this.resource.templateFileContent, this.resource.templateExtension);

    // IMPORTANT NOTE: wackadoo-field-binary uses the fileType attribute of the
    // binary field to properly display field contents in read and write mode...

    // First, properly initialize on when we don't have any file content at all...

    if ((!this.resource.templateFileContent.value)
        &&
        (
          (this.resource.templateExtension.value === null)
          || (this.resource.templateExtension.value === 'sql')
          || (this.resource.templateExtension.value === 'ghtml')
        )
    ) {
      this.resource.templateFileContent.fileType = this.resource.templateExtension.default;
    }

    // second, set up the extension field change subscription

    this.extensionChangeSubscription = this.extensionChange.subscribe(
      (f: WField) => {

        // console.log('onExtensionChange()', f, this.resource.templateFileContent);

        // By changing extensions, we change what file content is allowed.
        // So, if that changes, we need to clear out any previously existing file content.

        // if we don't have any file content, no worries. Simply set the fileType attribute...
        if (this.resource.templateFileContent.value === null) {
          this.resource.templateFileContent.fileType = f.value;
          this.resource.templateExtension.value = f.value;
        } else {
          // however, if we DO have previously existing file content, we
          // ask the user if they really want to clear out the old content...

          this._modalDialogService.showConfirm(
            'Do you REALLY want to do that?',
            'WARNING! You are about to clear the previously loaded file content!',
          ).subscribe(
            (choice: boolean) => {
              if (choice) {
                this.resource.templateFileContent.text = null;
                this.resource.templateFileContent.value = null;
                this.resource.templateFileContent.fileType = f.value;
                this.resource.templateExtension.value = f.value;
              } else {
                this.resource.templateExtension.value = f.oldValue;
              }
            }
          );
        }

      }
    );
  }

  ngOnDestroy(): void {
    if (this.extensionChangeSubscription) {
      this.extensionChangeSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

}
