<div class="marginTopHalfEm" [ngClass]="{'d-flex justify-content-center border-bottom':(screenType !== 'phone')}">
  <div class="" [ngClass]="{'border-right':(screenType !== 'phone')}">
    <div class="" [ngClass]="{'paddingHalfEm':(screenType !== 'phone')}">
      <div class="d-flex justify-content-between marginTop6 marginBottomHalfEm" >
        <button type="button" (click)="fireEvent('EventBroadcaster', 'backupAllDataArchives')" class="btn btn-secondary">Backup All DataArchives</button>
        <button type="button" (click)="fireEvent('EventBroadcaster', 'restoreAllDataArchives')" class="btn btn-secondary">Restore All DataArchives</button>
      </div>
    </div>
    <div class="" [ngClass]="{'paddingHalfEm border-top':(screenType !== 'phone')}">
      <div class="d-flex justify-content-between marginTop6 marginBottomHalfEm" >
        <button type="button" (click)="fireEvent('EventBroadcaster', 'forceIndexRebuild')" class="btn btn-secondary">Update Search Index</button>
        <button type="button" (click)="fireEvent('EventBroadcaster', 'forceCompleteIndexRebuild')" class="btn btn-secondary">Rebuild Search Index</button>
      </div>
    </div>
  </div>
  <div class="" [ngClass]="{'border-left':(screenType !== 'phone')}">
    <div class="" [ngClass]="{'paddingHalfEm':(screenType !== 'phone')}">
      <div class="d-flex justify-content-between marginTop6 marginBottomHalfEm" >
        <button type="button" (click)="fireEvent('EventBroadcaster', 'getStatistics')" class="btn btn-secondary">Get Statistics</button>
        <button type="button" (click)="fireEvent('EventBroadcaster', 'getStatus')" class="btn btn-secondary">Get Status</button>
      </div>
    </div>
    <div class="" [ngClass]="{'paddingHalfEm border-top':(screenType !== 'phone')}">
      <div class="d-flex justify-content-between marginTop6 marginBottomHalfEm" >
        <button type="button" (click)="fireEvent('EventBroadcaster', 'runBackupCleanupsService')" class="btn btn-secondary">Clean-up Backup Files</button>
        <button type="button" (click)="fireEvent('EventBroadcaster', 'runLogExtractor')" class="btn btn-secondary">Run Log Extractor</button>
        <button type="button" (click)="fireEvent('EventBroadcaster', 'runLogCleanupsService')" class="btn btn-secondary">Clean-Up Logs</button>
      </div>
    </div>
  </div>
  <div class="" [ngClass]="{'border-left':(screenType !== 'phone')}">
    <div class="" [ngClass]="{'paddingHalfEm':(screenType !== 'phone')}">
      <div class="d-flex flex-row marginTop6 marginBottomHalfEm" >
        <div>
          Application to operate on: 
        </div>
        <div class="marginLeftHalfEm">
          <wackadoo-field-foreign-key [f]="appID" mode="write" nullValueLabel="All Applications"></wackadoo-field-foreign-key>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card">
  <div class="card-body scrollable"
        wackadooMaximizeContentHeight
        maxHeightThingBelowSelector=".wackadoo-footer"
        [maxHeightFudgeFactor]="-12"
  >
    <div *ngIf="resources.length > 0">
      <ng-container *ngIf="screenType !== 'phone'">
        <table class="marginBottomQuarterEm">
          <tr class="border-bottom" *ngFor="let r of resources; let idx = index">
            <td class="right top">
              <div class="underline smallish">
                {{ r.getApplication() }}
              </div>
              <div class="bold">{{ r.getName() }}</div>
              <div class="italic smallish" *ngIf="r.getClassName()">({{ r.getClassName() }})</div>
            </td>
            <td>
              <div class="d-flex flex-wrap top marginBottomQuarterEm" >
                <div class="border-left border-right paddingLeftHalfEm paddingRightHalfEm" *ngFor="let f of r.fields" >
                  <div class="underline smallish">
                    {{ f.name }} 
                  </div>
                  <div>
                    <wackadoo-field [f]="f" [mode]="'read'"></wackadoo-field>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </ng-container>
      <ng-container *ngIf="screenType === 'phone'">
        <div class="border-bottom" *ngFor="let r of resources; let idx = index">
          <div>
            <div class="underline smallish">
              {{ r.getApplication() }}
            </div>
            <div class="bold">{{ r.getName() }}</div>
            <div class="italic smallish" *ngIf="r.getClassName()">({{ r.getClassName() }})</div>
          </div>
          <table class="marginBottomQuarterEm">
            <tr class="top" *ngFor="let f of r.fields; let h = index;">
              <td  class="underline smallish right">
                {{ f.name }}
              </td>
              <td>
                <wackadoo-field [f]="f" [mode]="'read'"></wackadoo-field>
              </td>
            </tr>
          </table>
        </div>
      </ng-container>
    </div>
    <div *ngIf="messages.length > 0">
      <div *ngFor="let m of messages; let idx = index">
        {{ m }}
      </div>
    </div>
  </div>
</div>
