<div class="card">
    <div class="card-header">
        {{ resource.label ? resource.label.value : 'Select Color' }}
    </div>
    <div class="card-body d-flex justify-content-center">
        <div class="larger width-4-em">
            <wackadoo-field [f]="resource.color" mode="write"></wackadoo-field>
        </div>
    </div>
</div>
