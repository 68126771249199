import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-backup-cleanup-service',
  templateUrl: './backup-cleanup-service.component.html',

})
export class BackupCleanupServiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
