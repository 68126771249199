import { Component } from '@angular/core';
import { GridButtonBarComponent } from '../../repository-page/grid-button-bar/grid-button-bar.component';
import { WEvent } from '../../../data/event.model';

@Component({
  selector: 'wackadoo-report-template-grid-button-bar',
  templateUrl: './report-template-grid-button-bar.component.html',
})
export class ReportTemplateGridButtonBarComponent extends GridButtonBarComponent {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ReportTemplateGridButtonBarComponent';

  reloadDefaultTemplates(): void {

    let confirmMessage = 'You are about to re-initialize ALL of the ReportTemplates that are marked as a "systemTemplate" to their original state.';
    confirmMessage += '\n' + 'Understand that the ReportTemplates that you see are "local" copies of those "systemTemplates" WHICH YOU MAY HAVE CHANGED.';
    confirmMessage += '\n' + 'If you do this, ANY changes that you have made to those "systemTemplate" ReportTemplates will be wiped out.';
    confirmMessage += '\n' + 'Note that this will NOT affect any ReportTemplate that is NOT a "systemTemplate".';
    confirmMessage += '\n\n' + 'Do you still want to do this?';

    this.modalDialogService.showConfirm(confirmMessage, '').subscribe(
      (response: boolean) => {

        if (response) {

          this.modalDialogService.showPleaseWait(true);

          // this call (without a specific templateID in the parms...) reloads ALL of them...
          this.eventServerService.fireEvent(this.eventHandler, 'reloadDefaultTemplate', {}).subscribe(
            (responseEvent: WEvent) => {

              this.modalDialogService.showPleaseWait(false);

              if (responseEvent.status !== 'OK') {
                // console.log(responseEvent);
                this.modalDialogService.showAlert('Failed to reload default templates: ' + responseEvent.message, 'Error Loading Default Templates');
              } else {
                this.userInterfaceService.reloadCurrentPage();
              }
            }
          );

        }
      }
    );
  }

}
