import { Component, OnInit, Renderer2, OnDestroy, Input, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { FieldComponent } from '../field.component';
import { FieldMode } from '../field-mode.model';
import { EventServerService } from '../../../services/event-server.service';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { Globals } from '../../../services/global.service';
import { WResource } from '../../resource.model';
import { Subscription, Subject } from 'rxjs';
import { WField } from '../../field.model';

@Component({
  selector: 'wackadoo-field-foreign-key',
  templateUrl: './foreign-key.component.html',

})
export class ForeignKeyComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges {

  debug = false;

  userHasPermissionToForeignEH = true;
  foreignEHName: string = null;
  fkeyResourceIsCurrentlySelected = false;

  selectOptionValues: any [] = [];
  selectOptionLabels: string [] = [];

  @Input() nullValueLabel = '';
  @Input() foreignKeyFilterParms: any = {};
  @Input() useDefaultSortForOptions = false;

  regularOnChangeSubject: Subject<WField> = null;
  fkeyOnChangeWrapperSubject: Subject<WField> = null;
  fkeyOnChangeWrapperSubscription: Subscription = null;

  typeAheadFKeyFieldsToSearch: string [] = null;

  selectTypeAheadResourceSubject: Subject<WResource> = null;
  selectTypeAheadResourceSubscription: Subscription = null;

  typeAheadSelectedResource: WResource = null;

  @Input() selectedResourceSubject: Subject<WResource> = null;

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
    public elementRef: ElementRef,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    // NOTE:
    // 1. EVERY fkey field that comes back from the server SHOULD have a displayValue...
    // 2. If the fkey field needs to populate a drop-down, we do below...

    // this.debug = (this.f.foreignType === 'Lender');

    this.foreignEHName = this.eventServerService.getEventHandlerForResourceType(this.f.foreignType);

    // This will be null if the user doesn't have permission to that EH...
    this.userHasPermissionToForeignEH = (this.foreignEHName !== null);

    if (this.debug) { console.log('foreignKey.ngOnInit()', this.f.name, this.f.displayComponent, this.f, new Error('Debug').stack); }

    const selectedForeignResource = this.userInterfaceService.getSelectedResourceByResourceType(this.f.foreignType);

    if ((!this.f.foreignKeyFilterParms || ((Object.entries(this.f.foreignKeyFilterParms).length === 0))) && (Object.entries(this.foreignKeyFilterParms).length > 0)) {
      this.f.foreignKeyFilterParms = this.foreignKeyFilterParms;
    }

    if (this.f.foreignKeyFilterParms && (Object.entries(this.f.foreignKeyFilterParms).length > 0)) {
      this.foreignKeyFilterParms = this.f.foreignKeyFilterParms;
    }

    // enable the UX element by default
    this.fkeyResourceIsCurrentlySelected = false;

    if (this.f.displayComponent === 'typeahead') {

      this.typeAheadFKeyFieldsToSearch = this.eventServerService.getResourceDisplayLabelFields(this.foreignEHName);

      this.selectTypeAheadResourceSubject = new Subject<WResource>();
      this.selectTypeAheadResourceSubscription = this.selectTypeAheadResourceSubject.subscribe(
        (r: WResource) => {
          // console.log('selectTypeAheadResourceSubscription()', r, typeof r);
          // console.log('selectTypeAheadResourceSubscription()', typeof r.keyField.value, r.keyField.value, r.keyField.displayValue);
          if (r) {
            this.f.value = r.keyField.value;
            this.f.displayValue = r.keyField.displayValue;
            this.f.changed = true;
          } else {
            this.f.value = Globals.MAGIC_NULL_FKEY_VALUE;
            this.f.displayValue = this.nullDisplayValueLabel;
            this.f.changed = true;
          }
          // console.log('selectTypeAheadResourceSubscription()', this.f);

          // console.log('selectTypeAheadResourceSubscription()', 'notifying ' + (this.selectedResourceSubject ? 'via resource subject' : (this.onChange ? 'via field subject' : 'NOBODY')));
          if (this.selectedResourceSubject) {
            this.selectedResourceSubject.next(r);
          } else if (this.onChange) {
            this.onChange.next(this.f);
          }
        }
      );

      // initialize the typeahead...

      // if we have one of these resources selected in the UI, then we lock it in...
      // UNLESS...
      // Remember: In write mode, users USUALLY can't change FKey selection when one of those
      // FKey things is selected. However, if we DO have foreignKeyFilterParms, then clearly,
      // we are TRYING to show the user a subset of the possible options for this FKey...
      // which means that - THIS TIME ONLY - we DO NOT want to lock-in the selected resource.

      if (selectedForeignResource && (!this.f.foreignKeyFilterParms || (Object.entries(this.f.foreignKeyFilterParms).length === 0))) {
        // if we have a selected resources, just use it...
        this.f.value = selectedForeignResource.keyField.value;
        this.f.displayValue = selectedForeignResource.keyField.displayValue;
        this.f.changed = true;
        this.typeAheadSelectedResource = selectedForeignResource;

        // AND... add a flag to disable the UX element
        this.fkeyResourceIsCurrentlySelected = true;

      } else if (this.f.isPopulated) {
        // first, we show the proper displayValue, if we have already it...
        if (this.f && this.f.isPopulated) {
          this.f.displayValue = this.userInterfaceService.getForeignKeyDisplayValue(this.f.foreignType, this.f.value);
        }
        // second, get the associated resource from the server, because typeaheads require having the whole indicated resource...
        this.eventServerService.loadResourceFromServer(this.foreignEHName, this.f.asParm).subscribe(
          (r: WResource) => {
            if (r) {
              if (this.f) {
                this.f.value = r.keyField.value;
                this.f.displayValue = r.keyField.displayValue;
              }
              this.typeAheadSelectedResource = r;
            }
          }
        );
      }

    } else {

      // if we have one of these resources selected in the UI, then we lock it in...
      // UNLESS...
      // Remember: In write mode, users USUALLY can't change FKey selection when one of those
      // FKey things is selected. However, if we DO have foreignKeyFilterParms, then clearly,
      // we are TRYING to show the user a subset of the possible options for this FKey...
      // which means that - THIS TIME ONLY - we DO NOT want to lock-in the selected resource.

      if (selectedForeignResource && (!this.f.foreignKeyFilterParms || (Object.entries(this.f.foreignKeyFilterParms).length === 0))) {

        if (this.debug) { console.log('foreignKey.ngOnInit() - selected foreign resource keyField', this.f.foreignType, selectedForeignResource.keyField); }

        // WRITE MODE: we need to build the select/option in the UX (and remember... the "this.selectOptionValues" need to be Strings)
        this.selectOptionValues = [];
        this.selectOptionLabels = [];
        this.selectOptionValues.push(selectedForeignResource.keyField.value);
        this.selectOptionLabels.push(selectedForeignResource.keyField.displayValue);

        // READ MODE: we want the displayValue for the selected fkey resource...
        this.f.displayValue = selectedForeignResource.keyField.displayValue;

        // if the value does not match, we re-set the value and mark it changed...
        // (The weird thing: somehow, this.f.value is a String at this point... Do not know how or where that happened...)

        if (this.debug) { console.log('foreignKey.ngOnInit() - checking for match', this.f.isNull, this.f.value, selectedForeignResource.keyField.value, (this.f.value !== selectedForeignResource.keyField.value)); }

        if (this.f.isNull || (this.f.value !== selectedForeignResource.keyField.value)) {
          this.f.value = selectedForeignResource.keyField.value;
          this.f.changed = true;
        }

        // AND... add a flag to disable the UX element
        this.fkeyResourceIsCurrentlySelected = true;

        if (this.debug) { console.log('foreignKey.ngOnInit() - field AFTER applying selected resource', JSON.stringify(this.f), this.f, this.selectOptionValues, this.selectOptionLabels); }

      // if we have a null value, and we're readOnly, we don't waste time going to the server for values
      // because they will get populated next time this field is cycled in the UX...
      } else if (this.f.isNull && this.f.readOnly) {

        this.selectOptionValues = [];
        this.selectOptionLabels = [];

        this.selectOptionValues.push(Globals.MAGIC_NULL_FKEY_VALUE);
        this.selectOptionLabels.push(this.nullDisplayValueLabel);

      // else if we are in write mode, then we go get ALL OF THEM from the server...
      } else if (this.mode === FieldMode.write) {
        this.loadAllDisplayValues();
      // else, if we are missing the displayValue for this field's value...
      } else if (!this.f.isNull && !this.f.displayValue) {
        // we populate just this one...

        // first, we check the UISvc cache of displayValues...
        const dv = this.userInterfaceService.getForeignKeyDisplayValue(this.f.foreignType, this.f.value);
        // if it's already loaded, we just use it...
        if (dv) {
          if (this.debug) { console.log('foreignKey.ngOnInit()', 'using fkey displayValue from UISvc'); }
          this.f.displayValue = dv;
        } else {
          // else, we go get JUST THIS ONE from the server...
          if (this.debug) { console.log('foreignKey.ngOnInit()', 'getting ONE fkey displayValue from server', this.f); }
          this.userInterfaceService.loadForeignKeyDisplayValuesFromTheServer(this.f.foreignType, [ this.f.value ], this.f.foreignKeyFilterParms).subscribe(
            (errorMessage: string) => {
              try {
                if (errorMessage) {
                  console.log('Failed to load single fkey displayValue', errorMessage);
                } else if (this.f) {
                  this.f.displayValue = this.userInterfaceService.getForeignKeyDisplayValue(this.f.foreignType, this.f.value);
                } else {
                  // sometimes, as components load/re-load during the component life-cycle, this.f is null at this point...
                  // which we simply ignore, because it eventually gets handled in other places...
                  if (this.debug) { console.log('foreignKey.ngOnInit()', 'Failed to load single fkey displayValue. We no longer have a field!', this.f); }
                }
              } catch (ex) {
                console.log('foreignKey.ngOnInit()', 'Failed to load single fkey displayValue.', this.f, ex);
              }
            }
          );
        }
      } else {
        this.buildSelectOptions();
      }

      // now we wrap the "onChange: Subject<WField>" (if it exists) to update this.f.displayValue when this.f.value
      // changes, because changes to this.f.value do NOT trigger "ngOnChanges()" in this component...

      this.regularOnChangeSubject = this.onChange;

      this.fkeyOnChangeWrapperSubject = new Subject<WField>();
      this.onChange = this.fkeyOnChangeWrapperSubject;

      if (this.f.isNull || (this.f.value === Globals.MAGIC_NULL_FKEY_VALUE)) {
        this.f.displayValue = this.nullDisplayValueLabel;
      }

      this.fkeyOnChangeWrapperSubscription = this.fkeyOnChangeWrapperSubject.subscribe(
        (f: WField) => {

          // (NOTE: "this.f" is already changed by the time we get here, so we use it directly...)

          // If we don't have a value, we put in the special nullDisplayValueLabel...
          if (this.f.isNull || (this.f.value === Globals.MAGIC_NULL_FKEY_VALUE)) {
            this.f.displayValue = this.nullDisplayValueLabel;
          } else {
            // else, we just need to select the displayValue for this field's value...
            // remember... the "this.selectOptionValues" are Strings (for use in the UX select/option...)
            const idx = this.selectOptionValues.indexOf(this.f.value);
            this.f.displayValue = this.selectOptionLabels[idx];
          }

          // now, if it exists, fire the regular "onChange" Subject...
          if (this.regularOnChangeSubject) {
            this.regularOnChangeSubject.next(f);
          }
        }
      );

    }

  }

  ngOnDestroy(): void {
    if (this.fkeyOnChangeWrapperSubscription) {
      this.fkeyOnChangeWrapperSubscription.unsubscribe();
    }
    if (this.selectTypeAheadResourceSubscription) {
      this.selectTypeAheadResourceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  ngOnChanges(sc: SimpleChanges): void {

    if (sc.f) {
      // console.log('ngOnChanges() - field: ' + this.f.name, this.f.displayComponent, (sc.f.previousValue ? sc.f.previousValue.value : 'none'), ' --> ', sc.f.currentValue.value);

      if (this.f.displayComponent === 'typeahead') {

        // in order to clear the selected resource, we pass in a full one with a null value in the proper field.
        const temp = this.eventServerService.newResource(this.foreignEHName);
        if (temp && this.f) {
          temp[this.f.name].value = null;
        }
        // console.log(this.f, temp);

        if (this.foreignEHName && this.userHasPermissionToForeignEH && !this.f.isNull) {
          // get the associated resource from the server, because typeaheads require having the whole indicated resource...
          if (this.debug) { console.log('foreignKey.ngOnChanges() - loading resource from server', this.foreignEHName, this.f.isPopulated, this.f); }
          this.eventServerService.loadResourceFromServer(this.foreignEHName, this.f.asParm).subscribe(
            (r: WResource) => {
              if (r) {
                if (this.f) {
                  this.f.value = r.keyField.value;
                  this.f.displayValue = r.keyField.displayValue;
                }
              }
              this.typeAheadSelectedResource = (this.f && (this.f.isPopulated) ? r : temp);
            }
          );
        } else {
          if (this.f && !this.f.isPopulated) {
            // console.log('setting ' + this.f.name + ' = ' + Globals.MAGIC_NULL_FKEY_VALUE);
            this.f.value = Globals.MAGIC_NULL_FKEY_VALUE;
            this.typeAheadSelectedResource = temp;
          }
        }
      } else {
        const selector = '[name="' + this.f.name + '"]';  // input? select? both?
        const element: HTMLInputElement = this.elementRef.nativeElement.querySelector(selector) as HTMLInputElement;
        if (element) {
          element.value = this.f.value;
        }
      }
    }

    if (sc.foreignKeyFilterParms && !sc.foreignKeyFilterParms.firstChange) {
      let changedFilterParms = false;
      // check all current values against previous values...
      for (const [name, value] of Object.entries(sc.foreignKeyFilterParms.currentValue)) {
        changedFilterParms = changedFilterParms || (sc.foreignKeyFilterParms.previousValue[name] !== value);
      }
      // check all previous values against current values...
      for (const [name, value] of Object.entries(sc.foreignKeyFilterParms.previousValue)) {
        changedFilterParms = changedFilterParms || (sc.foreignKeyFilterParms.currentValue[name] !== value);
      }
      if (changedFilterParms) {
        if (this.debug) { console.log('changed filter parms!', sc.foreignKeyFilterParms.currentValue, '-->', sc.foreignKeyFilterParms.previousValue); }
        this.f.foreignKeyFilterParms = sc.foreignKeyFilterParms.currentValue;
        this.loadAllDisplayValues();
      }
    }
  }

  /**
   * This is a modified version of the default method called on all input field changes.
   * It assumes the 'value' of the input element is populated with the new value.
   * The difference is that we force the fkey value to be a Number, rather than
   * the string value that comes out of the HTML SELECT object.
   */
  onFKeyInputChange(e: any): boolean {
    // e = e || window.event;
    const target = e.srcElement || e.target;

    const cellInput = target;

    // console.log(cellInput);

    if (cellInput) {

      const numericCellInputValue = Number(cellInput.value);

      // console.log(this.f.name, ', cellInput.value: ' + cellInput.value + ' (' + typeof cellInput.value + ')', 'numericCellInputValue: ' + numericCellInputValue, 'this.f.value: ' + this.f.value);
      // console.log('this.f.isValidValue(numericCellInputValue): ' + this.f.isValidValue(numericCellInputValue));

      // clear the previous formatting, if present...
      this.renderer.removeClass(cellInput, 'fieldChanged');
      this.renderer.removeClass(cellInput, 'fieldError');

      if (this.f.isValidValue(numericCellInputValue)) {
        // the new value passed validation...

        // console.log('numericCellInputValue: ' + numericCellInputValue, this.f.value, 'changed? ' + (numericCellInputValue !== this.f.value));

        if (numericCellInputValue !== this.f.value) {

          const oldValue = this.f.value;

          this.renderer.addClass(cellInput, 'fieldChanged');
          this.f.value = numericCellInputValue;
          this.f.changed = true;

          // console.log('numericCellInputValue: ' + numericCellInputValue, this.f.value, 'this.onChange exists? ' + (this.onChange !== null));
          if (this.onChange !== null) {
            // console.log('firing field.onChange.next()');
            this.f.oldValue = oldValue;
            this.onChange.next(this.f);
          }

        }
        // this._renderer.setStyle(cellInput, 'backgroundColor', '');
      } else {

        // console.log('this.f._errorMessage: ' + this.f._errorMessage);

        // the new value failed validation...
        this.renderer.addClass(cellInput, 'fieldError');
      }
    }

    return false;
  }

  loadAllDisplayValues(): void {
    if (this.debug) { console.log('foreignKey.loadAllDisplayValues()', 'getting ALL fkey displayValues from server', this.f.foreignType, this.f.foreignKeyFilterParms, this.f); }
    this.modalDialogService.showPleaseWait(true);
    this.userInterfaceService.loadForeignKeyDisplayValuesFromTheServer(this.f.foreignType, null, this.f.foreignKeyFilterParms).subscribe(
      (errorMessage: string) => {
        this.modalDialogService.showPleaseWait(false);
        try {
          if (errorMessage) {
            console.log('failed to load all fkey displayValues', errorMessage);
          } else {
            this.buildSelectOptions();
          }
        } catch (ex) {
          console.log('foreignKey.loadAllDisplayValues()', 'Error getting all fkey displayValues from server', errorMessage, this.f, ex);
        }
      }
    );
  }

  get nullDisplayValueLabel(): string {
    return (this.f.required ? '* ' : '') + this.nullValueLabel;
  }

  goToForeignEH(): void {
    if (this.userHasPermissionToForeignEH && !this.f.isNull) {
      const foreignKeyAsParms = {};
      foreignKeyAsParms[this.eventServerService.getKeyFieldName(this.foreignEHName)] = this.f.value;
      this.eventServerService.loadResourceFromServer(this.foreignEHName, foreignKeyAsParms).subscribe(
        (res: WResource) => {
          this.userInterfaceService.selectResource(this.foreignEHName, res);
          this.userInterfaceService.loadPage(this.foreignEHName, 'search', foreignKeyAsParms);
        }
      );
    }
  }

  buildSelectOptions(): void {

    // not sure why this ever happens, but sometimes it does...
    if (!this.f) {
      return;
    }

    if (this.debug) { console.log('foreignKey.buildSelectOptions()', this.f, this.userInterfaceService.getForeignKeyDisplayValues(this.f.foreignType)); }

    // now, sort and display the select option names/values

    // sort by the display label, not by the value...
    const sortable = [];
    const fkeyDisplayValues: any [] = this.userInterfaceService.getForeignKeyDisplayValues(this.f.foreignType);

    if (this.debug) { console.log('foreignKey.buildSelectOptions()', 'this.useDefaultSortForOptions:', this.useDefaultSortForOptions, 'fkeyDisplayValues:', fkeyDisplayValues); }

    if (fkeyDisplayValues) {
      for (const fkdv of fkeyDisplayValues) {
        sortable.push(fkdv);
      }
      if (!this.useDefaultSortForOptions) {
        sortable.sort((a, b) => (a.fkeyLabel < b.fkeyLabel ? -1 : (a.fkeyLabel > b.fkeyLabel ? 1 : 0)) );
      }
    }

    // now we move them over into the proper arrays for building the SELECT OPTIONS in the UX...

    this.selectOptionValues = [];
    this.selectOptionLabels = [];

    // The server converts ResourceRepository.MAGIC_NULL_FKEY_VALUE  ("-1") to "null" for fkeys,
    // so this allows for the first option to be 'blank', letting the user clear the value...
    this.selectOptionValues.push(Globals.MAGIC_NULL_FKEY_VALUE);
    this.selectOptionLabels.push(this.nullDisplayValueLabel);

    // if (this.debug) { console.log('foreignKey.buildSelectOptions()', 'setting fkey names and labels:', sortable); }

    for (const temp of sortable) {
      this.selectOptionValues.push(temp.fkeyValue);
      this.selectOptionLabels.push(temp.fkeyLabel);
    }

    if (this.debug) { console.log('foreignKey.buildSelectOptions()', 'set fkey names and labels:', this.selectOptionValues, this.selectOptionLabels); }
  }

}
