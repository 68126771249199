import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wackadoo-policy-expired-delinquent-accts',
  templateUrl: './policy-exp-delinq-accts.component.html',

})
export class PolicyExpiredDelinquentAcctsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
