import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

@Component({
  selector: 'wackadoo-page-not-found',
  templateUrl: './page-not-found.component.html',

})
export class PageNotFoundComponent implements OnInit, OnDestroy {

  @Input() missingPageName: string;

  private _timerMax = 5;
  timerCount = 0;

  private _countDownInterval: any = null;
  private _countDownTimeout: any = null;

  constructor(
    private _route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    // console.log(this.route.snapshot);
    // console.log('PNF - ngOnInit()');

    // good for initial load
    this.missingPageName = this.buildPageName(this._route.snapshot.url);
    this.resetCountDown();
    this.setCountDown();

    // required because it can change while we're on this page...
    this._route.url.subscribe(
       (url: any) => {
          this.missingPageName = this.buildPageName(url);
          this.resetCountDown();
          this.setCountDown();
       }
    );

  }

  buildPageName(url: UrlSegment[]): string {
    let temp = '';
    let i = 0;
    while (i < url.length) {
      temp = temp + '/' + url[i].path;
      i++;
    }
    return(temp);
  }

  ngOnDestroy(): void {
    // console.log('PNF - ngOnDestroy()');
    this.resetCountDown();
  }

  setCountDown(): void {
    // console.log('PNF - setCountDown()');

    this.timerCount = this._timerMax;

// Un-comment this to re-enable the re-direct countdown...

    // this._countDownInterval = setInterval(() => {
    //    this.timerCount--;
    // }, 1000);

    // this._countDownTimeout = setTimeout(() => {
    //    this._location.back();
    // }, this._timerMax * 1000);
  }

  resetCountDown(): void {
    // console.log('PNF - resetCountDown()');

    if (this._countDownInterval !== null) {
      clearInterval(this._countDownInterval);
      this._countDownInterval = null;
      this.timerCount = 0;
    }

    if (this._countDownTimeout !== null) {
      clearTimeout(this._countDownTimeout);
      this._countDownTimeout = null;
    }
  }

}
