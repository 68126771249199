import { Directive, ElementRef, Renderer2, Input, OnInit, RendererStyleFlags2, OnChanges } from '@angular/core';

@Directive({
  selector: '[wackadooStyle]'
})
export class StyleDirective implements OnInit, OnChanges {

  @Input() wStyle: string | string [] = null;
  @Input() wValue: string | string [] = null;
  @Input() wImportant = false;

  constructor(
    public elementRef: ElementRef,
    public renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.wStyle && this.wValue) {
      if ((typeof this.wStyle === 'string') && (typeof this.wValue === 'string')) {
        this.renderer.setStyle(this.elementRef.nativeElement, this.wStyle, this.wValue, (this.wImportant ? RendererStyleFlags2.Important : null));
      } else {
        for (let i = 0; i < this.wStyle.length; i++) {
          this.renderer.setStyle(this.elementRef.nativeElement, this.wStyle[i], this.wValue[i], (this.wImportant ? RendererStyleFlags2.Important : null));
        }
      }
    }
  }

}
