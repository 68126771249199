import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { ModalDialogService } from '../../../services/modal-dialog.service';
import { EMailerContent } from './e-mailer-content';
import { UserInterfaceService } from '../../../services/user-interface.service';
import { EventServerService } from '../../../services/event-server.service';
import { WResource } from '../../../data/resource.model';
import { Globals } from '../../../services/global.service';
import { WEvent } from '../../../data/event.model';
import { FieldMode } from '../../../data/field/field-mode.model';
import { User } from '../../../data/user.model';
import { UserAuthService } from '../../../services/user-auth.service';
import { HtmlEditorService } from '../../../services/html-editor.service';
import { FileContentDescriptor } from '../../file-content-descriptor.model';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';
import { WField, WPassword } from 'src/app/client-core/data/field.model';

@Component({
  selector: 'wackadoo-e-mailer',
  templateUrl: './e-mailer.component.html',
})
export class EMailerComponent implements OnInit, OnDestroy, AfterViewInit {

  visible = false;

  showModalSubscription: Subscription = null;

  eventHandler = null;
  parms: any = {};

  mode: FieldMode = FieldMode.write;

  public wysiwyg = true;
  public wysiwygSubscription: Subscription;

  title = '';

  resource: WResource;
  errorMessage: string = null;

  templateName: string = null;
  maxFileSizeMB = -1;

  templateEHName = 'EMailTemplates';
  template: WResource = null;

  domain = Globals.domain;

  user: User = null;
  userSubscription: Subscription = null;

  attachmentDropSubject = new Subject<FileContentDescriptor>();
  attachmentDropSubscription: Subscription;

  fromEMailChangeTimer: any = null;
  fromEMailChangeSubject = new Subject<WField>();
  fromEMailChangeSubscription: Subscription;

  mxEntries: WResource [] = null;
  matchingSMTPServers: WResource [] = null;

  includeTimeZone = false;

  constructor(
    public modalDialogService: ModalDialogService,
    public userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public userAuthService: UserAuthService,
    public htmlEditorService: HtmlEditorService,
  ) {
  }

  ngOnInit(): void {

    this.resource = null;
    this.eventHandler = null;
    this.parms = {};
    this.mode = FieldMode.write;
    this.title = 'EMailer';
    this.visible = false;

    this.mxEntries = null;
    this.matchingSMTPServers = null;

    this.wysiwygSubscription = this.htmlEditorService.wysiwygMode.subscribe(
      (wysiwyg: boolean) => {
        this.wysiwyg = wysiwyg;
      }
    );

    this.userSubscription = this.userAuthService.currentUser.subscribe(
      (user: User) => {
        this.user = user;
      }
    );

    this.attachmentDropSubscription = this.attachmentDropSubject.subscribe(
      (fcd: FileContentDescriptor) => {
        // console.log('attachmentDrop', fcd);
        if (fcd.valid) {
          this.resource.fileName.value = fcd.fileName;
          this.resource.fileSize.value = fcd.fileSize;
          this.resource.fileContent.value = fcd.fileContent;
          this.resource.fileType.value = fcd.fileType;
          // this.resource.fileDate.value = fcd.fileDate;
        } else {
          this.modalDialogService.showAlert(JSON.stringify(fcd), 'WARNING: Invalid File Descriptor!');
        }
      }
    );

    this.showModalSubscription = this.modalDialogService.showEMailerSubject.subscribe(
      (emailerContent: EMailerContent) => {
        if (emailerContent) {
            this.eventHandler = emailerContent.eventHandler;
            this.parms = emailerContent.parms;
            this.title = (emailerContent.title ? emailerContent.title : 'EMailer');

            // now we fire the event and populate the template...
            this.templateName = this.parms.templateName;

            // use the templateName to grab the template for later, should the user click editEMailTemplate()
            const templateParms: any = {};
            templateParms.templateName = this.templateName;

            this.modalDialogService.showPleaseWait(true);

            this.eventServerService.loadResourceFromServer(this.templateEHName, templateParms).subscribe(
              (template: WResource) => {

                this.modalDialogService.showPleaseWait(false);

                this.template = template;

                // Now, we ONLY want to proceed with this if we had a good email template name...

                this.generateEMail();

              }
            );
        } else {
          this.onClose();
        }
      }
    );

    this.fromEMailChangeSubject = new Subject<WField>();

    this.fromEMailChangeSubscription = this.fromEMailChangeSubject.subscribe(
      (f: WField) => {
        if (this.fromEMailChangeTimer) {
          window.clearTimeout(this.fromEMailChangeTimer);
          this.fromEMailChangeTimer = null;
        }
        this.fromEMailChangeTimer = window.setTimeout(
          () => {
            this.lookForSMTPServer(f.value);
            this.fromEMailChangeTimer = null;
          }
          , 333
        );
      }
    );
  }

  ngOnDestroy(): void {
    if (this.wysiwygSubscription) {
      this.wysiwygSubscription.unsubscribe();
    }
    if (this.showModalSubscription) {
      this.showModalSubscription.unsubscribe();
    }
    if (this.attachmentDropSubscription) {
      this.attachmentDropSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.fromEMailChangeSubscription) {
      this.fromEMailChangeSubscription.unsubscribe();
    }
    this.onClose();
  }

  ngAfterViewInit(): void {
    // IMPORTANT: here we re-kick the size of the HTML editor field on this
    // modal dialog because it was "empty" when it first calculated it's height...
    this.userInterfaceService.screenSizeChange.next();
  }

  onClose(): void {
    this.resource = null;
    this.eventHandler = null;
    this.parms = {};
    this.mode = FieldMode.write;
    this.wysiwyg = false;
    this.title = '';
    this.visible = false;
  }

  get screenType(): ScreenType {
    return this.userInterfaceService.screenType.getValue();
  }

  generateEMail(): void {

    // console.log('Emailer.preLoad() - this.templateName: ' + JSON.stringify(this.templateName));
    // add the timezone name to allow dates in the email content to be formatted correctly...
    this.parms.olsonIanaTimeZoneName = Globals.TIMEZONE;
    this.parms.includeTimeZone = '' + this.includeTimeZone;

    // 'generateEMail' is ALWAYS the "action" when displaying this modal dialog...
    const action = 'generateEMail';

    this.modalDialogService.showPleaseWait(true, true);

    // all the parameters we passed in make it into the "generateEMail" call so we can populate the template...

    this.eventServerService.fireEvent(this.eventHandler, action, this.parms).subscribe(
      (responseEvent: WEvent) => {

        this.modalDialogService.showPleaseWait(false);

        // console.log('Emailer.preLoad() - responseEvent', responseEvent, 'status: ' + responseEvent.status, 'message: ' + responseEvent.message);
        if (responseEvent.status !== 'OK') {
          this.errorMessage = responseEvent.message;
        } else if (responseEvent.resources && responseEvent.resources.length === 1) {

          this.resource = responseEvent.resources[0];

          // this should ALWAYS be true...
          if (this.resource.from) {
            this.lookForSMTPServer(this.resource.from.value);
          }

          // console.log('Emailer.preLoad()', 'resource:', this.resource);

        } else {
          this.errorMessage = 'Wrong number of resources: ' + (responseEvent.resources ? responseEvent.resources.length : 'null') + '(There should be just 1...)';
        }

        // we have to set the wysiwyg display mode here, since THIS is where the dialog is rendered...
        this.htmlEditorService.wysiwygMode.next(true);

        this.visible = true;
      }
    );
  }

  lookForSMTPServer(email: string): void {

    if (this.resource && (this.resource.from.isPopulated)) {
      this.mxEntries = null;
      this.matchingSMTPServers = null;

      const parms: any = {};
      parms.domain = email;


      this.eventServerService.fireEvent('SMTPServers', 'matchDomain', parms).subscribe(
        (event: WEvent) => {
          // console.log(event);
          this.matchingSMTPServers = event.resources ? event.resources : [];
        }
      );
    }
  }

  ////////////////////////////
  // Button functions...
  ////////////////////////////

  toggleTimeZone(): void {
    this.includeTimeZone = !this.includeTimeZone;
    this.generateEMail();
    this.toggle();
  }

  get timeZoneButtonTitle(): string {
    if (this.includeTimeZone) {
      return('Remove TimeZone From All Time-Stamps In EMail');
    } else {
      return('Add TimeZone To All Time-Stamps In EMail');
    }
  }

  private _internalSend(): void {
    try {

      this.modalDialogService.showPleaseWait('Sending EMail...');

      // Make sure all the parameters we passed in make it into the "send" call,
      // but in a NON-destructive manner so we don't screw up the core upstream logic
      // on common fields, like to, from, etc.
      const sendParms = this.resource.asParms;
      for (const [n, v] of Object.entries(this.parms)) {
        if (!sendParms[n]) {
          sendParms[n] = v;
        }
      }

      this.eventServerService.fireEvent('EMailer', 'send', sendParms).subscribe(
        (event: WEvent) => {
            this.modalDialogService.showPleaseWait(false);

            // console.log('Emailer.send.callback()', 'event:', event);
            try {
              if (event.status !== 'OK') {
                this.modalDialogService.showError(event.message, 'EMail NOT Sent', event.status);
              } else {
                const title = 'EMail Sent';
                let msg3 = event.message;

                // console.log('Emailer.send.callback()', event.parameters);

                if (event.parameters
                    && event.parameters.fileName
                    && (event.parameters.fileName !== '')
                    && (event.parameters.fileName !== 'undefined')
                ) {
                  msg3 += '<p>'
                    + 'attachment file: ' + event.parameters.fileName
                    + '</p>';
                }
                this.modalDialogService.showAlert(msg3, title);

                // NOW close the EMailer modal...
                this.onClose();
              }
            } catch (ex) {
              const msg4 = 'EMailer.send()\n';
              this.userInterfaceService.alertUserToException(ex, msg4);
            }
        }
      );

    } catch (ex) {
      const msg2 = 'EMailer.send()\n';
      this.userInterfaceService.alertUserToException(ex, msg2);
    }
  }

  send(): void {
    try {
      if (this.matchingSMTPServers && (this.matchingSMTPServers.length === 1)) {

        const message = 'Just double-checking. We are about to send the email in your name, but first need to get a password for your SMTP server.';

        this.modalDialogService.showConfirm(message, 'Proceed?').subscribe(
          (confirmSend: boolean) => {
            try {
              if (confirmSend) {
                const smtpServer = this.matchingSMTPServers[0].clone;
                smtpServer.smtpAccountName = this.resource.from.clone;
                smtpServer.smtpAccountName.name = 'smtpAccountName';
                const f = new WPassword('smtpPassword', null);
                f.length = 50;
                smtpServer.smtpPassword = f;

                // munge smtpNote, in case there's a {domain} in it...
                if (smtpServer.smtpNote.isPopulated) {
                  const domain = this.resource.from.value.replace(/.*@/, '');
                  smtpServer.smtpNote.value = smtpServer.smtpNote.value.replace('{domain}', domain);
                }

                // console.log('SMTP server', smtpServer);

                this.modalDialogService.showSelectValue('SelectSmtpServerPasswordComponent', smtpServer, 'smtpPassword', 'Send EMail').subscribe(
                  (newPassword: string) => {
                    try {
                      if (newPassword) {
                        // adding SMTP parms to request to use USER's smtp server...
                        this.resource.addAllFields(smtpServer);
                        this._internalSend();
                      } else {
                        this.modalDialogService.showAlert('EMail NOT sent.');
                      }

                    } catch (ex) {
                      const msg = 'EMailer.send()\n';
                      this.userInterfaceService.alertUserToException(ex, msg);
                    }
                  }
                );
              } else {
                this.modalDialogService.showAlert('EMail NOT sent.');
              }
            } catch (ex) {
              const msg = 'EMailer.send()\n';
              this.userInterfaceService.alertUserToException(ex, msg);
            }
          }
        );

      } else {

        const message = 'Just double-checking. We are about to send the email in your name, but from a "' + this.domain + '" email server.';

        this.modalDialogService.showConfirm(message, 'Send The EMail?').subscribe(
          (confirmSend: boolean) => {
            try {
              if (confirmSend) {
                this._internalSend();
              } else {
                this.modalDialogService.showAlert('EMail NOT sent.');
              }
            } catch (ex) {
              const msg = 'EMailer.send()\n';
              this.userInterfaceService.alertUserToException(ex, msg);
            }
          }
        );
      }

    } catch (ex) {
      const msg = 'EMailer.send()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  get toggleButtonTitle(): string {
    return('Toggle To ' + (this.wysiwyg ? 'XHTML' : 'WYSIWYG'));
  }

  toggle(): void {
    // all we do is toggle the HTML field editor...
    this.wysiwyg = !this.wysiwyg;
    this.htmlEditorService.wysiwygMode.next(this.wysiwyg);
  }

  editEMailTemplate(): void {
    // console.log('Emailer.editEMailTemplate()', 'this.template:', this.template);
    this.onClose();
    this.userInterfaceService.loadPage('EMailTemplates', 'modify', this.template.keyField.asParm);
  }

  cloneEMailTemplate(): void {
    // console.log('Emailer.editEMailTemplate()', 'this.template:', this.template);
    this.onClose();
    const oldTemplateName = this.template.templateName.value;

    try {

      // 1 - get the new name suffix from the clone button tag

      const newSuffix = 'copy';

      // console.log('EMailer.cloneEMailTemplate() - newSuffix: ' + JSON.stringify(newSuffix));

      // 2 - prompt the user for the new name, with (old name + new suffix) as the default

      let newTemplateName = oldTemplateName + ' - ' + newSuffix;

      let promptMessage = 'Please enter a new email template name, and hit OK to clone this email template.';
      promptMessage += '\n';

      if (!newTemplateName) {
        newTemplateName = oldTemplateName + ' - ' + newSuffix;
      }
      if (newTemplateName.trim() === '') {
        newTemplateName = oldTemplateName + ' - ' + newSuffix;
      }

      this.modalDialogService.showPrompt(promptMessage,  'Cloning EMailTemplate ' + oldTemplateName, 'new template name', newTemplateName).subscribe(
        (newTemplateName2: string | boolean) => {
          // console.log('Got here!  newTemplateName2: ' + newTemplateName2);
          if (typeof newTemplateName2 === 'string') {
            try {
                // 3 - add the new email template
                const parms: any = this.template.asParms();
                delete parms.templateID;
                parms.templateName = newTemplateName2;

                this.userInterfaceService.loadPage('EMailTemplates', 'add', parms);

            } catch (ex) {
              const msg = 'EMailer.cloneEMailTemplate.promptCallback()\n';
              this.userInterfaceService.alertUserToException(ex, msg);
            }
          }
        }
      );

    } catch (ex) {
      const msg = 'EMailer.cloneEMailTemplate()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  removeAttachment(): void {
    if (this.resource) {
      this.resource.fileContent.value = null;
      this.resource.fileName.value = null;
      this.resource.fileType.value = null;
      this.resource.fileSize.value = null;
      // this.resource.fileDate.value = null;
    }
  }

  cancel(): void {
    this.onClose();
  }

  canModifyEMailTemplates(): boolean {
    return this.eventServerService.getMethods('EMailTemplates').includes('modify');
  }

}
