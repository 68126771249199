import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) {}

    // input is a string with HTML in it...
    transform(value: any): any {
        return this._sanitizer.bypassSecurityTrustHtml(value);
        // return this._sanitizer.bypassSecurityTrustStyle(style);
        // return this._sanitizer.bypassSecurityTrustXxx(style); - see docs
    }
}
