import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserAuthService } from 'src/app/client-core/services/user-auth.service';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';
import { WResource } from 'src/app/client-core/data/resource.model';
import { EventServerService } from 'src/app/client-core/services/event-server.service';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { WEvent } from 'src/app/client-core/data/event.model';
import * as braintreeDropIn from 'braintree-web-drop-in';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-billing',
  templateUrl: './billing.component.html',

})
export class BillingComponent extends PageComponent implements OnInit, OnDestroy {

  clientToken: string = null;
  creditCard: WResource = null;

  theBigOptIn = '(' + Globals.theBigOptIn + ')';

  account: WResource = null;

  private _braintreeInstance = null;

  get creditCardIsOnFile(): boolean {
    return (this.creditCard && this.creditCard.cardType);
  }

  // These two items for the collapsable noe in the credit card section of the UX
  isCollapsed = true;

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  constructor(
    userAuthService: UserAuthService,
    userInterfaceService: UserInterfaceService,
    public eventServerService: EventServerService,
    public modalDialogService: ModalDialogService,
  ) {
    super(userAuthService, userInterfaceService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    try {
      // generate the client token needed to access the credit card service...

      if (this.user.isMemberOnly) {
        this.eventServerService.fireEvent('Billing', 'generateClientToken', {}).subscribe(
          (event: WEvent) => {
            // console.log('Billing.ngOnInit() - generateClientToken', event);
            if (event.status !== 'OK') {
              throw new Error(event.message);
            }

            this.clientToken = event.parameters.clientToken;

            // console.log('Billing.ngOnInit() - this.clientToken\n' + this.clientToken);

            // we don't wait for events above to complete before firing up the credit card drop-in...

            this.initializeBrainTreeDropIn();

            // Just for fun, we check to see if the customer already exists...

            this.eventServerService.fireEvent('Billing', 'checkCustomerExistence', {}).subscribe(
              (event2: WEvent) => {
                // console.log('Billing.ngOnInit() - checkCustomerExistence', event2);

                if (event2.status !== 'OK') {
                  throw new Error(event2.message);
                }

                const customerExists = event2.parameters.customerExists;

                // console.log('Billing.ngOnInit() - customerExists: ' + customerExists);

              }
            );

            // If there is one on file, we get non-identifying meta-data about the customer's credit card...

            this.eventServerService.fireEvent('Billing', 'getCreditCard', {}).subscribe(
              (event3: WEvent) => {
                // console.log('Billing.ngOnInit() - getCreditCard', event3);

                if (event3.status !== 'OK') {
                  throw new Error(event3.message);
                }

                if (event3.resources) {
                  this.creditCard = event3.resources[0];
                }

                // console.log('Billing.ngOnInit() - this.creditCard', this.creditCard);
              }
            );

          }
        );
      }

    } catch (ex) {
      const msg = 'Billing.ngOnInit()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

/*
  // THE OLD PURCHASE BUTTON DISPLAY LOGIC...

  // if the expiration date is THIS month, then we need to show the purchase button instead,
  // since that means either
  // 1. the regular customer payment failed, and they are making a catch-up payment, or...
  // 2. they recently cancelled their subscription, and would need to re-purchase it from scratch...
  const expirationDate = new Date(resource.expiration);
  const topOfNextMonth = wackadoo.lib.datetime.getTopOfMonth(new Date(), -1);

  if ($('#creditCardOnFile').is(':visible') && (expirationDate.getTime() < topOfNextMonth.getTime())) {
    $('.purchaseApplication[data-appID="'+resource.appID+'"]').show();
    $('.cancelApplication[data-appID="'+resource.appID+'"]').hide();
  }

  // console.log(JSON.stringify(resource));
  // if this app expires, show the expiration date...
  if (resource.expiration) {

    // we show the purchase button for "per event" apps to allow them to buy another...
    $('.purchaseApplication[data-appID="'+resource.appID+'"][data-priceModel="per event"]').show();
  }

*/

  initializeBrainTreeDropIn(): void {
    try {
      // console.log('Billing.initializeBrainTreeDropIn()');

      /////////////////////////////////////////////////////
      // set up the credit card input section
      /////////////////////////////////////////////////////

      // load the billing name and address
      this.eventServerService.loadResourceFromServer('Accounts', { accountID: this.user.accountID } ).subscribe(
        (account: WResource) => {
          this.account = account;
          // console.log('Billing.initializeBrainTreeDropIn() - account: ', account.asParms);
          this.account.accountCountry.length = 2;
          this.account.markAllFieldsAsChanged();
        }
      );

      // load the BrainTree drop-in form

      // console.log('Billing.initializeBrainTreeDropIn()', braintreeDropIn);

      braintreeDropIn.create(
        {
          authorization: this.clientToken,
          container: '#payment-form',
          card: {
            cardholderName: {
              required: true
            }
          }
        },
        (createErr: any, instance: any) => {
          // console.log('onReady()', createErr, instance);
          this._braintreeInstance = instance;
        }
      );

    } catch (ex) {
      const msg = 'Billing.initializeBrainTreeDropIn()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  save(): void {
    // console.log('save()', this._braintreeInstance);

    if (this._braintreeInstance) {
      this.modalDialogService.showPleaseWait('Submitting requestPaymentMethod');
      this._braintreeInstance.requestPaymentMethod(
        (requestPaymentMethodErr: any, payload: any) => {
          this.modalDialogService.showPleaseWait(false);
          // console.log('save()', requestPaymentMethodErr, payload);
          if (requestPaymentMethodErr) {
            this.onError(requestPaymentMethodErr, payload);
          } else {
            this.onSuccess(payload);
          }
        }
      );
    }
  }

  ///////////////////////////////////
  // Braintree processing actions...
  ///////////////////////////////////

  onSuccess(response: any): void {
    try {
      // console.log('onSuccess() ' + JSON.stringify(response), response);

      const nonce = response.nonce; // 'fake-valid-visa-nonce';

      this.eventServerService.fireEvent('Billing', 'checkCustomerExistence', {}).subscribe(
        (event: WEvent) => {
          // console.log('Billing.onSuccess() - checkCustomerExistence', event);

          if (event.status !== 'OK') {
            throw event.message;
          }

          const customerExists = event.parameters.customerExists;
          // console.log('Customer exists: ' + customerExists);

          const action = (customerExists ? 'updateCustomerAndReplaceCreditCard' : 'createCustomerAndVerifyCreditCard');
          const parms: any = {};
          parms.payment_method_nonce = nonce;

          // console.log('About to ' + action + ' customer. parms', parms);

          this.eventServerService.fireEvent('Billing', action, parms).subscribe(
            (event2: WEvent) => {
              // console.log('Billing.onSuccess() - ' + action, event);
              if (event2.status !== 'OK') {

                // console.log('Did NOT ' + (customerExists ? 'update' : 'create') + ' customer w/credit card... ' + event.message);
                // console.log(response, event);

                const errMsg = 'The ' + response.details.cardType + ' ending in ' + response.details.lastTwo + ' has been declined.'
                               + '\nPlease try another.\n\n' + event2.message;

                this.modalDialogService.showAlert(errMsg).subscribe(
                  () => {
                    this.userInterfaceService.reloadCurrentPage();
                  }
                );

              } else {
                // console.log((customerExists ? 'Updated' : 'Created') + ' customer and verified credit card...');
                this.userInterfaceService.reloadCurrentPage();
              }
            }
          );

        }

      );

    } catch (ex) {
      const msg = 'onSuccess()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  onError(errMsg: any, payload: any): void {
    try {
      console.log('onError(' + errMsg + ') - ' + JSON.stringify(payload), payload);

    } catch (ex) {
      const msg = 'onError()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  removeCreditCard(): void {
    try {
      let msg = 'Deleting your credit card will cause your next payment to fail for EVERY web app you have set up to charge on a "per month" basis when the next billing cycle runs.';
      msg += '\n';
      msg += '\nAt that time, all of your registered web apps with recurring payments will be handled according to our Delinquent Account Policy.';
      msg += '\n';
      msg += '\nDo you wish to continue?';

      this.modalDialogService.showConfirm(msg, 'PLEASE KEEP IN MIND...').subscribe(
        (ok: boolean) => {
          if (ok) {
            this.modalDialogService.showPleaseWait('Removing credit card...');
            // console.log('removeCreditCard()');
            this.eventServerService.fireEvent('Billing', 'removeCreditCard', {}).subscribe(
              (event: WEvent) => {
                // console.log('Billing.removeCreditCard()', event);
                this.modalDialogService.showPleaseWait(false);
                // console.log(JSON.stringify(event));
                if (event.status !== 'OK') {
                  throw event.message;
                }
                this.userInterfaceService.reloadCurrentPage();
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'removeCreditCard()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  // These are used ONLY by the administrators...

  runServiceNow(): void {
    try {
      let msg = 'Running the service now will only charge ALL active subscription customers if it is the billing date as configured on the server!';
      msg += '\n';
      msg += '\nDo you wish to continue?';

      this.modalDialogService.showConfirm(msg, 'PLEASE KEEP IN MIND...').subscribe(
        (ok: boolean) => {
          if (ok) {
            this.modalDialogService.showPleaseWait('Running Billing service...');
            // console.log('removeCreditCard()');
            this.eventServerService.fireEvent('Billing', 'runNow', {}).subscribe(
              (event: WEvent) => {
                // console.log('Billing.runServiceNow()', event);
                this.modalDialogService.showAlert(event.status + ' - ' + event.message).subscribe(
                  () => {
                    this.userInterfaceService.reloadCurrentPage();
                  }
                );
              }
            );
          }
        }
      );

    } catch (ex) {
      const msg = 'runServiceNow()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  createTheBillingAccount(account: WResource): void {

    // console.log('createTheBillingAccount()', account);

    this.eventServerService.fireEvent('Billing', 'checkCustomerExistence', {accountID: account.accountID.value}).subscribe(
      (event: WEvent) => {
        // console.log('Billing.createTheBillingAccount() - customerExists', event);

        if (event.status !== 'OK') {
          throw new Error(event.message);
        }

        const customerExists = event.parameters.customerExists;

        // get the customer's credit card info...

        if (customerExists) {
          this.modalDialogService.showAlert('The billing customer already existed, so no changes were made.', 'Customer Already Exists');
        } else {

          let msg = 'This will create a billing account for <strong>' + account.accountName.value + '</strong> account.';
          msg += ' Nothing will be charged to the account.';
          msg += '\n';
          msg += '\nDo you wish to continue?';

          this.modalDialogService.showConfirm(msg, 'Customer Does Not Already Exist').subscribe(
            (ok: boolean) => {
              if (ok) {
                const parms: any = {};
                parms.accountID = account.accountID.value;

                this.eventServerService.fireEvent('Billing', 'createCustomerAndVerifyCreditCard', parms).subscribe(
                  (event3: WEvent) => {
                    // console.log('Billing.createCustomerAndVerifyCreditCard()', event3);

                    if (event3.status !== 'OK') {
                      throw new Error(event3.message);
                    }

                    this.modalDialogService.showAlert('Customer billing account created.', 'Success!');
                  }
                );
              }
            }
          );
        }
      }
    );
  }

}
