import { SingleDataSet, Label, Colors } from 'ng2-charts';
import { ChartOptions } from 'chart.js';
import * as PluginDataLabels from 'chartjs-plugin-datalabels';

// NOTE: WChartType is a subset of the values available in ChartType from chart.js
export enum WChartType { line = 'line', bar = 'bar', pie = 'pie'}

export class WChart {

    // For help on options, etc. see https://www.chartjs.org/docs/2.9.4/

    title = 'Title';
    subTitle = 'Sub-Title';

    // indicates the type of charts, it can be: line, bar, radar, pie, polarArea, doughnut
    // NOTE: The only supported ones planned are line, bar, pie
    type: WChartType = WChartType.line;

    // set of points of the chart, it should be MultiDataSet only for line, bar, radar and doughnut, otherwise SingleDataSet
    // data: SingleOrMultiDataSet;

    // data see about, the label for the dataset which appears in the legend and tooltips
    datasets: { data: SingleDataSet, label: string } [] = null;

    // x axis labels. It's necessary for charts: line, bar and radar. And just labels (on hover)
    // for charts: polarArea, pie and doughnut. Label is either a single string, or it may be a
    // string[] representing a multi-line label where each array element is on a new line.
    labels: Label [] = [];

    // data colors, will use default and|or random colors if not specified (see below)
    colors: Colors [] = null;

    // if true show legend below the chart, otherwise not be shown
    legend = true;

    // chart options vary by type of chart...
    options: ChartOptions = {};

    // plugins for the chart...
    plugins: any = [ PluginDataLabels ];

    constructor(type: WChartType, title: string, yAxisLabel: string, subTitle: string, hideDataLabels: boolean, showPercentage: boolean) {
        this.type = type;
        this.title = title;
        this.subTitle = subTitle;
        this.colors = this.getColors(type);
        this.options = this.getOptions(type, yAxisLabel, hideDataLabels, showPercentage);
    }

    getOptions(type: string, yAxisLabel: string, hideDataLabels: boolean, showPercentage: boolean): ChartOptions {
        yAxisLabel = typeof yAxisLabel !== 'undefined' ? yAxisLabel : '';

        const options: ChartOptions = {

            // these allow the canvas to fit within the containing div...
            responsive: true,
            maintainAspectRatio : false,

            legend: {
                position: (type === WChartType.pie ? 'right' : 'bottom'),
                labels: {
                    padding: (type === WChartType.pie ? 3 : 10),
                    boxWidth: 10
                },
            },
            plugins: {
                // Change options for ALL labels of THIS CHART
                datalabels: {
                    color: (type === WChartType.bar ? 'white' : 'black'),
                    anchor: (type === WChartType.line ? 'end' : 'center'),
                    clamp: true,
                    display: !hideDataLabels,
                    clip: true,
                },
            }
        };

        if ((type === WChartType.pie) && showPercentage) {

            options.plugins.datalabels.formatter = (n, ctx) => {

                let percentage = '';

                if (!isNaN(n)) {
                    const dataset = ctx.dataset;

                    // console.log('pie chart', n, typeof n, ctx, dataset, dataset.data);

                    if (dataset && dataset.data && (dataset.data.length > 0)) {
                        let sum = 0;
                        dataset.data.forEach( (x: any) => { sum += x; } );
                        percentage = ((n / sum) * 100).toFixed(1) + '%';
                        // console.log('pie chart %', n, sum, (n / sum), percentage);
                    }
                }
                return percentage;
            };
        }

        if (type !== WChartType.pie) {

            options.scales = {
                yAxes: [
                    {
                    scaleLabel:
                        {
                            display: true,
                            labelString: yAxisLabel
                        }
                    }
                ]
            };
        }
        return options;
    }

    getColors(type: WChartType): Colors [] {

        let colors: any = [
            {
                backgroundColor: '#1BB946',
            },
            {
                backgroundColor: '#4472C4',
            },
            {
                backgroundColor: '#A5A5A5',
            },
            {
                backgroundColor: '#CC7A09',
            },
            {
                backgroundColor: '#943FFF',
            },
            {
                backgroundColor: '#FF2529',
            },
            {
                backgroundColor: '#1FF4F9',
            },
            {
                backgroundColor: '#E838DB',
            },
            {
                backgroundColor: '#085916'
            },
        ];

        if (type === WChartType.line) {
            colors = [
                {
                    backgroundColor: '#1BB946',
                    borderColor:     '#1BB946',
                    borderWidth: 1,
                    fill: false,
                    tension: 0,
                },
                {
                    backgroundColor: '#4472C4',
                    borderColor:     '#4472C4',
                    borderWidth: 1,
                    fill: false,
                    tension: 0,
                },
                {
                    backgroundColor: '#A5A5A5',
                    borderColor:     '#A5A5A5',
                    borderWidth: 1,
                    fill: false,
                    tension: 0,
                },
                {
                    backgroundColor: '#CC7A09',
                    borderColor:     '#CC7A09',
                    borderWidth: 1,
                    fill: false,
                    tension: 0,
                },
                {
                    backgroundColor: '#943FFF',
                    borderColor:     '#943FFF',
                    borderWidth: 1,
                    fill: false,
                    tension: 0,
                },
                {
                    backgroundColor: '#FF2529',
                    borderColor:     '#FF2529',
                    borderWidth: 1,
                    fill: false,
                    tension: 0,
                },
                {
                    backgroundColor: '#1FF4F9',
                    borderColor:     '#1FF4F9',
                    borderWidth: 1,
                    fill: false,
                    tension: 0,
                },
                {
                    backgroundColor: '#E838DB',
                    borderColor:     '#E838DB',
                    borderWidth: 1,
                    fill: false,
                    tension: 0,
                },
                {
                    backgroundColor: '#085916',
                    borderColor:     '#085916',
                    borderWidth: 1,
                    fill: false,
                    tension: 0,
                },
            ];
        } else if (type === WChartType.pie) {
            colors = [
                {
                    backgroundColor: [
                    '#1BB946',
                    '#4472C4',
                    '#A5A5A5',
                    '#CC7A09',
                    '#943FFF',
                    '#FF2529',
                    '#1FF4F9',
                    '#E838DB',
                    '#085916',
                    ]
                }
            ];
        }

        return colors;
    }

}

